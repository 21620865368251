import { v4 } from "uuid";
import { action } from "mobx";
import { isArray } from "lodash";

import { store } from "store";

import { validateRequired, validateSchema } from "pages/section-wizzard/validation/validation";

import { ColumnType } from "entities/ColumnType";
import { MAIN_TAB_NAME, SettingName } from "pages/section-wizzard/data/data";
import {
	AdditionalPanel,
	DisplayedPanel,
	GridItem,
	Navbar,
	OperationItem,
	OptionPage,
	RecordItem,
	RecordOperation,
	RecordRightLevel,
	SectionWizzard,
	TabId,
	TabsConfig,
	TabState
} from "types/entity";


export class SectionWizzardController {
	getSectionWizzard = (): SectionWizzard | null => {
		const findEntity = store.entities.find(entity => entity.id === store.currentEntityId)?.entity;
		if (findEntity) {
			return findEntity.sectionWizzard;
		}
		return null;
	};

	setHasChanges = action((value: boolean): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard)
			sectionWizzard.hasChanges = value;
	});

	/**
	 * @description функция-обертка decorator
	 **/
	changeObserver = (func: Function): Function => {
		return (...atributes: Array<any>) => {
			// @ts-ignore
			const result = func(...atributes);

			this.setHasChanges(true);
			return result;
		};
	};


	generateNew = action((): SectionWizzard => {
		const sectionWizzardConfig: SectionWizzard = {
			optionPage: OptionPage.GlobalSettings,
			entityTitle: "",
			systemName: "",
			hasStageModel: false,
			kanbanIsEnabled: false,
			hasCalendar: false,
			hasSpecificFinish: false,
			displayedPanel: DisplayedPanel.Main,
			viewColumnId: null,
			searchValue: null,
			reactorPage: "",
			reactorConfig: {
				navbar: {
					left: "",
					center: "",
					right: ""
				},
				tabs: {
					tabsConfig: [{
						tabIndex: 0,
						tabName: MAIN_TAB_NAME,
						tabTitle: "Основная информация",
						grid: {
							items: [
								{
									x: -1,
									y: 0,
									width: 1,
									height: 1,
									type: 0,
									gridItemId: v4(),
									fieldConfig: {
										columnId: v4(),
										columnName: "Id",
										columnTitle: "Id",
										columnType: ColumnType.Guid,
										hasIndex: false,
										isLookup: false,
										isRequired: true,
										lookupTable: "Section",
										specializations:
										{
											tag: 0,
											properties: {
												additionalProp1: "",
												additionalProp2: "",
												additionalProp3: ""
											}
										}
									}
								},
								{
									x: -1,
									y: 0,
									width: 1,
									height: 1,
									type: 0,
									gridItemId: v4(),
									fieldConfig: {
										columnId: v4(),
										columnName: "CreatedOn",
										columnTitle: "Дата создания",
										columnType: ColumnType.DateTime,
										hasIndex: false,
										isLookup: false,
										isRequired: true,
										lookupTable: "Section",
										specializations:
										{
											tag: 0,
											properties: {
												additionalProp1: "",
												additionalProp2: "",
												additionalProp3: ""
											}
										}
									}
								},
								{
									x: -1,
									y: 0,
									width: 1,
									height: 1,
									type: 0,
									gridItemId: v4(),
									fieldConfig: {
										columnId: v4(),
										columnName: "ModifiedOn",
										columnTitle: "Дата изменения",
										columnType: ColumnType.DateTime,
										hasIndex: false,
										isLookup: false,
										isRequired: true,
										lookupTable: "Section",
										specializations:
										{
											tag: 0,
											properties: {
												additionalProp1: "",
												additionalProp2: "",
												additionalProp3: ""
											}
										}
									}
								},
								{
									x: -1,
									y: 0,
									width: 1,
									height: 1,
									type: 0,
									gridItemId: v4(),
									fieldConfig: {
										columnId: v4(),
										columnName: "ExternalId",
										columnTitle: "Внешний Id",
										columnType: ColumnType.String,
										hasIndex: false,
										isLookup: false,
										isRequired: false,
										lookupTable: "Section",
										specializations:
										{
											tag: 0,
											properties: {
												additionalProp1: "",
												additionalProp2: "",
												additionalProp3: ""
											}
										}
									}
								},
							]
						}
					}],
					additionalPanel:
					{
						comments: {
							state: TabState.Disabled,
							index: -1,
							title: SettingName.Comments
						},
						files: {
							state: TabState.Disabled,
							index: -1,
							title: SettingName.Files
						},
						chronology: {
							state: TabState.Disabled,
							index: -1,
							title: SettingName.Chronology
						},
						linkedRecords: {
							state: TabState.Disabled,
							index: -1,
							title: SettingName.LinkedRecords
						}
					},
					currentTab: 0
				}
			},
			accessRightsConfig: {
				adminByOperation: {
					isEnabled: false,
					operationItems: []
				},
				adminByRecords: {
					isEnabled: false,
					recordItems: []
				}
			},
			hasChanges: false,
			stageModelConfig: null,
		};
		return sectionWizzardConfig
	});

	checkExistFieldName = (fieldName: string): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let checker = false;
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig.forEach(tab => {
				tab.grid.items.forEach(gridItem => {
					if (gridItem.groupFieldsConfig) {
						/* Проверяем все поля из группы полей */
						const findedItem = gridItem.groupFieldsConfig?.inner?.items.find(gridItemInGroup =>
							gridItemInGroup.fieldConfig?.columnName === fieldName
						);
						if (findedItem) {
							checker = true;
							return;
						}
					}
					else if (gridItem.fieldConfig) {
						/* Проверяем поле просто на холсте */
						if (gridItem.fieldConfig.columnName === fieldName) {
							checker = true;
							return;
						}
					}
				})
			})
		}
		return checker;
	};

	checkExistFieldTitle = (fieldTitle: string): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let checker = false;
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig.forEach(tab => {
				tab.grid.items.forEach(gridItem => {
					if (gridItem.groupFieldsConfig) {
						/* Проверяем все поля из группы полей */
						const findedItem = gridItem.groupFieldsConfig?.inner?.items.find(gridItemInGroup =>
							gridItemInGroup.fieldConfig?.columnTitle === fieldTitle
						);
						if (findedItem) {
							checker = true;
							return;
						}
					}
					else if (gridItem.fieldConfig) {
						/* Проверяем поле просто на холсте */
						if (gridItem.fieldConfig.columnTitle === fieldTitle) {
							checker = true;
							return;
						}
					}
				})
			})
		}
		return checker;
	};

	checkExistGroupFieldName = (groupName: string): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let checker = false;
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig.forEach(tab => {
				const groups = tab.grid.items.filter(item => item.groupFieldsConfig);
				const finded = groups.find(group => group.groupFieldsConfig?.name === groupName);
				if (finded) {
					checker = true;
					return;
				}
			})
		}
		return checker;
	};

	checkExistGroupFieldTitle = (groupTitle: string): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let checker = false;
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig.forEach(tab => {
				const groups = tab.grid.items.filter(item => item.groupFieldsConfig);
				const finded = groups.find(group => group.groupFieldsConfig?.title === groupTitle);
				if (finded) {
					checker = true;
					return;
				}
			})
		}
		return checker;
	};

	checkExistTabName = (tabName: string): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let checker = false;
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig.forEach(tab => {
				if (tab.tabName === tabName) {
					checker = true;
					return;
				}
			})
		}
		return checker;
	};

	checkExistTabTitle = (tabTitle: string): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let checker = false;
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig.forEach(tab => {
				if (tab.tabTitle === tabTitle) {
					checker = true;
					return;
				}
			})
		}
		return checker;
	};

	getViewColumnTitle = (): string => {
		const sectionWizzard = this.getSectionWizzard();
		let newValue = "Название записи";
		if (sectionWizzard) {
			const id = sectionWizzard.viewColumnId;
			if (id) {
				this.getAllGridItems().forEach((item) => {
					if (item.fieldConfig?.columnId === id)
						newValue = item.fieldConfig?.columnTitle!;
				});
			}
		}
		return newValue;
	};

	getGridItemsFromConfig = (tabIndex: number): GridItem[] | [] => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			return sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items ?? [];
		}
		return [];
	};

	getAdditionalFromConfig = (): AdditionalPanel | undefined => {
		return this.getSectionWizzard()?.reactorConfig.tabs.additionalPanel;
	};

	getViewAdditional = (): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let findVisibleTab = false;
		if (sectionWizzard) {
			const arrayOfAdditional = Object.entries(sectionWizzard?.reactorConfig.tabs.additionalPanel!);
			arrayOfAdditional.forEach(([key, tabParams]) => {
				if (tabParams.state === TabState.EnabledOnAuxiliary) {
					findVisibleTab = true;
					return
				}
			});
		}
		return findVisibleTab;
	};

	getAllGridItems = (): GridItem[] | [] => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			const allItems: GridItem[] = [];
			sectionWizzard.reactorConfig.tabs.tabsConfig.map(tab => {
				tab.grid.items.map(item => {
					allItems.push(item);
					if (item.groupFieldsConfig?.inner?.items) {
						item.groupFieldsConfig?.inner?.items.forEach((innerItem) => {
							allItems.push(innerItem);
						});
					}
				});
			});
			return allItems;
		}
		return [];
	};

	/**
	* @description находит последний индекс для добавления нового таба
	*/
	getLastIndexForAddTab = (): number => {
		let maxIndex = -1;
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			const additional = this.getAdditionalFromConfig();
			if (sectionWizzard.displayedPanel === DisplayedPanel.Main) {
				sectionWizzard.reactorConfig.tabs.tabsConfig.forEach(tab => {
					if (tab.tabIndex > maxIndex)
						maxIndex = tab.tabIndex
				})
			}
			if (additional) {
				const additionalTabsAsArray = Object.entries(additional);
				if (sectionWizzard.displayedPanel === DisplayedPanel.Main) {
					additionalTabsAsArray.filter(([key, value]) => value.state === TabState.EnabledOnPrimary)
				} else {
					additionalTabsAsArray.filter(([key, value]) => value.state === TabState.EnabledOnAuxiliary)
				}
				additionalTabsAsArray.forEach(([key, value]) => {
					if (value.index > maxIndex) {
						maxIndex = value.index
					}

				})
			}
		}
		return maxIndex
	};

	/**
	* @description проверяет является ли таб коробочным
	**/
	isBoxTab = action((tabId: string): boolean => {
		if (tabId === TabId.Chronology || tabId === TabId.Comments || tabId === TabId.Files || tabId === TabId.LinkedRecords)
			return true
		else return false
	});

	setOptionPage = action((optionPage: OptionPage): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard)
			sectionWizzard.optionPage = optionPage;
	});


	setEntityTitle = this.changeObserver(action((name: string): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard)
			sectionWizzard.entityTitle = name;
	}));

	setSystemName = this.changeObserver(action((name: string): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard)
			sectionWizzard.systemName = name;
	}));

	setHasStageModel = this.changeObserver(action((value: boolean): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard)
			sectionWizzard.hasStageModel = value;
	}));

	setKanbanIsEnabled = this.changeObserver(action((value: boolean): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard)
			sectionWizzard.kanbanIsEnabled = value;
	}));

	setHasCalendar = this.changeObserver(action((value: boolean): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard)
			sectionWizzard.hasCalendar = value;
	}));

	setHasSpecificFinish = this.changeObserver(action((value: boolean): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard)
			sectionWizzard.hasSpecificFinish = value;
	}));

	switchDisplayedPanel = this.changeObserver((panel: DisplayedPanel): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard)
			sectionWizzard.displayedPanel = panel;
	});

	setViewColumnId = this.changeObserver(action((displayedColumnId: string): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard)
			sectionWizzard.viewColumnId = displayedColumnId;
	}));

	setAdditionalTabsOrder = this.changeObserver(action((tabId: TabId, orderIndex: number): void => {
		const tabParams = this.getAdditionalFromConfig()![tabId];
		tabParams.index = orderIndex;
	}));

	setStateInAdditionalPanel = this.changeObserver(action((tabId: TabId, tabState: TabState): void => {
		const tabParams = this.getAdditionalFromConfig()![tabId];
		tabParams.state = tabState;
		if (tabState === TabState.Disabled) {
			tabParams.index = -1;
		}
		if (tabState === TabState.EnabledOnAuxiliary) {
			tabParams.index = 0;
		}
	}));

	setNewTitleAdditionalTab = this.changeObserver(action((tabId: TabId, newtitle: string): void => {
		const additional = this.getAdditionalFromConfig();
		if (additional) {
			const findAdditionalTab = additional[tabId];
			if (findAdditionalTab) {
				findAdditionalTab.title = newtitle;
			}
		}
	}));

	setSearchValue = this.changeObserver(action((value: string): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard)
			sectionWizzard.searchValue = value;
	}));



	setNavbar = this.changeObserver(action((position: Navbar): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard)
			sectionWizzard.reactorConfig.navbar = position;
	}));

	createNewTab = action((name: string, title: string, index: number): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			const newTab: TabsConfig = {
				tabIndex: index,
				tabName: name,
				tabTitle: title,
				grid: {
					items: []
				}
			};
			sectionWizzard.reactorConfig.tabs.tabsConfig.push(newTab);
		}
	});

	setTabByName = this.changeObserver(action((tabConfig: TabsConfig): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			let tab = sectionWizzard.reactorConfig.tabs.tabsConfig
				.find(tab => tab.tabName === tabConfig.tabName);
			if (tab) {
				let findedItemIndex = sectionWizzard.reactorConfig.tabs.tabsConfig.findIndex(elem => elem.tabName === tab?.tabName);
				sectionWizzard.reactorConfig.tabs.tabsConfig[findedItemIndex] = tabConfig;
			}
		}
	}));

	createDuplicateGridItemInTab = action((targetZone: string, gridItem: GridItem, tabIndex: number): void => {
		const sectionWizzard = this.getSectionWizzard();

		const groupField = sectionWizzard?.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.find((item) => item.gridItemId === targetZone);
		if (groupField?.groupFieldsConfig?.inner?.items && isArray(groupField?.groupFieldsConfig?.inner.items)) {
			groupField.groupFieldsConfig.inner.items.push(gridItem);
			return;
		}
		if (sectionWizzard) {
			const length = sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.filter(item => item.fieldConfig?.columnId === gridItem.groupFieldsConfig?.groupFieldId).length;
			const duplicateGridItem = {
				...gridItem,
				id: gridItem.groupFieldsConfig?.groupFieldId + `-${length + 1}`
			};
			sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.push(duplicateGridItem);
		}
	});

	addGridItemByTabIndex = action((gridItem: GridItem, tabIndex: number): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.push(gridItem);
		}
	});

	setCurrentTab = action((index: number): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard)
			sectionWizzard.reactorConfig.tabs.currentTab = index;
	});

	/**
	 * @description заменяет переданные значения элемента по его координатам
	 * @param tabIndex
	 * @param gridItem - список полей для изменения
	 **/
	setGridItemByTabIndex = action((tabIndex: number, gridItem: GridItem, targetZone?: string): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			if (sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.find((item) => item.gridItemId === targetZone)) {
				this.createDuplicateGridItemInTab(targetZone ?? "", gridItem, tabIndex);
				return;
			}

			let findedItemInGroupIndex = -1;
			let gridItemIndex = 0;
			const gridItems = sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items;

			/* Поиск элемента в группе полей */
			for (let itemIndex = 0; itemIndex < gridItems.length; itemIndex++) {
				findedItemInGroupIndex = gridItems[itemIndex].groupFieldsConfig?.inner?.items
					.findIndex(innerItem => (innerItem.fieldConfig?.columnId || innerItem.groupFieldsConfig?.groupFieldId) === gridItem.gridItemId) ?? -1;
				if (findedItemInGroupIndex !== -1) gridItemIndex = itemIndex;
			}

			if (findedItemInGroupIndex !== -1) {
				/* Элемент содержится в группе полей */
				if (gridItems[gridItemIndex]) {
					gridItems[gridItemIndex].groupFieldsConfig!.inner!.items[findedItemInGroupIndex] = {
						...sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items[gridItemIndex],
						...gridItem
					};
				}
			} else {
				/* Поиск элемента на холсте */
				const findedItemIndex = sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items
					.findIndex((item) => item?.gridItemId === gridItem.gridItemId);

				if (findedItemIndex !== -1) {
					/* Элемент лежит на холсте */
					sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items[findedItemIndex] = {
						...sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items[findedItemIndex],
						...gridItem
					};
				} else {
					/* Добавление на холст нового элемента */
					sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.push(gridItem);
				}
			}
		}
	});

	deleteTabByName = action((tabName: string): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			const mainTabIndex = sectionWizzard.reactorConfig.tabs.tabsConfig.findIndex(tab => tab.tabName === MAIN_TAB_NAME)
			const mainTabItems = this.getGridItemsFromConfig(mainTabIndex);
			const findDeletedTab = sectionWizzard.reactorConfig.tabs.tabsConfig.find(tab => tab.tabName === tabName);
			if (findDeletedTab) {
				findDeletedTab.grid.items.forEach(item => {
					if (item.fieldConfig) {
						const findedById = mainTabItems.find(gridItemInMain => gridItemInMain.fieldConfig?.columnId === item.fieldConfig?.columnId);
						if (!findedById) {
							const newItem = {
								...item,
								x: -1,
								y: -1
							}
							this.addGridItemByTabIndex(newItem, mainTabIndex);
						}
					}
				})
			}
			const indexFindedTab = sectionWizzard.reactorConfig.tabs.tabsConfig.findIndex(tab => tab.tabName === tabName);
			const currentTabIndex = sectionWizzard.reactorConfig.tabs.currentTab;
			if (indexFindedTab === currentTabIndex && currentTabIndex !== 0) {
				this.setCurrentTab(currentTabIndex - 1);
			}
			const newTabs = sectionWizzard.reactorConfig.tabs.tabsConfig.filter(tab => tab.tabName !== tabName);
			sectionWizzard.reactorConfig.tabs.tabsConfig = newTabs;
		}
	});

	/**
	 * @description Проверяет является ли цель group field
	 */
	itFieldGroup = (tabIndex: number, targetZoneId: string): boolean => {
		return Boolean(this.getSectionWizzard()?.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.find((item) => item.gridItemId === targetZoneId));
	};

	private autoPull = action("auto put item", ({ tabIndex, item, sourceId, itSameGrid }: { tabIndex: number, item: GridItem, sourceId?: string, itSameGrid?: boolean }): void => {
		if (sourceId) {
			const gridGroupFiled = this.getSectionWizzard()?.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items.find((innerItem: GridItem) => innerItem.gridItemId === sourceId)?.groupFieldsConfig?.inner;
			if (gridGroupFiled) {
				if (itSameGrid) {
					const indexItem = gridGroupFiled.items.findIndex((innerItem: GridItem) => (innerItem.gridItemId ?? innerItem.gridItemId) === (item.gridItemId ?? innerItem.gridItemId));
					gridGroupFiled.items[indexItem] = item;
				} else {
					gridGroupFiled.items = gridGroupFiled.items.filter((innerItem: GridItem) => (innerItem.gridItemId ?? innerItem.gridItemId) !== (item.gridItemId ?? innerItem.gridItemId));
				}
			}
		} else {
			const grid = this.getSectionWizzard()?.reactorConfig.tabs.tabsConfig[tabIndex]?.grid;
			if (grid) {
				if (itSameGrid) {
					const indexItem = grid.items.findIndex((innerItem: GridItem) => (innerItem.gridItemId ?? innerItem.gridItemId) === (item.gridItemId ?? item.gridItemId));
					grid.items[indexItem] = item;
				} else {
					grid.items = grid.items.filter((innerItem: GridItem) => innerItem.gridItemId !== item.gridItemId);
				}
			}
		}
	});

	putInZone = action("put in zone", (tabIndex: number, item: GridItem) => {
		this.getSectionWizzard()?.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.push(item);
	});

	putInGroupField = action("put in group field", (tabIndex: number, groupFieldId: string, item: GridItem) => {
		this.getSectionWizzard()?.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items.find((innerItem: GridItem) => innerItem.gridItemId === groupFieldId)?.groupFieldsConfig?.inner?.items.push(item);
	});

	/**
	 * @description Автоматически удаляет и кладет элемент в zone и groupField
	 */
	autoPut = action("auto put item", ({ tabIndex, targetId, sourceId, item }: {
		tabIndex: number,
		targetId: string,
		sourceId: string | null,
		item: GridItem,
	}): void => {

		const targetIsGroupField = this.itFieldGroup(tabIndex, targetId);
		const sourceIsGroupField = sourceId && this.itFieldGroup(tabIndex, sourceId);

		if (targetIsGroupField) {
			this.putInGroupField(tabIndex, targetId, item);
			if (sourceIsGroupField) {
				this.autoPull({ tabIndex, item, sourceId, itSameGrid: targetId === sourceId });
			} else {
				this.autoPull({ tabIndex, item });
			}
		} else {
			if (sourceIsGroupField) {
				this.putInZone(tabIndex, item);
				this.autoPull({ tabIndex, item, sourceId });
			} else {
				/**
				 * @description При перемещении в одном и том же grid, функционал по перемещению на себя берет autoPull, ибо он имеет функционал глубокого перебора grid
				 * В случае если будет не удобно, написать новый метод, replace или move
				 */
				this.autoPull({ tabIndex, item, itSameGrid: true });
			}
		}
	});


	/**
	* @description Удаление элементов грида из Существующих полей
	* @param gridItemId Id элемента грида
	* @param tabIndex Индекс таба
	* @param groupFieldId Id группы полей
	*/
	deleteGridItemFromTab = action((gridItemId: string, tabIndex: number, groupFieldId?: string | undefined): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			const gridItems = sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items;
			const groupField = gridItems?.find((item) => item.gridItemId === groupFieldId);
			if (groupField?.groupFieldsConfig?.inner?.items) {
				groupField.groupFieldsConfig.inner.items = groupField.groupFieldsConfig.inner.items.filter((item) => item.gridItemId !== gridItemId);
				return;
			}

			const fieldItemIndex = sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items
				.findIndex(item => item.gridItemId === gridItemId);
			if (fieldItemIndex > -1) {
				/* Элемент находится на холсте */
				if (sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items[fieldItemIndex]) {
					sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items.splice(fieldItemIndex, 1);
				}
			}
		}
	});

	createNewGridItemInTab = action((item: GridItem, tabIndex: number): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items.push(item);
		}
	});

	createNewGridItemInGroup = action("Создать новый элемент внутри группы полей", (groupId: string, tabIndex: number, item: GridItem) => {
		const sectionWizard = this.getSectionWizzard()?.reactorConfig.tabs.tabsConfig[tabIndex].grid.items;
		if (sectionWizard) {
			const indexFieldGroup = sectionWizard.findIndex((elementIndex) => elementIndex.gridItemId === groupId);
			if (indexFieldGroup && sectionWizard[indexFieldGroup]?.groupFieldsConfig) {
				if (isArray(sectionWizard[indexFieldGroup]?.groupFieldsConfig?.inner)) {
					// @ts-ignore
					sectionWizard[indexFieldGroup]?.groupFieldsConfig?.inner?.push(item);
				} else {
					sectionWizard[indexFieldGroup]?.groupFieldsConfig?.inner?.items.push(item);
				}

				return true;
			}
		} else false;
	});



	/**
	 * @description меняет тогл "Использовать доступ по операциям" в правах доступа
	 * @param isEnabled - значение тогла
	 **/
	setEnableAdminByOperation = this.changeObserver(action((isEnabled: boolean): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			sectionWizzard.accessRightsConfig.adminByOperation.isEnabled = isEnabled;
		}
	}));

	/**
	 * @description добавляет значения в массив OperationItems в правах доступа
	 * @param operationItem - элемент массива OperationItems
	 **/
	setAdminByOperationItems = this.changeObserver(action((operationItem: OperationItem): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			sectionWizzard.accessRightsConfig.adminByOperation.operationItems.push(operationItem);
		}
	}));

	/**
	 * @description меняет доступы по операциям в правах доступа
	 * @param id - id записи
	 * @param operation - название операции
	 **/
	setOperationActions = this.changeObserver(action((id: string, operation: string, value: boolean): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			const operationItem = sectionWizzard.accessRightsConfig.adminByOperation.operationItems.find(item => item.id === id);

			if (operationItem) {
				(operationItem as any)[operation] = value;
				const operationItemIndex = sectionWizzard.accessRightsConfig.adminByOperation.operationItems.findIndex(item => item.id === id);
				sectionWizzard.accessRightsConfig.adminByOperation.operationItems.splice(operationItemIndex, 1, operationItem);
			}
		}
	}));

	/**
	 * @description меняет тогл "Использовать доступ по записям" в правах доступа
	 * @param isEnabled - значение тогла
	 **/
	setEnableAdminByRecords = this.changeObserver(action((isEnabled: boolean): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			sectionWizzard.accessRightsConfig.adminByRecords.isEnabled = isEnabled;
		}
	}));

	/**
	 * @description добавляет 3 значения в массив RecordItem в правах доступа (для чтения, редактирования и удаления)
	 * @param recordItems - массив RecordItem
	 **/
	setAdminByRecordItems = this.changeObserver(action((recordItems: RecordItem[]): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			sectionWizzard.accessRightsConfig.adminByRecords.recordItems.push(...recordItems);
		}

	}));

	/**
	 * @description меняет доступы по операциям в правах доступа
	 * @param id - id записи
	 * @param operation - операция
	 **/
	setRecordActions = this.changeObserver(action((id: string, operation: RecordOperation, value: boolean): void => {
		const sectionWizzard = this.getSectionWizzard();

		if (sectionWizzard) {
			const groupRecordsIds = sectionWizzard.accessRightsConfig.adminByRecords.recordItems.filter(item => item.groupRecordsId === id);

			const recordItem = groupRecordsIds.find(item => item.operation == operation);
			if (recordItem) {
				recordItem.rightLevel = value ? RecordRightLevel.Granted : RecordRightLevel.Deny;
				const recordItemIndex = sectionWizzard.accessRightsConfig.adminByRecords.recordItems.findIndex(item => item.id === recordItem.id);
				sectionWizzard.accessRightsConfig.adminByRecords.recordItems.splice(recordItemIndex, 1, recordItem);
			}
		}
	}));

}

export const sectionWizzardController = new SectionWizzardController();
