import { Entity } from "store";
import { Column } from "types/entity";
import { Coordinate } from "types/item";

export enum Click {
    Single = 1,
    Double = 2
}
export interface IGeneralizedColumn extends Column {
    columnName: string;
    columnTitle: string;
    customerColumnTitle: string;
    indexX: number;
    indexY: number;
    spanX: number;
    spanY: number;
}

export type IContextMenuOption = {
    caption: string,
    action: () => void,
    isDivider: boolean
}
export type GeneralizedGridProps = {
    entity: Entity;
    contextMenuOptions?: IContextMenuOption[],
    onChangeCheckedAll: (value: boolean) => void,
    onDoubleRowClick?: (rowId: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    isDetailGrid?: boolean,
}

export type GridHeadProps = {
    columns: IGeneralizedColumn[],
    checkedAll: boolean,
    onChangeChecked: (checked: boolean) => void,
    onSortClick: (column: IGeneralizedColumn) => void,
    isDetailGrid?: boolean,
}

export type GridRowProps = {
    entity: Entity,
    row: any,
    gridBodyRef: React.RefObject<HTMLDivElement>,
    contextMenuRef: React.RefObject<HTMLDivElement>,
    setIsShownContextMenu: (value: boolean) => void,
    setPosition: (coords: Coordinate) => void,
    onClick?: (row: any) => void,
    onChangeCheckedAll: (value: boolean) => void,
    onDoubleRowClick?: (rowId: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export type ValueProps = {
    row: any,
    column: IGeneralizedColumn,
    width?: string,
    entityName?: string
}