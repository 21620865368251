import { validateRequired, validateSchema } from "entities/Validation";
import { makeAutoObservable, toJS } from "mobx";
import { ERROR_GROUP_EXIST_NAME, ERROR_GROUP_EXIST_TITLE, ValidationState } from "pages/section-wizzard/data/data";

import { dispatcher } from "store";

import { GridItem, ItemType } from "types/entity";
import { v4 } from "uuid";

export enum FieldNameFromStore {
	name = "name",
	systemName = "systemName",
	isDidabledSaveButton = "isDidabledSaveButton"
}

class FieldGroupConfigurationStore {
	hasChanges : boolean = false;
	name: string = "";
	systemName: string = "";
	isDidabledSaveButton: boolean = true;

	validation: { [key: string]: ValidationState };

	x: number;
	y: number;

	constructor() {
		makeAutoObservable(this);
		this.validation = {
			name: {
				isInvalid: false,
				isNotUnique: false,
				error: "",
			},
			systemName: {
				isInvalid: false,
				error: "",
			},
		};
		this.x = 0;
		this.y = 0;
	}

	setValue(fieldName: string, value: string | number) {
		this.hasChanges = true;
		Reflect.set(this, fieldName, value);
	}

	setValueWithoutTrackingChanges(fieldName: string, value: string | boolean | null | number){
		Reflect.set(this, fieldName, value);
	}

	validateTitle() {
		const isNotUnique = dispatcher.sectionWizzard.checkExistGroupFieldTitle(this.name);
		this.validation.name.isNotUnique = isNotUnique;
		if (isNotUnique) {
			this.validation.name.isInvalid = false;
			this.validation.name.error = ERROR_GROUP_EXIST_TITLE;
		}
		else {
			validateRequired(this.name, this.validation.name);
		}
	}

	validateSystemName() {
		const isNotUnique = dispatcher.sectionWizzard.checkExistGroupFieldName(this.systemName);
		if (isNotUnique) {
			this.validation.systemName.isInvalid = isNotUnique;
			this.validation.systemName.error = ERROR_GROUP_EXIST_NAME;
		}
		else if (validateSchema(this.systemName, this.validation.systemName)) {
			this.validation.systemName.isInvalid = false;
			this.validation.systemName.error = "";
		}
	}

	getConfigurationById(fieldGroupId: string) {
		const currentTabIndex = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
		const currentGroup = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items.find(item => item.gridItemId === fieldGroupId);

		if (currentGroup) {
			this.name = currentGroup.groupFieldsConfig?.title!;
			this.systemName = currentGroup.groupFieldsConfig?.name!;
			this.x = currentGroup.x;
			this.y = currentGroup.y;
		}
	}

	saveFieldGroup(fieldGroupId?: string) {
		const currentTabIndex = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;

		if (fieldGroupId) {
			const currentGroup = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items.find(item => item.gridItemId === fieldGroupId);
			if (currentGroup) {
				const editFieldGroup: GridItem = {
					...currentGroup,
					groupFieldsConfig: {
						name: this.systemName,
						title: this.name,
						inner: currentGroup.groupFieldsConfig?.inner!,
						groupFieldId: currentGroup.groupFieldsConfig?.groupFieldId!,
					},
				};
				dispatcher.sectionWizzard.setGridItemByTabIndex(currentTabIndex, editFieldGroup);
			}
		} else {
			const newFieldGroup: GridItem = {
				x: this.x,
				y: this.y,
				gridItemId: v4(),
				width: 2,
				height: 1,
				type: ItemType.GroupFields,
				groupFieldsConfig: {
					name: this.systemName,
					title: this.name,
					inner: {
						items: []
					},
					groupFieldId: v4(),
				},
			};
			dispatcher.sectionWizzard.createNewGridItemInTab(toJS(newFieldGroup), currentTabIndex);
		}
		this.resetConfiguration();
	};

	validGroup() {
		let nameValid = false;
		let systemName = false;
		if (!this.validation.name.isInvalid && this.name.length > 0) {
			nameValid = true;
		}
		if (!this.validation.systemName.isInvalid && this.systemName.length > 0) {
			systemName = true;
		}

		this.isDidabledSaveButton = !(nameValid && systemName);
	}


	resetConfiguration() {
		this.hasChanges = false;
		this.name = "";
		this.systemName = "";
		this.isDidabledSaveButton = true;

		for (const key in this.validation) {
			this.validation[key].isInvalid = false;
			this.validation[key].isNotUnique = false;
			this.validation[key].error = "";
		}
	}
}

const fieldGroupConfigurationStore = new FieldGroupConfigurationStore();

export default fieldGroupConfigurationStore;
