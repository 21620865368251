import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { store } from "store";
import ImportStore from "entities/import/ImportStore";

import Upload from "../upload/upload";
import ProgressBar from "../progress-bar/progress-bar";
import StatusBar from "components/status-bar/status-bar";
import ViewingFiles from "../viewingFiles/viewing-files";
import ErrorDownload from "../error-download/error-download";
import DisplayModeType from "../progress-bar/display-mode-type";

import styles from "./files.module.css";

const text = 'Загружаем...'

const Files = observer((props: { importStore: ImportStore }) => {
    const [dragOver, setDragOver] = useState(false);
    const [isUpload, setUpload] = useState<boolean>(false);

    useEffect(() => {
        if (store.currentRowId)
            props.importStore.load(store.currentRowId);       
    }, [])

    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragOver(true);
        } else if (e.type === "dragleave") {
            setDragOver(false);
        }
    };


    const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(false);
        if (store.currentRowId)
            props.importStore.uploadFiles(store.currentRowId, e.dataTransfer.files);
    }

    const isFailed = () => {
        if (props.importStore.moreFiles) {
            return ("Не удалось загрузить файлы");
        }
        else {
            return (props.importStore.isError ? undefined : `Не удалось загрузить файл «${props.importStore.fileName}»`);
        }
    }
  
    return (
        <div
            onDragEnter={handleDrag}
            className={styles.files}
        >
            {dragOver ?
                <div className={styles.dragOver}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                >
                    Перетащите файл сюда
                </div>
                :
                <>
                    {props.importStore.isUploadStep && <ViewingFiles importStore={props.importStore} entityId={store.currentRowId} />}
                    {<ProgressBar text={text} displayMode={props.importStore.isLoading ? DisplayModeType.VIEW : DisplayModeType.HIDDEN} />}
                    {props.importStore.isReadyToUpload && <Upload importStore={props.importStore} />}
                    {props.importStore.errorUploadFile.length > 1 && <ErrorDownload importStore={props.importStore} />}

                    <StatusBar eventName={"Файл загружен"} isOpen={isUpload} changeOpen={(value) => setUpload(value)} />
                    <StatusBar
                        isError={true}
                        eventName={isFailed()}
                        changeOpen={(value) => { if (!value) props.importStore.setValue(undefined, "error") }}
                        isOpen={props.importStore.error !== undefined}
                        errorMessage={props.importStore.error}
                    />
                </>
            }
        </div>
    )
})
export default Files;
