import {
    CSSProperties,
    useCallback,
    useMemo
} from "react";
import { LowFirst } from "entities/lowFirst";

import { dispatcher } from "store";

import { Item } from "types";
import {  InputTypes, ParsedProps, Props, inputs } from "./data/data";
import { BlockedColumnNames } from "types/entity";

export function AutoInputByType(props: Props) {
    const parsedProps: ParsedProps = JSON.parse(props.field);

    const UsageInput: InputTypes | undefined = useMemo(() => parsedProps.fieldConfig?.columnType && inputs[parsedProps.fieldConfig?.columnType], [parsedProps.fieldConfig?.columnType]);

    const styles: CSSProperties = useMemo(() => ({
        gridRow: parsedProps.y,
        gridColumn: parsedProps.x
    }), [parsedProps.y, parsedProps.x])

    const handleChange = useCallback((value: string | Item | null) => {
        dispatcher.currentRow.set(LowFirst(parsedProps?.fieldConfig?.columnName ?? ""), value)
    }, [parsedProps?.fieldConfig?.columnName]);

    const entity = dispatcher.currentRow.get()!;

    const { fieldConfig } = parsedProps;

    const cellTitle = LowFirst(fieldConfig?.columnName ?? "");
    const value = entity[cellTitle];

    const isLocked = useMemo(() => BlockedColumnNames.some(columnName => columnName.toLowerCase() === cellTitle.toLowerCase()), [cellTitle]);

    return (
        <div style={styles}>
            {UsageInput ? <UsageInput
                columnName={fieldConfig?.columnName}
                columnTitle={fieldConfig?.columnTitle}
                value={value}
                onChange={handleChange}
                isRequired={fieldConfig?.isRequired}
                prompt={fieldConfig?.prompt}
                rounding={fieldConfig?.specializations?.properties.rounding}
                lookupTable={fieldConfig?.lookupTable}
                isDisabled={isLocked}
            /> : <div />}
        </div>
    );
}