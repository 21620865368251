import { IArrayDidChange, IObjectDidChange, Lambda, autorun, makeAutoObservable, observe } from "mobx";
import { v4 } from "uuid";

import { BasicEntity, ReactionSubscribers, User } from "types";
import { dispatcher } from "./store-dipatcher";
import { ModalData } from "features/modals/viewer/modal-viewer";
import {isNull, isString, isUndefined} from "lodash";
import { LowFirst } from "entities/lowFirst";

enum DisplayEntity {
	TABLE = "TABLE",
	KANBAN = "KANBAN"
}

export const VISIBLE_COLUMNS = {
	sales: [],
	leads: [],
	contacts: [],
	accounts: [],
};

export const DISPLAY = {
	sales: [DisplayEntity.KANBAN, DisplayEntity.TABLE],
	leads: [DisplayEntity.KANBAN, DisplayEntity.TABLE],
	contacts: [DisplayEntity.TABLE],
	accounts: [DisplayEntity.TABLE],
};

export type Modals = { id: string, modal: ModalData };

export type Entity = {
	id: string,
	entityName: string,
	entityTitle: string,
	entity: BasicEntity,
	isKanban: boolean,
	isNew: boolean
};

export type Section = {
	id: string,
	entityName: string,
	displayValue: string,
	editPageConfig: any,
	editPageConfigId: string,
	entityInfo: any,
	entityInfoId: string,
	externalId: string,
	hasCalendar: boolean,
	hasCanban: boolean,
	hasSpecificFinish: boolean,
	hasStages: boolean,
	isFavorite: boolean,
	createdOn: string,
	modifiedOn: string,
};

class Store {
	reactionSubscribers: ReactionSubscribers;
	sections: Array<Section>;
	currentEntityId: any;
	currentRowId: string;
	users: Array<User>;
	user: any;
	entities: Array<Entity>;
	modals: Array<Modals>;
	session: {
		accessToken: string,
		tokenExpiration: string,
		tokenExpired: boolean,
		refreshToken: string,
	};
	options: any;
	filters: any;
	automation: any;
	oldRowValue: any;
	hasChanges: boolean;
	/**
     * Массив объектов для хранения измененных полей и их значений.
     * @type {Array<{ fieldName: string, value: any }>}
     */
	changedFields :Array<{ fieldName: string, value: any }>;
	/**
	 * Флаг сообщает о том, заполнены ли все обязательные поля
	 */
	requiredFieldsFilled: boolean;
	sort: any;

	noNeedReaction: boolean = true;
	disposer?: Lambda;

	constructor() {
		this.reactionSubscribers = {};
		this.sections = [];
		this.currentEntityId = ""; // Мигрировать currents в useState компонентов
		this.currentRowId = ""; // Мигрировать currents в useState компонентов
		this.user = {};
		this.users = [];
		this.entities = [];
		this.modals = [];
		this.session = {
			accessToken: "",
			tokenExpiration: "",
			tokenExpired: false,
			refreshToken: "", // Удалить, только в cookie
		};
		this.options = {};
		this.filters = {};
		this.automation = {};
		this.oldRowValue = {};
		this.hasChanges = false;
		this.changedFields = [];
		this.requiredFieldsFilled = false;


		// TODO makeObservable не запускается jest, падает, в ошибку, узнать почему | Пока коментить перед запуском unt test.
		makeAutoObservable(this);
	}

	disposeAutorun() {
		if (this.disposer !== undefined) {
			this.disposer();
		}
		this.noNeedReaction = true;
	}
	
	editEntityRow = (id: string, key: string, value: string) => {
		if (dispatcher.entity.get()) {
			// this.getCurrentEntity()?.entity.rows.find((row: any) => row.id === id)[key] = value;
		}
	};

	addEntityRow = (content: any) => {
		if (dispatcher.entity.get()) {
			// @ts-ignore
			this.getCurrentEntity().entity.rows.push({ ...content, id: `${v4}-local-data` });
		}
	};

	removeEntityRow = (id: string) => {
		if (dispatcher.entity.get()) {
			// @ts-ignore
			delete this.getCurrentEntity()?.entity.rows.find((row: any) => row.id === id);
		}
	};

	addEntity = () => {
		// TODO Добавить возможность по добавлению сущностей | stopper backend
	};

	removeEntity = (entityId: string) => {
		// TODO Добавить возможность по удаленю сущностей | stopper backend
		store.entities = store.entities.filter((entity: any) => entity.id !== entityId);

	};

	/**
	 * Метод проверяет заполнены ли все обязательные поля
	 */
	checkRequiredFields() {
		const columns = dispatcher.entity.get()?.entity.columns;
		const row = dispatcher.currentRow.get()!;
	  
		if(columns && row){
			const allRequiredColumnsFilled = columns.every(column => {
				if (column.isRequired) {
					return !!row[LowFirst(column.columnName)];
				}
				return true;
			  });

			  this.requiredFieldsFilled = allRequiredColumnsFilled;			  
		}
	  }

	 /**
     * Метод для добавления измененного поля и его значения в массив.
     * @param {string} fieldName - Имя измененного поля.
     * @param {any} value - Значение, на которое изменилось поле.
     */
	setChangedField(fieldName:string, value:any) {
		 if (!isString(fieldName)) {
			 console.error("fieldName is not a string")
			 return;
		 }
		const columns = dispatcher.entity.get()?.entity.columns;
		const isLookupColumn = columns!.some((column) => column.columnName.toLowerCase() === fieldName.toLowerCase() && column.isLookup);
		if (isLookupColumn) {
			value = !isNull(value)? value.id: null;
		}

		const existingFieldIndex = this.changedFields.findIndex(field => field.fieldName === fieldName);
		if (existingFieldIndex > -1) {
			this.changedFields[existingFieldIndex].value = value;
		} else {
			this.changedFields.push({ fieldName, value });
		}

		this.checkRequiredFields();
	}

	/**
     * Метод для получения значения измененного поля по его имени.
     * @param {string} fieldName - Имя измененного поля.
     * @returns {any|undefined} Значение измененного поля или undefined, если поле не было изменено.
     */
	getChangedFieldValue(fieldName:string) {
		const changedField = this.changedFields.find(field => field.fieldName === fieldName);
		return changedField ? changedField.value : undefined;
	}

	 /**
     * Метод для сброса всех измененных полей.
     */
	resetChangedFields() {
		this.changedFields = [];
		this.checkRequiredFields();
	}
}

// usageExample

// store.subscribe.set(id);
// ||
// store.reaction.set(id, value);

export const store = new Store();