import Api from "api/http/api";

class Export extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/Export";
	}

	exportEntity = () => {
		this.setPath(`${this.basicPath}/export`);
		return this;
	};
}

const exportEntity = new Export();

export { exportEntity };