import { observer } from "mobx-react-lite";
import { useState, useEffect, useCallback, useMemo } from "react";

import DetailDropDown from "features/detail-drop-down/detail-drop-down";
import { usersCaption, UserColumns, UserColumnsForAdministration, UsersSettings, UserEnums, OrgRolesColumns } from "pages/settings/data/Fields";
import CheckBox from "components/check-box/check-box";
import DetailDialog from "components/dialog/detail-dialog/detail-dialog";
import Field from "components/field/field";
import DataGrid from "components/grid/data-grid";
import Input from "components/input/input";
import { InputStyleName } from "components/input/input-styles";
import { UserRoleType } from "features/tree-view/tree-view";

import functionalRolesMainPanelStore from "../FunctionalRolesMainPanelStore";
import { userStore } from "entities/user/UsersSectionStore";
import { rootUserRoles } from "entities/userRole/RootUserRoles";

import styles from "./../FunctionalRoles.module.css";


export const UsersTab = observer(function () {
    const [isOpenUserDialog, setOpenUserDialog] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState("");

    const isVisibleAddButton = useMemo(() => {
        return !functionalRolesMainPanelStore.userRole ? false : true
    }, [functionalRolesMainPanelStore.userRole])

    const createData = useCallback(() => {
        let newData = userStore.data.filter(elem => { if (functionalRolesMainPanelStore.filteredUserStore.filter(e => e.id === elem.id).length <= 0) return elem })
        newData = newData.map(e => {
            return {
                ...e,
                checked: false
            }
        })
        functionalRolesMainPanelStore.setValue(newData, "dialogData")
    }, [])

    const onChangeChecked = useCallback((value: boolean, data: any) => {
        const newData = functionalRolesMainPanelStore.dialogData;
        newData.filter(e => e.id === data.id)[0].checked = value;
        functionalRolesMainPanelStore.setValue(newData, "dialogData")
    }, [functionalRolesMainPanelStore.dialogData])

    const onSave = useCallback(() => {
        const rolesMas: string[] = [];
        rolesMas.push(functionalRolesMainPanelStore.userRole?.data?.id!);
        functionalRolesMainPanelStore.dialogData.forEach(e => {
            if (e.checked) functionalRolesMainPanelStore.addUserInRole(e.id, rolesMas);
        })
        setOpenUserDialog(false);
    }, [functionalRolesMainPanelStore.dialogData])

    const onCheckBoxChecked = useCallback((value: boolean) => {
        functionalRolesMainPanelStore.setValue(value, "LDAPsynchronize")
    }, [])
    const onAddClick = useCallback(() => {
        createData(); setOpenUserDialog(true)
    }, [])
    const onClose = useCallback(() => {
        setOpenUserDialog(false)
    }, [])

    useEffect(() => {
        if (functionalRolesMainPanelStore.userRole !== null)
            functionalRolesMainPanelStore.loadOrgRoles(functionalRolesMainPanelStore.userRole.data?.id!);
        createData();
    }, [])

    useEffect(() => {
        createData()
    }, [functionalRolesMainPanelStore.orgRolesStore])

    function search(s: string) {
        setSearchValue(s);
    }

    // TODO Сделать SearchSelect Элеменов LDAP
    return (
        <div className={styles.tabWrapper}>
            <Field name={""} caption={"Синхронизировать с LDAP"} className={styles.field} >
                <CheckBox
                    checked={functionalRolesMainPanelStore.LDAPsynchronize}
                    onChangeChecked={onCheckBoxChecked}
                />
            </Field>
            <Field name={""} caption={"Элемент LDAP"} className={styles.field} >
                <Input
                    value={""}
                    placeholder={""}
                    onChangeValue={() => { }}
                    inputStyle={InputStyleName.FullViewInput}
                    classNameParent={styles.fieldInput}
                    isDisabled={true}
                    isDisabledLocked={true}
                />

                {/* <SearchSelect
                    items={entity.lookups['LeadPriority']}
                    isInput={true}
                    onItemsLoad={async () => {
                        return await entity.loadLookups('LeadPriority', undefined, null, true);
                    }}
                    value={entity.entity.priority}
                    isInvalid={entity.entity.validation.priority.isInvalid}
                    invalidMessage={entity.entity.validation.priority.error}
                    onChangeValue={(value) => {
                        entity.entity.setValue(value!, "priority");
                        // validateRequired(value, leadEntity.entity.validation.priority,true);
                    }}
                    onFocusOut={() => validateRequired(entity.entity.priority, entity.entity.validation.priority, true)}
                    inputStyle={InputStyles.BaseWithoutBorder}
                    selectStyle={SelectStyles.QuickViewSelect}
                /> */}
            </Field>
            <DetailDropDown
                caption={usersCaption}
                classNameBody={styles.detailBody}
                onAddClick={onAddClick}
                isVisibleAddButton={isVisibleAddButton}
                isOpened={true}
                body={<DataGrid
                    columns={UserColumns}
                    otherData={functionalRolesMainPanelStore.filteredUserStore}
                    isCheckBox={true}
                    className={styles.grid}
                    classNameHeader={styles.gridHeader}
                />}
            />
            <DetailDialog
                columns={UserColumnsForAdministration}
                data={functionalRolesMainPanelStore.dialogData}
                onSave={onSave}
                onChangeChecked={onChangeChecked}
                searchValue={searchValue}
                search={search}
                isOpen={isOpenUserDialog}
                onClose={onClose}
            />

        </div>
    );
});
export const OrgStructure = observer(function () {
    const [isOpenOrgDialog, setOpenOrgDialog] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState("");

    const isVisibleAddButton = useMemo(() => {
        return !functionalRolesMainPanelStore.userRole ? false : true
    }, [functionalRolesMainPanelStore.userRole])

    const caption = useMemo(() => {
        return UsersSettings.filter(e => e.id === UserEnums.OrgStructure)[0].name
    }, [])

    const createData = useCallback(async () => {
        const orgRolesStore = await rootUserRoles.getRolesByType(UserRoleType.OrganizationRole);
        let newData = orgRolesStore.filter((elem: any) => {
            if (functionalRolesMainPanelStore.orgRolesStore.filter(e => e.id === elem.id).length <= 0) return elem
        })
        newData = newData.map((e: any) => {
            return {
                ...e,
                checked: false
            }
        })
        functionalRolesMainPanelStore.setValue(newData, "dialogData")
    }, [])
    const onChangeChecked = useCallback((value: boolean, data: any) => {
        const newData = functionalRolesMainPanelStore.dialogData;
        newData.filter(e => e.id === data.id)[0].checked = value;
        functionalRolesMainPanelStore.setValue(newData, "dialogData")
    }, [functionalRolesMainPanelStore.dialogData])

    const onSave = useCallback(() => {
        const rolesMas: string[] = [];
        functionalRolesMainPanelStore.dialogData.forEach(e => {
            if (e.checked) rolesMas.push(e.id)
        })
        functionalRolesMainPanelStore.linkFuncAndOrgRoles(functionalRolesMainPanelStore.userRole?.data?.id!, rolesMas)
        setOpenOrgDialog(false);
    }, [functionalRolesMainPanelStore.dialogData])

    const onClose = useCallback(() => {
        setOpenOrgDialog(false)
    }, [])
    const onAddClick = useCallback(() => {
        createData(); setOpenOrgDialog(true)
    }, [])

    useEffect(() => {
        if (functionalRolesMainPanelStore.userRole)
            functionalRolesMainPanelStore.loadOrgRoles(functionalRolesMainPanelStore.userRole.data?.id!);
        createData();
    }, [])

    useEffect(() => {
        createData()
    }, [functionalRolesMainPanelStore.orgRolesStore])


    function search(s: string) {
        setSearchValue(s);
    }

    return (
        <div className={styles.tabWrapper}>
            <DetailDropDown
                caption={caption}
                classNameBody={styles.detailBody}
                onAddClick={onAddClick}
                isVisibleAddButton={isVisibleAddButton}
                isOpened={true}
                body={<DataGrid
                    columns={OrgRolesColumns}
                    otherData={functionalRolesMainPanelStore.orgRolesStore}
                    isCheckBox={true}
                    className={styles.grid}
                    classNameHeader={styles.gridHeader}
                />}
            />
            <DetailDialog
                columns={OrgRolesColumns}
                data={functionalRolesMainPanelStore.dialogData}
                onSave={onSave}
                onChangeChecked={onChangeChecked}
                searchValue={searchValue}
                search={search}
                isOpen={isOpenOrgDialog}
                onClose={onClose}
            />
        </div>
    );
});
