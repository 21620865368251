import { observer } from "mobx-react-lite";

import { InputDateTime, InputWrapper } from "components/input-for-section-designer";

export type Props = {
	columnName: string | undefined;
	columnTitle?: string | undefined;
	prompt?: string | undefined;
	isRequired?: boolean | undefined;
	rounding?: string | undefined;
	value: string;
	isDisabled?: boolean;
	onChange: (value: string) => void;
}

export const DateTime = observer(function (props: Props) {
	return (
		<InputWrapper
			name={props.columnTitle ?? ""}
			caption={props.columnTitle ?? ""}
			required={props.isRequired}
			infoCaption={props.prompt}
			noBorder
		>
			<InputDateTime
				value={props.value}
				onChangeValue={props.onChange}
				isDisabled={props.isDisabled}
			/>
		</InputWrapper>
	);
});
