import { useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";

import { lowerCase } from "lodash";

import detailConfigurationStore, { DetailStoreField } from "../detail-configuration-store";
import { validateRequired } from "entities/Validation";

import {
    Button, ButtonStyle, Dropdown, Field, Input,
    InputStyleName, SelectStyleName, Text
} from "components";
import ToggleBlock from "features/toggle-block/toggle-block";

import { Item } from "types";

import { CloseMaxi } from "assets/icons";

import styles from "../detail-configuration.module.css";

const ExistObjectConfiguration = observer((props: { close: () => void, closeAll: () => void, onSave: () => void }) => {

    const handleObjectChange = useCallback((value: Item | null) => {
        detailConfigurationStore.setValue(DetailStoreField.newObjectForDetail, value);
    }, []);

    const handleObjectFocusOut = useCallback(() => {
        validateRequired(detailConfigurationStore.newObjectForDetail, detailConfigurationStore.validation.newObjectForDetail, true);
        detailConfigurationStore.validExistObjectDetail();
    }, []);

    const handleDetailHeaderChange = useCallback((value: string) => {
        detailConfigurationStore.setValue(DetailStoreField.newDetailTitle, value);
    }, []);

    const handleDetailHeaderFocusOut = useCallback(() => {
        validateRequired(detailConfigurationStore.newDetailTitle, detailConfigurationStore.validation.newDetailTitle);
        detailConfigurationStore.validExistObjectDetail();
    }, []);

    const handleCancel = useCallback(() => {
        if (detailConfigurationStore.hasChanges) {
            props.close();
        } else {
            props.closeAll();
        }
        detailConfigurationStore.resetExistObjectConfiguration();
    }, [props.close, props.closeAll]);

    const handleSave = useCallback(() => {
        detailConfigurationStore.saveByExists()
        validateRequired(detailConfigurationStore.detailTitle, detailConfigurationStore.validation.detailTitle);
        validateRequired(detailConfigurationStore.detail, detailConfigurationStore.validation.detail, true);
        props.close(); 
        props.onSave();
    }, [props.close]);

    const dialogFooterButtons = useMemo(() => {
        return (
            <>
                <Button
                    key="cancelButton"
                    caption="Отменить"
                    onClick={handleCancel}
                    style={ButtonStyle.Subtle}
                />
                <Button
                    key="saveButton"
                    caption="Сохранить"
                    onClick={handleSave}
                    style={ButtonStyle.Success}
                    isDisabled={detailConfigurationStore.isDidabledSaveExistObjButton}
                />
            </>
        );
    }, [detailConfigurationStore.isDidabledSaveExistObjButton]);

    const handleObjectItemsLoad = useCallback(async (value: string | null) => {
        try {
            await detailConfigurationStore.loadAllObjects();;
            if (detailConfigurationStore.objectList) {
                const items: Item[] = [];
                if (value) {
                    detailConfigurationStore.objectList.forEach((item: any) => {
                        const displayValue = lowerCase(item.displayValue);
                        if (displayValue.includes(lowerCase(value)))
                            items.push(item);
                    });
                    detailConfigurationStore.setValue(DetailStoreField.objectList, items);
                    return items.length
                }
                else return detailConfigurationStore.objectList.length
            }
            else return -1
        }
        catch (error) {
            console.error(error)
        }
    }, [detailConfigurationStore.objectList]);

    const dialogContent = useMemo(() => {
        return (
            <>
                <div className={styles.fieldType}>
                    <Text className={styles.popupNewDetailTitle}>
                        {detailConfigurationStore.header}
                    </Text>
                </div>
                <div className={styles.fieldsBlock}>
                    <Text className={styles.sectionTitle}>Основное</Text>
                    <div className={styles.fields}>
                        <Field
                            name="newObjectForDetail"
                            caption="Объект"
                            required
                            promptText='Список всех таблиц в системе '
                            className={styles.detailField}
                        >
                            <Dropdown
                                isInput
                                items={detailConfigurationStore.objectList}
                                value={detailConfigurationStore.newObjectForDetail}
                                onChangeValue={handleObjectChange}
                                onItemsLoad={handleObjectItemsLoad}
                                isInvalid={detailConfigurationStore.validation.newObjectForDetail.isInvalid}
                                invalidMessage={detailConfigurationStore.validation.newObjectForDetail.error}
                                onFocusOut={handleObjectFocusOut}
                                selectStyle={SelectStyleName.Base}
                            />
                        </Field>
                        <Field
                            name="newDetailTitle"
                            caption="Заголовок детали"
                            required
                            promptText='Название детали, которое будет отображаться на странице записи'
                            className={styles.detailField}
                        >
                            <Input
                                value={detailConfigurationStore.newDetailTitle}
                                placeholder=''
                                onChangeValue={handleDetailHeaderChange}
                                isInvalid={detailConfigurationStore.validation.newDetailTitle.isInvalid}
                                invalidMessage={detailConfigurationStore.validation.newDetailTitle.error}
                                onFocusOut={handleDetailHeaderFocusOut}
                                inputStyle={InputStyleName.Base}

                            />
                        </Field>
                    </div>
                    <ToggleBlock />
                </div>
            </>
        );
    }, [detailConfigurationStore.objectList, detailConfigurationStore.newObjectForDetail,
    detailConfigurationStore.newDetailTitle, detailConfigurationStore.header,
    detailConfigurationStore.validation.newDetailTitle.isInvalid, detailConfigurationStore.validation.newObjectForDetail.isInvalid]);

    return (
        <div className={styles.dialog}>
            <div className={styles.headerModal}>
                <span className={styles.titleModal}>Настройки новой детали</span>
                <CloseMaxi
                    className={styles.closeButton}
                    onClick={handleCancel}
                />
            </div>
            <div className={styles.dialogBody}>
                {dialogContent}
                <div className={styles.dialogFooter}>
                    {dialogFooterButtons}
                </div>
            </div>
        </div>
    );
});
export default ExistObjectConfiguration