import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";

import accessRightsStore from "../../access-rights-store";

import { Button, ButtonStyle, InputSearch, Select } from "components";

import { RightsItems, rightsItemsIds } from "entities/accessRights/AccessRightLinkStore";
import TreeView from "features/tree-view/tree-view";
import { Item } from "types";

import { CloseMaxi, TriangleToDown } from "assets/icons";

import styles from "./new-operation-item.module.css";


interface INewOperationItemProps {
    close: () => void
}

const NewOperationItem = observer((props: INewOperationItemProps) => {

    useEffect(() => {
        accessRightsStore.getUsers();
        accessRightsStore.setValue("selectedUser",null);
    }, []);

    const onUserClick = useCallback((user: any) => {
        accessRightsStore.setUser(user);
    }, []);

    const users = useMemo(() => {
        return accessRightsStore.userList.map((user, index) => {
            const userItemClassName = classNames(styles.userList,{
                [`${styles.userListSelected}`]: accessRightsStore.user?.id === user.id,
            });

            return (
                <span
                    key={index}
                    className={userItemClassName}
                    onClick={() => onUserClick(user)}>
                    {user.name}
                </span>
            )
        })
    }, [accessRightsStore.userList, accessRightsStore.user, accessRightsStore.authorUser, accessRightsStore.granteeUser]);

    const handleChangeValue = useCallback((value: Item | null) => {
        accessRightsStore.selectRole(value);
    }, []);

    const handleRoleOrUserClick = useCallback((userRole: any) => {
        accessRightsStore.setUserRole(userRole);
    }, []);

    const handleCancel = useCallback(() => {
        props.close();
        accessRightsStore.resetRoleOrUserConfiguration();
    }, [props.close]);

    const handleSave = useCallback(() => {
        accessRightsStore.save();
        props.close();
    }, [props.close]);

    return (

        <div className={styles.dialog}>
            <div className={styles.dialogHeader}>
                <span className={styles.title}>Выбрать роль и пользователя</span>
                <CloseMaxi className={styles.closeButton} onClick={handleCancel} />
            </div>
            <div className={styles.searchWrapper}>
                <InputSearch classNameInput={styles.search} className={styles.search} value={null} placeholder={"Поиск"} onChangeValue={(value: string) => { }} />
                <Select
                    name={accessRightsStore.currentSelectRole.name}
                    secondIcon={<TriangleToDown />}
                    styles={ButtonStyle.IndigoSelect}
                    isRotateSecondIcon
                    onChangeValue={handleChangeValue}
                    items={RightsItems}
                />
            </div>
            <div className={styles.divider} />
            <div className={styles.dialogBody}>
                {accessRightsStore.currentSelectRole.id !== rightsItemsIds.Users ?
                    <TreeView
                        type={accessRightsStore.userRoleType}
                        isOpen
                        onClick={handleRoleOrUserClick}
                        isHeader={false}
                        choosingItem={accessRightsStore.userRole}
                        isActionsVisible={false}
                    />
                    :
                    <>{users}</>
                }
            </div>
            <div className={styles.footerDivider} />
            <div className={styles.dialogFooter}>
                <Button
                    caption="Отменить"
                    onClick={handleCancel}
                    style={ButtonStyle.Subtle}
                />
                <Button
                    caption="Выбрать"
                    onClick={handleSave}
                    style={ButtonStyle.Primary}
                // isDisabled={newTabConfigurationStore.isDidabledSaveButton}
                />
            </div>
        </div>
    )
});

export default NewOperationItem;