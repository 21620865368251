import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";

import { getSectionPath } from "lib/get-section-path";
import { getListWithColumns } from "./lib";

import { InputWrapper } from "components/input-for-section-designer";
import { Select, SelectStyleName } from "components";

import { Item } from "types";

import reactorStyles from "module/reactor/components/features/field/field.module.css";
import s from './style.module.scss';
import { isString } from "lodash";

export type Props = {
    columnName: string | undefined;
    columnTitle?: string | undefined;
    prompt?: string | undefined;
    isRequired?: boolean | undefined;
    rounding?: string | undefined;
    value: Item;
    isDisabled?: boolean;
    onChange: (value: Item | null) => void;
    lookupTable?: string | null | undefined
}

export const AdapterSelect = observer(function (props: Props) {
    const [items, setItems] = useState<Array<Item>>([]);
    const [isLinkSelect, setIsLinkSelect] = useState(false);

    useEffect(() => {
        getListWithColumns(props.lookupTable ?? "").then(req => {
            setIsLinkSelect(req?.data.data.isSection);
            setItems(req.data.data.records.map((dataItem) => ({
                id: dataItem.id,
                name: dataItem.displayValue,
                displayValue: dataItem.displayValue,
            })));
        });
    }, [props.columnName, props.lookupTable, props.value]);

    return (
        <InputWrapper
            name={props.columnTitle ?? ""}
            caption={props.columnTitle ?? ""}
            required={props.isRequired}
            infoCaption={props.prompt}
            noBorder
        >
            <Select
                items={items}
                onChangeValue={props.onChange}
                placeholder=""
                isInput={!isLinkSelect}
                isDisabled={props.isDisabled}
                value={items.length > 0 ? props.value : null}
                inputLink={isLinkSelect ? `${getSectionPath(props.lookupTable ?? "")}/${props?.value?.id}` : undefined}
                linkTarget="_blank"
                selectStyle={SelectStyleName.BaseWithoutBorder}
                className={classNames(reactorStyles.fullViewBasicInformationDataInput, reactorStyles.input, s.container)}
            />
        </InputWrapper>
    );
});