import { makeAutoObservable } from "mobx";
import IImportStage from "../types/IImportStage";
import { IRequestStore, RequestStore } from "../types/Request";
import { SelectDataStage } from "./import-stages";
import IPropertyInfo from "entities/IPropertyInfo";
import * as signalR from "@microsoft/signalr";
import EntityImport from "../types/EntityImport";
import CacheItem from "components/lib/cache/cache";
import { IImportResult, ResultStates } from "../data/import-constants";
import { getSignalRUrl } from "app/services/UrlService";
import { api } from "api";
import { Item } from "types";

class ImportStore {
	file: File | null = null;
	stage: IImportStage = new SelectDataStage(this);
	result: IRequestStore = new RequestStore("");
	steps: number = 1;
	properties: IPropertyInfo[] = [];
	fileInfo: any[] = [];
	importResult: IImportResult | null = {
		state: ResultStates.NotStarted,
		percent: 0,
		importedRow: 0,
		rowCount: 0
	};
	entity: Item | null = null;
	entityCache: CacheItem<Item[]> = new CacheItem<Item[]>();
	entities: Item[] = [];
	isLoading: boolean = false;
	requiredFields: boolean = false;

	constructor() {
		makeAutoObservable(this);

		this.loadInfoOnFile = this.loadingDecorator(this.loadInfoOnFile);
		this.loadProperties = this.loadingDecorator(this.loadProperties);
		this.initSignalR = this.loadingDecorator(this.initSignalR);
	}

	async initSignalR() {
		let url = getSignalRUrl();
		const connection = new signalR.HubConnectionBuilder()
			.withUrl(`${url}/importhub`)
			.build();

		await connection.start();

		await connection.invoke("JoinRoom", this.file?.name);

		connection.on("Receive", (message: any) => {
			this.importResult = message;
		});
	}

	new() {
		this.file = null;
		this.steps = 1;
		this.importResult = null;
		this.stage = new SelectDataStage(this);
		this.result = new RequestStore("");
		this.entity = null;
		this.isLoading = false;
		this.entities = [];
		this.entityCache.clear();
	}

	async next() {
		await this.stage.nextStage();
		this.steps++;
	}

	back() {
		this.stage.backStage();
		this.steps--;
	}

	setEntity(entity: Item | null) {
		if (entity === null) this.result.setTable("");
		else this.result.setTable(entity.name);
		this.entity = entity;
	}

	setFile(file: File) {
		importStore.file = file;
	}


	async loadInfoOnFile() {
		try {
			if (this.file) {
				let formData = new FormData();
				formData.append("file", this.file!);
				let response = await api.http.httpApi.importFile.importInfo().post(formData);

				console.log("import/info response", response);
				this.fileInfo = response.data.cellsInFile;
			}
		}
		catch (error) {
			console.error(error)
		}
	}

	async startImport() {
		let formData = new FormData();
		formData.append("file", this.file!);
		formData.append("json", JSON.stringify(this.result));
		await api.http.httpApi.importFile.startImport().post(formData);
	}

	async loadProperties() {
		// let response = await Api.post("/api/entity/entityinfo", { EntityName: this.result.tableName });
		let response = await api.http.httpApi.entity.entityInfo().post({ EntityName: this.result.tableName });
		this.properties = response.data.data.entityInfoResultItems;
	}

	async loadTables(value: string | null) {
		const cacheValue = this.entityCache.getValue();

		if (cacheValue == null) {
			// let response = await Api.get("/api/entity/entitydatalist");
			let response = await api.http.httpApi.entity.entityDataList().get();
			if (response.data.success) {
				this.entities = response.data.data.map((d: EntityImport) => { return { name: d.entityName, displayValue: d.entityTitle, id: 0 }; });
				this.entityCache.setValue(this.entities, 1000);
				if (value !== "") this.entities = this.entities.filter(entity => entity.displayValue!.includes(value ?? ""));
			}
		}
		else {
			if (value !== "") this.entities = cacheValue.filter(entity => entity.displayValue!.includes(value ?? ""));
		}

	}

	async loadRequiredSetting() {
		// let response = await Api.get("/api/Import/getImportSysSettings");
		let response = await api.http.httpApi.importFile.getImportSysSettings().get();
		this.requiredFields = response.data.data;
		console.log("getImportSysSettings", response.data);
	}

	loadingDecorator(func: (...args: any[]) => void) {
		return async (...args: any[]) => {
			this.isLoading = true;
			const result = await func.apply(this, args);
			this.isLoading = false;
			return result;
		};
	}
}


const importStore = new ImportStore();

export { ImportStore };
export default importStore;