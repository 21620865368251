import FilterColumnType from "entities/ColumnType";
import SectionRoute from "entities/SectionRoute";
import { IQuickViewFilld, QuickViewFilldType } from "features/quick-view-block/constants/constants";

export const columns = [
    {
        name: "stage",
        caption: "Стадия",
        width: 15,
        type: FilterColumnType.Stage,
        sortColumn: "stage.order"

    },
    {
        name: "title",
        caption: "Название",
        width: 20,
        type: FilterColumnType.String,
        getLink: (id: string) => `${id}`,
        sortColumn: "title"
    },
    {
        name: "priority",
        caption: "Приоритет",
        width: 9,
        type: FilterColumnType.Lookup,
        sortColumn: "priority.order"
    },
    {
        name: "needType",
        caption: "Тип потребности",
        width: 15,
        type: FilterColumnType.Lookup,
        sortColumn: "needType.name"
    },
    {
        name: "name",
        caption: "ФИО",
        width: 15,
        type: FilterColumnType.String,
        sortColumn: "name"
    },
    {
        name: "owner",
        caption: "Ответственный",
        width: 15,
        type: FilterColumnType.Owner,
        getLink: (id: string) => `/${SectionRoute.User}/${id}`,
        sortColumn: "owner.name"
    },
    {
        name: "createdOn",
        caption: "Дата создания",
        width: 11,
        type: FilterColumnType.Date,
        sortColumn: "createdOn"
    },

];

const fields: IQuickViewFilld[] = [
    {
        type: QuickViewFilldType.SelectFiltering,
        name: "contact",
        lookupName: "Contact",
        caption: "Контакт",
        required: true
    },
    {
        type: QuickViewFilldType.Select,
        name: "sourceReceipt",
        lookupName: "SourceReceipt",
        caption: "Источник поступления",
        required: true
    },
    {
        type: QuickViewFilldType.Select,
        name: "needType",
        lookupName: "NeedType",
        caption: "Тип потребности",
        required: true
    },
    {
        type: QuickViewFilldType.SelectFiltering,
        name: "owner",
        lookupName: "User",
        caption: "Ответственный",
        required: false
    },
    {
        type: QuickViewFilldType.Note,
        name: "notes",
        caption: "Заметки",
        isNew: true
    }
]
export const infoFields: IQuickViewFilld[] = [
    {
        type: QuickViewFilldType.SelectFiltering,
        name: "priority",
        lookupName: "SalePriority",
        caption: "Приоритет",
        required: true,
        isSorted: true
    },
    // {
    //     type: QuickViewFilldType.Select,
    //     name: "stage",
    //     lookupName: "SaleStage",
    //     caption: "Стадия",
    //     required: true,
    //     isSorted: true
    // },
    {
        name: "createdOn",
        caption: "Дата создания",
        type: QuickViewFilldType.Date,
        isDisabled: true
    },
    {
        name: "modifiedOn",
        caption: "Дата изменения",
        type: QuickViewFilldType.Date,
        isDisabled: true
    },
    // {
    //     name: "createdBy",
    //     caption: "Автор",
    // },
]
export default fields;