import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { v4 } from "uuid";

import { selector } from "store";

import { StageActions, StageActionProps } from "./stage-actions";

import { AddStage, DeleteIcon, DragIconStage, EyeHidden, EyeNotHidden } from "assets/icons";

import styles from "components/stage/stage-actions.module.scss";


/**
 * @description Создание карточки стадии
 * @param props.stageActionProps массив действий стадии
 * @param props.isError есть ли ошибка в стадии
 * @param props.name название стадии
 * @param props.color основной цвет стадии из палитры
 * @param props.isVisibleDelete параметр, отвечающий за видимость кнопки удаления стадии
 * @param props.isVisiblyHide параметр, отвечающий за видимость кнопки сокрытия стадии
 * @param props.onHidden функция происходит по клику на скрытие карточки
 * @param props.onDelete функция происходит по клику на удаление карточки
 * @param props.onAdd функция происходит по клику на добавление карточки
 * @param props.className накладываемый поверх стиль
 * @param props.height настраиваемая высота
 * @param props.onClick функция происходит по клику на карточку
 * @param props.id id
 * @param props.singleViewStage Включает полупрозрачность 50% для кейса со всеми скрытыми
 * @param props.selectedStageId id выбранной стадии
 * @returns
 */
export function Stage(props: {
    stageActionProps: StageActionProps[],
    isError?: boolean,
    [key: string]: any
}) {

    const [id] = useState<string>(v4())

    const stageClasses = classNames(`${styles.stageWrapper}`, {
        [`${styles.selectedStageWrapper}`]: props.selectedStageId === props.id,
        [`${props.className}`]: props.className
    });

    const stageHeaderClasses = classNames(`${styles.header}`, {
        [`${styles.headerError}`]:  !props.name.length  || selector.stageModels.getAll().filter(stage => stage.name === props.name).length > 1 || props.isError
   });

    useEffect(() => {
        document.getElementById(id)?.style.setProperty('--original-color', props.color)
    }, [props.color])




    //TODO Функция для изменения цвета с использованием формулы
    const modifyColor = (color: string, channel: 'red' | 'green' | 'blue', amount: number): string => {
        const rgb = color.match(/\d+/g);

        if (rgb) {
            const modifiedColor = rgb.map((val, index) =>
                index === ['red', 'green', 'blue'].indexOf(channel) ? String(Number(val) + amount) : val
            );
            return `rgb(${modifiedColor.join(', ')})`;
        }
        return color;
    };

    const rgbToHex = (rgb: string): string => {
        /** @description Разбиваем строку RGB на числа */
        const [r, g, b] = rgb.match(/\d+/g)?.map(Number) || [];

        /** @description Преобразуем числа в формат HEX */
        const hex = (value: number): string => {
            const hexValue = value.toString(16);
            return hexValue.length === 1 ? `0${hexValue}` : hexValue;
        };

        /** @description Собираем строку HEX */
        const hexColor = `#${hex(r)}${hex(g)}${hex(b)}`;

        return hexColor;
    };

    //TODO закомментировали функции, т.к по ним ожидается решение, а пока хардкод тестовые данные
    // const borderColor = props.color;
    // const iconsColor = rgbToHex(modifyColor(props.color, 'red', -60));
    // const textColor = rgbToHex(modifyColor(props.color, 'green', 100));
    // const backgroundColor = rgbToHex(modifyColor(props.color, 'red', 120));    

    const styleWrap = useMemo(() => {
        return {
            height: `${props.height}px`,
            opacity: props.singleViewStage ? "0.5" : 1
        }
    }, [props.height, props.singleViewStage]);

    return (
        <div id={id} className={stageClasses} style={styleWrap} onClick={props.onClick}>
            <div className={stageHeaderClasses} >
                <div className={styles.iconTextWrapper}>
                    <DragIconStage className={styles.headerIcon} />
                    <span className={styles.headerText}>{props.name}</span>
                </div>
                <div className={styles.iconsWrapper}>
                    {!props.singleViewStage && props.isVisiblyHide && (
                        props.hidden ? <EyeHidden className={styles.stageIcon} onClick={(event) => {
                            event.stopPropagation();
                            props.onView()
                        }} /> :
                            <EyeNotHidden className={styles.stageIcon} onClick={(event) => {
                                event.stopPropagation();
                                props.onHidden()
                            }} />
                    )}
                    {!props.singleViewStage && props.isVisibleDelete &&
                        <DeleteIcon className={styles.stageIcon} onClick={props.onDelete} />}
                    {!props.singleViewStage && <div className={styles.add}><AddStage onClick={(event) => {
                        event.stopPropagation();
                        props.onAdd()
                    }} /></div>}
                </div>
            </div>
            <hr />
            <div className={styles.stageBody}>
                <div className={styles.stageActionsWrapper}>
                    <StageActions stageActionProps={props.stageActionProps} />
                </div>
            </div>
        </div>
    );
};
