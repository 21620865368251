import {PersonIcon } from "assets/icons";
import { Account } from "assets/icons";
import { PersonWithCart } from "assets/icons";
import {  Cart } from "assets/icons";
import { filterTableType } from "./FilterTable";

function getEntityIcons(name:string){
    switch(name){
        case filterTableType.Contact:
            return <PersonIcon/>;
        case filterTableType.Account:
            return <Account/>;
        case filterTableType.Lead:
            return <PersonWithCart/>;
        case filterTableType.Sale:
            return <Cart/>;
        default:
            return <></>;
    }
    
}

export default getEntityIcons;