import { makeAutoObservable } from "mobx";
import { v4 } from "uuid";
import { api } from "api";

import { IGridItem } from "components/select/types";
import { DetailConfig, GridItem } from "types/entity";
import { synchroiser } from "synchroiser";


class DetailMasterState {
    detailInfo: any = {}
    gridItems: Array<GridItem> = []
    detailTitle: string = ''
    existColumns: Array<IGridItem> = []

    boxFields: Array<GridItem> = []

    constructor() {
        makeAutoObservable(this);
    }

    setValue(fieldName: string, value: string | Array<GridItem> | Array<IGridItem>) {
        Reflect.set(this, fieldName, value);
    }

    isBoxField(columnId: string) {
        return this.boxFields.find(field => field.fieldConfig?.columnId === columnId) ? true : false
    };

    async loadEditData(entity: string) {
        try {
            const detailInfo = await synchroiser.getDetailConfig(entity);
            this.detailInfo = detailInfo;
            if (detailInfo) {
                this.detailTitle = detailInfo?.detailConfig?.detailTitle!;
                this.gridItems = detailInfo?.detailConfig?.inner?.items ?? [];
                const columns = detailInfo?.columnsInfo;
                let resultColumns: Array<IGridItem> = [];
                columns.forEach((column: any) => {
                    const newColumn: IGridItem = {
                        gridItemId: v4(),
                        columnId: column.columnId,
                        name: column.columnName,
                        displayValue: column.columnTitle,
                        columnType: column.columnType,
                    }
                    resultColumns.push(newColumn);
                });
                this.existColumns = resultColumns;
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    createExistFields() {
        const exists: Array<IGridItem> = [];
        this.gridItems.forEach(item => {
            const newItem: IGridItem = {
                gridItemId: item.gridItemId,
                columnId: item.fieldConfig?.columnId,
                columnType: item.fieldConfig?.columnType,
                name: item.fieldConfig?.columnName!,
                displayValue: item.fieldConfig?.columnTitle!,
            }
            if (!exists.find(existItem => existItem.columnId === item.fieldConfig?.columnId)) {
                exists.push(newItem);
            }
        })
        this.existColumns = exists;
    };

    setBoxFields(items: GridItem[]) {
        this.boxFields = items;
    };

    loadCreateData(detailConfig: DetailConfig) {
        if (detailConfig) {
            this.detailTitle = detailConfig.detailTitle;
            this.gridItems = detailConfig.inner?.items ?? [];
        }
        this.createExistFields();
    };

    saveNewField(newItem: GridItem, existField?: IGridItem) {
        let newItems: GridItem[] = [];
        if (this.gridItems) {
            newItems = this.gridItems;
        }
        newItems.push(newItem);
        this.gridItems = newItems;

        if (existField) {
            let newExists: IGridItem[] = [];
            if (this.existColumns) {
                newExists = this.existColumns;
            }
            newExists.push(existField);
            this.existColumns = newExists;
        }
    }

    /*
    * Метод скрывает поле с холста при редактировании существующей детали
    */
    hideGridItem(gridItemId: string) {
        let findedIndex = -1;
        const finded = this.gridItems.find((item, index) => {
            if (item.gridItemId === gridItemId) {
                findedIndex = index;
                return item;
            }
        });
        if (finded && findedIndex != -1) {
            const newItem: GridItem = {
                ...finded,
                x: -1,
                y: 0
            }
            this.gridItems[findedIndex] = newItem;
        }
    }

    /*
    * Метод удаляет поле из конфига при создании новой детали
    */
    deleteGridItem(gridItemId: string) {
        const newItemsWithoutCurrentGridItem = this.gridItems.filter(item => item.gridItemId !== gridItemId);
        this.gridItems = newItemsWithoutCurrentGridItem;
    }

    updateGridItem(newItem: GridItem) {
        const findedIndex = this.gridItems.findIndex(item => item.gridItemId === newItem.gridItemId);
        if (findedIndex !== -1) {
            this.gridItems[findedIndex] = newItem;
        }
    }

    updateFieldConfig(newItem: GridItem) {
        const findedItems = this.gridItems.filter(item => item.fieldConfig?.columnId === newItem.fieldConfig?.columnId);
        if (findedItems.length > 0) {
            findedItems.forEach(item => {
                item.fieldConfig = newItem.fieldConfig
            })
        }
    }

    createDuplicateGridItem = (gridItem: GridItem): void => {
        const duplicateGridItem = {
            ...gridItem,
            gridItemId: v4()
        };
        let newItems = this.gridItems;
        newItems.push(duplicateGridItem)
        this.gridItems = newItems;
    };

    saveExistField(newItem: GridItem) {
        if (this.gridItems.find(item => item.fieldConfig?.columnId === newItem.fieldConfig?.columnId && (item.x > 0 && item.y > 0))) {
            this.createDuplicateGridItem(newItem)
        } else {
            this.updateGridItem(newItem)
        }
    };

    async updateDetail() {
        const data = {
            detailName: this.detailInfo.detailConfig.detailName,
            detailTitle: this.detailInfo.detailConfig.detailTitle,
            detailColumn: this.detailInfo.detailConfig.detailColumn,
            entityColumn: this.detailInfo.detailConfig.entityColumn,
            inner: {
                items: this.gridItems
            },
            entityName: this.detailInfo.detailConfig.entityName,
            virtualDetail: null
        };
        const response = await api.http.httpApi.systemDetailDesigner.updateSystemDetailDesigner(this.detailInfo.detailConfig.entityName).put(data);
        return response?.data?.success;
    }
}

const detailMasterState = new DetailMasterState();

export default detailMasterState;
