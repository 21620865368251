import ListStore from "entities/ListStore";



const salesSchema = "Sale";
const saleStagesSchema = "SaleStage";
const salePrioritySchema = "SalePriority";

export const saletStore = new ListStore(salesSchema);
export const saleStageStore = new ListStore(saleStagesSchema);
export const salePriorityStore = new ListStore(salePrioritySchema);