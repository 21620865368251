import { OptionPage } from "types/entity";

export enum MenuHeaders {
    SectionSettings = "sectionSettings",
    StagesConstructor = "stagesConstructor",
    KanbanSettings = "kanbanSettings",
}

export const Menu = [
    {
        id: MenuHeaders.SectionSettings,
        name: "Настройка раздела"
    },
    {
        id: MenuHeaders.StagesConstructor,
        name: "Конструктор стадий"
    },
    {
        id: MenuHeaders.KanbanSettings,
        name: "Настройки доски kanban"
    },

];

export const KanbanSettings = [
    {
        id: OptionPage.QuickView,
        name: 'Быстрый просмотр'
    },
    {
        id: OptionPage.CardSettings,
        name: 'Настройка карточки'
    },

];

