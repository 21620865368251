import debounce from "components/lib/debounce/debounce";
import { observer } from "mobx-react";
import { CSSProperties, useEffect, useState } from "react";

import { Button, ButtonStyle, CustomGrid, ICustomColumn, InputSearch, Modal, RadioButton, WarningDialog } from "components";
import Column from "./components/column/column";
import Header from "./components/header/header";
import ProgressBar from "./components/progress-bar/progress-bar";

import { IFormat } from "entities/IItem";
import exportStore, { ResultStates } from "./core/export-store";
import ListStore from "entities/ListStore";

import { CloseMaxi } from "assets/icons";

import styles from "./export-block.module.css";
import { Entity } from "store";

/**
 * Интерфейс блока экспорта
 * 
 * @interface IExportBlock
 * @property {string} pageTitle - Заголовок раздела, для которого происходит экспорт
 * @property {string} schema - Название таблицы по которой происходит экспорт
 * @property {ListStore | undefined} listStore - Устаревший store для старых разделов. Передается для того чтобы достать из него includes, excludes записи и фильтр
 * @property {IFormat[]} formats - Массив форматов для экспорта
 * @property {string} title - Заголовок блока экспорта
 * @property {number} choosingCount - Количество выбранных записей для экспорта
 * @property {number} allCount - Общее количество записей
 * @property {string | undefined} className - CSS-классы для блока экспорта
 * @property {CSSProperties | undefined} style - Стили для блока экспорта
 * @property {boolean} isOpen - Флаг, указывающий на то, открыт ли блок экспорта
 * @property {boolean | undefined} close - Флаг, указывающий на то, нужно ли отображать кнопку закрытия блока экспорта
 * @property {() => void} onClick - Функция-обработчик клика по блоку экспорта
 */
interface IExportBlock {
    pageTitle: string;
    schema: string;
    listStore: ListStore | undefined;
    entity?:Entity;
    formats: IFormat[];
    title: string;
    choosingCount: number;
    allCount: number;
    className?: string;
    style?: CSSProperties;
    isOpen: boolean;
    close?: boolean;
    onClick: () => void;
}
const start = debounce((entity?:Entity) => {
    exportStore.startExport(entity);
}, 100);

const ExportBlock = observer(function (props: IExportBlock) {
    const [openedWarningDialog, setOpenedWarningDialog] = useState(false);

    useEffect(() => {
        if (props.isOpen) {
             init();
        }       
    }, []);

    if (!props.isOpen) {
        return null;
    }

    let className = `${styles.wrapper} `;

    if (props.className) className += props.className;

    function init() {
        exportStore.setValue(props.formats, "formats");
        exportStore.setValue(props.schema, "schema");
        const initColumns = async () => { await exportStore.initColumnsByEntity(); };
        initColumns();
        exportStore.setValue(props.listStore, "listStore");
        exportStore.setValue(props.pageTitle, "pageTitle");
    }

    async function startExport() {
        exportStore.setValue(
            { state: ResultStates.Progress, percent: 0 },
            "exportResult"
        );
        await start(props.entity);
    }

    function drawPanel() {
        if (exportStore.exportResult?.state === ResultStates.NotStarted) {
            return (
                <Modal>
                    <div
                        className={styles.backdrop}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    />
                    <div
                        className={className}
                        style={props.style}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <div className={styles.header}>
                            <span className={styles.title}>{props.title}</span>
                            {props.close ? null : (
                                <CloseMaxi
                                    className={styles.closeButton}
                                    onClick={() => setOpenedWarningDialog(true)}
                                />
                            )}
                        </div>
                        <span className={styles.count}>
                            Количество записей для экспорта: {props.choosingCount} из{" "}
                            {props.allCount}
                        </span>
                        <div className={styles.dialogBody}>
                            <BodyExport />
                            <div className={styles.dialogFooter}>
                                <Button
                                    caption={"Отменить"}
                                    onClick={() => setOpenedWarningDialog(true)}
                                    style={ButtonStyle.Subtle}
                                />
                                <Button
                                    caption={"Экспортировать"}
                                    onClick={startExport}
                                    style={ButtonStyle.Primary}
                                    isDisabled={exportStore.checkedColumns.length < 1}
                                />
                            </div>
                        </div>
                    </div>
                    <WarningDialog
                        value={
                            "Вы действительно хотите отменить экспорт?\nТекущий прогресс будет утерян."
                        }
                        valueReturn={"Вернуться к экспорту"}
                        valueDelete={"Отменить"}
                        isOpen={openedWarningDialog}
                        onBackClick={() => setOpenedWarningDialog(false)}
                        onCancelClick={() => {
                            setOpenedWarningDialog(false);
                            exportStore.clearCheckedColumns();
                            props.onClick();
                        }}
                    />
                </Modal>
            );
        } else if (exportStore.exportResult?.state === ResultStates.Progress) {
            return (
                <Modal>
                    <div
                        className={styles.backdrop}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    />
                    <div className={className} style={props.style}>
                        <ProgressBar percent={exportStore.exportResult?.percent!} />
                    </div>
                </Modal>
            );
        } else {
            setTimeout(() => {
                props.onClick();
            }, 1000);
            exportStore.stopSignalR();
            exportStore.setValue(
                { state: ResultStates.NotStarted, percent: 0 },
                "exportResult"
            );
            init();
        }
    }

    return <>{drawPanel()}</>;
});

export default ExportBlock;

const BodyExport = observer(function () {
    return (
        <div className={styles.bodyExport}>
            <LeftBlockExport />
            <VerticalDivider />
            <RightBlockExport />
        </div>
    );
});

const LeftBlockExport = observer(function () {
    return (
        <div className={styles.leftBlockExport}>
            <span className={styles.titleBodyBlock}>Формат файла</span>
            <RadioButton elements={exportStore.formats} />
        </div>
    );
});
const VerticalDivider = observer(function () {
    return <div className={styles.divider} />;
});
const RightBlockExport = observer(function () {
    let [searchValue, setSearchValue] = useState("");
    let [checkedAll, setCheckedAll] = useState(false);

    function onChangeAll(value: boolean) {
        setCheckedAll(value);
        exportStore.clearCheckedColumns();
        if (value) exportStore.setCheckedAll();
    }
    function onChange(value: any) {

        let elem = exportStore.columns.filter((e) => e.columnName === value.column)[0];
        if (value.value) {
            exportStore.checkedColumns.push(elem);
        } else {
            let index = exportStore.checkedColumns.indexOf(elem);
            if (index !== -1) {
                exportStore.checkedColumns.splice(index, 1);
            }
        }
    }

    let columnTitles: ICustomColumn[] = [
        {
            name: "columnName",
            caption: "",
            width: "48px",
            head: <Header onChangeAll={onChangeAll} />,
            onChange: onChange,
            render: (column: ICustomColumn, value: any) => {
                return <Column column={column} value={value} checkedAll={checkedAll} />;
            },
        },
        {
            name: "columnTitle",
            caption: "Колонки в разделе [" + exportStore.pageTitle + "]",
        },
    ];

    function search(s: string) {
        setSearchValue(s);
    }

    return (
        <div className={styles.rightBlockExport}>
            <span className={styles.titleBodyBlock}>Выберите необходимые поля</span>
            <InputSearch
                placeholder="Поиск"
                value={searchValue}
                onChangeValue={search}
                classNameInput={styles.search}
            />
            <CustomGrid
                columns={columnTitles}
                datas={exportStore.columns}
                className={styles.grid}
            />
        </div>
    );
});
