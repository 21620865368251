// import api from "app/services/api";
import { api } from "api";
import { action, makeObservable, observable } from "mobx";

import { ISelectItem } from "pages/import/data/import-constants";


export interface IDeduplicationRule {
    entity: string;
    id?: string;
    keyColumn: string;
    idKeyColumn?: string;
    displayValue?: string;
    value?: any;
}

class DeduplicationRulesFuncs {
	rulesList: IDeduplicationRule[];
	rowsList: ISelectItem[];
	rule: IDeduplicationRule;
	checkedList: string[];
	checkedAll: boolean;
	isDisableDeleteButton: boolean;

	constructor() {
		makeObservable(this, {
			rulesList: observable,
			rowsList: observable,
			rule: observable,
			checkedList: observable,
			checkedAll: observable,
			isDisableDeleteButton: observable,
			setValue: action,
		});

		this.rulesList = [];
		this.rowsList = [];
		this.rule = { entity: "", keyColumn: "" };
		this.checkedList = [];
		this.checkedAll = false;
		this.isDisableDeleteButton = true;
	}

	setValue(value: boolean | any[], fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	async createDeduplRule(rule: IDeduplicationRule) {
		// await api.post("/api/DeduplicationSettings/create", rule);
		await api.http.httpApi.deduplicationSetting.createSetting().post(rule);
	}

	async getEntitySections() {
		try {
			// let entitySections = await api.get("/api/Entity/entitysectiondatalist").then(res => res.data);
			const response =  await api.http.httpApi.entity.entitylookupdatalist().get();
			let entitySections = response.data;
			this.rowsList = entitySections.map((sec: any) => { return { value: sec, displayValue: sec.entityTitle, id: sec.id }; });

			this.rulesList.forEach(rule => {
				entitySections.forEach((table: any) => {
					if (table.id === rule.entity) {
						rule.value = table;
						rule.displayValue = table.entityTitle;
					}
				});
			}
			);
		}
		catch (error: any) {
			var message = error;
			console.error("An error occurred:", error);
		}
	}

	async loadDeduplicationRulesList() {
		try {
			// let response = await api.get("/api/DeduplicationSettings/list").then(res => res.data);
			let response = await api.http.httpApi.deduplicationSetting.settingsList().get();

			if (response.data) {
				this.rulesList = response.data;
				await this.getEntitySections();
			}
		} catch (error: any) {
			console.error("An error occurred:", error);
		}
	}

	async updateRules(rule: IDeduplicationRule) {
		try {
			let ruleToUpdate = {
				id: rule.id,
				entity: rule.entity,
				keyColumn: rule.keyColumn,
			};
			// let response = await api.post("/api/DeduplicationSettings/update", ruleToUpdate);
			let response = await api.http.httpApi.deduplicationSetting.updateSetting().post(ruleToUpdate);

			if (response) {
				console.log(response, "reesponse");
			}
		}
		catch (error: any) {
			var message = error;
			console.error("An error occurred:", error);
		}
	}

	async deleteRules(rules: string[]) {
		try {
			let response;
			for (let i = 0; i < rules.length; i++) {
				// response = await api.delete(`/api/DeduplicationSettings/delete?id=${rules[i]}`);
				response = await api.http.httpApi.deduplicationSetting.deleteSetting(rules[i]).delete();
			}
		} catch (error: any) {
			var message = error;
			console.error("An error occurred:", error);
		}
	}
}

const deduplicationRulesFuncs = new DeduplicationRulesFuncs();

export default deduplicationRulesFuncs;