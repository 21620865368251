import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { v4 } from "uuid";

import accessRightsStore from "../../access-rights-store";

import NewOperationItem from "../../access-rights-admin-operation/new-operation-item/new-operation-item";
import { modalController } from "features/modals";

import { Button, ButtonStyle, Field, Input } from "components";

import { CloseMaxi } from "assets/icons";

import styles from "./new-record-item.module.css";

interface INewRecordItemProps {
    close: () => void
}

const NewRecordItem = observer((props: INewRecordItemProps) => {
    const [idModal] = useState<string>(v4());

    const closeFuncWithOutConfirm = useCallback(() => {
        modalController.modalRemove(idModal);
    }, []);

    const handleAuthorClick = useCallback(() => {
        accessRightsStore.setValue("isGranteeAdd",false);
        accessRightsStore.setValue("isAuthorAdd",true);
       
        modalController.popupAdd({
            id: idModal, 
            layout: <NewOperationItem close={closeFuncWithOutConfirm} />, 
            closeFunc: closeFuncWithOutConfirm
        });
    }, []);

    const handleGranteeClick = useCallback(() => {
        accessRightsStore.setValue("isAuthorAdd",false);
        accessRightsStore.setValue("isGranteeAdd",true);

        modalController.popupAdd({
            id: idModal, 
            layout: <NewOperationItem close={closeFuncWithOutConfirm} />, 
            closeFunc: closeFuncWithOutConfirm
        });
    }, []);

    const handleCancel = useCallback(() => {
        props.close();
        accessRightsStore.resetRecordsConfiguration();
    }, [props.close]);

    const handleSave = useCallback(() => {
        accessRightsStore.saveAccessRightByRecords();
        props.close();
    }, [props.close]);
   
    return (
        <div className={styles.dialog}>
            <div className={styles.dialogHeader}>
                <span className={styles.title}>Добавить права</span>
                <CloseMaxi className={styles.closeButton} onClick={handleCancel} />
            </div>
            <div className={styles.dialogBody}>
                <Field name="author" caption="Автор записи" className={styles.field}>
                    <Input
                        placeholder='Выберите значение...'
                        className={styles.input}
                        value={accessRightsStore.authorUser ? accessRightsStore.authorUser.name : (accessRightsStore.authorRole && accessRightsStore.authorRole.data) ? accessRightsStore.authorRole.data.name : null}
                        onClick={handleAuthorClick}
                        onChangeValue={(value: string) => { }}
                    />
                </Field>

                <Field name="grantee" caption="Получатель прав" className={styles.field}>
                    <Input
                        placeholder='Выберите значение...'
                        className={styles.input}
                        value={accessRightsStore.granteeUser ? accessRightsStore.granteeUser.name : (accessRightsStore.granteeRole && accessRightsStore.granteeRole.data) ? accessRightsStore.granteeRole.data.name : null}
                        onClick={handleGranteeClick}
                        onChangeValue={(value: string) => { }}
                    />
                </Field>
            </div>
            <div className={styles.dialogFooter}>
                <Button
                    caption="Отменить"
                    onClick={handleCancel}
                    style={ButtonStyle.Subtle}
                />
                <Button
                    caption="Выбрать"
                    onClick={handleSave}
                    style={ButtonStyle.Primary}
                // isDisabled={newTabConfigurationStore.isDidabledSaveButton}
                />
            </div>
        </div>
    )
});

export default NewRecordItem;