import { action, makeObservable, observable, runInAction } from "mobx";

import { UserRoleType } from "features/tree-view/tree-view";

// import api from 'app/services/api';
import UserRole from "./UserRole";
import { api } from "api";

export class RootUserRoles {
	rootUserRoles: UserRole[];

	constructor() {

		makeObservable(this, {
			rootUserRoles: observable,
			getTreeOfOrganizationRoles: action,
			getTreeOFunctionalRoles: action,
		});

		this.rootUserRoles = [];

	}
	async loadByType(roleType: UserRoleType) {
		if (roleType === UserRoleType.FunctionalRole)
			this.getTreeOFunctionalRoles();
		else this.getTreeOfOrganizationRoles();
	}

	async getTreeOfOrganizationRoles() {
		// let request = await api.get("/api/Administration/getTreeOfOrganizationRoles");
		const response =  await api.http.httpApi.administration.getTreeOfOrganizationRoles().get();
		let request = response.data;
		runInAction(() => {
			if (request) {
				this.rootUserRoles = request.rootUserRoles;
			}
		});
	}
	async getTreeOFunctionalRoles() {
		// let request = await api.get("/api/Administration/getTreeOfFunctionalRoles");
		const response =  await api.http.httpApi.administration.getTreeOfFunctionalRoles().get();
		let request = response.data;
		runInAction(() => {
			if (request) {
				this.rootUserRoles = request.rootUserRoles;
			}
		});
	}

	async addFolder(data: any) {
		// let request = await api.post("/api/Administration/roles", data);
		const resopnse = await api.http.httpApi.administration.roles().post(data);
		let request= resopnse.data;
		if (request) {
			await this.loadByType(data.roleType);
			return true;
		}
		else return false;
	}
	async renameFolder(data: any, type: UserRoleType) {
		// let request = await api.put("/api/Administration/roles", data);
		let request = await api.http.httpApi.administration.roles().put(data);
		if (request) {
			await this.loadByType(type);
			return true;
		}
		else return false;
	}
	async deleteFolder(id: string, type: UserRoleType) {
		// let request = await api.delete(`/api/Administration/roles?id=${id}`);
		let request = await api.http.httpApi.administration.roles(id).delete();
		if (request) {
			await this.loadByType(type);
			return true;
		}
		else return false;
	}

	async getRolesByType(type: UserRoleType) {
		// let request = await api.get(`/api/Administration/roles?userRoleType=${type}`);
		let request = await api.http.httpApi.administration.getRolesByType(type).get();
		if (request) {
			return request.data;
		}
		else return null;
	}

	async createLeaderRole(name: string, roleType: UserRoleType, childRoleId: string) {
		let data = {
			name: name,
			roleType: roleType,
			childRoleId: childRoleId
		};
		// let request = await api.post("/api/Administration/leadRoles", data);
		let request = await api.http.httpApi.administration.leadRoles().post(data);
		if (request) {
			await this.loadByType(roleType);
			return true;
		}
		else return false;
	}
	async deleteLeaderRole(id: string, roleType: UserRoleType) {
		// let request = await api.delete(`/api/Administration/deleteLeadRole?id=${id}`);
		let request =  await api.http.httpApi.administration.deleteLeadRole(id).delete();
		if (request) {
			await this.loadByType(roleType);
			return true;
		}
		else return false;
	}

}


export const rootUserRoles = new RootUserRoles();

export default RootUserRoles;