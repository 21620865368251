import React, { CSSProperties, useCallback, useState } from "react";
import { observer } from "mobx-react-lite";

import { store } from "../model/store";

import styles from "./style.module.css";

type Props = {
    id: string;
    hasMove: boolean;
    elementCenter: {
        x: number;
        y: number;
    };
    setTargetZone: (id: string | null) => void;
    setTargetCell: ({ x, y }: { x: number, y: number }) => void;
	setCenterElement: ({ x, y }: { x: number, y: number }) => void;
    moveElement: () => void;
	setSelectedElement: (value: string | null) => void;
	setHasMove: (value: boolean) => void;
	apiMethodOnDrop?: () => void;
	bufferedElementId: string | null;
	predictLayout?: JSX.Element;
}

const Layer = observer((function (props: Props) {
	const [predictElementStyles, setPredictElementStyles] = useState<CSSProperties>({ position: "absolute", left: 0, top: 0 });

	const handleMouseUp = useCallback(() => {
		props.moveElement();
		store.resetSelectedElement();
		store.setHasMove(false);
		props.setSelectedElement(null);
		props.setHasMove(false);
		props.setTargetCell({ x: -1, y: -1 });
		if (store._targetZoneId) {
			props.apiMethodOnDrop?.();
		}
	}, [props]);

	const handleMouseMove = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
		props.setCenterElement({ x: event.pageX, y: event.pageY });
		const targetZoneId = store.getZone({ x: event.pageX, y: event.pageY });

		if (targetZoneId) {
			const zone = store._zones[targetZoneId];
			props.setTargetZone(targetZoneId);
			if (zone.config.cellSize) {
				let cellX = -1;
				let cellY = -1;

				setPredictElementStyles({
					position: "absolute",
					left: `${event.pageX}px`,
					top: `${event.pageY}px`,
				});

				store._maskCells.sort((a, b) => {
					if (a.endY - a.startY > b.endY - b.startY) {
						return 1;
					} else if (a.endY - a.startY < b.endY - b.startY) {
						return -1;
					} else {
						return 0;
					}}).find((mask) => {
					if (
						mask.startX < event.pageX && event.pageX < mask.endX
						&&
						mask.startY < event.pageY && event.pageY < mask.endY
					) {
						// @ts-ignore
						if (store.zones[targetZoneId].config.width === store.zones[targetZoneId].elements[props.bufferedElementId || ""]?.size?.width) {
							cellX = 0;
						} else {
							cellX = mask.x;
						}
						cellY = mask.y;

						return true;
					}
				});

				// const cellX = Math.floor((event.pageX - zone.size.startX) / zone.config.cellSize.width);
				// const cellY = Math.floor((event.pageY - zone.size.startY) / zone.config.cellSize.height);
				props.setTargetCell({ x: cellX, y: cellY });
			}
			return true;
		} else {
			props.setTargetCell({ x: -1, y: -1 });
			props.setTargetZone(null);
		}

	}, [props, store._zones]);

	if (props.hasMove) {
		return (
			<div onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} className={styles.layer}>
				<div style={{ width: "100%", height: "100vh", position: "fixed", top: 0, left: 0, zIndex: 1 }} />
				{props.bufferedElementId ? <div style={predictElementStyles}>{props.predictLayout}</div> : <></>}
			</div>
		);
	}
	return <></>;
}));

export { Layer };