import { AddTag, AddToGroup, ChangeDisplay, DeleteIcon } from "assets/icons";

const actions = [
    {
        id: 1,
        name: 'Изменить отображение',
        icon: <ChangeDisplay />,
        isLocked: true,
        isSeparator: true,
    },
    {
        id: 2,
        name: 'Добавить в группу',
        icon: <AddToGroup />,
        isLocked: true,
    },
    {
        id: 3,
        name: 'Добавить тег',
        icon: (
            <AddTag
                style={{
                    width: `17px`,
                    height: `17px`,
                }}
            />
        ),
        isLocked: true,
        isSeparator: true,
    },
    {
        id: 4,
        name: 'Удалить запись',
        icon: (
            <DeleteIcon
                style={{
                    width: `17px`,
                    height: `17px`,
                }}
            />
        ),
        isLocked: true,
    },

]

export default actions;
