import { useState } from "react";
import DatePicker from "react-datepicker";

import classNames from "classnames";

import { DateIcon, Locked } from "assets/icons";

import styles from "components/input-for-section-designer/input.module.scss";
import "react-datepicker/dist/react-datepicker.css";

export const InputDate = (props:{[className: string]: any }) => {
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());

	const dateInputWrapper = classNames(styles.dateInputWrapper,
		{ 
			[`${props.className}`]: props.className, 
			[`${styles.parentLockedTime} ${styles.dateInputWrapper}`]: props.isDisabled, 
		}
	);

	const dateInput = classNames(styles.inputDate,
		{ [`${styles.dateLocked}`]: props.isDisabled, }
	);

	return (
		<div {...props} className={dateInputWrapper}>
			<DatePicker
				selected={selectedDate}
				onChange={(date: Date) => setSelectedDate(date)}
				dateFormat="dd.MM.yyyy"
				className={dateInput}
				disabled={props.isDisabled}
				open={false}
			/>
			{props.isDisabled ? <Locked className={styles.iconResult} />:<div className={styles.iconWraper}><DateIcon/></div>}

		</div>
	);
};

