import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";

import { synchroiser } from "synchroiser";
import { store } from "store";

import { ICardData, Kanban, Grid, Field, SearchSelect, InputStyleName, SelectStyleName, InputNumber, itemToLookup, Button, Dialog, ButtonStyle } from "components";
import SectionHead from "features/section-head/section-head";
import { QuickViewFilldType } from "features/quick-view-block/constants/constants";

import saleEntity, { Sale } from "entities/sale/Sale";
import { validateRequired } from "entities/Validation";
import { salePriorityStore, saleStageStore, saletStore } from "entities/sale/SalesSectionStore";
import { columns, fields, infoFields } from "./data/Fields";
import Entity, { IEntityStore } from "entities/Entity";
import leadEntity from "entities/lead/lead";
import contactEntity, { Contact } from "entities/contact/Contact";
import { Account as AccountFilling, default as accountEntity } from "entities/account/Account";
import { AccountDialogLeads } from "pages/accounts/accounts/accounts";
import { ContactsDialogLeads } from "pages/contacts/contacts/contacts";
import { Item } from "types";

import styles from "./sales.module.css";


const Sales = () => {

	const [entity, setEntity] = useState<Entity<IEntityStore>>();

	useEffect(() => {
		setEntity(new Entity<Sale>(new Sale()));
	}, []);

	useEffect(() => {
		synchroiser.switchSection("sale");
	}, [store.sections]);

	function getCardsData(data: any[]): ICardData[] {
		return data.map((item) => ({
			schema: "Sale",
			id: item.id,
			stageId: item.stage.id,
			position: item.position,
			priorityOrder: item.priority.order,
			priority: item.priority.name,
			priorityColor: item.priority.color,
			cardTitle: item.name,
			cardItems: [
				{
					caption: item?.salesAmount?.toLocaleString("ru-RU") + " ₽"
				},
				{
					caption: item?.account?.name
				},

			],
			createdOn: item.createdOn,
			modifiedOn: item.modifiedOn,
			owner: item.owner.name
		}));
	};

	const handleChangeNew = useCallback(() => {
		entity?.new(new Sale());
	}, []);

	return (
		entity ?
			<SectionHead
				pageTitle={"Продажи"}
				kanban={<Kanban
					entity={saleEntity}
					field={fields}
					infoFields={infoFields}
					stageField={{
						type: QuickViewFilldType.Select,
						name: "stage",
						lookupName: "SaleStage",
						caption: "Стадия",
						required: true,
						isSorted: true
					}}
					listStore={saletStore}
					getCardsData={getCardsData}
					listStagesStore={saleStageStore}
					listPriorityStore={salePriorityStore}
					stages={saleStageStore.data.slice().sort((a, b) => { return a.order - b.order; })}
				/>}
				grid={
					<Grid
						columns={columns}
						listStagesStore={saleStageStore}
						listStore={saletStore}
						entity={saleEntity}
					/>}
				priorityFilter={true}
				priorityStore={salePriorityStore}
				listStore={saletStore}
				entity={entity}
				dialogTitle={"Новая продажа"}
				columns={columns}
				dialogContent={<SalesDialogFilling entity={entity} />}
				new={handleChangeNew}
			/>
			:
			<></>
	);
};

export const SalesDialogFilling = observer((props: { entity: Entity<IEntityStore> }) => {
	let entity = props.entity as Entity<Sale>;

	return (
		<>
			{entity.isNew &&
                <>
                	<div className={styles.combination}>
                		<Field key={"priority"} name={"priority"} caption={"Приоритет"} required={true} className={styles.combinationInput}>
                			<SearchSelect
                				items={entity.lookups["SalePriority"]}
                				onChangeValue={(value) => {
                					entity.entity.setValue(value!, "priority");
                				}}
                				isInput={true}
                				onItemsLoad={async () => {
                					return await entity.loadLookups("SalePriority", undefined, null, true);
                				}}
                				value={entity.entity.priority}
                				isInvalid={entity.entity.validation.priority.isInvalid}
                				invalidMessage={entity.entity.validation.priority.error}
                				onFocusOut={() => validateRequired(entity.entity.priority, entity.entity.validation.priority, true)}
                				inputStyle={InputStyleName.BaseWithoutBorder}
                				selectStyle={SelectStyleName.Base}
                			/>

                		</Field>

                		<Field key={"stage"} name={"stage"} caption={"Стадия"} required={true} className={styles.combinationInput}>
                			<SearchSelect
                				items={entity.lookups["SaleStage"]}
                				onChangeValue={(value) => {
                					entity.entity.setValue(value!, "stage");
                				}}
                				isInput={true}
                				onItemsLoad={async () => {
                					return await entity.loadLookups("SaleStage", undefined, null, true);
                				}}
                				value={entity.entity.stage}
                				isInvalid={entity.entity.validation.stage.isInvalid}
                				invalidMessage={entity.entity.validation.stage.error}
                				onFocusOut={() => validateRequired(entity.entity.stage, entity.entity.validation.stage, true)}
                				inputStyle={InputStyleName.BaseWithoutBorder}
                				selectStyle={SelectStyleName.Base}
                			/>
                		</Field>
                	</div>
                	<Field key={"account"} name={"account"} caption={"Контрагент"} required={true}>
                		<SearchSelect
                			items={entity.lookups["Account"]}
                			isInput={true}
                			onItemsLoad={async (s) => {
                				return await entity.loadLookups("Account", s, null, false);
                			}}
                			value={entity.entity.account as Item}
                			className={styles.select}
                			onChangeValue={(value: any) => {
                				entity.entity.setValue(value, "account");
                			}}
                			isInvalid={entity.entity.validation.account.isInvalid}
                			invalidMessage={entity.entity.validation.account.error}
                			onFocusOut={() => validateRequired(entity.entity.account, entity.entity.validation.account, true, true)}
                			inputStyle={InputStyleName.BaseWithoutBorder}
                			selectStyle={SelectStyleName.Base}
                		/>
                	</Field>

                	<Field key={"contact"} name={"contact"} caption={"Контакт"} required={true}>

                		<SearchSelect
                			items={entity.lookups["Contact"]}
                			isInput={true}
                			onItemsLoad={async (s) => {
                				return await entity.loadLookups("Contact", s, null, false);
                			}}
                			value={entity.entity.contact as Item}
                			className={styles.select}
                			onChangeValue={(value: any) => {
                				entity.entity.setValue(value, "contact");
                			}}
                			isInvalid={entity.entity.validation.contact.isInvalid}
                			invalidMessage={entity.entity.validation.contact.error}
                			onFocusOut={() => validateRequired(entity.entity.contact, entity.entity.validation.contact, true, true)}
                			inputStyle={InputStyleName.BaseWithoutBorder}
                			selectStyle={SelectStyleName.Base}
                		/>
                	</Field>

                	<Field key={"needType"} name={"needType"} caption={"Тип потребности"} required={true}>
                		<SearchSelect
                			items={entity.lookups["NeedType"]}
                			onChangeValue={(value) => {
                				entity.entity.setValue(value!, "needType");
                			}}
                			isInput={true}
                			onItemsLoad={async () => { return await entity.loadLookups("NeedType", undefined); }}
                			value={entity.entity.needType}
                			isInvalid={entity.entity.validation.needType.isInvalid}
                			invalidMessage={entity.entity.validation.needType.error}
                			onFocusOut={() => validateRequired(entity.entity.needType, entity.entity.validation.needType, true)}
                			inputStyle={InputStyleName.BaseWithoutBorder}
                			selectStyle={SelectStyleName.Base}
                		/>

                	</Field>

                	<Field key={"salesAmount"} name={"salesAmount"} caption={"Сумма продажи"}>
                		<InputNumber
                			value={entity.entity.salesAmount}
                			placeholder={""}
                			onChangeValue={(value: string) => {
                				entity.entity.setValue(value, "salesAmount");
                			}}
                			isBaseInput={true}
                		/>
                	</Field>

                	<Field key={"owner"} name={"owner"} caption={"Ответственный"} required={true}>
                		<SearchSelect
                			items={entity.lookups["User"]}
                			isInput={true}
                			onItemsLoad={async (s) => {
                				return await entity.loadLookups("User", s, null, false);
                			}}
                			value={entity.entity.owner as Item}
                			className={styles.select}
                			onChangeValue={(value: any) => {
                				entity.entity.setValue(value, "owner");
                			}}
                			isInvalid={entity.entity.validation.owner.isInvalid}
                			invalidMessage={entity.entity.validation.owner.error}
                			onFocusOut={() => validateRequired(entity.entity.owner, entity.entity.validation.owner, true, true)}
                			inputStyle={InputStyleName.BaseWithoutBorder}
                			selectStyle={SelectStyleName.Base}
                		/>
                	</Field>
                </>
			}
		</>
	);

});

interface ILeadToSaleData {
    schema: string,
    id: string,
    entity: Entity<IEntityStore>,
}

export const LeadToSale = observer((props: ILeadToSaleData) => {
	const [isContactValue, setContactValue] = useState(false);
	const [isAccountValue, setAccountValue] = useState(false);
	let account = saleEntity.lookups["Account"] ?? [];
	let users = saleEntity.lookups["User"] ?? [];
	let contact = saleEntity.lookups["Contact"] ?? [];
	let lead = saleEntity.lookups["Lead"] ?? [];

	useEffect(() => {
		load();
		setSaleField();
	}, [props.id]);

	const setSaleField = async () => {
		saleEntity.new(new Sale());
		await saleEntity.loadLookups("SalePriority", "Средний", null, true);
		saleEntity.entity.setValue(saleEntity.lookups["SalePriority"][0], "priority");
		await saleEntity.loadLookups("SaleStage", "Зафиксировать цели", null, true);
		saleEntity.entity.setValue(saleEntity.lookups["SaleStage"][0], "stage");
		await saleEntity.loadLookups("Lead", undefined, null);
		saleEntity.entity.setValue(leadEntity.entity, "lead");
		await saleEntity.loadLookups("Account", undefined, null);
		saleEntity.entity.setValue(accountEntity.entity.name, "account");
		await saleEntity.loadLookups("Contact", undefined, null);
		saleEntity.entity.setValue(leadEntity.entity.contact!, "contact");
		await saleEntity.loadLookups("NeedType", undefined, null);
		saleEntity.entity.setValue(leadEntity.entity.needType!, "needType");
		await saleEntity.loadLookups("User", undefined, null);
		saleEntity.entity.setValue(leadEntity.entity.owner!, "owner");
	};
	const load = async () => {
		await saleEntity.load(props.id);
		await leadEntity.load(props.id);
		await contactEntity.load(props.id);
		await accountEntity.load(props.id);
	};

	const saveAccount = async () => {
		await accountEntity.save();
		if (accountEntity.isSaved) {
			saleEntity.entity.setValue(accountEntity.entity, "account");
		}
	};

	const saveContact = async () => {
		let saved = await contactEntity.save();
		if (saved) {
			saleEntity.entity.setValue(contactEntity.entity, "contact");
		}
	};

	const handleClickCreate = useCallback(() => {
		accountEntity.new(new AccountFilling());
	}, []);

	const handleClickCreateLater = useCallback(() => {
		accountEntity.cancelChanges();
	}, []);
	const handleClickSave = useCallback(async () => {
		await saveAccount();
	}, []);

	return (
		<>
			<div className={styles.combination}>
				<Field key={"priority"} name={"priority"} caption={"Приоритет"} required={true} className={styles.combinationInput}>
					<SearchSelect
						items={saleEntity.lookups["SalePriority"]}
						isInput={true}
						onItemsLoad={async () => {
							return await saleEntity.loadLookups("SalePriority", undefined, null, true);
						}}
						onChangeValue={(value) => {
							saleEntity.entity.setValue(value!, "priority");
							validateRequired(value, saleEntity.entity.validation.priority);
						}}
						value={saleEntity.entity.priority}
						isInvalid={saleEntity.entity.validation.priority.isInvalid}
						isLightning={true}
						onFocusOut={() => validateRequired(saleEntity.entity.priority, saleEntity.entity.validation.priority)}
						inputStyle={InputStyleName.BaseWithoutBorder}
						selectStyle={SelectStyleName.Base}
					/>

				</Field>

				<Field key={"stage"} name={"stage"} caption={"Стадия"} required={true} className={styles.combinationInput}>
					<SearchSelect
						items={saleEntity.lookups["SaleStage"]}
						onChangeValue={(value) => {
							saleEntity.entity.setValue(value!, "stage");
							validateRequired(value, saleEntity.entity.validation.stage);
						}}
						isInput={true}
						onItemsLoad={async () => {
							return await saleEntity.loadLookups("SaleStage", undefined, null, true);
						}}
						value={saleEntity.entity.stage}
						isInvalid={saleEntity.entity.validation.stage.isInvalid}
						isLightning={true}
						onFocusOut={() => validateRequired(saleEntity.entity.stage, saleEntity.entity.validation.stage)}
						inputStyle={InputStyleName.BaseWithoutBorder}
						selectStyle={SelectStyleName.Base}
					/>
				</Field>
			</div>
			<Field key={"lead"} name={"lead"} caption={"Лид"}>
				<SearchSelect
					items={lead}
					onChangeValue={(value) => {
						saleEntity.entity.setValue(itemToLookup(value!), "lead");
					}}
					isInput={true}
					onItemsLoad={async (s) => {
						return await saleEntity.loadLookups("Lead", s);
					}}
					value={saleEntity.entity.lead}
					isLightning={true}
					onFocusOut={() => validateRequired(saleEntity.entity.lead, saleEntity.entity.validation.lead)}
					inputStyle={InputStyleName.BaseWithoutBorder}
					selectStyle={SelectStyleName.Base}
				/>
			</Field>
			<Field key={"account"} name={"account"} caption={"Контрагент"} required={true}>
				<SearchSelect
					items={account}
					onChangeValue={(value) => {
						saleEntity.entity.setValue(itemToLookup(value!), "account");
						validateRequired(value, saleEntity.entity.validation.account);
					}}
					isInput={true}
					onItemsLoad={async (s) => {
						s && setAccountValue(true);
						return await saleEntity.loadLookups("Account", s);
					}}
					value={saleEntity.entity.account}
					isLightning={true}
					onFocusOut={() => validateRequired(saleEntity.entity.account, saleEntity.entity.validation.account)}
					inputStyle={InputStyleName.BaseWithoutBorder}
					selectStyle={SelectStyleName.Base}
				/>
				<>
					{isAccountValue ? <Button caption={"Добавить контрагент..."} className={styles.add} onClick={handleClickCreate} /> : null}
					{<Dialog title={"Новый контрагент"} isOpen={accountEntity.openedDialog} close={true} dialogFooterButtons={
						<>
							<Button caption={"Создать позднее"} onClick={handleClickCreateLater} style={ButtonStyle.Subtle} />
							<Button caption={"Сохранить"} onClick={handleClickSave} style={ButtonStyle.Success} />
						</>
					}>
						<AccountDialogLeads entity={accountEntity} />
					</Dialog>}
				</>
			</Field>

			<Field key={"contact"} name={"contact"} caption={"Контакт"} required={true}>
				<SearchSelect
					items={contact}
					onChangeValue={(value) => {
						saleEntity.entity.setValue(itemToLookup(value!), "contact");
						validateRequired(value, saleEntity.entity.validation.contact);
					}}
					isInput={true}
					onItemsLoad={async (s) => {
						s && setContactValue(true);
						return await saleEntity.loadLookups("Contact", s);
					}}
					value={saleEntity.entity.contact}
					isLightning={true}
					onFocusOut={() => validateRequired(saleEntity.entity.contact, saleEntity.entity.validation.contact)}
					inputStyle={InputStyleName.BaseWithoutBorder}
					selectStyle={SelectStyleName.Base}
				/>
				<>
					{isContactValue ? <Button caption={"Добавить контакт..."} className={styles.add} onClick={() => { contactEntity.new(new Contact()); }} /> : null}
					{
						<Dialog title={"Новый контакт"} isOpen={contactEntity.openedDialog} close={true} dialogFooterButtons={
							<>
								<Button caption={"Создать позднее"} onClick={() => { contactEntity.cancelChanges(); }} style={ButtonStyle.Subtle} />
								<Button caption={"Сохранить"} onClick={async () => { await saveContact(); }} style={ButtonStyle.Success} />
							</>
						}>
							<ContactsDialogLeads entity={contactEntity} />
						</Dialog>
					}
				</>
			</Field>


			<Field key={"needType"} name={"needType"} caption={"Тип потребности"} required={true}>
				<SearchSelect
					items={saleEntity.lookups["NeedType"]}
					onChangeValue={(value) => {
						saleEntity.entity.setValue(value!, "needType");
						validateRequired(value, saleEntity.entity.validation.needType);
					}}
					isInput={true}
					onItemsLoad={async () => { return await saleEntity.loadLookups("NeedType", undefined); }}
					value={saleEntity.entity.needType}
					isInvalid={saleEntity.entity.validation.needType.isInvalid}
					isLightning={true}
					onFocusOut={() => validateRequired(saleEntity.entity.needType, saleEntity.entity.validation.needType)}
					inputStyle={InputStyleName.BaseWithoutBorder}
					selectStyle={SelectStyleName.Base}
				/>
			</Field>

			<Field key={"salesAmount"} name={"salesAmount"} caption={"Сумма продажи"}>
				<InputNumber
					value={saleEntity.entity.salesAmount}
					placeholder={""}
					onChangeValue={(value: string) => {
						saleEntity.entity.setValue(value, "salesAmount");
					}}
					isBaseInput={true}
					isLightning={true}
				/>
			</Field>

			<Field key={"owner"} name={"owner"} caption={"Ответственный"} required={true}>
				<SearchSelect
					items={users}
					onChangeValue={(value) => {
						saleEntity.entity.setValue(itemToLookup(value!), "owner");
						validateRequired(value, saleEntity.entity.validation.owner);
					}}
					isInput={true}
					onItemsLoad={async (s) => {
						return await saleEntity.loadLookups("User", s);
					}}
					value={saleEntity.entity.owner}
					isLightning={true}
					onFocusOut={() => validateRequired(saleEntity.entity.owner, saleEntity.entity.validation.owner)}
					inputStyle={InputStyleName.BaseWithoutBorder}
					selectStyle={SelectStyleName.Base}
				/>
			</Field>
		</>

	);
});



export default Sales;
