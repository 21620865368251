import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useState } from "react";

import { Button, ButtonStyle, Modal, DataGrid, InputSearch, Select} from '../../../components'

import WarningDialog from "../warning-dialog/warning-dialog";

import { Item } from "types";

import { ArrowToDownMini, CloseMaxi } from "assets/icons";

import styles from "./detail-dialog.module.css";


const DetailDialog = observer(function (props: {
    isOpen: boolean,
    data: any[],
    columns: any[],
    onSave: () => void,
    onClose: () => void,
    onChangeChecked: (value: boolean, data: any) => void,
    searchValue: string,
    search: (s: string) => void
}) {
    const [isOpenWarningDialog, setOpenWarningDialog] = useState<boolean>(false);

    // TODO Прикрутить actions в деталь
    const actionsOfView = useMemo(() => {
        return [
            // {
            //     id: ActionsIds.Unite,
            //     name: "Объединить записи",
            //     icon: <Unite />,
            //     isLocked: props.listStore?.countOfChecked! < 2 ? true : false
            // },

        ]
    }, [])
    const isDisabled = useMemo(() => {
        return !(props.data.filter(e => e.checked).length > 0)
    }, [props.data.filter(e => e.checked).length])

    const onClickAction = useCallback((item: Item | null) => {

    }, [])

    const onClickBackdropClose = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
    }, [])

    const onClickClose = useCallback(() => {
        if (props.data.filter(e => e.checked === true).length > 0)
            setOpenWarningDialog(true);
        else props.onClose();
    }, [props.data, props.onClose])

    const onSaveClick = useCallback(() => {
        props.onSave();
    }, [])

    const onBackClick = useCallback(() => {
        setOpenWarningDialog(false)
    }, [])
    const cancel = useCallback(() => {
        setOpenWarningDialog(false);
        props.onClose();
    }, [])

    if (!props.isOpen) {
        return null;
    }

    return (
        <Modal>
            <div className={styles.backdrop} onClick={onClickBackdropClose} />
            <div className={styles.dialog} >
                <div className={styles.header}>
                    <span className={styles.title}>Выбор: Объект администрирования</span>
                    <Button firstIcon={<CloseMaxi className={styles.closeButton} />} style={ButtonStyle.Icon} onClick={onClickClose} />
                </div>
                <div className={styles.headerDialog}>
                    <div className={styles.dialogLeftHeader}>
                        <Button
                            caption={'Добавить отмеченные'}
                            style={ButtonStyle.Primary}
                            isDisabled={isDisabled}
                            className={styles.button}
                            onClick={onSaveClick}
                        />
                        <Button
                            caption={'Отменить'}
                            style={ButtonStyle.Subtle}
                            className={styles.button}
                            onClick={onClickClose}

                        />
                    </div>
                    <div className={styles.dialogRightHeader}>
                        <Select
                            name={'Вид'}
                            items={actionsOfView}
                            onChangeValue={onClickAction}
                            secondIcon={<ArrowToDownMini />}
                            isRotateSecondIcon={true}
                            styles={ButtonStyle.Link}
                            classNameButton={styles.actionButton}
                        />
                    </div>
                </div>
                <div className={styles.searchBlock}>
                    <InputSearch placeholder="Поиск" value={props.searchValue} onChangeValue={props.search} classNameInput={styles.searchData} />
                    <div className={styles.rightSearchBlock}>
                        <span className={styles.rowCountLabel}>Выбрано:</span>
                        <span className={styles.rowCountValue}>{0}</span>
                    </div>
                </div>
                <div className={styles.dialogBody}>
                    <DataGrid
                        columns={props.columns}
                        otherData={props.data}
                        isCheckBox={true}
                        onChangeChecked={props.onChangeChecked}
                        className={styles.grid}
                        classNameHeader={styles.gridHeader}
                    />
                </div>
                <WarningDialog
                    value={"Имеются несохраненные изменения. "}
                    valueReturn={"Вернуться к редактированию"}
                    valueDelete={"Выйти без сохранения"}
                    isOpen={isOpenWarningDialog}
                    onBackClick={onBackClick}
                    onCancelClick={cancel}
                />
            </div>

        </Modal >
    );
});

export default DetailDialog;