import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { dispatcher, selector, store } from "store";
import { synchroiser } from "synchroiser";

import Reactor from "module/reactor/Reactor";

import { DetailCard } from "../detail-card/detail-card";
import SkeletonAccountFullView from "pages/accounts/accountFullView/skeleton/SkeletonAccountFullView";
import { NotFoundPage } from "pages/error-page/error-page";

import { LoadingState } from "types/entity";

export const DetailSectionCard = observer(() => {    
    const location = useLocation();
    
    useEffect(() => {
        const loadRowAsync = async () => {
            if (location?.state?.detailEntityName && location.state.detailRowId) {
                await synchroiser.getRow(location?.state?.detailEntityName, location.state.detailRowId);
            }
        }
        loadRowAsync();
    }, []);

    useEffect(() => {
        if (location?.state?.isDetail && location?.state?.isSectionDetail && synchroiser.loadingState != LoadingState.Loading) {
            dispatcher.fullView.switchEntityAndRow(location?.state?.detailEntityName, location.state.detailRowId);
        }
    }, [location.state]);

    if (!location.state) {
        return null;
    }
   
    const sectionWizzard = useMemo(() => dispatcher.entity.get()?.entity.sectionWizzard, [dispatcher.entity.get()?.entity.sectionWizzard, store.currentEntityId]);    

    const xmlParser = useMemo(() => selector.fullView.getFullViewXml(sectionWizzard,"func:synchroiser:updateRowWithoutRedirect"), [sectionWizzard]);

    if (location?.state?.isDetail && !location?.state?.isSectionDetail) {
        return (<DetailCard />)
    }

    /* TODO мб сохранить первоначальный 
    результат парсера в reactorPage у sectionWizzard, отбражать его, потом сравнить пришедший и старый, 
    чтобы скелетон не скакал при переходе из детали обратно в запись раздела */
    if ((!location.state?.detail && !sectionWizzard && synchroiser.loadingState == LoadingState.Loading) || synchroiser.loadingState == LoadingState.Loading || isEmpty(xmlParser)) {
        return (<SkeletonAccountFullView />);
    }


    if (synchroiser.loadingState == LoadingState.Error) {
        return (<NotFoundPage />);
    }

    return (<Reactor xml={(xmlParser)} />);
});
