import { useEffect } from "react";
import { observer } from "mobx-react";
import {  useParams } from "react-router-dom";

import FullView from "features/full-view/full-view";
import Field from "components/field/field";
import HorizontalField from "components/field/horizontal-field";
import InputPhone from "components/input/input-phone";
import Notes from "components/notes/notes";
import Select from "components/select/select";
import Input from "components/input/input";
import SkeletonAccountFullView from "./skeleton/SkeletonAccountFullView";
import SearchSelect from "components/select/search-select/search-select";

import { dispatcher, store } from "store";
import { synchroiser } from "synchroiser";

import { SelectStyleName } from "components/select/select-styles";
import { InputStyleName } from "components/input/input-styles";
import accountEntity, { Account } from "entities/account/Account";
import Entity from "entities/Entity";
import { validateEmail, validatePhoneNumber, validateRequired } from "entities/Validation";
import contactEntity, { Contact } from "entities/contact/Contact";
import SectionRoute from "entities/SectionRoute";
import { NotFoundPage } from "pages/error-page/error-page";

import { NotesIcon } from "assets/icons";
import { Address } from "assets/icons";
import { MeansOfCommunication } from "assets/icons";

import styles from "./AccountFullView.module.css";


const AccountFullView = observer(() => {
	const contact = useParams<{ id: string }>();

	useEffect(() => {
		accountEntity.load(contact.id!);

		return () => {
			dispatcher.currentRow.reset();
		};
	}, []);

	useEffect(() => {
		dispatcher.currentRow.switch(contact.id!);
		if (!store.currentEntityId) {
			synchroiser.switchSection("accounts");
		}
		synchroiser.getComments();
	}, [store.sections]);

	if (accountEntity.isLoading) {
		return (<SkeletonAccountFullView />);
	}

	if (!accountEntity.entity.id) return (<NotFoundPage/>);

	return (
		<FullView entity={accountEntity}
			mainInformation={
				{
					title: accountEntity.entity.name,
					mainInformationDataLeft: <MainInformationDataLeft entity={accountEntity} />,
					mainInformationDataRight: <MainInformationDataRight entity={accountEntity} />
				}
			}
			mainInformationDetails={MainInformationDetails(accountEntity)}
		/>
	);
});

const MainInformationDetails = ((entity: Entity<Account>) => {
	return ([
		{
			caption: "Средства связи",
			iconCaption: <MeansOfCommunication />,
			body: <MainInformationDetailBodies entity={entity} caption={"Средства связи"} />,
		},
		{
			caption: "Адреса",
			iconCaption: <Address />,
			body: null,
		},
		{
			caption: "Заметки",
			iconCaption: <NotesIcon />,
			body: entity.entity.notes !== null ?
				<MainInformationDetailBodies entity={entity} caption={"Заметки"} />
				: null,
		},
	]);
});

const MainInformationDetailBodies = observer((props: { entity: Entity<Account>, caption: string }) => {
	switch (props.caption) {
	case ("Средства связи"):
		return (<>
			<Field name={"primaryPhone"} caption={"Основной телефон"} className={styles.meansOfCommunicationField} isMain={true}>
				<InputPhone
					value={props.entity.entity.primaryPhone}
					placeholder={"+7 (123) 456-78-90"}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "primaryPhone");
					}}
					inputStyle={InputStyleName.FullViewInput}
				/>
			</Field>

			<Field name={"site"} caption={"Сайт"} className={styles.meansOfCommunicationField}>
				<Input
					value={props.entity.entity.site}
					placeholder={""}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "site");
					}}
					inputStyle={InputStyleName.FullViewInput}
				/>
			</Field>
		</>);
	case ("Адреса"):
		return (null);
	case ("Заметки"):
		return (<Notes value={props.entity.entity.notes} onChangeValue={(value) => { props.entity.entity.setValue(value, "notes"); }} />);
	}
	return <></>;
});

const ContactsDetailBody = observer((props: { entity: Contact }) => {

	useEffect(() => {
		contactEntity.load(props.entity.id);
	}, [props.entity.id]);

	return (
		<Field key={"contactName"} name={"contactName"}
			caption={contactEntity.entity.name}
			captionLink={`/${SectionRoute.Contact}/${contactEntity.entity.id}`}
			className={styles.contactsDetailBodyField}
			isMain={true}

		>
			<div className={styles.contactsDetailBody}>
				<div className={styles.contactsDetailBodyLeft}>
					<HorizontalField key={"email"} name={"email"} caption={"Email"}>
						<Input
							value={contactEntity.entity.email}
							placeholder={"example@web.com"}
							onChangeValue={(value) => {
								contactEntity.entity.setValue(value, "email");
								validateEmail(contactEntity.entity.email, contactEntity.entity.validation.email);
							}}
							isInvalid={contactEntity.entity.validation.email.isInvalid}
							onFocusOut={() => validateEmail(contactEntity.entity.email, contactEntity.entity.validation.email)}
							inputStyle={InputStyleName.FullViewInput}
						/>
					</HorizontalField>
					<HorizontalField key={"phone"} name={"phone"} caption={"Основной телефон"}>
						<InputPhone
							value={contactEntity.entity.phone}
							placeholder={"+7 (123) 456-78-90"}
							onChangeValue={(value) => {
								contactEntity.entity.setValue(value, "phone");
								validatePhoneNumber(contactEntity.entity.phone, contactEntity.entity.validation.phone);
							}}
							isInvalid={contactEntity.entity.validation.phone.isInvalid}
							onFocusOut={() => validateEmail(contactEntity.entity.phone, contactEntity.entity.validation.phone)}
							inputStyle={InputStyleName.FullViewInput}
						/>
					</HorizontalField>
				</div>
				<div className={styles.contactsDetailBodyRight}>
					<HorizontalField key={"role"} name={"role"} caption={"Роль"}>
						<Select
							items={contactEntity.lookups["ContactRole"]}
							onChangeValue={(value) => {
								contactEntity.entity.setValue(value!, "role");
							}}
							isInput={true}
							onItemsLoad={async (s) => { return await contactEntity.loadLookups("ContactRole", undefined, null, true); }}
							value={contactEntity.entity.role}
							className={styles.fullViewBasicInformationDataInput + " " + styles.input}
						/>
					</HorizontalField>
					<HorizontalField key={"position"} name={"position"} caption={"Должность"} >
						<Input
							value={contactEntity.entity.position}
							placeholder={""}
							onChangeValue={(value: string) => { contactEntity.entity.setValue(value, "position"); }}
							classNameParent={styles.fullViewBasicInformationDataInput}
							inputStyle={InputStyleName.FullViewInput}
						/>
					</HorizontalField>
				</div>
			</div>
		</Field>

	);
});

const MainInformationDataLeft = observer((props: { entity: Entity<Account> }) => {
	return (
		<>
			<HorizontalField name={"name"} caption={"Название"} required={true} classNameCaption={styles.fieldCaption}>
				<Input
					value={props.entity.entity.name}
					placeholder={""}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "name");
					}}
					inputStyle={InputStyleName.FullViewInput}
					isInvalid={props.entity.entity.validation.name.isInvalid}
					invalidMessage={props.entity.entity.validation.name.error}
					onFocusOut={() => validateRequired(props.entity.entity.name, props.entity.entity.validation.name)}
				/>
			</HorizontalField>

			<HorizontalField name={"category"} caption={"Категория"} required={true} classNameCaption={styles.fieldCaption}>
				<Select
					items={props.entity.lookups["AccountCategory"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "category");
					}}
					isInput={true}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					onItemsLoad={async () => {
						return await props.entity.loadLookups("AccountCategory", undefined, null, true);
					}}
					value={props.entity.entity.category}
					isInvalid={props.entity.entity.validation.category.isInvalid}
					invalidMessage={props.entity.entity.validation.category.error}
					onFocusOut={() => validateRequired(props.entity.entity.category, props.entity.entity.validation.category)}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
				/>
			</HorizontalField>

			<HorizontalField name={"industry"} caption={"Отрасль"} classNameCaption={styles.fieldCaption}>
				<Select
					items={props.entity.lookups["Industry"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "industry");
					}}
					isInput={true}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					onItemsLoad={async () => {
						return await props.entity.loadLookups("Industry", undefined, null, false, undefined, 0);
					}}
					value={props.entity.entity.industry}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
				/>
			</HorizontalField>
			<HorizontalField name={"type"} caption={"Тип контрагента"} classNameCaption={styles.fieldCaption}>
				<Select
					items={props.entity.lookups["AccountType"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "type");
					}}
					isInput={true}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					onItemsLoad={async () => {
						return await props.entity.loadLookups("AccountType", undefined, null, false, undefined, 0);
					}}
					value={props.entity.entity.type}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
				/>
			</HorizontalField>
		</>
	);
});

const MainInformationDataRight = observer((props: { entity: Entity<Account> }) => {
	return (
		<>
			<HorizontalField name={"typeOwnership"} caption={"Форма собственности"} classNameCaption={styles.fieldCaption}>
				<Select
					items={props.entity.lookups["TypeOwnership"]}
					onChangeValue={(value) => { props.entity.entity.setValue(value!, "typeOwnership"); }}
					isInput={true}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					onItemsLoad={async () => { return await props.entity.loadLookups("TypeOwnership", undefined); }}
					value={props.entity.entity.typeOwnership}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
				/>
			</HorizontalField>

			<HorizontalField name={"inn"} caption={"ИНН"} classNameCaption={styles.fieldCaption}>
				<Input
					value={props.entity.entity.inn}
					placeholder={""}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "inn");
					}}
					inputStyle={InputStyleName.FullViewInput}
				/>
			</HorizontalField>
			<HorizontalField name={"kpp"} caption={"КПП"} classNameCaption={styles.fieldCaption}>
				<Input
					value={props.entity.entity.kpp}
					placeholder={""}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "kpp");
					}}
					inputStyle={InputStyleName.FullViewInput}
				/>
			</HorizontalField>
			<HorizontalField name={"ogrn"} caption={"ОГРН"} classNameCaption={styles.fieldCaption}>
				<Input
					value={props.entity.entity.ogrn}
					placeholder={""}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "ogrn");
					}}
					inputStyle={InputStyleName.FullViewInput}
				/>
			</HorizontalField>
			<HorizontalField name={"owner"} caption={"Ответственный"} required={false} classNameCaption={styles.fieldCaption}>
				<SearchSelect
					items={props.entity.lookups["User"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "owner");
					}}
					isInput={true}
					onItemsLoad={async (s) => { return await props.entity.loadLookups("User", s); }}
					value={props.entity.entity.owner}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
				/>
			</HorizontalField>
		</>
	);
});



export default AccountFullView;