import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import { v4 } from "uuid";
import { lowerCase } from "lodash";

import detailMasterState from "features/detail-master/detail-master-state";
import detailFieldConfigurationPopupState, { FieldStoreEnums } from "./detail-field-configuration-popup-state";
import {
	validateIsNumberAndRequired,
	validateRequired,
} from "entities/Validation";

import {
	Field,
	Input,
	Text,
	InputStyleName,
	SelectStyleName,
	Dropdown,
} from "components";
import { FieldTypeTitle } from "pages/section-wizzard/pages/constructor/field-configuration/field-configuration";
import { NewLookupConfiguration } from "./new-lookup-configuration/new-lookup-configuration";
import { BasicSettings } from "./basic-settings/basic-settings";
import { ToggleBlockConfiguration } from "./toggle-block-configuration/toggle-block-configuration";

import FilterColumnType, { ColumnType } from "entities/ColumnType";
import { ColumnSpecializationType } from "types/entity";
import IFilter, { ComparisonType, DataValueType } from "entities/filter/IFilter";
import { ILookup } from "entities/Entity";
import { ILookupData } from "pages/settings/data/Fields";
import { LookupType } from "pages/section-wizzard/data/data";

import styles from "./detail-field-configuration-popup.module.css";


interface DetailFieldConfigurationBodyProps {
	fieldType?: ColumnType;
	columnId?: string;
}

export const DetailFieldConfigurationBody = observer((props: DetailFieldConfigurationBodyProps) => {

	useEffect(() => {
		if (props.fieldType) {
			detailFieldConfigurationPopupState.setValueWithoutTrackingChanges(FieldStoreEnums.fieldType, props.fieldType);
		}
		if (props.columnId) {
			detailFieldConfigurationPopupState.getConfigurationById(props.columnId, detailMasterState.gridItems);
		}
		else {
			detailFieldConfigurationPopupState.setValueWithoutTrackingChanges(FieldStoreEnums.columnId, v4());
		}
	}, []);

	const configurationContent = useMemo(() => {
		return (
			<>
				{detailFieldConfigurationPopupState.lookupType === LookupType.NewLookup ?
					<NewLookupConfiguration /> : <DefaultConfiguration />
				}
			</>
		);
	}, [detailFieldConfigurationPopupState.lookupType]);

	return configurationContent;
});

const DefaultConfiguration = observer(() => {
	const [defValues, setDefValues] = useState<ILookup[]>([]);

	const fieldTypeTitleMemo = useMemo(() => {
		return <FieldTypeTitle fieldType={detailFieldConfigurationPopupState.fieldType} />;
	}, [detailFieldConfigurationPopupState.fieldType]);

	const basicSettingsMemo = useMemo(() => <BasicSettings />, []);

	const fieldTypeIsLookup = useMemo(() => {
		return detailFieldConfigurationPopupState.fieldType === ColumnType.Lookup;
	}, [detailFieldConfigurationPopupState.fieldType]);

	const fieldTypeIsDecemal = useMemo(() => {
		return detailFieldConfigurationPopupState.fieldType === ColumnType.Decimal;
	}, [detailFieldConfigurationPopupState.fieldType]);

	const handleRoundingChange = useCallback((value: string) => {
		detailFieldConfigurationPopupState.setValue(FieldStoreEnums.rounding, value);
		detailFieldConfigurationPopupState.setSpecialization(FieldStoreEnums.specializations, ColumnSpecializationType.Double, { "rounding": value });
	}, []);

	const handleRoundingFocusOut = useCallback(() => {
		validateIsNumberAndRequired(
			detailFieldConfigurationPopupState.rounding,
			detailFieldConfigurationPopupState.validation.rounding,
		);
	}, []);

	const handleLookupChange = useCallback(async (value: any) => {
		detailFieldConfigurationPopupState.setValue(FieldStoreEnums.selectedLookup, value);
		//Не подгружаем значения для разделов
		if (detailFieldConfigurationPopupState.selectedLookup?.name && !detailFieldConfigurationPopupState.selectedLookup.isSection) {
			await handleDefaultValuesLoad(null);
		}

		detailFieldConfigurationPopupState.setValue(FieldStoreEnums.selectedLookupDefaultValue, null);
	}, []);

	const handleLookupFocusOut = useCallback(() => {
		validateRequired(
			detailFieldConfigurationPopupState.selectedLookup,
			detailFieldConfigurationPopupState.validation.lookup,
			true
		);
	}, []);
	const handleLookupDefaultValueChange = useCallback(async (value: any) => {
		detailFieldConfigurationPopupState.setValue(FieldStoreEnums.selectedLookupDefaultValue, value);
		if (value !== null) {
			detailFieldConfigurationPopupState.setValue(FieldStoreEnums.defaultValue, detailFieldConfigurationPopupState.selectedLookupDefaultValue!.id);
		} else {
			detailFieldConfigurationPopupState.setValue(FieldStoreEnums.defaultValue, value);
		}

	}, []);

	const handleDefaultValueChange = useCallback((value: string) => {
		detailFieldConfigurationPopupState.setValue(FieldStoreEnums.defaultValue, value);
	}, []);

	const handleDefaultValueFocusOut = useCallback(() => {
		detailFieldConfigurationPopupState.validateDefaultValue();
	}, []);

	const roundingField = fieldTypeIsDecemal && (
		<Field
			name="rounding"
			caption="Количество возможных знаков после запятой"
			required
			promptText='Столько знаков после запятой сможет ввести пользователь'
		>
			<Input
				value={detailFieldConfigurationPopupState.specializations.properties["rounding"]}
				placeholder=""
				onChangeValue={(value: string) => {
					handleRoundingChange(value);
				}}
				isInvalid={detailFieldConfigurationPopupState.validation.rounding.isInvalid}
				invalidMessage={detailFieldConfigurationPopupState.validation.rounding.error}
				onFocusOut={handleRoundingFocusOut}
				inputStyle={InputStyleName.Base}
			/>
		</Field>
	);

	const handleLookupValuesLoad = useCallback(async (value: string | null) => {
		try {
			const items = await detailFieldConfigurationPopupState.loadLookups();
			if (items) {
				const newItems: ILookupData[] = [];
				items.filter((lookup: any) => {
					const entityTitle = lowerCase(lookup.entityTitle);
					if (entityTitle.includes(lowerCase(value!)))
						newItems.push(lookup);
				});
				detailFieldConfigurationPopupState.setLookups(newItems);
				return newItems.length
			}
			else return -1
		}
		catch (error) {
			console.error(error)
		}
	}, []);

	const lookupField = useMemo(() => {
		if (fieldTypeIsLookup) {
			return (
				<Field
					key="lookup"
					name="lookup"
					caption="Объект"
					required
					promptText='Список всех справочников и разделов в системе'
				>
					<Dropdown
						isInput
						items={detailFieldConfigurationPopupState.lookups}
						value={detailFieldConfigurationPopupState.selectedLookup}
						onChangeValue={handleLookupChange}
						onItemsLoad={handleLookupValuesLoad}
						isInvalid={detailFieldConfigurationPopupState.validation.lookup.isInvalid}
						invalidMessage={detailFieldConfigurationPopupState.validation.lookup.error}
						onFocusOut={handleLookupFocusOut}
						selectStyle={SelectStyleName.Base}
					/>
				</Field>
			);
		}
		return null;
	}, [fieldTypeIsLookup, detailFieldConfigurationPopupState.selectedLookup, detailFieldConfigurationPopupState.lookups]);

	const handleDefaultValuesLoad = useCallback(async (value: string | null) => {
		let filter: IFilter | null = null;
		if (value && detailFieldConfigurationPopupState.selectedLookup) {
			filter = {
				schema: detailFieldConfigurationPopupState.selectedLookup.name,
				isEnabled: true,
				type: 1,
				comparisonType: ComparisonType.Contain,
				attribute: "Name",
				attributeType: FilterColumnType.String,
				rightExpression: {
					type: 0,
					parameter: {
						dataValueType: DataValueType.Text,
						value: value
					}
				},
				filters: [],
				logicalOperation: 0,
				detail: ""
			};
		}
		try {
			if (detailFieldConfigurationPopupState.selectedLookup) {
				const items = await detailFieldConfigurationPopupState.loadLookupData(detailFieldConfigurationPopupState.selectedLookup.name, filter);
				if (items) {
					setDefValues(items);
					return items.length
				}
				else return -1
			}
		}
		catch (error) {
			console.error(error)
		}
	}, [defValues, detailFieldConfigurationPopupState.selectedLookup]);

	const defaultValueField = useMemo(() => {
		return fieldTypeIsLookup &&
			detailFieldConfigurationPopupState.fieldType !== ColumnType.DateTime &&
			!detailFieldConfigurationPopupState.selectedLookup?.isSection &&
			(
				<Field
					name="defaultValue"
					caption="Значение по умолчанию"
					promptText='Значение, которым заполнится поле при создании записи'
				>
					<Dropdown
						items={defValues}
						onChangeValue={handleLookupDefaultValueChange}
						onItemsLoad={handleDefaultValuesLoad}
						isInput
						value={detailFieldConfigurationPopupState.selectedLookupDefaultValue}
						selectStyle={SelectStyleName.Base}
					/>
				</Field>
			);
	}, [fieldTypeIsLookup, detailFieldConfigurationPopupState.fieldType, detailFieldConfigurationPopupState.selectedLookup,
		toJS(defValues), detailFieldConfigurationPopupState.selectedLookupDefaultValue]);

	const defaultValueFieldNonLookup = useMemo(() => {
		if (!fieldTypeIsLookup &&
			detailFieldConfigurationPopupState.fieldType !== ColumnType.DateTime &&
			detailFieldConfigurationPopupState.fieldType !== ColumnType.Date &&
			detailFieldConfigurationPopupState.fieldType !== ColumnType.Time
		) {
			return (
				<Field
					name="defaultValue"
					caption="Значение по умолчанию"
					promptText='Значение, которым заполнится поле при создании записи'
				>
					<Input
						value={detailFieldConfigurationPopupState.defaultValue}
						placeholder=""
						onChangeValue={(value: string) => {
							handleDefaultValueChange(value);
						}}
						isNeedCorrect
						isInvalid={detailFieldConfigurationPopupState.validation.defaultValue.isInvalid}
						invalidMessage={detailFieldConfigurationPopupState.validation.defaultValue.error}
						onFocusOut={handleDefaultValueFocusOut}
					/>
				</Field>
			);
		}
		return null;
	}, [fieldTypeIsLookup, detailFieldConfigurationPopupState.fieldType, detailFieldConfigurationPopupState.defaultValue,
		detailFieldConfigurationPopupState.validation.defaultValue.isInvalid]);

	return (
		<>
			{fieldTypeTitleMemo}
			{basicSettingsMemo}
			{roundingField}
			{lookupField}
			{defaultValueField}
			{defaultValueFieldNonLookup}
			<ToggleBlockConfiguration />
			<Text className={styles.sectionTitle}>ВНЕШНИЙ ВИД</Text>
			<Field
				name="prompt"
				caption="Подсказка"
				promptText='То, что вы читаете прямо сейчас 🙂'
			>
				<Input
					value={detailFieldConfigurationPopupState.prompt}
					placeholder=""
					onChangeValue={(value: string) => {
						detailFieldConfigurationPopupState.setValue(FieldStoreEnums.prompt, value);
					}}
				/>
			</Field>
		</>
	);
});




