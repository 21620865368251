import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { dispatcher, store } from "store";
import { modalController } from "features/modals";
import {
    DetailConfiguration,
    detailConfigurationStore,
    fieldConfigurationStore,
    FieldConfigurationWrapper,
    FieldGroupConfiguration,
    fieldGroupConfigurationStore
} from "../../../../pages/constructor";
import { Zone } from "modules/DND/zone";
import { Element } from "modules/DND/element";

import { Button, ButtonStyle } from "components";
import { FieldSelect } from "pages/section-wizzard/components";

import { DEFAULT_WARNING_TEXT, NewElemTitle } from "pages/section-wizzard/data/data";
import { Types } from "modules/DND/type";
import { ColumnType } from "entities/ColumnType";
import { DragIcon, Warning } from "assets/icons";
import { IGridItem } from "components/select/types";

import styles from "../constructor-source-panel.module.css";


type DNDOnDropEvent = {
    elementId: string | null;
    sourceZone: string | null;
    targetZone: string | null;
    type: Types,
    sourceData?: any;
    [key: string]: any;
}

export const NewFieldsBlock = observer((props: { items: Array<IGridItem> }) => {
    const [idModal] = useState<string>(v4());
    const [idConfirm] = useState<string>(v4());

    const [placeholderNewField, setPlaceholderNewField] = useState<JSX.Element>(<></>);
    const [targetElementViewLayoutNewField, setTargetElementViewLayoutNewField] = useState<JSX.Element>(<></>);

    const closeConfirm = useCallback(() => {
        modalController.modalRemove(idConfirm);
        store.options.isDisabledConstructorInSectionWizzard = false;
    }, [idConfirm]);

    const closeAllModals = useCallback(() => {
        store.modals.map((modal) => {
            modalController.modalRemove(modal.id);
        });
        fieldConfigurationStore.resetConfiguration();
        detailConfigurationStore.resetConfiguration();
        fieldGroupConfigurationStore.resetConfiguration();
        store.options.isDisabledConstructorInSectionWizzard = false;
    }, [store.modals]);

    const warningConfirm = useMemo(() => {
        return <div className={styles.warningDialog}>
            <div className={styles.warningHeader}>
                <span className={styles.warningTitle}>Внимание</span>
                <Warning />
            </div>
            <div className={styles.warningDialogBody}>
                <span className={styles.title}>{DEFAULT_WARNING_TEXT}</span>
            </div>
            <div className={styles.dialogFooter}>
                <Button caption="Вернуться к редактированию" onClick={closeConfirm} style={ButtonStyle.Subtle}
                    isDisabled={false} />
                <Button caption="Да, отменить" onClick={closeAllModals} style={ButtonStyle.Danger} isDisabled={false} />
            </div>
        </div>;
    }, [closeAllModals, closeConfirm, dispatcher.entity.get()?.entity.sectionWizzard]);

    const closeFuncWithConfirm = useCallback(() => {
        modalController.popupAdd({ id: idConfirm, layout: warningConfirm, closeFunc: closeConfirm });
    }, [closeConfirm, idConfirm, warningConfirm]);

    const closeOnFocusModal = useCallback(() => {
        if (fieldConfigurationStore.hasChanges || detailConfigurationStore.hasChanges || fieldGroupConfigurationStore.hasChanges) {
            closeFuncWithConfirm()
        } else {
            closeAllModals()
        }
    }, [fieldConfigurationStore.hasChanges, detailConfigurationStore.hasChanges, fieldGroupConfigurationStore.hasChanges]);

    const onZoneDropNewField = useCallback((value: DNDOnDropEvent) => {
        if (!value.targetZone?.includes('source-zone')) {
            if (value?.sourceData.type === ColumnType.String || value?.sourceData.type === ColumnType.DateTime ||
                value?.sourceData.type === ColumnType.Date || value?.sourceData.type === ColumnType.Time ||
                value?.sourceData.type === ColumnType.Lookup || value?.sourceData.type === ColumnType.Boolean ||
                value?.sourceData.type === ColumnType.Integer || value?.sourceData.type === ColumnType.Decimal) {
                modalController.popupAdd({
                    id: idModal,
                    layout: <FieldConfigurationWrapper
                        fieldType={value.sourceData.type}
                        close={closeFuncWithConfirm}
                        closeAll={closeAllModals}
                        targetZone={value.targetZone}
                        position={value.MATRIX}
                    />,
                    closeFunc: closeOnFocusModal
                });
            } else if (value?.sourceData.type === ColumnType.Detail) {
                modalController.popupAdd({
                    id: idModal,
                    layout: <DetailConfiguration
                        close={closeFuncWithConfirm}
                        closeAll={closeAllModals}
                        position={value.MATRIX}
                    />,
                    closeFunc: closeOnFocusModal
                });
            } else if (value?.sourceData.type === ColumnType.FieldGroup) {
                modalController.popupAdd({
                    id: idModal,
                    layout: <FieldGroupConfiguration
                        position={value.MATRIX}
                        close={closeFuncWithConfirm}
                        closeAll={closeAllModals}
                    />,
                    closeFunc: closeOnFocusModal
                });
            }
        }
    }, [idModal, closeFuncWithConfirm, closeAllModals, closeOnFocusModal]);

    const onZoneUpNewField = useCallback(({ elementId }: {
        elementId: string
    }) => {
        const item = props.items.find((innerItem) => (innerItem.gridItemId ?? innerItem.id) === elementId);
        if (item) {
            const fieldClassNames = classNames(`${styles.field} `, {
                [`${item.classNames} `]: item.classNames,
            });

            setPlaceholderNewField(
                <li
                    style={{ borderRadius: "8px", background: "var(--color-grayBlue-50)" }}
                    key={item.columnId}
                    className={fieldClassNames}
                >
                    <DragIcon style={{ stroke: "var(--color-gray-300)" }} />
                    {item.icon}
                    <span >{item.displayValue}</span>
                </li>
            );

            setTargetElementViewLayoutNewField(
                <div style={{
                    transform: "rotate(-3deg) translateX(-12px) translateY(-20px)",
                    padding: "0px 8px",
                    alignItems: "center",
                    gap: "8px",
                    flexShrink: 0,
                    borderRadius: "8px",
                    background: "#FFF",
                    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.14)",
                    maxWidth: "400px"
                }} key={item.id} className={fieldClassNames}>
                    <DragIcon style={{ stroke: "var(--color-gray-300)" }} />
                    {item.icon}
                    <span className={styles.targetElementTitle}>{item.displayValue}</span>
                </div>
            );
        }
    }, [props.items.map(item => item), placeholderNewField]);

    const zoneConfig = useMemo(() => {
        return {
            targetElementViewLayout: targetElementViewLayoutNewField,
            placeholderLayout: placeholderNewField,
            predictLayout: <div className={styles.predictLayout}></div>,
            cellLayout: <div style={{ height: "2em" }}></div>,
            width: 1,
            height: 1
        };
    }, [targetElementViewLayoutNewField, placeholderNewField]);

    const fieldsMapping = useMemo(() => {
        return (props.items &&
            props.items.map((item, index) => {
                const fieldClassNames = classNames(`${styles.field} `, {
                    [`${styles.fieldDisabled} `]: item.isDisabled || item.isLocked,
                    [`${item.classNames} `]: item.classNames,
                });

                return (
                    <Zone
                        key={`${item.columnId ?? item.gridItemId ?? item.id}-source-zone`}
                        onDrop={onZoneDropNewField}
                        onUp={onZoneUpNewField}
                        id={`${item.columnId ?? item.gridItemId ?? item.id}-source-zone`}
                        config={zoneConfig}
                        type={Types.MATRIX}
                    >
                        <Element
                            key={item.id}
                            id={`${item.columnId ?? item.gridItemId ?? item.id}`}
                            x={1}
                            y={1}
                            width={1}
                            height={1}
                            sourceData={{
                                title: item.displayValue,
                                icon: item.icon,
                                id: item.id,
                                type: item.columnType ?? item.name
                            }}
                        >
                            <li key={item.columnId ?? item.gridItemId} className={fieldClassNames}>
                                <DragIcon style={{ stroke: "var(--color-gray-300)" }} />
                                {item.icon}
                                <span>{item.displayValue}</span>
                            </li>
                        </Element>
                    </Zone>
                );
            })
        );
    }, [zoneConfig, toJS(props.items)]);

    return (
        <FieldSelect
            name={NewElemTitle}
            buttonStyle={ButtonStyle.Settings}
        >
            {!store.options.isDisabledConstructorInSectionWizzard ? fieldsMapping : <></>}
        </FieldSelect>
    )
});