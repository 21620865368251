import Api from "api/http/api";

class HealthCheck extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/HealthCheck";
	}

	healthCheck = () => {
		this.setPath(`${this.basicPath}/check`);
		return this;
	};
}

const healthCheck = new HealthCheck();

export { healthCheck };