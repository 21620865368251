import setAutoLogoutTimeout from "app/services/apiUtils";
import { observable, reaction } from "mobx";

import { sessionStore } from "./store";

function parseJwt(token: string) {
	const base64Payload = token.split(".")[1];
	const payload = new TextDecoder().decode(
		new Uint8Array(
			atob(base64Payload)
				.split("")
				.map((c) => c.charCodeAt(0))
		)
	);

	return JSON.parse(payload.toString());
}

const authStore = observable({
	token: window.localStorage.getItem("jwt"),
	tokenExpiration: window.localStorage.getItem("tokenExpiration"),

	get loggedIn(): boolean {
		return this.token !== undefined && this.token !== null;
	},
	get workspaceId(): string | undefined {
		if (authStore.loggedIn) {
			const jwt = parseJwt(authStore.token!);
			return jwt.workspace_id;
		}
		return undefined;
	},
	get link(): string | null {
		if (authStore.loggedIn) {
			const jwt = parseJwt(authStore.token!);
			return jwt.link;
		}
		return null;
	},
	get userId(): string | null {
		if (authStore.loggedIn) {
			const jwt = parseJwt(authStore.token!);
			return jwt.id;
		}
		return null;
	},

	logIn(token: string, expiration: string) {
		this.token = token;
		this.tokenExpiration = expiration;

		setAutoLogoutTimeout(expiration);
	},
	logOut() {
		sessionStore.logout();
		this.token = null;
		this.tokenExpiration = null;
		let removeCookie = (name: string) => {
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
		};
		removeCookie("refreshToken");
	},
});

reaction(
	() => authStore.token,
	(token) => {
		if (token) {
			window.localStorage.setItem("jwt", token);
		} else {
			window.localStorage.removeItem("jwt");
		}
	}
);

reaction(
	() => authStore.tokenExpiration,
	(tokenExpiration) => {
		if (tokenExpiration) {
			window.localStorage.setItem("tokenExpiration", tokenExpiration);
		} else {
			window.localStorage.removeItem("tokenExpiration");
		}
	}
);

reaction(
	() => authStore.link,
	(link) => {
		if (link) {
			window.localStorage.setItem("link", link);
		}
	}
);

export default authStore;
