// @ts-ignore
import { isEqual } from "lodash";

import { dispatcher } from "store";

export class CommentsController {
	get = (): Array<Comment> | undefined => {
		return dispatcher.currentRow.get()?.comments;
	};
	set = (comments: Array<any>) => {
		if (dispatcher.currentRow.get()) {
			// @ts-ignore
			dispatcher.currentRow.get().comments = comments;
		}
	};
	add = (comment: any) => {
		if (dispatcher.currentRow.get()?.comments && !dispatcher.currentRow.get()?.comments.find((oldComment: any) => oldComment.id === comment.id)) {
			// @ts-ignore
			const clone = [...dispatcher.currentRow.get().comments];
			clone.push(comment);
			// @ts-ignore
			dispatcher.currentRow.get().comments = clone;
		}
	};
	remove = (commentId: string) => {
		if (dispatcher.currentRow.get()?.comments) {
			// @ts-ignore
			dispatcher.currentRow.get().comments = this.get().filter(comment => comment.id !== commentId);
		}
	};

	edit = (commentId: string, text: string, modifiedOn?: string) => {
		if (this.get()?.find((comment: any) => comment.id === commentId)) {
			// @ts-ignore
			this.get().find((comment: any) => {
				return comment.id === commentId;
				// @ts-ignore
			}).text = text;

			if (modifiedOn) {
				// @ts-ignore
				this.get().find((comment: any) => {
					return comment.id === commentId;
					// @ts-ignore
				}).modifiedOn = modifiedOn;
			}
		}
	};

	sort = (func: (a: any, b: any) => 1 | 0 | -1) => {
		if (this.get()) {
			// @ts-ignore
			if (!isEqual([...this.get()].sort(func), this.get().map(comment => comment))) {
				// @ts-ignore
				dispatcher.currentRow.get().comments = [...this.get()].sort(func);
			}
		}
	};
}

export const commentsController = new CommentsController();