import { observer } from "mobx-react";
import { CSSProperties, useEffect, useRef, useState } from "react";

import { IUniteData } from 'features/section-head/data/constants';
import { IColumn, Dialog, Button, ButtonStyle, UnionGrid, WarningDialog } from 'components';

import { Item } from 'types';
import { IFormat } from "entities/IItem";
import FilterColumnType from "entities/ColumnType";
import Entity, { IEntityStore } from "entities/Entity";
import ListStore from "entities/ListStore";
// import api from 'app/services/api';
import { api } from "api";

import styles from './unite-block.module.css';

interface IUniteBlock {
    entity: Entity<IEntityStore>;
    listStore?: ListStore;
    className?: string;
    style?: CSSProperties;
    columns: IColumn[];
    isOpen: boolean;
    close?: boolean;
    onClick: () => void;
    setDone: (value: boolean) => void;
}

const UniteBlock = observer(function (props: IUniteBlock) {
    const [openedWarningDialog, setOpenedWarningDialog] = useState(false);
    const [uniteData, setUniteData] = useState<IUniteData[]>([]);
    const [headerNames, setHeaderNames] = useState<IColumn[]>([]);
    const [allCheched, setAllCheched] = useState(false);
    const data = props.listStore?.includedIds;
    const arrSortByData = data;
    const refSetTimeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (props.isOpen) {
            getDataRow(props);
        }
    }, [props.isOpen]);

    if (!props.isOpen) {
        return null;
    }

    let className = `${styles.wrapper} `;

    if (props.className) className += props.className;

    async function startUnite() {
        if (arrSortByData)
            arrSortByData.sort((a, b) => {
                if (Date.parse(a.modifiedOn!) < Date.parse(b.modifiedOn!)) {
                    return 1;
                }
                if (Date.parse(a.modifiedOn!) > Date.parse(b.modifiedOn!)) {
                    return -1;
                }
                return 0;
            });

        if (arrSortByData === undefined) return;
        const freshUniteData: { columnName: string; data: Item | string }[] = [];

        uniteData.forEach((item) => {
            let param = item.data.find((elem) => elem.checked === true);
            if (param !== undefined) {
                freshUniteData.push({
                    columnName: item.columnName,
                    data: param.id ? { id: param.id, name: param.name } : param.name,
                });
            }
        });

        props.entity.entity.deserialize(arrSortByData[0]);

        freshUniteData.forEach((item) => {
            props.entity.entity.setValue(item.data, item.columnName);
        });

        const mergeData = {
            entityId: arrSortByData[0].id,
            entitiesForDelete: arrSortByData
                .map((item, ind) => {
                    if (ind > 0) return item.id;
                })
                .filter((x) => x !== undefined),
            ...props.entity.entity.serialize(),
        };

        // let response = await api.post("/api/Entity/merge", mergeData);
        let response = await api.http.httpApi.entity.mergeEntity().post(mergeData);

        if (response && response.data.success === true) {
            await props.listStore!.load(
                props.listStore!.filter,
                props.listStore?.staticGroupId
            );
            props.onClick();
            props.setDone(true);
            refSetTimeout.current = setTimeout(() => {
                props.setDone(false);
            }, 4000);
        }
    }

    function getDataRow(props: IUniteBlock) {
        let uniteData: IUniteData[] = [];
        props.columns.forEach((column) => {
            let mass: IFormat[] = [];
            if (data)
                data.forEach((item) => {
                    let cell = Reflect.get(item, column.name);
                    if (column.type === FilterColumnType.Date) return;
                    if (cell === null || cell === undefined) return;

                    if (mass.includes(cell)) return;

                    if (typeof cell === "object") {
                        if (mass.filter((e) => e.id === cell.id).length > 0) return;
                        mass.push({
                            name: cell.name,
                            id: cell.id,
                            checked: false,
                        });
                    } else
                        mass.push({
                            name: cell,
                            checked: false,
                        });
                });
            if (mass.length > 0) {
                uniteData.push({
                    columnName: column.name,
                    data: mass,
                });
            }
        });
        setUniteData(uniteData);
        let templateArray: IColumn[] = [];
        props.columns.forEach((column, i) => {
            let nameDublicate = uniteData.find(
                (row, i) => row.columnName === column.name
            );
            if (nameDublicate) templateArray.push(column);
        });
        setHeaderNames(templateArray);
    }

    function checking() {
        let checkedCount = 0;

        uniteData.map((item) => {
            if (item.data.find((elem) => elem.checked === true) !== undefined)
                checkedCount += 1;
        });

        if (checkedCount === uniteData.length) {
            setAllCheched(true);
        }
    }

    return (
        <>
            <Dialog
                title={"Объединение записей"}
                isOpen={props.isOpen}
                dialogFooterButtons={
                    <>
                        <Button
                            caption={"Отмена"}
                            onClick={() => setOpenedWarningDialog(true)}
                            style={ButtonStyle.Subtle}
                        />
                        <Button
                            caption={"Объединить"}
                            onClick={startUnite}
                            style={ButtonStyle.Primary}
                            isDisabled={allCheched ? false : true}
                        />
                    </>
                }
                onClick={() => setOpenedWarningDialog(true)}
                className={className}
            >
                <div className={styles.infoTitle}>
                    {
                        "Найдены отличающиеся значения полей.\nВыберите те значения, которые отобразятся в конечной записи."
                    }
                </div>
                <UnionGrid
                    columns={headerNames}
                    data={uniteData}
                    onClickRadiobutton={checking}
                />
                <div className={styles.divider} />
            </Dialog>
            <WarningDialog
                value={
                    "Вы действительно хотите отменить объединение?\nТекущий прогресс будет утерян."
                }
                valueReturn={"Вернуться к объединению записей"}
                valueDelete={"Отменить"}
                isOpen={openedWarningDialog}
                onBackClick={() => setOpenedWarningDialog(false)}
                onCancelClick={() => {
                    setOpenedWarningDialog(false);
                    props.onClick();
                }}
            />
        </>
    );
});

export default UniteBlock;
