import { isNull, isString } from "lodash";
import { action } from "mobx";
import { dispatcher } from "store";

import { FieldValidationState } from "types";
import { ValidationState } from "../data/data";
import { OptionPage } from "types/entity";

export const validateRequired = action((value: string) => {
	let state: FieldValidationState = { isInvalid: false, error: "" };
	if (isString(value) && value.length === 0) {
		state.isInvalid = true;
		state.error = "Введите данные";
	} else {
		state.isInvalid = false;
		state.error = "";
	}
	return state;
});
/**
 * @description Валидация системного названия
 */
export const validateSchema = action((name: string) => {
	let state: FieldValidationState = { isInvalid: false, error: "" };
	if (isNull(name) || !(/^([a-zA-Z]+)$/g).test(name)) {
		state.isInvalid = true;
		state.error = "Некорректное системное название";
	} else if (name) {
		if (name[0] !== name[0].toUpperCase()) {
			state.isInvalid = true;
			state.error = "Системное название должно начинаться с заглавной буквы";
		}
	} else {
		state.isInvalid = false;
		state.error = "";
	}
	return state;
});
/* Валидация при создании нового таба в разделе */
export const validateNewTabName = action((tabTitle: string) => {
	let state: FieldValidationState = { isInvalid: false, error: "" };
	const filteredTabs = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig.find(tab => tab.tabTitle === tabTitle);
	if (isNull(tabTitle) || isString(tabTitle) && tabTitle.length === 0) {
		state.isInvalid = true;
		state.error = "Введите данные";
	}
	else if (filteredTabs) {
		state.isInvalid = true;
		state.error = "Вкладка с таким названием уже существует";
	} else {
		state.isInvalid = false;
		state.error = "";
	}
	return state;
});
/* Валидация при создании нового поля в разделе */
export const validateNewFieldSystemName = action((tabName: string) => {
	let state: FieldValidationState = { isInvalid: false, error: "" };
	const tabsConfig = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig;
	tabsConfig?.forEach(tab => {
		const foundField = tab.grid.items.find(field => field.fieldConfig?.columnName === tabName);
		if (foundField) {
			state.isInvalid = true;
			state.error = "Поле с таким названием уже существует";
		}
		return;
	});
	if (!state.isInvalid) {
		if (isNull(tabName) || !(/^([a-zA-Z]+)$/g).test(tabName)) {
			state.isInvalid = true;
			state.error = "Введите данные";
		} else {
			state.isInvalid = false;
			state.error = "";
		}
	}
	return state;
});

export const getGlobalSectionWizzardValidation = action((): OptionPage[] => {
	let resultGlobalStates = [];
	const titleState: ValidationState = validateRequired(dispatcher.sectionWizzard.getSectionWizzard()?.entityTitle ?? '');
	const systemNameState: ValidationState = validateSchema(dispatcher.sectionWizzard.getSectionWizzard()?.systemName ?? '');
	if (titleState.isInvalid || systemNameState.isInvalid) {
		resultGlobalStates.push(OptionPage.GlobalSettings);
	}
	if (!dispatcher.sectionWizzard.getSectionWizzard()?.viewColumnId) {
		resultGlobalStates.push(OptionPage.Constructor);
	}
	if (dispatcher.sectionWizzard.getSectionWizzard()?.hasStageModel) {
		const allStages = dispatcher.sectionWizzard.getSectionWizzard()?.stageModelConfig?.stages;
		if (allStages?.length! > 0) {
			const notValidStages = allStages?.filter(stage => stage.name.length <= 0);
 		    const findObjectsWithDuplicateNames = () => {
    			return allStages?.filter((item, index, array) => {
      				return array.map((mapItem) => mapItem.name).indexOf(item.name) !== index;
    			});
			};
			const dublicateNamesLength = findObjectsWithDuplicateNames() ? findObjectsWithDuplicateNames()?.length : 0;			
			if (notValidStages && notValidStages.length > 0 || dublicateNamesLength) {
				resultGlobalStates.push(OptionPage.StageModel);
			}
		} else {
			resultGlobalStates.push(OptionPage.StageModel);
		}
	}
	return resultGlobalStates;
});