import { api } from "api";

import { dispatcher } from "store/store-dipatcher/dispatcher";

export class SystemDesigner {
	async createNewSection() {
		const sectionFields = dispatcher.entity.get()?.entity.sectionWizzard;

		const data = {
			entityTitle: sectionFields?.entityTitle,
			systemName: sectionFields?.systemName,
			hasStageModel: sectionFields?.hasStageModel,
			kanbanIsEnabled: sectionFields?.kanbanIsEnabled,
			hasCalendar: sectionFields?.hasCalendar,
			hasSpecificFinish: sectionFields?.hasSpecificFinish,
			viewColumnId: sectionFields?.viewColumnId,
			reactorPage: sectionFields?.reactorPage ?? "",
			reactorConfig: sectionFields?.reactorConfig,
			accessRightsConfig:sectionFields?.accessRightsConfig,
			stageModelConfig: sectionFields?.stageModelConfig,
		};
		const response = await api.http.httpApi.systemDesigner.createSection().post(data);
		return response;
	}
	async updateSection() {
		const sectionFields = dispatcher.entity.get()?.entity.sectionWizzard;
		
		const data = {
			entityTitle: sectionFields?.entityTitle,
			systemName: sectionFields?.systemName,
			hasStageModel: sectionFields?.hasStageModel,
			kanbanIsEnabled: sectionFields?.kanbanIsEnabled,
			hasCalendar: sectionFields?.hasCalendar,
			hasSpecificFinish: sectionFields?.hasSpecificFinish,
			viewColumnId: sectionFields?.viewColumnId,
			reactorPage: sectionFields?.reactorPage ?? "",
			reactorConfig: sectionFields?.reactorConfig,
			accessRightsConfig:sectionFields?.accessRightsConfig,
			stageModelConfig: sectionFields?.stageModelConfig,
		};
		return await api.http.httpApi.systemDesigner.updateSection().put(data);
	}
}

export const systemDesigner = new SystemDesigner();