import TypeIcon from "features/type-icon/type-icon";
import { DragDetailBlock, DragFieldBlock, DragFieldBlockProps, DragFieldGroupBlock } from "./drag-blocks";

import { GridItem } from "types/entity";
import { ColumnType } from "entities/ColumnType";


export const multiTypeDragBlocks = {
	[ColumnType.String]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={<TypeIcon type={props.element.fieldConfig?.columnType} />}
			width={props.width}
			onEdit={props.onEdit}
			onDelete={props.onDelete}
		/>;
	}),
	[ColumnType.Boolean]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={<TypeIcon type={props.element.fieldConfig?.columnType} />}
			width={props.width}
			onEdit={props.onEdit}
			onDelete={props.onDelete}
		/>;
	}),
	[ColumnType.Integer]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={<TypeIcon type={props.element.fieldConfig?.columnType} />}
			width={props.width}
			onEdit={props.onEdit}
			onDelete={props.onDelete}
		/>;
	}),
	[ColumnType.Decimal]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={<TypeIcon type={props.element.fieldConfig?.columnType} />}
			width={props.width}
			onEdit={props.onEdit}
			onDelete={props.onDelete}
		/>;
	}),
	[ColumnType.DateTime]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={<TypeIcon type={props.element.fieldConfig?.columnType} />}
			width={props.width}
			onEdit={props.onEdit}
			onDelete={props.onDelete}
		/>;
	}),
	[ColumnType.Date]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={<TypeIcon type={props.element.fieldConfig?.columnType} />}
			width={props.width}
			onEdit={props.onEdit}
			onDelete={props.onDelete}
		/>;
	}),
	[ColumnType.Time]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={<TypeIcon type={props.element.fieldConfig?.columnType} />}
			width={props.width}
			onEdit={props.onEdit}
			onDelete={props.onDelete}
		/>;
	}),
	[ColumnType.Lookup]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={<TypeIcon type={props.element.fieldConfig?.columnType} />}
			width={props.width}
			onEdit={props.onEdit}
			onDelete={props.onDelete}
		/>;
	}),
	[ColumnType.Double]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={<TypeIcon type={props.element.fieldConfig?.columnType} />}
			width={props.width}
			onEdit={props.onEdit}
			onDelete={props.onDelete}
		/>;
	}),
	[ColumnType.Guid]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={<TypeIcon type={props.element.fieldConfig?.columnType} />}
			width={props.width}
			onEdit={props.onEdit}
			onDelete={props.onDelete}
		/>;
	}),
	[ColumnType.FieldGroup]: ((props: { element: GridItem, targetZone?: string }) => {
		return <DragFieldGroupBlock
			id={props.element?.gridItemId}
			elements={props.element.groupFieldsConfig?.inner?.items ?? []}
			groupName={props.element.groupFieldsConfig?.title ?? "EMPTY NAME"}
			groupField={props.element}
			targetZone={props.targetZone}
		/>;
	}),
	[ColumnType.Detail]: ((props: { element: GridItem }) => {
		return <DragDetailBlock
			element={props.element}
		/>;
	}),
};