import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import classNames from "classnames";

import { Button } from "components";

import { IFormat } from "entities/IItem";

import { OffRadioButton, OnRadioButton } from "assets/icons";

import styles from "./radio-button.module.css";

interface IRadioButton {
    className?: string,
    classNameIcon?: string,
    elementRadioButton?: string,
    buttonClassName?: string,
    buttonCaptionClassName?: string,
    buttonContentClassName?: string,
    elements: IFormat[];
    onClickRadiobutton?: (item: string) => void;
    isDivider?: boolean; /* параметр отвечает за то, нужно ли разделять кнопки  между собой разделителем */
    rightIcon?: boolean /* параметр отвечает за то, с какой стороны будет находиться кнопка on/off */
}

const RadioButton = observer(function (props: IRadioButton) {
    let [element, setElement] = useState<string>();

    const wrapClasses = classNames(`${styles.wrapper}`, {
        [`${props.className}`]: props.className
    });

    const buttonClassNames = classNames(`${styles.button}`, {
        [`${props.buttonClassName}`]: props.buttonClassName
    });
    const buttonCaptionClassNames = classNames(`${styles.buttonCaption}`, {
        [`${props.buttonCaptionClassName}`]: props.buttonCaptionClassName
    });

    const onClick = useCallback((item: string) => {
        props.elements.forEach(el => {
            el.checked = el.name === item;
        })
        setElement(item);
        if (props.onClickRadiobutton) {
            props.onClickRadiobutton(item)
        }
    }, [props.onClickRadiobutton, props]);

    const buttonView = useCallback((elem: IFormat) => {
        return <Button
            firstIcon={props.rightIcon ? <></> : elem.checked ? <OnRadioButton /> :
                <OffRadioButton />}
            classNameFirstIcon={!props.rightIcon ? props.classNameIcon : ''}
            secondIcon={!props.rightIcon ? <></> : elem.checked ? <OnRadioButton /> :
                <OffRadioButton />}
            classNameSecondIcon={props.rightIcon ? props.classNameIcon : ''}
            caption={elem.name}
            className={buttonClassNames}
            classNameCaption={buttonCaptionClassNames}
            classNameContent={props.buttonContentClassName}
            onClick={() => onClick(elem.name)}
        />
    }, [buttonClassNames, buttonCaptionClassNames, props.rightIcon, props.classNameIcon, props.buttonContentClassName, onClick]);

    return (
        <div className={wrapClasses}>
            {props.elements.map((elem: IFormat, i: number) => {
                return (
                    <div className={props.elementRadioButton}>
                        {buttonView(elem)}
                        {elem.info && <span className={styles.infoCaption}>{elem.info} </span>}
                        {props.isDivider && i < props.elements.length - 1 && <div className={styles.divider}></div>}
                    </div>
                );
            })
            }

        </div>
    );
});

export default RadioButton;