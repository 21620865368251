import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { selector } from 'store';

import FilterFolderTreeItem from '../filter-folder-tree-item';

import { ArrowToDown,FavouriteStar} from 'assets/icons';

import styles from './favorite-filter-folder.module.css';

const FavoriteFilterFolder = observer(function () {
    const [isOpened, setOpened] = useState(false);

    const arrowToDownClassNames = classNames(styles.folderArrow, {
        [` ${styles.open}`]: isOpened,
    })

    const listClassNames = classNames(styles.folderList, {
        [` ${styles.visible}`]: isOpened,
    })

    const favoriteFilters = useMemo(() => selector.filter.getFilter()?.favoriteFilters, [toJS(selector.filter.getFilter()?.favoriteFilters)]);    

    const openFolder = useCallback(() => {
        setOpened(!isOpened);
    }, [isOpened]);
    
    if (selector.filter.getFilter()?.favoriteFilters.length == 0) {
        return null;
    }

    return (
        <>
            <div onClick={openFolder} className={styles.folderName}>
                <ArrowToDown className={arrowToDownClassNames} />
                <FavouriteStar fill="var(--color-yellow-500)" />
                Избранное
            </div>
            {isOpened && (
                <ul className={listClassNames}>
                    {
                        favoriteFilters?.map((item) => {
                            return (
                                <FilterFolderTreeItem item={item} />
                            );
                        })}
                </ul>
            )}
        </>
    );
});



export default FavoriteFilterFolder;
