import React from 'react';

import styles from './notes.module.css';

export interface INotesProps extends React.HTMLAttributes<HTMLTextAreaElement> {
    name?: string;
    value: string | null;
    isDisabled?: boolean;
    size?: number | null;
    onChangeValue: (value: string) => void;
    onFocusOut?: () => void;
    maxLength?: number
}

function Notes(props: INotesProps) {
    function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        props.onChangeValue(event.target.value);
    }

    let classNames = `${styles.textarea} `;
    if (props.className) {
        classNames += props.className;
    }

    return (
        <textarea
            id={props.id}
            name={props.name}
            className={classNames}
            value={props.value ?? ""}
            placeholder={props.placeholder}
            onChange={handleChange}
        />
    );
}


export default Notes;