import { observer } from "mobx-react-lite";

import { HorizontalField, RadioButton, Toggle } from "components";

import { IFormat } from "entities/IItem";

import styles from "./toggle-with-options.module.css"
import { useEffect, useState } from "react";

/**
 *@description Интерфейс пропсов компонента ToggleWithOptions
 * @param {string} name - Поле HorizontalField name
 * @param {string} caption - Поле HorizontalField caption
 * @param {string} description - Поле HorizontalField infoCaption
 * @param {(value: boolean) => void} onToggle - Что происходит при выборе элемента Toggle
 * @param {boolean} toggleValue - Значение toggle
 * @param {(value: string) => void} onSelect - Что происходит при выборе элемента RadioButton
 * @param {boolean} disabled - Доступен ли toggle
 * @param {IFormat[]} options - Типы результатов стадии
 * @example Пример options: IFormat[] = [
        { id: '', name: 'Нейтральный', checked: true, info: 'Например, «Задача выполнена»' },
        { id: '', name: 'Положительный', checked: false, info: 'Например, лид: «Перевод в продажи»' },
        { id: '', name: 'Отрицательный', checked: false, info: 'Например, лид: «Дисквалифицирован»' }
    ]
 */
interface IToggleWithOptions {
    name: string;
    caption: string;
    description: string;
    onToggle: (value: boolean) => void;
    toggleValue: boolean;
    onSelect: (value: string) => void;
    disabled?: boolean;
    options: IFormat[]
}

export const ToggleWithOptions = observer((props: IToggleWithOptions) => {
    const [toggleValue, setToggleValue] = useState<boolean>(props.toggleValue);

    useEffect(() => {
        setToggleValue(props.toggleValue);
    }, [props.toggleValue])

    return (
        <div className={styles.wrapper}>
            <HorizontalField
                name={props.name}
                caption={props.caption}
                infoCaption={props.description}
                className={styles.field}
            >
                <Toggle
                    checked={props.toggleValue}
                    onCheck={props.onToggle}
                    isDisabled={props.disabled}
                />
            </HorizontalField>
            {toggleValue &&
                <div className={styles.resultTypeContainer}>
                    <div className={styles.divider}> </div>
                    <div className={styles.resultTypes}>
                        <RadioButton
                            elements={props.options}
                            className={styles.radioButtonWrapContainer}
                            classNameIcon={styles.radioButtonIcon}
                            elementRadioButton={styles.elementRadioButton}
                            buttonCaptionClassName={styles.withoutMargin}
                            buttonContentClassName={styles.radioButtonContent}
                            buttonClassName={styles.radioButton}
                            onClickRadiobutton={props.onSelect}
                            rightIcon
                        />
                    </div>
                </div>
            }
        </div>
    );
});