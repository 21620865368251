import FilterColumnType from "entities/ColumnType";
import SectionRoute from "entities/SectionRoute";
import { IQuickViewFilld, QuickViewFilldType } from "features/quick-view-block/constants/constants";

export enum UserRoleType {
    OrganizationRole = 0,
    FunctionalRole = 1
}
export const columns = [
    {
        name: "stage",
        caption: "Стадия",
        width: 15,
        type: FilterColumnType.Stage,
        sortColumn: "stage.order"
    },
    {
        name: "name",
        caption: "Название",
        width: 20,
        type: FilterColumnType.String,
        getLink: (id: string) => `${id}`,
        sortColumn: "name"
    },
    {
        name: "needType",
        caption: "Тип потребности",
        width: 18,
        type: FilterColumnType.Lookup,
        sortColumn: "needType.name"
    },
    {
        name: "owner",
        caption: "Ответственный",
        width: 15,
        type: FilterColumnType.Owner,
        getLink: (id: string) => `/${SectionRoute.User}/${id}`,
        sortColumn: "owner.name"
    },
    {
        name: "account",
        caption: "Контрагент",
        width: 16,
        type: FilterColumnType.Lookup,
        getLink: (id: string) => `/section/${SectionRoute.Account}/${id}`,
        sortColumn: "account.name"
    },
    {
        name: "salesAmount",
        caption: "Сумма продажи",
        width: 7,
        type: FilterColumnType.Amount,
        sortColumn: "salesAmount"
    },
    {
        name: "createdOn",
        caption: "Дата создания",
        width: 9,
        type: FilterColumnType.Date,
        sortColumn: "createdOn"
    },
    {
        name: "lead",
        caption: "Лид",
        width: 20,
        type: FilterColumnType.Lookup,
        sortColumn: "lead.name"
    },
];
export const fields: IQuickViewFilld[] = [
    {
        type: QuickViewFilldType.Input,
        name: "number",
        caption: "Номер",
        isNotValidation: true,
        isNew: true
    },
    {
        type: QuickViewFilldType.SelectFiltering,
        name: "lead",
        lookupName: "Lead",
        caption: "Лид",
        isNotValidation: true
    },
    {
        type: QuickViewFilldType.SelectFiltering,
        name: "account",
        lookupName: "Account",
        caption: "Контрагент",
        required: true
    },
    {
        type: QuickViewFilldType.SelectFiltering,
        name: "contact",
        lookupName: "Contact",
        caption: "Контакт",
        required: true
    },
    {
        type: QuickViewFilldType.SelectFiltering,
        name: "owner",
        lookupName: "User",
        caption: "Ответственный",
        required: false
    },
    {
        type: QuickViewFilldType.Select,
        name: "needType",
        lookupName: "NeedType",
        caption: "Тип потребности",
        required: true
    },
    {
        type: QuickViewFilldType.InputNumber,
        name: "salesAmount",
        caption: "Сумма продажи"
    },
    {
        type: QuickViewFilldType.Note,
        name: "notes",
        caption: "Заметки",
        isNew: true
    }
]
export const infoFields: IQuickViewFilld[] = [
    {
        type: QuickViewFilldType.SelectFiltering,
        name: "priority",
        lookupName: "SalePriority",
        caption: "Приоритет",
        required: true,
        isSorted: true
    },
    // {
    //     type: QuickViewFilldType.Select,
    //     name: "stage",
    //     lookupName: "SaleStage",
    //     caption: "Стадия",
    //     required: true,
    //     isSorted: true
    // },

    {
        name: "createdOn",
        caption: "Дата создания",
        type: QuickViewFilldType.Date,
        isDisabled: true
    },
    {
        name: "modifiedOn",
        caption: "Дата изменения",
        type: QuickViewFilldType.Date,
        isDisabled: true
    },
    // {
    //     name: "createdBy",
    //     caption: "Автор",
    // },

]
export default fields;