import { useCallback, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { Button } from "components";

import { Item } from "types";
import { IListProps, ISettingsSelectProps } from "../types";

import { ArrowToUpThin, Locked, Warning } from "assets/icons";

import styles from "./settings-select.module.css";

function List(props: IListProps) {

	const listClassNames = classNames(`${styles.list} `, {
		[`${styles.visible} `]: props.isOpened,
		[`${props.classNameList} `]: props.classNameList,
	});

	const locked = useCallback((item: Item) => {
		return item.isLocked && <Locked className={styles.lockedIcon} />;
	}, []);

	const warning = useCallback((item: Item) => {
		return item.isWarning && <Warning className={styles.warningIcon} />;
	}, []);

	const captionItem = useCallback((item: Item) => {
		return item.title ? item.title
			: item.displayValue ? item.displayValue
				: item.name;
	}, []);

	const handleClick = useCallback((item: Item) => {
		if (!item.isDisabled || !item.isLocked)
			props.onChangeValue(item);
	}, [props.onChangeValue]);

	const mappingItems = useMemo(() => {
		return props.items.map((item) => {
			const listItemClassNames = classNames(`${styles.listItem} `, {
				[`${styles.selected} `]: props.selected === item.id,
				[`${styles.listItemDisabled} `]: item.isDisabled || item.isLocked,
				[`${styles.exit} `]: item.isRed,
				[`${item.classNames} `]: item.classNames,
			});

			return <div key={item.id}>
				{item.entityName ?
					<Link key={item.id} to={item.entityName} className={listItemClassNames}>
						{captionItem(item)}
						{locked(item)}
						{warning(item)}
					</Link>
					:
					<li key={item.id} className={listItemClassNames} onClick={() => handleClick(item)}>
						{captionItem(item)}
						{locked(item)}
						{warning(item)}
					</li>}
			</div>;
		});
	}, [props.selected, props.items]);

	return (
		<ul className={listClassNames} >
			{mappingItems}
		</ul>

	);
};


function SettingsSelect(props: ISettingsSelectProps) {
	const [isOpened, setOpened] = useState(true);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const wrapClassNames = classNames(`${styles.wrapper} `, {
		[`${props.className} `]: props.className,
	});

	const buttonClassNames = classNames(`${styles.button} `, {
		[`${props.classNameButton} `]: props.classNameButton,
		[`${props.classNameOpenList} `]: props.classNameOpenList && isOpened
	});

	const secondIconClassNames = classNames(`${styles.secondIcon} `, {
		[`${styles.close} `]: isOpened,
	});

	function openMenu() {
		setOpened(!isOpened);
	}

	return (
		<div ref={wrapperRef} className={wrapClassNames}>
			<Button
				classNameSecondIcon={secondIconClassNames}
				className={buttonClassNames}
				secondIcon={<ArrowToUpThin />}
				caption={props.value === null || props.value === undefined ? props.name : props.value.name}
				onClick={() => openMenu()}
				iconOnRight={props.isRightIcon}
				style={props.styles}
				onFocus={props.onFocus}
				onBlur={props.onBlur}
				onMouseEnter={() => { }}
				onMouseLeave={() => { }}
				selected={isOpened}
			/>
			<List
				items={props.items}
				selected={props.selected}
				isOpened={isOpened}
				onChangeValue={props.onChangeValue}
			/>

		</div>
	);
}



export default SettingsSelect;