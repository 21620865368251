interface IFiltres {
    [schema: string]: string
}

class SimpleFilterStore{
    static prefix : string = "SimpleFilter_";
    static setFilter(schema: string, value:string | null){
        if(value === null) {
            window.localStorage.removeItem(this.prefix+schema);
        }
        else {
            window.localStorage.setItem(this.prefix+schema, value);
        }
    }
    static getFilter(schema: string){
        return window.localStorage.getItem(this.prefix+schema);
    }
}

export default SimpleFilterStore; //const filtres : IFiltres = {}