// import api from "app/services/api";
import { api } from "api";
import ListStore from "entities/ListStore";
import { userStore } from "entities/user/UsersSectionStore";
import { makeAutoObservable } from "mobx";

class UserMainPanelStore {
	entityCount: number = 0;
	userChecked: boolean = false;
	userStore: ListStore | undefined;

	constructor() {
		makeAutoObservable(this);
		userStore.load(null);
		this.userStore = userStore;
	}

	async setEntityCount() {
		try {
			const param: any = {
				entityName: "User",
			};
			// let url = `/api/Entity/entitycount`;
			// const data = await api.post(url, param).then(res => res.data);
			const response =  await api.http.httpApi.entity.entityCount().post(param);
			let data = response.data.data;
			if (data !== null) {
				this.entityCount = data;

			} else {
				console.log("Something went wrong :(");
				console.log(data.message);
			}

		} catch (error: any) {
			var message = error.response.message;
			console.error("An error occurred:", error);
		} finally {
		}
	}

	async setValue(value: string | number, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}


}

const userMainPanelStore = new UserMainPanelStore();

export default userMainPanelStore;