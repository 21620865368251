import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { toJS } from "mobx";

import { CheckBox } from "components";

import { StaticGroup, StaticGroupFolder } from "types/entity";

import { ArrowToDown, Folder } from "assets/icons";

import styles from './static-group-block-folder-tree.module.css';




export const StaticGroupBlockFolderTree = observer(function (props: {
    staticGroupFolder?: StaticGroupFolder | null;
    selected: StaticGroup | null;
    setSelected: (value: StaticGroup | null) => void;
}) {
    const { staticGroupFolder, selected, setSelected } = props;

    const [isOpened, setOpened] = useState(false);

    const arrowToDownClassNames = classNames(styles.filterFolderArrow, {
        [`${styles.open}`]: isOpened || staticGroupFolder!.isRoot,
    });

    const listClassNames = classNames(styles.filterFolderList, {
        [`${styles.visible}`]: isOpened || staticGroupFolder!.isRoot,
    });

    const groupsMap = useMemo(() =>
        staticGroupFolder?.groups?.map((item, i) =>
            <li key={i}
                className={styles.group}
                onClick={() => { selectGroup(item) }}
            >
                <CheckBox
                    checked={selected?.id === item.id}
                    onChangeChecked={() => { selectGroup(item) }}
                />
                <span className={styles.groupName}>{item.name}</span>
            </li>
        )
        , [toJS(staticGroupFolder?.groups), selected]);

    const childGroupFoldersMap = useMemo(
        () =>
            staticGroupFolder?.childGroupFolders?.map((item, i) => {
                return (
                    <li key={i} className={styles.childFilterFolder}>
                        <StaticGroupBlockFolderTree
                            staticGroupFolder={item}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </li>
                );
            })
        , [toJS(staticGroupFolder?.childGroupFolders),selected]
    );

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const openFolder = useCallback(() => {
        if (!staticGroupFolder!.isRoot) {
            setOpened(!isOpened);
        }
    }, [isOpened, staticGroupFolder?.isRoot]);

    const selectGroup = useCallback((item: StaticGroup) => {
        if (selected?.id === item.id) {
            setSelected(null);
        }
        else {
            setSelected(item);
        }
    }, [selected?.id]);

    return (
        <>
            <div className={styles.groupFolderName} onClick={openFolder}>
                {!staticGroupFolder!.isRoot && (
                    <ArrowToDown
                        className={arrowToDownClassNames}
                        onClick={openFolder}
                    />
                )}
                <Folder />
                {staticGroupFolder!.name}
            </div>
            {(isOpened || staticGroupFolder!.isRoot) && (
                <ul className={listClassNames}>
                    {groupsMap}
                    {childGroupFoldersMap}
                </ul>
            )}
        </>
    );
});
