import { makeAutoObservable, runInAction } from "mobx";
import UserRole from "entities/userRole/UserRole";
import { api } from "api";
// import api from 'app/services/api';



class FunctionalRolesMainPanelStore {
    filteredUserStore: any[];
    orgRolesStore: any[];
    userRole: UserRole | null = null;
    dialogData: any[];

    LDAPsynchronize: boolean = false;
    LDAPelem: any;

    constructor() {
        makeAutoObservable(this);
        this.filteredUserStore = [];
        this.orgRolesStore = [];
        this.dialogData = [];
    }

    async setValue(value: any, fieldName: string) {
        Reflect.set(this, fieldName, value);
    }

    async setUsersById(id: string) {
        this.filteredUserStore = await this.getUsersByRoleId(id);
    }

    async getUsersByRoleId(id: string) {
        // let request = await api.get(`/api/Administration/users/${id}`);
        let request = await api.http.httpApi.administration.usersByRole(id).get();
        if (request) {
            return request.data;
        }

    }
    async loadOrgRoles(id: string) {
        // let request = await api.get(`/api/Administration/getAllOrgRoleByFuncRole?roleId=${id}`);
        let request = await api.http.httpApi.administration.getAllOrgRoleByFuncRole(id).get();
        if (request) {
            this.orgRolesStore = request.data;
        }

    }
    async addUserInRole(id: string, roles: string[]) {
        let data = {
            userId: id,
            roleIds: roles
        };
        // let request = await api.post("/api/Administration/roles/include/user", data);
        let request = await api.http.httpApi.administration.includeUserInPole().post(data);
        if (request) {
            this.setUsersById(this.userRole?.data?.id!);
            return true;
        }
        else return false;
    }
    async linkOrgAndFuncRole(orgRoleId: string, funcRoleId: string) {
        // let url = "/api/Administration/linkOrgAndFuncRole?orgRoleId=" + orgRoleId + "&funcRoleId=" + funcRoleId;
        // let request = await api.post(url, {});
        let request = await api.http.httpApi.administration.linkOrgAndFuncRole(orgRoleId, funcRoleId).post(null);
        if (request) {
            this.loadOrgRoles(funcRoleId);
            return true;
        }
        else return false;
    }
    async linkFuncAndOrgRoles(funcRoleId: string, orgRoleIds: string[]) {
        if (orgRoleIds.length > 0) {
            let data = {
                funcRoleId: funcRoleId,
                orgRoleIds: orgRoleIds
            };
            console.log(data);

            // let request = await api.post("/api/Administration/linkFuncAndOrgRoles", data);
            let request = await api.http.httpApi.administration.linkOrgAndFuncRoles().post(data);
            if (request) {
                this.loadOrgRoles(funcRoleId);
                return true;
            }
            else return false;
        }
        else this.linkOrgAndFuncRole(orgRoleIds[0], funcRoleId);
    }
}

const functionalRolesMainPanelStore = new FunctionalRolesMainPanelStore();

export default functionalRolesMainPanelStore;