import React, { useEffect, useState } from 'react';

import { SquareCheckBoxDone } from 'assets/icons';

import styles from './check-box.module.css';


interface ICheckBoxProps extends React.HTMLAttributes<Element> {
    icon?: JSX.Element;
    classNameIcon?: string;
    checked: boolean;
    onChangeChecked: (value: boolean) => void;
    block?: boolean
}

function CheckBox(props: ICheckBoxProps) {
    const [checked, setChecked] = useState<boolean>(props.checked);

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    function handleChange(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation(); 
        props.onChangeChecked(!checked);
    }

    let classNames = `${styles.checkbox} `;
    if (checked) {
        if (props.classNameIcon) {
            classNames += props.classNameIcon + " "
        }
        classNames += `${styles.checked} `
    }

    if (props.className) {
        classNames += props.className;
    }

    if (props.block === true) {
        return (
            <div className={classNames} style={{ background: `var(--color-gray-300)`, border: `none` }}>
            </div>
        );
    }
    else
        return (
            <div className={classNames} onClick={handleChange} style={props.style}>
                {checked &&
                    <>
                        {props.icon &&
                            <div className={props.classNameIcon}>
                                {props.icon}
                            </div>
                        }
                        {!props.icon &&
                            <SquareCheckBoxDone className={styles.icon} />
                        }
                    </>
                }
            </div>
        );

}

export default CheckBox;