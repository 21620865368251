import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { v4 } from "uuid";
import { toJS } from "mobx";
import { ceil } from "lodash";

import { dispatcher, store } from "store";
import { modalController } from "features/modals";

import { Button, ButtonStyle, Dropdown, Tooltip } from "components";
import { DeleteTabLayout, RenameTabLayout } from "./tab-layouts";
import { DragTabs } from "./drag-tabs/drag-tabs";

import { DisplayedPanel, TabId, TabsConfig } from "types/entity";
import { Item } from "types";
import { ITab, MAIN_TAB_NAME } from "pages/section-wizzard/data/data";

import { ArrowToRightThin, Plus, SectionMasterLookupIcon, Warning } from "assets/icons";

import styles from "./tabs-with-pagination.module.css";

interface ITabsWithPagination {
	items: ITab[];
	className?: string;
	styleButton?: ButtonStyle;
	onAddClick?: () => void;
	onMoveBoxTab?: (id: string, value: boolean, currentDisplayedPanel?: DisplayedPanel) => void
}

const WARNING_TEXT = "Вы действительно покинуть редактирование таба?\nТекущий прогресс будет утерян";
const RIGHT_ACTION_BUTTONS_WIDTH = 101;
const LEFT_ACTION_BUTTON_WIDTH = 40;

export function TabsWithPagination(props: ITabsWithPagination) {
	const [currentEditableTab, setCurrentEditableTab] = useState<ITab>();
	const [isFirstTabVisible, setFirstTabVisible] = useState<boolean>(true);
	const [isLastTabVisible, setLastTabVisible] = useState<boolean>(false);

	const [idConfirm] = useState<string>(v4());
	const [idModal] = useState<string>(v4());

	const itemBarRef = useRef<HTMLDivElement>(null);
	const clearTime = useRef<NodeJS.Timeout>();
	const [variableDropDownItems, setVariableDropDownItems] = useState<Item[]>([]);

	const dropDownItems = useMemo((): Item[] => {
		if (itemBarRef.current) {
			setFirstTabVisible(itemBarRef.current!.scrollLeft == 0);
			setLastTabVisible(ceil(itemBarRef.current!.scrollLeft) == (itemBarRef.current!.scrollWidth - itemBarRef.current!.clientWidth));
		}
		return props.items.map((item, index) => {
			return {
				id: index,
				name: item.caption
			}
		});
	}, [toJS(props.items), currentEditableTab]);

	const itemBarClasses = classNames(`${styles.itemBar} `, {
		[`${props.className} `]: props.className,
	});

	const sectionWizzard = useMemo(() => {
		return dispatcher.sectionWizzard.getSectionWizzard();
	}, [dispatcher.sectionWizzard.getSectionWizzard]);

	const tabsConfig = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.tabsConfig ?? [];
	}, [sectionWizzard?.reactorConfig.tabs.tabsConfig.map(tab => tab)]);

	const currentTabIndex = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
	}, [sectionWizzard?.reactorConfig.tabs.currentTab]);


	const moreItems: Item[] = useMemo(() => {
		let isLocked = false;
		const moveToOtherPanel = sectionWizzard?.displayedPanel === DisplayedPanel.Main ? "Перенести на вспом. панель" : "Перенести на основн. панель"
		if (currentEditableTab?.tabName === MAIN_TAB_NAME) {
			isLocked = true;
			return [
				{
					id: 'rename',
					name: "rename",
					title: "Переименовать",
					isSeparator: true
				},
				{
					id: 'delete',
					name: "delete",
					title: "Удалить",
					isRed: true,
					isLocked: isLocked
				}
			]
		}
		else if (currentEditableTab?.tabName === TabId.Files || currentEditableTab?.tabName === TabId.Comments
			|| currentEditableTab?.tabName === TabId.Chronology || currentEditableTab?.tabName === TabId.LinkedRecords) {
			isLocked = true;
			return [
				{
					id: 'rename',
					name: "rename",
					title: "Переименовать"
				},
				{
					id: 'move',
					name: "move",
					title: moveToOtherPanel,
					isSeparator: true
				},
				{
					id: 'delete',
					name: "delete",
					title: "Удалить",
					isRed: true,
					isLocked: isLocked
				}
			]
		}
		else {
			return [
				{
					id: 'rename',
					name: "rename",
					title: "Переименовать",
					isSeparator: true
				},
				{
					id: 'delete',
					name: "delete",
					title: "Удалить",
					isRed: true,
					isLocked: isLocked
				}
			]
		}

	}, [currentEditableTab, sectionWizzard?.displayedPanel, toJS(props.items)]);

	useEffect(() => {
		dispatcher.sectionWizzard.setCurrentTab(0)
		setTimeout(() => { props.items[0].action() }, 200);
	}, [dispatcher.sectionWizzard.getSectionWizzard()?.displayedPanel]);

	useEffect(() => {
		if (props.items.length > 0) {
			if (props.items[currentTabIndex]) {
				props.items[currentTabIndex].action();
				dispatcher.sectionWizzard.setCurrentTab(currentTabIndex);
			}
		}
	}, [props.items.length, currentTabIndex]);

	const itemClasses = useCallback((i: number) => {
		return classNames(`${styles.tabButton} `, {
			[`${styles.selected} `]: currentTabIndex === i,
		});
	}, [currentTabIndex]);

	const handleClick = useCallback((index: number) => {
		dispatcher.sectionWizzard.setCurrentTab(index);
		props.items[index].action();

		if (itemBarRef.current) {
			const childNodes = Array.from((itemBarRef.current.childNodes[0].childNodes as NodeListOf<HTMLDivElement>));
			let scrollLeft = 0;
			const sumOfWidthUpCurrentTab = childNodes.slice(0, index).reduce((acc, tab: HTMLDivElement) => acc + tab.offsetWidth, 0);
			const isPartOfTabBeyondRightPart = ((sumOfWidthUpCurrentTab + childNodes[index].offsetWidth) > (itemBarRef.current!.clientWidth + itemBarRef.current!.scrollLeft - LEFT_ACTION_BUTTON_WIDTH - RIGHT_ACTION_BUTTONS_WIDTH))

			if (!isPartOfTabBeyondRightPart) {

				const isPartOfTabBeyondLeftPart = (sumOfWidthUpCurrentTab < (itemBarRef.current!.scrollLeft + LEFT_ACTION_BUTTON_WIDTH));

				if (!isPartOfTabBeyondLeftPart) {
					return;
				}

				scrollLeft = itemBarRef.current!.scrollLeft - sumOfWidthUpCurrentTab + LEFT_ACTION_BUTTON_WIDTH;
				itemBarRef.current.scrollLeft -= scrollLeft;
			}
			else {
				scrollLeft = (sumOfWidthUpCurrentTab + childNodes[index].offsetWidth) - (itemBarRef.current!.clientWidth + itemBarRef.current!.scrollLeft - RIGHT_ACTION_BUTTONS_WIDTH);
				itemBarRef.current.scrollLeft += scrollLeft;
			}

			clearTime.current = setTimeout(() => {
				setFirstTabVisible(itemBarRef.current!.scrollLeft == 0);
				setLastTabVisible(ceil(itemBarRef.current!.scrollLeft) == (itemBarRef.current!.scrollWidth - itemBarRef.current!.clientWidth));
				if (clearTime.current) clearTimeout(clearTime.current);
			}, 200);
		}
	}, [toJS(props.items), itemBarRef.current]);

	const closeAllModals = useCallback(() => {
		store.modals.map((modal) => {
			modalController.modalRemove(modal.id);
		});
	}, [store.modals]);

	const closeConfirm = useCallback(() => {
		modalController.modalRemove(idConfirm);
	}, [idConfirm]);

	const warningConfirm = useMemo(() => {
		return <div className={styles.warningDialog}>
			<div className={styles.warningHeader}>
				<span className={styles.warningTitle}>Внимание</span>
				<Warning />
			</div>
			<div className={styles.warningDialogBody}>
				<span className={styles.title}>{WARNING_TEXT}</span>
			</div>
			<div className={styles.dialogFooter}>
				<Button caption="Вернуться к редактированию" onClick={closeConfirm} style={ButtonStyle.Subtle}
					isDisabled={false} />
				<Button caption="Да, отменить" onClick={closeAllModals} style={ButtonStyle.Danger} isDisabled={false} />
			</div>
		</div>;
	}, [closeAllModals, closeConfirm, dispatcher.entity.get()?.entity.sectionWizzard]);

	const handleClose = useCallback(() => {
		modalController.popupAdd({ id: idConfirm, layout: warningConfirm, closeFunc: closeConfirm });
	}, []);

	const renameTab = useCallback((tab: ITab) => {
		const currentTabConfig = tabsConfig.find(findedTab => findedTab.tabName === tab.tabName);
		if (currentTabConfig) {
			const newTab: TabsConfig = {
				...currentTabConfig,
				tabTitle: tab.caption,
			};
			dispatcher.sectionWizzard.setTabByName(newTab)
		}
		else {
			const additional = dispatcher.sectionWizzard.getAdditionalFromConfig();

			if (additional![tab.tabName as TabId]) {
				dispatcher.sectionWizzard.setNewTitleAdditionalTab(tab.tabName as TabId, tab.caption)
			}
		}
		closeAllModals();
	}, [closeAllModals]);

	const deleteTab = useCallback(() => {
		if (currentEditableTab) {
			dispatcher.sectionWizzard.deleteTabByName(currentEditableTab.tabName);
			closeAllModals();
		}
	}, [closeAllModals, currentEditableTab]);

	const switchPanel = useCallback(() => {
		const newDisplayedPanel =
			sectionWizzard?.displayedPanel == DisplayedPanel.Main ? DisplayedPanel.Additional
				: DisplayedPanel.Main;
		if (sectionWizzard) {
			dispatcher.sectionWizzard.switchDisplayedPanel(newDisplayedPanel);
		}
	}, [sectionWizzard, sectionWizzard?.displayedPanel]);


	const handleClickToMoreInRound = useCallback((value: Item | null) => {
		if (value?.id === 'rename') {
			currentEditableTab && modalController.popupAdd({
				id: idModal,
				layout: <RenameTabLayout
					currentTab={currentEditableTab}
					close={handleClose}
					save={renameTab}
				/>,
				closeFunc: closeConfirm
			});
		}
		else if (value?.id === 'move') {
			if (props.onMoveBoxTab) {
				switchPanel();
				props.onMoveBoxTab(currentEditableTab?.tabName!, true, sectionWizzard?.displayedPanel);
			}
		} else {
			currentEditableTab && modalController.popupAdd({
				id: idModal,
				layout: <DeleteTabLayout
					close={closeAllModals}
					delete={deleteTab}
				/>,
				closeFunc: closeConfirm
			});
		}
	}, [idModal, handleClose, renameTab, closeConfirm, currentEditableTab]);

	const handleSetCurrentEditableTab = useCallback((value: ITab, index: number, e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e?.stopPropagation();
		setCurrentEditableTab(value);
		if (itemBarRef.current) {
			const childNodes = Array.from((itemBarRef.current.childNodes[0].childNodes as NodeListOf<HTMLDivElement>));
			const childNodesOfTab = Array.from((childNodes[index].childNodes[0].childNodes[0].childNodes[0].childNodes as NodeListOf<HTMLDivElement>));
			const childNodesOfSelect = Array.from((childNodesOfTab[2].childNodes as NodeListOf<HTMLDivElement>));

			const sumOfWidthUpCurrentTab = childNodes.slice(0, index + 1).reduce((acc, tab: HTMLDivElement) => acc + tab.offsetWidth, 0);

			const leftForTab = sumOfWidthUpCurrentTab - childNodesOfTab[2].offsetWidth - itemBarRef.current.scrollLeft;
			const leftForLastTab = sumOfWidthUpCurrentTab - childNodesOfTab[2].offsetWidth - itemBarRef.current.scrollLeft - RIGHT_ACTION_BUTTONS_WIDTH;
			childNodesOfSelect[1].style.left = (index == childNodes.length - 1) ? `${leftForLastTab}px` : `${leftForTab}px`;
		}
	}, [currentEditableTab, itemBarRef.current]);

	const handleSwitchClick = useCallback((isLeftScroll: boolean) => {
		if (!itemBarRef.current) {
			return null;
		}

		let sumOfWidthsUpFirstOrLastTab = 0;
		let scrollLeft = 0;

		const childNodes = (itemBarRef.current.childNodes[0].childNodes as NodeListOf<HTMLDivElement>);
		if (isLeftScroll) {
			childNodes.forEach((tab: HTMLDivElement) => {
				if ((sumOfWidthsUpFirstOrLastTab + tab.offsetWidth) < (itemBarRef.current!.clientWidth + itemBarRef.current!.scrollLeft - LEFT_ACTION_BUTTON_WIDTH)) {
					sumOfWidthsUpFirstOrLastTab += tab.offsetWidth;
				}
				else if (scrollLeft == 0) {
					scrollLeft = (sumOfWidthsUpFirstOrLastTab + tab.offsetWidth) - (itemBarRef.current!.clientWidth + itemBarRef.current!.scrollLeft - RIGHT_ACTION_BUTTONS_WIDTH);;
				}
			});
			itemBarRef.current.scrollLeft += scrollLeft;
		}
		else {
			childNodes.forEach((tab: HTMLDivElement) => {
				if (scrollLeft == 0) {
					if ((sumOfWidthsUpFirstOrLastTab + tab.offsetWidth) < itemBarRef.current!.scrollLeft + LEFT_ACTION_BUTTON_WIDTH) {
						sumOfWidthsUpFirstOrLastTab += tab.offsetWidth;
					}
					else {
						scrollLeft = itemBarRef.current!.scrollLeft - sumOfWidthsUpFirstOrLastTab + LEFT_ACTION_BUTTON_WIDTH;
					}
				}
			});
			itemBarRef.current.scrollLeft -= scrollLeft;
		}

		clearTime.current = setTimeout(() => {
			setFirstTabVisible(itemBarRef.current!.scrollLeft == 0);
			setLastTabVisible(ceil(itemBarRef.current!.scrollLeft) == (itemBarRef.current!.scrollWidth - itemBarRef.current!.clientWidth));
			if (clearTime.current) clearTimeout(clearTime.current);
		}, 200);

	}, [toJS(props.items), itemBarRef]);

	const handleDropDownChangeValue = useCallback((value: Item | null) => {
		if (value) {
			handleClick(Number(value.id))
		}

	}, [props.items, itemBarRef.current]);

	const handleItemsLoad = useCallback(async (value: string | null) => {
		if (value === null) {
			setVariableDropDownItems(dropDownItems);
			return dropDownItems.length;
		}
		let newItems = dropDownItems.filter((x) => x.name.includes(value));
		setVariableDropDownItems(newItems);
		return newItems.length;

	}, [dropDownItems]);

	return (
		<div className={styles.tab}>
			<div className={itemBarClasses} ref={itemBarRef}>
				<DragTabs
					items={props.items}
					moreItems={moreItems}
					getItemClass={itemClasses}
					onClick={handleClick}
					onClickToMoreInRound={handleClickToMoreInRound}
					setCurrentEditableTab={handleSetCurrentEditableTab}
				/>
			</div>
			<div className={styles.leftActionButton}>
				<Tooltip tooltip="Предыдущие вкладки">
					<Button
						firstIcon={<ArrowToRightThin />}
						className={styles.arrowToLeftButton}
						style={ButtonStyle.GrayBlue300}
						onClick={() => { handleSwitchClick(false) }}
						isDisabled={isFirstTabVisible}
					/>
				</Tooltip>
			</div>
			<div className={styles.rightActionButtons}>
				<Tooltip tooltip="Следующие вкладки">
					<Button
						firstIcon={<ArrowToRightThin />}
						className={styles.actionButton}
						style={ButtonStyle.GrayBlue300}
						onClick={() => { handleSwitchClick(true) }}
						isDisabled={isLastTabVisible}
					/>
				</Tooltip>
				<Dropdown
					items={variableDropDownItems}
					onChangeValue={handleDropDownChangeValue}
					onItemsLoad={handleItemsLoad}
					firstIcon={<SectionMasterLookupIcon />}
					classNameButton={styles.actionButton}
					className={styles.actionButton}
					styles={ButtonStyle.GrayBlue300}
					tooltip="Список вкладок"
				/>
				{props.onAddClick &&
					<>
						<div className={styles.actionDivider} />
						<Tooltip tooltip="Добавить вкладку">
							<Button
								firstIcon={<Plus />}
								className={styles.addNewTab}
								style={ButtonStyle.GrayBlue300}
								onClick={props.onAddClick}
							/>
						</Tooltip>
					</>
				}
			</div>
		</div>
	);
}
