import { CSSProperties, useMemo } from "react";
import { observer } from "mobx-react";

import { store } from "store";
import { Position } from "../controller";
import { NotificationCenter, NotificationLeft } from "./notifications";
import { Popup } from "./popup";

export enum ModalType {
	NOTIFICATION = "NOTIFICATION",
	POPUP = "POPUP",
	LARGE_POPUP = "LARGE_POPUP",
}
export type ModalData = {
	id: string;
	type?: ModalType;
	position?: Position;
	queue?: number;
	layout?: JSX.Element;
	style?: CSSProperties;
	allowTimer?: boolean;
	allowDefaultClick?: boolean;
	closeFunc?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	withBackdrop?: boolean;
}

export const ModalViewer = observer(() => {
	const padding = 64;
	const notificationStyle = (index: number) => { return { bottom: `calc(${padding * index}px + 40px)` }; };

	const notificationsLeft = useMemo(() => {
		let notificationsLeft: ModalData[] = [];

		store.modals.forEach((modal, index) => {
			if (modal.modal.type === ModalType.NOTIFICATION && modal.modal.position === Position.LEFT) {
				notificationsLeft.push({ id: modal.id, layout: modal.modal.layout, queue: index });
			}
		});

		return notificationsLeft.map((notification, index) => (<NotificationLeft
			key={index}
			{...notification}
			style={notificationStyle(index)}
		/>));
	}, [store.modals.map(i => i)]);

	const notificationsCenter = useMemo(() => {
		let notificationsCenter: ModalData[] = [];

		store.modals.forEach((modal, index) => {
			if (modal.modal.type === ModalType.NOTIFICATION && modal.modal.position === Position.CENTER) {
				notificationsCenter.push({
					id: modal.id, layout: modal.modal.layout,
					allowTimer: modal.modal.allowTimer, allowDefaultClick: modal.modal.allowDefaultClick,
					withBackdrop: modal.modal.withBackdrop,
				});
			}
		});

		return notificationsCenter.map((notification, index) => (<NotificationCenter
			key={index}
			{...notification}
		/>));
	}, [store.modals.length, store.modals.map(i => i)]);

	const popUp = useMemo(() => {
		let popups: ModalData[] = [];

		store.modals.forEach((modal, index) => {
			if (modal.modal.type === ModalType.POPUP || modal.modal.type === ModalType.LARGE_POPUP) {
				popups.push({ layout: modal.modal.layout, id: modal.id, closeFunc: modal.modal.closeFunc, type: modal.modal.type });
			}
		});

		return popups.map((popup, index) => (<Popup
			key={index}
			closeFunc={popup.closeFunc}
			isLargePopup={popup.type === ModalType.LARGE_POPUP}
		>
			{popup.layout}
		</Popup>));
	}, [store.modals.map(i => i)]);

	return (<>
		{notificationsLeft}
		{notificationsCenter}
		{popUp}
	</>);
});