import { filterTableType } from "../data/FilterTable";
import { GetSchemes } from "../data/SearchSettings";

import { makeAutoObservable } from "mobx";
import ISearchItem from "entities/search/model/SearchItem";
import { api } from "api";
// import Api from "app/services/api";

class SearchStore {
	entities: ISearchItem[] = [];
	isLoaded: boolean = false;
	isLoadedBatch: boolean = true;
	isError: boolean = false;
	errorMessage: string = "";
	filtres: string[] = [filterTableType.Account, filterTableType.Contact, filterTableType.Lead, filterTableType.Sale];
	count: number = 0;
	currentBatch = 0;
	batchSize = 30;


	constructor() {
		makeAutoObservable(this);
	}

	async load(value: string, customFilters?: string[]) {
		this.isLoaded = false;
		this.isError = false;
		this.currentBatch = 0;
		// let result = await Api.post("/api/GlobalSearch/search", {
		//     "schemes": GetSchemes(customFilters ?? this.filtres),
		//     "searchValue": value,
		//     "batch": this.currentBatch,
		//     "batchSize": this.batchSize
		// })
		let result =await api.http.httpApi.globalSearcher.search().put({
			"schemes": GetSchemes(customFilters ?? this.filtres),
			"searchValue": value,
			"batch": this.currentBatch,
			"batchSize": this.batchSize
		});
		this.isLoaded = true;
		/*    if(result.status === 200){
                console.log(result.data.data);
                
                this.entities = result.data.data;
                this.count = result.data.count;
                this.currentBatch++;
            }
            else{
                this.isError = true;
                this.errorMessage = result.statusText;
            }*/
		this.entities = result.data.data;
		this.count = result.data.data.count;
		this.currentBatch++;
	}

	async loadMore(value: string) {
		this.isError = false;
		this.isLoadedBatch = false;
		// let result = await Api.post("/api/GlobalSearch/search", {
		// 	"schemes": GetSchemes(this.filtres),
		// 	"searchValue": value,
		// 	"batch": this.currentBatch,
		// 	"batchSize": this.batchSize
		// });

		let result =await api.http.httpApi.globalSearcher.search().put({
			"schemes": GetSchemes(this.filtres),
			"searchValue": value,
			"batch": this.currentBatch,
			"batchSize": this.batchSize
		});
		this.isLoadedBatch = true;
		/*   if(result.status === 200){
               this.entities = this.entities.concat(result.data.data);
               this.count = result.data.count;
               this.currentBatch++;
           }
           else{
               this.isError = true;
               this.errorMessage = result.statusText;
           }*/
		this.entities = this.entities.concat(result.data.data);
		this.count = result.data.data.count;
		this.currentBatch++;
	}
}

const searchStore = new SearchStore();

export default searchStore;