import { observer } from "mobx-react";
import { useState } from "react";

import RightExpression from "./right-expression/right-expression";

import { CheckBox, InputStyleName, SearchSelect, SelectStyleName } from "components";

import { IFilterStore } from "entities/filter/FilterStore";
import {ComparisonType,LogicalOperation} from "entities/filter/IFilter";
import { Item } from "types";

import {DeleteIcon,Duplicate,} from "assets/icons";

import styles from "./filter-conditions.module.css";


function getDisplayValue(operation: LogicalOperation) {
    if (operation === LogicalOperation.And) {
        return "И";
    }
    return "ИЛИ";
}

const FilterConditions = observer(function (props: {
    filter: IFilterStore | null;
}) {
    const { filter} = props;

    if (filter === null) {
        return null;
    }

    const add = () => {
        filter!.addFilter();
    };

    return (
        <div className={styles.filterConditions}>
            {filter!.filters.length > 0 && (
                <div className={styles.filterGroup}>
                    <div className={styles.filterGroupLeft}>
                        <div
                            className={styles.filterGroupLogicalOperation}
                            onClick={() => {
                                filter!.changeLogicalOperation();
                            }}
                        >
                            {getDisplayValue(filter!.logicalOperation)}
                        </div>
                        <div className={styles.filterGroupUnion} />
                    </div>
                    <div className={styles.filterGroupRight}>
                        {filter!.filters.map((item) => {
                            return <Condition filter={item} />;
                        })}
                    </div>
                </div>
            )}
        </div>
    );
});

const Condition = observer(function (props: {
    filter: IFilterStore;
}) {
    const { filter } = props;

    return (
        <div className={styles.condition}>
            <div className={styles.actionsForConditionsBlock}>
                <div className={styles.deleteAction}>
                    <span className={styles.tooltip} style={{ left: "-23px" }}>Удалить</span>
                    <DeleteIcon className={styles.actionForConditionItem} onClick={() => { filter.delete(); }} />
                </div>

                <CheckBox checked={filter.isActive} onChangeChecked={(value: boolean) => { filter.changeActive(); }} />

                <div className={styles.dublicateAction}>
                    <span className={styles.tooltip} >Дублировать</span>
                    <Duplicate className={styles.actionForConditionItem} style={{ height: "18px", width: "18px" }} onClick={() => { filter.dublicate(); }} />
                </div>
            </div>

            <div className={styles.conditionDivider} />
            <div className={styles.conditionBody}>
                <SelectProperty filter={filter} />
                <Comparisons templateConditions={filter} />
                {filter.comparisonType !== ComparisonType.IsNotNull &&
                    filter.comparisonType !== ComparisonType.IsNull && (
                        <RightExpression
                            isDisabled={!filter.property.propertyValue.value || filter.comparisonType === null}
                            filter={filter}
                            isAdvancedFilter={true}
                        />
                    )}
            </div>
        </div>
    );
});

const SelectProperty = observer((props: { filter: IFilterStore }) => {
    const property = props.filter.property;
    const propertyItems = property.items;

    let value = props.filter.property.propertyValue.value;

    const [items, setItems] = useState<Item[]>(propertyItems);

    return (
        <SearchSelect
            isInput={true}
            items={items}
            onChangeValue={(value: Item | null) => {
                props.filter.selectAttributeOrDetail(value);
            }}
            value={value}
            onItemsLoad={async (s) => {
                if (s === null) {
                    setItems(propertyItems);
                    return propertyItems.length;
                }
                let newItems = propertyItems.filter((x) => x.displayValue?.includes(s));
                setItems(newItems);
                return newItems.length;
            }}
            isListDelay={true}
            className={styles.searchSelect}
            classNameInput={styles.searchSelectInput}
            placeholder="Колонка"
            inputStyle={InputStyleName.QuickViewInput}
            selectStyle={SelectStyleName.QuickViewSelect}
        />
    );
});

const Comparisons = observer((props: { templateConditions: IFilterStore }) => {
    let comparisonItems = props.templateConditions.comparators;
    let value = props.templateConditions.comparisonType;
    const [items, setItems] = useState<Item[]>(comparisonItems);

    function getComparatorDisplayValue() {
        const comparator = comparisonItems.find((item) => item.id === value);
        return comparator;
    }

    return (
        <SearchSelect
            isInput={true}
            items={items}
            onChangeValue={(value: Item | null) => {
                props.templateConditions.selectComparator(value);
            }}
            onItemsLoad={async (s) => {
                if (s === null) {
                    setItems(comparisonItems);
                    return comparisonItems.length;
                }
                let newItems = comparisonItems.filter((x) => x.name?.includes(s));
                setItems(newItems);
                return 3;
            }}
            value={getComparatorDisplayValue()}
            placeholder={"Условие"}
            isDisabled={!props.templateConditions.property.propertyValue.value}
            isListDelay={true}
            className={styles.searchSelect}
            classNameInput={styles.searchSelectInput}
            inputStyle={InputStyleName.QuickViewInput}
            selectStyle={SelectStyleName.QuickViewSelect}
        />
    );
});

export default FilterConditions;
