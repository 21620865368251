import { Toggle } from "components";

import styles from "./toggle-block.module.css";

const ToggleBlock = () => {
    return (<div className={styles.toggleBlock}>
        <div className={styles.editToggle}>
            <span className={styles.headerToggle}>Разрешить редактирование реестра </span>
            <span className={styles.infoToggle}>Изменяйте значения полей не открывая запись</span>
        </div>
        <Toggle
            checked={false}
            onCheck={() => { }}
            isDisabled={true}
        />
    </div>);
}
export default ToggleBlock