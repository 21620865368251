import axios, { AxiosHeaders, AxiosInstance, AxiosResponse } from "axios";
import { runInAction } from "mobx";

import { sessionStore, store } from "store";
import { CLIENT_ERROR } from "../errors";

import authStore from "AuthStore";
import TokenManager from "api/token-manager";
import QueueManager from "api/queue-manager";
import ResponseService from "entities/Response";

const tokenManager = new TokenManager("/api/User/refresh-token");
const queueManager = new QueueManager();

class Api {
	private server: string;
	private currentPath: string;
	private axiosInstance: AxiosInstance;

	constructor() {
		this.server = "";
		this.currentPath = "";
		this.axiosInstance = this.createAxiosInstance();
	}

	private getHeaders = () => {
		let state = store.session;
		return {
			Authorization: `Bearer ${store.session.accessToken}`,
			"content-type": "application/json; charset=utf-8",
			...AxiosHeaders,
		};
	};

	/**
 	* @return {Object} AxiosInstance - Экземпляр Axios
 	* @property {function} interceptors.request.use - Перехватчик перед отправкой запроса
 	* @property {function} interceptors.response.use - Перехватчик после получения ответа
 	*/
	private createAxiosInstance(): AxiosInstance {
		const axiosInstance = axios.create({
			withCredentials: true,
		});

		axiosInstance.interceptors.request.use((config) => {
			//   config.headers = this.getHeaders();
			if (authStore.token) {
				config.headers["Authorization"] = `Bearer ${authStore.token}`;
			}
			return config;
		});

		axiosInstance.interceptors.response.use(
			(response: AxiosResponse) => response,
			async (error) => {
				const originalConfig = error.config;
				if (error.response.status === 401 && !originalConfig._retry) {
					if (queueManager.getRefreshing()) {
						return new Promise((resolve, reject) => {
							queueManager.addToQueue(resolve, reject);
						})
							.then((token) => {
								originalConfig.headers["Authorization"] = "Bearer " + token;
								return axiosInstance(originalConfig);
							})
							.catch((err) => {
								return Promise.reject(err);
							});
					}
					originalConfig._retry = true;
					queueManager.setRefreshing(true);

					return new Promise((resolve, reject) => {
						tokenManager
							.refreshToken()
							.then(() => {
								originalConfig.headers[
									"Authorization"
								] = `Bearer ${authStore.token}`;
								queueManager.processQueue(null, authStore.token);
								resolve(axiosInstance(originalConfig));
							})
							.catch((err) => {
								queueManager.processQueue(err, null);
								reject(err);
							})
							.then(() => {
								queueManager.setRefreshing(false);
							});
					});
				}

				return Promise.reject(error);
			}
		);

		return axiosInstance;
	}

	setPath(endpoint: string) {
		this.currentPath = `${this.server}${endpoint}`;
	}

	private handleResponse(response: AxiosResponse) {
		return response;
	}

	//TODO надо определиться что мы будем возвращать при ответе отличном от 200. То возвращалось ({status:CLIENT_ERROR, data:{success: false}}) 
	//не позваляет в полной мере использовать информацию об ошибке с сервера. Я думаю что мы должны возвращать error.response,
	//т.к. в случае ошибки на серевере формируется объект с кодом ошибки и информации по ней
	private handleError(error: any):ResponseService {
		// return {
		// 	status: CLIENT_ERROR,
		// 	data: { success: false },
		// };
		return error.response;
	}

	get() {
		return this.axiosInstance
			.get(this.currentPath)
			.then(this.handleResponse)
			.catch(this.handleError);
	}

	post(reqBody: any) {
		return this.axiosInstance
			.post(this.currentPath, reqBody)
			.then(this.handleResponse)
			.catch(this.handleError);
	}

	put(reqBody: any) {
		return this.axiosInstance
			.put(this.currentPath, reqBody)
			.then(this.handleResponse)
			.catch(this.handleError);
	}

	delete() {
		return this.axiosInstance
			.delete(this.currentPath)
			.then(this.handleResponse)
			.catch(this.handleError);
	}

	getWithParams(params?: any){
		return this.axiosInstance
			.get(this.currentPath,{ params:params })
			.then(this.handleResponse)
			.catch(this.handleError);
	}

	deleteWithData(requestData:any){
		return this.axiosInstance
			.delete(this.currentPath,{ data:requestData })
			.then(this.handleResponse)
			.catch(this.handleError);
	}

	downloadFile(requestData:any, fileName:string){
		this.axiosInstance({
			url: this.currentPath,
			method: "POST",
			responseType: "blob", // important
			data: requestData,
			headers: { Authorization: `Bearer ${authStore.token}` },
		}).then((response) => {
			//Функционал старта загрузки файла на фронте
			var file = new Blob([response.data], {
				type: "application/octet-stream",
			});
			const url = window.URL.createObjectURL(file);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", fileName); //or any other extension
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		});
	}
	// get(): Promise<any> {
	// 	try {
	// 		return axios.get(this.currentPath, {
	// 			headers: this.getHeaders(),
	// 			withCredentials: true,
	// 		}).catch((serverError) => {
	// 			console.error(`Server error. Status ${serverError.response.status}`);
	// 			if (serverError.response.status === 401) {
	// 				// authStore.logOut(); Зачем?
	// 			};
	// 			return { status: CLIENT_ERROR, data: { success: false } };
	// 		});
	// 	} catch (error) {
	// 		console.error(error);
	// 		return new Promise(() => {
	// 			return {
	// 				status: CLIENT_ERROR
	// 			};
	// 		});
	// 	}
	// };



	// post(reqBody: any) {
	// 	try {
	// 		return axios.post(this.currentPath, reqBody, {
	// 			headers: this.getHeaders(),
	// 			withCredentials: true,
	// 		}).catch((serverError) => {
	// 			if (serverError.response.status === 401) {
	// 				//authStore.logOut();
	// 			};
	// 			return { status: CLIENT_ERROR, data: { success: false } };
	// 		});;
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };



	// put(reqBody: any) {
	// 	try {
	// 		return axios.put(this.currentPath, reqBody, {
	// 			headers: this.getHeaders(),
	// 			withCredentials: true,
	// 		}).catch((serverError) => {
	// 			if (serverError.response.status === 401) {
	// 				//authStore.logOut();
	// 			};
	// 			return { status: CLIENT_ERROR, data: { success: false } };
	// 		});
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };



	// delete() {
	// 	try {
	// 		return axios.delete(this.currentPath, {
	// 			headers: this.getHeaders(),
	// 			withCredentials: true,
	// 		}).catch((serverError) => {
	// 			if (serverError.response.status === 401) {
	// 				//authStore.logOut();
	// 			};
	// 			return { status: CLIENT_ERROR, data: { success: false } };
	// 		});
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

}

export default Api;
