import App from "app/App";
import Invite from "pages/invite/Invite";
import Login from "pages/login/Login";

export const privateRoutes = [
	{ path: "/", component: <App /> },
	{ path: "/*", component: <App /> },
];

export const publicRoutes = [
	{ path: "/login", component: <Login /> },
	{ path: "/*", component: <Login /> },

	{ path: "/invite/:id", component: <Invite /> },
];
