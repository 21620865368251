import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { toJS } from 'mobx';

import importStore from 'pages/import/core/import-store';

import { Button, ButtonStyle, Tooltip } from 'components';

import { ArrowToDownGrey, InfoIcon } from 'assets/icons';

import styles from './required-fields-block.module.css';

interface RequiredFieldsBlockProps {
    requiredList: any[];
    usingColumns: string[];
    choosing: number;
}

function HeadBlock(props: { arrowButtonClassNames: string, openList: () => void }) {

    const headColumns = useMemo(() => {
        return `Обязательные колонки в [${importStore.entity?.displayValue}]`
    }, [importStore.entity?.displayValue]);

    return (
        <div className={styles.headBlock}>
            <span className={styles.headColumns}>{headColumns}</span>
            <Tooltip tooltip='Поля ниже отмечены как обязательные для заполнения. Импортировать без них неполучится'>
                <Button
                    classNameFirstIcon={styles.infoIcon}
                    className={styles.infoIconButton}
                    firstIcon={<InfoIcon />}
                    style={ButtonStyle.Icon}
                />
            </Tooltip>
            <Button
                className={props.arrowButtonClassNames}
                firstIcon={<ArrowToDownGrey />}
                style={ButtonStyle.Icon}
                onClick={props.openList}
            />
        </div>
    );
};

const RequiredFieldsBlock = observer((props: RequiredFieldsBlockProps) => {
    const [listIsOpened, setListOpened] = useState(false);

    const listClassNames = classNames(`${styles.list}`, {
        [`${styles.visible} `]: listIsOpened,
    });

    const arrowButtonClassNames = classNames(`${styles.arrowButton} `, {
        [`${styles.close} `]: listIsOpened,
    });

    const choosingColumnsText = useMemo(() => {
        return `Выбрано: ${props.choosing} / ${props.requiredList.length}`
    }, [props.choosing, props.requiredList.length]);

    const columnTitle = useCallback((column: any) => {
        return `${column.columnTitle} [${column.columnName}]`
    }, []);

    const requiredList = useMemo(() => {
        return props.requiredList.map((column) => {
            const columnNameClasses = classNames(`${styles.columnName} `, {
                [`${styles.greenColumnName} `]: props.usingColumns.includes(column.columnName),
            });

            return (
                <span className={columnNameClasses}>{columnTitle(column)} </span>
            );
        })
    }, [toJS(props.requiredList), toJS(props.usingColumns)]);

    const handleOpenListClick = useCallback(() => {
        if (listIsOpened) {
            setListOpened(false);
        }
        else {
            setListOpened(true);
        }
    }, [listIsOpened]);

    const hiddenList = useMemo(() => {
        const wrapClasses = classNames(`${styles.wrapper} `);
        return <div className={wrapClasses}>
            <div className={styles.headRequiredFieldsBlock}>
                <HeadBlock arrowButtonClassNames={arrowButtonClassNames} openList={handleOpenListClick} />
            </div>
        </div>
    }, [listIsOpened, arrowButtonClassNames, handleOpenListClick]);

    const openList = useMemo(() => {
        return <div className={styles.wrapper}>
            <div className={styles.headRequiredFieldsBlock}>
                <HeadBlock arrowButtonClassNames={arrowButtonClassNames} openList={handleOpenListClick} />
                <span className={styles.choosingColumns}>{choosingColumnsText}</span>
                <div className={styles.divider}></div>
            </div>
            <div className={listClassNames}>
                {requiredList}
            </div>
        </div>
    }, [listIsOpened, arrowButtonClassNames, handleOpenListClick, choosingColumnsText]);

    return (
        <>{listIsOpened ? openList : hiddenList}</>
    );
}
);
export default RequiredFieldsBlock;
