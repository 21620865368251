import { UpFirst } from "entities/lowFirst";
import { v4 } from "uuid";
import { isEmpty, isNull, isObject, lowerCase } from "lodash";
import { dispatcher, DISPLAY, Entity, selector, sessionStore, store } from "store";
import { entity, savedFilter, staticGroup, systemDetailDesigner, user, stage } from "../api/http/endpoints";
import { Item, SubscribeType } from "types";
import { api } from "api";
import authStore from "../AuthStore"; // TODO Временный костыль для частичного общения store
import { BasicEntity, Column, DetailConfig, DetailDesignerType, DisplayedPanel, EntityNameType, ExcludeRecordsFromStaticGroup, Filter, LoadingState, OptionPage, SavedFilterFolder, SectionWizzard, StaticGroup, StaticGroupFolder, TabId, TabState, ViewColumn } from "types/entity";
import { MAIN_TAB_NAME, SettingName } from "pages/section-wizzard/data/data";
import { SortDirection } from "entities/ISort";
import { ColumnType } from "entities/ColumnType";
import { LowFirst } from "entities/lowFirst";
import { action, makeObservable, observable, toJS } from "mobx";
import { IFilterStore } from "entities/filter/FilterStore";
import SavedFilter from "entities/filter/SavedFilter";
import { sortArray } from "app/services/SortService";


// TODO Коменты при low 3g не отображаются отправленные, до загрузки всех
// TODO ADD в коментах, которые добавили мы


const FIELD_DEFAULT_VALUE = "defaultValue";
export const NEW_RECORD = "new";
interface UpdateRowRequest {
	entityName: string,
	values: Array<{ propertyName: string, propertyValue: string }>
}

class Synchroiser {
	currentCommentsSubscribe: any;
	loadingState: LoadingState;
	errorMessage: string | null;

	constructor() {
		makeObservable(this, { loadingState: observable, errorMessage: observable, getEntityWithFilter: action });
		this.currentCommentsSubscribe = null;
		this.loadingState = LoadingState.NotAsked;
		this.errorMessage = null;
	}

	auth = async (name: string, password: string) => {
		if (sessionStore.checkAuth()) {
			const authData = sessionStore.getAuth();
			if (!authData) {
				console.error("Ошибка synchroiser auth");
			} else {
				store.user = {
					expires: authData.expires,
					id: authData.id,
					message: authData.message,
					username: authData.userName,
				};

				store.session.accessToken = authData.tokens.accessToken;
				store.session.refreshToken = authData.tokens.refreshToken;
			}
		} else {
			await user.userAuthenticate().post({ name: name, password: password })?.then((req: any) => {
				// TODO Скрыть токен, ну или допилить процессы по записыванию
				if (!req.data.changePassword) {
					store.user = {
						expires: req.data.expires,
						id: req.data.id,
						message: req.data.message,
						userName: req.data.username
					};

					store.session.accessToken = req.data.accessToken;
					store.session.refreshToken = req.data.refreshToken;

					sessionStore.setAuth({
						expires: req.data.expires,
						id: req.data.id,
						message: req.data.message,
						userName: req.data.username,
						tokens: {
							accessToken: req.data.accessToken,
							refreshToken: req.data.refreshToken
						}
					});

					this.postSuccessfulAuthSync();
				}

			});
		}

	};

	// refreshToken = () => {

	// 	api.http.httpApi.entity.recordsList().get();
	// 	const removeCookie = (name: string) => {
	// 		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
	// 	};

	// 	// @ts-ignore
	// 	api.http.httpApi.user.refreshToken().post(null).then((req: any) => {
	// 		store.session.accessToken = req.data.accessToken;
	// 		store.session.tokenExpiration = req.data.expires;
	// 		store.session.tokenExpired = false;
	// 	}).catch((error: any) => {
	// 		const err = error as AxiosError;
	// 		if (err.response && err.response.status === 401) {
	// 			store.session.tokenExpired = true;
	// 			removeCookie("refreshToken");
	// 		} else {
	// 			removeCookie("refreshToken");
	// 			authStore.logOut();
	// 		}
	// 		console.error(error);
	// 	});
	// };


	getSectionsList = async () => {
		const sectionsList = await api.http.httpApi.section.entitySectionDataList().get();
		const newSections = sectionsList?.data.data;

		const favoriteSections = await api.http.httpApi.favoriteSection.favoriteSections().get();
		if (favoriteSections?.data?.data) favoriteSections.data.data.forEach((favoriteSection: any) => {
			newSections.forEach((section: any) => {
				if (section.id === favoriteSection.sectionId) {
					section.isFavorite = true;
				}
			});
		});

		store.sections = newSections
	};

	getUser = (userId: string) => {
		api.http.httpApi.user.userById(userId).get()?.then((req: any) => {
			store.users.push(req.data);
		});
	};

	entityRowReactions = (prevStoreEntityRow: any[], newStoreEntityRow: any) => {
	};

	setWithoutSessionStoreValues = () => {
		/**
		 * @description На этом этапе он нас авторизует на основе store
		 */
		if (sessionStore.checkAuth()) {
			store.user = sessionStore.getAuth();
			// @ts-ignore
			store.session.accessToken = sessionStore.getAuth().tokens.accessToken;
			// @ts-ignore
			store.session.tokenExpiration = sessionStore.getAuth().tokens.accessToken;
			store.session.tokenExpired = false;
			this.getSectionsList();

		}
	};

	getEntity = async () => {
		this.loadingState = LoadingState.Loading;
		let currentEntityName = store.sections.find((section: any) => section.id === store.currentEntityId)?.entityName;

		if (currentEntityName) {
			const newEntity: {
				columns: Array<Column>,
				viewColumn: ViewColumn | null,
				rows: [],
				quality: number,
				visibleColumns: any[],
				display: [],
				filterTree: null,
				sort: {},
				filter: Filter | null,
				comments: [],
				sectionWizzard: SectionWizzard | null,
				isCheckedAll: boolean,
				includedIds: [],
				excludedIds: [],
				countOfChecked: number
			} = {
				columns: [],
				viewColumn: null,
				rows: [],
				filterTree: null,
				quality: 0,
				sort: dispatcher.entity.get()?.entity.sort || { columnPath: "createdOn", direction: SortDirection.Descending },
				visibleColumns: [],
				display: [],
				filter: null,
				comments: [],
				sectionWizzard: null,
				isCheckedAll: false,
				includedIds: [],
				excludedIds: [],
				countOfChecked: 0
			};

			// @ts-ignore
			newEntity.display = DISPLAY[currentEntityName];




			await entity.entityData().post({
				entityName: currentEntityName
			})?.then((req: any) => {
				newEntity.columns = req.data.data.columns.map((i: any) => i);
				newEntity.viewColumn = req.data.data.viewColumn;
			});

			let filterForList = null;
			const filter = sessionStore.getFilter(currentEntityName);
			newEntity.filter = filter;

			if (newEntity.filter && newEntity.filter.savedFilter && newEntity.filter.savedFilter.filterInfo) {
				filterForList = newEntity.filter.savedFilter.filterInfo;
			}

			await api.http.httpApi.sectionViewPageSettings.getSectionByUserAndEntity(store.user.id, currentEntityName)?.get()?.then((req: any) => {
				let combinedColumns: any[] = [];
				newEntity.visibleColumns = [];
				if (req.data.success && req.data.data) {
					combinedColumns = req.data.data.columnSettings.map((columnSettings: any) => {
						const column = { ...newEntity.columns.find(column => column.columnName.toLowerCase() === columnSettings.columnName.toLowerCase()) } as Column | undefined;
						if (column && column.columnName) {
							column.columnName = column.columnName.charAt(0).toLowerCase() + column.columnName.slice(1, column.columnName.length);
							return {
								...columnSettings,
								...column
							};
						}
						return { ...columnSettings, columnName: columnSettings.columnName.charAt(0).toLowerCase() + columnSettings.columnName.slice(1, columnSettings.columnName.length), };
					});
				}
				newEntity.visibleColumns = combinedColumns;
			}).catch(error => {
				this.loadingState = LoadingState.Error;
				console.error(error);
			});

			const dispatcherSort = dispatcher.entity.get()?.entity?.sort

			const rows = entity.recordsListWithColumns().post({
				canbanColumn: null,
				columnNames: newEntity.visibleColumns.map(visibleColumn => visibleColumn.columnName),
				entityName: currentEntityName,
				filter: filterForList,
				limit: 30,
				offset: 0,
				sort: isEmpty(dispatcherSort) ? { columnPath: "createdOn", direction: 1 } : dispatcherSort,
				staticGroupId: newEntity.filter?.staticGroup?.id || null,
			})?.then((req: any) => {
				if (req?.data?.success) {
					newEntity.rows = req.data.data.records.map((i: any) => i);
				}
			});

			const columns = entity.entityCount().post({
				entityName: currentEntityName,
				filter: filterForList,
				staticGroupId: newEntity.filter?.staticGroup?.id || null,
			})?.then((req: any) => {
				newEntity.quality = req.data.data;
			});

			const filtersList = this.getFiltersList(newEntity, currentEntityName);
			const savedFilterFolderTree = this.getSavedFilterFolderTree(newEntity, currentEntityName);
			const staticGroupFolderTree = this.getStaticGroupFolderTree(newEntity, currentEntityName);

			await Promise.all([rows, columns, filtersList, savedFilterFolderTree, staticGroupFolderTree]).then(() => {
				this.entityRowReactions(dispatcher.entity.get()?.entity?.rows || [], newEntity.rows);
				const currentSection = store.sections.find(section => section.id === store.currentEntityId);
				if (currentSection) {
					dispatcher.entity.set({
						id: store.currentEntityId,
						entityName: currentSection.entityName,
						entityTitle: currentSection.displayValue,
						entity: newEntity,
						isKanban: currentSection.hasCanban,
						isNew: false,
					});
					sessionStore.setEntities(store.entities);
					this.loadingState = LoadingState.Successful;
				}
				else {
					this.loadingState = LoadingState.Error;
					console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				}
			});


		} else {
			this.loadingState = LoadingState.Error;
			console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
		}
	};

	switchSection = async (name: string) => {
		if (store.sections && store.sections.find((section: any) => section.entityName.toLowerCase() === name.toLowerCase())) {
			dispatcher.entity.switchSectionByName(name);
			await this.getEntity();
		} else {
			this.loadingState = LoadingState.Error;
			console.error(`${name} в sections не существует`);
		}
	};

	// switchEntity = async (name: string) => {
	// 	if (store.sections && store.entities.find((section: any) => section.entityName.toLowerCase() === name.toLowerCase())) {
	// 		dispatcher.entity.switchEntityByName(name);
	// 		await this.getEntity();
	// 	} else {
	// 		this.loadingState = LoadingState.Error;
	// 		console.error(`${name} в sections не существует`);
	// 	}
	// };

	switchSectionWizzard = async (name: string): Promise<void> => {
		let sectionWiz = dispatcher.sectionWizzard.generateNew();
		if (store.sections.find((section: any) => section.entityName.toLowerCase() === name.toLowerCase())) {
			dispatcher.entity.switchSectionByName(name);
			const entity = dispatcher.entity.get();
			if (!entity) {
				await this.getEntity();
			}
			let newEntity = {
				...dispatcher.entity.get()?.entity!,
			};

			const sectionWizzard = await api.http.httpApi.systemDesigner.getSystemDesigner(dispatcher.entity.get()?.entityName!)
				.get()?.then((req: any) => {
					return req.data.data;
				});

			newEntity = {
				...newEntity,
				sectionWizzard: {
					...sectionWizzard,
					optionPage: OptionPage.GlobalSettings,
					displayedPanel: DisplayedPanel.Main,
					currentTab: 0
				}
			};
			if (!sectionWizzard.accessRightsConfig && newEntity.sectionWizzard) {
				newEntity.sectionWizzard = {
					...newEntity.sectionWizzard,
					accessRightsConfig: {
						adminByOperation: {
							isEnabled: false,
							operationItems: []
						},
						adminByRecords: {
							isEnabled: false,
							recordItems: []
						}
					}
				};
			}
			dispatcher.entity.setBasicEntity(newEntity);
		}
		else {
			const id = v4();
			const currentEntity: BasicEntity = {
				columns: [],
				viewColumn: null,
				rows: [],
				filterTree: null,
				quality: 0,
				sort: {},
				visibleColumns: [],
				display: {},
				filter: null,
				sectionWizzard: sectionWiz,
				isCheckedAll: false,
				includedIds: [],
				excludedIds: [],
				countOfChecked: 0
			};
			const newSection = {
				id: id,
				entityName: "",
				entityTitle: "",
				entity: currentEntity,
				isKanban: false,
				visibleColumns: [],
				isNew: true,
			};


			dispatcher.entity.set(newSection);
			dispatcher.entity.switchById(id);
		}
	};

	startSyncWithComments = (rowId: string) => {
		if (this.currentCommentsSubscribe) {
			// this.currentCommentsSubscribe.stopConnection();
		}

		const subscale = new api.ws.SignalRService("SubscribeToFullView", rowId);
		subscale.startConnection();
		subscale.addEventListener("FullViewCreated", (id: any, req: any) => {
			const parsedRequest = JSON.parse(req);
			const date = new Date().toISOString();

			api.http.httpApi.user.userById(parsedRequest.UserId).get()?.then((req: any) => {
				dispatcher.comments.add({
					// @ts-ignore
					id: parsedRequest.Id,
					// @ts-ignore
					userId: parsedRequest.UserId,
					entityId: dispatcher.entity.get()!.id,
					entityName: dispatcher.entity.get()!.entityName,	// TODO Допилить
					// @ts-ignore
					text: parsedRequest.Text,
					userName: req.data.name,	// TODO Допилить
					isOwner: store.user.id === parsedRequest.UserId,
					createdOn: date,
					modifiedOn: date
				});

				dispatcher.subscribers.add(parsedRequest.Id);
				dispatcher.reactions.set(parsedRequest.Id, SubscribeType.ADD);
			});
		});
		subscale.addEventListener("FullViewUpdated", (fullViewId: any, updatedCommentId: any, updatedFieldsJson: any) => {
			const date = new Date().toISOString();
			dispatcher.comments.edit(updatedCommentId, JSON.parse(updatedFieldsJson).Text, date);
		});
		subscale.addEventListener("FullViewDelited", (rowId: string, req: string) => {
			dispatcher.comments.remove(req);
		});

		this.currentCommentsSubscribe = subscale;
	};

	getComments = () => {
		let currentEntityName = store.sections.find((section: any) => section.id === store.currentEntityId)?.entityName;
		if (currentEntityName) {
			currentEntityName = currentEntityName.charAt(0).toUpperCase() + currentEntityName.slice(1, currentEntityName.length - 1);
			api.http.httpApi.comment.getComments(currentEntityName, store.currentRowId).get()?.then((req: any) => {
				// @ts-ignore
				dispatcher.comments.set(req.data?.data);
				this.startSyncWithComments(store.currentRowId);
			});
		} else {
			console.error("store section is empty");
		}

	};

	sendEntityRow = (rowProperties: Array<{ propertyName: string, propertyValue: string }>) => {
		const currentEntityName = store.sections.find((section: any) => section.id === store.currentEntityId)?.entityName;

		this.entityRowReactions(
			dispatcher.entity.get()?.entity?.rows || [],
			dispatcher.entity.get()?.entity?.rows
				.map(
					(row: any) => row.id !== rowProperties
						.find((newRow: { propertyName: string, propertyValue: string }) => newRow.propertyName === "Id")
						?.propertyValue ? row : rowProperties) || []
		);

		entity.updateEntity().post({
			entityName: currentEntityName,
			values: rowProperties,
		})?.then((req: any) => {

		});
	};

	setRow = (body: any) => {
		api.http.httpApi.entity.createEntity().post(body)?.then((req) => {
			store.oldRowValue = body;
		}).catch(error => {
			console.error(error);
			dispatcher.currentRow.rollback();
		});

	};



	/**
	  * Обновляет или создает запись в соответствии с заданными значениями.
	  *
	  * @async
	  * @param {UpdateRowRequest} body - Объект запроса для обновления записи.
	  * @param {Array<{ propertyName: string, propertyValue: string }>} body.values - Массив значений для обновления строки.

	  * @returns {Promise<any>} Результат выполнения запроса на обновление строки.
	  */

	updateRow = async () => {
		//TODO Пока не понятно как передавать параметр в функцию из реактора. Буду получать прям здесь
		const body = this.getUpdateBody();

		//TODO убрать, когда в this.getUpdateBody() будет сделано добавление сущности в массив store.sections или store.entities
		if (!body) {
			return null;
		}
		try {
			const currentRecordId = store.currentRowId;
			if (!currentRecordId || currentRecordId === NEW_RECORD) {
				const response = await entity.createEntity().post({ ...body, values: body.values.filter(value => Object.keys(value.propertyValue || {}).length) });

				if (response && response.data.success) {
					let recordId = response?.data.data;

					const newRow = { ...dispatcher.currentRow.get(), id: recordId };
					//Обновление записи на фронте
					dispatcher.entity.get()?.entity.rows.splice(dispatcher.entity.get()?.entity.rows.findIndex(row => row.id === NEW_RECORD)!, 1, newRow);
					dispatcher.currentRow.switch(recordId);

					const arrayOfNavigation = document.location.pathname.split("/");
					arrayOfNavigation[arrayOfNavigation.length - 1] = recordId;
					document.location.replace(arrayOfNavigation.join("/"));
				}

			} else {
				body.values.push({ propertyName: "Id", propertyValue: currentRecordId });
				await entity.updateEntity().post({ ...body, values: body.values.filter(value => Object.keys(value.propertyValue).length) });
			}

			let oldvalue = dispatcher.currentRow.get();
			store.oldRowValue = oldvalue;

			store.hasChanges = false;
			store.resetChangedFields();


		} catch (err) {
			console.error(err);
		}

	};

	//TODO по-хорошему в дальнейшем пробрасывать синхройзер в реактор
	/**
	  * Обновляет или создает запись в соответствии с заданными значениями без редиректа на эту запись.
	  *
	  * @async
	  * @param {UpdateRowRequest} body - Объект запроса для обновления записи.
	  * @param {Array<{ propertyName: string, propertyValue: string }>} body.values - Массив значений для обновления строки.

	  * @returns {Promise<any>} Результат выполнения запроса на обновление строки.
	  */

	updateRowWithoutRedirect = async () => {
		//TODO Пока не понятно как передавать параметр в функцию из реактора. Буду получать прям здесь
		const body = this.getUpdateBody();

		//TODO убрать, когда в this.getUpdateBody() будет сделано добавление сущности в массив store.sections или store.entities
		if (!body) {
			return null;
		}
		try {
			const currentRecordId = store.currentRowId;
			if (!currentRecordId || currentRecordId === NEW_RECORD) {
				const response = await entity.createEntity().post({ ...body, values: body.values.filter(value => Object.keys(value.propertyValue || {}).length) });

				if (response && response.data.success) {
					let recordId = response?.data.data;

					const newRow = { ...dispatcher.currentRow.get(), id: recordId };
					//Обновление записи на фронте
					dispatcher.entity.get()?.entity.rows.splice(dispatcher.entity.get()?.entity.rows.findIndex(row => row.id === NEW_RECORD)!, 1, newRow);
					dispatcher.currentRow.switch(recordId);
				}

			} else {
				body.values.push({ propertyName: "Id", propertyValue: currentRecordId });
				await entity.updateEntity().post({ ...body, values: body.values.filter(value => Object.keys(value.propertyValue).length) });
			}

			let oldvalue = dispatcher.currentRow.get();
			store.oldRowValue = oldvalue;

			store.hasChanges = false;
			store.resetChangedFields();


		} catch (err) {
			console.error(err);
		}

	};

	/*
	* Фильтрация row - только поля
	* Временное решение проблемы с тем, что в row попадают названия табов
	*/
	filterValues(): Record<string, any> {
		const row: Record<string, any> = dispatcher.currentRow.get()!;
		let values: Record<string, any> = [];
		Object.entries(row).forEach(([fieldName, value]) => {
			const arrTabIds = Object.entries(TabId);
			if (!arrTabIds.find(arr => arr[0].toLowerCase() === fieldName.toLowerCase())) {
				values.push([UpFirst(fieldName), value]);
			}
		}, []);
		return values;
	}
	/*
	* Формирует тело запроса на обновление или создание записи
	*
	* @returns {UpdateRowRequest} Тело запроса для обновления или создания записи
	*/
	getUpdateBody(): UpdateRowRequest | null {
		const nameOfSection = store.sections.find((section: any) => section.id === store.currentEntityId)?.entityName;
		const nameOfEntity = store.entities.find((entity: any) => entity.id === store.currentEntityId)?.entityName;
		const currentEntityName = nameOfSection ?? nameOfEntity;
		const currentRecordId = store.currentRowId;

		//TODO возможно сделать добавление сущности в массив store.sections или store.entities
		if (!currentEntityName) {
			return null;
		}

		let values: Array<{ propertyName: string; propertyValue: string }> = [];

		if (!currentRecordId || currentRecordId === NEW_RECORD) {
			const row = this.filterValues(); /* Временное решение проблемы с тем, что в row попадают названия табов */
			values = row.reduce((acc: { propertyName: string; propertyValue: any; }[], [fieldName, value]: any) => {
				if (fieldName.toLowerCase() !== "id") {
					let propertyValue = value;
					if (typeof value === "object" && !isNull(value) && value.hasOwnProperty("id")) {
						propertyValue = value.id;
					}
					acc.push({
						propertyName: UpFirst(fieldName),
						propertyValue: propertyValue
					});
				}
				return acc;
			}, [] as Array<{ propertyName: string; propertyValue: string }>);
		} else {
			values = store.changedFields.map((item) => {
				return {
					propertyName: UpFirst(item.fieldName),
					propertyValue: item.value
				};
			});
		}

		let updateRequestBody: UpdateRowRequest = {
			entityName: currentEntityName,
			values: values
		};

		return updateRequestBody;
	}

	getRow = async (entityName: string, id: string) => {
		this.loadingState = LoadingState.Loading;
		let currentEntity = store.entities.find(entity => entity.entityName.toLowerCase() === entityName.toLowerCase());

		if (!currentEntity) {
			//TODO подумать над тем, что тут могут быть не только разделы
			await this.switchSection(entityName);
			this.loadingState = LoadingState.Loading;
			currentEntity = dispatcher.entity.get();
		}
		if (currentEntity!.id !== store.currentEntityId) {
			dispatcher.entity.switchById(currentEntity!.id);
		}

		await entity.entityData().post({
			entityName: currentEntity?.entityName
		})?.then((req: any) => {
			currentEntity!.entity.columns = req.data.data.columns.map((i: any) => i);
			currentEntity!.entity.viewColumn = req.data.data.viewColumn;
		});


		await api.http.httpApi.systemDesigner.getSystemDesigner(currentEntity!.entityName).get()?.then((response) => {
			if (currentEntity && response && response.data.success) {
				currentEntity.entity.sectionWizzard = response.data.data;
			}
		});

		if (currentEntity && currentEntity.entity.sectionWizzard?.stageModelConfig) {
			await api.http.httpApi.stage.getSectionFromDesigner(currentEntity!.entityName).get()?.then((response) => {
				if (currentEntity && currentEntity.entity.sectionWizzard && response && response.data.success) {
					currentEntity.entity.sectionWizzard.stageModelConfig!.movingRules = response.data.data.movingRules;
					currentEntity.entity.sectionWizzard.stageModelConfig!.stages = response.data.data.stages;
				}
			});
		}

		if (id === NEW_RECORD) {
			const columns = currentEntity!.entity.columns;
			const newRow: { [key: string]: any } = {};

			const defaultValues = await this.getDefaultRowValue(currentEntity!.entity.sectionWizzard);

			columns.forEach(column => {
				newRow[LowFirst(column.columnName)] = defaultValues[LowFirst(column.columnName)] ?? null;
			});
			newRow.id = NEW_RECORD;
			currentEntity!.entity.rows.push(newRow);

			dispatcher.entity.set(currentEntity!);
			dispatcher.currentRow.switch(newRow.id);
			store.oldRowValue = newRow;
			this.loadingState = LoadingState.Successful;
		}
		else {
			await api.http.httpApi.entity.getRecord().post({
				entityName: currentEntity?.entityName,
				entityId: id
			})?.then((req) => {
				const newRow = req.data.data;

				if (isEmpty(newRow)) {
					this.loadingState = LoadingState.Error;
					return;
				}
				else {
					if (currentEntity?.entity.rows.find(row => row.id === newRow.id)) {
						currentEntity?.entity.rows.splice(currentEntity?.entity.rows.findIndex(row => row.id === newRow.id), 1, newRow);
					}
					else {
						currentEntity?.entity.rows.push(newRow);
					}
					dispatcher.entity.set(currentEntity!);

					dispatcher.currentRow.switch(newRow.id);
					store.oldRowValue = newRow;
					this.loadingState = LoadingState.Successful;
				}
			}).catch(error => {
				console.error(error);
				dispatcher.currentRow.rollback();
				this.loadingState = LoadingState.Error;


			});
		}
	};

	getDefaultRowValue = async (data: any, defaultValues = {}) => {
		const values: { [key: string]: any } = defaultValues;

		const getDefaultLookupValue = async (fieldName: string) => {
			await api.http.httpApi.entity.getRecordWithColumns().post({
				entityName: data["lookupTable"],
				entityId: data[FIELD_DEFAULT_VALUE],
				columnNames: []
			})?.then((req) => {
				values[fieldName] = req.data.data;
			}).catch(error => {
				console.error(error);
			});
		};


		if (data) {
			await Promise.all(Object.keys(data).map(async (item) => {
				if (item === "columnName") {
					if (data["columnType"] === ColumnType.Lookup && !isNull(data[FIELD_DEFAULT_VALUE]) && data[FIELD_DEFAULT_VALUE].length > 0) {
						await getDefaultLookupValue(LowFirst(data[item]));
					}
					else {
						values[LowFirst(data[item])] = data[FIELD_DEFAULT_VALUE];
					}
				} else if (isObject(data[item])) {
					await this.getDefaultRowValue(data[item], values);
				}

			}));
		}
		return values;
	};

	/**
	 * @description получение json детали
	 * @param entityName - системное название детали 
	 * @returns объект детали
	 */
	getDetailConfig = async (entityName: string): Promise<DetailDesignerType | null> => {
		const systemDetailDesignerResponse = await systemDetailDesigner.getSystemDetailDesigner(entityName).get();

		if (!systemDetailDesignerResponse || !systemDetailDesignerResponse.data.success) {
			return null;
		}

		const result: DetailDesignerType = {
			columnsInfo: systemDetailDesignerResponse.data.data.columnsInfo.map((item: any) => item),
			detailConfig: this.replaceKeys((JSON.parse(systemDetailDesignerResponse.data.data.detailConfig))),
			isSection: systemDetailDesignerResponse.data.data.isSection
		}
		return result;
	};

	/**
	 * @description ВРЕМЕННОЕ РЕШЕНИЕ! метод для замены первой буквы у всех ключей в json детали на нижний регистр
	 * @param value - объект детали/из детали
	 * @returns json детали, у которой все ключи начинаются с нижнего регистра
	 */
	replaceKeys = (value: any): any => {
		return value instanceof Array
			? value.map(valueItem => {
				if (valueItem instanceof Object) {
					return this.replaceKeys(valueItem)
				}
				return LowFirst(valueItem)
			})
			: value instanceof Object
				? Object.fromEntries(Object
					.entries(value)
					.map(n => [LowFirst(n[0]), this.replaceKeys(n[1])])
				)
				: value;
	}

	/**
	 * @description получение json детали и ее записи
	 * @param id - id записи для получения
	 * @returns объект детали
	 */
	getDetailFromConfig = async (id: string): Promise<DetailDesignerType | null> => {
		this.loadingState = LoadingState.Loading;
		const currentEntity = dispatcher.entity.get();
		if (!currentEntity) {
			this.loadingState = LoadingState.Error;
			return null;
		}
		const systemDetailDesignerResponse = await this.getDetailConfig(currentEntity.entityName);

		if (!systemDetailDesignerResponse) {
			return null;
		}

		currentEntity.entity.columns = systemDetailDesignerResponse.columnsInfo;

		if (id === NEW_RECORD) {
			const columns = currentEntity!.entity.columns;
			const newRow: { [key: string]: any } = {};

			const defaultValues = await this.getDefaultRowValue(systemDetailDesignerResponse.detailConfig);

			columns.forEach(column => {
				newRow[LowFirst(column.columnName)] = defaultValues[LowFirst(column.columnName)] ?? null;
			});
			newRow.id = NEW_RECORD;
			currentEntity!.entity.rows.push(newRow);

			dispatcher.entity.set(currentEntity!);
			dispatcher.currentRow.switch(newRow.id);
			store.oldRowValue = newRow;
			this.loadingState = LoadingState.Successful;
		}
		else {
			if (!systemDetailDesignerResponse.isSection) {
				await entity.getRecord().post({
					entityName: currentEntity?.entityName,
					entityId: id
				})?.then((req) => {
					const newRow = req.data.data;

					if (isEmpty(newRow)) {
						this.loadingState = LoadingState.Error;
						return;
					}
					else {
						if (currentEntity?.entity.rows.find(row => row.id === newRow.id)) {
							currentEntity?.entity.rows.splice(currentEntity?.entity.rows.findIndex(row => row.id === newRow.id), 1, newRow);
						}
						else {
							currentEntity?.entity.rows.push(newRow);
						}
						dispatcher.entity.set(currentEntity!);

						dispatcher.currentRow.switch(newRow.id);
						store.oldRowValue = newRow;
						this.loadingState = LoadingState.Successful;
					}
				}).catch(error => {
					console.error(error);
					dispatcher.currentRow.rollback();
					this.loadingState = LoadingState.Error;


				});

			}
		}
		return systemDetailDesignerResponse;
	};

	/**
	* @description удаление записи из таблицы
	* @param entityName - название таблицы в БД
	* @param entityId - id записи, которую необходимо удалить
	**/
	deleteRecord = async (entityName: string, entityId: string) => {
		try {
			const deleted = await api.http.httpApi.entity.deleteRecord().post(
				{
					entityName: entityName,
					entityId: entityId
				}
			);
			return deleted.data.success;
		} catch (error: any) {
			if (error.response.status === 401) {
				console.error(error.response);
			}
		}
	};

	/**
	* @description удаление выделенных записей в GeneralizedGrid
	**/
	deleteRecords = async (entity?: Entity) => {
		try {
			let promises: Promise<boolean>[] = [];
			const entityOfDeleteItems = entity ?? dispatcher.entity.get();
			if (entityOfDeleteItems) {
				const includedIds = entityOfDeleteItems.entity.includedIds;
				const isCheckedAll = entityOfDeleteItems.entity.isCheckedAll;
				const excludedIds = entityOfDeleteItems.entity.excludedIds;
				if (includedIds.length > 0)
					promises = includedIds.map(async (element) => {
						const deleted = await this.deleteRecord(
							entityOfDeleteItems.entityName,
							element.id
						);
						return deleted;
					});
				else if (isCheckedAll) {
					if (excludedIds.length > 0)
						promises = entityOfDeleteItems.entity.rows.map(async (item) => {
							let index = excludedIds.findIndex(
								(exc) => exc.id === item.id
							);
							let deleted = true;
							if (index === -1)
								deleted = await this.deleteRecord(
									entityOfDeleteItems.entityName,
									item.id
								);
							return deleted;
						});
					else
						promises = entityOfDeleteItems.entity.rows.map(async (item) => {
							const deleted = await this.deleteRecord(
								entityOfDeleteItems.entityName,
								item.id
							);
							return deleted;
						});
				}
				await Promise.all(promises);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response);
			}
		}
	};

	/**
	* @description дублировать запись в таблице
	* @param entityName - название таблицы в БД
	* @param entityId - id записи, которую необходимо дублировать
	**/
	dublicateRecord = async (entityName: string, recordId: string) => {
		try {
			const response = await api.http.httpApi.entity.dublicateRecord().post(
				{
					entityName: entityName,
					recordId: recordId
				}
			);
			return response.data.success;
		} catch (error: any) {
			if (error.response.status === 401) {
				console.error(error.response);
			}
		}
	};

	/**
	 * @description дублирование выделенных записей в GeneralizedGrid
	 */
	dublicateRecords = async () => {
		try {
			let promises: Promise<boolean>[] = [];
			const entity = dispatcher.entity.get();
			if (entity) {
				const includedIds = entity.entity.includedIds;
				const isCheckedAll = entity.entity.isCheckedAll;
				const excludedIds = entity.entity.excludedIds;
				if (includedIds.length > 0)
					promises = includedIds.map(async (element) => {
						const response = await this.dublicateRecord(
							entity.entityName,
							element.id
						);
						return response;
					});
				else if (isCheckedAll) {
					if (excludedIds.length > 0)
						promises = entity.entity.rows.map(async (item) => {
							let index = excludedIds.findIndex(
								(exc) => exc.id === item.id
							);
							let response = true;
							if (index === -1)
								response = await this.dublicateRecord(
									entity.entityName,
									item.id
								);
							return response;
						});
					else
						promises = entity.entity.rows.map(async (item) => {
							const response = await this.dublicateRecord(
								entity.entityName,
								item.id
							);
							return response;
						});
				}
				await Promise.all(promises);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response);
			}
		}
	};

	/**
	 * @description Метод для применения фильтра
	 */
	getEntityWithFilter = async () => {
		this.loadingState = LoadingState.Loading;
		const currentEntity = { ...dispatcher.entity.get() } as Entity;
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось применить фильтр";
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}

			const rows = entity.recordsListWithColumns().post({
				canbanColumn: null,
				columnNames: currentEntity.entity.visibleColumns.map((visibleColumn: any) => visibleColumn.columnName),
				entityName: currentEntity.entityName,
				filter: currentEntity.entity.filter?.savedFilter?.filterInfo?.serialize() || null,
				limit: 30,
				offset: 0,
				sort: dispatcher.entity.get()?.entity?.sort || { columnPath: "createdOn", direction: 1 },
				staticGroupId: currentEntity.entity.filter?.staticGroup?.id || null,
			})?.then((response: any) => {
				currentEntity.entity!.rows = response.data.data.records.map((i: any) => i);
			});

			const columns = entity.entityCount().post({
				entityName: currentEntity.entityName,
				filter: currentEntity.entity.filter?.savedFilter?.filterInfo?.serialize() ?? null,
				staticGroupId: currentEntity.entity.filter?.staticGroup?.id || null,
			})?.then((req: any) => {
				currentEntity.entity.quality = req.data.data;
			});

			await Promise.all([rows, columns]).then(() => {
				this.entityRowReactions(dispatcher.entity.get()?.entity?.rows || [], currentEntity.entity.rows);
				const currentSection = store.sections.find(section => section.id === store.currentEntityId);
				if (currentSection) {
					dispatcher.filter.setPlaceholder();
					dispatcher.entity.setBasicEntity(currentEntity.entity);
					sessionStore.setEntities(store.entities);
					sessionStore.setFilter(currentSection.entityName, currentEntity.entity.filter || null);
					this.loadingState = LoadingState.Successful;
				}
				else {
					this.loadingState = LoadingState.Error;
					console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				}
			})
				.catch((error) => {
					this.loadingState = LoadingState.Error;
					//TODO переделать в будущем на генератор ошибок
					this.errorMessage = "Не удалось применить фильтр";
					console.error(error);

				});
		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			//TODO переделать в будущем на генератор ошибок
			this.errorMessage = "Не удалось применить фильтр";
			console.error(error);
		}
	};

	/**
	 * @description Метод для сохрапнения фильтра
	 * @param filter - фильтр для сохранения
	 */
	saveFilter = async (filter: SavedFilter) => {
		const currentEntity = dispatcher.entity.get();
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось сохранить фильтр";
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}

			const response = await savedFilter.filterCreate().post(filter.serialize());
			if (response && response.data.success) {
				filter.setValue(response.data.data, "id");
				filter.setValue(filter.filterInfo ? (filter.serialize()) : null, "oldFilter");

				if (filter.isFavorite) {
					dispatcher.filter.updateFavoriteFilters(filter);
				}
			}
			else {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось сохранить фильтр";
				console.error(response.data.error);
			}

		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			//TODO переделать в будущем на генератор ошибок
			this.errorMessage = "Не удалось сохранить фильтр";
			console.error(error);
		}
	};

	/**
	 * @description Метод для обновления фильтра
	 * @param filter - обновленный фильтр
	 */
	updateFilter = async (filter: SavedFilter, needWorkWithFavoriteFilters: boolean) => {
		const currentEntity = dispatcher.entity.get();
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось обновить фильтр";
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}

			const serializedFilter = filter.disposer ? filter.serialize() : filter;

			const response = await savedFilter.filterUpdate().post(serializedFilter);
			if (response && response.data.success) {
				filter.oldFilter = filter.disposer ? (filter.filterInfo ? filter.filterInfo.serialize() : filter.filterInfo) : null;
				if (selector.filter.getFilter()?.savedFilter?.id === filter.id) {

					dispatcher.filter.setSavedFilter(filter);
				}
				else if (filter.isFavorite) {
					dispatcher.filter.setFilterInFavoriteFilters(filter);
				}

				if (needWorkWithFavoriteFilters) {
					dispatcher.filter.updateFavoriteFilters(filter);
				}
				dispatcher.filter.setPlaceholder();
				sessionStore.setFilter(currentEntity.entityName, currentEntity.entity.filter || null);
			}
			else {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось обновить фильтр";
				console.error(response.data.error);
			}

		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			//TODO переделать в будущем на генератор ошибок
			this.errorMessage = "Не удалось обновить фильтр";
			console.error(error);
		}
	};

	/**
	 * @description Метод для удаления фильтра
	 * @param filterId - id фильтра для удаления
	 */
	deleteFilter = async (filterId: string) => {
		try {
			await savedFilter.filterDelete(filterId).delete().then(async (response) => {
				if (response && response.data.success) {
					dispatcher.filter.deleteFromFavoriteFilters(filterId);
					await this.getSavedFilterFolderTree();
					if (filterId === selector.filter.getFilter()?.savedFilter?.id) {
						dispatcher.filter.setSavedFilter(null);
						dispatcher.filter.setPlaceholder();
						await synchroiser.getEntityWithFilter();
					}
				}
			});
		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	}

	/**
	 * @description Метод для получения сохраненных фильтров и обновления списка избранных фильтров
	 * @param basicEntity - объект BasicEntity (для первой инициализации, когда вызывается getEntity())
	 * @param entityNameOfNewEntity - системное название раздела (для первой инициализации, когда вызывается getEntity())
	 * @returns массив сохраненных фильтров
	 */
	getFiltersList = async (basicEntity?: BasicEntity, entityNameOfNewEntity?: string): Promise<Item[]> => {
		const currentEntity = basicEntity || dispatcher.entity.get()?.entity;
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return [];
			}
			const entityName = entityNameOfNewEntity || dispatcher.entity.get()?.entityName;
			let resultItems: Item[] = [];
			await savedFilter.filtersList(authStore.userId!, entityName).get().then((response) => {
				if (response.data.success) {
					let items = (response.data.data as SavedFilter[]).map((item: any) => {
						return (
							{
								...item,
								createdOn: new Date(Date.parse(item.createdOn)),
								modifiedOn: new Date(Date.parse(item.modifiedOn))
							}
						);
					});
					items = sortArray(items, "modifiedOn", 1);
					resultItems = items.map((item: any) => { return { id: item.id, name: item.filterName }; });
					if (!currentEntity.filter) {
						dispatcher.filter.initFilter(currentEntity);
					}
					currentEntity.filter!.favoriteFilters = items.filter(item => item.isFavorite);
				}
			});
			return resultItems;
		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
			return [];
		}
	}

	/**
	 * @description Метод для получения и применения фильтра по его id
	 * @param filterId - id фильтра
	 */
	getFilter = async (filterId: string): Promise<void> => {
		const currentEntity = dispatcher.entity.get();
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}

			const response = await savedFilter.getFilter(filterId).get();
			if (response) {
				if (response.data.success) {
					dispatcher.filter.setSavedFilter(response.data.data);
					await this.getEntityWithFilter();
				}
				else {
					this.loadingState = LoadingState.Error;
					//TODO переделать в будущем на генератор ошибок
					this.errorMessage = "Не удалось применить фильтр";
				}
			}


		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	}

	/**
	 * @description Метод для сохранения статической группы
	 * @param group - статическая группа
	 */
	saveStaticGroup = async (group: StaticGroup): Promise<void> => {
		const currentEntity = dispatcher.entity.get();
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}

			const response = await staticGroup.staticGroupCreate().post(group);
			if (response && response.data.success) {
				group.id = response.data.data;
			}

		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	};

	/**
	 * @description Метод для обновления статической группы
	 * @param group - статическая группа
	 */
	updateStaticGroup = async (group: StaticGroup): Promise<void> => {
		const currentEntity = dispatcher.entity.get();
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}

			const response = await staticGroup.staticGroupUpdate().post(group);
			if (response && response.data.success) {
				if (selector.filter.getFilter()?.staticGroup?.id === group.id) {
					dispatcher.filter.setStaticGroup(group);
				}
				dispatcher.filter.setPlaceholder();
				sessionStore.setFilter(currentEntity.entityName, currentEntity.entity.filter || null);
			}

		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	};

	/**
	 * @description Метод для удаления статической группы
	 * @param staticGroupId - id статической группы
	 */
	deleteStaticGroup = async (staticGroupId: string): Promise<void> => {
		try {
			await staticGroup.staticGroupDelete(staticGroupId).delete().then(async (response) => {
				if (response && response.data.success) {
					await this.getStaticGroupFolderTree();
					if (staticGroupId === selector.filter.getFilter()?.staticGroup?.id) {
						dispatcher.filter.setStaticGroup(null);
						dispatcher.filter.setPlaceholder();
						await synchroiser.getEntityWithFilter();
					}
				}
			});
		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	}

	/**
	 * @description Метод для исключения записей статической группы
	 * @param data - данные, необходимые для исключения
	 */
	excludeRecordsFromStaticGroup = async (data: ExcludeRecordsFromStaticGroup): Promise<void> => {
		this.loadingState = LoadingState.Loading;
		try {
			await staticGroup.excludeRecords().post(data).then(async (response) => {
				if (response && response.data.success) {
					await this.getEntityWithFilter();
				}
				else {
					this.loadingState = LoadingState.Error;
					console.error(response?.data?.error);
				}
			});
		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	}

	/**
	 * @description Метод для получения дерева динамических фильтров
	 * @param basicEntity - объект BasicEntity (для первой инициализации, когда вызывается getEntity())
	 * @param entityNameOfNewEntity - системное название раздела (для первой инициализации, когда вызывается getEntity())
	 */
	getSavedFilterFolderTree = async (basicEntity?: BasicEntity, entityNameOfNewEntity?: string): Promise<void> => {
		const currentEntity = basicEntity || dispatcher.entity.get()?.entity;
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}
			const entityName = entityNameOfNewEntity || dispatcher.entity.get()!.entityName;
			await savedFilter.getFilterTree(authStore.userId!, entityName).get().then((response) => {
				if (response.data.success) {
					if (!currentEntity.filterTree) {
						if (basicEntity) {
							currentEntity.filterTree = selector.filter.getNewFilterTree(entityName);
						}
						else {
							dispatcher.filter.generateNewFilterTree();
						}
					}
					currentEntity.filterTree!.savedFilterTree = response.data.data;

					if (!basicEntity) {
						sessionStore.setFilter(dispatcher.entity.get()!.entityName, currentEntity.filter || null);
					}
				}



			});
		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	}

	/**
	 * @description Метод для получения дерева динамических фильтров
	 * @param basicEntity - объект BasicEntity (для первой инициализации, когда вызывается getEntity())
	 * @param entityNameOfNewEntity - системное название раздела (для первой инициализации, когда вызывается getEntity())
	 */
	getStaticGroupFolderTree = async (basicEntity?: BasicEntity, entityNameOfNewEntity?: string): Promise<void> => {
		const currentEntity = basicEntity || dispatcher.entity.get()?.entity;
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}
			const entityName = entityNameOfNewEntity || dispatcher.entity.get()!.entityName
			await staticGroup.getFilterTree(authStore.userId!, entityName).get().then((response) => {
				if (response.data.success) {
					if (!currentEntity.filterTree) {
						if (basicEntity) {
							currentEntity.filterTree = selector.filter.getNewFilterTree(entityName);
						}
						else {
							dispatcher.filter.generateNewFilterTree();
						}
					}
					currentEntity.filterTree!.staticGroupFolderTree = response.data.data;;
					if (!basicEntity) {
						sessionStore.setFilter(dispatcher.entity.get()!.entityName, currentEntity.filter || null);
					}
				}
			});
		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	}

	/**
	 * @description Метод для сохранения папки фильтра/статической группы
	 * @param savedFilterFolder - папка фильтра для сохранения
	 * @param staticGroupFolder - папка статической группы для сохранения
	 */
	saveFilterFolder = async (savedFilterFolder: SavedFilterFolder | null, staticGroupFolder: StaticGroupFolder | null): Promise<void> => {
		const currentEntity = dispatcher.entity.get()?.entity;
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}
			let response = null;
			if (savedFilterFolder) {
				response = await savedFilter.createFolder().post(savedFilterFolder);
				if (response && response.data.success) {
					savedFilterFolder.id = response.data.data;
				}
			}
			if (staticGroupFolder) {
				response = await staticGroup.createFolder().post(staticGroupFolder);
				if (response && response.data.success) {
					staticGroupFolder.id = response.data.data;
				}
			}
			if (!response || !response.data.success) {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось сохранить папку";
			}
		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			//TODO переделать в будущем на генератор ошибок
			this.errorMessage = "Не удалось сохранить папку";
			console.error(error);
		}
	}

	/**
	 * @description Метод для обновления папки фильтра/статической группы
	 * @param savedFilterFolder - папка фильтра для обновления
	 * @param staticGroupFolder - папка статической группы для обновления
	 */
	updateFilterFolder = async (savedFilterFolder: SavedFilterFolder | null, staticGroupFolder: StaticGroupFolder | null): Promise<void> => {
		const currentEntity = dispatcher.entity.get()?.entity;
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось обновить папку";
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}
			let response = null;
			if (savedFilterFolder) {
				response = await savedFilter.updateFolder().post(savedFilterFolder);
			}
			if (staticGroupFolder) {
				response = await staticGroup.updateFolder().post(staticGroupFolder);
			}
			if (!response || !response.data.success) {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось обновить папку";
			}
		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			//TODO переделать в будущем на генератор ошибок
			this.errorMessage = "Не удалось обновить папку";
			console.error(error);
		}
	}

	/**
	 * @description Метод для удаления папки фильтра/статической группы
	 * @param folderId - id папки для удаления
	 */
	deleteFolder = async (savedFilterFolder: SavedFilterFolder | null, staticGroupFolder: StaticGroupFolder | null) => {
		try {
			let response = null;
			if (savedFilterFolder) {
				response = await savedFilter.deleteFolder(savedFilterFolder.id!).delete();
			}
			if (staticGroupFolder) {
				response = await staticGroup.deleteFolder(staticGroupFolder.id!).delete();
			}
			if (response && response.data.success) {
				if (savedFilterFolder) {
					await this.getSavedFilterFolderTree();
				}
				if (staticGroupFolder) {
					await this.getStaticGroupFolderTree();
				}
			}
		}
		catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	}


	postSuccessfulAuthSync = async () => {
		if (store.user.id) {
			if (sessionStore.checkSections()) {
				store.sections = sessionStore.getSections();
			}
			if (sessionStore.checkEntities()) {
				dispatcher.entity.setAll(sessionStore.getEntities());
			}
			await this.getSectionsList();
		} else {
			console.error("ERROR FOR SYNC FIELD USER IS EMPTY");
		}
	};


	deleteComment = (commentId: string) => {
		dispatcher.comments.remove(commentId);
		api.http.httpApi.comment.commentById(commentId).delete();
	};

	addComments = (comment: any) => {
		const uuid = v4();
		let currentEntityName = dispatcher.entity.get()!.entityName;
		currentEntityName = currentEntityName.charAt(0).toUpperCase() + currentEntityName.slice(1, currentEntityName.length - 1);
		dispatcher.subscribers.add(uuid);
		dispatcher.reactions.set(uuid, SubscribeType.PENDING_SEND);

		const date = new Date().toISOString();

		dispatcher.comments.add({
			id: uuid,
			userId: store.user.id,
			entityId: store.currentRowId,
			entityName: dispatcher.entity.get?.name,
			text: comment,
			userName: store.user.userName,
			isOwner: true,
			createdOn: date,
			modifiedOn: date,
		});
		api.http.httpApi.comment.sendComments().post({
			text: comment,
			id: uuid,
			userId: store.user.id,
			entityId: store.currentRowId,
			entityName: currentEntityName,
		})?.then(() => {
			// @ts-ignore
			dispatcher.reactions.set(uuid, SubscribeType.NONE);
		});
		dispatcher.reactions.reset(uuid);
	};

	editComments = (id: string, comment: any) => {
		const date = new Date().toISOString();
		dispatcher.comments.edit(id, comment, date);
		api.http.httpApi.comment.sendComments(id).post({
			text: comment
		});
	};// TODO Доработать

	getCurrentTarget = (pathFragments: Array<string>) => {
		this.getSectionsList()?.then(() => {

			dispatcher.entity.switchSectionByName(pathFragments[1]);
			this.getEntity().then(() => {
				if (pathFragments[2]) {
					dispatcher.currentRow.switch(pathFragments[2]);
				}
			});
		});
	};

	/** 
	 * @description Проверяет системное название таблицы на дублирование в системе.
	 */
	async checkExistEntityName(tableName: string) {
		try {
			const response = await api.http.httpApi.entity.checkExistEntityName(tableName).get();
			let data = response.data;
			if (data.success) {
				return data.data;
			} else {
				console.error(data.message);
			}

		} catch (error: any) {
			console.error("An error occurred:", error);
		}
	}
	/** 
	 * @description Проверяет название таблицы на дублирование в системе.
	 */
	async checkExistEntityTitle(tableTitle: string, entityNameType: EntityNameType) {
		try {
			const response = await api.http.httpApi.entity.checkExistEntityTitle(tableTitle, entityNameType).get();
			let data = response.data;
			if (data.success) {
				return data.data;
			} else {
				console.error(data.message);
			}

		} catch (error: any) {
			console.error("An error occurred:", error);
		}
	}

	/** 
	 * @description Получает стадии и правила их перехода.
	 */
	async getSectionFromDesigner(entityName: string) {
		try {
			const response = await api.http.httpApi.stage.getSectionFromDesigner(entityName).get();
			let data = response.data;
			if (data.success) {
				return data.data;
			} else {
				console.error(data.message);
			}

		} catch (error: any) {
			console.error("An error occurred:", error);
		}
	}



}

export const synchroiser = new Synchroiser();
