import Api from "api/http/api";

class PageSettings extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/PageSettings";
	}

	getFullPageFields = (entityName?: string) => {
		if (entityName) {
			this.setPath(`${this.basicPath}/fullPageFields/${entityName}`);
			return this;
		}
	};
}

const pageSettings = new PageSettings();

export { pageSettings };