import { observer } from "mobx-react-lite";
import styles from "./Products.module.css";

import { InProgress } from 'assets/icons';
import NavigationTitle from "../NavigationTitle";


const ProductMainPanel = observer(function (props: { }) {
    return (
        <div className={styles.systemMainPanel}>
            <NavigationTitle />
            <div className={styles.inProgress}>
                <InProgress className={styles.inProgressSvg} />
            </div>


        </div>
    );
});
export default ProductMainPanel;