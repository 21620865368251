import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { UpFirst } from "entities/lowFirst";

import detailMasterState from "features/detail-master/detail-master-state";
import detailFieldConfigurationPopupState, { FieldStoreEnums } from "../detail-field-configuration-popup-state";

import {
    Field,
    Input,
    Text,
} from "components";

import styles from "../detail-field-configuration-popup.module.css";

export const BasicSettings = observer(() => {

    const handleTitleChange = useCallback((value: string) => {
        detailFieldConfigurationPopupState.setValue(FieldStoreEnums.title, value);
    }, []);

    const handleTitleFocusOut = useCallback(() => {
        detailFieldConfigurationPopupState.validateTitle(detailMasterState.gridItems);
    }, [detailMasterState.gridItems]);

    const handleSystemNameChange = useCallback((value: string) => {
        detailFieldConfigurationPopupState.setValue(FieldStoreEnums.systemName, value);
    }, []);

    const handleSystemNameFocusOut = useCallback(() => {
        detailFieldConfigurationPopupState.validateSystemName(detailMasterState.gridItems);
    }, [detailMasterState.gridItems]);

    return (
        <>
            <Text className={styles.sectionTitle}>ОСНОВНОЕ</Text>
            <Field
                name="title"
                caption="Заголовок"
                required
                promptText='Название, которое будет отображаться на странице записи'
            >
                <Input
                    value={detailFieldConfigurationPopupState.title}
                    placeholder=""
                    onChangeValue={handleTitleChange}
                    isNeedCorrect
                    isInvalid={detailFieldConfigurationPopupState.validation.title.isInvalid}
                    isNotUnique={detailFieldConfigurationPopupState.validation.title.isNotUnique}
                    invalidMessage={detailFieldConfigurationPopupState.validation.title.error}
                    onFocusOut={handleTitleFocusOut}
                />
            </Field>
            <Field
                name="systemName"
                caption="Системное название"
                required
                promptText='Название, которое будет отображаться в базе данных'
            >
                <Input
                    value={detailFieldConfigurationPopupState.systemName}
                    placeholder=""
                    onChangeValue={(value: string) => {
                        handleSystemNameChange(UpFirst(value));
                    }}
                    isNeedCorrect
                    isInvalid={detailFieldConfigurationPopupState.validation.systemName.isInvalid}
                    invalidMessage={detailFieldConfigurationPopupState.validation.systemName.error}
                    onFocusOut={handleSystemNameFocusOut}
                />
            </Field>
        </>
    );
});