import authStore from "AuthStore";
// import m from 'app/services/api';
import { SavingState } from "entities/Entity";
import { LoadingState } from "entities/ListStore";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import SavedFilter from "./SavedFilter";
import { api } from "api";

export interface ISavedFilterFolder {
    id?: string | null;
    parentFolderId: string | null;
    name: string | null;
    filters: SavedFilter[];
    childFolders: ISavedFilterFolder[];
    isRoot: boolean;
    entityName: string | null;
}

export class SavedFilterFolder implements ISavedFilterFolder {
	id: string | null;
	name: string | null;
	filters: SavedFilter[];
	childFolders: SavedFilterFolder[];
	parentFolderId: string | null;
	isRoot: boolean;
	entityName: string | null;
	oldValues: SavedFilterFolder | null;
	loadingState: LoadingState;
	savingState: SavingState;

	constructor(entityName: string) {
		makeObservable(this, {
			id: observable,
			parentFolderId: observable,
			name: observable,
			filters: observable,
			childFolders: observable,
			loadingState: observable,
			savingState: observable,
			isSaved: computed,
			setValue: action,
			deserialize: action,
			getFolderTree: action,
			saveFolder: action,

		});

		this.entityName = entityName;
		this.id = null;
		this.name = null;
		this.filters = [];
		this.childFolders = [];
		this.isRoot = false;
		this.oldValues = null;
		this.parentFolderId = null;
		this.loadingState = LoadingState.NotAsked;
		this.savingState = SavingState.NotAsked;
	}

	get isLoading(): boolean {
		return this.loadingState === LoadingState.Loading;
	}

	get isLoaded(): boolean {
		return this.loadingState === LoadingState.Loaded;
	}

	get isError(): boolean {
		return this.loadingState === LoadingState.Failed;
	}

	get isSaving(): boolean {
		return this.savingState === SavingState.Saving;
	}

	get isSaved(): boolean {
		return this.savingState === SavingState.Saved;
	}

	setValue(value: any, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	validate() {
		if (this.name === null || this.name.length === 0) return false;
		return true;
	}


	async getFolderTree() {
		this.loadingState = LoadingState.Loading;
		// let request = await m.get(`/api/SavedFilters/getfiltertree?userId=${authStore.userId}&entityName=${this.entityName}`);
		let request = await api.http.httpApi.savedFilter.getFilterTree(authStore.userId!,this.entityName!).get();
		runInAction(() => {
			if (request.data.id) {
				this.loadingState = LoadingState.Loaded;
				this.deserialize(request);
				this.oldValues = this;
			}
			else {
				this.loadingState = LoadingState.Failed;
			}
		});
	}

	async saveFolder(folder: SavedFilterFolder): Promise<boolean> {
		if (!this.validate()) {
			return false;
		}
		this.savingState = SavingState.Saving;
		const data = this.serializeFolder(folder);
		let response;
		if (data.id === null || data.id === undefined) {
			// response = await m.post("/api/SavedFilters/createfolder", data);
			response = await api.http.httpApi.savedFilter.createFolder().post(data);
		}
		else {

			// response = await m.post("/api/SavedFilters/updatefolder", data);
			response = await api.http.httpApi.savedFilter.updateFolder().post(data);
		}

		if (response && response.data.success === true) {
			this.savingState = SavingState.Saved;
			await this.getFolderTree();
			return true;
		}
		else {
			this.savingState = SavingState.Failed;
		}
		return false;
	}

	async deleteFolder(folderId: string): Promise<boolean> {
		this.savingState = SavingState.Saving;
		// let response = await m.delete(`/api/SavedFilters/deletefolder?id=${folderId}`);
		let response = await api.http.httpApi.savedFilter.deleteFolder(folderId).delete();
		if (response && response.data.success === true) {
			this.savingState = SavingState.Saved;
			await this.getFolderTree();
			return true;
		}
		else {
			this.savingState = SavingState.Failed;
		}
		return false;
	}


	deserializeChildFilterFolders(childFilterFolders: any): SavedFilterFolder[] {
		let folders: SavedFilterFolder[] = [];
		childFilterFolders.forEach((item: any) => {
			let folder = new SavedFilterFolder(this.entityName!);
			folder.deserialize(item);
			folders.push(folder);
		});
		return folders;
	}

	deserialize(p: any) {
		this.id = p.id;
		this.name = p.name;
		this.filters = p.filters.map((filter: any) => {
			let newFilter = new SavedFilter(filter.entityName);
			newFilter.deserialize(filter);
			return newFilter;
		});
		this.childFolders = this.deserializeChildFilterFolders(p.childFilterFolders);
		this.isRoot = p.isRoot;
		this.parentFolderId = p.parentFolderId;
	}
    
	serializeFolder(folder: ISavedFilterFolder) {
		return folder.id ?
			{
				id: folder.id,
				name: folder.name,
				isRoot: folder.isRoot,
				parentFolderId: folder.parentFolderId,
				entityName: folder.entityName,
				userId: authStore.userId,
			}
			:
			{
				name: folder.name,
				isRoot: folder.isRoot,
				parentFolderId: folder.parentFolderId,
				entityName: folder.entityName,
				userId: authStore.userId,
			};
	}
}

export default SavedFilterFolder;