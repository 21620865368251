import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { dispatcher } from "store";
import { Position, modalController } from "features/modals";

import TypeIcon from "features/type-icon/type-icon";

import ConstructorTitle from "../constructor-title/constructor-title";
import { ConstructorRightPanel } from "../constructor-right-panel";
import { TabsPanel } from "pages/section-wizzard/components";

import { DisplayedPanel, TabId, TabState } from "types/entity";
import { ColumnType } from "entities/ColumnType";
import { IGridItem } from "components/select/types";

import { SectionWizzardEmptyInfo } from "assets/icons";

import styles from "./constructor-main-panel.module.css";

function EmptyInfo() {
	return (
		<div className={styles.emptyInfoWrapper}>
			<SectionWizzardEmptyInfo />
			<div className={styles.textBlockInEmptyInfo}>
				<span className={styles.firstTextInEmptyInfo}>Пока здесь пусто</span>
				<span className={styles.secondTextInEmptyInfo}>Добавьте дополнительные вкладки</span>
			</div>
		</div>
	);
}

const ConstructorMainPanel = observer((props: { checkWarningForSetting: () => void }) => {
	const [idModal] = useState<string>(v4());

	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]
	);

	const displayedPanel = useMemo(() => {
		return sectionWizzard?.displayedPanel;
	}, [sectionWizzard?.displayedPanel]
	);

	const currentTabIndex = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
	}, [sectionWizzard?.reactorConfig.tabs.currentTab]);


	const sourcePanelElements = useMemo(() => {
		const rightColumns: Array<IGridItem> = [];
		dispatcher.sectionWizzard.getAllGridItems().forEach(item => {
			let find = rightColumns.find(col => col.columnId === item.fieldConfig?.columnId);
			if (find) {
				let findIndex = rightColumns.findIndex(col => col.columnId === item.fieldConfig?.columnId);
				if (findIndex !== -1) {
					find = {
						...find,
						isDisabled: false,
						isLocked: false
					};
					rightColumns[findIndex] = find;
				}
			}
			else {
				let icon = <></>;
				if (item) {
					if (item.fieldConfig) {
						icon = <TypeIcon type={item.fieldConfig?.columnType} />;
						if (item.x > 0 && item.y > 0) {
							rightColumns.push({
								gridItemId: item.gridItemId,
								columnId: item.fieldConfig.columnId,
								name: item.fieldConfig.columnName,
								displayValue: item.fieldConfig.columnTitle,
								columnType: item.fieldConfig.columnType,
								icon: icon,
								isDisabled: false,
								isLocked: false
							});
						} else {
							rightColumns.push({
								gridItemId: item.gridItemId,
								columnId: item.fieldConfig.columnId,
								name: item.fieldConfig.columnName,
								displayValue: item.fieldConfig.columnTitle,
								columnType: item.fieldConfig.columnType,
								icon: icon,
								isDisabled: true,
								isLocked: true
							});
						}
					} else if (item.detailConfig) {
						icon = <TypeIcon type={ColumnType.Detail} />;
						if (item.x > 0 && item.y > 0) {
							rightColumns.push({
								gridItemId: item.gridItemId,
								name: item.detailConfig.detailName,
								displayValue: item.detailConfig.detailTitle,
								columnType: ColumnType.Detail,
								icon: icon,
								isDisabled: false,
								isLocked: false
							});
						} else {
							rightColumns.push({
								gridItemId: item.gridItemId,
								name: item.detailConfig.detailName,
								displayValue: item.detailConfig.detailTitle,
								columnType: ColumnType.Detail,
								icon: icon,
								isDisabled: true,
								isLocked: true
							});
						}
					}
				}
			}

			if (item.groupFieldsConfig?.inner?.items) {
				item.groupFieldsConfig.inner.items.forEach(innerItem => {
					if (!innerItem.fieldConfig) {
						return;
					}
					let find = rightColumns.find(col => col.id === innerItem.fieldConfig?.columnId);
					if (find) {
						let findIndex = rightColumns.findIndex(col => col.id === innerItem.gridItemId);
						find = {
							...find,
							isDisabled: false,
							isLocked: false
						};
						rightColumns[findIndex] = find;
					}
					else {
						let icon = <></>;
						if (innerItem) {
							if (innerItem.fieldConfig) {
								icon = <TypeIcon type={innerItem.fieldConfig?.columnType} />;
								if (innerItem.x > 0 && item.y > 0) {
									rightColumns.push({
										gridItemId: innerItem.fieldConfig.columnId,
										columnId: item.fieldConfig?.columnId,
										name: innerItem.fieldConfig.columnName,
										displayValue: innerItem.fieldConfig.columnTitle,
										columnType: innerItem.fieldConfig.columnType,
										icon: icon,
										isDisabled: false,
										isLocked: false
									});
								} else {
									rightColumns.push({
										gridItemId: innerItem.gridItemId,
										columnId: item.fieldConfig?.columnId,
										name: innerItem.fieldConfig.columnName,
										displayValue: innerItem.fieldConfig.columnTitle,
										columnType: innerItem.fieldConfig.columnType,
										icon: icon,
										isDisabled: true,
										isLocked: true
									});
								}
							}
							else if (innerItem.groupFieldsConfig) {
								icon = <TypeIcon type={ColumnType.FieldGroup} />;
								if (innerItem.x > 0 && innerItem.y > 0) {
									rightColumns.push({
										gridItemId: innerItem.gridItemId,
										name: innerItem.groupFieldsConfig.name,
										displayValue: innerItem.groupFieldsConfig.title,
										columnType: ColumnType.FieldGroup,
										icon: icon,
										isDisabled: false,
										isLocked: false
									});
								} else {
									rightColumns.push({
										gridItemId: innerItem.gridItemId,
										name: innerItem.groupFieldsConfig.name,
										displayValue: innerItem.groupFieldsConfig.title,
										columnType: ColumnType.FieldGroup,
										icon: icon,
										isDisabled: true,
										isLocked: true
									});
								}
							}
							else if (innerItem.detailConfig) {
								icon = <TypeIcon type={ColumnType.Detail} />;
								if (innerItem.x > 0 && innerItem.y > 0) {
									rightColumns.push({
										gridItemId: innerItem.gridItemId,
										name: innerItem.detailConfig.detailName,
										displayValue: innerItem.detailConfig.detailTitle,
										columnType: ColumnType.Detail,
										icon: icon,
										isDisabled: false,
										isLocked: false
									});
								} else {
									rightColumns.push({
										gridItemId: innerItem.gridItemId,
										name: innerItem.detailConfig.detailName,
										displayValue: innerItem.detailConfig.detailTitle,
										columnType: ColumnType.Detail,
										icon: icon,
										isDisabled: true,
										isLocked: true
									});
								}
							}
						}
					}
				});
			}
		});

		const search = sectionWizzard?.searchValue?.toLowerCase();
		if (search) {
			return rightColumns.filter(column =>
				(column.displayValue?.toLowerCase().includes(search) || column.name.toLowerCase().includes(search)));
		}

		return rightColumns;

	}, [sectionWizzard?.searchValue, dispatcher.sectionWizzard.getAllGridItems().map(tab => tab), currentTabIndex]);

	const findPanelTitle = useCallback((tabId: string) => {
		if (sectionWizzard?.reactorConfig.tabs.additionalPanel!) {
			const tab = sectionWizzard?.reactorConfig.tabs.additionalPanel![tabId as TabId];
			if (tab) return tab.title
		}
		else return ''
	}, [sectionWizzard?.reactorConfig.tabs.additionalPanel]);

	const openConfirm = useCallback((settingId: string, tabState: number) => {
		let panel = "";
		if (tabState === TabState.Disabled) {
			panel = displayedPanel === DisplayedPanel.Main ? "основной" : "вспомогательной";
		}
		else {
			panel = tabState === TabState.EnabledOnAuxiliary ? "основной" : "вспомогательной";
		}
		const layout = <span className={styles.notificationTabs}>Вкладка «{findPanelTitle(settingId)}» выключена с {panel} панели</span>;
		modalController.notificationAdd({ id: idModal, position: Position.CENTER, layout: layout, allowTimer: true, allowDefaultClick: true });
	}, [displayedPanel]);

	const onMoveBoxTab = useCallback((id: string, value: boolean, passedDisplayedPanel?: DisplayedPanel) => {
		let usageDisplayedPanel: DisplayedPanel = DisplayedPanel.Main;
		if (passedDisplayedPanel !== undefined) {
			usageDisplayedPanel = passedDisplayedPanel
		}
		else {
			usageDisplayedPanel = displayedPanel!
		}
		if (value) {
			if (usageDisplayedPanel === DisplayedPanel.Main) {
				if (sectionWizzard?.reactorConfig.tabs.additionalPanel![id as TabId].state === TabState.EnabledOnAuxiliary) {
					openConfirm(id, TabState.EnabledOnPrimary);
				}
				dispatcher.sectionWizzard.setStateInAdditionalPanel(id as TabId, TabState.EnabledOnPrimary);
				dispatcher.sectionWizzard.setAdditionalTabsOrder(id as TabId, dispatcher.sectionWizzard.getLastIndexForAddTab() + 1);
			}
			else {
				if (sectionWizzard?.reactorConfig.tabs.additionalPanel![id as TabId].state === TabState.EnabledOnPrimary) {
					openConfirm(id, TabState.EnabledOnAuxiliary);
				}
				dispatcher.sectionWizzard.setStateInAdditionalPanel(id as TabId, TabState.EnabledOnAuxiliary);
				dispatcher.sectionWizzard.setAdditionalTabsOrder(id as TabId, dispatcher.sectionWizzard.getLastIndexForAddTab() + 1);
			}
		}
		else {
			dispatcher.sectionWizzard.setStateInAdditionalPanel(id as TabId, TabState.Disabled);
			dispatcher.sectionWizzard.setAdditionalTabsOrder(id as TabId, -1);
		}

	}, [displayedPanel, sectionWizzard?.reactorConfig.tabs.additionalPanel]);

	const additionalTabsOrInfo = useMemo(() => {
		if (dispatcher.sectionWizzard.getViewAdditional())
			return <TabsPanel isAdded={false} onMoveBoxTab={onMoveBoxTab} />
		else return <EmptyInfo />
	}, [dispatcher.sectionWizzard.getViewAdditional(), onMoveBoxTab]);

	const centralPanel = useMemo(() => {
		const mainPanelClasses = classNames(styles.mainPanelDefault, {
			[`${styles.mainPanelShow}`]: displayedPanel === DisplayedPanel.Main,
			[`${styles.mainPanelHide}`]: displayedPanel === DisplayedPanel.Additional,
		});
		const additionalClasses = classNames(styles.additionalPanelDefault, {
			[`${styles.additionalPanelShow}`]: displayedPanel === DisplayedPanel.Additional,
			[`${styles.additionalPanelHide}`]: displayedPanel === DisplayedPanel.Main,
		});

		return (
			<div className={styles.wrapCenterPanel}>
				<div className={additionalClasses}>
					<div className={styles.panel}>
						{additionalTabsOrInfo}
					</div>
				</div>
				<div className={mainPanelClasses}>
					<div className={styles.panel}>
						<ConstructorTitle columns={sourcePanelElements} checkWarningForSetting={props.checkWarningForSetting}/>
						<TabsPanel isAdded={true} onMoveBoxTab={onMoveBoxTab} />
					</div>
				</div>
			</div>
		);
	}, [additionalTabsOrInfo, sourcePanelElements, displayedPanel]);

	return (
		<div className={styles.mainWizardPanel}>
			{centralPanel}
			<ConstructorRightPanel sourcePanelElements={sourcePanelElements} onMoveBoxTab={onMoveBoxTab} />
		</div>
	);
});

export default ConstructorMainPanel;
