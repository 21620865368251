import { makeAutoObservable } from "mobx";
// import api from 'app/services/api';

import UserRole from "entities/userRole/UserRole";
import IFilter from "entities/filter/IFilter";
import { api } from "api";

class OrgStructureMainPanelStore {
    filteredUserStore: any[];
    funcRolesStore: any[];
    funcLeaderRolesStore: any[];
    leaderStore: any[];
    dialogData: any[];

    userRole: UserRole | null = null;
    userFilter: IFilter | null = null;
    LDAPsynchronize: boolean = false;
    LDAPelem: any;

    constructor() {
        makeAutoObservable(this);
        this.filteredUserStore = [];
        this.funcRolesStore = [];
        this.funcLeaderRolesStore = [];
        this.leaderStore = [];
        this.dialogData = [];
    }

    async setValue(value: any, fieldName: string) {
        Reflect.set(this, fieldName, value);
    }


    async loadFuncRoles(id: string) {
        // let request = await api.get(`/api/Administration/getAllFuncRoleByOrgRole?roleId=${id}`);
        let request = await api.http.httpApi.administration.getAllFuncRoleByOrgRole(id).get();
        if (request) {
            this.funcRolesStore = request.data;
        }

    }
    async loadFuncLeaderRoles(id: string) {
        // let request = await api.get(`/api/Administration/getAllFuncRoleByOrgRole?roleId=${id}`);
        let request = await api.http.httpApi.administration.getAllFuncRoleByOrgRole(id).get();
        if (request) {
            this.funcLeaderRolesStore = request.data;
        }

    }
    async setUsersById(id: string) {
        this.filteredUserStore = await this.getUsersByRoleId(id);
    }
    async setLeadersById(id: string) {
        this.leaderStore = await this.getUsersByRoleId(id);
    }

    async getUsersByRoleId(roleId: string) {
        // let request = await api.get(`/api/Administration/users/${id}`);
        let request = await api.http.httpApi.administration.usersByRole(roleId).get();
        if (request) {
            return request.data;
        }

    }

    async addUserInRole(id: string, roles: string[]) {
        let data = {
            userId: id,
            roleIds: roles
        };
        // let request = await api.post("/api/Administration/roles/include/user", data);
        let request = await api.http.httpApi.administration.includeUserInPole().post(data);
        if (request) {
            this.setUsersById(this.userRole?.data?.id!);
            if (this.userRole?.data?.hasLeadRole) this.setLeadersById(this.userRole?.data?.leadRole.id!);
            return true;
        }
        else return false;
    }

    async linkOrgAndFuncRole(orgRoleId: string, funcRoleId: string) {
        // let url = "/api/Administration/linkOrgAndFuncRole?orgRoleId=" + orgRoleId + "&funcRoleId=" + funcRoleId;
        // let request = await api.post(url, {});
        let request = await api.http.httpApi.administration.linkOrgAndFuncRole(orgRoleId, funcRoleId).post(null);
        if (request) {
            this.loadFuncRoles(this.userRole?.data?.id!);
            if (this.userRole?.data?.hasLeadRole) this.loadFuncLeaderRoles(this.userRole?.data?.leadRole.id!);
            return true;
        }
        else return false;
    }

    async linkOrgAndFuncRoles(orgRoleId: string, funcRoleIds: string[]) {
        if (funcRoleIds.length > 0) {
            let data = {
                orgRoleId: orgRoleId,
                funcRoleIds: funcRoleIds
            };
            // let request = await api.post("/api/Administration/linkOrgAndFuncRoles", data);
            let request = await api.http.httpApi.administration.linkOrgAndFuncRoles().post(data);
            if (request) {
                this.loadFuncRoles(this.userRole?.data?.id!);
                if (this.userRole?.data?.hasLeadRole) this.loadFuncLeaderRoles(this.userRole?.data?.leadRole.id!);
                return true;
            }
            else return false;
        }
        else this.linkOrgAndFuncRole(orgRoleId, funcRoleIds[0]);
    }

}

const orgStructureMainPanelStore = new OrgStructureMainPanelStore();

export default orgStructureMainPanelStore;