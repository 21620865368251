import { observer } from "mobx-react-lite";

import importStore from "pages/import/core/import-store";
import { ResultStates } from "pages/import/data/import-constants";
import ProgressBar from "components/progress-bar/progress-bar";

import { Success } from "assets/icons";
import { Error } from "assets/icons";

import styles from "./import-progress.module.css";

function ImportProgress(props: { step: number, stepName: string, importResult: any | null }) {

    function getStageName() {
        if (props.importResult)
            switch (props.importResult.state) {
                case ResultStates.Progress:
                    if (importStore.entity?.displayValue) return <span className={styles.title}>{"Импорт данных в раздел [" + importStore.entity?.displayValue + "]"}</span>;
                    else return <span className={styles.title}>{"Импорт данных в раздел"}</span>;
                case ResultStates.Success:
                    return <div className={styles.titleContainer}><Success /><span className={styles.title + " " + styles.success}>Данные импортированы</span></div>;
                case ResultStates.Failure:
                    return <div className={styles.titleContainer}><Error /><span className={styles.title + " " + styles.error}>Данные не импортированы</span></div>;
                default:
                    if (importStore.entity?.displayValue) return <span className={styles.title}>{"Импорт данных в раздел [" + importStore.entity?.displayValue + "]"}</span>;
                    else return <span className={styles.title}>{"Импорт данных в раздел"}</span>;
            }
        else if (importStore.entity?.displayValue) return <span className={styles.title}>{"Импорт данных в раздел [" + importStore.entity?.displayValue + "]"}</span>;
        else return <span className={styles.title}>{"Импорт данных в раздел"}</span>;
    }

    function getSteps() {
        if (props.importResult)
            switch (props.importResult.state) {
                case ResultStates.Progress:
                    return <span style={{ fontFamily: "inherit" }}>Шаг {props.step} из 4</span>;
                case ResultStates.Success:
                    return <span style={{ fontFamily: "inherit" }} className={styles.success}>Успех</span>;
                case ResultStates.Failure:
                    return <span style={{ fontFamily: "inherit" }} className={styles.error}>Ошибка</span>;
            }
        else return <span style={{ fontFamily: "inherit" }}>Шаг {props.step} из 4</span>;
    }

    let color = `var(--color-indigo-500)`;
    if (props.importResult) {
        if (props.importResult.state === ResultStates.Success) {
            color = `var(--color-success-500)`;
        }
        else if (props.importResult.state === ResultStates.Failure) {
            color = `var(--color-error-500)`;
        }
    }


    return (
        <div className={styles.body}>
            <span className={styles.title}>{getStageName()}</span>
            <div className={styles.progress}>
                <div className={styles.progressLabels}>
                    {props.importResult ? 
                    <span style={{ fontFamily: "inherit" }}>{props.importResult.state === ResultStates.Progress ? props.stepName : "Готово"}</span>:
                    <span style={{ fontFamily: "inherit" }}>{props.stepName}</span>
                    }
                    <span style={{ fontFamily: "inherit" }}>{getSteps()}</span>
                </div>
                <ProgressBar steps={4} currentStep={props.step} color={color} />
            </div>
        </div>
    )
}

export default observer(ImportProgress);