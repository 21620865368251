import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";

import { Modal, Button, ButtonStyle, Tabs, WarningDialog, SearchSelect} from "components";
import { LeftContent, RightContent } from "./quick-view-content/quick-view-content";
import SkeletonQuickView from "features/quick-view-block/skeleton/skeleton-quick-view";
import Comments from "features/comments/comments-block";

import CommentEntity from "entities/CommentEntity";
import Entity, { IEntityStore } from "entities/Entity";
import { LoadingState } from "entities/ListStore";
import { IQuickViewBlock, IQuickViewFilld, getModifiedOn } from "./constants/constants";
import CopyHref from "entities/CopyHref";
import { validateRequired } from "entities/Validation";

import { AddFlag, ArrowToDownWhite, CheckboxDone, Close, CopyLink, OpenInNewWindow } from "assets/icons";

import styles from "./quick-view-block.module.css";
import { Item } from "types";




const QuickViewBlock = observer(function (props: IQuickViewBlock) {
	const [openedWarningDialog, setOpenedWarningDialog] = useState(false);
	const [copied, setCopied] = useState(false);
	const [tab, setTab] = useState<JSX.Element>(<></>);
	const [comment, setComment] = useState<CommentEntity | null>(null);
	const tabs = [
		{
			caption: "Основная информация",
			action: () => {
				setTab(
					<LeftContent fields={props.fields} entityStore={props.entity} />
				);
			}

		},
	];

	useEffect(() => {
		props.entity.setValue(LoadingState.NotAsked, "loadingState");
		load();
		function keyup(event: any) {
			if (event.key === "Escape") {
				close();
			}
		}
		document.addEventListener("keyup", keyup);
		return () => document.removeEventListener("keyup", keyup);
	}, []);

	const load = async () => {
		const quickComment = new CommentEntity(props.schema, props.id);
		setComment(quickComment);
		await props.entity.load(props.id);
		await quickComment.loadComments(true);
	};

	function close() {
		if (props.entity.hasChanges)
			setOpenedWarningDialog(true);
		else {
			props.closeQuick();
			props.entity.cancelChanges();
		}

	}

	const copyLink = useCallback(
		() => { setCopied(true); CopyHref(props.id); setTimeout(() => { setCopied(false); }, 4000); }, [CopyHref]
	);

	if (!props.entity.isLoaded || !comment?.isLoaded) {
		return <SkeletonQuickView />;
	}
	return (
		<Modal>
			<div className={styles.backdrop} onClick={(e) => { e.preventDefault(); e.stopPropagation(); close(); }} />
			<div className={styles.quickView}>
				<div className={styles.header}>
					<div className={styles.leftHead}>
						<span className={styles.blueText}>{props.pageTitle}</span>
						{copied ?
							<div className={styles.actionsTool} >
								<span className={styles.tooltip}>
									<span>Ссылка скопирована!</span>
								</span>
								<CheckboxDone className={styles.green} />
							</div>
							:
							<div className={styles.actionsTool} >
								<span className={styles.tooltip}>
									<span>Копировать ссылку</span>
								</span>
								<CopyLink
									className={styles.copyIcon}
									onClick={copyLink}
								/>
							</div>

						}
					</div>
					<ButtonsHead close={close} id={props.entity.entity.id} />
				</div>
				<div className={styles.quickViewHeader}>
					<div className={styles.quickViewLeftHeader}>
						{!props.entity.hasChanges &&
                            <span className={styles.changeTitle}>посл. изменение: {getModifiedOn(props.entity.entity.modifiedOn!).toLocaleString()}</span>
                        }
                        <Button
                            caption={'Сохранить изменения'}
                            style={ButtonStyle.Success}
                            isVisible={props.entity.hasChanges}
                            className={styles.button}
                            onClick={async () => {await props.entity.save();}}
                        />
                        <Button
                            caption={'Отменить'}
                            style={ButtonStyle.Subtle}
                            isVisible={props.entity.hasChanges}
                            className={styles.button}
                            onClick={props.entity.cancelChanges}
                        />
                    </div>
                    <div className={styles.quickViewRightHeader}>
                        <Button
                            caption={'Уведомлять об изменениях'}
                            style={ButtonStyle.Subtle}
                            className={styles.grayButton}
                        />
                        {props.stageField &&
                            <StageField
                            	field={props.stageField}
                            	entityStore={props.entity}
                            />}
					</div>

				</div>
				<div className={styles.quickViewContentwrapper}>
					<div className={styles.quickViewLeftContent}>
						<div className={styles.quickViewTitle}>
							{props.quickViewTitle}
						</div>
						<Tabs items={tabs} className={styles.tab} />
						<div className={styles.quickLeftScroll}>
							<div className={styles.quickViewContent}>
								<div className={styles.quickBlock}>{tab}</div>
							</div>
							<div className={styles.commentsBlockTitle}>
                                Комментарии
							</div>
							{comment &&
                                <Comments
                                	className={styles.commentsWrapper}
                                	classNameTabContent={styles.tabContent}
                                	classNameCommentItem={styles.commentItemWrapper}
                                	classNameSort={styles.sortWrapper}
                                	commentsAndInputClassName={styles.commentsAndInputBlock}
                                />
							}
						</div>


					</div>
					<div className={styles.quickViewRightContent}>
						<RightContent fields={props.infoFields} entity={props.entity} />
					</div>
				</div>

				<WarningDialog value={"Вы действительно хотите закрыть быстрый просмотр?\nТекущие изменения будут утеряны."} valueReturn={"Вернуться к быстрому просмотру"} valueDelete={"Отменить"} isOpen={openedWarningDialog} onBackClick={() => setOpenedWarningDialog(false)}
					onCancelClick={() => { setOpenedWarningDialog(false); props.closeQuick(); props.entity.cancelChanges(); }} />

			</div>

		</Modal >
	);

});
const StageField = observer((props: { field: IQuickViewFilld, entityStore: Entity<IEntityStore> }) => {
	const { field, entityStore } = props;
	const entity = entityStore.entity;
	let [val, setVal] = useState<Item>(Reflect.get(entity, field.name));
	const isInvalid = props.field.isNotValidation ? false : entityStore.entity.validation[field.name].isInvalid;

	const onChangeValue = useCallback(
		(value: Item | null) => {
			if (value) setVal(value);
			Reflect.set(entity, field.name, value!);
			if (!field.isNotValidation) {
				validateRequired(value, entityStore.entity.validation[field.name]);
			}
		},
		[validateRequired]
	);

	return (
		<SearchSelect
			items={entityStore.lookups[field.lookupName!]}
			onChangeValue={onChangeValue}
			className={styles.wrapper}
			classNameInput={styles.input}
			onItemsLoad={async () => {
				return await entityStore.loadLookups(field.lookupName!, undefined, null, field.isSorted);
			}}
			secondIcon={<ArrowToDownWhite className={styles.selectDropdown} />}
			isRotateSecondIcon={true}
			styles={ButtonStyle.Primary}
			value={val}
			isInvalid={isInvalid}
			onFocusOut={() => {
				if (!field.isNotValidation) {
					validateRequired(val, entityStore.entity.validation[field.name]);
				}
			}}

		/>
	);
});

const ButtonsHead = observer(function (props: { close: () => void, id: string }) {
	return (
		<div className={styles.buttonsHead}>
			<div className={styles.actionsTool} >
				<span className={styles.tooltip}>
					<span>Открыть в новой вкладке</span>
				</span>
				<OpenInNewWindow className={styles.iconHead} onClick={() => {
					window.open(window.location.href + "/" + props.id);
				}} />
			</div>

			<div className={styles.actionsTool} >
				<span className={styles.tooltip}>
					<span>Добавить флажок</span>
				</span>
				<AddFlag className={styles.iconHead} />
			</div>
			<div className={styles.actionsTool} >
				<span className={styles.tooltip}>
					<span>Закрыть</span>
				</span>
				<Close className={styles.iconHead} onClick={props.close} />
			</div>

		</div>
	);

});


export default QuickViewBlock;





