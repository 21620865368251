import { useCallback, useEffect, useRef, } from 'react';
import classNames from 'classnames';

import styles from './input-search.module.css';

interface IInputProps extends React.HTMLAttributes<HTMLElement> {
    name?: string;
    value: string | null;
    placeholder: string;
    isPassword?: boolean;
    isInvalid?: boolean;
    isDisabled?: boolean;
    autoComplete?: boolean;
    invalidMessage?: string;
    classNameInput?: string;
    invalidMessageSmall?: boolean;
    size?: number | null;
    onChangeValue: (value: string) => void;
    onFocusOut?: () => void;
    onInput?: () => void;
    maxLength?: number;
    focus?: boolean;
}

function InputSearch(props: IInputProps) {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (props.focus) {
            ref.current!.focus();
        }
    }, [props.focus]);

    const handleClick = useCallback((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        ref.current!.focus();
        if (props.onClick !== undefined)
            props.onClick(event);
    }, [ref.current, props.onClick]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChangeValue(event.target.value);
    }, [props.onChangeValue]);

    const handleOnBlur = useCallback(() => {
        if (props.onFocusOut !== undefined)
            props.onFocusOut();
    }, [props.onFocusOut]);

    const wrapClassNames = classNames({
        [`${styles.invalid} `]: props.isInvalid,
        [`${props.className} `]: props.className,
    });

    const inputClassNames = classNames(`${styles.inputText} `, {
        [`${styles.invalid} `]: props.isInvalid && props.invalidMessage && props.invalidMessage.length > 0,
        [`${props.classNameInput} `]: props.classNameInput,
    });

    let error = null;
    if (props.isInvalid === true && props.invalidMessage !== undefined && props.invalidMessage.length > 1) {
        const invalidMessageClasses = classNames(`${styles.errorMessage} `, {
            [`${styles.small} `]: props.invalidMessageSmall,
        });
        error = <div className={invalidMessageClasses}>{props.invalidMessage}</div>
    }

    return (
        <div className={wrapClassNames}>
            <input
                tabIndex={0}
                ref={ref}
                id={props.id}
                name={props.name}
                type={"text"}
                className={inputClassNames}
                value={props.value ?? ""}
                onChange={handleChange}
                placeholder={props.placeholder}
                onBlur={handleOnBlur}
                autoComplete={props.autoComplete === true ? "on" : "off"}
                disabled={props.isDisabled}
                maxLength={props.maxLength}
                size={props.size!}
                style={props.style}
                onMouseOver={props.onMouseOver}
                onInput={props.onInput}
                onClick={handleClick}
            />
            {error}
        </div>
    );
}

export default InputSearch;