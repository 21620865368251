import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { isNull } from "lodash";

import { dispatcher } from "store";

import { Dropdown, Field, SelectStyleName } from "components";

import { Item } from "types";
import { StageFullInfo } from "types/entity";

import styles from "../settings-panel.module.css";

export const MovingRules = observer((props: { allStages: StageFullInfo[], idStage: string }) => {
    const ALL_STAGES = 'allStages';
    const [allStages, setAllStages] = useState<StageFullInfo[]>(props.allStages);
    const [stagesToValue, setStagesToValue] = useState<Item[]>();
    const [stagesFromValue, setStagesFromValue] = useState<Item[]>();

    const [stagesToItems, setStagesToItems] = useState<Item[]>([]);
    const [stagesFromItems, setStagesFromItems] = useState<Item[]>([]);

    useEffect(() => {
        setAllStages(props.allStages)
    }, [props.allStages]);

    const currentStage = useMemo(() => {
        return allStages.find((stage: StageFullInfo) => stage.id === props.idStage)
    }, [toJS(allStages), props.idStage]);

    const checkAllCheckbox = useCallback((list: Item[]) => {
        let checkedAll = true;
        list.forEach(item => {
            if (item.id !== ALL_STAGES) {
                if (!item.isChecked) {
                    checkedAll = false;
                }
            }
        });
        return checkedAll
    }, []);

    const generateCheckedStages = useCallback((stageType: 'to' | 'from') => {
        const listItems: Item[] = [];
        const stagesWithoutCurrent = allStages.filter((stage) => stage.id !== currentStage?.id);
        const allStagesItem = {
            id: ALL_STAGES,
            name: 'Все',
            displayValue: 'Все',
            isChecked: false
        }
        listItems.push(allStagesItem);
        stagesWithoutCurrent.forEach(stage => {
            let isChecked = false;
            if (stageType === 'to') {
                if (currentStage?.stagesTo?.includes(stage.id)) {
                    isChecked = true;
                }
            }
            else if (stageType === 'from') {
                if (currentStage?.stagesFrom?.includes(stage.id)) {
                    isChecked = true;
                }
            }
            listItems.push({
                id: stage.id,
                name: stage.name,
                displayValue: stage.name,
                isChecked: isChecked
            })
        });
        const checkAll = checkAllCheckbox(listItems);
        if (checkAll) {
            setStagesToValue([allStagesItem]);
            listItems[0].isChecked = true;
        }
        return listItems;
    }, [checkAllCheckbox, toJS(allStages), currentStage]);

    const handleStagesToLoad = useCallback(async (value: string | null, limit?: number | undefined) => {
        if (currentStage) {
            const stages = generateCheckedStages('to');
            if (isNull(value)) {
                setStagesToItems(stages);
                return stages.length;
            }
            const resultList = stages.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
            setStagesToItems(resultList);
            return resultList.length;
        }
        return 0
    }, [props.idStage, toJS(allStages), currentStage]);

    const handleStagesFromLoad = useCallback(async (value: string | null, limit?: number | undefined) => {
        if (currentStage) {
            const stages = generateCheckedStages('from');
            if (isNull(value)) {
                setStagesFromItems(stages);
                return stages.length;
            }
            const resultList = stages.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
            setStagesFromItems(resultList);
            return resultList.length;
        }
        return 0
    }, [props.idStage, toJS(allStages), currentStage]);

    const setCheckedAll = useCallback((stageType: 'to' | 'from', checked: boolean) => {
        if (stageType === 'to') {
            const findedAllStages = stagesToItems.find(stage => stage.id === ALL_STAGES);
            stagesToItems.forEach(stage => stage.isChecked = checked ?? !findedAllStages?.isChecked);
            if (checked) {
                setStagesToValue([findedAllStages!]);
                stagesToItems.forEach(stage => {
                    if (stage.id !== ALL_STAGES) {
                        dispatcher.stageModel.addMoveRule(currentStage!.id, stage.id as string);
                    }
                })
            } else {
                setStagesToValue([]);
                currentStage?.stagesTo.forEach(stage => {
                    dispatcher.stageModel.deleteRuleById(currentStage.id, stage);
                })
            }

        } else if (stageType === 'from') {
            const findedAllStages = stagesFromItems.find(stage => stage.id === ALL_STAGES);
            stagesFromItems.forEach(stage => stage.isChecked = checked ?? !findedAllStages?.isChecked);
            if (checked) {
                setStagesFromValue([findedAllStages!]);
                stagesFromItems.forEach(stage => {
                    if (stage.id !== ALL_STAGES) {
                        dispatcher.stageModel.addMoveRule(stage.id as string, currentStage!.id);
                    }
                })
            } else {
                setStagesFromValue([]);
                currentStage?.stagesFrom.forEach(stage => {
                    dispatcher.stageModel.deleteRuleById(stage, currentStage.id);
                })
            }
        }
    }, [toJS(stagesToItems), toJS(stagesFromItems), toJS(currentStage?.stagesTo), toJS(currentStage?.stagesFrom)]);


    const changeCheckedStageByType = useCallback((stageType: 'to' | 'from', checked: boolean | undefined, stageId: string | null) => {
        if (stageType === 'to') {
            const index = stagesToItems.findIndex(stage => stage.id === stageId);
            if (index !== -1) {
                stagesToItems[index].isChecked = checked;
            }
        }
        else if (stageType === 'from') {
            const index = stagesFromItems.findIndex(stage => stage.id === stageId);
            if (index !== -1) {
                stagesFromItems[index].isChecked = checked;
            }
        }
    }, [toJS(stagesToItems), toJS(stagesFromItems)]);

    const handleStagesToSelect = useCallback((item: Item | null, checkedValue?: boolean | undefined) => {
        if (currentStage) {
            if (item) {
                if (item.id === ALL_STAGES) {
                    setCheckedAll('to', checkedValue ?? false);
                }
                else {
                    if (!checkedValue) {
                        dispatcher.stageModel.deleteRuleById(currentStage.id, item.id as string);
                    }
                    else {
                        dispatcher.stageModel.addMoveRule(currentStage.id, item.id as string);
                    }
                    changeCheckedStageByType('to', checkedValue, item.id as string);
                    const findedAllStages = stagesToItems[0];
                    if (checkAllCheckbox(stagesToItems)) {
                        stagesToItems[0].isChecked = true;
                        setStagesToValue([findedAllStages]);
                    } else {
                        findedAllStages.isChecked = false;
                        const newValues = stagesToItems.filter(stage => stage.isChecked);
                        setStagesToValue(newValues);
                        newValues.forEach(value => {
                            dispatcher.stageModel.addMoveRule(currentStage.id, value.id as string);
                        })
                    }
                }
            }
            else {
                setStagesToValue([]);
                setCheckedAll('to', false);
                currentStage.stagesTo.forEach(stage => {
                    dispatcher.stageModel.deleteRuleById(currentStage.id, stage);
                })
            }
        }
    }, [currentStage?.id, toJS(currentStage?.stagesTo), toJS(stagesToItems)]);

    const handleStagesFromSelect = useCallback((item: Item | null, checkedValue?: boolean | undefined) => {
        if (currentStage) {
            if (item) {
                if (item.id === ALL_STAGES) {
                    setCheckedAll('from', checkedValue ?? false);
                }
                else {
                    if (!checkedValue) {
                        dispatcher.stageModel.deleteRuleById(item.id as string, currentStage.id);
                    }
                    else {
                        dispatcher.stageModel.addMoveRule(item.id as string, currentStage.id);
                    }
                    changeCheckedStageByType('from', checkedValue, item.id as string);
                    const findedAllStages = stagesFromItems[0];
                    if (checkAllCheckbox(stagesFromItems)) {
                        stagesFromItems[0].isChecked = true;
                        setStagesFromValue([findedAllStages]);
                    } else {
                        findedAllStages.isChecked = false;
                        const newValues = stagesFromItems.filter(stage => stage.isChecked);
                        setStagesFromValue(newValues);
                        newValues.forEach(value => {
                            dispatcher.stageModel.addMoveRule(value.id as string, currentStage.id);
                        })
                    }
                }
            }
            else {
                setStagesFromValue([]);
                setCheckedAll('from', false);
                currentStage.stagesFrom.forEach(stage => {
                    dispatcher.stageModel.deleteRuleById(stage, currentStage.id);
                })
            }
        }
    }, [currentStage?.id, toJS(currentStage?.stagesFrom), toJS(stagesFromItems)]);

    const updateValues = useCallback(() => {
        const generatingStagesTo = generateCheckedStages('to');
        const generatingStagesFrom = generateCheckedStages('from');
        if (generatingStagesTo[0].isChecked) {
            setStagesToValue([generatingStagesTo[0]]);
        } else {
            const newValuesTo = generatingStagesTo.filter(stage => stage.isChecked);
            setStagesToValue(newValuesTo);
        }
        if (generatingStagesFrom[0].isChecked) {
            setStagesFromValue([generatingStagesFrom[0]]);
        } else {
            const newValuesFrom = generatingStagesFrom.filter(stage => stage.isChecked);
            setStagesFromValue(newValuesFrom);
        }
    }, [allStages, currentStage]);

    useEffect(() => {
        if (currentStage) {
            updateValues();
        }
    }, [props.idStage]);

    return (<>
        <Field
            name='transitionToStage'
            caption='На какие стадии можно переходить'
            className={styles.field}
        >
            <Dropdown
                items={stagesToItems}
                onChangeValue={handleStagesToSelect}
                onItemsLoad={handleStagesToLoad}
                value={stagesToValue}
                selectStyle={SelectStyleName.Base}
                isMultiSelect
                isInput
            />
        </Field>
        <Field
            name='stagesFromTransition'
            caption='С каких стадий можно переходить'
            className={styles.field}
        >
            <Dropdown
                items={stagesFromItems}
                onChangeValue={handleStagesFromSelect}
                onItemsLoad={handleStagesFromLoad}
                value={stagesFromValue}
                selectStyle={SelectStyleName.Base}
                isMultiSelect
                isInput
            />
        </Field>
    </>)
});