import ToggleSelect, { IToggleSelectItem } from "features/toggle-select/toggle-select";
import { ExistFieldsBlock, NewFieldsBlock } from "./blocks";

import { MoreTitle } from "pages/section-wizzard/data/data";
import { IGridItem } from "components/select/types";
import { ButtonStyle } from "components";
import { DisplayedPanel } from "types/entity";

interface Props extends React.HTMLAttributes<HTMLElement> {
    tabs: IToggleSelectItem[],
    newFields: Array<IGridItem>,
    sourcePanelElements: Array<IGridItem>,
    onMoveBoxTab: (id: string, value: boolean, currentDisplayedPanel?: DisplayedPanel) => void
}

export const ConstructorSourcePanel = (props: Props) => {

    return (
        <>
            <NewFieldsBlock items={props.newFields} />
            <ExistFieldsBlock items={props.sourcePanelElements} />
            <ToggleSelect
                items={props.tabs}
                name={MoreTitle}
                buttonStyle={ButtonStyle.Settings}
                onToggleClick={props.onMoveBoxTab}
            />
        </>
    );
};