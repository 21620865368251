import styles from "./column.module.css";
import { useEffect, useState } from "react";
import CheckBox from "components/check-box/check-box";
import { ICustomColumn } from "components/grid/custom-grid/custom-grid";
import { observer } from "mobx-react-lite";


const Column = observer(function (props: { column: ICustomColumn, value: any, checkedAll: boolean }) {
    let [checked, setCheck] = useState<boolean>(false);
    const onChange = props.column.onChange!;

    useEffect(() => {
        setCheck(props.checkedAll);
    }, [props.checkedAll])


    return (
        <div className={styles.checkBoxContainer}>
            <CheckBox checked={checked} onChangeChecked={(value) => {
                onChange({ value: value, column: props.value });
                setCheck(value);
            }} />
        </div>
    )
})

export default Column;