import { useLocation, useNavigate } from "react-router-dom";
import { CSSProperties, useCallback, useEffect, useMemo } from "react";
import {v4} from "uuid";
import {toJS} from "mobx";

import {dispatcher, store} from "store";
import {binding, bindingInXml} from "module/reactor/binding/binding";

import {LowFirst} from "entities/lowFirst";

import actions from "./data/actions";
import {Button, ButtonStyle, Select} from "components";
import {StagePanel, StageParams} from "features/stage-panel";

import {MovingRule, StageId, StageSetting, StageType, Stages} from "types/entity";

import IProps from "module/reactor/components/IProps";
import {ArrowToDownMini, ArrowToRightStretch, GoBack} from "assets/icons";

import styles from "./full-view.module.css";
import {lowerFirst} from "lodash";

interface FullViewProps extends IProps {
    entityTitle: string;
    navigation: string;
    viewColumnId: string;
    onSave?: string;
    onCancel?: string;
    isChange?: string;
    setCurrentStage: () => void;
    currentStage: StageId;
}

function FullView(props: FullViewProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const entityTitle = binding(props.services, props.entityTitle);
    const navigation = bindingInXml(props.services, props.navigation);
    const viewColumnId = bindingInXml(props.services, props.viewColumnId);

    const sectionWizzard = useMemo(() => dispatcher.entity.get()?.entity.sectionWizzard, [toJS(dispatcher.entity.get()?.entity.sectionWizzard)]);
    const currentStageId = useMemo(() => dispatcher.currentRow.get()[`${lowerFirst(sectionWizzard?.stageModelConfig?.stageLookupName)}Id`] ?? sectionWizzard?.stageModelConfig?.stageSettings.find(stage => stage.order === 0)?.stageId, [sectionWizzard?.stageModelConfig?.stageLookupName, sectionWizzard?.stageModelConfig?.stageSettings, toJS(dispatcher.currentRow.get())])

    const handleChangeCurrentStageId = useCallback((stageId: string) => {
        if (!sectionWizzard?.stageModelConfig?.stageLookupName) {
            return
        }
        dispatcher.currentRow.set(`${lowerFirst(sectionWizzard?.stageModelConfig?.stageLookupName)}Id`, stageId);
    }, [sectionWizzard?.stageModelConfig?.stageLookupName])


    useEffect(() => {
        if (location?.state?.entityColumn?.toLowerCase() !== 'id') {
            return;
        }
        const currentRow = dispatcher.currentRow.get();

        const entityColumnId = Object.keys(currentRow).find(key => key.toLowerCase() === `${location?.state?.detailLinkColumn}`.toLowerCase());

        if (entityColumnId) {
            const defaultValue = {
                id: location?.state?.entityViewColumn?.id,
                name: location?.state?.entityViewColumn?.displayValue,
                displayValue: location?.state?.entityViewColumn?.displayValue,
            }
            dispatcher.currentRow.set(entityColumnId, defaultValue);
        }
    }, []);

    function convertToStageParams(stages: Stages[], movingRules: MovingRule[], stageSettings: StageSetting[]): StageParams[] {
        const currentStageSetting = sectionWizzard!.stageModelConfig?.stageSettings.find(x => x.stageId === currentStageId) ?? sectionWizzard!.stageModelConfig?.stageSettings.find(stage  => stage.order === 0);

        if (currentStageSetting === null)
            return [];
        return stages.map(stage => {
            const matchingRule = movingRules.filter(rule => rule.stageFromId === stage.id);
            const matchingSetting = stageSettings.find(setting => setting.stageId === stage.id);

            const stagesTo: Array<string> = [];
            matchingRule?.forEach(rule => {
                stagesTo.push(rule.stageToId);
            });

            const stageParams: StageParams = {
                id: v4(),
                name: stage.name,
                stageId: stage.id,
                color: matchingSetting!.color,
                order: matchingSetting!.order,
                allowStages: stagesTo,
                disabled: stage.id === currentStageId ? true : undefined,
                stageType: stage.id === currentStageId ? StageType.CURRENT : matchingSetting!.order > currentStageSetting!.order ? StageType.DEFAULT : StageType.PASSED,
                resultType: matchingSetting ? matchingSetting.resultType : null,
            };

            return stageParams;
        });
    }

    const intermediateStages = useMemo(() =>
            sectionWizzard?.stageModelConfig &&
            convertToStageParams(sectionWizzard?.stageModelConfig.stages ?? [], sectionWizzard?.stageModelConfig.movingRules ?? [], sectionWizzard?.stageModelConfig.stageSettings ?? [])
                .filter(x => x.resultType === null).sort((a, b) => a.order - b.order)
        , [sectionWizzard?.stageModelConfig]
    );

    const resultStages = useMemo(() =>
            sectionWizzard?.stageModelConfig &&
            convertToStageParams(sectionWizzard?.stageModelConfig.stages ?? [], sectionWizzard?.stageModelConfig.movingRules ?? [], sectionWizzard?.stageModelConfig.stageSettings ?? []).filter(x => x.resultType !== null)
        , [sectionWizzard?.stageModelConfig]
    );

    const fullViewStyle: CSSProperties = useMemo(() => (
        intermediateStages || resultStages ? {
            gridTemplateRows: `auto auto 1fr`,
        } : {
            gridTemplateRows: `auto 1fr`,
        }
    ), [intermediateStages, resultStages]);

    const onSave: () => void = () => {
        const currentRow = dispatcher.currentRow.get();
        if (!currentRow["CreatedOn"]) {
            dispatcher.currentRow.set(LowFirst("CreatedOn"), new Date().toISOString());
        }
        dispatcher.currentRow.set(LowFirst("ModifiedOn"), new Date().toISOString());

        binding(props.services, props.onSave)();
    };
    const onCancel: () => void = binding(props.services, props.onCancel);

    const isChange = store.hasChanges;

    //TODO возможно не делать через entities
    const entity = useMemo(() => dispatcher.entity.get()!, [dispatcher.entity.get(), viewColumnId]);
    const row = useMemo(() => dispatcher.currentRow.get()!, [dispatcher.currentRow.get(), viewColumnId]);
    const cellTitle = LowFirst(entity.entity.columns.find(column => column.columnId === viewColumnId)!.columnName);

    const title = row[cellTitle];

    const navigateToEntity = () => {
        const arrayOfNavigation = navigation.split("/");
        arrayOfNavigation[arrayOfNavigation.length - 1] = arrayOfNavigation[arrayOfNavigation.length - 1].toLowerCase();

        navigate(arrayOfNavigation.join('/'));
        if (row.id === "new") {
            dispatcher.entity.get()?.entity.rows.pop();
        }
    }

    const navigateBack = () => {
        if (row.id === "new") {
            const index = dispatcher.entity.get()?.entity.rows.indexOf(dispatcher.currentRow.get());
            if (index && index > -1) {
                //TODO почему-то эта залупа не убирает элемент из массива
                dispatcher.entity.get()!.entity.rows.splice(index, 1);
            };
        }
        navigate(-1)
    }


    if (!entity || !row) {
        return null;
    }

    return (
        <div className={styles.fullView} style={fullViewStyle}>
            <div className={styles.fullViewHeader}>
                <div className={styles.buttons}>
                    <Button
                        key="closeButton"
                        firstIcon={<GoBack/>}
                        caption={"Назад"}
                        onClick={navigateBack}
                        isVisible={!isChange}
                        style={ButtonStyle.Link}
                        className={styles.button}
                    />
                    <Button
                        key="cancel"
                        caption={"Отменить"}
                        onClick={onCancel}
                        isVisible={isChange}
                        style={ButtonStyle.Subtle}
                        className={styles.button}
                    />
                    <Button
                        key="save"
                        caption={"Сохранить изменения"}
                        onClick={onSave}
                        isVisible={isChange}
                        style={ButtonStyle.Success}
                        className={styles.button}
                        isDisabled={!store.requiredFieldsFilled}
                    />
                </div>
                <div className={styles.fullViewTitle}>
                    <span className={styles.fullViewTitleSection} onClick={navigateToEntity}>{entityTitle}</span>
                    <ArrowToRightStretch/>
                    <span className={styles.fullViewTitleDetail}>
                        {title as string}
                    </span>
                </div>
                <Select
                    name={"Действия"}
                    items={actions}
                    onChangeValue={() => {
                    }}
                    secondIcon={<ArrowToDownMini/>}
                    isRotateSecondIcon={true}
                    styles={ButtonStyle.Link}
                    classNameButton={styles.actionButton}
                />
            </div>
            {sectionWizzard?.stageModelConfig &&
                <StagePanel
                    intermediate={intermediateStages!}
                    result={resultStages!}
                    onChange={handleChangeCurrentStageId}
                    current={currentStageId}
                />
            }
            <div className={styles.fullViewContent}>
                {props.children}
            </div>
        </div>
    );
}

export default FullView;
