import { IFilterItem } from "entities/search";

export enum filterTableType {
  Contact = "Contact",
  Account = "Account",
  Sale = "Sale",
  Lead = "Lead",
}

const filterTable: IFilterItem[] = [
  {
    table: filterTableType.Contact,
    title: "Контакты",
  },
  {
    table: filterTableType.Account,
    title: "Контрагенты",
  },
  {
    table: filterTableType.Sale,
    title: "Продажи",
  },
  {
    table: filterTableType.Lead,
    title: "Лиды",
  },
];

export default filterTable;
