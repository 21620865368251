import { useState } from "react";
import TimePicker from "react-time-picker";

import classNames from "classnames";

import { ClocksIcon, Locked } from "assets/icons";

import styles from "components/input-for-section-designer/input.module.scss";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

export const InputTime = (props:{[className: string]: any }) => {
	const [selectedTime, setSelectedTime] = useState<Date>(props.value);

	const timeInputWrapper = classNames(`${styles.parent} time-picker-container`,
		{ [`${props.className}`]: props.className, 
			[`${styles.parentLockedTime} ${styles.parent} time-picker-container`]: props.isDisabled, 
		});

	 	const timeInput = classNames(styles.inputTime,
		{ [`${styles.inputTimeLocked}`]: props.isDisabled, }
	);

	const iconStyle = `${styles.iconWraper}`;

	return (
		<div {...props} className={timeInputWrapper}>
			<TimePicker
				onChange={(time: any) => {setSelectedTime(time); props.onChangeValue(time)}}
				value={selectedTime}
				format="HH:mm"
				clockIcon={null}
				clearIcon={null}
				className={timeInput}
				disabled={props.isDisabled}
				openClockOnFocus={false}
			/>
			{props.isDisabled ? <Locked className={styles.iconResult} />:<div className={iconStyle}><ClocksIcon/></div>}
		</div>
	);
};