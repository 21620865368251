import { observer } from "mobx-react-lite";

import { Input, InputStyleName } from "components";
import { InputWrapper } from "components/input-for-section-designer";

export type Props = {
	columnName: string | undefined;
	columnTitle?: string | undefined;
	prompt?: string | undefined;
	isRequired?: boolean | undefined;
	rounding?: string | undefined;
	value: string;
	isDisabled?: boolean;
	onChange: (value: string) => void;
}

export const String = observer(function (props: Props) {

	return (
		<InputWrapper
			name={props.columnTitle ?? ""}
			infoCaption={props.prompt}
			caption={props.columnTitle ?? ""}
			required={props.isRequired}
		>
			<Input
				inputStyle={InputStyleName.FullViewInput}
				value={props.value}
				onChangeValue={props.onChange}
				placeholder=""
				isDisabled={props.isDisabled}
				isDisabledLocked={props.isDisabled}
			/>
		</InputWrapper>
	);
});