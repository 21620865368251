import { useRef, useState } from "react";

import { Button, ButtonStyle } from "components";

import { IItem } from "entities/IItem";
import { binding, bindingInXml } from "module/reactor/binding/binding";

import { ArrowToDown } from "assets/icons";

import styles from "./Dropdown.module.css";
import stylesBase from "components/select/select.module.css";


interface Dropdown {
    items: string;
    caption: string;
    onChange: string;
    className?: string;
    placeholder?: string;
    services: Map<string, any>;
}



function Dropdown(props: Dropdown) {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [showItems, setShowItems] = useState(false);

    const items = binding(props.services, props.items);
    const caption = bindingInXml(props.services, props.caption);
    const onChange = binding(props.services, props.onChange);


    let classNames = `${stylesBase.wrapper} ${styles.wrapper} ${props.className}`;
    let listClassNames = `${stylesBase.list} ${styles.list}`;
    let inputClassNames = `${styles.inputPrimary} `;
    let listItemClassNames = `${stylesBase.listItem} `;

    if (props.items && props.items!.length > 0 && showItems) {
        listClassNames += " " + stylesBase.visible;
    }

    if (showItems) {
        inputClassNames += `${styles.focusPrimary} `;
    }

    function hideMenu() {
        setShowItems(false);
        document.removeEventListener("click", handleClick);
    }

    function handleClick(event: Event) {
        if (wrapperRef.current != null && !wrapperRef.current.contains(event.target as Node)) {
            hideMenu();
        }
    }

    const handleItemClick = (selectedItem: IItem) => {
        setShowItems(false);
        onChange(selectedItem);
    };

    function onSelectOpen() {
        setShowItems(true);
        document.addEventListener("click", handleClick);
    }

    const onButtonClick = () => {
        if (showItems) {
            hideMenu();
        }
        else {
            onSelectOpen();
        }
    }

    function defaultRender(value: IItem): JSX.Element {
        if (value)
            return <span className={styles.displayValue}>{value.displayValue}</span>
        else return <></>
    }

    return (
        <div ref={wrapperRef} className={classNames}>
            <div className={inputClassNames} onClick={onButtonClick}>
                <div className={styles.item}>
                    {caption}
                </div>
                <Button
                    onClick={onButtonClick}
                    firstIcon={<ArrowToDown />}
                    style={ButtonStyle.Icon}
                    className={showItems ? stylesBase.selectButton + " " + stylesBase.close : stylesBase.selectButton}
                />
            </div>
            <ul className={listClassNames}>
                <div className={styles.itemsContainer}>
                    {items.map((item: IItem) => {
                        return (
                            <li key={item.value} className={listItemClassNames} onClick={() => handleItemClick(item)}>
                                {defaultRender(item)}
                            </li>
                        )
                    })}
                </div>
            </ul>
        </div>
    )
}

export default Dropdown;