import Api from "api/http/api";

class User extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/User";
	}

	userById = (id: string) => {
		this.setPath(`${this.basicPath}/users/${id}`);
		return this;
	};
	userAuthenticate = () => {
		this.setPath(`${this.basicPath}/authenticate`);
		return this;
	};
	getUser = () => {
		this.setPath(`${this.basicPath}/getuser`);
		return this;
	};
	userList = () => {
		this.setPath(`${this.basicPath}/users`);
		return this;
	};
	// refreshToken = () => {
	// 	this.setPath(`${this.basicPath}/refresh-token`);
	// 	return this;
	// };
}

const user = new User();

export { user };