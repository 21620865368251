import { useLocation, useNavigate } from "react-router-dom";

import { dispatcher, store } from "store";
import { binding, bindingInXml } from "module/reactor/binding/binding";

import { LowFirst } from "entities/lowFirst";

import { Button, ButtonStyle, Select } from "components";

import IProps from "module/reactor/components/IProps";

import { ArrowToDownMini, ArrowToRightStretch, GoBack } from "assets/icons";

import styles from "./detail-full-view.module.css";
import { toJS } from "mobx";
import { useEffect } from "react";


interface DetailFullViewType extends IProps {
    entityTitle: string;
    viewColumn: string;
    detailTitle: string;
    navigationToEntity: string;
    navigationToRecord: string;

    onSave?: string;
    onCancel?: string;
}

function DetailFullView(props: DetailFullViewType) {
    const navigate = useNavigate();
    const location = useLocation();
    const entityTitle = binding(props.services, props.entityTitle);
    const detailTitle = binding(props.services, props.detailTitle);
    const viewColumn = bindingInXml(props.services, props.viewColumn);
    const navigationToEntity = bindingInXml(props.services, props.navigationToEntity);
    const navigationToRecord = bindingInXml(props.services, props.navigationToRecord);

    useEffect(() => {
        if (location?.state?.entityColumn?.toLowerCase()!=='id'){
            return;
        }
        const currentRow = dispatcher.currentRow.get();
        const entityColumnId = Object.keys(currentRow).find(key => key.toLowerCase() === `${location?.state?.detailLinkColumn}`.toLowerCase());

        if (entityColumnId) {
            const defaultValue={
                id: location?.state?.entityViewColumn?.id,
                name: location?.state?.entityViewColumn?.displayValue,
                displayValue: location?.state?.entityViewColumn?.displayValue,
            }
            dispatcher.currentRow.set(entityColumnId, defaultValue);
        }
    }, [])

    const onSave: () => void = () => {
        if (!dispatcher.currentRow.get()["CreatedOn"]) {
            dispatcher.currentRow.set(LowFirst("CreatedOn"), new Date().toISOString());
        }
        dispatcher.currentRow.set(LowFirst("ModifiedOn"), new Date().toISOString());

        binding(props.services, props.onSave)();
    };
    const onCancel: () => void = binding(props.services, props.onCancel);

    const isChange = store.hasChanges;

    //TODO возможно не делать через entities
    const entity = dispatcher.entity.get()!;
    const row = dispatcher.currentRow.get()!;

    if (!entity || !row) {
        return null;
    }

    const navigateToEntity = () => {
        navigate(navigationToEntity);
        if (row.id === "new") {
            dispatcher.entity.get()?.entity.rows.pop();
        }
    }

    const navigateToRecord = () => {
        navigate(navigationToRecord, { state: null, replace: true });
        if (row.id === "new") {
            dispatcher.entity.get()?.entity.rows.pop();
        }
    }

    const navigateBack = () => {
        navigate(-1);
        if (row.id === "new") {
            dispatcher.entity.get()?.entity.rows.pop();
        }
    }

    return (
        <div className={styles.fullView}>
            <div className={styles.fullViewHeader}>
                <div className={styles.buttons}>
                    <Button
                        key="closeButton"
                        firstIcon={<GoBack />}
                        caption={"Назад"}
                        onClick={navigateBack}
                        isVisible={!isChange}
                        style={ButtonStyle.Link}
                        className={styles.button}
                    />
                    <Button
                        key="cancel"
                        caption={"Отменить"}
                        onClick={onCancel}
                        isVisible={isChange}
                        style={ButtonStyle.Subtle}
                        className={styles.button}
                    />
                    <Button
                        key="save"
                        caption={"Сохранить изменения"}
                        onClick={onSave}
                        isVisible={isChange}
                        style={ButtonStyle.Success}
                        className={styles.button}
                        isDisabled={!store.requiredFieldsFilled}
                    />
                </div>
                <div className={styles.fullViewTitle}>
                    <span className={styles.fullViewTitleSection} onClick={navigateToEntity}>{entityTitle}</span>
                    <ArrowToRightStretch />
                    <span className={styles.fullViewTitleSection} onClick={navigateToRecord} >
                        {viewColumn}
                    </span>
                    <ArrowToRightStretch />
                    <span className={styles.fullViewTitleDetail}>{detailTitle}</span>
                </div>
                <Select
                    name={"Действия"}
                    items={[]}
                    onChangeValue={() => { }}
                    secondIcon={<ArrowToDownMini />}
                    isRotateSecondIcon={true}
                    styles={ButtonStyle.Link}
                    classNameButton={styles.actionButton}
                />
            </div>
            <div className={styles.fullViewContent}>
                {props.children}
            </div>
        </div>
    );
}

export default DetailFullView;
