import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { toNumber } from "lodash";

import { InputWrapper } from "components/input-for-section-designer";
import { InputNumber, InputStyleName } from "components";

export type Props = {
	columnName: string | undefined;
	columnTitle?: string | undefined;
	prompt?: string | undefined;
	isRequired?: boolean | undefined;
	rounding?: string | undefined;
	value: string;
	isDisabled?: boolean;
	onChange: (value: string) => void;
}

export const Decimal = observer(function (props: Props) {
	const handleChange = useCallback((value: string) => {
		const clearValue = value.replace(/[^+\d.]/g, "");
		props.onChange(clearValue.substring(0, clearValue.indexOf(".") === -1 ? Infinity : clearValue.indexOf(".") + (toNumber(props.rounding) + 1) ?? 0))
	}, [props]);

	return (
		<InputWrapper
			name={props.columnTitle ?? ""}
			caption={props.columnTitle ?? ""}
			required={props.isRequired}
			infoCaption={props.prompt}
		>
			<InputNumber
				inputStyle={InputStyleName.FullViewInput}
				value={props.value}
				onChangeValue={handleChange}
				placeholder=""
				isDisabled={props.isDisabled}
				isDisabledLocked={props.isDisabled}
			/>
		</InputWrapper>
	);
});