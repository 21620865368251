import { v4 } from "uuid";

import { ButtonStyle } from "components";

import { ColumnType } from "entities/ColumnType";
import { Item } from "types"
import { IGridItem } from "components/select/types";
import {
	TextIcon, DateTimeIcon, DateIcon, Clocks,
	SectionMasterLookupIcon, LogicalIcon, Numbers, DecimalIcon,
	ChangeView, FieldGroupIcon,
} from "assets/icons";

export const MAIN_TAB_NAME = "mainInfo";
export const DEFAULT_WARNING_TEXT = "Вы действительно хотите отменить настройки?\nТекущий прогресс будет утерян";
export const DEFAULT_ERROR_VALUE = "Значение не соответствует типу";

export const ERROR_VALUE_EXIST_SECTION_TITLE = "Раздел с таким названием уже существует";
export const ERROR_VALUE_EXIST_SECTION_NAME = "Раздел с таким системным названием уже существует";

export const ERROR_VALUE_EXIST_TITLE = "Поле с таким заголовком уже существует";
export const ERROR_VALUE_EXIST_NAME = "Поле с таким системным названием уже существует";

export const ERROR_LOOKUP_EXIST_TITLE = "Справочник с таким названием уже существует";
export const ERROR_LOOKUP_EXIST_NAME = "Справочник с таким системным названием уже существует";

export const ERROR_GROUP_EXIST_TITLE = "Группа полей с таким названием уже существует";
export const ERROR_GROUP_EXIST_NAME = "Группа полей с таким системным названием уже существует";

export const ERROR_TAB_EXIST_TITLE = "Вкладка с таким названием уже существует";
export const ERROR_TAB_EXIST_NAME = "Вкладка с таким системным названием уже существует";

export const ERROR_DETAIL_EXIST_TITLE = "Деталь с таким заголовком уже существует";
export const ERROR_DETAIL_EXIST_NAME = "Деталь с таким системным названием уже существует";

export const NewElemTitle = "новый элемент";
export const FieldsTitle = "существующие поля";
export const MoreTitle = "дополнительные вкладки";

export interface ValidationState {
	isInvalid: boolean;
	isNotUnique?: boolean;
	error?: string;
}
export interface IFieldSelectProps extends React.HTMLAttributes<HTMLElement> {
	name: string;
	buttonStyle?: ButtonStyle;
}

export const NewFields: Array<IGridItem> = [
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.String,
		displayValue: "Текстовое",
		icon: <TextIcon />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.DateTime,
		displayValue: "Дата и время",
		icon: <DateTimeIcon />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Date,
		displayValue: "Дата",
		icon: <DateIcon />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Time,
		displayValue: "Время",
		icon: <Clocks />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Lookup,
		displayValue: "Справочное",
		icon: <SectionMasterLookupIcon />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Boolean,
		displayValue: "Логическое",
		icon: <LogicalIcon />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Integer,
		displayValue: "Целое число",
		icon: <Numbers />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Decimal,
		displayValue: "Дробное число",
		icon: <DecimalIcon />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Detail,
		displayValue: "Деталь",
		icon: <ChangeView />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.FieldGroup,
		displayValue: "Группа полей",
		icon: <FieldGroupIcon />
	},
];


export enum SettingName {
	Files = "Файлы",
	Comments = "Комментарии",
	Chronology = "Хронология",
	LinkedRecords = "Связанные записи"
}

export interface ISettings {
	id: string,
	displayValue: string,
	checked: boolean
}
export interface ITab {
	tabIndex: number;
	tabName: string;
	caption: string;
	tooltipCaption?: string;
	action: () => void;
}

export enum LookupType {
	NewLookup = "newLookup",
	ExistLookup = "existLookup",
}