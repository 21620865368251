import accountEntity from "entities/account/Account";
import contactEntity from "entities/contact/Contact";
import leadEntity from "entities/lead/lead";
import saleEntity from "entities/sale/Sale";
import { Item } from "types";

import { DocsModule, Homepage, SalesModule, SectionWizzard } from "assets/icons";

import styles from "./../navigation-panel.module.css";
import { SectionWizzardMode } from "pages/section-wizzard/section-wizzard";

export enum addItemIds {
    NewAccount,
    NewContact,
    NewSale,
    NewLead
}

export enum profileItemIds {
    AccountManagement,
    PersonalSettings,
    Profile,
    Exit
}
export const profileItems: Item[] = [
    {
        id: profileItemIds.AccountManagement,
        name: "Управление аккаунтом",
        isSeparator: true,
    },
    {
        id: profileItemIds.PersonalSettings,
        name: "Персональные настройки",
    },
    {
        id: profileItemIds.Profile,
        name: "Профиль",
        isSeparator: true,
    },
    {
        id: profileItemIds.Exit,
        name: "Выйти",
        isRed: true
    },
];

export enum NavigationMenu {
    Workspace,
    SectionWizzard,
    Sales,
    Docs,
    Exit
}
export const NavigationMenuItems = [
    {
        id: NavigationMenu.Workspace,
        name: "Мой рабочий стол",
        icon: <Homepage />,
        note: "Настройте свое рабочее пространство",
        path: "/workspace",
        classNames: styles.sectionsListItem
    },
    {
        id: NavigationMenu.SectionWizzard,
        name: "Дизайнер раздела",
        icon: <SectionWizzard />,
        note: "Создайте новый пользовательский раздел",
        isSeparator: true,
        path: `/sectionMaster/${SectionWizzardMode.NewSection}/new`,
        target: "_blank",
        classNames: styles.sectionsListItem
    },
    {
        id: NavigationMenu.Sales,
        name: "Продажи",
        icon: <SalesModule />,
        note: "Управляйте полным циклом продаж",
        classNames: styles.sectionsListItem,
        option: "скоро"
    },
    {
        id: NavigationMenu.Docs,
        name: "Документооборот",
        icon: <DocsModule />,
        note: "Организуйте документооборот в вашей организации",
        classNames: styles.sectionsListItem,
        option: "скоро"
    },
];