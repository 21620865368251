import { useCallback, useState } from "react";

import classNames from "classnames";

import styles from "./modal-viewer.module.css";


interface IPopup extends React.HTMLAttributes<HTMLElement> {
	closeFunc?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	isLargePopup?: boolean
}

export function Popup(props: IPopup) {

	const popupClasses = classNames({
		[`${styles.popup}`]: !props.isLargePopup,
		[`${styles.largePopup}`]: props.isLargePopup,
	});

	const handleClose = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (props.closeFunc) {
			props.closeFunc(e);
		}
	}, [props.closeFunc]);

	return (
		<>
			<div className={styles.backdrop} onClick={handleClose} />
			<div className={popupClasses} >
				{props.children}
			</div>
		</>

	);
};
