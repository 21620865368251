import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";

import { synchroiser } from "synchroiser";
import { dispatcher, store } from "store";

import Input from "components/input/input";
import SkeletonAccountFullView from "pages/accounts/accountFullView/skeleton/SkeletonAccountFullView";
import FullView from "features/full-view/full-view";
import HorizontalField from "components/field/horizontal-field";
import InputNumber from "components/input/input-number";
import Select from "components/select/select";
import InputPhone from "components/input/input-phone";
import SearchSelect from "components/select/search-select/search-select";
import { IStageIndicationPanel } from "components/stage-indication/stage-indication-panel/stage-indication-panel";
import { SelectStyleName } from "components/select/select-styles";
import { InputStyleName } from "components/input/input-styles";

import leadEntity, { Lead } from "entities/lead/lead";
import Entity from "entities/Entity";
import { validatePhoneNumber, validateRequired } from "entities/Validation";
import IStage from "entities/IStage";
import { leadStageStore } from "entities/lead/leads-section-store";
import { NotFoundPage } from "pages/error-page/error-page";

import { Cart } from "assets/icons";
import { InProgress } from "assets/icons";

import styles from "./LeadFullView.module.css";


const LeadFullView = observer(() => {
	const sale = useParams<{ id: string }>();

	useEffect(() => {
		leadEntity.load(sale.id!);
		leadStageStore.load(null, null, 0);
	}, []);

	useEffect(() => {
		if (store.currentEntityId) {
			synchroiser.switchSection("leads");
		}
		dispatcher.currentRow.switch(sale.id!);
	}, [store.sections, sale.id]);

	if (leadEntity.isLoading || leadStageStore.isLoading) {
		return (<SkeletonAccountFullView />);
	}

	if (!leadEntity.entity.id) return (<NotFoundPage/>);

	return (
		<FullView entity={leadEntity}
			mainInformation={
				{
					title: leadEntity.entity.name,
					dontDisplayTitle: true,
					mainInformationDataLeft: <MainInformationDataLeft entity={leadEntity} />,
					mainInformationDataRight: <MainInformationDataRight entity={leadEntity} />
				}
			}
			mainInformationDetails={MainInformationDetails()}
			stages={
				leadStageStore.data && leadEntity.entity.stage ?
                    {
                    	stages: leadStageStore.data as IStage[],
                    	stage: leadEntity.entity.stage
                    } as IStageIndicationPanel
					: null}
		/>
	);
});

const MainInformationDetails = (() => {
	return ([
		{
			caption: "Продажи",
			iconCaption: <Cart />,
			body: <InProgress style={{ margin: "0 auto" }} />
		},
	]);
});

const MainInformationDataLeft = observer((props: { entity: Entity<Lead> }) => {
	return (
		<>
			<HorizontalField name={"number"} caption={"Номер"} classNameCaption={styles.fieldCaption}>
				<InputNumber
					value={props.entity.entity.number}
					placeholder={""}
					onChangeValue={(value: string) => { }}
					inputStyle={InputStyleName.FullViewInput}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
					isDisabled={true}
					isDisabledLocked={true}
				/>
			</HorizontalField>

			<HorizontalField key={"title"} name={"title"} caption={"Название"} classNameCaption={styles.fieldCaption}>
				<Input
					value={props.entity.entity.title}
					placeholder={""}
					onChangeValue={(value: string) => { }}
					inputStyle={InputStyleName.FullViewInput}
					isDisabled={true}
					isDisabledLocked={true}
				/>
			</HorizontalField>

			<HorizontalField name={"priority"} caption={"Приоритет"} required={true} classNameCaption={styles.fieldCaption}>
				<Select
					items={props.entity.lookups["LeadPriority"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "priority");
						validateRequired(value, props.entity.entity.validation.priority);
					}}
					isInput={true}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					onItemsLoad={async () => {
						return await props.entity.loadLookups("LeadPriority", undefined, null, false, undefined, 0);
					}}
					value={props.entity.entity.priority}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
					isInvalid={props.entity.entity.validation.priority.isInvalid}
					invalidMessage={props.entity.entity.validation.priority.error}
					onFocusOut={() => validateRequired(props.entity.entity.priority, props.entity.entity.validation.priority)}
				/>
			</HorizontalField>

			<HorizontalField key={"owner"} name={"owner"} caption={"Ответственный"} required={false} classNameCaption={styles.fieldCaption}>
				<SearchSelect
					items={props.entity.lookups["User"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "owner");
						validateRequired(value, props.entity.entity.validation.owner);
					}}
					isInput={true}
					onItemsLoad={async (s) => { return await props.entity.loadLookups("User", s); }}
					value={props.entity.entity.owner}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					className={styles.fullViewBasicInformationDataInput}
					classNameInput={styles.inputForSelect}
				/>
			</HorizontalField>

			<HorizontalField key={"contact"} name={"contact"} caption={"Контакт"} classNameCaption={styles.fieldCaption}>
				<SearchSelect
					items={props.entity.lookups["Contact"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "contact");
						validateRequired(value, props.entity.entity.validation.contact);
					}}
					isInput={true}
					onItemsLoad={async (s) => { return await props.entity.loadLookups("Contact", s); }}
					value={props.entity.entity.contact}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					className={styles.fullViewBasicInformationDataInput}
					classNameInput={styles.inputForSelect}
				/>
			</HorizontalField>


			<HorizontalField key={"account"} name={"account"} caption={"Контрагент"} classNameCaption={styles.fieldCaption}>
				<SearchSelect
					items={props.entity.lookups["Account"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "account");
						validateRequired(value, props.entity.entity.validation.account);
					}}
					isInput={true}
					onItemsLoad={async (s) => { return await props.entity.loadLookups("Account", s); }}
					value={props.entity.entity.account}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					className={styles.fullViewBasicInformationDataInput}
					classNameInput={styles.inputForSelect}
				/>

			</HorizontalField>
		</>
	);
});

const MainInformationDataRight = observer((props: { entity: Entity<Lead> }) => {
	return (
		<>
			<HorizontalField key={"needType"} name={"needType"} caption={"Тип потребности"} required={true} classNameCaption={styles.fieldCaption}>
				<Select
					items={props.entity.lookups["NeedType"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "needType");
						validateRequired(value, props.entity.entity.validation.needType);
					}}
					isInput={true}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					onItemsLoad={async () => { return await props.entity.loadLookups("NeedType", undefined); }}
					value={props.entity.entity.needType}
					isInvalid={props.entity.entity.validation.needType.isInvalid}
					invalidMessage={props.entity.entity.validation.needType.error}
					onFocusOut={() => validateRequired(props.entity.entity.needType, props.entity.entity.validation.needType)}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
				/>
			</HorizontalField>
			<HorizontalField key={"name"} name={"name"} caption={"ФИО"} required={true} classNameCaption={styles.fieldCaption}>
				<Input
					value={props.entity.entity.name}
					placeholder={""}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "name");
					}}
					isInvalid={props.entity.entity.validation.name.isInvalid}
					invalidMessage={props.entity.entity.validation.name.error}
					onFocusOut={() => validateRequired(props.entity.entity.name, props.entity.entity.validation.name)}
					inputStyle={InputStyleName.FullViewInput}
				/>
			</HorizontalField>

			<HorizontalField name={"phone"} caption={"Мобильный телефон"} required={true} classNameCaption={styles.fieldCaption}>
				<InputPhone
					value={props.entity.entity.phone}
					placeholder={"+7 (123) 456-78-90"}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "phone");
					}}
					inputStyle={InputStyleName.FullViewInput}
					isInvalid={props.entity.entity.validation.phone.isInvalid}
					invalidMessage={props.entity.entity.validation.phone.error}
					onFocusOut={() => validatePhoneNumber(props.entity.entity.phone, props.entity.entity.validation.phone)}
				/>
			</HorizontalField>
			<HorizontalField name={"email"} caption={"Email"} required={true} classNameCaption={styles.fieldCaption} >
				<Input
					value={props.entity.entity.email}
					placeholder={"example@web.com"}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "email");
					}}
					inputStyle={InputStyleName.FullViewInput}
					isInvalid={props.entity.entity.validation.email.isInvalid}
					invalidMessage={props.entity.entity.validation.email.error}
					onFocusOut={() => validateRequired(props.entity.entity.email, props.entity.entity.validation.email)}
				/>
			</HorizontalField>

			<HorizontalField name={"city"} caption={"Город"} classNameCaption={styles.fieldCaption}>
				<SearchSelect
					items={props.entity.lookups["City"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "city");
					}}
					isInput={true}
					onItemsLoad={async (s) => { return await props.entity.loadLookups("City", s); }}
					value={props.entity.entity.city}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					className={styles.fullViewBasicInformationDataInput}
					classNameInput={styles.inputForSelect}
				/>
			</HorizontalField>

			<HorizontalField key={"sourceReceipt"} name={"sourceReceipt"} required={true} caption={"Источник поступления"} classNameCaption={styles.fieldCaption}>
				<Select
					items={props.entity.lookups["SourceReceipt"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "sourceReceipt");
						validateRequired(value, props.entity.entity.validation.sourceReceipt);
					}}
					isInput={true}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					onItemsLoad={async () => { return await props.entity.loadLookups("SourceReceipt", undefined); }}
					value={props.entity.entity.sourceReceipt}
					isInvalid={props.entity.entity.validation.sourceReceipt.isInvalid}
					invalidMessage={props.entity.entity.validation.sourceReceipt.error}
					onFocusOut={() => validateRequired(props.entity.entity.sourceReceipt, props.entity.entity.validation.sourceReceipt)}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
				/>
			</HorizontalField>
		</>
	);
});


export default LeadFullView;