import { FullViewController, fullViewController } from "./selectors";
import { filterController, FilterController } from "./selectors/filter";
import { stageModels, StageModels } from "./selectors/stage-model";

export class Selector {
    stageModels: StageModels;
    filter: FilterController;
    fullView:FullViewController;

    constructor() {
        this.stageModels = stageModels;
        this.filter = filterController;
        this.fullView=fullViewController;
    }
}

export const selector = new Selector();