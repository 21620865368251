import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { api } from "api";
import { toJS } from "mobx";

import { v4 } from "uuid";
import { lowerCase } from "lodash";
import { UpFirst } from "entities/lowFirst";

import fieldConfigurationStore, { FieldStoreEnums } from "./field-configuration-store";
import {
	validateIsNumberAndRequired,
	validateRequired
} from "entities/Validation";

import {
	Field,
	Input,
	Toggle,
	Text,
	InputStyleName,
	SelectStyleName,
	Button,
	ButtonStyle,
	Dropdown,
	ValidationPrompt,
} from "components";

import { NewLookupTabs } from "./types";
import FilterColumnType, { ColumnType } from "entities/ColumnType";
import { ColumnSpecializationType } from "types/entity";
import IFilter, { ComparisonType, DataValueType } from "entities/filter/IFilter";
import { ILookup } from "entities/Entity";
import { ILookupData } from "pages/settings/data/Fields";
import { LookupType } from "pages/section-wizzard/data/data";

import {
	DragIcon,
	Cross
} from "assets/icons";
import TypeIcon from "features/type-icon/type-icon";

import styles from "./field-configuration.module.css";

interface FieldConfigurationProps {
	fieldType?: ColumnType;
	columnId?: string;
}

const FieldConfiguration = observer((props: FieldConfigurationProps) => {

	useEffect(() => {
		if (props.fieldType) {
			fieldConfigurationStore.setValueWithoutTrackingChanges(FieldStoreEnums.fieldType, props.fieldType);
		}
		if (props.columnId) {
			fieldConfigurationStore.getConfigurationById(props.columnId);
		}
		else {
			fieldConfigurationStore.setValueWithoutTrackingChanges(FieldStoreEnums.columnId, v4());
		}
	}, []);

	const configurationContent = useMemo(() => {
		return (
			<>
				{fieldConfigurationStore.lookupType === LookupType.NewLookup ?
					<NewLookupConfiguration /> : <DefaultConfiguration />
				}
			</>
		);
	}, [fieldConfigurationStore.lookupType]);

	return configurationContent;
});

export default FieldConfiguration;

const DefaultConfiguration = observer(() => {
	const [defValues, setDefValues] = useState<ILookup[]>([]);

	const fieldTypeTitleMemo = useMemo(() => {
		return <FieldTypeTitle fieldType={fieldConfigurationStore.fieldType} />;
	}, [fieldConfigurationStore.fieldType]);

	const basicSettingsMemo = useMemo(() => <BasicSettings />, []);

	const fieldTypeIsLookup = useMemo(() => {
		return fieldConfigurationStore.fieldType === ColumnType.Lookup;
	}, [fieldConfigurationStore.fieldType]);

	const fieldTypeIsDecemal = useMemo(() => {
		return fieldConfigurationStore.fieldType === ColumnType.Decimal;
	}, [fieldConfigurationStore.fieldType]);

	const handleRoundingChange = useCallback((value: string) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.rounding, value);
		fieldConfigurationStore.setSpecialization(FieldStoreEnums.specializations, ColumnSpecializationType.Double, { "rounding": value });
	}, []);

	const handleRoundingFocusOut = useCallback(() => {
		validateIsNumberAndRequired(
			fieldConfigurationStore.rounding,
			fieldConfigurationStore.validation.rounding,
		);
	}, []);

	const handleLookupChange = useCallback(async (value: any) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.selectedLookup, value);
		//Не подгружаем значения для разделов
		if (fieldConfigurationStore.selectedLookup?.name && !fieldConfigurationStore.selectedLookup.isSection) {
			await handleDefaultValuesLoad(null);
		}

		fieldConfigurationStore.setValue(FieldStoreEnums.selectedLookupDefaultValue, null);
	}, []);

	const handleLookupFocusOut = useCallback(() => {
		validateRequired(
			fieldConfigurationStore.selectedLookup,
			fieldConfigurationStore.validation.lookup,
			true
		);
	}, []);
	const handleLookupDefaultValueChange = useCallback(async (value: any) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.selectedLookupDefaultValue, value);
		if (value !== null) {
			fieldConfigurationStore.setValue(FieldStoreEnums.defaultValue, fieldConfigurationStore.selectedLookupDefaultValue!.id);
		} else {
			fieldConfigurationStore.setValue(FieldStoreEnums.defaultValue, value);
		}

	}, []);

	const handleDefaultValueChange = useCallback((value: string) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.defaultValue, value);
	}, []);

	const handleDefaultValueFocusOut = useCallback(() => {
		fieldConfigurationStore.validateDefaultValue();
	}, []);

	const roundingField = fieldTypeIsDecemal && (
		<Field
			name="rounding"
			caption="Количество возможных знаков после запятой"
			required
			promptText='Столько знаков после запятой сможет ввести пользователь'
		>
			<Input
				value={fieldConfigurationStore.specializations.properties["rounding"]}
				placeholder=""
				onChangeValue={(value: string) => {
					handleRoundingChange(value);
				}}
				isInvalid={fieldConfigurationStore.validation.rounding.isInvalid}
				invalidMessage={fieldConfigurationStore.validation.rounding.error}
				onFocusOut={handleRoundingFocusOut}
				inputStyle={InputStyleName.Base}
			/>
		</Field>
	);

	const handleLookupValuesLoad = useCallback(async (value: string | null) => {
		try {
			const items = await fieldConfigurationStore.loadLookups();
			if (items) {
				const newItems: ILookupData[] = [];
				items.filter((lookup: any) => {
					const entityTitle = lowerCase(lookup.entityTitle);
					if (entityTitle.includes(lowerCase(value!)))
						newItems.push(lookup);
				});
				fieldConfigurationStore.setLookups(newItems);
				return newItems.length
			}
			else return -1
		}
		catch (error) {
			console.error(error)
		}
	}, []);

	const lookupField = useMemo(() => {
		if (fieldTypeIsLookup) {
			return (
				<Field
					key="lookup"
					name="lookup"
					caption="Объект"
					required
					promptText='Список всех справочников и разделов в системе'
				>
					<Dropdown
						isInput
						items={fieldConfigurationStore.lookups}
						value={fieldConfigurationStore.selectedLookup}
						onChangeValue={handleLookupChange}
						onItemsLoad={handleLookupValuesLoad}
						isInvalid={fieldConfigurationStore.validation.lookup.isInvalid}
						invalidMessage={fieldConfigurationStore.validation.lookup.error}
						onFocusOut={handleLookupFocusOut}
						selectStyle={SelectStyleName.Base}
					/>
				</Field>
			);
		}
		return null;
	}, [fieldTypeIsLookup, fieldConfigurationStore.selectedLookup, fieldConfigurationStore.lookups]);

	const getItemsCount = useCallback(async (schema: string, filter: IFilter | null) => {
		const allCount = await api.http.httpApi.entity.entityCount().post({
			entityName: schema,
			filter: filter
		});
		if (allCount.data.success) {
			return allCount.data.data;
		}
		else return 0
	}, []);

	const handleDefaultValuesLoad = useCallback(async (value: string | null) => {
		let filter: IFilter | null = null;
		if (value && fieldConfigurationStore.selectedLookup) {
			filter = {
				schema: fieldConfigurationStore.selectedLookup.name,
				isEnabled: true,
				type: 1,
				comparisonType: ComparisonType.Contain,
				attribute: "Name",
				attributeType: FilterColumnType.String,
				rightExpression: {
					type: 0,
					parameter: {
						dataValueType: DataValueType.Text,
						value: value
					}
				},
				filters: [],
				logicalOperation: 0,
				detail: ""
			};
		}
		try {
			if (fieldConfigurationStore.selectedLookup) {
				const items = await fieldConfigurationStore.loadLookupData(fieldConfigurationStore.selectedLookup.name, filter);
				if (items) {
					setDefValues(items);
					return items.length
				}
				else return -1
			}
		}
		catch (error) {
			console.error(error)
		}
	}, [defValues, fieldConfigurationStore.selectedLookup]);

	const defaultValueField = useMemo(() => {
		return fieldTypeIsLookup &&
			fieldConfigurationStore.fieldType !== ColumnType.DateTime &&
			!fieldConfigurationStore.selectedLookup?.isSection &&
			(
				<Field
					name="defaultValue"
					caption="Значение по умолчанию"
					promptText='Значение, которым заполнится поле при создании записи'
				>
					<Dropdown
						items={defValues}
						onChangeValue={handleLookupDefaultValueChange}
						onItemsLoad={handleDefaultValuesLoad}
						isInput
						value={fieldConfigurationStore.selectedLookupDefaultValue}
						selectStyle={SelectStyleName.Base}
					/>
				</Field>
			);
	}, [fieldTypeIsLookup, fieldConfigurationStore.fieldType, fieldConfigurationStore.selectedLookup,
		toJS(defValues), fieldConfigurationStore.selectedLookupDefaultValue]);

	const defaultValueFieldNonLookup = useMemo(() => {
		if (!fieldTypeIsLookup &&
			fieldConfigurationStore.fieldType !== ColumnType.DateTime &&
			fieldConfigurationStore.fieldType !== ColumnType.Date &&
			fieldConfigurationStore.fieldType !== ColumnType.Time
		) {
			return (
				<Field
					name="defaultValue"
					caption="Значение по умолчанию"
					promptText='Значение, которым заполнится поле при создании записи'
				>
					<Input
						value={fieldConfigurationStore.defaultValue}
						placeholder=""
						onChangeValue={(value: string) => {
							handleDefaultValueChange(value);
						}}
						isNeedCorrect
						isInvalid={fieldConfigurationStore.validation.defaultValue.isInvalid}
						invalidMessage={fieldConfigurationStore.validation.defaultValue.error}
						onFocusOut={handleDefaultValueFocusOut}
					/>
				</Field>
			);
		}
		return null;
	}, [fieldTypeIsLookup, fieldConfigurationStore.fieldType, fieldConfigurationStore.defaultValue,
		fieldConfigurationStore.validation.defaultValue.isInvalid]);

	return (
		<>
			{fieldTypeTitleMemo}
			{basicSettingsMemo}
			{roundingField}
			{lookupField}
			{defaultValueField}
			{defaultValueFieldNonLookup}
			<ToggleBlock />
			<Text className={styles.sectionTitle}>ВНЕШНИЙ ВИД</Text>
			<Field
				name="prompt"
				caption="Подсказка"
				promptText='То, что вы читаете прямо сейчас 🙂'
			>
				<Input
					value={fieldConfigurationStore.prompt}
					placeholder=""
					onChangeValue={(value: string) => {
						fieldConfigurationStore.setValue(FieldStoreEnums.prompt, value);
					}}
				/>
			</Field>
		</>
	);
});

const NewLookupConfiguration = observer(() => {
	const [tab, setTab] = useState(NewLookupTabs.LookupTab);
	const [selected, setSelected] = useState<number>(0);
	const [defValues, setDefValues] = useState<ILookup[]>(fieldConfigurationStore.virtualLookup.virtualLookupValues);

	const basicSettingsMemo = useMemo(() => <BasicSettings />, []);

	const handleVirtualLookupDefaultValueChange = useCallback(async (value: any) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.selectedLookupDefaultValue, value);
		if (value !== null) {
			fieldConfigurationStore.setValue(
				FieldStoreEnums.defaultValue,
				fieldConfigurationStore.selectedLookupDefaultValue!.id
			);
		} else {
			fieldConfigurationStore.setValue(FieldStoreEnums.defaultValue, value);
		}
	}, []);

	const handleDefaultValuesLoad = useCallback(async (find: string | null) => {
		if (find) {
			const filtered = fieldConfigurationStore.virtualLookup.virtualLookupValues.filter(value => value.name.includes(find));
			setDefValues(filtered);
			return filtered.length
		}
		else {
			setDefValues(fieldConfigurationStore.virtualLookup.virtualLookupValues);
			return fieldConfigurationStore.virtualLookup.virtualLookupValues.length
		}
	}, [defValues, fieldConfigurationStore.virtualLookup.virtualLookupValues]);

	const defaultVirtualValueField = useMemo(() => {
		return <Field
			name="defaultValue"
			caption="Значение по умолчанию"
			promptText='Значение, которым заполнится поле при создании записи'
		>
			<Dropdown
				items={defValues}
				onChangeValue={handleVirtualLookupDefaultValueChange}
				onItemsLoad={handleDefaultValuesLoad}
				isInput
				value={fieldConfigurationStore.selectedLookupDefaultValue}
				selectStyle={SelectStyleName.Base}
			/>
		</Field>
	}, [toJS(defValues), fieldConfigurationStore.selectedLookupDefaultValue]);

	const removeBadVirtualLookupValues = () => {
		fieldConfigurationStore.virtualLookup.virtualLookupValues = fieldConfigurationStore.virtualLookup.virtualLookupValues.filter(
			(item) => item.name.trim() !== ""
		);
	};

	const handleChangeTab = (tabIndex: NewLookupTabs) => {
		if (tab === NewLookupTabs.LookupTab) {
			if (fieldConfigurationStore.virtualLookup.entityTitle.trim() === ""
				|| fieldConfigurationStore.virtualLookup.systemName.trim() === "") {
				return;
			}
		}
		removeBadVirtualLookupValues();
		setSelected(tabIndex);
		setTab(tabIndex);
	};
	const handleAddVirtualLookupValue = () => {
		if (fieldConfigurationStore.virtualLookup.virtualLookupValues.length > 0) {
			const lastIndex = fieldConfigurationStore.virtualLookup.virtualLookupValues.length - 1;
			if (fieldConfigurationStore.virtualLookup.virtualLookupValues[lastIndex].name.trim() === "") {
				return;
			}
		}
		fieldConfigurationStore.virtualLookup.virtualLookupValues.push({ id: v4(), name: "" });
	};

	const onChangeVirtualLookupValue = (value: string, index: number) => {
		fieldConfigurationStore.virtualLookup.virtualLookupValues[index].name = value;
	};

	const handleTitleChange = useCallback((value: string) => {
		fieldConfigurationStore.virtualLookup.entityTitle = value;
	}, []);

	const handleTitleFocusOut = useCallback(async () => {
		await fieldConfigurationStore.validateNewLookupTitle();
	}, []);

	const handleVirtualLookupSystemNameChange = useCallback((value: string) => {
		fieldConfigurationStore.virtualLookup.systemName = UpFirst(value);
	}, []);

	const handleVirtualLookupSystemNameFocusOut = useCallback(async () => {
		await fieldConfigurationStore.validateNewLookupName();
	}, []);

	const handleRemoveVirtualLookupValue = (index: number) => {
		const valueToRemove = fieldConfigurationStore.virtualLookup.virtualLookupValues[index];

		if (valueToRemove.id === fieldConfigurationStore.defaultValue) {
			return;
		}
		fieldConfigurationStore.virtualLookup.virtualLookupValues.splice(index, 1);
	};


	const itemClasses = useCallback((i: number) => {
		return classNames(`${styles.tabButton} `, {
			[`${styles.selected} `]: selected === i,
		});
	}, [selected]);

	return (
		<>
			<FieldTypeTitle fieldType={fieldConfigurationStore.fieldType} />

			<div className={styles.tabContainer}>
				<Button
					key="newLookupSettingTab"
					caption="Справочник"
					onClick={() => handleChangeTab(NewLookupTabs.LookupTab)}
					className={itemClasses(NewLookupTabs.LookupTab)}
					style={ButtonStyle.DragGrayButton}
				/>
				<Button
					key="settingFieldTab"
					caption="Настройки поля"
					onClick={() => handleChangeTab(NewLookupTabs.FieldSettingsTab)}
					className={itemClasses(NewLookupTabs.FieldSettingsTab)}
					style={ButtonStyle.DragGrayButton}
				/>
			</div>
			{tab == NewLookupTabs.LookupTab ? (
				<>
					<Text className={styles.sectionTitle}>ОСНОВНОЕ</Text>
					<Field
						name="title"
						caption="Название справочника"
						required
						promptText='Название нового справочника'
					>
						<Input
							value={fieldConfigurationStore.virtualLookup.entityTitle}
							placeholder=""
							onChangeValue={handleTitleChange}
							isNeedCorrect
							isInvalid={fieldConfigurationStore.validation.virtualLookupTitle.isInvalid}
							isNotUnique={fieldConfigurationStore.validation.virtualLookupTitle.isNotUnique}
							invalidMessage={fieldConfigurationStore.validation.virtualLookupTitle.error}
							onFocusOut={handleTitleFocusOut}
						/>
					</Field>

					<Field
						name="systemName"
						caption="Системное название"
						required
						promptText='Название, которое будет отображаться в базе данных'
					>
						<Input
							value={fieldConfigurationStore.virtualLookup.systemName}
							placeholder=""
							onChangeValue={handleVirtualLookupSystemNameChange}
							isNeedCorrect
							isInvalid={fieldConfigurationStore.validation.virtualLookupSystemName.isInvalid}
							invalidMessage={fieldConfigurationStore.validation.virtualLookupSystemName.error}
							onFocusOut={handleVirtualLookupSystemNameFocusOut}
						/>
					</Field>

					<Text className={styles.sectionTitle}>ЗНАЧЕНИЯ СПРАВОЧНИКА</Text>
					<ValidationPrompt text="Добавятся только текстовые значения справочника" />
					{fieldConfigurationStore.virtualLookup.virtualLookupValues.length === 0 ? (
						<Text className={styles.defaultTipText}>Нажмите «Добавить значение», чтобы наполнить справочник</Text>
					) : (
						<>
							{fieldConfigurationStore.virtualLookup.virtualLookupValues.map((item, index) => (
								<div className={styles.item} key={index}>
									<DragIcon />
									<Input
										value={item.name}
										placeholder=""
										onChangeValue={(value: string) => {
											onChangeVirtualLookupValue(value, index);
										}}
										className={styles.virtualLookupInput}
										isNeedCorrect
										invalidMessage="Заполните поле"
									/>
									<Button
										onClick={(e) => {
											handleRemoveVirtualLookupValue(index);
										}}
										firstIcon={<Cross />}
										style={ButtonStyle.Icon}
										className={styles.clearButton}
									/>
								</div>

							))}
						</>
					)}
					<Button
						key="addLookupValue"
						className={styles.addLookupValueButton}
						caption="Добавить значение"
						onClick={handleAddVirtualLookupValue}
						style={ButtonStyle.LightBlue} />
				</>
			) :
				(<>
					{basicSettingsMemo}
					{defaultVirtualValueField}
					<ToggleBlock />
					<Text className={styles.sectionTitle}>ВНЕШНИЙ ВИД</Text>
					<Field
						name="prompt"
						caption="Подсказка"
						promptText='То, что вы читаете прямо сейчас 🙂'
					>
						<Input
							value={fieldConfigurationStore.prompt}
							placeholder=""
							onChangeValue={(value: string) => {
								fieldConfigurationStore.setValue(FieldStoreEnums.prompt, value);
							}}
						/>
					</Field>
				</>)
			}

		</>
	);
});

const BasicSettings = observer(() => {

	const handleTitleChange = useCallback((value: string) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.title, value);
	}, []);

	const handleTitleFocusOut = useCallback(() => {
		fieldConfigurationStore.validateTitle();
	}, []);

	const handleSystemNameChange = useCallback((value: string) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.systemName, value);
	}, []);

	const handleSystemNameFocusOut = useCallback(() => {
		fieldConfigurationStore.validateSystemName();
	}, []);

	return (
		<>
			<Text className={styles.sectionTitle}>ОСНОВНОЕ</Text>
			<Field
				name="title"
				caption="Заголовок"
				required
				promptText='Название, которое будет отображаться на странице записи'
			>
				<Input
					value={fieldConfigurationStore.title}
					placeholder=""
					onChangeValue={handleTitleChange}
					isNeedCorrect
					isInvalid={fieldConfigurationStore.validation.title.isInvalid}
					isNotUnique={fieldConfigurationStore.validation.title.isNotUnique}
					invalidMessage={fieldConfigurationStore.validation.title.error}
					onFocusOut={handleTitleFocusOut}
				/>
			</Field>
			<Field
				name="systemName"
				caption="Системное название"
				required
				promptText='Название, которое будет отображаться в базе данных'
			>
				<Input
					value={fieldConfigurationStore.systemName}
					placeholder=""
					onChangeValue={(value: string) => {
						handleSystemNameChange(UpFirst(value));
					}}
					isNeedCorrect
					isInvalid={fieldConfigurationStore.validation.systemName.isInvalid}
					invalidMessage={fieldConfigurationStore.validation.systemName.error}
					onFocusOut={handleSystemNameFocusOut}
				/>
			</Field>
		</>
	);
});

const ToggleBlock = observer(() => {
	const renderToggleForDateTime = useCallback(() => {
		if (
			fieldConfigurationStore.fieldType === ColumnType.DateTime ||
			fieldConfigurationStore.fieldType === ColumnType.Date ||
			fieldConfigurationStore.fieldType === ColumnType.Time
		) {
			return (
				<>
					<div className={styles.toggleField}>
						<div className={styles.toggleFieldLayout}>
							<Text className={styles.defaultText}>
								{fieldConfigurationStore.fieldType === ColumnType.Date
									? "Заполнять текущей датой по умолчанию"
									: fieldConfigurationStore.fieldType === ColumnType.Time
										? "Заполнять текущим временем по умолчанию"
										: "Заполнять текущей датой и временем по умолчанию"
								}
							</Text>
							<Toggle
								checked={fieldConfigurationStore.isSetDefaultData || fieldConfigurationStore.defaultValue.length > 0}
								onCheck={(val) => {
									if (val) {
										const currentDate = new Date();
										let currentDateString = "";
										if (fieldConfigurationStore.fieldType === ColumnType.Date) {
											currentDateString = currentDate.toISOString().split("T")[0];
										} else if (fieldConfigurationStore.fieldType === ColumnType.Time) {
											currentDateString = currentDate.toISOString().split("T")[1];
										} else {
											currentDateString = currentDate.toISOString();
										}
										fieldConfigurationStore.setValue(FieldStoreEnums.defaultValue, currentDateString);
									}
									else {
										fieldConfigurationStore.setValue(FieldStoreEnums.defaultValue, "");
									}
									fieldConfigurationStore.setValue(FieldStoreEnums.isSetDefaultData, val);
								}}
							/>
						</div>
						<Text className={styles.defaultTipText}>
							{fieldConfigurationStore.fieldType === ColumnType.Date
								? "Поле будет заполнено датой создания записи"
								: fieldConfigurationStore.fieldType === ColumnType.Time
									? "Поле будет заполнено временем на момент создания записи"
									: "Поле будет заполнено датой и временем на момент создания записи"
							}
						</Text>
						<div className={styles.divider} />
					</div>
				</>
			);
		}
		return null;
	}, [fieldConfigurationStore.fieldType]);

	const renderToggleForNonLogicalTypes = () => {
		const renderIndexToggle = () => {
			return (
				<div className={styles.toggleField}>
					<div className={styles.toggleFieldLayout}>
						<Text className={styles.defaultText}>Поле индексируемое</Text>
						<Toggle
							checked={fieldConfigurationStore.hasIndex}
							onCheck={(val) => fieldConfigurationStore.setValue(FieldStoreEnums.hasIndex, val)}
						/>
					</div>
					<Text className={styles.defaultTipText}>
						Включите, чтобы поиск по этому полю выполнялся быстрее
					</Text>
				</div>
			);
		};

		if (fieldConfigurationStore.fieldType !== ColumnType.Boolean) {
			return (
				<>
					<div className={styles.toggleField}>
						<div className={styles.toggleFieldLayout}>
							<Text className={styles.defaultText}>Поле обязательное</Text>
							<Toggle
								checked={fieldConfigurationStore.isRequired}
								onCheck={(val) => fieldConfigurationStore.setValue(FieldStoreEnums.isRequired, val)}
							/>
						</div>
						<Text className={styles.defaultTipText}>
							Перед сохранением записи необходимо заполнить это поле
						</Text>
						<div className={styles.divider} />
					</div>
					{renderIndexToggle()}
				</>
			);
		}
		return renderIndexToggle();
	};

	return (
		<>
			{renderToggleForDateTime()}
			{renderToggleForNonLogicalTypes()}
		</>
	);
});

export const FieldTypeTitle = (props: { fieldType: ColumnType }) => {

	const getFieldTypeName = useCallback((fieldType: ColumnType): string => {
		switch (fieldType) {
			case ColumnType.String:
				return "Текст";
			case ColumnType.Date:
				return "Дата";
			case ColumnType.Time:
				return "Время";
			case ColumnType.DateTime:
				return "Дата и время";
			case ColumnType.Boolean:
				return "Логическое";
			case ColumnType.Integer:
				return "Целое число";
			case ColumnType.Decimal:
				return "Дробное число";
			case ColumnType.Lookup:
				return "Справочное";
			case ColumnType.Time:
				return "Время";
			case ColumnType.Date:
				return "Дата";
			default:
				return "Неизвестный тип";
		}
	}, []);

	return (
		<div className={styles.fieldTypeTitleContainer}>
			<TypeIcon type={props.fieldType} />
			<Text className={styles.fieldTypeTitle}>
				Тип поля [{getFieldTypeName(props.fieldType)}]
			</Text>
		</div>
	);
};
