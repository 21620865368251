import { observer } from "mobx-react";

import { store } from "store";
import ImportStore from "entities/import/ImportStore";
import { uploadFileHelper } from "../core/upload-file-helper";

import { NoDocuments } from "assets/icons";
import { AttachFileBlue } from "assets/icons";

import buttonStyles from 'components/button/button.module.css';
import styles from "./upload.module.css";

const Upload = observer((props: { importStore: ImportStore }) => {

    function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        uploadFileHelper(store.currentRowId, e.target.files, props.importStore)
    }

    return (
        <div className={styles.upload}>
            <NoDocuments />
            <span className={styles.title}>Файлов и документов нет</span>
            <span className={styles.tip}>Прикрепите файл или перетащите его сюда</span>
            <label className={`${buttonStyles.whiteLink} ${styles.buttonFile}`}>
                <input
                    className={styles.inputFile}
                    type="file" multiple
                    onChange={onChangeHandler}
                />
                <div className={styles.buttonText} >
                    <AttachFileBlue style={{ marginRight: "5px" }} /> Прикрепить файл
                </div>
            </label>
        </div>
    )
})
export default Upload;