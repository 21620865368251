import styles from "./SkeletonAccountFullView.module.css";

function SkeletonAccountFullView() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.itemShort}></div>
                <div className={styles.headerCentral}>
                    <div className={styles.item}></div>
                    <div className={styles.itemLong}></div>
                </div>
                <div className={styles.itemShort}></div>
            </div>
            <div className={styles.body}>
                <div className={styles.bodyLeft}>
                    <div className={styles.headerLeft}>
                        <div className={styles.itemShort}></div>
                        <div className={styles.item}></div>
                    </div>
                    <div className={styles.mainLeft}>

                        <div className={styles.mainBodyHalf}>
                            <div className={styles.halfLeft}>
                                <div className={styles.itemShort}></div>
                                <div className={styles.itemShort}></div>
                                <div className={styles.itemShort}></div>
                                <div className={styles.itemShort}></div>
                            </div>
                            <div className={styles.halfLeft}>
                                <div className={styles.itemVeryLong}></div>
                                <div className={styles.itemLong}></div>
                                <div className={styles.itemLong}></div>
                                <div className={styles.itemVeryLong}></div>
                            </div>
                        </div>

                        <div className={styles.mainBodyHalf}>
                            <div className={styles.halfLeft}>
                                <div className={styles.itemShort}></div>
                                <div className={styles.itemShort}></div>
                                <div className={styles.itemShort}></div>
                            </div>
                            <div className={styles.halfLeft}>
                                <div className={styles.itemLong}></div>
                                <div className={styles.itemVeryLong}></div>
                                <div className={styles.itemLong}></div>
                            </div>
                        </div>

                    </div>
                    <div className={styles.footerLeft}>
                        <div className={styles.round}></div>
                        <div className={styles.itemShort}></div>
                    </div>
                    <div className={styles.footerLeft}>
                        <div className={styles.round}></div>
                        <div className={styles.itemShort}></div>
                    </div>
                    <div className={styles.footerLeft}>
                        <div className={styles.round}></div>
                        <div className={styles.itemShort}></div>
                    </div>
                </div>

                <div className={styles.bodyRight}>
                    <div className={styles.headerLeft}>
                        <div className={styles.itemShort}></div>
                        <div className={styles.item}></div>
                        <div className={styles.itemShort}></div>
                    </div>
                    <div className={styles.mainRight}>

                        <div className={styles.mainBodyRight}>
                            <div className={styles.avatar}></div>
                            <div className={styles.halfLeft}>
                                <div className={styles.item}></div>
                                <div className={styles.itemShort}></div>
                                <div className={styles.itemLong}></div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default SkeletonAccountFullView;