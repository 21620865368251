import PanelList from "components/panel-list/panel-list";

import { GlobalSearchCountSkelet } from "assets/icons";
import { GlobalSearchSkelet1 } from "assets/icons";
import { GlobalSearchSkelet2 } from "assets/icons";
import { GlobalSearchSkelet3 } from "assets/icons";

import styles from "./SearchCardSkeleton.module.css";

interface ISearchCardSkeletonProps {
    children: any;
    isLoading: boolean;
}

function SearchCardSkeleton(props: ISearchCardSkeletonProps) {
    if (props.isLoading) return props.children;

    return (
        <PanelList className={styles.panelResult}>
            <GlobalSearchCountSkelet />
            <div className={styles.dividerResult} />
            <div className={styles.result}>
                <GlobalSearchSkelet1 />
                <GlobalSearchSkelet2 />
                <GlobalSearchSkelet3 />
            </div>
        </PanelList>
    )
}

export default SearchCardSkeleton;