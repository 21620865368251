import * as signalR from "@microsoft/signalr";

import { SignalRManager } from "./";
import { getSignalRUrl } from "../../app/services/UrlService";

class SignalRService {
	connection: signalR.HubConnection | undefined = undefined;
	subscribeMethod: string = "";
	fullViewId: string = "";
	key: string = "";

	constructor(subscribeMethod: string, fullViewId: string) {

		this.connection = SignalRManager.getInstance().getConnection(this.key);
		if (!this.connection) {
			let url = getSignalRUrl();
			this.connection = new signalR.HubConnectionBuilder()
				.withUrl(`${url}/entityEventsHub`)
				.withAutomaticReconnect()
				.configureLogging(signalR.LogLevel.Information)
				.build();
			this.key = `${subscribeMethod}_${fullViewId}`;
		}
		this.subscribeMethod = subscribeMethod;
		this.fullViewId = fullViewId;
	}

	startConnection() {
		if (SignalRManager.getInstance().hasConnection(this.key)) {
			console.info(`Connection by key ${this.key} exist`);
			return;
		}
		if (this.connection && this.fullViewId.length !== 0) {
			this.connection
				.start()
				.then(() => {
                    this.connection!.invoke(this.subscribeMethod, this.fullViewId);
                    console.info("SignalR connection established");
                    SignalRManager.getInstance().addConnection(this.key, this.connection!);
				})
				.catch((error) => {
					console.error("Error starting SignalR connection:", error);
				});
		}
	}

	stopConnection() {
		if (this.connection && this.connection.state === signalR.HubConnectionState.Connected) {
			this.connection
				.stop()
				.then(() => {
					console.info("SignalR connection stopped");
					SignalRManager.getInstance().removeConnection(this.key);
				})
				.catch((error) => {
					console.error("Error stopping SignalR connection:", error);
				});
		}
	}

	addEventListener(event: string, handler: (...args: any[]) => void) {
		if (this.connection) {
			this.connection.on(event, handler);
		}
	}

	dispose() {
		this.stopConnection();
		this.connection = undefined;
	}
}

export default SignalRService;