import { observer } from "mobx-react-lite";
import { observable } from "mobx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";


import CurrentLookupPanel from "./CurrentLookupPanel";
import {  DataGrid, ITab, Button, ButtonStyle, InputSearch, Dialog, WarningDialog, Select, Input, SearchSelect, ValidationPrompt } from "components";
import DisplayTabs from "features/display-tabs/display-tabs";
import NavigationTitle from "../NavigationTitle";

import { LookupInfo } from "entities/lookupInfo/LookupInfo";
import Entity from "entities/Entity";
import { default as lookupEntity, Lookup } from "entities/lookup/Lookup";
import Field from "components/field/field";
import { FieldValidationState, validateRequired, validateSchema } from "entities/Validation";
import lookupMainPanelStore from "./LookupMainPanelStore";
import {UpFirst} from "entities/lowFirst";
import {
    actionEnums, fieldTypes, ILookupData, LookupColumns, lookupTitle,
    MainLookupsActions, systemNameTitle, SystemSettings, titleTitle, type, TypesEnums, typeTitle
} from "../data/Fields";
import { Item } from "types";

import { ArrowToDownMini, GoBack, LookupInfoIcon, CrossInRoundMini } from "assets/icons";

import styles from "./Lookups.module.css";


const StateForFirstField = observable<FieldValidationState>({
    error: "", isInvalid: false
})

const LookupMainPanel = observer(function () {
    let [searchValue, setSearchValue] = useState("");
    const [tab, setTab] = useState<JSX.Element>(<></>);
    const navigate = useNavigate();
    const location = useLocation();

    let columnTitles: any[];

    const tabs = [
        {
            action: () => {
                // setTab();
            },
            caption: "системные"

        },
        {
            action: () => {
                // setTab()
            },
            caption: "пользовательские"
        },
    ];


    useEffect(() => {
        navigateTo(location.pathname);
    }, [location.pathname])


    function navigateTo(path: string) {
        let subStr = path.split("/");
        subStr.shift();
        if (subStr.length === 4) {
            if (columnTitles) {
                navigate(path);
                setTab(
                    <CurrentLookupPanel columns={columnTitles} listStore={lookupMainPanelStore.lookupStore!} />
                );
            }
        }
        else {
            lookupMainPanelStore.loadLookups();
            lookupMainPanelStore.setValue("", "nameLookup");
            lookupMainPanelStore.setValue(1, "step");
        }
    }

    function search(s: string) {
        setSearchValue(s);
    }


    function onChangeActions(value: Item | null) {
        if (value?.id === actionEnums.ChangeView) {
            //изменить отображение
        }
        if (value?.id === actionEnums.Export) {
            //экспорт
        }
    }

    function onClick(row: ILookupData) {
        columnTitles = row.entityInfo;
        lookupMainPanelStore.setLookupStore(row.entityName);
        lookupMainPanelStore.setValue(row.entityTitle, "nameLookup");
        navigateTo(location.pathname + '/' + row.id);
    }

    async function loadLookupsWithSort(sort: any) {
        lookupMainPanelStore.loadLookups(sort);
    }

    function getFieldsToSave() {
        let newList: any = [];
        newList.push(lookupMainPanelStore.firstField);
        lookupMainPanelStore.fields.map((field) => {
            let index = lookupMainPanelStore.fields.indexOf(field);
            if (field.lookupTable) {
                lookupMainPanelStore.changeValueInFields(true, "isLookup", index);
                lookupMainPanelStore.changeValueInFields(field.lookupTable.entityName, "lookupTable", index);
            }
            lookupMainPanelStore.changeValueInFields(true, "isRequired", index);
            newList.push(field);
        })
        return newList;
    }
    async function saveNewSchema() {
        if (lookupMainPanelStore.validateFields()) {
            let fields = getFieldsToSave();
            if (!lookupEntity.isSaving) {
                let saved = await lookupEntity.createNewSchema(fields);
                if (saved) {
                    await lookupMainPanelStore.loadLookups();
                    let newLookup = lookupMainPanelStore.lookups.filter((lookup) => lookup.entityName === lookupEntity.entity.schema);
                    columnTitles = newLookup[0].entityInfo as LookupInfo[];
                    lookupMainPanelStore.setLookupStore(newLookup[0].entityName);
                    lookupMainPanelStore.setValue(newLookup[0].entityTitle, "nameLookup");
                    navigateTo(location.pathname + '/' + newLookup[0].id);
                }
            }
        }

    }

    return (
        <div className={styles.systemMainPanel}>
            {lookupMainPanelStore.nameLookup === "" ?
                <>
                    <NavigationTitle />
                    <div className={styles.mainTitle}>
                        {SystemSettings[0].name}
                    </div>
                    <ButtonsTitle searchValue={searchValue} search={search}
                        onChangeActions={onChangeActions} saveNewSchema={saveNewSchema} tabs={tabs} />
                    <div className={styles.grid}>
                        <DataGrid columns={LookupColumns} otherData={lookupMainPanelStore.lookups} onClick={onClick} isCheckBox={false} loadLookup={loadLookupsWithSort} />
                    </div>
                </>
                :
                <>{tab}</>
            }


        </div>

    );
});

const ButtonsTitle = observer(function (props: {
    searchValue: string,
    search: (s: string) => void,
    onChangeActions: (value: Item | null) => void,
    saveNewSchema: () => void,
    tabs: ITab[]
}) {
    let [searchValue, setSearchValue] = useState(props.searchValue);

    const styleDialog = useMemo(
        () => { return lookupMainPanelStore.step === 2 ? { height: `700px` } : {} },
        [lookupMainPanelStore.step]
    );

    const onClickNew = useCallback(() => {
        lookupMainPanelStore.setValue([], "fields");
        lookupMainPanelStore.setValue(1, "step");
        lookupEntity.new(new Lookup());
        lookupMainPanelStore.fromLookupsToIItem(lookupMainPanelStore.lookups);
    }, [])

    return (
        <div className={styles.buttonsTitle}>
            <div className={styles.searchingTitle}>
                <Button
                    caption={'Создать справочник'}
                    onClick={onClickNew}
                    style={ButtonStyle.Primary}
                    className={styles.addLookupButton}
                />
                <InputSearch placeholder="Поиск" value={searchValue} onChangeValue={props.search} classNameInput={styles.searchLookupGrid} />
                <Dialog title="Новый справочник" isOpen={lookupEntity.openedDialog} style={styleDialog}
                    dialogFooterButtons={<DialogFooterButtons saveNewSchema={props.saveNewSchema} />}
                    onClick={lookupEntity.cancelClick}
                >
                    {lookupMainPanelStore.step === 1 ?
                        <FirstLookupDialogFilling entity={lookupEntity} />
                        :
                        <SecondLookupDialogFilling />
                    }
                </Dialog>
                <WarningDialog isOpen={lookupEntity.openedWarningDialog} onBackClick={lookupEntity.setWarningDialog} onCancelClick={lookupEntity.cancelChanges} />
            </div>

            <div className={styles.actionsButton}>
                <DisplayTabs items={props.tabs} styleButton={ButtonStyle.Icon} />
                <Select
                    name={'Действия'}
                    items={MainLookupsActions}
                    onChangeValue={props.onChangeActions}
                    secondIcon={<ArrowToDownMini />}
                    isRotateSecondIcon={true}
                    styles={ButtonStyle.Link}
                    classNameButton={styles.actionButton}
                />

            </div>

        </div>
    );

});

const DialogFooterButtons = observer((props: { saveNewSchema: () => void }) => {

    const isDisabledSaveButton = useMemo(() => {
        return lookupMainPanelStore.disabledSaveButton()
    }, [lookupMainPanelStore.validate])

    const isDisabledNextButton = useMemo(() => {
        let state: FieldValidationState = { isInvalid: false, error: '' }
        return !(lookupEntity.entity.displaySchema && lookupEntity.entity.schema && validateSchema(lookupEntity.entity.schema, state))
    }, [lookupEntity.entity.schema, lookupEntity.entity.displaySchema])

    const onClickBack = useCallback(() => {
        lookupMainPanelStore.setValue(1, "step")
    }, [])

    const onClickNext = useCallback(async () => {
        if (!await lookupMainPanelStore.checkExistEntityName(lookupEntity.entity.schema)) {
            lookupMainPanelStore.setValue(2, "step")
        }
        else {
            lookupEntity.entity.handleError("name_is_already exist");
        }
    }, [])

    return (
        <>
            <Button caption={'Отмена'} onClick={lookupEntity.cancelClick} style={ButtonStyle.Subtle} />
            {lookupMainPanelStore.step === 1 ?
                <div className={styles.actionsTool} >
                    {(lookupEntity.entity.displaySchema === "" || lookupEntity.entity.schema === "" || lookupEntity.entity.validation.schema.isInvalid) &&
                        <span className={styles.tooltip}>
                            <span> Заполните поля </span>
                        </span>
                    }
                    <Button caption={'Продолжить'}
                        isDisabled={isDisabledNextButton}
                        onClick={onClickNext}
                        style={ButtonStyle.Primary}
                    />
                </div>
                :
                <div className={styles.actionsTool} >
                    {isDisabledSaveButton &&
                        <span className={styles.tooltip}>
                            <span> Заполните поля </span>
                        </span>
                    }
                    <Button caption={'Сохранить'} onClick={props.saveNewSchema}
                        style={ButtonStyle.Primary}
                        isDisabled={isDisabledSaveButton}
                    />
                </div>

            }
            {lookupMainPanelStore.step === 2 &&
                <Button className={styles.backButton} firstIcon={<GoBack />} caption={'Назад'} onClick={onClickBack} style={ButtonStyle.Link} />
            }
        </>
    );
});
export const FirstLookupDialogFilling = observer((props: { entity: Entity<Lookup> }) => {
    let entity = props.entity;

    const onChangeDisplaySchema = useCallback(
        (value: string) => {
            entity.entity.setValue(UpFirst(value), "displaySchema");
            validateRequired(value, entity.entity.validation.displaySchema);
        }, [entity.entity]);
    const onChangeSchema = useCallback(
        (value: string) => {
            entity.entity.setValue(UpFirst(value), "schema");
            validateSchema(value, entity.entity.validation.schema, lookupMainPanelStore.fields);
        }, [entity.entity])

    return (
        <>
            <div className={styles.stepTitle}>
                <span className={styles.countStep}>
                    Шаг {lookupMainPanelStore.step} / 2
                </span>
                <span className={styles.infoStep}>
                    Заполните поля
                </span>
            </div>
            <Field name={"displaySchema"} caption={titleTitle} required={true}>
                <Input
                    value={entity.entity.displaySchema}
                    placeholder={""}
                    onChangeValue={onChangeDisplaySchema}
                    isNeedCorrect={true}
                    isInvalid={entity.entity.validation.displaySchema.isInvalid}
                    invalidMessage={entity.entity.validation.displaySchema.error}
                    onFocusOut={() => validateRequired(entity.entity.displaySchema, entity.entity.validation.displaySchema)}
                />
            </Field>
            <Field name={"schema"} caption={systemNameTitle} required={true}>
                <Input
                    value={entity.entity.schema}
                    placeholder={""}
                    onChangeValue={onChangeSchema}
                    isNeedCorrect={true}
                    isInvalid={entity.entity.validation.schema.isInvalid}
                    invalidMessage={entity.entity.validation.schema.error}
                    onFocusOut={() => validateSchema(entity.entity.schema, entity.entity.validation.schema, lookupMainPanelStore.fields)}
                />
            </Field>
            <ValidationPrompt 
                header={"Обратите внимание"}
                example={"Пример: «Contact-Address_02»"}
            />
        </>
    );
});

export const SecondLookupDialogFilling = observer(() => {

    const addItem = useCallback(() => { lookupMainPanelStore.addItem() }, [])
    const deleteField = useCallback(
        (field: any) => {
            lookupMainPanelStore.deleteItem(field);
        }, [])

    return (
        <>
            <div className={styles.stepTitle}>
                <span className={styles.countStep}>
                    Шаг {lookupMainPanelStore.step} / 2
                </span>
                <span className={styles.infoStep}>
                    Добавьте колонки
                </span>
            </div>
            <div className={styles.body}>
                <FirstNewField />
                <FieldsFilling deleteField={deleteField} />
                <Button
                    caption={"Добавить колонку"}
                    className={styles.addFieldButton}
                    onClick={addItem}
                    style={ButtonStyle.LightBlue}
                />
            </div>
            <div className={styles.separator}></div>
        </>
    );
});

export const FieldsFilling = observer((props: { deleteField: (elem: any) => void }) => {
    return (
        <>
            {lookupMainPanelStore.fields.map((elem) => {
                return (
                    <DefaultNewField field={elem} deleteField={props.deleteField} />
                )
            })}
        </>
    );
});
const VerticalDivider = observer(function () {
    return (
        <div className={styles.divider} />
    );
});
export const FirstNewField = observer(() => {
    let state = StateForFirstField;
    let field = lookupMainPanelStore.firstField;

    return (
        <>
            <span className={styles.columnHeader} >
                Колонка [{field.columnTitle}] [({field.columnName})]
            </span>

            <div className={styles.bodyField}>
                <VerticalDivider />
                <div className={styles.fillingFields} >
                    <Field name={"valueType"} caption={typeTitle} required={false} >
                        <Input
                            value={type}
                            placeholder={""}
                            onChangeValue={() => { }}
                            style={{
                                background: `var(--color-gray-100)`,
                            }}
                            isDisabled={true}
                        />
                    </Field>

                    <div className={styles.secondLine}>
                        <Field name={"columnTitle"} caption={titleTitle} required={true} className={styles.input}>
                            <Input
                                value={field.columnTitle}
                                placeholder={""}
                                onChangeValue={(value: string) => {
                                    lookupMainPanelStore.changeValueInFirstField(UpFirst(value));
                                }}
                                isInvalid={state.isInvalid}
                                invalidMessage={state.error}
                                onFocusOut={() => validateRequired(field.columnTitle, state)}
                            />
                        </Field>
                        <Field name={"systemName"} caption={systemNameTitle} required={false} className={styles.input}>
                            <Input
                                value={field.columnName}
                                placeholder={""}
                                onChangeValue={() => { }}
                                style={{
                                    background: `var(--color-gray-100)`,
                                }}
                                isDisabled={true}
                            />
                        </Field>
                    </div>
                </div>
            </div>

        </>
    );
});


export const DefaultNewField = observer((props: { field: LookupInfo, deleteField: (elem: any) => void }) => {
    let field = props.field
    let [choosingType, setChoosingType] = useState<Item>();
    let [choosingLookup, setChoosingLookup] = useState<Item>();

    useEffect(() => {
        loadingStates();
    }, [])

    async function loadingStates() {
        fieldTypes.map((elem) => {
            if (elem.entityName === field.columnType) {
                setChoosingType(elem);
            }
        })
        lookupMainPanelStore.lookups.map((elem) => {
            if (elem.entityName === field.lookupTable?.entityName) {
                let look: Item = {
                    id: elem.id,
                    name: elem.entityTitle,
                    entityName: elem.entityName
                };
                setChoosingLookup(look);
            }
        })
        var index = lookupMainPanelStore.fields.indexOf(field);
        if (choosingType?.id === TypesEnums.Lookup) {
            lookupMainPanelStore.changeValueInFields(true, "isLookup", index);
        }
        else lookupMainPanelStore.changeValueInFields(false, "isLookup", index);
        field.resetValidate();
    }


    async function onChangeValue(value: any, fieldName: string) {
        let index = lookupMainPanelStore.fields.indexOf(field);
        lookupMainPanelStore.changeValueInFields(value, fieldName, index);
        // lookupMainPanelStore.validateFields();
    }
    async function onChangeLookupTable(value: any) {
        setChoosingLookup(value);
        onChangeValue(value.entityName, "columnName");
        // field.resetValidate();
        let index = lookupMainPanelStore.fields.indexOf(field);
        lookupMainPanelStore.changeValueInFields(value, "lookupTable", index);
        // lookupMainPanelStore.validateFields()
        lookupMainPanelStore.deleteUsingLookupFromList();
    }

    const onChangeType = useCallback(
        (value: any) => {
            onChangeValue(value !== null ? value.entityName : "", "columnType")
            setChoosingType(value);
        }, [])
    const onChangeLookup = useCallback(
        (value: any) => {
            onChangeLookupTable(value);
        }, [])
    const onChangeTitle = useCallback(
        (value: any) => {
            onChangeValue(UpFirst(value), "columnTitle")
        }, [])
    const onChangeColumnName = useCallback(
        (value: any) => {
            onChangeValue(UpFirst(value), "columnName")
        }, [])

    return (
        <>
            <div className={styles.headField}>
                {(field.columnTitle === "" || field.columnName === "") ?
                    <span className={styles.columnHeader} >
                        Новая колонка
                    </span>
                    :
                    <span className={styles.columnHeader} >
                        Колонка [{field.columnTitle}] [({field.columnName})]
                    </span>
                }
                <div className={styles.deleteButtonContainer}>
                    <Button firstIcon={<CrossInRoundMini />} caption={"Удалить"} className={styles.deleteButton} onClick={() => { props.deleteField(field) }} />
                </div>

            </div>
            <div className={styles.bodyField}>
                <VerticalDivider />
                <div className={styles.fillingFields}>
                    <Field name={"type"} caption={typeTitle} required={true}>
                        <SearchSelect
                            items={fieldTypes as Item[]}
                            value={choosingType}
                            onChangeValue={onChangeType}
                            isInvalid={field.validation.columnType.isInvalid}
                            invalidMessage={field.validation.columnType.error}
                            onFocusOut={() => validateRequired(field.columnType, field.validation.columnType, true)}
                            notTranslate={true}
                            isInput={true}
                        />

                    </Field>
                    {choosingType?.id === TypesEnums.Lookup && //выбрать справочник
                        <Field name={"lookup"} caption={lookupTitle} required={true}>
                            <SearchSelect
                                isInput={true}
                                items={lookupMainPanelStore.dialogFillingLookups as Item[]}
                                value={choosingLookup}
                                onChangeValue={onChangeLookup}
                                isInvalid={field.validation.lookupTable.isInvalid}
                                invalidMessage={field.validation.lookupTable.error}
                                onFocusOut={() => validateRequired(field.lookupTable, field.validation.lookupTable, true)}
                                notTranslate={true}
                            />
                        </Field>
                    }

                    <div className={styles.secondLine}>
                        <Field name={"columnTitle"} caption={titleTitle} required={true} className={styles.input}>
                            <Input
                                value={field.columnTitle}
                                placeholder={""}
                                onChangeValue={onChangeTitle}
                                isInvalid={field.validation.columnTitle.isInvalid}
                                invalidMessage={field.validation.columnTitle.error}
                                onFocusOut={() => validateRequired(field.columnTitle, field.validation.columnTitle)}
                            />
                        </Field>
                        <Field name={"columnName"} caption={systemNameTitle} required={true} className={styles.input}>
                            <Input
                                value={field.columnName}
                                placeholder={""}
                                onChangeValue={onChangeColumnName}
                                isInvalid={field.validation.columnName.isInvalid}
                                invalidMessage={field.validation.columnName.error}
                                onFocusOut={() => validateSchema(field.columnName, field.validation.columnName, lookupMainPanelStore.fields)}
                            />
                        </Field>
                    </div>
                </div>
            </div>

        </>
    );
});
export default LookupMainPanel;
