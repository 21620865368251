import Api from "api/http/api";

class Import extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/Import";
	}

	startImport = () => {
		this.setPath(`${this.basicPath}/start`);
		return this;
	};
	importInfo = () => {
		this.setPath(`${this.basicPath}/info`);
		return this;
	};
	getImportSysSettings = () => {
		this.setPath(`${this.basicPath}/getImportSysSettings`);
		return this;
	};
	importExample = (entityName:string) => {
		this.setPath(`${this.basicPath}/example?entityName=${entityName}`);
		return this;
	};
}

const importFile = new Import();

export { importFile };