import { action, makeAutoObservable, toJS } from "mobx";

import { Types } from "../../type";

type Element = {
    layout: JSX.Element;
    id: string;
	sourceData?: {[key: string]: any};
    position: {
        x: number;
        y: number;
    };
	size: {
		width: number;
		height: number;
	}
}

type Zone = {
    size: {
        startX: number;
        startY: number;
        endX: number;
        endY: number;
    };
    config: {
        type: Types;
        height?: number;
        width?: number;
        cellSize?: {
            width: number;
            height: number;
        }
    };
    elements: {
        [key: string]: Element;
    };
}

type Id = string;

class Store {
	_zones: {
        [key: Id]: Zone;
    };

	_targetCell: {cellX: number, cellY: number} | null;

	_targetZoneId: string | null;

	_selectedElementId: string | null;

	_elemCenter: { x: number, y: number } | null;

	_hasMove: boolean;

	_predictLayout: JSX.Element | null;

	_maskCells: Array<{
		x: number;
		y: number;
		id: Id | null;
		matrixWidth: number;
		matrixHeight: number;
		zoneId: Id;
		startX: number;
		startY: number;
		endX: number;
		endY: number;
	}>;

	constructor() {
		this._zones = {};
		this._targetCell = null;
		this._targetZoneId = null;
		this._selectedElementId = null;
		this._elemCenter = null;
		this._hasMove = false;
		this._predictLayout = null;
		this._maskCells = [];

		makeAutoObservable(this);
	}

	get zones(): { [key: Id]: Zone } {
		return this._zones;
	}

	deleteZone = action("delete zone", (zoneId: string) => {
		delete this._zones[zoneId];
	});

	setPredictLayout = action("set predict layout", (newPredictLayout: JSX.Element | null) => {
		this._predictLayout = newPredictLayout;
	});

	setTargetCell = action("set target cell", (value: {x: number, y: number} | null) => {
		this._targetCell = value ? { cellX: value.x, cellY: value.y } : value;
	});

	setHasMove = action("set has move", (value: boolean) => {
		this._hasMove = value;
	});

	setSelectedElement = action("set selected element", (value: string | null) => {
		this._selectedElementId = value;
	});

	resetSelectedElement = action("reset selected element", () => {
		this._selectedElementId = null;
	});

	getZone = ({ x, y }: {x: number, y: number}) => {
		let result = null;

		Object.keys(this._zones).sort((a, b) => {
			if (this._zones[a].size.endY - this._zones[a].size.startY > this._zones[b].size.endY - this._zones[b].size.startY) {
				return 1;
			} else if (this._zones[a].size.endY - this._zones[a].size.startY < this._zones[b].size.endY - this._zones[b].size.startY) {
				return -1;
			} else {
				return 0;
			}
		}).find((zoneId) => {
			const zone = this._zones[zoneId];
			if (x && y) {
				if (zone && zone.size.startX < x && x < zone.size.endX
					&&
					zone.size.startY < y && y < zone.size.endY) {
					result = zoneId;
					return true;
				}
			}
		});

		return result;
	};

	setZone = action("set zone", (id: string, value: Zone) => {
		this._zones[id] = value;
	});

	restZoneElements = action("set zone elements", (id: string) => {
		this._zones[id].elements = {};
	});

	setTargetZoneId = action("set target zone id", (zoneId: string | null) => {
		this._targetZoneId = zoneId;
	});

	setZoneSize = action("set zone size", ({ zoneId, startX, startY, width, height }: { zoneId: Id, startX: number, startY: number, width: number, height: number }) => {
		store.setZone(zoneId, {
			size: {
				startX,
				startY,
				endX: startX + width,
				endY: startY + height,
			},
			config: this._zones[zoneId].config,
			elements: this._zones[zoneId]?.elements || {}
		});
	});

	setZoneCellSize = action("set zone cell size", ({ id, width, height }: { id: Id, width: number, height: number }) => {
		if (this._zones[id]) {
			this._zones[id].config = {
				...this._zones[id].config,
				cellSize: {
					width,
					height,
				}
			};
		}
	});

	getElementSourceData = action("get element source daa", (foundElementId: string) => {
		let element = null;

		Object.keys(store._zones).find((zoneId) =>  {
			element = store._zones[zoneId].elements[foundElementId];

			return element;
		});

		// @ts-ignore
		return element?.sourceData ?? null;
	});
}

export const store: Store = new Store();
