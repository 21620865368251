import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { isEmpty } from "lodash";

import { synchroiser } from "synchroiser";
import { validateRequired, validateSchema } from "pages/section-wizzard/validation/validation";
import { dispatcher } from "store";

import { UpFirst } from "entities/lowFirst";

import { Field, Input, InputStyleName, ButtonStyle, Button } from "components";

import { ERROR_LOOKUP_EXIST_NAME, ERROR_LOOKUP_EXIST_TITLE, ValidationState } from "pages/section-wizzard/data/data";
import { EntityNameType } from "types/entity";

import { CloseMaxi } from "assets/icons";

import styles from "./new-lookup-configuration-popup.module.css";

interface NewLookupConfigurationProps {
    close: () => void,
    onSave: (title: string, name: string) => void
}

export const NewLookupConfiguration = observer((props: NewLookupConfigurationProps) => {
    const [name, setName] = useState<string>('');
    const [systemName, setSystemName] = useState<string>('');

    const [nameState, setNameState] = useState<ValidationState>({
        isInvalid: false,
        isNotUnique: false,
        error: ""
    });
    const [systemNameState, setSystemNameState] = useState<ValidationState>({
        isInvalid: false,
        isNotUnique: false,
        error: ""
    });

    const handleSave = useCallback(() => {
        props.onSave(name, systemName);
        dispatcher.sectionWizzard.setHasStageModel(true);
    }, [name, systemName]);

    const handleCancel = useCallback(() => {
        props.close();
    }, [props.close]);

    const handleTitleChange = useCallback(async (value: string) => {
        setName(value);
        let newState: ValidationState = {
            isInvalid: false,
            isNotUnique: false,
            error: ""
        };
        const isNotUnique = await synchroiser.checkExistEntityTitle(value, EntityNameType.Lookups);
        if (isNotUnique) {
            newState.isNotUnique = isNotUnique;
            newState.error = ERROR_LOOKUP_EXIST_TITLE;
            newState.isInvalid = false;
        }
        else {
            newState = validateRequired(value);
        }
        setNameState(newState);
    }, []);

    const handleSystemNameChange = useCallback(async (value: string) => {
        setSystemName(UpFirst(value));
        let newState: ValidationState = {
            isInvalid: false,
            isNotUnique: false,
            error: ""
        };
        const isNotUnique = await synchroiser.checkExistEntityName(UpFirst(value));
        if (isNotUnique) {
            newState.error = ERROR_LOOKUP_EXIST_NAME;
            newState.isInvalid = isNotUnique;
        }
        else {
            newState = validateSchema(UpFirst(value));
        }
        setSystemNameState(newState);
    }, []);

    const disabledSaveButton = useMemo(() =>
        nameState.isInvalid || systemNameState.isNotUnique ||
        systemNameState.isInvalid || isEmpty(name) || isEmpty(systemName)
        , [nameState.isInvalid, systemNameState.isNotUnique, systemNameState.isInvalid, name, systemName]
    );

    return (
        <div className={styles.dialog}>
            <div className={styles.header}>
                <span className={styles.title}>Новая стадийная модель</span>
                <CloseMaxi
                    className={styles.closeButton}
                    onClick={handleCancel}
                />
            </div>
            <div className={styles.dialogBody}>
                <Field name="name" caption="Название справочника" required>
                    <Input
                        value={name}
                        placeholder="Введите значение"
                        onChangeValue={handleTitleChange}
                        inputStyle={InputStyleName.Base}
                        isInvalid={nameState.isInvalid}
                        isNotUnique={nameState.isNotUnique}
                        invalidMessage={nameState.error}
                    />
                </Field>
                <Field name="systemName" caption="Системное название справочника" required>
                    <Input
                        value={systemName}
                        placeholder="Введите значение"
                        onChangeValue={handleSystemNameChange}
                        inputStyle={InputStyleName.Base}
                        isInvalid={systemNameState.isInvalid}
                        invalidMessage={systemNameState.error}
                    />
                </Field>
                <div className={styles.dialogFooter}>
                    <Button
                        caption="Отменить"
                        onClick={handleCancel}
                        style={ButtonStyle.Subtle}
                    />
                    <Button
                        caption="Сохранить"
                        onClick={handleSave}
                        style={ButtonStyle.Primary}
                        isDisabled={disabledSaveButton}
                    />
                </div>
            </div>
        </div >
    );
})
