import { api } from "api";

import IFilter from "entities/filter/IFilter";

export async function loadLookup(
	table: string,
	filter: IFilter | null,
	limit?: number,
): Promise<any[] | null> {
	const data: any = {
		filter: filter,
		offset: 0,
		limit: limit,
		entityName: table,
	};

	try {
		const items = await api.http.httpApi.entity.recordsList().post(data);
		return items.data.data;
	}
	catch (error) {
		console.error("An error occurred:", error);
		return null
	}

}

export async function loadEntity(table: string, filter?: IFilter): Promise<any[]> {
	const data: any = {
		filter: filter,
		offset: 0,
		limit: 7,
		entityName: table,
	};
	const items = await api.http.httpApi.entity.recordsList().post(data);
	return items.data.data;
}