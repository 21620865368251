import { useState } from "react";
import { observer } from "mobx-react-lite";

import { CustomGrid, Button, ButtonStyle } from "components";
import importStore from "pages/import/core/import-store";
import {EntityButton} from "pages/import/controls";
import { UpsertMode } from "pages/import/types/Request";

import { columns } from "./data/data";
import { EnrichIcon, RefreshIcon, SkipIcon } from "assets/icons";
import { InfoIcon } from "assets/icons";
import { RefreshPromt } from "assets/icons";
import { SkipPromt } from "assets/icons";
import { EnrichPropmt } from "assets/icons";

import styles from "./deduplication-rule.module.css";

function DeduplicationRule() {
    const settings = importStore.result?.upsertSettings;

    function getPromt(mode: UpsertMode) {
        switch (mode) {
            case UpsertMode.Update:
                return (
                    <Promt
                        title="Обновление"
                        texts="Система перезапишет значения в полях по последнему совпадению ключа дедубликации."
                    >
                        <RefreshPromt />
                    </Promt>
                )
            case UpsertMode.Enrich:
                return (
                    <Promt
                        title="Обогащение"
                        texts="Система последовательно заполнит пустые значения в полях."
                    >
                        <EnrichPropmt />
                    </Promt>
                )
            case UpsertMode.Skip:
                return (
                    <Promt
                        title="Пропуск"
                        texts="Система игнорирует дубли."
                    >
                        <SkipPromt />
                    </Promt>
                )
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <span className={styles.title}>Записи являются дублями, если у них совпадают колонки:</span>
                <CustomGrid columns={columns} datas={importStore.properties.filter(p => importStore.result.mappingProperties.some(m => m.columnInModel === p.columnName))} />
                <Button className={styles.link} caption={"Перейти в расширенный режим..."} style={ButtonStyle.Link} onClick={() => { }} />
            </div>
            <div className={styles.container}>
                <span className={styles.title}>Что делать при обнаружении дублей?</span>
                <div className={styles.deduplicationRule}>
                    <EntityButton icon={<RefreshIcon />} title={"Обновлять"} onClick={() => { settings.setMode(UpsertMode.Update) }} selected={settings?.mode === UpsertMode.Update} />
                    <EntityButton icon={<EnrichIcon />} title={"Обогащать"} onClick={() => { settings.setMode(UpsertMode.Enrich) }} selected={settings?.mode === UpsertMode.Enrich} />
                    <EntityButton icon={<SkipIcon />} title={"Пропускать"} onClick={() => { settings.setMode(UpsertMode.Skip) }} selected={settings?.mode === UpsertMode.Skip} />
                </div>
                <div className={styles.prompt}>
                    {getPromt(settings.mode)}
                </div>
            </div>
        </div>
    )
}

function Promt(props: { children: any, title: string, texts: string }) {
    const [isVisible, setVisible] = useState<boolean>(true);

    return (
        <div>
            <div className={styles.promtInfo}>
                <InfoIcon className={styles.promtIcon} />
                <div className={styles.promtInfoContent}>
                    <span className={styles.promtTitle}>{props.title}</span>
                    <span className={styles.promtText}>{props.texts}</span>
                    <span className={styles.promtLink} onClick={() => setVisible(!isVisible)}>{isVisible ? "Скрыть" : "Показать"} пример</span>
                </div>
            </div>
            {isVisible && props.children}
        </div>
    )
}

export default observer(DeduplicationRule);