import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";

import Avatar from "features/avatar/avatar-generator";
import { CheckBox, StageIndication } from "components";

import ListStore from "entities/ListStore";
import FilterColumnType from "entities/ColumnType";
import { ISort, SortDirection } from "entities/ISort";
import { IEntityStore } from "entities/Entity";

import { SortOldToNew } from "assets/icons";

import styles from "./grid.module.css";


const Header = observer(function (props: {
    columns: any[],
    onSortClick?: (column: any) => void,
    isCheckBox?: boolean,
    classNameHeader?: string
}) {
    const headClassNames = classNames({
        [`${styles.dataTableHeader} `]: props.isCheckBox,
        [`${styles.withoutCheckBoxTableHeader} `]: !props.isCheckBox,
        [`${props.classNameHeader}`]: props.classNameHeader,
    });
    const sortIconVisibility = classNames({
        [`${styles.sortIcon} `]: props.onSortClick,
        [`${styles.sortIconHidden} `]: !props.onSortClick,
    });
    const sortColumn = useCallback((column: any) => {
        if (props.onSortClick) { props.onSortClick(column) }
    }, [props.onSortClick])

    return (
        <div className={headClassNames} >
            {props.columns.map((column: any, i: number) => {
                return (
                    <>
                        <div key={column.name} id={column.caption} className={styles.headerCell}
                            style={{ width: `calc(${column.width! + "%"} - 20px)` }}>
                            <span >{column.caption}</span>
                            <SortOldToNew className={sortIconVisibility}
                                onClick={() => sortColumn(column)}
                            />
                        </div>

                    </>
                );
            })
            }
        </div>
    );
});

const DataGrid = observer(function (props: {
    columns: any[],
    listStore?: ListStore,
    otherData?: any[],
    loadLookup?: (sort?: ISort) => void,
    onClick?: (row: any) => void,
    onChangeChecked?: (value: boolean, data: any) => void,
    isCheckBox?: boolean,
    checked?: boolean,
    maxHeight?: any,
    className?: string;
    classNameHeader?: string;
}) {

    const defaultLimit = 30
    const listStore = props.listStore;
    const otherData = props.otherData;
    const sortClick = (listStore || props.loadLookup) ? onSortClick : undefined;

    const sort = useRef<ISort>(
        {
            columnPath: "createdOn",
            direction: 1
        }
    );

    const wrapperClassNames = classNames({
        [`${styles.dataGrid} `]: true,
        [`${props.className} `]: props.className,
    });
    const gridMainStyle = useMemo(() => {
        return props.maxHeight && { maxHeight: props.maxHeight }
    }, [props.maxHeight])

    const listStoreMapping = useMemo(() => {
        return !listStore?.isError &&
            listStore?.data.map((row) => {
                return (
                    <Row
                        key={row["id"]}
                        columns={props.columns}
                        row={row}
                        onClick={() => { }}
                        isUnread={row.isUnread}
                        listStore={listStore}
                        isCheckBox={props.isCheckBox}
                    />
                )
            })
    }, [listStore?.data])

    const otherDataMapping = useMemo(() => {
        
        return otherData &&
            otherData?.map((row) => {
                return (
                    <Row
                        key={row["id"]}
                        columns={props.columns}
                        row={row}
                        onClick={props.onClick}
                        isUnread={row.isUnread}
                        onChangeChecked={props.onChangeChecked}
                        isCheckBox={props.isCheckBox}
                    />
                )
            })
    }, [otherData?.map(data => data)])

    useEffect(() => {
        if (listStore)
            listStore?.load(listStore.filter, null, defaultLimit);
    }, [])

    function trackScrolling(e: SyntheticEvent<HTMLDivElement>) {
        const wrappedElement = e.target as HTMLDivElement;
        if (Math.round(wrappedElement.scrollHeight - wrappedElement.scrollTop) <= wrappedElement.clientHeight + 1 && listStore!.isLoaded) {
            listStore?.loadMore(null, defaultLimit)
        }
    }

    function onSortClick(column: any) {
        if (sort.current?.columnPath === column.sortColumn) {
            if (sort?.current.direction == SortDirection.Ascending) {
                sort.current.direction = SortDirection.Descending;
            }
            else {
                sort.current.direction = SortDirection.Ascending;
            }
        }
        else {
            sort.current = {
                columnPath: column.sortColumn!,
                direction: SortDirection.Ascending
            }
        }
        if (listStore) listStore.load(listStore.filter, null, defaultLimit, sort.current);
        else if (otherData && props.loadLookup) {
            props.loadLookup(sort.current);
        }
    }

    if (listStore && listStore!.isLoading) {
        return (
            <div className={styles.loading}>
            </div>
        )
    }

    return (
        <div className={wrapperClassNames}>
            <Header columns={props.columns} onSortClick={sortClick} isCheckBox={props.isCheckBox} classNameHeader={props.classNameHeader} />
            <div className={styles.dataTable} style={gridMainStyle} onScroll={listStore && trackScrolling}>
                {listStoreMapping}
                {otherDataMapping}
                {listStore?.isError && <div>{listStore.error}</div>}
            </div>

        </div>

    );
})

const Row = observer(function (props: {
    columns: any[],
    stages?: any[],
    row: any,
    onClick?: (row: any) => void,
    isUnread?: boolean,
    listStore?: ListStore,
    isCheckBox?: boolean,
    onChangeChecked?: (checked: boolean, data: any) => void,
}) {
    const columns = props.columns;
    const row = props.row;
    const entity = row as IEntityStore;

    const checkedRow = useMemo(() => {
        if (props.listStore)
            return (props.listStore!.includedIds.findIndex(x => x.id === row.id) > -1 ||
                (props.listStore!.isCheckedAll && props.listStore!.excludedIds.findIndex(x => x.id === row.id) === -1))
        else return row.checked
    }, [row.checked, props.listStore?.includedIds, props.listStore?.isCheckedAll, props.listStore?.excludedIds])

    const onChangeChecked = useCallback((value: boolean) => {
        if (props.listStore) {
            props.listStore!.onChangeChecked(value, entity)
        }
        else if (props.onChangeChecked) {
            props.onChangeChecked(value, row)
        }
    }, [])
    const onClick = useCallback(() => {
        if (props.onClick) {
            props.onClick(row);
        }
    }, [props])

    const rowClassNames = classNames({
        [`${styles.dataTableRow} `]: true,
        [`${styles.activeDataTableRow} `]: props.listStore && checkedRow
    })

    
    

    return (
        <>
            <div className={rowClassNames} onClick={onClick} >
                {props.isCheckBox &&
                    <CheckBox
                        className={styles.checkBoxGrid}
                        style={{ marginRight: `32px` }}
                        checked={checkedRow}
                        onChangeChecked={onChangeChecked}
                    />
                }
                {columns.map((column, i) => {
                    return (
                        <div key={column.name} className={styles.cell}
                            style={{ width: `calc(${column.width! + "%"} - 20px)` }}>
                            <Value r={row} stages={props.stages} column={column} width={`calc(${column.width! + "%"} - 20px)`} />
                        </div>

                    );
                })
                }
            </div>
        </>
    );
});


function FormatValue(value: any, type: FilterColumnType, stages?: any[]): JSX.Element {
    let text: JSX.Element | null = null;
    let element: JSX.Element | null = null;
    switch (type) {
        case FilterColumnType.String:
            text = value;
            break;
        case FilterColumnType.Date:
            const date = new Date(Date.parse(value));
            text = <>{date.toLocaleDateString("ru-Ru")}</>;
            break;
        case FilterColumnType.Owner:
            element = <Avatar name={value.name} className={styles.avatar} />;
            text = value.name;
            break;
        case FilterColumnType.Stage:
            element = <StageIndication stages={stages} stage={value} />;
            break;
        case FilterColumnType.Lookup:
            text = value.name;
            break;
        case FilterColumnType.Amount:
            text = <>{value.toLocaleString('ru-RU')} ₽</>;
            break;
        case FilterColumnType.MobilePhone:
            let formattedValue = "+" + value;
            formattedValue = formattedValue.substring(0, 2) + " (" + formattedValue.substring(2, 5) + ") " +
                formattedValue.substring(5, 8) + "-" + formattedValue.substring(8, 10) + "-" + formattedValue.substring(10)

            text = <>{formattedValue}</>;
            break;
        case FilterColumnType.Name:
            element = <Avatar name={value} className={styles.avatar} />;
            text = value;
            break;
    }
    return (
        <>
            {element !== null && element}
            {text !== null &&
                <span className={styles.value} >
                    {text}
                </span>
            }
        </>)
}

function Value(props: { r: any, stages?: any[], column: any, width?: string }) {
    let text: JSX.Element = <>-</>;
    const value = props.r[props.column.name];
    const entityId = props.r['entityId'];
    const id = props.r["id"];
    if (value) {
        text = FormatValue(value, props.column.type, props.stages);
    }

    if (props.column.getLink && value) {
        const id = props.r["id"];
        return (
            <NavLink className={styles.linkColumn} to={props.column.getLink(id)}>{text}</NavLink>
        );
    }

    if (props.column.onChecked) {
        return (<CheckBox onChangeChecked={(check) => props.column.onChecked(entityId, check, id)} checked={value} />)
    }

    return (
        <>
            {text}
        </>

    );
}

export default DataGrid;
