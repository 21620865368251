import FilterColumnType from "entities/ColumnType";
import SectionRoute from "entities/SectionRoute";
import { ChangeView } from "assets/icons";
import { Export } from "assets/icons";
import { CheckMarker } from "assets/icons";
import { DeleteIcon } from "assets/icons";
import { IFormat } from "entities/IItem";
import { Item } from "types";

export const systemDesigner = "Дизайнер системы";
export const systemNameTitle = "Системное название";
export const titleTitle = "Заголовок";
export const typeTitle = "Тип данных";
export const lookupTitle = "Справочник";
export const type = "Текстовое";

export const usersCaption = "Пользователи";
export const leadersCaption = "Руководители";
export const allUsersCaption = "Все сотрудники";

export const SettingsPath = '/settings';

export interface ILookupInfo {
    id?: string,
    columnName: string,
    columnTitle: string,
    columnType: string,
    isLookup?: boolean,
    lookupTable?: ILookupData,
    isRequired?: boolean,
    hasIndex?: boolean,
}
export interface ILookupData {
    id: string,
    createdOn?: string,
    modifiedOn?: string,
    entityName: string,
    entityTitle: string,
    entityInfo: ILookupInfo[],
    isLookup?: boolean,
    isSection?: boolean,
}

/**
 * @description форматы экспорта
 */
export const ExportFormats: IFormat[] = [
    {
        name: '.xlsx',
        checked: true
    },
    {
        name: '.csv',
        checked: false
    }
];

export enum actionEnums {
    ChooseAll = "1",
    GroupTo = "2",
    Cancel = "3",
    Deactive = "4",
    ChangeView = "5",
    Export = "6",
    Delete = "7",
    DeleteLookup = "8"
}
export enum TypesEnums {
    Text = "a778cd2d-01fb-4fd8-9f39-e8575ef9a799",
    Lookup = "075b8c3e-3a50-4f63-82f2-e78df03d6797",
    Logical = "a339aa9c-2448-4c2e-8129-a89ea65a6e60",
    Integer = "a212cce9-2f14-408c-9974-6d43e41f287d",
    Double = "471cd33c-aa6f-4a4a-8bf7-5cef13169d54",
    Date = "893ce3f7-abdd-497b-b382-33f9f1e13539",
    Color = "3a98afcc-feff-4575-8ab5-8b4aa26c233f"
}
export enum ColumnTypeName {
    Text = "String",
    Logical = "Boolean",
    Integer = "Int32",
    Decimal = "Decimal",
    DateTime = "DateTime",
    Guid = "Guid",
    Lookup = "Lookup"
}
export const fieldTypes = [
    {
        id: TypesEnums.Text,
        entityName: ColumnTypeName.Text,
        name: 'Текстовое'
    },
    {
        id: TypesEnums.Lookup,
        entityName: ColumnTypeName.Guid,
        name: 'Справочное',
    },
    {
        id: TypesEnums.Logical,
        entityName: ColumnTypeName.Logical,
        name: 'Логическое',
    },
    {
        id: TypesEnums.Integer,
        entityName: ColumnTypeName.Integer,
        name: 'Число целое',
    },
    {
        id: TypesEnums.Double,
        entityName: ColumnTypeName.Decimal,
        name: 'Число дробное',
    },
    {
        id: TypesEnums.Date,
        entityName: ColumnTypeName.DateTime,
        name: 'Дата',
    }
]

export const FieldsToSave = [
    {
        columnName: "Name",
        columnTitle: "Название",
        columnType: "String",
        isLookup: false,
        isRequired: true,
        hasIndex: false
    },
    {
        columnName: "Id",
        columnTitle: "Id",
        columnType: "Guid",
        isLookup: false,
        isRequired: true,
        hasIndex: false
    },]

export const DeduplicationNames = [
    {
        name: "tableName",
        caption: "Таблица в SaleBridge",
        width: 50,
        type: FilterColumnType.String,
        sortColumn: "name"
    },
    {
        name: "deduplicationKey",
        caption: "Ключ дедубликации",
        width: 50,
        type: FilterColumnType.String,
        sortColumn: "email"
    },
]

export const UserColumns = [
    {
        name: "name",
        caption: "ФИО",
        width: 25,
        type: FilterColumnType.Name,
        getLink: (id: string) => `/${SectionRoute.User}/${id}`,
        sortColumn: "name"
    },
    {
        name: "email",
        caption: "Email",
        width: 15,
        type: FilterColumnType.String,
        sortColumn: "email"
    },
    {
        name: "position",
        caption: "Должность",
        width: 15,
        type: FilterColumnType.String,
        sortColumn: "position.name"
    },
    {
        name: "department",
        caption: "Отдел",
        width: 15,
        type: FilterColumnType.Lookup,
        sortColumn: "department.name"
    },
    {
        name: "createdOn",
        caption: "Дата создания",
        width: 15,
        type: FilterColumnType.Date,
        sortColumn: "createdOn"
    },

];
export const UserColumnsForAdministration = [
    {
        name: "name",
        caption: "ФИО",
        width: 40,
        type: FilterColumnType.Name,
        getLink: (id: string) => `/${SectionRoute.User}/${id}`,
        sortColumn: "name"
    },
    {
        name: "email",
        caption: "Email",
        width: 20,
        type: FilterColumnType.String,
        sortColumn: "email"
    },
    {
        name: "position",
        caption: "Должность",
        width: 20,
        type: FilterColumnType.String,
        sortColumn: "position.name"
    },
    {
        name: "department",
        caption: "Отдел",
        width: 20,
        type: FilterColumnType.Lookup,
        sortColumn: "department.name"
    }

];

export const FuncRolesColumns = [
    {
        name: "name",
        caption: "Функциональная роль",
        width: 100,
        type: FilterColumnType.String,
        sortColumn: "name"
    }

];
export const OrgRolesColumns = [
    {
        name: "name",
        caption: "Организационная роль",
        width: 100,
        type: FilterColumnType.String,
        sortColumn: "name"
    }

];

export const LicenseColumns = [
    {
        name: "name",
        caption: "Название",
        width: 25,
        type: FilterColumnType.Name,
        // getLink: (id: string) => `/${SectionRoute.License}/${id}`,
        sortColumn: "name"
    },
    {
        name: "startDate",
        caption: "Действует от",
        width: 15,
        type: FilterColumnType.String,
        sortColumn: "startDate"
    },
    {
        name: "finishDate",
        caption: "Действует до",
        width: 15,
        type: FilterColumnType.String,
        sortColumn: "finishDate"
    },
    {
        name: "countLic",
        caption: "Количество",
        width: 15,
        type: FilterColumnType.Integer,
        sortColumn: "countLic"
    },
    {
        name: "createdOn",
        caption: "Дата создания",
        width: 11,
        type: FilterColumnType.Date,
        sortColumn: "createdOn"
    },
];
export const LookupColumns = [
    {
        name: "entityTitle",
        caption: "Название",
        width: 30,
        type: FilterColumnType.String,
        getLink: (id: string) => ``,
        sortColumn: "entityTitle"
    },
    {
        name: "entityName",
        caption: "Системное название",
        width: 30,
        type: FilterColumnType.String,
        sortColumn: "entityName"
    },
    {
        name: "createdOn",
        caption: "Дата создания",
        width: 20,
        type: FilterColumnType.Date,
        sortColumn: "createdOn"
    },
    {
        name: "modifiedOn",
        caption: "Дата изменения",
        width: 20,
        type: FilterColumnType.Date,
        sortColumn: "modifiedOn"
    },

];
export const MainLookupsActions = [
    {
        id: actionEnums.ChangeView,
        name: "Изменить отображение...",
        icon: <ChangeView />
    },
    {
        id: actionEnums.Export,
        name: "Экспортировать...",
        icon: <Export />
    },
]
export const LookupsActions: Item[] = [
    {
        id: actionEnums.ChangeView,
        name: "Изменить отображение...",
        icon: <ChangeView />
    },
    {
        id: actionEnums.Export,
        name: "Экспортировать...",
        icon: <Export />
    },
    {
        id: actionEnums.ChooseAll,
        name: "Выделить все",
        icon: <CheckMarker />,
        isSeparator: true
    },
    {
        id: actionEnums.DeleteLookup,
        name: "Удалить справочник",
        icon: <DeleteIcon />,
        isRed: true
    },
]

export const UsersActions: Item[] = [
    {
        id: actionEnums.ChooseAll,
        name: "Выбрать всех"
    },
    {
        id: actionEnums.GroupTo,
        name: "Поместить в группу..."
    },
    {
        id: actionEnums.Cancel,
        name: "Отменить множественный выбор"
    },
    {
        id: actionEnums.Deactive,
        name: "Деактивировать",
        isRed: true
    },
];

export enum MenuItems {
    UserSettings = "usersettings",
    SystemSettings = "systemsettings",
    AutomationSettings = "automationsettings",
    AccountSettings = "accountsettings",
    DataSettings = "datasettings"
}

export const Menu = [
    {
        id: MenuItems.UserSettings,
        name: "Настройка пользователей"
    },
    {
        id: MenuItems.SystemSettings,
        name: "Настройки системы"
    },
    {
        id: MenuItems.AutomationSettings,
        name: "Автоматизация"
    },
    {
        id: MenuItems.AccountSettings,
        name: "Управление аккаунтом",
    },
    {
        id: MenuItems.DataSettings,
        name: "Данные в системе",
    },
]
export enum UserEnums {
    SystemUsers = "systemUsers",
    OrgStructure = "orgStructure",
    FunctionalRoles = "functionalRoles",
    AccessRights = "accessRights",
    AccesRightsLink = "accesRightsLink"

}
export const UsersSettings = [
    {
        id: UserEnums.SystemUsers,
        name: 'Пользователи системы',
    },
    {
        id: UserEnums.OrgStructure,
        name: 'Структура организации',
    },
    {
        id: UserEnums.FunctionalRoles,
        name: 'Функциональные роли',
    },
    {
        id: UserEnums.AccessRights,
        name: 'Права доступа',
    },
];

export enum SystemEnums {
    Lookups = "lookups"
}

export const SystemSettings = [
    {
        id: SystemEnums.Lookups,
        name: 'Справочники',
    },

];

export enum AutomationEnums {
    Current = "currentsettings"
}

export const AutomationSettings = [
    {
        id: AutomationEnums.Current,
        name: 'Текущие',

    }

];

export enum AccountEnums {
    Manager = "licensemanager",
    Invoices = "invoices",
    Products = "products"
}

export const AccountSettings = [
    {
        id: AccountEnums.Manager,
        name: 'Менеджер лицензий',
    },
    {
        id: AccountEnums.Invoices,
        name: 'Счета',
    },
    {
        id: AccountEnums.Products,
        name: 'Продукты',
    },
];

export enum DataSettingEnums {
    Import = "import",
    Store = "store",
    Deduplication = 'deduplication',
}

export const DataSettings = [
    {
        id: DataSettingEnums.Import,
        name: 'Импорт',
    },
    {
        id: DataSettingEnums.Store,
        name: 'Хранение данных',
    },
    {
        id: DataSettingEnums.Deduplication,
        name: 'Правила дедубликации',
    }
];

export function getPath(value: string | undefined) {
    switch (value) {
        //pages
        case (UserEnums.SystemUsers):
            {
                return SettingsPath + '/' + MenuItems.UserSettings + '/' + UserEnums.SystemUsers;
            }
        case (UserEnums.OrgStructure):
            {
                return SettingsPath + '/' + MenuItems.UserSettings + '/' + UserEnums.OrgStructure;
            }
        case (UserEnums.FunctionalRoles):
            {
                return SettingsPath + '/' + MenuItems.UserSettings + '/' + UserEnums.FunctionalRoles;
            }
        case (UserEnums.AccessRights):
            {
                return SettingsPath + '/' + MenuItems.UserSettings + '/' + UserEnums.AccessRights;
            }
        case (SystemEnums.Lookups):
            {
                return SettingsPath + '/' + MenuItems.SystemSettings + '/' + SystemEnums.Lookups;
            }
        case (AutomationEnums.Current):
            {
                return SettingsPath + '/' + MenuItems.AutomationSettings + '/' + AutomationEnums.Current;
            }
        case (AccountEnums.Manager):
            {
                return SettingsPath + '/' + MenuItems.AccountSettings + '/' + AccountEnums.Manager;
            }
        case (AccountEnums.Invoices):
            {
                return SettingsPath + '/' + MenuItems.AccountSettings + '/' + AccountEnums.Invoices;
            }
        case (AccountEnums.Products):
            {
                return SettingsPath + '/' + MenuItems.AccountSettings + '/' + AccountEnums.Products;
            }
        case (DataSettingEnums.Import):
            {
                return SettingsPath + '/' + MenuItems.DataSettings + '/' + DataSettingEnums.Import;
            }
        case (DataSettingEnums.Store):
            {
                return SettingsPath + '/' + MenuItems.DataSettings + '/' + DataSettingEnums.Store;
            }
        case (DataSettingEnums.Deduplication):
            {
                return SettingsPath + '/' + MenuItems.DataSettings + '/' + DataSettingEnums.Deduplication;
            }
        //MenuItems
        case (MenuItems.UserSettings):
            {
                return SettingsPath + '/' + MenuItems.UserSettings
            }
        case (MenuItems.SystemSettings):
            {
                return SettingsPath + '/' + MenuItems.SystemSettings
            }
        case (MenuItems.AutomationSettings):
            {
                return SettingsPath + '/' + MenuItems.AutomationSettings
            }
        case (MenuItems.AccountSettings):
            {
                return SettingsPath + '/' + MenuItems.AccountSettings
            }
        case (MenuItems.DataSettings):
            {
                return SettingsPath + '/' + MenuItems.DataSettings
            }
        //MainPage
        case (SettingsPath):
            {
                return SettingsPath
            }
        default: {
            return SettingsPath
        }
    }
}

export function getTitleOfMenu(title: string) {
    let elem = Menu.filter(e => e.id === title)
    return elem[0]?.name //TODO elem[0] может отсутствовать, приложение падает. Системная ошибка

}

export function getTitleOfPage(title: string, page: string) {
    switch (title) {
        case (MenuItems.UserSettings):
            {
                return UsersSettings.filter(e => e.id === page)[0].name
            }
        case (MenuItems.SystemSettings):
            {
                return SystemSettings.filter(e => e.id === page)[0].name
            }
        case (MenuItems.AutomationSettings):
            {
                return AutomationSettings.filter(e => e.id === page)[0].name
            }
        case (MenuItems.AccountSettings):
            {
                return AccountSettings.filter(e => e.id === page)[0].name
            }
        case (MenuItems.DataSettings):
            {
                return DataSettings.filter(e => e.id === page)[0].name
            }
        default: {
            return ""
        }
    }
}