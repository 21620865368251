import { Button, ButtonStyle, Select } from "components";

import { Item } from "types";
import { ITab } from "pages/section-wizzard/data/data";

import { DragIcon, MoreInRound, MoreInRoundActive } from "assets/icons";

import styles from "./tab.module.css";

interface IPropsTab {
    tab: ITab,
    index: number,
    moreItems: Item[],
    styleButton?: ButtonStyle,
    getItemClass: (i: number) => string,
    onClick: (index: number) => void,
    onClickToMoreInRound: (value: Item | null) => void,
    handleClickToSelect: (value: ITab, index: number,e?: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export function Tab(props: IPropsTab) {

    return (
        <div key={props.tab.tabName} id={props.tab.tabName} className={styles.item}>
            <DragIcon className={styles.dragIcon} />
            <Button
                key={props.index}
                caption={props.tab.caption}
                className={props.getItemClass(props.index)}
                style={props.styleButton}
                onClick={() => props.onClick(props.index)}
            />
            <Select
                id={props.tab.tabName}
                firstIcon={<MoreInRound id='SvgMoreInRound' className={styles.moreButton} />}
                classNameButton={styles.itemTabButton}
                iconOpened={<MoreInRoundActive id='SvgMoreInRoundActive' className={styles.moreButtonIsActive} />}
                onChangeValue={props.onClickToMoreInRound}
                items={props.moreItems}
                classNameList={styles.moreList}
                onClick={(e) => props.handleClickToSelect(props.tab, props.index,e)}
            />
        </div>
    );
}