import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";

import { InputSearch, Button } from "components";
import AddingFolder from "./adding-folder/adding-folder";
import FilterFolder from "./filter-folder/filter-folder";

import { rootUserRoles } from "entities/userRole/RootUserRoles";
import UserRole from "entities/userRole/UserRole";

import { Plus } from "assets/icons";

import styles from "./tree-view.module.css";



export const allUsersCaption = "Все сотрудники";

export enum UserRoleType {
    OrganizationRole = 0,
    FunctionalRole = 1
}

const TreeView = observer(function (props: {
    type: UserRoleType,
    isOpen: boolean,
    onClick: (value: any) => void,
    choosingItem: UserRole | null,
    onClickDelete?: (userRole: UserRole) => void,
    isHeader?: boolean,
    isActionsVisible: boolean
}) {
    const { isOpen } = props;
    const [isOpenNewDialog, setOpenNewDialog] = useState<boolean>(false);

    const mainRootClassNames = classNames({
        [`${styles.filterFolderName}`]: true,
        [`${styles.listItemSelected}`]: !props.choosingItem,
    });
    const roots = useMemo(() => {
        if (rootUserRoles) {
            return rootUserRoles.rootUserRoles.map((item, i) => {
                return (
                    <li key={i} className={styles.childFilterFolder}>
                        <FilterFolder
                            choosingItem={props.choosingItem}
                            userRole={item}
                            onClickFilter={props.onClick}
                            onClickDelete={props.onClickDelete}
                            onClickLiValue={props.onClick}
                            isActionsVisible={props.isActionsVisible}
                        />
                    </li>
                );
            });
        }
        else {
            return <></>;
        }
    }, [rootUserRoles.rootUserRoles, props])

    useEffect(() => {
        rootUserRoles.loadByType(props.type)
    }, [props.type])

    const onClickPlus = useCallback(() => {
        setOpenNewDialog(true)
    }, [])
    const onClickMainRoot = useCallback(() => {
        props.onClick(null)
    }, [props.onClick])

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.treeViewWrapper}>
            {props.isHeader &&
                <div className={styles.headTreeView}>
                    <InputSearch classNameInput={styles.search} className={styles.searchWrapper} value={null} placeholder={"Поиск"} onChangeValue={() => { }} />
                    <Button firstIcon={<Plus />} className={styles.addButton} onClick={onClickPlus} />
                </div>
            }
            <div className={styles.filterFolder}>
                <li key={0} onClick={onClickMainRoot} className={mainRootClassNames}>
                    <span>{allUsersCaption}</span>
                </li>
                {roots}
            </div>
            <AddingFolder type={props.type} rootUserRoles={rootUserRoles} isOpen={isOpenNewDialog} setOpen={setOpenNewDialog} />
        </div>
    )
})
export default TreeView;