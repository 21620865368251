import { Account } from "entities/account/Account";
import { Contact } from "entities/contact/Contact";
import Entity, { IEntityData, IEntityStore } from "entities/Entity";
import IStage from "entities/IStage";
// @ts-ignore
import { Lead } from "entities/lead/lead";
import { FieldValidationState, validateRequired } from "entities/Validation";
import { action, makeObservable, observable } from "mobx";
import { Item } from "types";



export class Sale implements IEntityStore {
	schema: string = "Sale";
	displaySchema: string = "Продажи";
	id: string = "";


	number: string = this.random();
	name: string = "";
	lead: Lead | null = null;
	priority: Item | null = null;
	stage: IStage | null = null;
	account: Account | null = null;
	contact: Contact | null = null;
	contactPerson: Contact | null = null;
	owner: Item | null = null;
	needType: Item | null = null;
	salesAmount: number | null = null;
	budgetEstimate: number | null = null;

	notes: string = "";
	modifiedOn: string = "";
	createdOn: string = "";

	validation: { [key: string]: FieldValidationState };

	constructor() {
		makeObservable(this, {
			name: observable,
			lead: observable,
			priority: observable,
			stage: observable,
			account: observable,
			contact: observable,
			contactPerson: observable,
			owner: observable,
			needType: observable,
			salesAmount: observable,
			budgetEstimate: observable,
			notes: observable,
			validation: observable,
			setValue: action,
			validate: action,
			resetValidate: action,
			deserialize: action,
		});

		this.validation = {
			priority: {
				isInvalid: false
			},
			stage: {
				isInvalid: false
			},
			account: {
				isInvalid: false
			},
			contact: {
				isInvalid: false
			},
			owner: {
				isInvalid: false
			},
			needType: {
				isInvalid: false
			},
			// lead: {
			//     isInvalid: false
			// },
		};
	}

	random(): string {
		let rnd = Math.floor((Math.random() * (1000 - 1) + 1)).toString();
		if (rnd.length < 3) {
			let mask = "000";
			rnd = mask.substring(0, mask.length - rnd.length).concat(rnd);

		}
		return rnd;
	}

	setValue(value: string | Item, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	// @action
	validate(): boolean {
		if (!validateRequired(this.priority, this.validation.priority, true)) {
			return false;
		}
		if (!validateRequired(this.stage, this.validation.stage, true)) {
			return false;
		}
		if (!validateRequired(this.account, this.validation.account, true, true)) {
			return false;
		}
		if (!validateRequired(this.contact, this.validation.contact, true, true)) {
			return false;
		}
		if (!validateRequired(this.owner, this.validation.owner, true, true)) {
			return false;
		}
		if (!validateRequired(this.needType, this.validation.needType, true)) {
			return false;
		}
		// if (!validateRequired(this.lead, this.validation.lead)) {
		//     return false;
		// }
		return true;
	}

	resetValidate() {
		this.validation = {
			priority: {
				isInvalid: false
			},
			stage: {
				isInvalid: false
			},
			account: {
				isInvalid: false
			},
			contact: {
				isInvalid: false
			},
			owner: {
				isInvalid: false
			},
			needType: {
				isInvalid: false
			},
			// lead: {
			//     isInvalid: false
			// },
		};
	}

	// @action
	deserialize(p: any) {
		this.id = p.id;
		this.number = p.number ?? this.random();
		this.name = p.name ?? "";
		this.lead = p.lead;
		this.priority = p.priority;
		this.stage = p.stage;
		this.account = p.account;
		this.contact = p.contact;
		this.contactPerson = p.contactPerson;
		this.owner = p.owner;
		this.needType = p.needType;
		this.notes = p.notes ?? "";
		this.salesAmount = p.salesAmount;
		this.budgetEstimate = p.budgetEstimate;
		this.modifiedOn = p.modifiedOn;
		this.createdOn = p.createdOn;
	}

	serialize() {

		let values = Reflect.ownKeys(this).filter(x => !x.toString().toLowerCase().includes("schema") && !(this.id === "" && x === "id") && x !== "validation" && !x.toString().endsWith("edOn")).map((item) => {
			let name = null;
			let value = null;
			if (typeof Reflect.get(this, item) === "object" && item.toString().startsWith("sales") === false && item.toString().startsWith("budget") === false) {
				name = (item.toString().charAt(0).toUpperCase() + item.toString().slice(1)) + "Id";
				value = ((Reflect.get(this, item) as Item) !== null) ? (Reflect.get(this, item) as Item).id : null;
			} else {
				name = item.toString().charAt(0).toUpperCase() + item.toString().slice(1);
				value = Reflect.get(this, item) === "" ? null : Reflect.get(this, item);
			}
			if (name === "Phone" && value !== null && value !== undefined) {
				value = (value as string).replace(/\D/g, "");
				if (value[0] !== "7")
					value = value.charAt(0).replace(value[0], "7") + value.toString().slice(1);

			}
			if (name === "Name" && (value === undefined || value === null))
				value = this.number + " / " + this.needType?.name;
			return {
				propertyName: name,
				propertyValue: value
			};
		}) as IEntityData[];
		values.pop();
		return {
			entityName: this.schema!,
			values: values
		};
	}

	handleError(error: string): void {
		if (error === "name_is_empty") {
			// this.validName = false;
			// this.errorName = "";
		}
	}
}


const sale = new Sale();

const saleEntity = new Entity<Sale>(sale);

export default saleEntity;