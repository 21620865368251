import Api from "api/http/api";

class SystemDetailDesigner extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/SystemDetailDesigner";
	}

	createSystemDetailDesigner = () => {
		this.setPath(`${this.basicPath}/create`);
		return this;
	};
	updateSystemDetailDesigner = (entityName: string) => {
		this.setPath(`${this.basicPath}/update?detailEntityName=${entityName}`);
		return this;
	};
	getSystemDetailDesigner = (entityName: string) => {
		this.setPath(`${this.basicPath}?entityName=${entityName}`);
		return this;
	};
}

const systemDetailDesigner = new SystemDetailDesigner();

export { systemDetailDesigner };