import { SyntheticEvent } from "react";

import PanelList from "components/panel-list/panel-list";
import HorizontalField from "components/field/horizontal-field";
import CheckBox from "components/check-box/check-box";
import SearchCardSkeleton from "entities/search/ui/SerchSkeleton/SearchCardSkeleton";

import { SearchItem, IFilterItem, FilterItem } from "entities/search";
import ISearchItem from "entities/search/model/SearchItem";
import getEntityIcons from "../data/EntityIcons";

import styles from "./SearchCard.module.css";

interface ISearchCardProps {
    entities: ISearchItem[]
    filtres: IFilterItem[]
    filtresActive: string[]
    onFilterChange: (table: string, value: boolean) => void;
    onFilterAll: (value: boolean) => void;
    isCheckedFilterAll: boolean;
    isLoaded: boolean;
    count: string;
    onScroll: (e: SyntheticEvent<HTMLDivElement>) => void;
}

function SearchCard(props: ISearchCardProps) {
    return (
        <div className={styles.body}>
            <PanelList className={styles.panelFilter}>
                <HorizontalField caption={"Искать везде"} name={"searchAll"} className={styles.filterHeader}>
                    <CheckBox checked={props.isCheckedFilterAll} onChangeChecked={props.onFilterAll} />
                </HorizontalField>
                <div className={styles.dividerFilter} />
                {props.filtres.map(f => <FilterItem entity={f} onChangeChecked={props.onFilterChange} filtres={props.filtresActive} />)}
            </PanelList>
            <SearchCardSkeleton isLoading={props.isLoaded}>
                <PanelList className={styles.panelResult}>
                    <div className={styles.resultHeader}>
                        <span>{props.count}</span>
                    </div>
                    <div className={styles.dividerResult} />
                    <div className={styles.result} onScroll={props.onScroll}>
                        <EntitiesResult entities={props.entities} />
                    </div>
                </PanelList>
            </SearchCardSkeleton>
        </div>
    )
}

function EntitiesResult(props: { entities: ISearchItem[] }) {
    if (props.entities.length === 0) {
        return (
            <span className={styles.nonResult}>Попробуйте изменить поисковый запрос</span>
        )
    }

    return (
        <>
            {props.entities.map(e => <SearchItem icon={getEntityIcons(e.schema)} entity={e} />)}
        </>
    )
}

export default SearchCard;