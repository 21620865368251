import { SavingState } from "entities/Entity";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import authStore from "AuthStore";
// import m from 'app/services/api';
import { LoadingState } from "entities/ListStore";
import StaticGroup from "./StaticGroup";
import { api } from "api";

export interface IStaticGroupFolder {
    id?: string | null;
    parentFolderId: string | null;
    name: string | null;
    groups: StaticGroup[];
    childFolders: IStaticGroupFolder[];
    isRoot: boolean;
    entityName: string | null;
}

export class StaticGroupFolder implements IStaticGroupFolder {
	id: string | null;
	name: string | null;
	groups: StaticGroup[];
	childFolders: StaticGroupFolder[];
	parentFolderId: string | null;
	isRoot: boolean;
	entityName: string | null;
	oldValues: StaticGroupFolder | null;
	loadingState: LoadingState;
	savingState: SavingState;

	constructor(entityName: string) {
		makeObservable(this, {
			id: observable,
			parentFolderId: observable,
			name: observable,
			groups: observable,
			childFolders: observable,
			loadingState: observable,
			savingState: observable,
			isSaved: computed,
			setValue: action,
			deserialize: action,
			getFolderTree: action,
			saveFolder: action,

		});

		this.entityName = entityName;
		this.id = null;
		this.name = null;
		this.groups = [];
		this.childFolders = [];
		this.isRoot = false;
		this.oldValues = null;
		this.parentFolderId = null;
		this.loadingState = LoadingState.NotAsked;
		this.savingState = SavingState.NotAsked;
	}

	get isLoading(): boolean {
		return this.loadingState === LoadingState.Loading;
	}

	get isLoaded(): boolean {
		return this.loadingState === LoadingState.Loaded;
	}

	get isError(): boolean {
		return this.loadingState === LoadingState.Failed;
	}

	get isSaving(): boolean {
		return this.savingState === SavingState.Saving;
	}

	get isSaved(): boolean {
		return this.savingState === SavingState.Saved;
	}

	setValue(value: any, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	validate() {
		if (this.name === null || this.name.length === 0) return false;
		return true;
	}


	async getFolderTree() {
		this.loadingState = LoadingState.Loading;
		// let request = await m.get(`api/StaticGroup/getfiltertree?userId=${authStore.userId}&entityName=${this.entityName}`);
		let request = await api.http.httpApi.staticGroup.getFilterTree(authStore.userId!,this.entityName!).get();
		runInAction(() => {
			if (request.data.id) {
				this.setValue(LoadingState.Loaded,"loadingState");
				this.deserialize(request);
				this.oldValues = this;
			}
			else {
				this.setValue(LoadingState.Failed,"loadingState");
			}
		});
	}


	async saveFolder(folder: StaticGroupFolder): Promise<boolean> {
		if (!this.validate()) {
			return false;
		}
		this.setValue(SavingState.Saving,"savingState");
		const data = this.serializeFolder(folder);
		let response;
		if (data.id === null || data.id === undefined) {
			// response = await m.post("/api/StaticGroup/createfolder", data);
			response =  await api.http.httpApi.staticGroup.createFolder().post(data);
		}
		else {
			// response = await m.post("/api/StaticGroup/updatefolder", data);
			response = await api.http.httpApi.staticGroup.updateFolder().post(data);
		}
		if (response && response.data.success === true) {
			this.setValue(SavingState.Saved,"savingState");
			await this.getFolderTree();
			return true;
		}
		else {
			this.setValue(SavingState.Failed,"savingState");
		}
		return false;
	}

	async deleteFolder(folderId: string): Promise<boolean> {
		this.setValue(SavingState.Saving,"savingState");
		// let response = await m.delete(`/api/StaticGroup/deletefolder?id=${folderId}`);
		let response = await api.http.httpApi.staticGroup.deleteFolder(folderId).delete();
		if (response && response.data.success === true) {
			this.setValue(SavingState.Saved,"savingState");
			await this.getFolderTree();
			return true;
		}
		else {
			this.setValue(SavingState.Failed,"savingState");
		}
		return false;
	}


	deserializeChildGroupFolders(childFilterFolders: any): StaticGroupFolder[] {
		let folders: StaticGroupFolder[] = [];
		childFilterFolders.forEach((item: any) => {
			let folder = new StaticGroupFolder(this.entityName!);
			folder.deserialize(item);
			folders.push(folder);
		});
		return folders;
	}

	deserialize(p: any) {
		this.id = p.id;
		this.name = p.name;
		this.groups = p.groups.map((group: any) => {
			let templateGroup = new StaticGroup(group.entityName);
			templateGroup.deserialize(group);
			return templateGroup;
		});
		this.childFolders = this.deserializeChildGroupFolders(p.childGroupFolders);
		this.isRoot = p.isRoot;
		this.parentFolderId = p.parentFolderId;
	}

	serializeFolder(folder: IStaticGroupFolder) {
		return folder.id ?
			{
				id: folder.id,
				name: folder.name,
				isRoot: folder.isRoot,
				parentFolderId: folder.parentFolderId,
				entityName: folder.entityName,
				userId: authStore.userId,
			}
			:
			{
				name: folder.name,
				isRoot: folder.isRoot,
				parentFolderId: folder.parentFolderId,
				entityName: folder.entityName,
				userId: authStore.userId,
			};
	}
}

export default StaticGroupFolder;