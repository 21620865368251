import { observer } from "mobx-react-lite";
import { useState, useEffect, useCallback, useMemo } from "react";

import DetailDropDown from "features/detail-drop-down/detail-drop-down";
import { UserRoleType } from "features/tree-view/tree-view";
import CheckBox from "components/check-box/check-box";
import DetailDialog from "components/dialog/detail-dialog/detail-dialog";
import Field from "components/field/field";
import DataGrid from "components/grid/data-grid";
import Input from "components/input/input";
import { InputStyleName } from "components/input/input-styles";
import WarningMessage from "components/dialog/warning-message/warning-message";

import { usersCaption, UserColumns, UserColumnsForAdministration, leadersCaption, UsersSettings, UserEnums, FuncRolesColumns } from "pages/settings/data/Fields";
import { rootUserRoles } from "entities/userRole/RootUserRoles";
import orgStructureMainPanelStore from "../OrgStructureMainPanelStore";
import { userStore } from "entities/user/UsersSectionStore";

import styles from "./../OrgStructure.module.css";

export const UsersTab = observer(function () {
    const [isOpenUserDialog, setOpenUserDialog] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState("");

    const isVisibleAddButton = useMemo(() => {
        return !orgStructureMainPanelStore.userRole ? false : true
    }, [orgStructureMainPanelStore.userRole])

    const createData = useCallback(() => {
        let newData = userStore.data.filter(elem => { if (orgStructureMainPanelStore.filteredUserStore.filter(e => e.id === elem.id).length <= 0) return elem })
        newData = newData.map(e => {
            return {
                ...e,
                checked: false
            }
        }
        )
        orgStructureMainPanelStore.setValue(newData, "dialogData")
    }, [])

    const onChangeChecked = useCallback((value: boolean, data: any) => {
        const newData = orgStructureMainPanelStore.dialogData;
        newData.filter(e => e.id === data.id)[0].checked = value;
        orgStructureMainPanelStore.setValue(newData, "dialogData")
    }, [orgStructureMainPanelStore.dialogData])

    const onSave = useCallback(() => {
        const rolesMas: string[] = [];
        rolesMas.push(orgStructureMainPanelStore.userRole?.data?.id!);
        orgStructureMainPanelStore.dialogData.forEach(e => {
            if (e.checked) orgStructureMainPanelStore.addUserInRole(e.id, rolesMas);
        })
        setOpenUserDialog(false);
    }, [orgStructureMainPanelStore.dialogData])

    const onCheckBoxChecked = useCallback((value: boolean) => {
        orgStructureMainPanelStore.setValue(value, "LDAPsynchronize")
    }, [])
    const onAddClick = useCallback(() => {
        createData(); setOpenUserDialog(true);
    }, [])

    useEffect(() => {
        createData()
    }, [])

    useEffect(() => {
        createData()
    }, [orgStructureMainPanelStore.filteredUserStore])

    function search(s: string) {
        setSearchValue(s);
    }
    // TODO Сделать SearchSelect Элеменов LDAP
    return (
        <div className={styles.tabWrapper}>
            <Field name={""} caption={"Синхронизировать с LDAP"} className={styles.field} >
                <CheckBox
                    checked={orgStructureMainPanelStore.LDAPsynchronize}
                    onChangeChecked={onCheckBoxChecked}
                />
            </Field>
            <Field name={""} caption={"Элемент LDAP"} className={styles.field} >
                <Input
                    value={""}
                    placeholder={""}
                    onChangeValue={() => { }}
                    inputStyle={InputStyleName.FullViewInput}
                    classNameParent={styles.fieldInput}
                    isDisabled={true}
                    isDisabledLocked={true}
                />
                {/* <SearchSelect
                    items={entity.lookups['LeadPriority']}
                    isInput={true}
                    onItemsLoad={async () => {
                        return await entity.loadLookups('LeadPriority', undefined, null, true);
                    }}
                    value={entity.entity.priority}
                    isInvalid={entity.entity.validation.priority.isInvalid}
                    invalidMessage={entity.entity.validation.priority.error}
                    onChangeValue={(value) => {
                        entity.entity.setValue(value!, "priority");
                        // validateRequired(value, leadEntity.entity.validation.priority,true);
                    }}
                    onFocusOut={() => validateRequired(entity.entity.priority, entity.entity.validation.priority, true)}
                    inputStyle={InputStyles.BaseWithoutBorder}
                    selectStyle={SelectStyles.QuickViewSelect}
                /> */}
            </Field>
            <DetailDropDown
                caption={usersCaption}
                classNameBody={styles.detailBody}
                onAddClick={onAddClick}
                isVisibleAddButton={isVisibleAddButton}
                isOpened={true}
                body={<DataGrid
                    columns={UserColumns}
                    otherData={orgStructureMainPanelStore.filteredUserStore}
                    isCheckBox={true}
                    className={styles.grid}
                    classNameHeader={styles.gridHeader}
                />}
            />
            <DetailDialog
                columns={UserColumnsForAdministration}
                data={orgStructureMainPanelStore.dialogData}
                onSave={onSave}
                onChangeChecked={onChangeChecked}
                searchValue={searchValue}
                search={search}
                isOpen={isOpenUserDialog}
                onClose={() => setOpenUserDialog(false)}
            />
        </div>
    );
});

export const LeadersTab = observer(function () {
    const [roleName, setRoleName] = useState<string>(orgStructureMainPanelStore.userRole?.data?.hasLeadRole ? orgStructureMainPanelStore.userRole?.data?.name : "");
    const [isOpenLeaderDialog, setOpenLeaderDialog] = useState<boolean>(false);
    const [isOpenWarningMessage, setOpenWarningMessage] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState("");

    const defaultName = useMemo(() => {
        return 'Руководители группы [' + orgStructureMainPanelStore.userRole?.data?.name + ']'
    }, [orgStructureMainPanelStore.userRole?.data?.name])

    const isBlockLeaderRole = useMemo(() => {
        return !orgStructureMainPanelStore.userRole ? true : false
    }, [orgStructureMainPanelStore.userRole])

    const isCheckedLeaderRole = useMemo(() => {
        return orgStructureMainPanelStore.userRole?.data?.hasLeadRole ?? false
    }, [orgStructureMainPanelStore.userRole])

    const leaderRoleName = useMemo(() => {
        return orgStructureMainPanelStore.userRole?.data?.hasLeadRole ? roleName : ""
    }, [roleName, isCheckedLeaderRole])

    const isDisabledLeaderRole = useMemo(() => {
        return orgStructureMainPanelStore.userRole?.data?.hasLeadRole ? false : true
    }, [orgStructureMainPanelStore.userRole])

    const isVisibleAddButton = useMemo(() => {
        return orgStructureMainPanelStore.userRole?.data?.hasLeadRole ? true : false
    }, [orgStructureMainPanelStore.userRole])

    const createData = useCallback(async () => {
        let newData = userStore.data.filter(elem => { if (orgStructureMainPanelStore.leaderStore.filter(e => e.id === elem.id).length <= 0) return elem })
        newData = newData.map(e => {
            return {
                ...e,
                checked: false
            }
        })
        orgStructureMainPanelStore.setValue(newData, "dialogData")
    }, [])

    const onChangeChecked = useCallback((value: boolean, data: any) => {
        const newData = orgStructureMainPanelStore.dialogData;
        newData.filter(e => e.id === data.id)[0].checked = value;
        orgStructureMainPanelStore.setValue(newData, "dialogData")
    }, [orgStructureMainPanelStore.dialogData])

    const onSave = useCallback(() => {
        const rolesMas: string[] = [];
        rolesMas.push(orgStructureMainPanelStore.userRole?.data?.leadRole.id!);
        orgStructureMainPanelStore.dialogData.forEach(e => {
            if (e.checked) orgStructureMainPanelStore.addUserInRole(e.id, rolesMas);
        })
        setOpenLeaderDialog(false);
    }, [orgStructureMainPanelStore.dialogData])

    const onChangeIsLeaderRole = useCallback(async (flag: boolean) => {
        if (flag) {
            setRoleName(defaultName);
            await rootUserRoles.createLeaderRole(defaultName, UserRoleType.OrganizationRole, orgStructureMainPanelStore.userRole?.data?.id!)
        }
        else {
            if (orgStructureMainPanelStore.leaderStore.length > 0) {
                setOpenWarningMessage(true)
            }
            else {
                await rootUserRoles.deleteLeaderRole(orgStructureMainPanelStore.userRole?.data?.leadRole.id!, UserRoleType.OrganizationRole)
            }
        }
    }, [defaultName])

    const onInputFocusOut = useCallback(async () => {
        if (roleName && roleName.length > 0) {
            const data = {
                id: orgStructureMainPanelStore.userRole?.data?.leadRole.id,
                name: roleName
            };
            await rootUserRoles.renameFolder(data, UserRoleType.OrganizationRole);
        }
    }, [leaderRoleName, roleName])

    const onCheckBoxChecked = useCallback((value: boolean) => {
        onChangeIsLeaderRole(value)
    }, [])
    const onLeaderRoleNameChange = useCallback((value: string) => {
        setRoleName(value)
    }, [])
    const onFocusOut = useCallback(async () => {
        await onInputFocusOut()
    }, [leaderRoleName, roleName])

    const onAddClick = useCallback(() => {
        createData();
        setOpenLeaderDialog(true)
    }, [])
    const onClose = useCallback(() => {
        setOpenLeaderDialog(false)
    }, [])
    const onBack = useCallback(() => {
        setOpenWarningMessage(false)
    }, [])

    useEffect(() => {
        if (orgStructureMainPanelStore.userRole?.data?.hasLeadRole === true)
            setRoleName(orgStructureMainPanelStore.userRole?.data?.leadRole.name!)
        else
            setRoleName("")
        createData()
    }, [])

    useEffect(() => {
        createData()
    }, [orgStructureMainPanelStore.leaderStore])

    useEffect(() => {
        if (orgStructureMainPanelStore.userRole)
            orgStructureMainPanelStore.setValue(rootUserRoles.rootUserRoles.filter(e => e.data?.id === orgStructureMainPanelStore.userRole?.data?.id)[0], "userRole");
    }, [rootUserRoles.rootUserRoles])

    useEffect(() => {
        if (orgStructureMainPanelStore.userRole?.data?.hasLeadRole)
            setRoleName(orgStructureMainPanelStore.userRole?.data?.leadRole.name!)
        else
            setRoleName("")
    }, [orgStructureMainPanelStore.userRole])

    function search(s: string) {
        setSearchValue(s);
    }

    return (
        <div className={styles.tabWrapper}>
            <Field name={"isLeadRole"} caption={"Существует роль руководителей"} className={styles.field} >
                <CheckBox
                    block={isBlockLeaderRole}
                    checked={isCheckedLeaderRole}
                    onChangeChecked={onCheckBoxChecked}
                />
            </Field>
            <Field name={"leadRoleName"} caption={"Название роли руководителей"} className={styles.field}>
                <Input
                    value={leaderRoleName}
                    placeholder={""}
                    onChangeValue={onLeaderRoleNameChange}
                    onFocusOut={onFocusOut}
                    inputStyle={InputStyleName.FullViewInput}
                    classNameParent={styles.fieldInput}
                    isDisabled={isDisabledLeaderRole}
                    isDisabledLocked={true}
                />
            </Field>
            <DetailDropDown
                caption={leadersCaption}
                classNameBody={styles.detailBody}
                onAddClick={onAddClick}
                isVisibleAddButton={isVisibleAddButton}
                isOpened={true}
                body={<DataGrid
                    columns={UserColumns}
                    otherData={orgStructureMainPanelStore.leaderStore}
                    isCheckBox={true}
                    className={styles.grid}
                    classNameHeader={styles.gridHeader}
                />
                }
            />
            <DetailDialog
                columns={UserColumnsForAdministration}
                data={orgStructureMainPanelStore.dialogData}
                onSave={onSave}
                onChangeChecked={onChangeChecked}
                searchValue={searchValue}
                search={search}
                isOpen={isOpenLeaderDialog}
                onClose={onClose}
            />
            <WarningMessage
                value={"Невозможно отключить роль руководителей, если в детали «Руководители» содержатся пользователи"}
                isOpen={isOpenWarningMessage}
                onBackClick={onBack}
            />
        </div>
    );
});
export const RolesTab = observer(function () {
    const [isOpenRoleDialog, setOpenRoleDialog] = useState<boolean>(false);
    const [isOpenLeaderRoleDialog, setOpenLeaderRoleDialog] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState("");

    const funcCaption = useMemo(() => {
        return UsersSettings.filter(e => e.id === UserEnums.FunctionalRoles)[0].name
    }, [])

    const funcBody = useMemo(() => {
        if (!orgStructureMainPanelStore.userRole || orgStructureMainPanelStore.funcRolesStore.length <= 0) {
            return null
        }
        else {
            return (<DataGrid
                columns={FuncRolesColumns}
                otherData={orgStructureMainPanelStore.funcRolesStore}
                isCheckBox={true}
                className={styles.grid}
                classNameHeader={styles.gridHeader}
            />);
        }
    }, [orgStructureMainPanelStore.userRole, orgStructureMainPanelStore.funcRolesStore])

    const leaderBody = useMemo(() => {
        if (!orgStructureMainPanelStore.userRole || orgStructureMainPanelStore.funcLeaderRolesStore.length <= 0) {
            return null
        }
        else {
            return (<DataGrid
                columns={FuncRolesColumns}
                otherData={orgStructureMainPanelStore.funcLeaderRolesStore}
                isCheckBox={true}
                className={styles.grid}
                classNameHeader={styles.gridHeader}
            />);
        }
    }, [orgStructureMainPanelStore.userRole, orgStructureMainPanelStore.funcRolesStore])

    const isVisibleFuncAddButton = useMemo(() => {
        return orgStructureMainPanelStore.userRole ? true : false
    }, [orgStructureMainPanelStore.userRole])
    const isVisibleLeaderAddButton = useMemo(() => {
        return orgStructureMainPanelStore.userRole?.data?.hasLeadRole ? true : false
    }, [orgStructureMainPanelStore.userRole])

    const createData = useCallback(async (roles: any[]) => {
        const funcRolesStore = await rootUserRoles.getRolesByType(UserRoleType.FunctionalRole);
        let newData = funcRolesStore.filter((elem: any) => { if (roles.filter(e => e.id === elem.id).length <= 0) return elem })
        newData = newData.map((e: any) => {
            return {
                ...e,
                checked: false
            }
        })
        orgStructureMainPanelStore.setValue(newData, "dialogData")
    }, [])

    const onChangeChecked = useCallback((value: boolean, data: any) => {
        const newData = orgStructureMainPanelStore.dialogData;
        newData.filter(e => e.id === data.id)[0].checked = value;
        orgStructureMainPanelStore.setValue(newData, "dialogData")
    }, [orgStructureMainPanelStore.dialogData])

    const onSaveFuncRole = useCallback(() => {
        const rolesMas: string[] = [];
        orgStructureMainPanelStore.dialogData.forEach(e => {
            if (e.checked) rolesMas.push(e.id)
        })
        orgStructureMainPanelStore.linkOrgAndFuncRoles(orgStructureMainPanelStore.userRole?.data?.id!, rolesMas)
        setOpenRoleDialog(false);
    }, [orgStructureMainPanelStore.dialogData, orgStructureMainPanelStore.userRole])
    const onSaveLeaderRole = useCallback(() => {
        const rolesMas: string[] = [];
        orgStructureMainPanelStore.dialogData.forEach(e => {
            if (e.checked) rolesMas.push(e.id)
        })
        orgStructureMainPanelStore.linkOrgAndFuncRoles(orgStructureMainPanelStore.userRole?.data?.leadRole.id!, rolesMas)
        setOpenLeaderRoleDialog(false);
    }, [orgStructureMainPanelStore.dialogData, orgStructureMainPanelStore.userRole])

    const onFuncAddClick = useCallback(() => {
        createData(orgStructureMainPanelStore.funcRolesStore); setOpenRoleDialog(true)
    }, [])
    const onLeaderAddClick = useCallback(() => {
        createData(orgStructureMainPanelStore.funcLeaderRolesStore); setOpenLeaderRoleDialog(true);
    }, [])

    const onFuncClose = useCallback(() => {
        setOpenRoleDialog(false)
    }, [])
    const onLeaderClose = useCallback(() => {
        setOpenLeaderRoleDialog(false)
    }, [])

    useEffect(() => {
        if (orgStructureMainPanelStore.userRole !== null) {
            orgStructureMainPanelStore.loadFuncRoles(orgStructureMainPanelStore.userRole.data?.id!);
            if (orgStructureMainPanelStore.userRole.data?.hasLeadRole)
                orgStructureMainPanelStore.loadFuncLeaderRoles(orgStructureMainPanelStore.userRole.data?.leadRole.id!)
        }
    }, [])

    function search(s: string) {
        setSearchValue(s);
    }

    return (
        <div className={styles.tabWrapper} style={{ gap: `20px` }}>
            <DetailDropDown
                caption={funcCaption}
                classNameBody={styles.detailBody}
                onAddClick={onFuncAddClick}
                isVisibleAddButton={isVisibleFuncAddButton}
                isOpened={true}
                body={funcBody ?? null}
            />
            <DetailDialog
                columns={FuncRolesColumns}
                data={orgStructureMainPanelStore.dialogData}
                onSave={onSaveFuncRole}
                onChangeChecked={onChangeChecked}
                searchValue={searchValue}
                search={search}
                isOpen={isOpenRoleDialog}
                onClose={onFuncClose}
            />
            <DetailDropDown
                caption={"Функциональные роли руководителей"}
                classNameBody={styles.detailBody}
                onAddClick={onLeaderAddClick}
                isVisibleAddButton={isVisibleLeaderAddButton}
                isOpened={true}
                body={leaderBody ?? null}
            />
            <DetailDialog
                columns={FuncRolesColumns}
                data={orgStructureMainPanelStore.dialogData}
                onSave={onSaveLeaderRole}
                onChangeChecked={onChangeChecked}
                searchValue={searchValue}
                search={search}
                isOpen={isOpenLeaderRoleDialog}
                onClose={onLeaderClose}
            />
        </div>
    );
});
