import { useEffect, useState } from "react";

import classNames from "classnames";

import CheckBox from "components/check-box/check-box";

import { CheckBoxBlue, Locked } from "assets/icons";

import styles from "components/input-for-section-designer/input.module.scss";

export const InputChecker = (props: {
	startValue: boolean,
	onChangeValue?: (value: boolean) => void,
	className?: string,
	text?: string,
	isDisabled?: boolean,
	[key: string]: any
}
) => {
	const [isChecked, setIsChecked] = useState(props.startValue);

	useEffect(() => {
		setIsChecked(props.startValue);
	}, [props.startValue]);

	const handleToogleChange = () => {
		setIsChecked(!isChecked);
		props.onChangeValue?.(!isChecked);
	};

	const checkerWrapper = classNames(styles.checkerWrapper,
		{
			[`${props.className}`]: props.className,
			[`${styles.checkerLocked}`]: props.isDisabled,
		}
	);

	return (
		<div {...props} className={checkerWrapper} >
			<CheckBox
				checked={isChecked}
				onChangeChecked={handleToogleChange}
				icon={<CheckBoxBlue />}
			/>
			<span className={styles.inputCheckerText} onClick={handleToogleChange}>{props.text}</span>
			{props.isDisabled ? <Locked className={styles.iconResult} /> : <></>}
		</div>
	);

};