import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { v4 } from 'uuid'
import { toJS } from 'mobx'

import SelectSections from './select-sections/select-sections'
import GlobalSearch from 'widgets/globalSearch/Index'
import {
	ButtonStyle,
	Button,
	WarningDialog,
	Select,
	Dialog,
	StatusBar,
} from 'components'
import Avatar from 'features/avatar/avatar-generator'
import NavigationSelect, {
	INavigationSelectItem,
} from 'features/navigation-select/navigation-select'

import {
	NavigationMenuItems,
	profileItems,
	profileItemIds,
	addItemIds,
} from './fields/navigation-fields'
import { AdvancedFilterConst } from '../section-head/data/constants'
import authStore from 'AuthStore'
import { LoadingState } from 'entities/ListStore'
import userEntity from 'entities/user/User'
import SectionRoute from 'entities/SectionRoute'
import { Item } from 'types'

import { LogoSalebridge } from 'assets/icons'
import { AddDefault } from 'assets/icons'
import { NoNotificationsDefault } from 'assets/icons'
import { SettingsDefault } from 'assets/icons'
import { TriangleToDown } from 'assets/icons'
import { Sections } from 'assets/icons'

import styles from './navigation-panel.module.css'
import { api } from 'api'
import { SignalRService } from 'api/ws'
import { store } from 'store'
import { Section } from 'store/store'
import { synchroiser } from 'synchroiser'


const Navigation = observer(function () {
	let [favorites, setFavorites] = useState<FavoriteSection[]>([])
	let [allSections, setAllSections] = useState<INavigationSelectItem[]>([])

	let [signalR, setSignalR] = useState<SignalRService | null>(null)
	let loadingState = useRef(LoadingState.NotAsked)

	useEffect(() => {
		const loadSectionsAndFavorites = async () => {
			await getSectionsWithStore()
			await getFavoritesWithStore()
		}
		loadSectionsAndFavorites()

		userEntity.load(authStore.userId!)
	}, [store.sections])

	useEffect(() => {
		setPropertyIsFavoriteInSections(allSections, favorites)
	}, [favorites])

	useEffect(() => {
		setSignalR(initSignalR())
		return () => {
			signalR?.stopConnection()
		}
	}, [])

	/**
	 * Инициализирует сервис SignalR и подписывается на событие "SectionCreated". При отработке события SectionCreated вызывается подгрузка разделов, что позволяет видеть новые разделы в выпадающем списке без перезагрузки страницы
	 * @returns SignalRService|null Объект сервиса SignalR или null, если инициализация не удалась.
	 */
	function initSignalR(): SignalRService | null {
		const signalRService = new SignalRService('SubscribeToSectionEvents', v4())
		signalRService.startConnection()
		signalRService.addEventListener(
			'SectionCreated',
			async (eventEntity: Section) => {

				const sectionInPanelExist = allSections.some(section => section.id === eventEntity.id)
				if (!sectionInPanelExist) {
					await synchroiser.getSectionsList();
					//TODO разобраться с дублированием. Мы можем обойтись без лишних запросов(synchroiser.getSectionsList()) но в таком случае запись дублируется в выпадающем списке разделов
					// const updatedSections = toJS(store.sections)
					// updatedSections.push(eventEntity)
					// store.sections = updatedSections

				}
			},
		)
		return signalRService
	}

	const setPropertyIsFavoriteInSections = (allSections: INavigationSelectItem[], favorites: FavoriteSection[],) => {
		if (allSections.length !== 0) {
			const newSections = allSections.map(section => {
				if (!section.tag) {
					return section
				}
				const favorite = favorites.find(f => f.sectionId === section.id)
				return { ...section, IsFavorite: favorite !== undefined }
			})
			setAllSections(newSections)
		}
	}

	const handleClickToFavorites = async (item: INavigationSelectItem) => {
		let result
		if (loadingState.current !== LoadingState.Loading) {
			loadingState.current = LoadingState.Loading
			if (item.IsFavorite) {
				result = await api.http.httpApi.favoriteSection
					.favoritesByUserId(authStore.userId!, item.id as string)
					.delete()
			} else {
				result = await api.http.httpApi.favoriteSection
					.favoriteSections()
					.post({
						UserId: authStore.userId,
						SectionId: item.id,
					})
			}
			const data = result.data
			if (data.success === true) {
				await loadFavorites()
			}

			loadingState.current = LoadingState.Loaded
		}
	}

	const loadFavorites = async () => {
		const result = await api.http.httpApi.favoriteSection
			.favoriteSections(authStore.userId!)
			.get()
		const data = result.data

		if (data.success === true) {
			setFavorites(data.data)
		}
	}

	// const loadSections = async () => {
	// 	const result = await api.http.httpApi.favoriteSection.sections().get()
	// 	const data = result.data
	// 	if (data.success === true && data.data.length > 0) {
	// 		var newSections = data.data.map((item: any) => {
	// 			return { ...item, tag: true }
	// 		})
	// 		setAllSections(newSections)
	// 	}
	// }

	const getFavoritesWithStore = async () => {
		const favoriteSection = store.sections.filter(x => x.isFavorite === true)
		if (favoriteSection.length !== 0) {
			const mapperFavorites = favoriteSection.map(item => {
				return {
					userId: authStore.userId!,
					sectionId: item.id,
					section: item,
				}
			})
			setFavorites(mapperFavorites)
		}
	}

	const getSectionsWithStore = async () => {
		var newSections = store.sections.map((item: any) => {
			return { ...item, tag: true }
		})
		setAllSections(newSections)
	}

	return (
		<div className={styles.navigation}>
			<LeftNavigation
				favorites={toJS(favorites).sort((a, b) => {
					if (a.section.displayValue < b.section.displayValue) {
						return -1
					}
					if (a.section.displayValue > b.section.displayValue) {
						return 1
					}
					return 0
				})}
				sections={allSections}
				handleClickToFavorites={handleClickToFavorites}
			/>
			<RightNavigation
				userEmail={userEntity.entity.email}
				userName={userEntity.entity.name}
			/>

		</div>
	)
})

interface FavoriteSection {
	userId: string
	sectionId: string
	section: any
}

const LeftNavigation = observer(function (props:
	{
		sections: INavigationSelectItem[]
		favorites: FavoriteSection[],
		handleClickToFavorites: (item: INavigationSelectItem) => void

	}) {
	const boxSections = Object.values(SectionRoute);
	const [openedBackWarningDialog, setOpenedBackWarningDialog] = useState(false);
	const location = useLocation();
	const [currentSection, setCurrentSection] = useState("");

	const workspaceClassNames = classNames({
		[`${styles.favSection} ${styles.favSectionOpened}`]: true,
	})

	const moreButtonStyle = classNames(styles.favSection, styles.moreButton);

	let navigate = useNavigate();

	function closeFilter() {
		AdvancedFilterConst.setOpenAdvancedFilterTree(
			!AdvancedFilterConst.isOpenAdvancedFilterTree,
			true,
		)
	}
	const getPath = useCallback((section: string) => {
		let path = ''
		boxSections.forEach(boxSection => {
			if (section.toLowerCase() === boxSection) {
				path = `/section/${section}`
				return
			}
		})
		if (!path) {
			path = `/singlePage/${section}`
		}
		return path
	}, [boxSections])

	const goToSection = useCallback(
		(section?: string) => {
			let path = getPath(section?.toLowerCase() ?? currentSection.toLowerCase())
			if (!section) {
				closeFilter()
			}
			navigate(path)
		},
		[currentSection, getPath, navigate],
	)

	const changeSection = useCallback(
		(section: string) => {
			var filterIsActive = AdvancedFilterConst.isOpenAdvancedFilter
			if (filterIsActive) {
				setCurrentSection(section)
				setOpenedBackWarningDialog(true)
			} else goToSection(section)
		},
		[goToSection],
	)

	const favoriteButtons = useMemo(() => {
		const warningText = 'Есть несохраненные изменения\nОтменить редактирование фильтра?'
		return (
			props.favorites.length > 0 &&
			props.favorites.map((favorite: FavoriteSection, i) => {
				const arrayOfLocation = location.pathname.split("/");
				const entity = arrayOfLocation[2] ? arrayOfLocation[2].toLowerCase() : null;
				let favoritesClassNames = classNames(`${styles.favSection}`, {
					[`${styles.favSectionOpened}`]: entity && entity === favorite?.section.entityName.toLowerCase()
				})
				return (
					<div>
						<Button
							onClick={() => changeSection(favorite.section.entityName)}
							caption={favorite.section.displayValue}
							className={favoritesClassNames}
						/>

						<WarningDialog
							value={warningText}
							valueReturn='Вернуться к редактированию'
							valueDelete='Да, отменить'
							isOpen={openedBackWarningDialog}
							onBackClick={() => {
								setOpenedBackWarningDialog(false)
							}}
							onCancelClick={() => {
								setOpenedBackWarningDialog(false)
								goToSection()
							}}
						/>
					</div>
				)
			})
		)
	}, [openedBackWarningDialog, props.favorites, location.pathname, changeSection, goToSection])

	return (
		<div className={styles.left}>
			<SelectSections
				firstIcon={<Sections />}
				onChangeValue={() => { }}
				items={NavigationMenuItems}
				styles={ButtonStyle.IconIndigo}
			/>
			<div className={styles.logo}>
				<LogoSalebridge />
			</div>
			<NavigationSelect
				name="Разделы"
				secondIcon={<TriangleToDown />}
				isRotateSecondIcon={true}
				classNameButton={moreButtonStyle}
				classNameOpenList={styles.moreButtonOpened}
				classNameList={styles.moreList}
				items={props.sections}
				handleClickToFavorites={props.handleClickToFavorites}
			/>
			<div className={styles.favSections}>
				{location.pathname.includes('workspace') && (
					<Link to='/workspace' className={workspaceClassNames}>
						<span>Рабочий стол</span>
					</Link>
				)}
				{favoriteButtons}
			</div>
		</div>
	)
})

const RightNavigation = observer(function (props: {
	userName: string
	userEmail: string
}) {
	const [searchValue, setSearchValue] = useState('')
	const [dialogTitleValue, setDialogTitleValue] = useState<string>('')
	const [entityValue, setEntityValue] = useState<any>()
	const [dialogContentValue, setDialogContentValue] = useState<JSX.Element>()
	const [isSaved, setSaved] = useState<boolean>(false)

	const history = useNavigate()
	const refSetTimeout = useRef<NodeJS.Timeout>()

	const profile = useMemo(() => {
		return (
			<div className={styles.profile}>
				<Avatar name={props.userName} className={styles.profileAvatar} />
				<div className={styles.profileInformation}>
					<span>{props.userName}</span>
					<span>{props.userEmail}</span>
				</div>
			</div>
		)
	}, [props.userName, props.userEmail])

	const moreButtonStyle = classNames({
		[`${styles.favSection} ${styles.moreButton}`]: true,
	})
	const avatarStyle = classNames({
		[`${styles.iconButton} ${styles.profileAvatarIcon}`]: true,
	})

	async function save() {
		let saved = await entityValue.save()
		setSaved(saved)
		refSetTimeout.current = setTimeout(() => {
			setSaved(false)
		}, 4000)
	}

	function search(s: string) {
		setSearchValue(s)
	}

	function changeOnProfile(value: Item | null) {
		if (value?.id === profileItemIds.AccountManagement) {
		} else if (value?.id === profileItemIds.Profile) {
			history(`/${SectionRoute.User}/${userEntity.entity.id}`)
		} else if (value?.id === profileItemIds.Exit) {
			authStore.logOut()
			history('/')
		}
	}

	function makeLink() {
		return `${(Object.keys(SectionRoute) as Array<keyof typeof SectionRoute>)
			.find(key => key.includes(entityValue?.entity.schema))
			?.toLocaleLowerCase()} / ${entityValue?.entity.id}`
	}

	return (
		<div className={styles.right}>
			<GlobalSearch />
			<Button
				className={styles.iconButton}
				style={ButtonStyle.IconIndigo}
				firstIcon={<NoNotificationsDefault />}
			/>
			<Button
				className={styles.settingsButton}
				style={ButtonStyle.IconIndigo}
				firstIcon={<SettingsDefault />}
				to={'/settings'}
			/>
			<Select
				styles={ButtonStyle.IconIndigo}
				firstIcon={<Avatar name={props.userName} className={avatarStyle} />}
				classNameButton={styles.iconButton}
				onChangeValue={changeOnProfile}
				items={profileItems}
				isProfile={true}
				classNameList={styles.profileListSelect}
			>
				{profile}
			</Select>
			{entityValue && (
				<>
					<Dialog
						title={dialogTitleValue}
						isOpen={entityValue.openedDialog}
						dialogFooterButtons={
							<>
								<Button
									caption={'Отмена'}
									onClick={entityValue.cancelClick}
									style={ButtonStyle.Subtle}
								/>
								<Button
									caption={'Сохранить'}
									onClick={save}
									style={ButtonStyle.Success}
								/>
							</>
						}
						onClick={entityValue.cancelClick}
					>
						{dialogContentValue}
					</Dialog>
					<WarningDialog
						isOpen={entityValue.openedWarningDialog}
						onBackClick={entityValue.setWarningDialog}
						onCancelClick={entityValue.cancelChanges}
					/>
				</>
			)}
			<StatusBar
				isOpen={isSaved}
				path={makeLink()}
				onMouseOver={() => {
					clearTimeout(refSetTimeout.current)
					setSaved(true)
				}}
				onMouseLeave={() => {
					if (isSaved)
						refSetTimeout.current = setTimeout(() => {
							setSaved(false)
						}, 4000)
				}}
			/>
		</div>
	)
})

export default Navigation
