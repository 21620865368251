import React from 'react';

import { Button, ButtonStyle, Modal } from '../../../components'

import { Warning } from "assets/icons";

import styles from './warning-dialog.module.css';
import dialogStyles from '../dialog.module.css';


interface IWarningDialogProps extends React.HTMLAttributes<HTMLElement> {
    isOpen: boolean;
    onCancelClick: () => void;
    onBackClick: () => void;
    valueReturn?: string;
    valueDelete?: string;
    value?: string | JSX.Element;
    isDisabled?: boolean;
}

function WarningDialog(props: IWarningDialogProps) {
    if (!props.isOpen) {
        return null;
    }

    return (
        <Modal>
            <div className={styles.backdrop} />
            <div className={styles.dialog} style={props.style}>
                <div className={styles.header}>
                    <span className={styles.title}>Внимание</span>
                    <Warning className={dialogStyles.closeButton} />

                </div>
                <div className={styles.dialogBody}>
                    {props.value == null ? 'Есть несохраненные изменения.\nОтменить создание новой записи?' : props.value}
                </div>
                <div className={dialogStyles.dialogFooter}>
                    <Button caption={props.valueReturn == null ? 'Вернуться к редактированию' : props.valueReturn} onClick={props.onBackClick} style={ButtonStyle.Subtle} isDisabled={props.isDisabled} />
                    <Button caption={props.valueDelete == null ? 'Да, отменить' : props.valueDelete} onClick={props.onCancelClick} style={ButtonStyle.Danger} isDisabled={props.isDisabled} />
                </div>

            </div>
        </Modal>
    );
}

export default WarningDialog;