import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Button, ButtonStyle, InputStyleName, Input } from 'components';

import { InlineStyle, TextStyles, mappingColors } from "../config/config";
import { useEditorApi } from "../config/context";
import { getImage } from "../services/convert";
import { validateLink } from "entities/Validation";

import { ArrowToDownMini, Clear, TextFormattingIconLink } from "assets/icons";

import styles from "./tool-panel.module.css";
import classNames from "classnames";

const ToolPanel = observer(function (props: { focusEditor: () => void, visible: boolean, setVisible: (value: boolean) => void, fixedToolPanel: boolean, setFixedToolPanel: (value: boolean) => void }) {
    const { editorState, toggleInlineStyle, hasInlineStyle, hasColorInlineStyle, addLink, removeLink } = useEditorApi();
    let [isOpenColors, setOpenColors] = useState<boolean>(false);
    let [isOpenLinkBlock, setOpenLinkBlock] = useState<boolean>(false);
    let [startLink, setStartLink] = useState<string>('');
    const inlineStyles = Object.keys(TextStyles)

    let wrapper = classNames({
        [`${styles.toolPanelWrapper} ` + `${styles.visible}`]: props.visible,
        [`${styles.toolPanelWrapper} `]: true
    });
    const selectedTextColor = useMemo(
        () => startLink.length <= 0 && hasColorInlineStyle(),
        [startLink.length, hasColorInlineStyle]
    );
    const disabledButton = useMemo(
        () => { return startLink.length > 0 ?? false },
        [startLink.length]
    );

    const selectedLink = useMemo(
        () => startLink.length > 0,
        [startLink.length]
    );

    const disabledUnderlineButton = useCallback(
        (style: InlineStyle) => {
            if (style === InlineStyle.UNDERLINE) return disabledButton
            else return false
        }, [startLink.length]
    );

    const onClickInlineStyleButton = useCallback(
        (style: InlineStyle) => {
            props.focusEditor();
            toggleInlineStyle(style);
            setOpenColors(false);
            setOpenLinkBlock(false);
        }, [props.focusEditor, toggleInlineStyle]
    );

    const buttonsMap = useMemo(() =>
        inlineStyles.map(style => {
            return (
                <Button
                    key={style as InlineStyle}
                    style={ButtonStyle.TextEditor}
                    selected={hasInlineStyle(style as InlineStyle)}
                    onClick={e => {
                        onClickInlineStyleButton(style as InlineStyle);
                    }}
                    firstIcon={getImage(style)}
                    isDisabled={disabledUnderlineButton(style as InlineStyle)}
                />
            )
        }), [startLink.length, inlineStyles]
    );

    useEffect(() => {
        setLink();
    }, [editorState.getSelection()]);

    useEffect(() => {
        if (!props.visible) {
            setOpenColors(false);
            setOpenLinkBlock(false);
        }
    }, [props.visible]);




    const onClickColorButton = useCallback(
        () => {
            setOpenColors(!isOpenColors);
            setOpenLinkBlock(false);
            props.setFixedToolPanel(true);
        }, [isOpenColors, props.setFixedToolPanel]
    );
    const onClickLinkButton = useCallback(
        () => {
            setOpenLinkBlock(!isOpenLinkBlock);
            setOpenColors(false);
            props.setFixedToolPanel(true);
        }, [isOpenColors, props.setFixedToolPanel]
    );
    const onMouseLeave = (e: any) => {
        e.persist();
        if (!props.fixedToolPanel) { props.setVisible(false); props.setFixedToolPanel(true); }
    };

    function setLink() {
        var selectionState = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const startKey = selectionState.getStartKey();
        const startOffset = selectionState.getStartOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

        if (linkKey) {
            let linkInstance = contentState.getEntity(linkKey);
            setStartLink(linkInstance.getData().url);
        }
        else setStartLink('');
    }

    function setColor(value: InlineStyle) {
        toggleInlineStyle(value);
        setOpenColors(false);
    }

    function customRemoveLink() {
        var selectionState = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const startKey = selectionState.getStartKey();
        const startOffset = selectionState.getStartOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
        if (linkKey) {
            removeLink(selectionState)
        }
    }

    return (
        <div className={wrapper} onMouseLeave={onMouseLeave}>
            {isOpenColors &&
                <div className={styles.colorTab}>
                    <ColorsBlock focusEditor={props.focusEditor} setColor={setColor} setFixedToolPanel={props.setFixedToolPanel} />
                </div>
            }
            {isOpenLinkBlock && <AddLinkBlock focusEditor={props.focusEditor} startLink={startLink} addLink={addLink} removeLink={customRemoveLink} closeLink={() => setOpenLinkBlock(false)} setFixedToolPanel={props.setFixedToolPanel} />}
            <Button
                style={ButtonStyle.TextEditor}
                className={styles.colorToolPanelItem}
                selected={selectedTextColor}
                onClick={onClickColorButton}
                firstIcon={getImage('color')}
                secondIcon={<ArrowToDownMini />}
                isRotateSecondIcon={true}
                isOpened={isOpenColors}
                isDisabled={disabledButton}
            />
            <Divider />
            <div className={styles.inlineStylesDiv}>
                {buttonsMap}
            </div>
            <Divider />
            <Button
                style={ButtonStyle.TextEditor}
                selected={selectedLink}
                onClick={onClickLinkButton}
                firstIcon={getImage('link')}
            />

        </div>
    );
});

export default ToolPanel;

const AddLinkBlock = observer(function (props: { focusEditor: () => void, startLink: string, addLink: (value: string) => void, removeLink: () => void, closeLink: () => void, setFixedToolPanel: (value: boolean) => void }) {
    let [value, setValue] = useState(props.startLink);
    let clearStyles = classNames({
        [`${styles.clearIcon} ` + `${styles.visible}`]: value.length > 0,
        [`${styles.clearIcon} `]: true
    });

    useEffect(() => {
        function keyup(event: any) {
            if (event.key === 'Enter') {
                props.closeLink();
                props.focusEditor()
                props.setFixedToolPanel(false);
            }
        }
        document.addEventListener("keyup", keyup);
        return () => document.removeEventListener("keyup", keyup);
    }, [])

    function onChange(value: string) {
        setValue(value);
        if (value === '') props.removeLink();
        else if (validateLink(value)) { props.addLink(value); }
    }

    return (
        <div className={styles.linkBlock}>
            <TextFormattingIconLink className={styles.linkIcon} />
            <Input
                focus={true}
                value={value}
                placeholder={"Вставьте ссылку..."}
                inputStyle={InputStyleName.BaseWithoutBorder}
                onChangeValue={(value) => { onChange(value) }}
                className={styles.linkInput}
            />
            <Clear className={clearStyles} onClick={() => { onChange('') }} />
        </div>
    )
})
const Divider = observer(function () {
    return (
        <div className={styles.divider}></div>
    )
});
const ColorsBlock = observer(function (props: { focusEditor: () => void, setColor: (inlineStyle: InlineStyle) => void, setFixedToolPanel: (value: boolean) => void }) {
    let setColor = props.setColor;
    const colorArray = Array.from(mappingColors)

    const onClickColor = useCallback(
        async (color: InlineStyle) => {
            props.focusEditor();
            setColor(color);
            props.setFixedToolPanel(false);
        }, [props.focusEditor, setColor]
    );

    return (
        <div className={styles.colorWrapper}>
            {colorArray.map(([key, value]) => {
                return (
                    <Button
                        key={key}
                        className={styles.colorButton}
                        styleButton={{ backgroundColor: value }}
                        onClick={e => {
                            e.preventDefault();
                            onClickColor(key);
                        }}
                    />)
            })}
        </div>
    );
});