import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { v4 } from "uuid";

import { dispatcher } from "store";

import { modalController } from "features/modals";

import { TabsWithPagination } from "../tabs-with-pagination/tabs-with-pagination";
import { DragZone } from "../drag-zone/drag-zone";
import NewTabConfiguration from "./new-tab-configuration/new-tab-configuration";

import { DisplayedPanel, TabId, TabState, TabsConfig } from "types/entity";
import { ITab } from "pages/section-wizzard/data/data";

import styles from "./tabs-panel.module.css";

const TabDemoData = observer(() => {
	return (
		<div className={styles.demoData}>
			<span className={styles.demoCaption}>Здесь будут демо данные</span>
		</div>
	);
});

export const TabsPanel = observer((props: {
	isAdded?: boolean,
	onMoveBoxTab?: (id: string, value: boolean, currentDisplayedPanel?: DisplayedPanel) => void,
}) => {
	const [currentTab, setCurrentTab] = useState<JSX.Element>(<TabDemoData />);
	const [idModal] = useState<string>(v4());

	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	const tabsConfig = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.tabsConfig.filter(elem => elem);
	}, [sectionWizzard?.reactorConfig.tabs.tabsConfig.filter(elem => elem)]);

	const closeFuncWithOutConfirm = useCallback(() => {
		modalController.modalRemove(idModal);
	}, []);

	const addTab = useCallback(() => {
		modalController.popupAdd({
			id: idModal,
			layout: <NewTabConfiguration
				close={closeFuncWithOutConfirm}
			/>,
			closeFunc: closeFuncWithOutConfirm
		});
	}, []);

	const notBoxTab = useCallback((tab: TabsConfig) => {
		return {
			tabIndex: tab.tabIndex,
			tabName: tab.tabName,
			caption: tab.tabTitle,
			action: () => {
				setCurrentTab(
					<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
						<DragZone />
					</div>
				);
			}
		};
	}, [currentTab]);

	const tabs = useMemo(() => {
		const resultTabs: ITab[] = [];
		if (sectionWizzard?.displayedPanel === DisplayedPanel.Main) {
			tabsConfig?.forEach(tab => {
				resultTabs.push(notBoxTab(tab));
			});
		}
		const additionalTabs = dispatcher.sectionWizzard.getAdditionalFromConfig();
		if (additionalTabs) {
			const additionalTabsAsArray = Object.entries(additionalTabs);
			const filteredAdditionalTabs = additionalTabsAsArray.filter(([key, value]) => sectionWizzard?.displayedPanel === DisplayedPanel.Main ?
				value.state === TabState.EnabledOnPrimary :
				value.state === TabState.EnabledOnAuxiliary).map(([key, value]) => key);
			filteredAdditionalTabs.forEach((item) => {
				const findTab = additionalTabs[item as TabId];
				if (findTab)
					resultTabs.push({
						tabIndex: findTab.index,
						tabName: item,
						caption: findTab.title,
						action: () => {
							setCurrentTab(<TabDemoData />);
						}
					});
			});
		}
		const sortedTabs = resultTabs.sort((a, b) => {
			return a.tabIndex - b.tabIndex;
		});

		return sortedTabs;

	}, [tabsConfig?.map(tab => tab), sectionWizzard, dispatcher.sectionWizzard.getAdditionalFromConfig()]);

	if (tabs.length) {
		return (
			<div className={styles.tabsPanel}>
				{props.isAdded ?
					<TabsWithPagination items={tabs} className={styles.tabs} onAddClick={addTab} onMoveBoxTab={props.onMoveBoxTab} />
					: <TabsWithPagination items={tabs} className={styles.tabs} onMoveBoxTab={props.onMoveBoxTab} />
				}
				<div id='tabsPanelBody' className={styles.tabsPanelBody}>
					{currentTab}
				</div>
			</div>
		);
	} else {
		return <></>;
	}
});
