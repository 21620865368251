
import Comments from "features/comments/comments-block";

import styles from "./comments-block.module.css";


function СommentsBlock() {

    return (
        <div className={styles.wrapper}>
            <Comments
                className={styles.commentsWrapper}
                classNameTabContent={styles.tabContent}
                classNameTextEditor={styles.textEditor}
                commentsAndInputClassName={styles.commentsAndInputBlock}
            />
        </div>
    )
}

export default СommentsBlock;