import { action } from "mobx";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import classNames from "classnames";

import { UserRoleType, allUsersCaption } from "../tree-view";
import { Dialog, Button, ButtonStyle, Field, Input } from "components";

import RootUserRoles from "entities/userRole/RootUserRoles";
import UserRole from "entities/userRole/UserRole";

import { ArrowToDown } from "assets/icons";

import styles from "./adding-folder.module.css";


interface IAddingFolder {
    rootUserRoles: RootUserRoles,
    type: UserRoleType,
    isOpen: boolean,
    setOpen: (value: boolean) => void,
}

const AddingFolder = observer(function (props: IAddingFolder) {
    const { rootUserRoles, type, isOpen } = props;

    const [selected, setSelected] = useState<string | null>(null);
    const [newUserRole, setNewUserRole] = useState<string>("");

    const addNewFolder = useCallback(() => {
        const data = {
            parentId: selected,
            name: newUserRole,
            roleType: type
        };
        rootUserRoles.addFolder(data).then(() => {
            props.setOpen(false);
            setSelected(null);
            setNewUserRole("");
        }).catch(console.error)
    }, [selected, newUserRole, props]);

    if (!isOpen) {
        return null;
    }
    return (
        <Dialog title={type === UserRoleType.FunctionalRole ? "Новая функциональная роль" : "Новое подразделение"} isOpen={isOpen}
            dialogFooterButtons={
                <>
                    <Button caption={'Отмена'} onClick={action((e) => { props.setOpen(false) })}
                        style={ButtonStyle.Subtle} />

                    <Button caption={'Сохранить'}
                        onClick={addNewFolder}
                        style={ButtonStyle.Primary}
                        isDisabled={newUserRole === "" ? true : false}
                    />
                </>
            }
            onClick={action((e) => { props.setOpen(false) })}
        >
            <Field name={"name"} caption={"Название"} className={styles.selectPropertyField}>
                <Input
                    placeholder="Введите значение..."
                    value={newUserRole}
                    onChangeValue={(value) => { setNewUserRole(value) }}
                    focus={true}
                />
            </Field>
            <>
                <div className={styles.dialogBody}>
                    <div className={styles.filterName}>
                        Место создания
                    </div>
                    <div className={styles.folderTree}>
                        <li key={0} onClick={() => { setSelected(null) }}
                            className={selected === null ? `${styles.filterFolderName} ${styles.filterFolderSelected}` : styles.filterFolderName}
                        >
                            <span>{allUsersCaption}</span>
                        </li>
                        {
                            rootUserRoles !== undefined &&
                            rootUserRoles.rootUserRoles.map((item, i) => {
                                return (
                                    <li key={i} className={styles.childFilterFolderParent}>
                                        <FolderTree rootUserRoles={rootUserRoles}
                                            userRole={item}
                                            selected={selected}
                                            setSelected={(value: string | null) => { setSelected(value) }} />
                                    </li>

                                );

                            })
                        }
                    </div>
                </div>
                <div className={styles.devider} />
            </>
        </Dialog >
    )
})


const FolderTree = observer(function (props: {
    rootUserRoles: RootUserRoles,
    userRole: UserRole,
    selected: string | null,
    setSelected: (value: string | null) => void
}) {
    const { userRole, selected, setSelected } = props;
    const [isOpened, setOpened] = useState(false);

    const wrapperClassNames = classNames({
        [`${styles.filterFolderName} `]: true,
        [`${styles.filterFolderSelected}`]: selected === userRole.data?.id,
    });
    const listClassNames = classNames({
        [`${styles.filterFolderList} `]: true,
        [`${styles.visible}`]: isOpened,
    });

    const openFolder = useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        event.stopPropagation();
        setOpened(!isOpened);
    }, [])

    const selectFolder = useCallback(() => {
        setSelected(userRole.data!.id);
    }, [])

    return (
        <>
            <div onClick={selectFolder} className={wrapperClassNames} >
                {props.userRole.children.length !== 0 &&
                    <ArrowToDown
                        className={isOpened ? `${styles.filterFolderArrow} ${styles.open}` : styles.filterFolderArrow}
                        onClick={openFolder} />
                }
                {userRole.data?.name}

            </div>
            {isOpened &&
                <ul className={listClassNames} >
                    {
                        props.userRole.children !== undefined &&
                        props.userRole.children.map((item, i) => {

                            return (
                                <li key={i} className={styles.childFilterFolder}>
                                    <FolderTree rootUserRoles={props.rootUserRoles}
                                        userRole={item}
                                        selected={selected}
                                        setSelected={(value: string | null) => { setSelected(value) }} />
                                </li>

                            );

                        })
                    }


                </ul>
            }
        </>
    )
})



export default AddingFolder;