export function dataToItem(data: any, array?: any[]) {
    if (data == null) return { displayValue: "", value: undefined }
    return { displayValue: data.name ?? data.title ?? data.displayValue, value: data.id };
}

export function itemToLookup(item: any) {
    return item !== null ? item.title ? { id: item.id, name: item.name, title: item.title } : { id: item.id, name: item.name } : { id: "", name: "" }
}

export function getValue(data: any) {
    if (data == null) return "";
    return data.name ?? data.title ?? data.displayValue;
}
