import { filterTableType } from "./FilterTable";
import ColumnType from "../core/ColumnType";

export const Schemes : {[id: string] : any} = {
    [filterTableType.Contact]: {
        "schemeName":"Contact",
        "displayColumn":{ "name":"Name", "type":ColumnType.String},
        "columns":[{ "name":"Name", "type":ColumnType.String}, { "name":"Phone", "type":ColumnType.String, "isDisplay": true}, { "name":"Email", "type":ColumnType.String, "isDisplay": true}]
    },
    [filterTableType.Account] : {
        "schemeName":"Account",
        "displayColumn":{ "name":"Name", "type":ColumnType.String},
        "columns":[{ "name":"Name", "type":ColumnType.String},{ "collection":{ "name":"City", "type":ColumnType.String,}, "name":"Name", "type":ColumnType.String,},{ "name":"City.Name", "type":ColumnType.String, "isDisplay": true, "noSearch":true},{ "name":"Site", "type":ColumnType.String, "isDisplay": true}]
    },
    [filterTableType.Lead]: {
        "schemeName": "Lead",
        "displayColumn": { "name": "Title", "type": ColumnType.String },
        "columns": [
            { "name": "Title", "type": ColumnType.String },
            { "collection": { "name": "Stage", "type": ColumnType.String, }, "name": "Name", "type": ColumnType.String, },
            { "name": "Stage.Name", "type": ColumnType.String, "isDisplay": true, "noSearch": true },
            { "collection": { "name": "Contact", "type": ColumnType.String, }, "name": "Name", "type": ColumnType.String, },
            { "name": "Contact.Name", "type": ColumnType.String, "isDisplay": true, "noSearch": true },
        ]
    },
    [filterTableType.Sale] : {
        "schemeName":"Sale",
        "displayColumn":{ "name":"Name", "type":ColumnType.String},
        "columns":[
            { "name":"Name", "type":ColumnType.String },
            { "name":"SalesAmount", "type":ColumnType.Double, "isDisplay": true},
            { "collection":{ "name":"Stage", "type":ColumnType.String,}, "name":"Name", "type":ColumnType.String,},{ "name":"Stage.Name", "type":ColumnType.String, "isDisplay": true, "noSearch":true} 
        ]
    },
}

export function GetSchemes(schemes:string[]){
    let result : any[] = [];

    for(let schema of schemes){
        result.push(Schemes[schema]);
    }

    return result;
}