import { observer } from "mobx-react";
import classNames from "classnames";
import { CSSProperties, useCallback, useMemo, useState } from "react";

import {  Select, ButtonStyle, Dialog, Button, Field, Input } from "components";

import { rootUserRoles } from "entities/userRole/RootUserRoles";
import UserRole from "entities/userRole/UserRole";

import { ArrowToDown, DeleteIcon, MoreEllipsisVertical, Rename } from "assets/icons";

import styles from "./filter-folder.module.css";
import { Item } from "types";



const FilterFolder = observer(function (props: {
    userRole: UserRole,
    onClickFilter: (value: any) => void,
    choosingItem: UserRole | null,
    onClickDelete?: (userRole: UserRole) => void,
    onClickLiValue?: (user: UserRole) => void,
    isActionsVisible: boolean
}) {
    const [isOpened, setOpened] = useState(false);
    const [isOpenRenameDialog, setOpenRenameDialog] = useState<boolean>(false);

    const [chosingRole, setChosingRole] = useState<string | null>(null);
    const [newUserRole, setNewUserRole] = useState<string | null>(null);
    const [selectStyle, setSelectStyle] = useState<CSSProperties | undefined>(undefined);

    const listClassNames = classNames({
        [`${styles.filterFolderList} `]: true,
        [`${styles.visible}`]: isOpened,
    });
    const folderClassNames = classNames({
        [`${styles.filterFolderName} `]: true,
        [`${styles.listItemSelected}`]: props.choosingItem?.data?.id === props.userRole.data?.id,
    });
    const arrowClassNames = classNames({
        [`${styles.filterFolderArrow} `]: true,
        [`${styles.open} `]: isOpened,
    });



    const folders = useMemo(() => {
        if (isOpened && props.userRole.children)
            return (
                <ul className={listClassNames} >
                    {props.userRole.children.map((item, i) => {
                        return (
                            <li key={i} className={styles.childFilterFolder}>
                                <FilterFolder
                                    choosingItem={props.choosingItem}
                                    userRole={item}
                                    onClickFilter={props.onClickFilter}
                                    onClickDelete={props.onClickDelete}
                                    onClickLiValue={props.onClickLiValue}
                                    isActionsVisible={props.isActionsVisible}
                                />
                            </li>);
                    })
                    }
                </ul >
            );
        else {
            return <></>;
        }

    }, [props, isOpened])

    const openFolder = useCallback(() => {
        setOpened(!isOpened);
        props.onClickFilter(props.userRole)
        props.onClickLiValue && props.onClickLiValue(props.userRole);
    }, [props, isOpened])


    const onMouseEnter = useCallback(() => {
        setSelectStyle({ visibility: "visible" })
    }, [])

    const onMouseLeave = useCallback(() => {
        setSelectStyle({ visibility: "hidden" })
    }, [])

    const onChangeValue = useCallback((value: Item | null) => {
        if (value) {
            if (value.id === "rename") {
                setNewUserRole("");
                setChosingRole(props.userRole.data?.name!);
                setOpenRenameDialog(true);
            }
            else if (value.id === "delete") {
                props.onClickDelete!(props.userRole);
            }
        }
    }, [props.onClickDelete, props.userRole])

    const cancelEdit = useCallback(() => {
        setOpenRenameDialog(false);
        setChosingRole(null);
    }, [])

    const updateRole = useCallback(async () => {
        let data = {
            id: props.userRole.data?.id,
            name: newUserRole
        };
        const updated = await rootUserRoles.renameFolder(data, props.userRole.data?.roleType!)
        if (updated) {
            setOpenRenameDialog(false);
        }
    }, [props.userRole.data, newUserRole])

    return (
        <div className={styles.filterFolderRoot} >
            <div onClick={openFolder} className={folderClassNames} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                {props.userRole.children.length !== 0 &&
                    <ArrowToDown className={arrowClassNames} />
                }
                <span>{props.userRole.data?.name}</span>
                {props.isActionsVisible &&
                    <Select
                        items={folderActions}
                        onChangeValue={onChangeValue}
                        styles={ButtonStyle.IconIndigo}
                        firstIcon={<MoreEllipsisVertical />}
                        className={styles.selectFolder}
                        classNameButton={styles.selectFolderButton}
                        isPositionLeft={true}
                        style={selectStyle}
                    />
                }
            </div>
            {folders}
            <Dialog title={"Переименовать"} isOpen={isOpenRenameDialog}
                dialogFooterButtons={
                    <>
                        <Button caption={'Отмена'} onClick={cancelEdit} style={ButtonStyle.Subtle} />
                        <Button caption={'Сохранить'} onClick={updateRole} style={ButtonStyle.Primary} />
                    </>
                }
                onClick={(e) => {
                    setOpenRenameDialog(false);
                    setChosingRole(null);
                }}
            >
                <Field name={"oldName"} caption={"Старое значение"} className={styles.selectPropertyField}>
                    <Input
                        placeholder={""}
                        value={chosingRole}
                        onChangeValue={setChosingRole}
                        focus={true}
                        isDisabled={true}
                    />
                </Field>
                <Field name={"newName"} caption={"Новое значение"} className={styles.selectPropertyField}>
                    <Input
                        placeholder="Введите значение..."
                        value={newUserRole}
                        onChangeValue={setNewUserRole}
                        focus={true}
                        maxLength={100}
                    />
                </Field>
            </Dialog>

        </ div>
    )
})


const folderActions: Item[] = [
    {
        id: "rename",
        name: "Переименовать",
        isSeparator: true,
        icon: <Rename />
    },

    {
        id: "delete",
        name: "Удалить",
        isRed: true,
        icon: <DeleteIcon />,
    },
]

export default FilterFolder;