import Api from "api/http/api";

class SavedFilters extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/SavedFilters";
	}
	filterCreate = () => {
		this.setPath(`${this.basicPath}/create`);
		return this;
	};
	filterUpdate = () => {
		this.setPath(`${this.basicPath}/update`);
		return this;
	};
	filterDelete = (filterId:string) => {
		this.setPath(`${this.basicPath}/delete?id=${filterId}`);
		return this;
	};
	getFilter = (filterId:string) => {
		this.setPath(`${this.basicPath}/get?id=${filterId}`);
		return this;
	};
	filtersList = (userId?: string, entityName?:string) => {
		if (userId && entityName) {
			this.setPath(`${this.basicPath}/list?userId=${userId}&entityName=${entityName}`);
		}else if(userId){
			this.setPath(`${this.basicPath}/list?userId=${userId}`);
		} 		
		else {
			this.setPath(`${this.basicPath}/list`);
		}
		return this;
	};
	createFolder = () => {
		this.setPath(`${this.basicPath}/createfolder`);
		return this;
	};
	updateFolder = () => {
		this.setPath(`${this.basicPath}/updatefolder`);
		return this;
	};	
	deleteFolder = (folderId:string) => {
		this.setPath(`${this.basicPath}/deletefolder?id=${folderId}`);
		return this;
	};
	getFolder = (folderId:string) => {
		this.setPath(`${this.basicPath}/getfolder?id=${folderId}`);
		return this;
	};	
	getFilterTree = (userId:string,entityName:string) => {
		this.setPath(`${this.basicPath}/getfiltertree?userId=${userId}&entityName=${entityName}`);
		return this;
	};
}

const savedFilter = new SavedFilters();

export { savedFilter };