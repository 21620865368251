import { useEffect, useState } from "react";

import AdditionalBlock from "features/additional-block/additional-block";

import IProps from "../../IProps";
import { CommentEntity } from "entities/CommentEntity";
import { binding } from "module/reactor/binding/binding";

import styles from "./additional.module.css"
import { store } from "store";


function Additional() {
    //TODO получить схему!!!
    const schema = 'Contact'
    // const schema = bindingInXml(props.services, props.schema);
    
    const id = store.currentRowId;
    const [comment, setComment] = useState<CommentEntity>();

    const load = async () => {
        const newComment = new CommentEntity(schema, id)
        newComment.InitSignalR();
        await newComment.loadComments(true);
        setComment(newComment)
    };

    useEffect(() => {
        load();
        return () => {
            if (comment) {
                comment.stopSignalRConnection();
            }
        };
    }, []);

    return (
        <div className={styles.wrapperSort}>
            <AdditionalBlock
                id={id}
                commentEntity={comment!}
            />
        </div>
    );
}

export default Additional;


