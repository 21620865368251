import { observer } from "mobx-react-lite";
import { useMemo } from "react";

import { selector } from "store";

import { AdditionalBody, AutoTransitionBody, SettingsBody } from "./components";
import { HEXColors, ResultType, StageFullInfo, StageModel } from "types/entity";

import styles from "./settings-panel.module.css";


export type SettingPanelProps = {
    idStage: string,
    allStages: StageFullInfo[],
    onCloseSettingsPanel: () => void,
    stage: StageModel,
    color?: HEXColors,
    name?: string,
    stageToId?: string,
    stageFromId?: string,
    resultType?: ResultType,
};

export const SettingsPanel = observer((props: SettingPanelProps) => {

    return (
        <div className={styles.settingsPanelWrapper}>
            <SettingsBody
                idStage={props.idStage}
                allStages={props.allStages}
                onCloseSettingsPanel={props.onCloseSettingsPanel}
                stage={props.stage}
            />
            <AutoTransitionBody />
            <AdditionalBody
                idStage={props.idStage}
                allStages={props.allStages}
            />
        </div>
    );
});
