import { observer } from "mobx-react-lite";
import { NavLink, useNavigate } from "react-router-dom";
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";

import Avatar from "features/avatar/avatar-generator";
import { CheckBox, StageIndication } from "components";
import SkeletonGrid from "./skeleton/skeleton-grid";

import FilterColumnType from "entities/ColumnType";
import ListStore from "entities/ListStore";
import Entity, { IEntityStore } from "entities/Entity";
import ISort, { SortDirection } from "entities/ISort";
import GetFormattedPhone from "entities/GetFormattedPhone";
import { Click } from "./data/data";

import { SortOldToNew } from "assets/icons";

import styles from "./grid.module.css";

export interface IColumn {
    caption: string;
    width?: number;
    name: string;
    isLookup?: boolean;
    type: FilterColumnType;
    getLink?: (id: string) => string;
    onClick?: (e: any) => void;
    sortColumn?: string;
}

const Header = observer(function (props: {
    checkedAll: boolean,
    columns: IColumn[],
    classNameHeader?: string,
    listStore: ListStore,
    onChangeChecked: (checked: boolean) => void,
    onSortClick: (column: IColumn) => void
}
) {
    const headerClassNames = classNames({
        [`${styles.tableHeader} `]: true,
        [`${props.classNameHeader}`]: props.classNameHeader,
    });

    const onSortClick = useCallback((column: IColumn) => props.onSortClick(column), [props.onSortClick])
    const handleChange = useCallback((value: boolean) => props.onChangeChecked(value), [props.onChangeChecked])

    return (
        <div className={headerClassNames} >
            <CheckBox
                className={styles.checkBoxGrid}
                checked={props.checkedAll}
                onChangeChecked={handleChange}
            />
            {props.columns.map((column: IColumn, i: number) => {
                return (
                    <div key={column.name} id={column.caption} className={styles.headerCell} style={{ width: `calc(${column.width! + "%"} - 20px` }} >
                        <div className={styles.headerCaption}>{column.caption}</div>
                        {<SortOldToNew className={styles.sortIcon} onClick={() => onSortClick(column)} />}

                    </div>
                );
            })
            }

        </div>
    );
});

export const EmptyGrid = () => {
    return (
        <div className={styles.emptyWrapper}>
            <div className={styles.emptyTitle}>Ничего не найдено.</div>
            <div className={styles.emptySubTitle}>
                <span className={styles.emptyLink}>Создайте </span>
                запись или проверьте настройки фильтра</div>
        </div>
    )
}

export interface IRowAction {
    caption: string,
    action: (row: any) => void,
    getIsVisible?: (row: any) => boolean
}

const Grid = observer(function (props: {
    columns: IColumn[],
    listStore: ListStore,
    listStagesStore?: ListStore,
    classNameHeader?: string,
    entity: Entity<IEntityStore>
}) {
    const defaultLimit = 30;
    const allRecords = 0;
    const listStore = props.listStore;
    const listStagesStore = props.listStagesStore;
    const sort = useRef<ISort>(
        {
            columnPath: "createdOn",
            direction: 1
        }
    );
    const checkedAll = useMemo(() => {
        if (listStore.excludedIds.length > 0) { return false }
        else return listStore.isCheckedAll
    }, [listStore.excludedIds, listStore.isCheckedAll])

    const listStoreMapping = useMemo(() => {
        return !listStore?.isError && listStore?.data.map((row) => {
            return (
                <Row
                    key={row["id"]}
                    columns={props.columns}
                    row={row}
                    stages={listStagesStore?.data}
                    onClick={() => { }}
                    isUnread={row.isUnread}
                    onChangeChecked={listStore.onChangeChecked}
                    entity={props.entity}
                    listStore={props.listStore}
                    checkedAll={checkedAll}
                />
            )
        })
    }, [listStore?.data])

    const onSortClick = useCallback((column: IColumn) => {
        if (sort.current?.columnPath == column.sortColumn) {
            if (sort?.current.direction == SortDirection.Ascending) {
                sort.current.direction = SortDirection.Descending;
            }
            else {
                sort.current.direction = SortDirection.Ascending;
            }
        }
        else {
            sort.current = {
                columnPath: column.sortColumn!,
                direction: SortDirection.Ascending
            }
        }
        listStore?.load(listStore.filter, null, defaultLimit, sort.current);
    }, [sort.current])

    useEffect(() => {
        listStagesStore?.load(null, null, allRecords);
        listStore?.load(listStore.filter, null, defaultLimit, sort.current);

    }, [])

    function trackScrolling(e: SyntheticEvent<HTMLDivElement>) {
        const wrappedElement = e.target as HTMLDivElement;
        if (Math.round(wrappedElement.scrollHeight - wrappedElement.scrollTop) <= wrappedElement.clientHeight + 1 && listStore!.isLoaded) {
            listStore?.loadMore(listStore.filter, defaultLimit, sort.current);
        }
    }


    if (listStore!.isLoading || listStagesStore?.isLoading)
        return (<SkeletonGrid />)

    return (
        <div className={styles.grid}>
            <Header
                checkedAll={checkedAll}
                columns={props.columns}
                classNameHeader={props.classNameHeader}
                listStore={listStore}
                onChangeChecked={listStore.onChangeCheckedAll}
                onSortClick={onSortClick}
            />
            <div className={styles.table} onScroll={trackScrolling}>
                {listStoreMapping}
                {!listStore?.data.length && <div><EmptyGrid /></div>}
                {listStore?.isError && <div>{listStore.error}</div>}
            </div>
        </div>
    );
})

const Row = observer(function (props: {
    checkedAll: boolean,
    columns: IColumn[],
    stages?: any[],
    row: any,
    onClick?: (row: any) => void,
    isUnread?: boolean,
    onChangeChecked: (checked: boolean, entity: IEntityStore) => void,
    entity: Entity<IEntityStore>,
    listStore: ListStore
}) {
    const history = useNavigate();
    const columns = props.columns;
    let row = props.row;

    const checkedRow = useMemo(() => {
        return (props.listStore.includedIds.findIndex(x => x.id === row.id) > -1 ||
            (props.listStore.isCheckedAll && props.listStore.excludedIds.findIndex(x => x.id === row.id) == -1)) ? true : false

    }, [props.listStore.includedIds, props.listStore.isCheckedAll, props.listStore.excludedIds])

    function handleChange(value: boolean) {
        let val = Reflect.get(props.entity, "entity");
        props.onChangeChecked(value, row as typeof val);
    }

    const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (event.detail === Click.Single) {
            handleChange(!checkedRow);
            if (props.onClick) {
                props.onClick(row);
            }
        }
        else if (event.detail === Click.Double) {
            history(row.id);
        }

    }, [checkedRow, props.onClick, row]);

    const rowClassNames = classNames(`${styles.tableRow} `, {
        [`${styles.activeDataTableRow} `]: checkedRow,
    })

    return (
        <div
            className={rowClassNames}
            onClick={handleClick}
        >
            <CheckBox
                className={styles.checkBoxGrid}
                checked={checkedRow}
                onChangeChecked={handleChange}
            />
            {columns.map((column, i) => {
                return (
                    <div key={column.name} className={styles.cell} style={{ width: `calc(${column.width! + "%"} - 20px` }}>
                        <Value r={row} stages={props.stages} column={column} width={`calc(${column.width! + "%"} - 20px`} />
                    </div>

                );
            })
            }
        </div>
    );
});


function FormatValue(value: any, type: FilterColumnType, stages?: any[]): JSX.Element {
    let text: JSX.Element | null = null;
    let element: JSX.Element | null = null;
    switch (type) {
        case FilterColumnType.String:
            text = value;
            break;
        case FilterColumnType.Date:
            const date = new Date(Date.parse(value));
            text = <>{date.toLocaleDateString("ru-Ru")}</>;
            break;
        case FilterColumnType.Owner:
            element = <Avatar name={value.name} className={styles.avatar} />;
            text = value.name;
            break;
        case FilterColumnType.Stage:
            element = <StageIndication stages={stages} stage={value} />;
            break;
        case FilterColumnType.Lookup:
            text = value.name;
            break;
        case FilterColumnType.Amount:
            text = <>{value.toLocaleString('ru-RU')} ₽</>;
            break;
        case FilterColumnType.MobilePhone:
            text = <>{GetFormattedPhone(value)}</>;
            break;
        case FilterColumnType.Name:
            element = <Avatar name={value} className={styles.avatar} />;
            text = value;
            break;
    }
    return (
        <>
            {element !== null && element}
            {text !== null &&
                <span className={styles.value} >
                    {text}
                </span>
            }
        </>)
}

function Value(props: { r: any, stages?: any[], column: IColumn, width?: string }) {
    let text: JSX.Element = <>-</>;
    const value = props.r[props.column.name];
    if (value) {
        text = FormatValue(value, props.column.type, props.stages);
    }
    if (props.column.getLink && value) {
        let id = (props.column.type === FilterColumnType.Lookup || props.column.type === FilterColumnType.Owner) ? value.id : props.r["id"];

        return (
            <NavLink className={styles.linkColumn} to={props.column.getLink(id)}>{text}</NavLink>
        );
    }
    return (
        <>{text}</>
    );
}

export default Grid;