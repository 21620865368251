import { useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";

import { lowerCase } from "lodash";

import { dispatcher } from "store";

import detailConfigurationStore, { DetailStoreField } from "../detail-configuration-store";
import { validateRequired, validateSchema } from "entities/Validation";
import { UpFirst } from "entities/lowFirst";

import {
    Button, ButtonStyle, Dropdown, Field, Input,
    InputStyleName, SelectStyleName, Text
} from "components";
import ToggleBlock from "features/toggle-block/toggle-block";

import { Item } from "types";

import { CloseMaxi } from "assets/icons";

import styles from "../detail-configuration.module.css";

const NewObjectConfiguration = observer((props: { close: () => void, closeAll: () => void, onSave: () => void }) => {
    useEffect(() => {
        const loadLookups = async () => {
            await detailConfigurationStore.loadAllObjects();
        }
        loadLookups();
        detailConfigurationStore.setCurrentSection();
        detailConfigurationStore.setNames();
    }, [])

    const sectionWizzard = useMemo(() => {
        return dispatcher.entity.get()?.entity.sectionWizzard;
    }, [dispatcher.entity.get()?.entity.sectionWizzard]);

    const tabsConfig = useMemo(() => {
        return sectionWizzard?.reactorConfig.tabs.tabsConfig ?? [];
    }, [sectionWizzard?.reactorConfig.tabs.tabsConfig.map(tab => tab)]);

    const currentTabIndex = useMemo(() => {
        return sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
    }, [sectionWizzard?.reactorConfig.tabs.currentTab]);

    const getIdColumnConfig = useMemo(() => {
        return tabsConfig[currentTabIndex]?.grid.items
            .find(item => item.fieldConfig?.columnName === 'Id')?.fieldConfig;
    }, [tabsConfig, currentTabIndex]);

    const handleCancel = useCallback(() => {
        if (detailConfigurationStore.hasChanges) {
            props.close();
        } else {
            props.closeAll();
        }
        detailConfigurationStore.resetNewObjectConfiguration();
    }, [props.close, props.closeAll]);


    const handleSave = useCallback(async () => {
        if (getIdColumnConfig) {
            const column: Item = {
                id: getIdColumnConfig.columnId as string,
                name: getIdColumnConfig.columnName,
                displayValue: getIdColumnConfig.columnTitle
            }
            detailConfigurationStore.saveNewObject(column);
            props.close();
            if (!await detailConfigurationStore.isDetailSection()) {
                detailConfigurationStore.setValue(DetailStoreField.isVisibleFieldSettingsButton, true);
            }
            props.onSave();
        }
    }, [props.close, tabsConfig, getIdColumnConfig, props.onSave]);

    const dialogFooterButtons = useMemo(() => {
        return (
            <>
                <Button
                    key="cancelButton"
                    caption="Отменить"
                    onClick={handleCancel}
                    style={ButtonStyle.Subtle}
                />
                <Button
                    key="saveButton"
                    caption="Сохранить"
                    onClick={handleSave}
                    style={ButtonStyle.Success}
                    isDisabled={detailConfigurationStore.isDidabledSaveNewObjButton}
                />
            </>
        );
    }, [detailConfigurationStore.isDidabledSaveNewObjButton]);

    const handleObjectChange = useCallback((value: string) => {
        detailConfigurationStore.setValue(DetailStoreField.newObjectTitle, value);
    }, []);

    const handleObjectFocusOut = useCallback(() => {
        validateRequired(detailConfigurationStore.newObjectTitle, detailConfigurationStore.validation.newObjectTitle);
        detailConfigurationStore.validNewObjectDetail();
    }, []);

    const handleDetailTitleChange = useCallback((value: string) => {
        detailConfigurationStore.setValue(DetailStoreField.newDetailTitle, value);
    }, []);

    const handleDetailTitleFocusOut = useCallback(() => {
        validateRequired(detailConfigurationStore.newDetailTitle, detailConfigurationStore.validation.newDetailTitle);
        detailConfigurationStore.validNewObjectDetail();

    }, []);

    const handleDetailNameChange = useCallback((value: string) => {
        detailConfigurationStore.setValue(DetailStoreField.newDetailName, UpFirst(value));
    }, []);

    const handleDetailNameFocusOut = useCallback(() => {
        validateSchema(detailConfigurationStore.newDetailName, detailConfigurationStore.validation.newDetailName);
        detailConfigurationStore.validNewObjectDetail();
    }, []);

    const handleObjectNameChange = useCallback((value: string) => {
        detailConfigurationStore.setValue(DetailStoreField.newObjectName, UpFirst(value));
    }, []);

    const handleObjectNameFocusOut = useCallback(() => {
        validateSchema(detailConfigurationStore.newObjectName, detailConfigurationStore.validation.newObjectName);
        detailConfigurationStore.validNewObjectDetail();
    }, []);

    const mainContent = useMemo(() => {
        return (
            <>
                <Text className={styles.sectionTitle}>Основное</Text>
                <div className={styles.fields}>
                    <Field
                        name="newObjectTitle"
                        caption="Заголовок объекта"
                        required
                        promptText='Название новой таблицы для детали'
                        className={styles.detailField}
                    >
                        <Input
                            value={detailConfigurationStore.newObjectTitle}
                            placeholder=''
                            onChangeValue={handleObjectChange}
                            isInvalid={detailConfigurationStore.validation.newObjectTitle.isInvalid}
                            invalidMessage={detailConfigurationStore.validation.newObjectTitle.error}
                            onFocusOut={handleObjectFocusOut}
                            inputStyle={InputStyleName.Base}

                        />
                    </Field>
                    <Field
                        name="newDetailTitle"
                        caption="Заголовок детали"
                        required
                        promptText='Название детали, которое будет отображаться на странице записи'
                        className={styles.detailField}
                    >
                        <Input
                            value={detailConfigurationStore.newDetailTitle}
                            placeholder=''
                            onChangeValue={handleDetailTitleChange}
                            isInvalid={detailConfigurationStore.validation.newDetailTitle.isInvalid}
                            invalidMessage={detailConfigurationStore.validation.newDetailTitle.error}
                            onFocusOut={handleDetailTitleFocusOut}
                            inputStyle={InputStyleName.Base}

                        />
                    </Field>
                    <Field
                        name="newDetailName"
                        caption="Системное название детали"
                        required
                        promptText='Название, которое будет отображаться в базе данных'
                        className={styles.detailField}
                    >
                        <Input
                            value={detailConfigurationStore.newDetailName}
                            placeholder=''
                            onChangeValue={handleDetailNameChange}
                            isInvalid={detailConfigurationStore.validation.newDetailName.isInvalid}
                            invalidMessage={detailConfigurationStore.validation.newDetailName.error}
                            onFocusOut={handleDetailNameFocusOut}
                            inputStyle={InputStyleName.Base}

                        />
                    </Field>
                    <Field
                        name="newObjectName"
                        caption="Системное название нового объекта"
                        required
                        promptText='Название, которое будет отображаться в базе данных'
                        className={styles.detailField}
                    >
                        <Input
                            value={detailConfigurationStore.newObjectName}
                            placeholder=''
                            onChangeValue={handleObjectNameChange}
                            isInvalid={detailConfigurationStore.validation.newObjectName.isInvalid}
                            invalidMessage={detailConfigurationStore.validation.newObjectName.error}
                            onFocusOut={handleObjectNameFocusOut}
                            inputStyle={InputStyleName.Base}

                        />
                    </Field>
                </div>
            </>
        );
    }, [detailConfigurationStore.newObjectTitle, detailConfigurationStore.validation.newObjectTitle.isInvalid,
    detailConfigurationStore.newDetailTitle, detailConfigurationStore.validation.newDetailTitle.isInvalid,
    detailConfigurationStore.newDetailName, detailConfigurationStore.validation.newDetailName.isInvalid,
    detailConfigurationStore.newObjectName, detailConfigurationStore.validation.newObjectName.isInvalid]);

    const handleLookupChange = useCallback((value: Item | null) => {
        detailConfigurationStore.setValue(DetailStoreField.linkWithLookup, value);
    }, []);

    const handleLookupFocusOut = useCallback(() => {
        validateRequired(detailConfigurationStore.linkWithLookup, detailConfigurationStore.validation.linkWithLookup, true)
        detailConfigurationStore.validNewObjectDetail();
    }, []);

    const handleLookupColumnTitleChange = useCallback((value: string) => {
        detailConfigurationStore.setValue(DetailStoreField.newLookupColumnTitle, value);
    }, []);

    const handleLookupColumnTitleFocusOut = useCallback(() => {
        validateRequired(detailConfigurationStore.newLookupColumnTitle, detailConfigurationStore.validation.newLookupColumnTitle);
        detailConfigurationStore.validNewObjectDetail();
    }, []);

    const handleLookupColumnNameChange = useCallback((value: string) => {
        detailConfigurationStore.setValue(DetailStoreField.newLookupColumnName, UpFirst(value));
    }, []);

    const handleLookupColumnNameFocusOut = useCallback(() => {
        validateSchema(detailConfigurationStore.newLookupColumnName, detailConfigurationStore.validation.newLookupColumnName);
        detailConfigurationStore.validNewObjectDetail();
    }, []);

    const handleLookupListLoad = useCallback(async (value: string | null) => {
        try {
            await detailConfigurationStore.loadAllObjects();
            if (detailConfigurationStore.lookupList) {
                const items: Item[] = [];
                if (value) {
                    detailConfigurationStore.lookupList.forEach((lookup: any) => {
                        const displayValue = lowerCase(lookup.displayValue);
                        if (displayValue.includes(lowerCase(value)))
                            items.push(lookup);
                    });
                    detailConfigurationStore.setValue(DetailStoreField.lookupList, items);
                    return items.length
                }
                else return detailConfigurationStore.lookupList.length
            }
            else return -1
        }
        catch (error) {
            console.error(error)
        }
    }, [detailConfigurationStore.lookupList]);

    const linkWithLookup = useMemo(() => {
        return (
            <>
                <Text className={styles.sectionTitle}>Как связать деталь с текущей страницей</Text>
                <div className={styles.fields}>
                    <Field
                        name="linkWithLookup"
                        caption="Связать по справочнику"
                        required
                        promptText='Таблица, с которой будет связана новая деталь'
                        className={styles.detailField}
                    >
                        <Dropdown
                            isInput
                            items={detailConfigurationStore.lookupList}
                            value={detailConfigurationStore.linkWithLookup}
                            onChangeValue={handleLookupChange}
                            onItemsLoad={handleLookupListLoad}
                            isInvalid={detailConfigurationStore.validation.linkWithLookup.isInvalid}
                            invalidMessage={detailConfigurationStore.validation.linkWithLookup.error}
                            onFocusOut={handleLookupFocusOut}
                            selectStyle={SelectStyleName.Base}
                        />
                    </Field>
                    <Field
                        name="newLookupColumnTitle"
                        caption="Заголовок справочной колонки"
                        required
                        promptText='Название справочной колонки, которое будет отображаться на странице записи'
                        className={styles.detailField}
                    >
                        <Input
                            value={detailConfigurationStore.newLookupColumnTitle}
                            placeholder=''
                            onChangeValue={handleLookupColumnTitleChange}
                            isInvalid={detailConfigurationStore.validation.newLookupColumnTitle.isInvalid}
                            invalidMessage={detailConfigurationStore.validation.newLookupColumnTitle.error}
                            onFocusOut={handleLookupColumnTitleFocusOut}
                            inputStyle={InputStyleName.Base}

                        />
                    </Field>
                    <Field
                        name="newLookupColumnName"
                        caption="Системное название справочной колонки"
                        required
                        promptText='Название, которое будет отображаться в базе данных'
                        className={styles.detailField}
                    >
                        <Input
                            value={detailConfigurationStore.newLookupColumnName}
                            placeholder=''
                            onChangeValue={handleLookupColumnNameChange}
                            isInvalid={detailConfigurationStore.validation.newLookupColumnName.isInvalid}
                            invalidMessage={detailConfigurationStore.validation.newLookupColumnName.error}
                            onFocusOut={handleLookupColumnNameFocusOut}
                            inputStyle={InputStyleName.Base}

                        />
                    </Field>
                </div>
            </>
        );
    }, [detailConfigurationStore.lookupList,
    detailConfigurationStore.newLookupColumnName, detailConfigurationStore.validation.newLookupColumnName.isInvalid,
    detailConfigurationStore.newLookupColumnTitle, detailConfigurationStore.validation.newLookupColumnTitle.isInvalid,
    detailConfigurationStore.linkWithLookup, detailConfigurationStore.validation.linkWithLookup.isInvalid]);

    const dialogContent = useMemo(() => {
        return (
            <>
                <div className={styles.fieldType}>
                    <Text className={styles.popupNewDetailTitle}>
                        {detailConfigurationStore.header}
                    </Text>
                </div>
                <div className={styles.fieldsBlock} style={{ marginBottom: `28px` }}>
                    {mainContent}
                    <ToggleBlock />
                    {linkWithLookup}
                </div>
            </>
        );
    }, [detailConfigurationStore.header, mainContent, linkWithLookup]);

    return (
        <div className={styles.dialog}>
            <div className={styles.headerModal}>
                <span className={styles.titleModal}>Настройки новой детали</span>
                <CloseMaxi
                    className={styles.closeButton}
                    onClick={handleCancel}
                />
            </div>
            <div className={styles.dialogBody}>
                {dialogContent}
                <div className={styles.dialogFooter}>
                    {dialogFooterButtons}
                </div>
            </div>
        </div>
    );
});
export default NewObjectConfiguration