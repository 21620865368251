import { useCallback, useEffect, useState } from "react";

import { dispatcher } from "store";
import { ModalData } from "./modal-viewer";

import classNames from "classnames";
import styles from "./modal-viewer.module.css";

export function NotificationLeft(props: ModalData) {
	const [isOpen, setIsOpen] = useState<boolean>(true);
	const [timer, setTimer] = useState<NodeJS.Timeout | null>();

	const styleName = classNames(`${styles.notification} ${styles.left}`, {
		[`${styles.closeNotification} `]: !isOpen,
	});

	const handleClick = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (props.allowDefaultClick) {
			if (timer) {
				clearTimeout(timer);
			}
			setIsOpen(false);
			dispatcher.modal.remove(props.id);
		}
	}, [timer]);

	const handleMouseEnter = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (props.allowTimer) {
			if (timer) {
				clearTimeout(timer);
			}
			setIsOpen(true);
		}
	}, [timer]);

	const handleMouseLeave = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (props.allowTimer) {
			setTimer(setTimeout(() => {
				setIsOpen(false);
				setTimeout(() => {
					dispatcher.modal.remove(props.id);
				}, 5000);
			}, 3000));
			if (timer) {
				clearTimeout(timer);
			}
		}
	}, [timer]);

	useEffect(() => {
		if (props.allowTimer) {
			if (isOpen) {
				setTimer(setTimeout(() => {
					setIsOpen(false);
					setTimeout(() => {
						dispatcher.modal.remove(props.id);
					}, 5000);
				}, 3000));
			}
			if (timer) {
				clearTimeout(timer);
			}
		}
	}, [isOpen]);

	return (
		<div
			{...props}
			className={styleName}
			onClick={handleClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{props.layout}
		</div>
	);
};

export function NotificationCenter(props: ModalData) {
	const [isOpen, setIsOpen] = useState<boolean>(true);
	const [timer, setTimer] = useState<NodeJS.Timeout | null>();

	const styleName = classNames(`${styles.notification} ${styles.center}`, {
		[`${styles.closeNotification} `]: !isOpen,
	});

	const handleClick = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (props.allowDefaultClick) {
			if (timer) {
				clearTimeout(timer);
			}
			setIsOpen(false);
			dispatcher.modal.remove(props.id);
		}
	}, [timer]);

	const handleMouseEnter = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (props.allowTimer) {
			if (timer) {
				clearTimeout(timer);
			}
			setIsOpen(true);
		}
	}, [timer]);

	const handleMouseLeave = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (props.allowTimer) {
			setTimer(setTimeout(() => {
				setIsOpen(false);
				setTimeout(() => {
					dispatcher.modal.remove(props.id);
				}, 5000);
			}, 3000));
			if (timer) {
				clearTimeout(timer);
			}
		}
	}, [timer, props.allowTimer]);

	useEffect(() => {
		if (props.allowTimer) {
			if (isOpen) {
				setTimer(setTimeout(() => {
					setIsOpen(false);
					setTimeout(() => {
						dispatcher.modal.remove(props.id);
					}, 6000);
				}, 4000));
			}
			if (timer) {
				clearTimeout(timer);
			}
		}
	}, [isOpen, props.allowTimer]);

	const styleNameBackdrop = classNames({
		[`${styles.backdropWhite}`]: props.withBackdrop,
	});

	return (<>
		<div className={styleNameBackdrop} />
		<div
			className={styleName}
			onClick={handleClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{props.layout}
		</div>
	</>

	);
};