import { v4 } from "uuid";

import { store } from "store";

import { ModalData } from "features/modals/viewer/modal-viewer";

export class ModalController {
	add = (modal : ModalData) => {
		// TODO Создать modal store для modal controller
		store.modals.push({ id: modal.id, modal: modal  });
		return modal.id;
	};
	remove = (id: string) => {
		store.modals = store.modals.filter((modal) => modal.id !== id);
	};
}

export const modalController = new ModalController();