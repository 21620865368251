import { uniq } from "lodash";
import { toJS } from "mobx";

import { dispatcher } from "store/store-dipatcher";

import { DetailResult, ResultType, StageFullInfo, StageSetting, Stages } from "types/entity";


export class StageModels {
    /**
     * @description Метод Возвращает все элементы Array<StageFullInfo>.
     */
    getAll(): Array<StageFullInfo> {
        const result: StageFullInfo[] = [];
        const stages = toJS(dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stages)
        const detailResult = toJS(dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.detailResults);
        const stageSettings = toJS(dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stageSettings);
        const movingRules = toJS(dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.movingRules);

        stages?.forEach((stage: Stages) => {
            result.push(stage as StageFullInfo);
        });
        detailResult?.forEach((detail: DetailResult) => {
            const stage = result.find((stage: any) => stage.id === detail.stageId);
            if (stage) {
                stage.detailResultText = detail.detailResultText;
            }
        });
        stageSettings?.forEach((setting: StageSetting) => {
            const stage = result.find((stage: any) => stage.id === setting.stageId);
            if (stage) {
                stage.color = setting.color;
                stage.isHidden = setting.isHidden;
                stage.order = setting.order;
                stage.resultType = setting.resultType;
            }
        });
        result?.forEach((stage) => {
            const stagesFrom: Array<string> = [];
            movingRules?.forEach(rule => {
                if (rule.stageToId === stage.id) {
                    stagesFrom.push(rule.stageFromId)
                }
            });
            const stagesTo: Array<string> = [];
            movingRules?.forEach(rule => {
                if (rule.stageFromId === stage.id) {
                    stagesTo.push(rule.stageToId)
                }
            });
            uniq(stagesFrom);
            uniq(stagesTo);
            stage.stagesFrom = stagesFrom;
            stage.stagesTo = stagesTo;
        });
        return result;
    };

    /**
     * @description Метод Возвращает все поля со значениями stageSettings.isHidden === true.
     * @param type - определяет вернуть только finally, только simply или всё при непереданном аргументе.
     */
    getOnlyHide(type?: ResultType) {
        return this.getAll()?.filter((stageFullInfo: StageFullInfo) => stageFullInfo.isHidden && stageFullInfo.resultType === type);
    };

    /**
     * @description Метод Возвращает все поля со значениями stageSettings.isHidden === false.
     * @param isIntermediate - определяет вернуть только finally, только simply или всё при непереданном аргументе.
     */
    getOnlyVisibly(isIntermediate?: boolean) {
        return this.getAll()?.filter((stageFullInfo: StageFullInfo) => !stageFullInfo.isHidden && (isIntermediate ? stageFullInfo.resultType === null : stageFullInfo.resultType !== null));
    };

    /**
     * @description Метод Возвращает StageFullInfo поля по id.
     * @param id - id стадии.
     */
    getById(id: string) {
        return this.getAll()?.filter((stageFullInfo: StageFullInfo) => stageFullInfo.id === id);
    };
}

export const stageModels = new StageModels();