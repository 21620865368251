import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useParams, Params } from "react-router";
import { useToggle } from "usehooks-ts";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { synchroiser } from "synchroiser";
import { dispatcher, sessionStore, store } from "store";
import { systemDesigner } from "store/store-dipatcher/dispatchers/system-designer/system-designer";

import { Button, ButtonStyle } from "components";
import { SectionWizzardHeader } from "./components";
import ConstructorMainPanel from "./pages/constructor/constructor-main-panel/constructor-main-panel";
import { SectionWizzardLeftPanel, PreviewFullView } from "pages/section-wizzard/components";
import GlobalSettingsPanel from "./pages/global-settings/global-settings";
import { StageModel } from "./pages/stage-model";
import ProgressBar from "../../features/full-view/files/progress-bar/progress-bar";
import { AccessRights } from "./pages/access-rights";
import { NotFoundPage } from "pages/error-page/error-page";

import { Item } from "types";
import { OptionPage } from "types/entity";
import { Position, modalController } from "features/modals";
import SectionRoute from "entities/SectionRoute";
import { getGlobalSectionWizzardValidation } from "./validation/validation";

import styles from "./section-wizzard.module.css";

export enum SectionWizzardMode {
	NewSection = "create",
	ExistSection = "edit"
}

type SectionWizzardParams = Params & {
	mode: SectionWizzardMode;
	entityName: string;
}

const SectionWizzard = observer(() => {
	const [selected, setSelected] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(true);
	const [isViewing, isViewingToggle, setViewing] = useToggle(false);
	const [idLoadingNotification] = useState<string>(v4());
	const [idLoadedNotification] = useState<string>(v4());
	const [idModal] = useState<string>(v4());
	const { mode, entityName } = useParams<SectionWizzardParams>();
	const modalRemoveTimeout = useRef<NodeJS.Timeout>();
	const modalRemoveTimeoutSwitch = useRef<NodeJS.Timeout>();
	const [warningOptions, setWarningOptions] = useState<OptionPage[]>([]);
	const [clickedSave, setClickedSave] = useState<boolean>(false);

	const navigate = useNavigate();

	const circle = useMemo(() => {
		return (
			<div className={styles.loaderWrapper}>
				<div className={styles.rotate}>
					<svg className={styles.circleLoader} viewBox="0 0 40 40">
						<circle
							className={styles.circle}
							cx="20"
							cy="20"
							r="15"
							fill="none"
							stroke={`var(--color-indigo-600)`}
							strokeWidth="2">
						</circle>
					</svg>
				</div>

			</div>
		);
	}, []);

	const layoutNotificationViewing = useMemo(() => {
		return (
			<>
				{circle}
				<div className={styles.noCursor}>Подготовка предварительного просмотра...</div>
			</>
		);
	}, [circle]);

	const layoutNotificationViewed = useMemo(() => {
		return (
			<>
				<div className={styles.notification}>
					<div className={styles.noCursor}>Предварительный просмотр настроен</div>
				</div>
			</>
		);
	}, []);

	const layoutNotificationSaving = useMemo(() => {
		return (
			<>
				{circle}
				<div className={styles.notificationClose}>Сохранение настроек раздела...</div>
			</>
		);
	}, [circle]);

	const layoutNotificationSavedWithError = useMemo(() => {
		return (
			<>
				<div className={styles.notificationClose}>Не удалось сохранить настройки раздела</div>
				<div className={styles.verticalDivider}></div>
				<div className={styles.notificationCancel} onClick={() => { }}>Подробнее</div>
			</>
		);
	}, []);
	/**
	  * @description Confirm при обновлении/закрытии страницы
	  */
	const beforeUnloadHandler = (event: any) => {
		const confirmationMessage = "Вы уверены, что хотите покинуть страницу? Ваши несохраненные изменения могут быть потеряны.";
		event.returnValue = confirmationMessage;
		return confirmationMessage;
	};

	useEffect(() => {
		if (entityName) {
			switchNewSection();
		}
		// TODO ПОКА УВЕДОМЛЕНИЕ О ЗАКРЫТИИ ОКНА, УДАЛИТЬ
		// if (mode && Object.values(SectionWizzardMode).includes(mode)) {
		// 	window.addEventListener("beforeunload", beforeUnloadHandler);
		// }
		// return () => {
		// 	window.removeEventListener("beforeunload", beforeUnloadHandler);
		// };
	}, []);

	const switchNewSection = useCallback(async () => {
		if (mode && !Object.values(SectionWizzardMode).includes(mode)) {
			throw new Error(`Invalid mode: ${mode}`);
		}
		const entity = dispatcher.entity.get();
		if (entity?.isNew) {
			await synchroiser.getSectionsList();
		}
		if (mode === SectionWizzardMode.NewSection) {
			await synchroiser.switchSectionWizzard(v4());
		} else {
			await synchroiser.switchSectionWizzard(entityName!);
		}

		if (dispatcher.entity.get()?.entity.sectionWizzard?.optionPage)
			setSelected(dispatcher.entity.get()?.entity.sectionWizzard?.optionPage!);
	}, [dispatcher.entity.get()]);

	useEffect(() => {
		if (isViewing) {
			if (!isPreviewLoading) {
				modalController.modalRemove(idLoadingNotification);
				modalController.notificationAdd({
					id: idLoadedNotification,
					position: Position.CENTER,
					layout: layoutNotificationViewed,
					allowTimer: true
				});

			}
			else {
				modalController.modalRemove(idLoadedNotification);
				modalController.notificationAdd({ id: idLoadingNotification, position: Position.CENTER, layout: layoutNotificationViewing });
			}
		}
	}, [isPreviewLoading]);

	const goToSection = () => {
		navigate(`/${SectionRoute.SinglePage}/${dispatcher.entity?.get()?.entity.sectionWizzard?.systemName.toLowerCase()}`)
	}

	const layoutNotificationSaved = useMemo(() => {
		const handleGoToSection = () => {
			clearTimeout(modalRemoveTimeout.current);
			clearTimeout(modalRemoveTimeoutSwitch.current);
			goToSection();
		};
		return (
			<>
				<div className={styles.notificationClose}>Настройки раздела сохранены</div>
				<div className={styles.verticalDivider}></div>
				<div className={styles.notificationCancel} onClick={handleGoToSection}>Перейти в раздел</div>
			</>
		);
	}, []);

	const modalRemove = useCallback(() => {
		modalRemoveTimeout.current = setTimeout(() => {
			modalController.modalRemove(idModal);
			modalController.notificationAdd({ id: idModal, position: Position.CENTER, layout: layoutNotificationSaved, allowDefaultClick: true, allowTimer: true });
			if (dispatcher.entity?.get()?.isNew) {
				modalRemoveTimeoutSwitch.current = setTimeout(() => {
					window.location.replace(`/sectionMaster/${SectionWizzardMode.ExistSection}/${dispatcher.entity?.get()?.entity.sectionWizzard?.systemName.toLowerCase()}`);
				}, 4000);
			}
		}, 4000);
	}, []);

	const closeWarning = useCallback(() => {
		modalController.modalRemove(idModal);
	}, [idModal]);

	const notValidLayout = useMemo(() => {
		return (
			<div className={styles.dialog}>
				<div className={styles.header}>
					<span className={styles.title}>Внимание</span>
				</div>
				<div className={styles.dialogBody}>
					<span>Проверьте, заполнены ли поля:</span>
					<ul className={styles.listWarning}>
						<li>«Название», «Системное название» в общих настройках</li>
						<li>«Название записи» в конструкторе</li>
						<li>«Название стадии» в стадийной модели, если вы настраивали стадийную модель</li>
					</ul>

				</div>
				<div className={styles.dialogFooter}>
					<Button
						caption="Закрыть"
						onClick={closeWarning}
						style={ButtonStyle.Primary} />
				</div>
			</div>
		);
	}, []);

	const handleClickSaveButton = useCallback(async () => {
		setClickedSave(true);
		const warnings = getGlobalSectionWizzardValidation();
		setWarningOptions(warnings);
		if (warnings.length <= 0) {
			window.removeEventListener("beforeunload", beforeUnloadHandler);
			modalController.notificationAdd({
				id: idModal,
				position: Position.CENTER,
				layout: layoutNotificationSaving,
				withBackdrop: true
			});
			let create;
			let update;
			const entity = dispatcher.entity?.get();

			if (entity?.isNew) {
				create = await systemDesigner.createNewSection();
			} else {
				update = await systemDesigner.updateSection();
			}

			if (create?.data.success) {
				const newEntity = { ...dispatcher.entity.get()!, id: create.data.data };
				store.entities.splice(-1);
				dispatcher.entity.set(newEntity);
				dispatcher.entity.switchById(create.data.data);
				sessionStore.setEntities(store.entities);
				modalRemove();
			} else {
				if (update?.data.success) {
					modalRemove();
				} else {
					modalController.modalRemove(idModal);
					modalController.notificationAdd({
						id: idModal,
						position: Position.CENTER,
						layout: layoutNotificationSavedWithError,
						allowDefaultClick: true,
						allowTimer: true
					});
				}
			}
			await synchroiser.getSectionsList()
		} else {
			modalController.popupAdd({ id: idModal, position: Position.CENTER, layout: notValidLayout });
		}
	}, [idModal, layoutNotificationSaving, modalRemove, layoutNotificationSavedWithError, notValidLayout, toJS(warningOptions)]);

	const checkWarningForSetting = useCallback(() => {
		if (clickedSave) {
			const warnings = getGlobalSectionWizzardValidation();
			setWarningOptions(warnings);
		}
		else {
			setWarningOptions([]);
		}
	}, [clickedSave]);

	const mainPanel = useMemo(() => {
		switch (selected) {
			case (OptionPage.GlobalSettings): {
				return <GlobalSettingsPanel checkWarningForSetting={checkWarningForSetting} />;
			}
			case (OptionPage.Constructor): {
				return <ConstructorMainPanel checkWarningForSetting={checkWarningForSetting} />;
			}
			case (OptionPage.AccessRights): {
				return <AccessRights />;
			}
			case (OptionPage.StageModel): {
				return <StageModel />;
			}
			default: {
				return <></>;
			}
		}
	}, [selected, checkWarningForSetting]);

	const onChangeSelected = useCallback(
		(value: Item | null) => {
			const templateEntity = dispatcher.entity.get();
			if (templateEntity?.entity.sectionWizzard?.optionPage === value?.id) {
				return;
			}
			setSelected(value!.id.toString());
			if (templateEntity?.entity) {
				dispatcher.entity.setBasicEntity(templateEntity.entity);
			}
			dispatcher.sectionWizzard.setOptionPage(value!.id as OptionPage);
		}, [dispatcher.entity.get()?.entity?.sectionWizzard?.optionPage, dispatcher.entity.get()]
	);

	const getWarningForSetting = useCallback((option: OptionPage) => {
		if (warningOptions.includes(option))
			return true
		else return false
	}, [toJS(warningOptions)]);

	const sectionSettings: Item[] = useMemo(() => {
		return [{
			id: OptionPage.GlobalSettings,
			name: "Общие настройки",
			isWarning: getWarningForSetting(OptionPage.GlobalSettings),
		},
		{
			id: OptionPage.Constructor,
			name: "Конструктор",
			isWarning: getWarningForSetting(OptionPage.Constructor),
		},
		{
			id: OptionPage.AccessRights,
			name: "Права доступа",
		}]
	}, [toJS(warningOptions)]);

	const stagesConstructor = useMemo(() => {
		return [{
			id: OptionPage.StageModel,
			name: 'Стадийная модель',
			isWarning: getWarningForSetting(OptionPage.StageModel),
		}]
	}, [toJS(warningOptions)]);

	const contentPanel = useMemo(() => {
		if (mode && !Object.values(SectionWizzardMode).includes(mode)) {
			return <NotFoundPage />
		}
		if (isLoading && mode && Object.values(SectionWizzardMode).includes(mode)) {
			return <ProgressBar text="Подождите, идёт загрузка раздела" />;
		}
		else {
			if (isViewing) {
				if (isPreviewLoading) modalController.notificationAdd({ id: idLoadingNotification, position: Position.CENTER, layout: layoutNotificationViewing });
				return <PreviewFullView
					sectionWizzard={dispatcher.entity.get()?.entity.sectionWizzard}
					isPreviewLoading={isPreviewLoading}
					setIsPreviewLoading={setIsPreviewLoading}
				/>;
			}
			else {
				return <div className={styles.sectionWizzardBody}>
					<SectionWizzardLeftPanel
						sectionSettingsItems={sectionSettings}
						stagesConstructorItems={stagesConstructor}
						selected={selected}
						onChangeValue={onChangeSelected}
						useLocalStorage={true}
						localStorageKey={"openedLeftPanel"}
					/>
					{mainPanel}
				</div>;
			}

		}
	}, [isLoading, selected, isViewing, mainPanel,
		isPreviewLoading, toJS(sectionSettings), toJS(stagesConstructor)
	]);

	useEffect(() => {
		if (dispatcher.entity.get()?.entity.sectionWizzard) {
			setIsLoading(false);
		}
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	return (
		<div className={styles.wrapper}>
			<SectionWizzardHeader
				isViewing={isViewing}
				isViewingToggle={isViewingToggle}
				beforeUnloadHandler={beforeUnloadHandler}
				saveClick={handleClickSaveButton}
			/>
			{contentPanel}
		</div>
	);

});

export default SectionWizzard;

