import {api} from "api";

type Request = Promise<{data: {data: {
            canbanColumn: null;
            isLookup: boolean;
            isSection: boolean;
            records: Array<{
                displayValue: string;
                id: string;
                name: string;
            }>
            error: string | null;
            success: boolean;
        }}}>

export function getListWithColumns(entityName: string): Request {
    return api.http.httpApi.entity.recordsListWithColumns().post({
        canbanColumn: null,
        columnNames: [],
        filter: null,
        offset: 0,
        limit: 99, // TODO Хард код
        staticGroupId: null,
        entityName: entityName,
        sort: null,
    }) as Request;
}