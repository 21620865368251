import Api from "api/http/api";

class Cache extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/Cache";
	}

	getCache = () => {
		this.setPath(`${this.basicPath}/get`);
		return this;
	};
	dataBase = () => {
		this.setPath(`${this.basicPath}/database`);
		return this;
	};
	checkConnect = () => {
		this.setPath(`${this.basicPath}/check-connect`);
		return this;
	};
	tests = () => {
		this.setPath(`${this.basicPath}/tests`);
		return this;
	};
	removeAll = () => {
		this.setPath(`${this.basicPath}/remove-all`);
		return this;
	};
}

const cache = new Cache();

export { cache };