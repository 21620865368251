import { makeAutoObservable } from "mobx";

// import api from "app/services/api";
import { sortArray } from "app/services/SortService";
import ISort from "entities/ISort";
import ListStore from "entities/ListStore";
import { LookupInfo } from "entities/lookupInfo/LookupInfo";
import { ColumnTypeName, ILookupData, ILookupInfo } from "../data/Fields";
import { Item } from "types";
import { FieldValidationState, validateRequired, validateSchema } from "entities/Validation";
import { api } from "api";

class LookupMainPanelStore {
    nameLookup: string = "";
    lookups: ILookupData[] = [];
    dialogFillingLookups: Item[] = [];
    usingDialogFillingLookups: ILookupData[] = [];
    lookupStore: ListStore | undefined;
    step: number = 1;
    fields: LookupInfo[] = [];
    firstField: ILookupInfo = {
        id: '0',
        columnName: 'Name',
        columnTitle: 'Название',
        columnType: "String",
        isLookup: false,
        isRequired: true
    };
    validate: boolean = true;



	constructor() {
		makeAutoObservable(this);
	}
	setLookups(data: ILookupData[]) {
		this.lookups = data;
	}

	setIdInLookupColumns() {
		this.lookups.forEach((lookup) => {
			lookup.entityInfo.forEach((column) => {
				if (column.isLookup) {
					if (!column.columnName.endsWith("Id"))
						column.columnName += "Id";

				}
			});

		});
	}

	async loadLookups(sort?: ISort) {
		try {
			// const url = "/api/Entity/entitylookupdatalist";
			// let data = await api.get(url).then(res => res.data);
			const response = await api.http.httpApi.entity.entitylookupdatalist().get();
			let data = response.data.data;
			if (data) {
				if (sort) {
					data = sortArray(data, sort.columnPath, sort.direction);
					this.setLookups(data);
				}
				else this.setLookups(data);
				this.setIdInLookupColumns();

			} else {
				console.error(data.message);
			}

		} catch (error: any) {
			console.error("An error occurred:", error);
		} finally {

		}
	}

	validateFields(): boolean {
		this.validate = true;
		if (this.firstField.columnTitle === "") {
			this.validate = false;
		}
		else this.fields.map((field) => {
			if (!field.validate(this.fields)) {
				this.validate = false;
			}
		});
		return this.validate;
	}

	disabledSaveButton(): boolean {
		this.validate = true;
		const valid: FieldValidationState = { isInvalid: false, error: "" };
		if (this.firstField.columnTitle === "")
			this.validate = false;

		else this.fields.map((field) => {
			if (!validateRequired(field.columnType, valid, true, true))
				this.validate = false;
			else if (field.columnType === ColumnTypeName.Guid) {
				if (field.lookupTable) {
					if (!validateRequired(field.lookupTable, valid, true, true))
						this.validate = false;
				}
				else this.validate = false;
			}
			else if (!validateRequired(field.columnTitle, valid))
				this.validate = false;
			else if (!validateSchema(field.columnName, valid, this.fields))
				this.validate = false;

		});
		return !this.validate;
	}

	setLookupStore(nameStore: string) {
		this.lookupStore = new ListStore(nameStore);
	}

	setValue(value: string | number | boolean | [] | any[], fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	changeValueInFields(value: string | boolean, fieldName: string, index: number) {
		Reflect.set(this.fields.at(index) as Object, fieldName, value);
		this.disabledSaveButton();
	}

	changeValueInFirstField(value: string) {
		this.firstField.columnTitle = value;
		this.disabledSaveButton();
	}
	addItem() {
		const newField = new LookupInfo();
		newField.setValue((lookupMainPanelStore.fields.length + 1).toString(), "id");
		newField.setValue(false, "isLookup");
		newField.resetValidate();
		this.fields.push(newField);
		this.disabledSaveButton();
	}

	deleteItem(field: any) {
		const newList: any[] = [];
		this.fields.map((elem) => {
			if (elem.id !== field.id) {
				newList.push(elem);
			}
		});
		this.setValue(newList, "fields");
		this.disabledSaveButton();
		return newList;
	}

	fromLookupsToIItem(lookups: any[]) {
		this.dialogFillingLookups = [];
		let newLookups: any[] = [];
		let newItem = {
			id: "",
			name: "",
			entityName: ""
		};
		lookups.map((lookup) => {
			newItem = {
				id: lookup.id,
				name: lookup.entityTitle,
				entityName: lookup.entityName
			};

			newLookups.push(newItem);

		});
		this.dialogFillingLookups = newLookups;
		this.validateFields();

	}
	getLookupByEntityName(entityName: string) {
		let result: ILookupData = {
			id: "",
			entityName: "",
			entityTitle: "",
			entityInfo: []
		};
		this.lookups.forEach(lookup => {
			if (entityName === lookup.entityName)
				result = lookup;
		});
		return result;
	}
	getUsingDialogFillingLookups() {
		this.usingDialogFillingLookups = [];
		this.fields.map((field) => {
			if (field.lookupTable)
				this.usingDialogFillingLookups.push(this.getLookupByEntityName(field.lookupTable.entityName));
		});
		return;

	}
	deleteUsingLookupFromList() {
		this.getUsingDialogFillingLookups();
		let newLookups: any[] = [];

		const s = new Set(this.usingDialogFillingLookups);
		newLookups = this.lookups.filter(e => !s.has(e));
		this.fromLookupsToIItem(newLookups);
	}
	async checkExistEntityName(tableName?: string) {
		try {
			// const url = `/api/Entity/check-exist-entityname?tableName=${tableName}`;
			// const data = await api.get(url);
			const response = await api.http.httpApi.entity.checkExistEntityName(tableName!).get();
			let data = response.data;
			if (data.success) {
				return data.data;
			} else {
				console.error(data.message);
			}

		} catch (error: any) {
			console.error("An error occurred:", error);
		} finally { }
	}


}

const lookupMainPanelStore = new LookupMainPanelStore();

export default lookupMainPanelStore;