import { InfoIcon } from "assets/icons";

import styles from "./input-header.module.scss";

interface InputHeaderProps {
    name: string;
    caption: string;
    required?: boolean;
    infoCaption?: string
};

export const InputHeader = (props: InputHeaderProps) => {
	return (
		<label className={styles.captionTextHorizontal} htmlFor={props.name} >
			<span>{props.caption} {props.required && <span className={styles.star}>*</span>}</span>
			{props.infoCaption &&
          		<div className={styles.promptTool} >
          			<span className={styles.tooltip}>
          				<span> {props.infoCaption} </span>
          			</span>
          			<InfoIcon className={styles.promtIcon} />
          		</div>
			}
		</label>
	);
};