
import { Route, Routes, useParams } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./router";
import { observer } from "mobx-react-lite";
import authStore from "AuthStore";

const AppRouter = observer(() => {
	return (

		authStore.loggedIn ?
			<Routes>
				{privateRoutes.map(route =>
					<Route path={route.path} element={route.component} />
				)}
			</Routes>
			:
			<Routes>
				{publicRoutes.map(route =>
					<Route path={route.path} element={route.component} />
				)}
			</Routes>
	);
});

export default AppRouter;

