import { action, autorun } from "mobx";
import { dispatcher, store } from "store";

export class RowController {
	get = () => {
		return dispatcher.entity.get()?.entity?.rows.find((row: any) => {
			return row.id === store.currentRowId;
		});
	};
	set = action((fieldName: string, value: any) => {
		store.hasChanges = true;
		const cell = this.get()!;
		cell[fieldName] = value;
		store.setChangedField(fieldName, value);		
	});
	add = () => { console.log(store.currentRowId); };
	reset = () => {
		store.currentRowId = "";
		store.resetChangedFields();
	};
	switch = (id: string) => {
		store.currentRowId = id;
		store.resetChangedFields();
	};
	rollback =  action("rollback",() => {
		const index = dispatcher.entity.get()!.entity!.rows.findIndex(row => row.id === store.currentRowId);
		dispatcher.entity.get()!.entity!.rows[index] = { ...store.oldRowValue };
		store.hasChanges = false;
	});
}

export const rowController = new RowController();