import classNames from "classnames";
import Button, { IButtonProps } from "../button/button";

import styles from "./tooltip.module.css";

export interface TooltipProps extends React.HTMLAttributes<HTMLElement> {
    tooltip: string;
}

function Tooltip(props: TooltipProps) {
    const tooltipButtonClassName = classNames(styles.tooltipButton, {
        [`${props.className}`]: props.className
    });

    return (
        <div className={tooltipButtonClassName}>
            <span className={styles.tooltip}>
                {props.tooltip}
            </span>
            {props.children}
        </div>
    );
}

export default Tooltip;