import { observer } from "mobx-react";
import { useEffect, useState } from "react";

import { dispatcher, store } from "store";
import { synchroiser } from "synchroiser";

import SectionHead from "features/section-head/section-head";
import Field from "components/field/field";
import Grid from "components/grid/grid";
import InputPhone from "components/input/input-phone";
import { Item } from "types";
import Input from "components/input/input";

import FilterColumnType from "entities/ColumnType";
import { Contact, default as contactEntity } from "entities/contact/Contact";
import { validateEmail, validatePhoneNumber, validateRequired } from "entities/Validation";
import { contactStore } from "entities/contact/ContactsSectionStore";
import SectionRoute from "entities/SectionRoute";
import SearchSelect from "components/select/search-select/search-select";
import { InputStyleName } from "components/input/input-styles";
import { SelectStyleName } from "components/select/select-styles";
import Entity, { IEntityStore } from "entities/Entity";

import styles from "./contacts.module.css";


const Contacts = () => {

    const [entity, setEntity] = useState<Entity<IEntityStore>>();

    useEffect(() => {
        setEntity(new Entity<Contact>(new Contact()));
    }, []);

    useEffect(() => {
        synchroiser.switchSection("contact");
    }, [store.sections]);

    return (
        entity ?
            <SectionHead
                pageTitle={'Контакты'}
                grid={<Grid columns={columns} listStore={contactStore} entity={contactEntity} />}
                entity={entity}
                dialogTitle={'контакт'}
                dialogContent={<ContactDialogFilling entity={entity} />}
                new={() => { entity.new(new Contact()); }}
                listStore={contactStore}
                columns={columns}
            />
            :
            <></>
    )
};


export const ContactDialogFilling = observer((props: { entity: Entity<IEntityStore> }) => {
    let entity = props.entity as Entity<Contact>;
    return (
        <>
            {entity.isNew &&
                <>
                    <Field name={"surName"} caption={"Фамилия"} required={true}>
                        <Input
                            value={entity.entity.surName}
                            placeholder={""}
                            onChangeValue={(value: string) => {
                                entity.entity.setValue(value, "surName");
                            }}
                            inputStyle={InputStyleName.Base}
                            isInvalid={entity.entity.validation.surName.isInvalid}
                            invalidMessage={entity.entity.validation.surName.error}
                            onFocusOut={() => validateRequired(entity.entity.surName, entity.entity.validation.surName)}
                        />
                    </Field>
                    <div className={styles.combination}>
                        <Field name={"givenName"} caption={"Имя"} required={true} className={styles.input}>
                            <Input
                                value={entity.entity.givenName}
                                placeholder={""}
                                onChangeValue={(value: string) => {
                                    entity.entity.setValue(value, "givenName");
                                }}
                                inputStyle={InputStyleName.Base}
                                isInvalid={entity.entity.validation.givenName.isInvalid}
                                invalidMessage={entity.entity.validation.givenName.error}
                                onFocusOut={() => validateRequired(entity.entity.givenName, entity.entity.validation.givenName)}
                            />
                        </Field>
                        <Field name={"middleName"} caption={"Отчество"} className={styles.input}>
                            <Input
                                value={entity.entity.middleName}
                                placeholder={""}
                                onChangeValue={(value: string) => {
                                    entity.entity.setValue(value, "middleName");
                                }}
                                inputStyle={InputStyleName.Base}
                            />
                        </Field>
                    </div>
                    <div className={styles.combination}>
                        <Field name={"phone"} caption={"Мобильный телефон"} required={true} className={styles.input}>
                            <InputPhone
                                value={entity.entity.phone}
                                placeholder={"+7 (123) 456-78-90"}
                                onChangeValue={(value: string) => {
                                    entity.entity.setValue(value, "phone");
                                }}
                                inputStyle={InputStyleName.Base}
                                isNeedCorrect={true}
                                isInvalid={entity.entity.validation.phone.isInvalid}
                                invalidMessage={entity.entity.validation.phone.error}
                                onFocusOut={() => validatePhoneNumber(entity.entity.phone, entity.entity.validation.phone)}
                            />
                        </Field>
                        <Field name={"city"} caption={"Город"} required={true} className={styles.input}>
                            <SearchSelect
                                items={entity.lookups['City']}
                                isInput={true}
                                onItemsLoad={async (s) => {
                                    return await entity.loadLookups('City', s, null, false)
                                }}
                                value={entity.entity.city as Item}
                                isInvalid={entity.entity.validation.city.isInvalid}
                                invalidMessage={entity.entity.validation.city.error}
                                onChangeValue={(value: any) => {
                                    entity.entity.setValue(value, "city");
                                }}
                                onFocusOut={() => validateRequired(entity.entity.city, entity.entity.validation.city, true)}
                                inputStyle={InputStyleName.BaseWithoutBorder}
                                selectStyle={SelectStyleName.Base}
                            />
                        </Field>
                    </div>
                    <Field name={"email"} caption={"Email"} required={true}>
                        <Input
                            value={entity.entity.email}
                            placeholder={"example@web.com"}
                            onChangeValue={(value: string) => {
                                entity.entity.setValue(value, "email");
                            }}
                            isNeedCorrect={true}
                            isInvalid={entity.entity.validation.email.isInvalid}
                            invalidMessage={entity.entity.validation.email.error}
                            onFocusOut={() => validateEmail(entity.entity.email, entity.entity.validation.email)}
                            inputStyle={InputStyleName.Base}

                        />
                    </Field>
                    <Field name={"role"} caption={"Роль"} required={true}>
                        <SearchSelect
                            items={entity.lookups['ContactRole']}
                            isInput={true}
                            onItemsLoad={async (s) => {
                                return await entity.loadLookups('ContactRole', s, null, true)
                            }}
                            value={entity.entity.role as Item}
                            isInvalid={entity.entity.validation.role.isInvalid}
                            invalidMessage={entity.entity.validation.role.error}
                            onChangeValue={(value: any) => {
                                entity.entity.setValue(value, "role");
                            }}
                            onFocusOut={() => validateRequired(entity.entity.role, entity.entity.validation.role, true)}
                            inputStyle={InputStyleName.BaseWithoutBorder}
                            selectStyle={SelectStyleName.Base}
                        />
                    </Field>
                    <Field name={"position"} caption={"Должность"}>
                        <Input
                            value={entity.entity.position}
                            placeholder={""}
                            onChangeValue={(value: string) => { entity.entity.setValue(value, "position"); }}
                            inputStyle={InputStyleName.Base}
                        />
                    </Field>
                </>
            }
        </>
    );
});

export const ContactsDialogLeads = observer((props: { entity: Entity<Contact> }) => {
    return (
        <>
            <Field name={"surName"} caption={"Фамилия"} required={true}>
                <Input
                    value={props.entity.entity.surName}
                    placeholder={""}
                    onChangeValue={(value: string) => {
                        props.entity.entity.setValue(value, "surName");
                    }}
                    inputStyle={InputStyleName.Base}
                    isNeedCorrect={true}
                    isInvalid={props.entity.entity.validation.surName.isInvalid}
                    onFocusOut={() => validateRequired(props.entity.entity.surName, props.entity.entity.validation.surName)}
                />
            </Field>
            <div className={styles.combination}>
                <Field name={"givenName"} caption={"Имя"} required={true} className={styles.input}>
                    <Input
                        value={props.entity.entity.givenName}
                        placeholder={""}
                        onChangeValue={(value: string) => {
                            props.entity.entity.setValue(value, "givenName");
                        }}
                        inputStyle={InputStyleName.Base}
                        isNeedCorrect={true}
                        isInvalid={props.entity.entity.validation.givenName.isInvalid}
                        onFocusOut={() => validateRequired(props.entity.entity.givenName, props.entity.entity.validation.givenName)}
                    />
                </Field>
                <Field name={"middleName"} caption={"Отчество"} className={styles.input}>
                    <Input
                        value={props.entity.entity.middleName}
                        placeholder={""}
                        onChangeValue={(value: string) => {
                            props.entity.entity.setValue(value, "middleName");
                        }}
                        inputStyle={InputStyleName.Base}
                        isNeedCorrect={true}

                    />
                </Field>
            </div>

            <div className={styles.combination}>
                <Field name={"phone"} caption={"Мобильный телефон"} required={true} className={styles.input}>
                    <InputPhone
                        value={props.entity.entity.phone}
                        placeholder={"+7 (123) 456-78-90"}
                        onChangeValue={(value: string) => {
                            props.entity.entity.setValue(value, "phone");
                        }}
                        inputStyle={InputStyleName.FullViewInput}
                        isNeedCorrect={true}
                        isInvalid={props.entity.entity.validation.phone.isInvalid}
                        onFocusOut={() => validatePhoneNumber(props.entity.entity.phone, props.entity.entity.validation.phone)}
                    />
                </Field>
                <Field name={"city"} caption={"Город"} required={true} className={styles.input}>
                    <SearchSelect
                        items={props.entity.lookups['City']}
                        isInput={true}
                        onItemsLoad={async (s) => {
                            return await props.entity.loadLookups('City', s, null, false)
                        }}
                        value={props.entity.entity.city as Item}
                        isInvalid={props.entity.entity.validation.city.isInvalid}
                        invalidMessage={props.entity.entity.validation.city.error}
                        onChangeValue={(value: any) => {
                            props.entity.entity.setValue(value, "city");
                        }}
                        onFocusOut={() => validateRequired(props.entity.entity.city, props.entity.entity.validation.city, true)}
                        inputStyle={InputStyleName.BaseWithoutBorder}
                        selectStyle={SelectStyleName.Base}
                    />
                </Field>
            </div>
            <Field name={"email"} caption={"Email"} required={true}>
                <Input
                    value={props.entity.entity.email}
                    placeholder={"example@web.com"}
                    onChangeValue={(value: string) => {
                        props.entity.entity.setValue(value, "email");
                    }}
                    isNeedCorrect={true}
                    inputStyle={InputStyleName.Base}
                    isInvalid={props.entity.entity.validation.email.isInvalid}
                    onFocusOut={() => validateEmail(props.entity.entity.email, props.entity.entity.validation.email)}

                />
            </Field>
            <Field name={"role"} caption={"Роль"} required={true}>
                <SearchSelect
                    items={props.entity.lookups['ContactRole']}
                    isInput={true}
                    onItemsLoad={async () => {
                        return await props.entity.loadLookups('ContactRole', undefined, null, true);
                    }}
                    value={props.entity.entity.role}
                    isInvalid={props.entity.entity.validation.role.isInvalid}
                    onFocusOut={() => validateRequired(props.entity.entity.role, props.entity.entity.validation.role)}
                    onChangeValue={(value) => {
                        props.entity.entity.setValue(value!, "role");
                    }}
                    inputStyle={InputStyleName.BaseWithoutBorder}
                    selectStyle={SelectStyleName.Base}
                />
            </Field>
        </>
    );
});

const columns = [
    {
        name: "name",
        caption: "ФИО",
        width: 26,
        type: FilterColumnType.String,
        getLink: (id: string) => `${id}`,
        sortColumn: "name"
    },
    {
        name: "phone",
        caption: "Мобильный телефон",
        width: 18,
        type: FilterColumnType.MobilePhone,
        sortColumn: "phone"
    },
    {
        name: "email",
        caption: "Email",
        width: 18,
        type: FilterColumnType.String,
        sortColumn: "email"
    },
    {
        name: "primaryAccount",
        caption: "Контрагент",
        width: 19,
        type: FilterColumnType.Lookup,
        getLink: (id: string) => `/section/${SectionRoute.Account}/${id}`,
        sortColumn: "primaryAccount.name"
    },
    {
        name: "city",
        caption: "Город",
        width: 10,
        type: FilterColumnType.Lookup,
        sortColumn: "city.name"
    },
    {
        name: "createdOn",
        caption: "Дата создания",
        width: 9,
        type: FilterColumnType.Date,
        sortColumn: "createdOn"
    },

];


export default Contacts;