import { DeleteIcon, Export, Import, ViewHistory } from "assets/icons";
import { Item } from "types";


export enum ActionEnums {
    ViewHistory,
    Import,
    Export,
    CancelChanges
}

export const actionsSectionWizzard:Item[] = [
    {
        id: ActionEnums.ViewHistory,
        name: "Показать историю изменений",
        icon: <ViewHistory />,
        isSeparator: true
    },
    {
        id: ActionEnums.Import,
        name: "Импортировать из файла...",
        icon: <Import />
    },
    {
        id: ActionEnums.Export,
        name: "Экспортировать",
        icon: <Export />,
        isSeparator: true
    },
    {
        id: ActionEnums.CancelChanges,
        name: "Сбросить текущие изменения",
        icon: <DeleteIcon />,
        isRed: true
    },
]