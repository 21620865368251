import classNames from "classnames";

import { binding, bindingInXml } from "module/reactor/binding/binding";

import { Button, ButtonStyle, Select } from "components";
import IProps from "module/reactor/components/IProps";

import { ArrowToDownMini, ArrowToRightStretch, GoBack } from "assets/icons";

import styles from "../full-view/full-view.module.css";


interface FullViewProps extends IProps {
    entityTitle: string;
    viewColumn: string;
}

function PreviewFullView(props: FullViewProps) {
    const entityTitle = binding(props.services, props.entityTitle);
    const viewColumn = bindingInXml(props.services, props.viewColumn);
    return (
        <div className={styles.fullView}>
            <div className={classNames(styles.fullViewHeader, styles.fullViewTitlePreview)}>
                <div className={styles.fullViewTitle}>
                    <span className={styles.fullViewTitleSection}>{entityTitle}</span>
                    <ArrowToRightStretch />
                    <span className={styles.fullViewTitleDetail} >
                        {viewColumn}
                    </span>
                </div>
            </div>
            <div className={styles.fullViewContent}>
                {props.children}
            </div>
        </div>
    );
}

export default PreviewFullView;
