import { useCallback, useState } from "react";
import { observer } from "mobx-react";

import {  Button, ButtonStyle, Select } from "components";

import { Item } from "types";

import { ArrowToDown, Plus } from "assets/icons";

import styles from "./detail-drop-down.module.css";


export interface IDetailDropDownProps {
    caption: string;
    iconCaption?: JSX.Element;
    body: JSX.Element | null;
    items?: Item[] | null;
    onChangeValue?: (value: Item | null) => void;
    onAddClick?: () => void;
    isVisibleAddButton?: boolean;
    classNameBody?: string
    isOpened?: boolean
}

const DetailDropDown = observer((props: IDetailDropDownProps) => {
    const [isOpened, setOpened] = useState(props.isOpened ?? false);

    let firstIconClassNames = ``;
    let classNameHeader = `${styles.detailDropDownHeader} `;
    let classNameBody = `${styles.detailDropDownBody} `;
    if (isOpened) {
        firstIconClassNames += `${styles.rotate} `;
        classNameBody += `${styles.visible} `;
        classNameHeader += `${styles.openedHeader} `;
    }
    else classNameHeader += `${styles.notOpenedHeader} `;

    const onClickToHeader = useCallback(() => {
        setOpened(!isOpened)
    }, [isOpened])

    const onAddClick = useCallback(() => {
        props.onAddClick!();
        setOpened(true);
    }, [props.onAddClick])

    if (props.classNameBody)
        classNameBody += props.classNameBody
    return (
        <div className={styles.detailDropDown}>
            <div className={classNameHeader} onClick={onClickToHeader}>
                <Button firstIcon={<ArrowToDown className={styles.arrow} />} style={ButtonStyle.Icon} classNameFirstIcon={firstIconClassNames} className={styles.dropDown} onClick={() => { setOpened(!isOpened) }} />
                <span className={styles.caption}>{props.caption}</span>
                {props.iconCaption}
                {props.isVisibleAddButton &&
                    <Button
                        firstIcon={<Plus className={styles.addButton} />}
                        style={ButtonStyle.Icon}
                        className={styles.addButton}
                        onClick={onAddClick}
                    />
                }
                {(props.items && props.onChangeValue) &&
                    <Select
                        items={props.items}
                        onChangeValue={props.onChangeValue}
                        name={"Добавить..."}
                        styles={ButtonStyle.Link}
                        className={styles.select}
                        classNameButton={styles.selectButton}
                        isPositionLeft={true}
                    />
                }
            </div>
            <div className={classNameBody}>
                {props.body ??
                    <span className={styles.emptyBody}>Нет данных</span>
                }
            </div>
        </div>
    )
}

);

export default DetailDropDown;