class SignalRManager {
	private static instance: SignalRManager;
	private activeConnections: Map<string, signalR.HubConnection> = new Map();

	private constructor() { }

	public static getInstance(): SignalRManager {
		if (!SignalRManager.instance) {
			SignalRManager.instance = new SignalRManager();
		}
		return SignalRManager.instance;
	}

	public addConnection(key: string, connection: signalR.HubConnection): void {
		this.activeConnections.set(key, connection);
	}

	public removeConnection(key: string): void {
		const connection = this.activeConnections.get(key);
		if (connection) {
			connection.stop();
			this.activeConnections.delete(key);
		}
	}
	public hasConnection(key: string): boolean {
		return this.activeConnections.has(key);
	}
	public getConnection(key: string): signalR.HubConnection | undefined {
		return this.activeConnections.get(key);
	}
}

export default SignalRManager;