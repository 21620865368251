import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { isNull, lowerCase } from "lodash";
import { v4 } from "uuid";

import { store } from "store";

import { modalController } from "features/modals";
import detailConfigurationStore, { DetailStoreField } from "./detail-configuration-store";
import { validateRequired } from "entities/Validation";
import { UpFirst } from "entities/lowFirst";

import {
	Button, ButtonStyle, Dropdown, Field, Input,
	InputStyleName, Select, SelectStyleName, Text,
} from "components";
import ExistObjectConfiguration from "./exist-object/exist-object-configuration";
import NewObjectConfiguration from "./new-object/new-object-configuration";
import { DetailMaster } from "features/detail-master";

import { IGridItem } from "components/select/types";
import { Item } from "types";
import { ColumnType } from "entities/ColumnType";
import { ModalType } from "features/modals/viewer/modal-viewer";
import { GridItem } from "types/entity";

import {
	ArrowToDown, CloseMaxi, ExistsObjectDetail,
	LookupTypeIcon, NewObjectDetail
} from "assets/icons";
import TypeIcon from "features/type-icon/type-icon";

import styles from "./detail-configuration.module.css";

const DetailConfiguration = observer((props: { detailId?: string, close: () => void, closeAll: () => void, position: { cellX: number, cellY: number } }) => {
	const [idModal] = useState<string>(v4());
	const [idDetailMaster] = useState<string>(v4());
	const [isSaveNewDetail, setSaveNewDetail] = useState<boolean>(false);

	const createDetail: Item[] = [
		{ id: "newObj", name: "По новому объекту", icon: <NewObjectDetail /> },
		{ id: "existsObj", name: "По существующему объекту", icon: <ExistsObjectDetail /> }
	];
	
	useEffect(() => {
		detailConfigurationStore.setValue(DetailStoreField.detailId, props.detailId);
		detailConfigurationStore.loadAll();
	}, []);

	useEffect(() => {
		detailConfigurationStore.setValueWithoutTrackingChanges("x", props.position.cellX);
		detailConfigurationStore.setValueWithoutTrackingChanges("y", props.position.cellY);
	}, [props.position.cellX, props.position.cellY]);

	const closeFuncWithOutConfirm = useCallback(() => {
		modalController.modalRemove(idModal);
	}, []);

	const handleSaveClick = useCallback(async () => {
		if (props.detailId) {
			detailConfigurationStore.updateConfigForDetail(props.detailId);
		} else {
			await detailConfigurationStore.createConfigForDetail();
		}

		props.closeAll();
	}, [props.closeAll, detailConfigurationStore.detailTitle]);

	const handleClose = useCallback(() => {
		if (detailConfigurationStore.hasChanges) {
			props.close();
		} else {
			props.closeAll();
		}

	}, [props.close]);

	const closeDetailMasterModal = useCallback(() => {
		modalController.modalRemove(idDetailMaster);
		store.options.isDisabledConstructorInSectionWizzard = false;
	}, []);

	const getLinkColumn = useCallback(() => {
		const findedLookup = detailConfigurationStore.lookupList.find(lookup => lookup.name === detailConfigurationStore.virtualDetail?.entityLinkColumn);

		let linkField: IGridItem = {
			name: "",
			displayValue: ""
		};
		if (findedLookup) {
			linkField = {
				gridItemId: v4(),
				columnId: v4(),
				name: detailConfigurationStore.detailColumn?.name!,
				displayValue: findedLookup.displayValue ?? findedLookup.title ?? detailConfigurationStore.detailColumn?.name!,
				icon: <TypeIcon type={ColumnType.Lookup} />
			}
		}
		return linkField
	}, [detailConfigurationStore.virtualDetail?.entityLinkColumn, detailConfigurationStore.detailColumn]);

	const saveGridForDetail = useCallback((grid: GridItem[]) => {
		detailConfigurationStore.setValue(DetailStoreField.inner, grid);
		closeDetailMasterModal();
		detailConfigurationStore.validDetail();
	}, []);

	const handleFieldSettingsButtonClick = useCallback(async () => {
		store.options.isDisabledConstructorInSectionWizzard = true;
		let gridItem: GridItem = {
			x: -1,
			y: 0,
			gridItemId: "",
			width: 0,
			height: 0
		};

		if (props.detailId) {
			const config = detailConfigurationStore.getDetailConfigForOldDetail();
			modalController.popupAdd({
				id: idDetailMaster,
				type: ModalType.LARGE_POPUP,
				layout: <DetailMaster
					close={closeDetailMasterModal}
					detailConfig={config}
					boxFields={detailConfigurationStore.boxFields}
					onSave={saveGridForDetail}
				/>,
				closeFunc: closeDetailMasterModal
			});
		}
		else {
			const column = getLinkColumn();
			if (column.gridItemId) {
				gridItem = await detailConfigurationStore.getGridItemForNewDetail(column);
			}
			else {
				gridItem = await detailConfigurationStore.getGridItemForNewDetail();
			}
			modalController.popupAdd({
				id: idDetailMaster,
				type: ModalType.LARGE_POPUP,
				layout: <DetailMaster
					close={closeDetailMasterModal}
					detailConfig={gridItem.detailConfig}
					boxFields={detailConfigurationStore.boxFields}
					onSave={saveGridForDetail}
				/>,
				closeFunc: closeDetailMasterModal
			})
		}

	}, [idDetailMaster, getLinkColumn, detailConfigurationStore.boxFields]);

	const handleSaveConfigWithNewLookup = useCallback(() => {
		detailConfigurationStore.resetNewObjectConfiguration();
		detailConfigurationStore.validDetail();
		detailConfigurationStore.setDefaultColumnId();
		setSaveNewDetail(true);
	}, []);

	const handleSaveConfigWithExistLookup = useCallback(() => {
		detailConfigurationStore.resetExistObjectConfiguration();
		detailConfigurationStore.validDetail();
		setSaveNewDetail(false);
	}, []);

	const dialogFooterButtons = useMemo(() => {
		return (
			<>
				<Button
					key="fieldSettings"
					caption="Настроить поля в детали"
					onClick={handleFieldSettingsButtonClick}
					style={ButtonStyle.LightBlue}
					isVisible={detailConfigurationStore.isVisibleFieldSettingsButton}
				/>
				<div className={styles.rightFooterButtons}>
					<Button
						key="cancelButton"
						caption="Отменить"
						onClick={handleClose}
						style={ButtonStyle.Subtle}
					/>
					<Button
						key="saveButton"
						caption="Сохранить"
						onClick={handleSaveClick}
						style={ButtonStyle.Success}
						isDisabled={detailConfigurationStore.isDidabledSaveDetailButton}
					/>
				</div>
			</>
		);
	}, [detailConfigurationStore.isDidabledSaveDetailButton, detailConfigurationStore.isVisibleFieldSettingsButton]);


	const handleCreateClick = useCallback((value: Item | null) => {
		if (value) {
			detailConfigurationStore.setValue(DetailStoreField.header, value.name);
			if (value?.id === "existsObj") {
				modalController.popupAdd({
					id: idModal,
					layout: <ExistObjectConfiguration
						close={closeFuncWithOutConfirm}
						closeAll={props.closeAll}
						onSave={handleSaveConfigWithExistLookup}
					/>,
					closeFunc: closeFuncWithOutConfirm
				});
			}
			else {
				modalController.popupAdd({
					id: idModal,
					layout: <NewObjectConfiguration
						close={closeFuncWithOutConfirm}
						closeAll={props.closeAll}
						onSave={handleSaveConfigWithNewLookup}
					/>,
					closeFunc: closeFuncWithOutConfirm
				});
			}
		}
	}, []);

	const handleDetailChange = useCallback((value: Item | null) => {
		detailConfigurationStore.setValue(DetailStoreField.detail, value);
		if (!isNull(value) && value.name) {
			detailConfigurationStore.loadColumns(value.name);
		}
		setSaveNewDetail(false);
	}, [detailConfigurationStore.columnList]);

	const handleDetailFocusOut = useCallback(async () => {
		validateRequired(detailConfigurationStore.detail, detailConfigurationStore.validation.detail, true);
		detailConfigurationStore.validFieldSettingsButton();
		detailConfigurationStore.validDetail();
	}, []);

	const handleDetailTitleChange = useCallback((value: string) => {
		detailConfigurationStore.setValue(DetailStoreField.detailTitle, value);
	}, []);

	const handleDetailTitleFocusOut = useCallback(() => {
		detailConfigurationStore.validateDetailTitle();
		detailConfigurationStore.validFieldSettingsButton();
		detailConfigurationStore.validDetail();
	}, []);

	const handleDetailNameChange = useCallback((value: string) => {
		detailConfigurationStore.setValue(DetailStoreField.detailName, UpFirst(value));
	}, []);

	const handleDetailNameFocusOut = useCallback(async () => {
		await detailConfigurationStore.validateDetailSystemName();
		detailConfigurationStore.validFieldSettingsButton();
		detailConfigurationStore.validDetail();
	}, []);

	const handleDetailItemsLoad = useCallback(async (value: string | null) => {
		try {
			await detailConfigurationStore.loadAllObjects();
			if (detailConfigurationStore.detailList) {
				const items: Item[] = [];
				if (value) {
					detailConfigurationStore.detailList.forEach((item: any) => {
						const displayValue = lowerCase(item.displayValue);
						if (displayValue.includes(lowerCase(value)))
							items.push(item);
					});
					detailConfigurationStore.setValue(DetailStoreField.detailList, items);
					return items.length
				}
				else return detailConfigurationStore.detailList.length
			}
			else return -1
		}
		catch (error) {
			console.error(error)
		}
	}, [detailConfigurationStore.detailList]);

	const mainContent = useMemo(() => {
		return (
			<div className={styles.fieldsBlock}>
				<Text className={styles.sectionTitle}>Основное</Text>
				<div className={styles.fields}>
					<div className={styles.detailBlock}>
						<Field
							key="detail"
							name="detail"
							caption="Деталь"
							required
							promptText='Список всех объектов в системе, не являющихся справочниками'
							className={styles.detailField}
						>
							<Dropdown
								isInput
								items={detailConfigurationStore.detailList}
								value={detailConfigurationStore.detail}
								onChangeValue={handleDetailChange}
								onItemsLoad={handleDetailItemsLoad}
								isInvalid={detailConfigurationStore.validation.detail.isInvalid}
								invalidMessage={detailConfigurationStore.validation.detail.error}
								onFocusOut={handleDetailFocusOut}
								selectStyle={SelectStyleName.Base}
								isDisabled={detailConfigurationStore.isEditExistDetail}
							/>
						</Field>
						<div className={styles.createDetailBlock}>
							{!props.detailId &&
								<Select
									items={createDetail}
									onChangeValue={handleCreateClick}
									placeholder=""
									value={{ id: "newDetail", name: "Создать новую деталь" }}
									secondIcon={<ArrowToDown />}
									styles={ButtonStyle.LightBlue}
								/>
							}
							<span className={styles.detailInfo}>Что такое «Деталь»?</span>
						</div>
					</div>
					<Field
						name="detailTitle"
						caption="Заголовок детали"
						required
						promptText='Название детали, которое будет отображаться на странице записи'
						className={styles.detailField}
					>
						<Input
							value={detailConfigurationStore.detailTitle}
							placeholder=''
							onChangeValue={handleDetailTitleChange}
							isInvalid={detailConfigurationStore.validation.detailTitle.isInvalid}
							isNotUnique={detailConfigurationStore.validation.detailTitle.isNotUnique}
							invalidMessage={detailConfigurationStore.validation.detailTitle.error}
							onFocusOut={handleDetailTitleFocusOut}
							inputStyle={InputStyleName.Base}
						/>
					</Field>
					<Field
						name="detailName"
						caption="Системное название детали"
						required
						promptText='Уникальный идентификатор детали в системе'
						className={styles.detailField}
					>
						<Input
							value={detailConfigurationStore.detailName}
							placeholder=''
							onChangeValue={handleDetailNameChange}
							isInvalid={detailConfigurationStore.validation.detailName.isInvalid}
							isNotUnique={detailConfigurationStore.validation.detailName.isNotUnique}
							invalidMessage={detailConfigurationStore.validation.detailName.error}
							onFocusOut={handleDetailNameFocusOut}
							inputStyle={InputStyleName.Base}
							isDisabled={detailConfigurationStore.isEditExistDetail}
						/>
					</Field>
				</div>
			</div>
		);
	}, [detailConfigurationStore.isEditExistDetail, detailConfigurationStore.detailList, detailConfigurationStore.detail,
	detailConfigurationStore.detailTitle, detailConfigurationStore.detailName,
	detailConfigurationStore.validation.detail.isInvalid, detailConfigurationStore.validation.detailTitle.isInvalid,
	detailConfigurationStore.validation.detailName.isInvalid, props.detailId]);

	const handleDetailColumnChange = useCallback((value: Item | null) => {
		detailConfigurationStore.setValue(DetailStoreField.detailColumn, value);
	}, []);

	const handleDetailColumnFocusOut = useCallback(() => {
		validateRequired(detailConfigurationStore.detailColumn, detailConfigurationStore.validation.detailColumn);
		detailConfigurationStore.validDetail();
	}, []);

	const handleSectionColumnChange = useCallback((value: Item | null) => {
		detailConfigurationStore.setValue(DetailStoreField.entityColumn, value);
	}, []);

	const handleEntityColumnFocusOut = useCallback(() => {
		validateRequired(detailConfigurationStore.entityColumn, detailConfigurationStore.validation.entityColumn);
		detailConfigurationStore.validDetail();
	}, []);

	const handleDetailColumnsLoad = useCallback(async (value: string | null) => {
		try {
			if (detailConfigurationStore.detail) {
				await detailConfigurationStore.loadColumns(detailConfigurationStore.detail.name);
				const items: Item[] = [];
				if (value) {
					detailConfigurationStore.columnDetailList.forEach((item: any) => {
						const displayValue = lowerCase(item.displayValue);
						if (displayValue.includes(lowerCase(value)))
							items.push(item);
					});
					detailConfigurationStore.setValue(DetailStoreField.columnDetailList, items);
					return items.length
				}
				else return detailConfigurationStore.columnDetailList.length
			}
			else return -1
		}
		catch (error) {
			console.error(error)
		}
	}, [detailConfigurationStore.columnDetailList, detailConfigurationStore.detail]);

	const handleColumnsLoadFromNewDetail = useCallback((value: string | null, column: Item) => {
		detailConfigurationStore.loadColumnsFromNewLookup(column);
		const items: Item[] = [];
		if (value) {
			detailConfigurationStore.columnDetailList.forEach((item: any) => {
				const displayValue = lowerCase(item.displayValue);
				if (displayValue.includes(lowerCase(value)))
					items.push(item);
			});
			detailConfigurationStore.setValue(DetailStoreField.columnDetailList, items);
			return items.length
		}
		else return detailConfigurationStore.columnDetailList.length
	}, [detailConfigurationStore.columnDetailList]);

	const handleSectionColumnsLoad = useCallback(async (value: string | null) => {
		try {
			await detailConfigurationStore.loadSectionColumns();
			if (detailConfigurationStore.columnList) {
				const items: Item[] = [];
				if (value) {
					detailConfigurationStore.columnList.forEach((item: any) => {
						const displayValue = lowerCase(item.displayValue);
						if (displayValue.includes(lowerCase(value)))
							items.push(item);
					});
					detailConfigurationStore.setValue(DetailStoreField.columnList, items);
					return items.length
				}
				else return detailConfigurationStore.columnList.length
			}
			else return -1
		}
		catch (error) {
			console.error(error)
		}
	}, [detailConfigurationStore.columnList]);

	const handleDetailColumnLoad = useCallback((value: string | null) => {
		if (isSaveNewDetail) {
			handleColumnsLoadFromNewDetail(value, detailConfigurationStore.detailColumn!);
		}
		else {
			handleDetailColumnsLoad(value);
		}
	}, [isSaveNewDetail, detailConfigurationStore.entityColumn]);

	const moreContent = useMemo(() => {

		return (
			<div className={styles.fieldsBlock}>
				<Text className={styles.sectionTitle}>Какие записи показать на странице?</Text>
				<div className={styles.fields}>
					<Field
						key="detailColumn"
						name="detailColumn"
						caption="У которых колонка детали"
						required
						promptText='Список колонок в таблице детали. Выберите ту колонку, по которой нужно связать деталь и раздел'
						className={styles.detailField}
					>
						<Dropdown
							isInput
							items={detailConfigurationStore.columnDetailList}
							value={detailConfigurationStore.detailColumn}
							onChangeValue={handleDetailColumnChange}
							onItemsLoad={handleDetailColumnLoad}
							isInvalid={detailConfigurationStore.validation.detailColumn.isInvalid}
							invalidMessage={detailConfigurationStore.validation.detailColumn.error}
							onFocusOut={handleDetailColumnFocusOut}
							selectStyle={SelectStyleName.Base}
							isDisabled={detailConfigurationStore.isEditExistDetail}
						/>
					</Field>
					<Field
						key="sectionColumn"
						name="sectionColumn"
						caption="Равна колонке страницы"
						required
						className={styles.detailField}
					>
						<Dropdown
							isInput
							notTranslate
							items={detailConfigurationStore.columnList}
							value={detailConfigurationStore.entityColumn}
							onChangeValue={handleSectionColumnChange}
							onItemsLoad={handleSectionColumnsLoad}
							isInvalid={detailConfigurationStore.validation.entityColumn.isInvalid}
							invalidMessage={detailConfigurationStore.validation.entityColumn.error}
							onFocusOut={handleEntityColumnFocusOut}
							selectStyle={SelectStyleName.Base}
							isDisabled={detailConfigurationStore.isEditExistDetail}
						/>
					</Field>
				</div>
			</div>
		);
	}, [isSaveNewDetail, detailConfigurationStore.isEditExistDetail, detailConfigurationStore.columnDetailList, detailConfigurationStore.columnList,
		detailConfigurationStore.detailColumn, detailConfigurationStore.entityColumn,
		detailConfigurationStore.validation.detailColumn.isInvalid, detailConfigurationStore.validation.entityColumn.isInvalid]);

	const dialogContent = useMemo(() => {
		return (
			<>
				<div className={styles.fieldType}>
					<LookupTypeIcon />
					<Text className={styles.fieldTypeTitle}>
						Тип [Деталь]
					</Text>
				</div>
				<div className={styles.content}>
					{mainContent}
					{moreContent}
				</div>
			</>
		);
	}, [mainContent, moreContent]);

	return (
		<>
			{detailConfigurationStore.detailIsLoading && (
				<div className={styles.modalOverlay}>
					<svg className={styles.circleLoader} viewBox='0 0 50 50'>
						<circle
							className={styles.circle}
							cx='25'
							cy='25'
							r='20'
							fill='none'
							stroke={`var(--color-gray-300)`}
							strokeWidth='3'
						/>
					</svg>
				</div>
			)}
			<div className={styles.headerModal}>
				<span className={styles.titleModal}>Настройки новой детали</span>
				<CloseMaxi className={styles.closeButton} onClick={handleClose} />
			</div>
			<div className={styles.dialogBody}>
				{dialogContent}
				<div className={styles.dialogFooterWrapper}>
					{dialogFooterButtons}
				</div>
			</div>
		</>

	);
});

export default DetailConfiguration;