import Entity, { IEntityData, IEntityStore } from 'entities/Entity';
import { FieldValidationState, validateRequired, validateSchema } from 'entities/Validation';
import ImportStore from 'entities/import/ImportStore';
import { action, makeObservable, observable } from 'mobx';
import {UpFirst} from 'entities/lowFirst';

export interface IColumn {
    columnName: string;
    columnTitle: string;
    isLookup?: boolean;
    width?: number;
    sortColumn?: string;
    canSort?: boolean;
    value?: any
}

export class Lookup implements IEntityStore {
    schema: string = "";
    displaySchema: string = "";
    id: string = "";

    columns: IColumn[] = [];
    validation: { [key: string]: FieldValidationState };

    constructor() {
        makeObservable(this, {
            columns: observable,
            schema: observable,
            displaySchema: observable,

            validation: observable,
            setValue: action,
            validate: action,
            resetValidate: action,
            deserialize: action,
            setValueInColumn: action,
        })

        this.validation = {
            schema: {
                isInvalid: false
            },
            displaySchema: {
                isInvalid: false
            },

        }
    }

    setValueInColumn(value: any, fieldName: string) {
        this.columns.map((column: IColumn) => {
            if (column.columnName === fieldName) {
                column.value = value;
            }
        })
    }

    setValue(value: any, fieldName: string) {
        Reflect.set(this, fieldName, value);

    }

    // @action
    validate(): boolean {
        if (!validateRequired(this.displaySchema, this.validation.displaySchema, true)) {
            return false;
        }
        if (!validateSchema(this.schema, this.validation.schema)) {
            return false;
        }
        return true;
    }

    resetValidate() {
        this.validation = {
            displaySchema: {
                isInvalid: false
            }
        }
        this.validation = {
            schema: {
                isInvalid: false
            }
        }
    }

    // @action
    deserialize(p: any) {
        // this.id = p.id ?? "";     
        Reflect.ownKeys(p).map(async (item) => {
            let value = Reflect.get(p, item);
            this.setValueInColumn(value, UpFirst(item.toString()));
        });

    }

    serialize() {
        let values: IEntityData[] = []
        this.columns.filter(x => !x.columnName.endsWith('edOn')).map((column) => {
            if (column.value !== "" && column.value !== null && column.value !== undefined)
                values.push({
                    propertyName: column.columnName,
                    propertyValue: column.value
                })
        })

        return {
            entityName: this.schema!,
            values: values.filter(x => x)
        }
    }

    handleError(error: string): void {
        if (error === "name_is_already exist") {
            this.validation.schema.isInvalid = true;
            this.validation.schema.error = "Такое системное название уже существует";
        }
    }
}


const lookup = new Lookup();

const lookupEntity = new Entity<Lookup>(lookup);

export const lookupImportStore = new ImportStore(lookup.schema);

export default lookupEntity;
