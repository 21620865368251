import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { v4 } from "uuid";
import classNames from "classnames";

import { dispatcher, store } from "store";
import { modalController } from "features/modals";
import { FieldConfigurationWrapper, detailConfigurationStore, fieldConfigurationStore, fieldGroupConfigurationStore } from "pages/section-wizzard/pages/constructor";

import { Zone } from "modules/DND/zone";
import { Element } from "modules/DND/element";
import { multiTypeDragBlocks } from "./multi-type-drag-block";
import { Button, ButtonStyle } from "components";

import { ColumnType } from "entities/ColumnType";
import { GridItem, ItemType } from "types/entity";
import { Types } from "modules/DND/type";
import { DEFAULT_WARNING_TEXT } from "pages/section-wizzard/data/data";

import TypeIcon from "features/type-icon/type-icon";
import { DragIcon, Warning } from "assets/icons";

import styles from "./drag-zone.module.css";

export interface IDragElement {
	id: string;
	title: string;
	x: number;
	y: number;
	inner?: {
		items: Array<IDragElement>
	};
	width: number;
	height: number;
	type: any;
	fieldType: ItemType | undefined;
}

const CELL_HEIGHT = 48;

const POST_INIT_TIME = 500;

const MINIMAL_ROW_QUALITY = 11;

export const DragZone = observer(() => {
	const [rowQualityByGridList, setRowQualityByGridList] = useState(MINIMAL_ROW_QUALITY);
	const [rowQualityByScreen, setRowQualityByScreen] = useState(MINIMAL_ROW_QUALITY);

	const [cellLayoutWidth, setCellLayoutWidth] = useState(0);
	const [targetElementViewLayout, setTargetElementViewLayout] = useState<JSX.Element>(<></>);

	const [zoneId] = useState(v4());
	const [idConfirm] = useState<string>(v4());
	const [idModal] = useState<string>(v4());

	const container = useRef<HTMLDivElement>(null);

	const sectionWizard = useMemo(() => {
		return dispatcher.sectionWizzard.getSectionWizzard();
	}, [toJS(dispatcher.sectionWizzard.getSectionWizzard())]);

	const currentTabIndex = useMemo(() => {
		return sectionWizard?.reactorConfig.tabs.currentTab ?? 0;
	}, [sectionWizard?.reactorConfig.tabs.currentTab]);

	const tabsConfig = useMemo(() => {
		return sectionWizard?.reactorConfig.tabs.tabsConfig ?? [];
	}, [toJS(sectionWizard?.reactorConfig.tabs.tabsConfig)]);

	const grid = useMemo(() => {
		if (tabsConfig[currentTabIndex]) {
			return toJS(tabsConfig[currentTabIndex].grid.items);
		}
		else return [];
	}, [toJS(tabsConfig[currentTabIndex]?.grid.items)]);

	const isNew = useMemo(() => {
		return dispatcher.entity.get()?.isNew;
	}, [dispatcher.entity.get()?.isNew]);

	useEffect(() => {
		const updateContainerSize = () => {
			if (container.current) {
				setRowQualityByScreen((Math.floor(container.current.getBoundingClientRect().height / (CELL_HEIGHT))));
				setCellLayoutWidth(container.current.getBoundingClientRect().width / 2);
			}
		};
		updateContainerSize();
		window.addEventListener('resize', updateContainerSize);
		return () => {
			window.removeEventListener('resize', updateContainerSize);
		};
	}, []);

	useEffect(() => {
		if (grid.length > 0 && grid.find((item) => item.x !== -1 && item.y !== -1)) {
			const maxYElement = grid.reduce((maxYElement, currentElement) => maxYElement.y > currentElement.y ? maxYElement : currentElement);
			setRowQualityByGridList(maxYElement.y + 1);
		}
	}, [grid]);

	const handleDrop = useCallback(({
		elementId,
		sourceZone,
		targetZone,
		MATRIX
	}: {
		elementId: string | null;
		sourceZone: string | null;
		targetZone: string | null;
		type: Types;
		sourceData?: any;
		[key: string]: any;
	}) => {
		if (MATRIX.cellY === Math.max(rowQualityByGridList, rowQualityByScreen, MINIMAL_ROW_QUALITY)) {
			setRowQualityByGridList(Math.max(rowQualityByGridList, rowQualityByScreen, MINIMAL_ROW_QUALITY) + 1);
		}
		if (grid) {
			const inFieldGroup = grid.find(({ gridItemId, groupFieldsConfig }) => (gridItemId ?? groupFieldsConfig?.groupFieldId) === targetZone);

			if (inFieldGroup) {
				const dragElement = grid.find(({ gridItemId }) => (gridItemId) === elementId);
				const innerDragElement = inFieldGroup?.groupFieldsConfig?.inner?.items.find(({ gridItemId }) => gridItemId === elementId);
				if (dragElement) {
					const gridItem: GridItem = {
						...dragElement,
						x: MATRIX.cellX,
						y: MATRIX.cellY,
						gridItemId: dragElement?.gridItemId,
						width: dragElement?.width,
						height: dragElement?.height,
					};
					dispatcher.sectionWizzard.autoPut({
						tabIndex: dispatcher.sectionWizzard.getSectionWizzard()?.reactorConfig.tabs.currentTab ?? 0,
						targetId: targetZone || "",
						sourceId: sourceZone,
						item: gridItem
					});
				} else {
					if (innerDragElement) {
						const gridItem: GridItem = {
							...innerDragElement,
							x: MATRIX.cellX,
							y: MATRIX.cellY,
							gridItemId: innerDragElement?.gridItemId,
							width: innerDragElement?.width,
							height: innerDragElement?.height
						};
						dispatcher.sectionWizzard.autoPut({
							tabIndex: dispatcher.sectionWizzard.getSectionWizzard()?.reactorConfig.tabs.currentTab ?? 0,
							targetId: targetZone || "",
							sourceId: sourceZone,
							item: gridItem
						});
					}
				}

			} else {
				const dragElement = grid.find(({ gridItemId }) => gridItemId === elementId);
				if (dragElement) {
					const gridItem: GridItem = {
						...dragElement,
						x: MATRIX.cellX,
						y: MATRIX.cellY,
						gridItemId: dragElement?.gridItemId,
						width: dragElement?.width,
						height: dragElement?.height
					};
					dispatcher.sectionWizzard.autoPut({
						tabIndex: dispatcher.sectionWizzard.getSectionWizzard()?.reactorConfig.tabs.currentTab ?? 0,
						targetId: targetZone || "",
						sourceId: sourceZone,
						item: gridItem
					});
				}
			}
		}
	}, [grid, rowQualityByGridList, rowQualityByScreen]);

	const handleUp = useCallback(({ elementId }: { elementId: string }) => {

		if (grid) {
			const gridItem = grid.find((item) => item.gridItemId === elementId);
			if (gridItem) {
				const fieldClassName = classNames(styles.fieldWrapper, {
					[`${styles.fillField} `]: gridItem?.fieldConfig?.columnTitle?.length ?? false,
				});

				setTargetElementViewLayout(
					<div style={{
						transform: "rotate(-3deg) translateX(-12px) translateY(-20px)",
						padding: "0px 8px",
						alignItems: "center",
						gap: "8px",
						flexShrink: 0,
						borderRadius: "8px",
						boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.14)",
						width: `calc(${cellLayoutWidth}px * 0.4)`,
						minHeight: "40px"
					}} key={gridItem.gridItemId} className={fieldClassName}>
						<DragIcon />
						<TypeIcon type={gridItem?.fieldConfig?.columnType ?? "GroupField"} />
						<span style={{
							color: "var(--color-indigo-800)",
							fontFamily: "var(--font-roboto-400)",
							fontSize: "14px",
							overflow: "hidden",
							textOverflow: "ellipsis",
							width: "80%"
						}} >{gridItem.fieldConfig?.columnTitle}</span>
					</div >
				);
			}
		}

	}, [cellLayoutWidth, grid]);

	const closeConfirm = useCallback(() => {
		modalController.modalRemove(idConfirm);
	}, []);

	const closeAllModals = useCallback(() => {
		store.modals.map((modal) => { modalController.modalRemove(modal.id); });
		fieldConfigurationStore.resetConfiguration();
		detailConfigurationStore.resetConfiguration();
		fieldGroupConfigurationStore.resetConfiguration();
	}, [store.modals]);

	const warningConfirm = useMemo(() => {
		return <div className={styles.warningDialog}>
			<div className={styles.warningHeader}>
				<span className={styles.warningTitle}>Внимание</span>
				<Warning />
			</div>
			<div className={styles.warningDialogBody}>
				<span className={styles.title}>{DEFAULT_WARNING_TEXT}</span>
			</div>
			<div className={styles.dialogFooter}>
				<Button caption="Вернуться к редактированию" onClick={closeConfirm} style={ButtonStyle.Subtle} />
				<Button caption="Да, отменить" onClick={closeAllModals} style={ButtonStyle.Danger} />
			</div>
		</div>;
	}, [closeAllModals, closeConfirm, dispatcher.entity.get()?.entity.sectionWizzard]);

	const closeFuncWithConfirm = useCallback(() => {
		modalController.popupAdd({ id: idConfirm, layout: warningConfirm, closeFunc: closeConfirm });
	}, [idConfirm]);

	const closeOnFocusModal = () => {
		if (fieldConfigurationStore.hasChanges) {
			closeFuncWithConfirm()
		} else {
			closeAllModals()
		}
	}

	const handleEditField = useCallback((columnId: string) => {
		const finded = grid.find(item => item.fieldConfig?.columnId === columnId);
		modalController.popupAdd({
			id: idModal,
			layout: <FieldConfigurationWrapper
				fieldType={finded?.fieldConfig?.columnType as ColumnType}
				position={{ cellX: -1, cellY: -1 }}
				columnId={columnId}
				close={closeFuncWithConfirm}
				closeAll={closeAllModals}
			/>,
			closeFunc: closeOnFocusModal
		});
	}, [idModal, toJS(grid)]);

	const handleDeleteField = useCallback((gridItemId: string) => {
		let canDelete = true;
		dispatcher.sectionWizzard.getAllGridItems().forEach(item => {
			if (gridItemId === item.gridItemId) {
				if (item.fieldConfig) {
					{
						if (currentTabIndex === 0 && (item.fieldConfig.columnName === "Id" || item.fieldConfig.columnName === "CreatedOn" ||
							item.fieldConfig.columnName === "ModifiedOn" || item.fieldConfig.columnName === "ExternalId")) {
							canDelete = false;
						}
						else canDelete = true;
					}
				}
			}
		});

		const finded = grid.find(item => item.gridItemId === gridItemId);
		if (isNew && canDelete) {
			dispatcher.sectionWizzard.deleteGridItemFromTab(finded!.gridItemId, currentTabIndex);
		} else {
			const newItem: GridItem = {
				...finded as unknown as GridItem,
				gridItemId: finded?.gridItemId ?? "",
				x: -1,
				y: -1
			};
			dispatcher.sectionWizzard.getAllGridItems().forEach(item => {
				if (item.gridItemId === newItem.gridItemId) {
					item.x = -1;
					item.y = -1;
				}
			});
		}
	}, [currentTabIndex, isNew]);

	const elements = useMemo(() => {
		return grid?.map((element) => {
			let fieldType: string = '';
			if (element.type === ItemType.Detail) {
				fieldType = ColumnType.Detail;
			} else if (element.type === ItemType.GroupFields) {
				fieldType = ColumnType.FieldGroup;
			} else {
				fieldType = element?.fieldConfig?.columnType ?? '';
			}

			const fieldId: string = element.gridItemId;
			const gridItem = {
				gridItemId: element.gridItemId,
				columnId: element.fieldConfig?.columnId!,
				title: element.fieldConfig?.columnTitle!,
				x: element?.groupFieldsConfig ? 1 : element.x,
				y: element.y,
				width: element.width,
				height: element.height,
				type: fieldType,
				fieldConfig: element.fieldConfig,
				groupFieldsConfig: element.groupFieldsConfig,
				detailConfig: element.detailConfig
			};

			// @ts-ignore
			const GridItem = multiTypeDragBlocks[fieldType] ?? ((props: any) => <></>);

			const fieldClassName = classNames(styles.fieldWrapper, {
				[`${styles.fillField} `]: gridItem?.fieldConfig?.columnTitle?.length ?? false,
				[`${styles.fieldGroupWrap}`]: fieldType === ColumnType.FieldGroup || fieldType === ColumnType.Detail,
				[`${styles.detailWrap}`]: fieldType === ColumnType.Detail,
			});

			const width: string = (cellLayoutWidth * gridItem.width - 20).toString();

			return (
				<Element
					key={fieldId}
					id={fieldId}
					x={gridItem.x}
					y={gridItem.y}
					width={gridItem.width}
					height={gridItem.height}
				>
					<>
						{fieldType === ColumnType.FieldGroup ?
							<div style={{ width: `${width}px` }} className={fieldClassName}>
								<GridItem element={gridItem} />
							</div>
							: fieldType === ColumnType.Detail ?
								<div style={{ width: `${width}px` }} className={fieldClassName}>
									<GridItem element={gridItem} />
								</div> :
								<GridItem
									element={gridItem}
									width={`${width}px`}
									onEdit={handleEditField}
									onDelete={handleDeleteField}
								/>
						}
					</>
				</Element>
			);
		});
	}, [cellLayoutWidth, toJS(grid)]);

	const cellLayout = useMemo(() => {
		return (
			<div style={{ width: cellLayoutWidth - 10, height: `${CELL_HEIGHT}px` }} >
				<div className={styles.fieldWrapper}></div>
			</div>);
	}, [cellLayoutWidth]);

	return (
		<>
			{!store.options.isDisabledConstructorInSectionWizzard ?
				<div ref={container} style={{ height: "100%" }}>
					<Zone
						id={zoneId}
						config={{
							targetElementViewLayout: targetElementViewLayout,
							cellLayout: cellLayout,
							placeholderLayout: cellLayout,
							predictLayout: <div style={{
								borderRadius: "4px",
								border: "1px solid var(--color-gray-300)",
								background: "var(--color-indigo-50)",
								width: "calc(100% - 40px)",
								height: "26px",
								padding: "10px",
								margin: "5px",
								marginBottom: 0,
								gap: "12px",
							}} />,
							width: 2,
							height: Math.max(rowQualityByGridList, rowQualityByScreen, MINIMAL_ROW_QUALITY),
						}}
						type={Types.MATRIX}
						onDrop={handleDrop}
						postInitTime={POST_INIT_TIME}
						onUp={handleUp}
					>
						{elements || []}
					</Zone>
				</div>
				: <></>
			}
		</>
	);
});
