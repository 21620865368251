import { stringify, parse } from 'flatted';
import { Filter } from 'types/entity';
import SavedFilter from 'entities/filter/SavedFilter';
import { isFunction } from 'lodash';

type AuthData = {
	expires: string,
	id: string,
	message: string,
	userName: string,
	tokens: {
		accessToken: string,
		refreshToken: string,
	}
}

class SessionStore {
	prefix: string = "Filter_";
	checkAuth = (): boolean => {
		return Boolean(localStorage.getItem("auth"));
	};

	setAuth = (authData: AuthData): void => {
		localStorage.setItem("auth", JSON.stringify(authData));
	};

	getAuth = (): AuthData | null => {
		const authJSON = localStorage.getItem("auth");
		if (authJSON) {
			return JSON.parse(authJSON);
		}
		return null;
	};

	logout = () => {
		localStorage.clear();
	};

	checkSections = () => {
		return Boolean(localStorage.getItem("sections"));
	};

	setSections = (sections: Array<any>) => {
		localStorage.setItem("sections", JSON.stringify(sections));
	};

	getSections = () => {
		const sectionsJSON = localStorage.getItem("sections");
		if (sectionsJSON) {
			return JSON.parse(sectionsJSON);
		}
		return null;
	};

	resetSections = () => {
		localStorage.removeItem("sections");
	};

	checkEntities = () => {
		return Boolean(localStorage.getItem("entities"));
	};

	setEntities = (entities: Array<any>) => {
		localStorage.setItem("entities", stringify(entities) /*JSON.stringify(entities)*/);
	};

	getEntities = () => {
		const entitiesJSON = localStorage.getItem("entities");

		if (entitiesJSON) {
			return parse(entitiesJSON);
		}
		return null;
	};

	resetEntities = () => {
		sessionStorage.removeItem("entities");
	};

	setFilter(entityName: string, value: Filter | null) {
		if (value === null) {
			localStorage.removeItem(this.prefix + entityName);
		}
		else {
			const filter: Filter = parse(stringify(value));

			if (value.savedFilter && value.savedFilter.filterInfo && value.savedFilter.disposer) {
				filter.savedFilter = value.savedFilter.serialize() as SavedFilter;
			}
			localStorage.setItem(this.prefix + entityName, stringify(filter));
		}
	}

	getFilter(entityName: string) {
		const filterJSON = localStorage.getItem(this.prefix + entityName);

		if (filterJSON) {
			return parse(filterJSON);
		}
		return null;
	}
}


export const sessionStore = new SessionStore();