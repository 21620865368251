import eventBus from "app/services/EventBus";
// import api from "app/services/api";
import FileStorages from "entities/FileStorages";
import { makeAutoObservable } from "mobx";
import FileStoreEvents from "../types/FileStoreEvents";
import FileStoresTypes from "../types/FileStoresTypes";
import { api } from "api";

class FileStoreService {
	currentStore: FileStorages = FileStorages.Db;
	activePage: FileStorages = FileStorages.Db;

	isSwithing: boolean = false;

	//TODO this.switchCheck(); был закомментирован, т.к. этот метод начал зацикливаться, в дальнейшем разобраться за что он отвечал и если что вернуть
	constructor() {
		makeAutoObservable(this);
		eventBus.registerHandler(FileStoreEvents.Switch, this.switchStore.bind(this));
		eventBus.unregisterHandler(FileStoreEvents.Switch, this.switchStore.bind(this));
		this.getSettings();
		// this.switchCheck();
	}

	switchStore(currentStore: FileStorages) {
		this.currentStore = currentStore;
	}

	setActivePage(page: FileStorages) {
		this.activePage = page;
	}

	async getSettings() {
		// const response = await api.get("/api/file/sourceInfo");
		const response = await api.http.httpApi.file.sourceInfo().get();
		try {
			this.currentStore = response.data.currentFileSource;
			this.activePage = response.data.currentFileSource;
		}
		catch (error) {
			console.log(error);
		}
	}

	async switchCheck() {
		// const response = await api.get("/api/file/taskrunning");
		const response = await api.http.httpApi.file.taskrunning().get();
		try {
			if (response.data.success && response.data) {
				setTimeout(() => this.switchCheck(), 3000 + Math.random() * 5000);
			}

			if (response.data.success) {
				eventBus.dispatchEvent(FileStoreEvents.Swithing, response.data);
				this.isSwithing = response.data;
			}
		}
		catch (error) {
			console.log(error);
		}
	}
}

const fileStoreService = new FileStoreService;

export default fileStoreService;