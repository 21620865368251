import { makeAutoObservable } from "mobx";

import { dispatcher, selector, sessionStore } from "store";

import SavedFilter from "entities/filter/SavedFilter";
import { BasicEntity, SavedFilterFolder, StaticGroup, StaticGroupFolder } from "types/entity";
import ComparisonsGroup from "features/filter/simpleFilter/data/ComparisonsGroup";
import { ComparisonType, DataValueType, ExpressionType } from "entities/filter/IFilter";
import authStore from "AuthStore";


export class FilterController {
    constructor() {
        makeAutoObservable(this);
    }
    initFilter = (currentEntity: BasicEntity): void => {
        if (!currentEntity.filter) {
            currentEntity.filter = {
                savedFilter: null,
                staticGroup: null,
                favoriteFilters: [],
                placeholder: null
            }
        }
    }

    generateNewFilterTree = (): void => {
        const entity = dispatcher.entity.get();
        if (!entity) {
            return;
        }


        const savedFilterFolder: SavedFilterFolder = {
            id: null,
            parentFolderId: null,
            name: null,
            filters: [],
            childFilterFolders: [],
            isRoot: false,
            entityName: entity.entityName,
            userId: authStore.userId,
        }

        const staticGroupFolder: StaticGroupFolder = {
            id: null,
            parentFolderId: null,
            name: null,
            groups: [],
            childGroupFolders: [],
            isRoot: false,
            entityName: entity.entityName,
        }


        entity.entity.filterTree = {
            savedFilterTree: savedFilterFolder,
            staticGroupFolderTree: staticGroupFolder
        }
    };

    setSavedFilter = (newSavedFilter: SavedFilter | null): void => {
        const findEntity = dispatcher.entity.get();
        if (!findEntity) {
            return;
        }
        if (!findEntity.entity.filter) {
            findEntity.entity.filter = {
                savedFilter: null,
                staticGroup: null,
                favoriteFilters: [],
                placeholder: null
            }
        }

        if (newSavedFilter === null) {
            findEntity.entity.filter.savedFilter = newSavedFilter;
            // this.setPlaceholder();
            return;
        }
        findEntity.entity.filter.savedFilter = new SavedFilter(findEntity.entityName);
        findEntity.entity.filter.savedFilter.deserialize(newSavedFilter);
    }

    setStaticGroup = (newGroup: StaticGroup | null): void => {
        const findEntity = dispatcher.entity.get();
        if (!findEntity) {
            return;
        }
        if (!findEntity.entity.filter) {
            findEntity.entity.filter = {
                savedFilter: null,
                staticGroup: null,
                favoriteFilters: [],
                placeholder: null
            }
        }
        findEntity.entity.filter.staticGroup = newGroup;
    }

    setPlaceholder = (): void => {
        const filter = dispatcher.entity.get()?.entity.filter;
        if (!filter) {
            return;
        }
        if (!filter.savedFilter && !filter.staticGroup) {
            filter.placeholder = null;
        }
        if (filter.savedFilter) {
            if (filter.savedFilter.filterName) {
                filter.placeholder = `${filter.savedFilter.filterName}`;
            }
            else if (filter.savedFilter.filterInfo && filter.savedFilter.filterInfo.filters.length == 1) {
                const currentFilter = filter.savedFilter.filterInfo.filters[0];
                const comparisonValue = ComparisonsGroup[filter.savedFilter.filterInfo.filters[0].property.propertyValue.type!].find(({ id }) => id == currentFilter.comparisonType)!;
                let value = null;

                if (currentFilter.rightExpression) {
                    if (currentFilter.property.propertyValue.type === DataValueType.DateTime) {
                        if (currentFilter.comparisonType === ComparisonType.Between) {
                            value = new Date(currentFilter.rightExpression!.parameter.value.start).toLocaleString().substring(0, 10) + " и "
                                + new Date(currentFilter.rightExpression!.parameter.value.end).toLocaleString().substring(0, 10);
                        }
                        else if (currentFilter.rightExpression!.type === ExpressionType.Macros) {
                            value = selector.filter.getMacros(currentFilter.rightExpression!.macros!);
                        }
                        else {
                            value = new Date(currentFilter.rightExpression!.parameter.value).toLocaleString().substring(0, 10);
                        }
                    }
                    else {
                        value = currentFilter.rightExpression!.parameter.value.displayValue || currentFilter.rightExpression!.parameter.value.name || currentFilter.rightExpression!.parameter.value;
                    }
                }

                filter.placeholder = `${currentFilter.property.propertyValue.value?.displayValue!} : ${comparisonValue.name} ${value ? "«" + (value) + "»" : ""} `;
            }
            else if (filter.savedFilter.filterInfo && filter.savedFilter.filterInfo.filters.length > 1) {
                filter.placeholder = `Колонки : ${filter.savedFilter.filterInfo.filters.length}`
            }
        }
        if (filter.staticGroup && filter.staticGroup.name) {
            filter.placeholder = `${filter.staticGroup.name}`
        }
    };

    setFilterInFavoriteFilters = (newSavedFilter: SavedFilter | null): void => {
        const findEntity = dispatcher.entity.get();
        if (!findEntity || !newSavedFilter) {
            return;
        }
        if (!findEntity.entity.filter) {
            findEntity.entity.filter = {
                savedFilter: null,
                staticGroup: null,
                favoriteFilters: [],
                placeholder: null
            }
        }

        const filterIndex = findEntity.entity.filter.favoriteFilters.findIndex(({ id }) => id === newSavedFilter.id)
        if (filterIndex > -1) {
            findEntity.entity.filter.favoriteFilters.splice(filterIndex, 1, newSavedFilter);
        }

    }

    updateFavoriteFilters = (newSavedFilter: SavedFilter | null): void => {
        const findEntity = dispatcher.entity.get();
        if (!findEntity || !newSavedFilter) {
            return;
        }
        if (!findEntity.entity.filter) {
            findEntity.entity.filter = {
                savedFilter: null,
                staticGroup: null,
                favoriteFilters: [],
                placeholder: null
            }
        }

        const filterIndex = findEntity.entity.filter.favoriteFilters.findIndex(({ id }) => id === newSavedFilter.id)
        if (filterIndex > -1 && !newSavedFilter.isFavorite) {
            findEntity.entity.filter.favoriteFilters.splice(filterIndex, 1);
            return;
        }
        if (filterIndex > -1 && newSavedFilter.isFavorite) {
            return;
        }
        findEntity.entity.filter.favoriteFilters.unshift(newSavedFilter);
    }

    deleteFromFavoriteFilters = (filterId: string): void => {
        const findEntity = dispatcher.entity.get();
        if (!findEntity) {
            return;
        }

        const filterIndex = findEntity.entity.filter!.favoriteFilters.findIndex(({ id }) => id === filterId)
        if (filterIndex > -1) {
            findEntity.entity.filter!.favoriteFilters.splice(filterIndex, 1);
        }
    }
}

export const filterController = new FilterController();
