import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useParams } from "react-router";

import { synchroiser } from "synchroiser";
import { sessionStore } from "store";

import Leads from "pages/leads/leads";
import Contacts from "pages/contacts/contacts/contacts";
import Settings from "pages/settings/Settings";
import ContactFullView from "pages/contacts/contactFullView/ContactFullView";
import AccountFullView from "pages/accounts/accountFullView/AccountFullView";
import Accounts from "pages/accounts/accounts/accounts";
import SaleFullView from "pages/sales/saleFullView/SaleFullView";
import LeadFullView from "pages/leads/leadFullView";
import ImportPage from "pages/import/import-page";
import Sales from "pages/sales/sales/sales";
import SectionWizzard from "pages/section-wizzard/section-wizzard";
import SinglePage from "pages/singlePage/single-page";
import { SectionCard } from "pages/section-card/section-card";
import Profile from "pages/profile/Profile";
import { DetailMaster } from "features/detail-master";
import { NotFoundPage } from "pages/error-page/error-page";

import SectionRoute from "entities/SectionRoute";
import DialogHandler from "./events/dialog-handler";
import Navigation from "features/navigation/navigation-panel";
import { ModalViewer } from "features/modals";

import ProgressBar from "features/full-view/files/progress-bar/progress-bar";

import { InProgress } from "assets/icons";

import styles from "./App.module.css";


//TODO объявить переменную куда будем складывать классы

const App = observer(function () {
	const [init, setInit] = useState<boolean>(false);
	const pathFragments = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (Object.keys(pathFragments).length === 0) {
			navigate(`/${SectionRoute.Settings}`);
		}
	}, [navigate, pathFragments]);

	useEffect(() => {
		if (sessionStore.checkAuth()) {
			synchroiser.setWithoutSessionStoreValues();
			synchroiser.getSectionsList()?.then(() => {
				setInit(true);
			});
		} else {
			setInit(true);
		}
	}, []);

	//TODO решить проблему с токеном и авторизацией 
	// useEffect(() => {
	// 	if (store.session.accessToken) {
	// 		setInit(true);
	// 	}
	// }, [store.session.accessToken]);

	//TODO FIX-раскоментить как пофиксим руты
	// useEffect(() => {
	// 	const path = document.location.pathname;
	// 	synchroiser.getCurrentTarget(path.split("/"));
	// }, []);

	return (init ?
		<>
			<Routes>
				<Route path={`/section/${SectionRoute.Lead}`} element={<ComponentsWithNavigation component={<Leads />} />} />
				<Route path={`/section/${SectionRoute.Sale}`} element={<ComponentsWithNavigation component={<Sales />} />} />
				<Route path={`/section/${SectionRoute.Contact}`} element={<ComponentsWithNavigation component={<Contacts />} />} />
				<Route path={`/section/${SectionRoute.Account}`} element={< ComponentsWithNavigation component={<Accounts />} />} />
				<Route path={`/${SectionRoute.Settings}/*`} element={<ComponentsWithNavigation component={<Settings />} />} />
				<Route path={`/${SectionRoute.Workspace}`} element={<ComponentsWithNavigation component={<InProgress className={styles.loading} />} />} />
				<Route path={`/${SectionRoute.User}/*`} element={<ComponentsWithNavigation component={<Profile />} />} />
				<Route path={`/section/${SectionRoute.Contact}/:id`} element={<ComponentsWithNavigation component={<ContactFullView />} />} />
				<Route path={`/section/${SectionRoute.Account}/:id`} element={<ComponentsWithNavigation component={<AccountFullView />} />} />
				<Route path={`/section/${SectionRoute.Sale}/:id`} element={<ComponentsWithNavigation component={<SaleFullView />} />} />
				<Route path={`/section/${SectionRoute.Lead}/:id`} element={<ComponentsWithNavigation component={<LeadFullView />} />} />
				<Route path={`${SectionRoute.Import}`} element={<ComponentsWithNavigation component={<ImportPage />} />} />
				<Route path={`${SectionRoute.Import}/:entity`} element={<ComponentsWithNavigation component={<ImportPage />} />} />
				<Route path={"/sectionMaster/:mode/:entityName"} element={<SectionWizzard />} />
				<Route path={"/singlePage/:entityName"} element={<ComponentsWithNavigation component={<SinglePage />} />} />
				<Route path={"/singlePage/:entityName/:id"} element={<ComponentsWithNavigation component={<SectionCard />} />} />
				<Route path={"/detailMaster/:mode/:entityName"} element={<DetailMaster />} />
				<Route path={"*"} element={<ComponentsWithNavigation component={<NotFoundPage />} />} />
			</Routes>
			<DialogHandler />
			<ModalViewer />
		</> : <ProgressBar text="Пожалуйста, подождите..." />

	);
});

const ComponentsWithNavigation = observer(function (props: { component: JSX.Element }) {

	return (
		<div className={styles.app} id='target' >
			<Navigation />
			<div className={styles.appContent}>
				{props.component}
			</div>
		</div>
	);
});

export default App;
