import { Contact } from 'entities/contact/Contact';
import Entity, { IEntityData, IEntityStore } from 'entities/Entity';
import { User } from 'entities/user/User';
import { FieldValidationState, validateRequired } from 'entities/Validation';
import { action, makeAutoObservable, makeObservable, observable } from 'mobx';
import { Item } from "types";


export class Account implements IEntityStore {
    schema: string = "Account";
    displaySchema: string = "Контрагенты";
    id: string = "";



    name: string = "";
    site: string = "";
    owner: User | null = null;
    category: Item | null = null;
    city: Item | null = null;//????????????
    type: Item | null = null;
    typeOwnership: Item | null = null;
    industry: Item | null = null;
    notes: string = "";
    primaryPhone: string = "";
    inn: string = "";
    kpp: string = "";
    ogrn: string = "";

    modifiedOn: string = "";
    createdOn: string = "";

    validation: { [key: string]: FieldValidationState };

    constructor() {
        makeObservable(this, {
            name: observable,
            site: observable,
            owner: observable,
            category: observable,
            city: observable,
            type: observable,
            typeOwnership: observable,
            industry: observable,
            notes: observable,
            primaryPhone: observable,
            inn: observable,
            kpp: observable,
            ogrn: observable,
            validation: observable,
            setValue: action,
            validate: action,
            resetValidate: action,
            deserialize: action,
        })

        this.validation = {
            name: {
                isInvalid: false
            },
            site: {
                isInvalid: false
            },
            // owner: {
            //     isInvalid: false
            // },
            category: {
                isInvalid: false
            }
        }
    }

    setValue(value: string | Item, fieldName: string) {
        Reflect.set(this, fieldName, value);
    }

    // @action
    validate(): boolean {
        if (!validateRequired(this.name, this.validation.name)) {
            return false;
        }
        if (!validateRequired(this.site, this.validation.site)) {
            return false;
        }
        // if (!validateRequired(this.owner, this.validation.owner,true,true)) {
        //     return false;
        // }
        if (!validateRequired(this.category, this.validation.category,true)) {
            return false;
        }
        return true;
    }

    resetValidate() {
        this.validation = {
            name: {
                isInvalid: false
            },
            site: {
                isInvalid: false
            },
            // owner: {
            //     isInvalid: false
            // },
            category: {
                isInvalid: false
            }
        }
    }

    // @action
    deserialize(p: any) {
        // p.values.map((item:IEntityData) => {
        //     let name = item.propertyName.charAt(0).toLowerCase() + item.propertyName.slice(1);
        //     Reflect.set(this, name, item.propertyValue);
        // })

        this.id = p.id;
        this.name = p.name ?? "";
        this.site = p.site ?? "";
        this.owner = p.owner;
        this.category = p.category;
        this.primaryPhone = p.primaryPhone ?? "";
        this.city = p.city;
        this.type = p.type;
        this.typeOwnership = p.typeOwnership;
        this.notes = p.notes ?? "";
        this.industry = p.industry;
        this.inn = p.inn ?? "";
        this.kpp = p.kpp ?? "";
        this.ogrn = p.ogrn ?? "";
        this.modifiedOn = p.modifiedOn;
        this.createdOn = p.createdOn;
    }

    serialize() {
        let values = Reflect.ownKeys(this).filter(x => !x.toString().toLowerCase().includes('schema') && !(this.id === "" && x === 'id') && x !== 'validation' && !x.toString().endsWith('edOn')).map((item) => {
            let name = null;
            let value = null;
            if (typeof Reflect.get(this, item) === 'object') {
                name = (item.toString().charAt(0).toUpperCase() + item.toString().slice(1)) + "Id";
                value = ((Reflect.get(this, item) as Item) !== null) ? (Reflect.get(this, item) as Item).id : null;
            }
            else {
                name = item.toString().charAt(0).toUpperCase() + item.toString().slice(1);
                value = Reflect.get(this, item) === "" ? null : Reflect.get(this, item);
            }
            if (name === 'PrimaryPhone' && value !== null && value !== undefined) {
                value = (value as string).replace(/\D/g, '');
                if (value[0] !== '7')
                    value = value.charAt(0).replace(value[0], '7') + value.toString().slice(1);

            }
            return {
                propertyName: name,
                propertyValue: value
            }
        }) as IEntityData[];
        values.pop();

        return {
            entityName: this.schema!,
            values: values
        }
    }

    handleError(error: string): void {
        if (error === "name_is_empty") {
            // this.validName = false;
            // this.errorName = "";
        }
    }
}


const account = new Account();

const accountEntity = new Entity<Account>(account);

export default accountEntity;