import { observer } from "mobx-react-lite"
import { useCallback, useState } from "react";

import { dispatcher } from "store";

import { Button, ButtonStyle, Field, Input, InputStyleName } from "components";

import { validateRequired } from "pages/section-wizzard/validation/validation";
import { ERROR_TAB_EXIST_TITLE, ITab, ValidationState } from "pages/section-wizzard/data/data";

import { CloseMaxi, Warning } from "assets/icons";

import styles from "./tabs-with-pagination.module.css";

export const RenameTabLayout = observer((props: { currentTab: ITab, close: () => void, save: (tab: ITab) => void }) => {
    const [newValue, setNewValue] = useState('');
    const [validateEntityTitleState, setValidateEntityTitleState] = useState<ValidationState>({
        isInvalid: false,
        isNotUnique: false,
        error: ''
    })

    const changeValue = useCallback((value: string) => {
        setNewValue(value);
    }, [newValue]);

    const handleSave = useCallback(() => {
        const newTab: ITab = {
            ...props.currentTab,
            caption: newValue
        }
        props.save(newTab)
    }, [newValue]);

    const handleNewTitleFocusOut = useCallback(() => {
        const isNotUnique = dispatcher.sectionWizzard.checkExistTabTitle(newValue);
        if (isNotUnique) {
            setValidateEntityTitleState({
                isInvalid: false,
                isNotUnique: isNotUnique,
                error: ERROR_TAB_EXIST_TITLE
            })
        }
        else {
            setValidateEntityTitleState(validateRequired(newValue))
        }
    }, [validateEntityTitleState, newValue]);

    return <div className={styles.dialog}>
        <div className={styles.header}>
            <span className={styles.title}>Переименование вкладки</span>
            {<CloseMaxi
                className={styles.closeButton}
                onClick={props.close} />
            }
        </div>
        <div className={styles.dialogBody}>
            <Field name="oldName" caption="Старое название">
                <Input
                    value={props.currentTab.caption!}
                    placeholder=""
                    onChangeValue={() => { }}
                    inputStyle={InputStyleName.Base}
                    isDisabled
                />
            </Field>
            <Field name="newName" caption="Новое название" required={true}>
                <Input
                    value={newValue}
                    placeholder="Введите значение"
                    onChangeValue={changeValue}
                    inputStyle={InputStyleName.Base}
                    isInvalid={validateEntityTitleState.isInvalid}
                    isNotUnique={validateEntityTitleState.isNotUnique}
                    invalidMessage={validateEntityTitleState.error}
                    onFocusOut={handleNewTitleFocusOut}
                />
            </Field>
            <div className={styles.dialogFooter}>
                <Button
                    caption="Отменить"
                    onClick={props.close}
                    style={ButtonStyle.Subtle} />
                <Button
                    caption="Сохранить"
                    onClick={handleSave}
                    style={ButtonStyle.Primary} />
            </div>
        </div>
    </div>
});

export const DeleteTabLayout = observer((props: { close: () => void, delete: () => void }) => {
    const WARNING_TEXT = "Вы действительно хотите удалить вкладку?\nСуществующие в ней элементы останутся в системе.";

    const handleDelete = useCallback(() => {
        props.delete()
    }, []);

    return <div className={styles.warningDialog}>
        <div className={styles.warningHeader}>
            <span className={styles.warningTitle}>Внимание</span>
            <Warning />
        </div>
        <div className={styles.warningDialogBody}>
            <span className={styles.title}>{WARNING_TEXT}</span>
        </div>
        <div className={styles.dialogFooter}>
            <Button caption="Вернуться к редактированию" onClick={props.close} style={ButtonStyle.Subtle}
                isDisabled={false} />
            <Button caption="Удалить" onClick={handleDelete} style={ButtonStyle.Danger} isDisabled={false} />
        </div>
    </div>
});