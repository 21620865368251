import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";

import { dispatcher } from "store";
import Reactor from "module/reactor/Reactor";

import { GridItem, SectionWizzard, TabId } from "types/entity";
import SkeletonAccountFullView from "pages/accounts/accountFullView/skeleton/SkeletonAccountFullView";


export const PreviewFullView = observer((props: {
    sectionWizzard: SectionWizzard | null | undefined,
    isPreviewLoading: boolean,
    setIsPreviewLoading: (value: boolean) => void
}) => {

    const findPanelTitle = useCallback((tabId: string) => {
        const additional = dispatcher.sectionWizzard.getAdditionalFromConfig();
        if (additional) {
            const tab = additional[tabId as TabId];
            if (tab) return tab.title
        }
        else return ''
    }, [dispatcher.sectionWizzard.getAdditionalFromConfig()]);

    const viewColumnTitle = useMemo(() => {
        return dispatcher.sectionWizzard.getViewColumnTitle()
    }, [dispatcher.sectionWizzard.getSectionWizzard()?.viewColumnId]);

    const viewAdditionalPanel = useMemo(() => {
        return dispatcher.sectionWizzard.getViewAdditional()
    }, [dispatcher.sectionWizzard.getViewAdditional()]);

    useEffect(() => {
        if (props.sectionWizzard)
            setTimeout(() => {
                props.setIsPreviewLoading(false);
            }, 1000);
    }, [props.sectionWizzard])

    const xmlParser = useMemo(() => (props.sectionWizzard ? `
        <Program>
            <Module>
                <Service name="main"/>
            </Module>

            <Render direction="column" height="100%">
                <PreviewFullView 
                    entityTitle="value:main:entityTitle" 
                    viewColumn="${viewColumnTitle}" 
                >
                    <Flex gap="10" direction="column" height="100%">
                        <Grid columns="${viewAdditionalPanel ? "1fr 0.5fr" : "1fr"}" rows="1fr" gap="10">
                            <Content>
                                <Tabs activeTab="{{value:main:mainTab}}" setActiveTab="func:main:onChangeMainTab">
                                    ${props.sectionWizzard.reactorConfig.tabs.tabsConfig.map((tabConfig: any) => {
        return (
            `<Tab caption="${tabConfig.tabTitle}" name="${tabConfig.tabName}">
                                                <Flex direction="column" paddingRight="25" paddingLeft="25" gap="20">
                                                    <BaseInfo title="${viewColumnTitle}">
                                                        ${tabConfig.grid.items.map((item: GridItem) => {
                return (
                    item.fieldConfig && `<PreviewField field='${JSON.stringify(item)}' />`
                );
            })}
                                                    </BaseInfo>
                                                    ${tabConfig.grid.items.map((item: GridItem) => {
                return (item.groupFieldsConfig &&
                    `<FieldGroup title="${item.groupFieldsConfig.title}" > 
                                                                ${item.groupFieldsConfig.inner?.items.map((item: GridItem) => {
                        return (
                            item.fieldConfig && `<PreviewField field='${JSON.stringify(item)}' />`
                        );
                    })}                                                                
                                                            </FieldGroup>`
                );
            })}
                                                </Flex>
                                            </Tab>`
        );
    })}
                                    ${Object.keys(props.sectionWizzard.reactorConfig.tabs.additionalPanel).
            filter(panel => (props.sectionWizzard?.reactorConfig.tabs.additionalPanel as any)[panel].state === 0).map(panel => {
                return (
                    `<Tab caption="${findPanelTitle(panel)}" name="${panel}">
                                                    <Label>${findPanelTitle(panel)}</Label>
                                                </Tab>`
                );
            })}                        
                                </Tabs> 
                            </Content>
                            ${viewAdditionalPanel &&
        `<Content>
                                    <Tabs activeTab="{{value:main:additionalTab}}" setActiveTab="func:main:onChangeAdditionalTab">
                                        ${Object.keys(props.sectionWizzard.reactorConfig.tabs.additionalPanel).
            filter(panel => (props.sectionWizzard?.reactorConfig.tabs.additionalPanel as any)[panel].state === 1).map(panel => {
                return (
                    `<Tab caption="${findPanelTitle(panel)}" name="${panel}">
                                                        <Label>${findPanelTitle(panel)}</Label>
                                                    </Tab>`
                );
            })}                        
                                    </Tabs> 
                                </Content>`
        }
                        </Grid>
                    </Flex>
                </PreviewFullView>               
            </Render>

            <Script>
            (
                class MainService{
                    tabsConfig=${JSON.stringify(props.sectionWizzard.reactorConfig.tabs.tabsConfig)};
                    mainTab="${props.sectionWizzard.reactorConfig.tabs.tabsConfig[0].tabName}";
                    additionalTab="${Object.keys(props.sectionWizzard.reactorConfig.tabs.additionalPanel).
            filter(panel => (props.sectionWizzard?.reactorConfig.tabs.additionalPanel as any)[panel].state === 1)[0]}";
                    entityTitle="${props.sectionWizzard.entityTitle}";
                    entityName="${props.sectionWizzard.systemName}";

                    constructor(){

                    }

                    onChange(item){
                        this.value = item;
                    }

                    onChangeMainTab(tabName){
                        this.mainTab = tabName;
                    }

                    onChangeAdditionalTab(tabName){
                        this.additionalTab = tabName;
                    }

                }
            )
            </Script>
        </Program>
    `
        :
        ""
    ), [props.sectionWizzard, viewColumnTitle]);

    if (!props.isPreviewLoading) {
        return (<Reactor xml={xmlParser} />);
    }
    else {
        return (<SkeletonAccountFullView />);
    }
});
