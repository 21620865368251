import {useCallback, useEffect, useState} from "react";
import {toJS} from "mobx";
import {observer} from "mobx-react-lite";

import {ButtonStyle, Dropdown} from "components";

import {Stage} from './stage'

import {HEXColors, ResultHEXColors, ResultType, StageType} from "types/entity";
import {Item} from "types";

import {ArrowToDown} from "assets/icons";

import styles from "./stage-panel.module.scss"
import {isNull} from "lodash";
import {store} from "../../store";

export interface StageParams extends Item {
    name: string;
    stageId: string;
    color: HEXColors | string;
    allowStages?: string[],
    resultType?: ResultType | null,
    stageType?: StageType,
    order: number,
    disabled?: boolean,
    opacity?: number,
    hoverForDefault?: boolean;
}

export type StagePanelProps = {
    intermediate: StageParams[],
    result: StageParams[],
    onChange: (currentStage: string) => void,
    current: string;
};

/**
 * @description Компонент Стадийная панель
 * @param intermediate массив промежуточных стадий
 * @param result массив результирующих стадий
 * @param current id текущей стадии
 * @param onChange handler смены стадии
 */
export const StagePanel = observer((props: StagePanelProps) => {
    const [finalStage, setFinalStage] = useState<StageParams>(props.result[0] ?? props.intermediate[props.intermediate.length - 1]);
    const [resultColor, setResultColor] = useState<ResultHEXColors | null>(null);
    const [stages, setStages] = useState<StageParams[]>([]);
    const [finalStages, setFinalStages] = useState<StageParams[]>([]);

    /**
     * @description установить текущую стадию
     */
    const handleChangeCurrent = useCallback((stageId: string, isDisable?: boolean) => {
        if (isDisable) {
            return;
        }
        store.hasChanges = true;
        props.onChange(stageId);
    }, [props]);

    /**
     * @description установить стадиям свойство disabled в зависимости от стадий, разрешенных для перехода
     */
    const setDisable = useCallback((currentStageId: string) => {
        const allStages: StageParams[] = [];
        const allowStages = toJS([...props.intermediate, ...props.result]).find((stage: StageParams) => stage.stageId === currentStageId)?.allowStages;
        toJS([...props.intermediate, ...props.result]).forEach((stage: StageParams) => {
            let isFlag = false
            allowStages?.forEach((allowStageId) => {
                if (allowStageId == stage.stageId) {
                    isFlag = true;
                }
            });
            stage.disabled = !isFlag;
            allStages.push(stage);
        });

        setStages(allStages.sort((a, b) => a.order - b.order));
    }, [props.intermediate, props.result]);


    /**
     * @description меняет тип промежуточных и результирующих стадий
     */
    const handleChangeStageType = useCallback(() => {
        const allStages: StageParams[] = [];
        let isCurrentFind = false;
        let isFinal = false;
        toJS([...props.intermediate, ...props.result]).forEach((stage: StageParams) => {
            if (stage.stageId === props.current && !isNull(stage.resultType)) {
                isFinal = true;
            }
        });

        toJS([...props.intermediate, ...props.result]).sort((a, b) => a.order - b.order).forEach((stage: StageParams) => {
            if (stage.stageId === props.current) {
                stage.stageType = StageType.CURRENT;
                isCurrentFind = true;
            } else {
                if (!isCurrentFind || !isNull(stage.resultType)) {
                    stage.stageType = StageType.PASSED;
                    stage.opacity = 1;
                } else {
                    stage.stageType = StageType.DEFAULT;
                    stage.opacity = 0.3;
                }
            }
            if (!isNull(stage.resultType) && !isFinal) {
                stage.stageType = StageType.DEFAULT;
            }
            allStages.push(stage);
        });

        if (!isFinal)
            setResultColor(null);
        setStages(allStages.sort((a, b) => a.order - b.order));
        setFinalStages(allStages.filter((stage) => !isNull(stage.resultType)));
    }, [props.result, props.current, props.intermediate]);

    useEffect(() => {
        handleChangeStageType();
        setDisable(props.current);
    }, [props.current]);

    /**
     * @description при выборе результирующей стадии устанавливает цвет для всех стадий в зависимости от результата
     */
    const handleDropDownChangeValue = useCallback((value: any) => {
        if (value) {
            setFinalStage({...value, color: value.color});
            handleChangeCurrent(value.stageId, false);
        }
        stages.map(x => x.stageType = StageType.PASSED);
        switch (value.resultType) {
            case ResultType.SUCCESSFUL:
                setResultColor(ResultHEXColors.SUCCESSFUL);
                break;
            case ResultType.NEGATIVE:
                setResultColor(ResultHEXColors.NEGATIVE);
                break;
            case ResultType.NULL:
                setResultColor(ResultHEXColors.NULL);
                break;
            default:
                setResultColor(value.color);
                break;
        }
    }, [stages, handleChangeCurrent, setFinalStage, setResultColor]);
    console.log(finalStages)
    /**
     * @description если нет результирующих стадий, то последняя по порядку стадия - последняя в массиве intermediate
     */
    return (
        <div className={styles.stagePanelWrapper}>
            {stages.map((stage, index) => (isNull(stage.resultType) && <Stage
                    disabled={stage.disabled}
                    passed={stage.stageType === StageType.PASSED}
                    key={stage.stageId}
                    name={stage.name}
                    order={index === 0 ? -1 : index === (stages.length - 1) ? 1 : 0}
                    color={resultColor ?? stage.color}
                    onClick={() => handleChangeCurrent(stage.stageId, stage.disabled)}
                />)
            )}
            {props.result[0] ?
                <Stage
                    overflowClipDisable
                    name={props.result[0].name}
                    order={1}
                    passed={props.result[0].stageType === StageType.PASSED}
                    disabled={props.result[0].disabled}
                    color={resultColor ?? props.result[0].color}
                >

                    <div>
                        <Dropdown
                            items={finalStages.map((stage) => ({id: stage.stageId, name: stage.name, title: stage.name}))}
                            value={finalStage}
                            onChange={handleDropDownChangeValue}
                            onChangeValue={handleDropDownChangeValue}
                            classNameList={styles.dropdownList}
                            secondIcon={<ArrowToDown style={{fill: "none"}}/>}
                            isRotateSecondIcon
                            styles={ButtonStyle.GrayBlue300}
                        />
                    </div>
                </Stage>
                : <></>}
        </div>
    );
});