import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react";
import { isEmpty } from "lodash";

import { synchroiser } from "synchroiser";
import { dispatcher, selector, store } from "store";
import Reactor from "module/reactor/Reactor";

import { LoadingState } from "types/entity";
import SkeletonAccountFullView from "pages/accounts/accountFullView/skeleton/SkeletonAccountFullView";
import { NotFoundPage } from "pages/error-page/error-page";
import { DetailCard } from "./pages/detail-card/detail-card";

//TODO доделать отображение согласно координатам и действие "назад"
export const SectionCard = observer(() => {
    const param = useParams<{ entityName: string, id: string }>();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        navigate(".", { state: null, replace: true });
        const loadRowAsync = async () => {
            if (param) {
                await synchroiser.getRow(param.entityName!, param.id!);
            }
        }
        loadRowAsync();
    }, [location.pathname]);

    useEffect(() => {
        if (!location.state && param && param.entityName !== dispatcher.entity.get()?.entityName && synchroiser.loadingState != LoadingState.Loading) {
            dispatcher.fullView.switchEntityAndRow(param.entityName!, param.id!);
        }
    }, [location.state, location.pathname, synchroiser.loadingState]);

    const sectionWizzard = useMemo(() => dispatcher.entity.get()?.entity.sectionWizzard, [dispatcher.entity.get()?.entity.sectionWizzard, store.currentEntityId]);

    const xmlParser = useMemo(() => {
        if (dispatcher.entity.get()?.entityName.toLowerCase() === param.entityName?.toLowerCase()) {
            return selector.fullView.getFullViewXml(sectionWizzard);
        }
        return "";
    }, [dispatcher.entity.get(), param, sectionWizzard]);

    if (location.state?.isDetail) {
        return (<DetailCard />)
    }

    /* TODO мб сохранить первоначальный 
    результат парсера в reactorPage у sectionWizzard, отбражать его, потом сравнить пришедший и старый, 
    чтобы скелетон не скакал при переходе из детали обратно в запись раздела */
    if ((!location.state?.isDetail && !sectionWizzard && synchroiser.loadingState == LoadingState.Loading) || synchroiser.loadingState == LoadingState.Loading || isEmpty(xmlParser)) {
        return (<SkeletonAccountFullView />);
    }

    if (synchroiser.loadingState == LoadingState.Error) {
        return (<NotFoundPage />);
    }

    return (<Reactor xml={(xmlParser)} />);
});
