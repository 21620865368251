import { action, makeAutoObservable, makeObservable, observable } from "mobx";

import lookupMainPanelStore from "pages/settings/lookups/LookupMainPanelStore";

import { sortArray } from "app/services/SortService";
// import api from 'app/services/api';
import FilterColumnType from "entities/ColumnType";
import ISort from "entities/ISort";
import { api } from "api";

export interface IAccessRight {
    id: string,
    entityId: string,
    entityName: string,
    title: string,
    administratedByRecords: boolean,
    administratedByEntityOperations: boolean,
}

export interface IDataSection {
    id: string;
    createdOn: string | null;
    modifiedOn: string | null;
    entityName: string | null;
    entityTitle: string | null;
    isBox: boolean | null;
    entityInfo: any[];
    entityInfoBox: any[];
    isLookUp: boolean | null;
    isSection: boolean | null;
}
export const enum accessItemsIds {
    All = 1,
    Lookups = 2,
    Sections = 3
}
export const AccessItems = [
	{
		id: accessItemsIds.All,
		name: "Все объекты",
	},
	{
		id: accessItemsIds.Lookups,
		name: "Справочники",
	},
	{
		id: accessItemsIds.Sections,
		name: "Разделы",
	},
];



export class AccessRight {
	settingsRightStore: IAccessRight[];
	settingsRightsDataToGrid: any[];
	entityTitle: string | undefined;
	entityId: string | any;
	valueSelect: string;
	dataLookups: any[];
	sectionDataList: IDataSection[];

	constructor() {
		makeObservable(this, {
			settingsRightStore: observable,
			settingsRightsDataToGrid: observable,
			entityTitle: observable,
			entityId: observable,
			valueSelect: observable,
			dataLookups: observable,
			sectionDataList: observable,
			setValue: action,
		});

		this.settingsRightStore = [];
		this.settingsRightsDataToGrid = [];
		this.entityTitle = "";
		this.entityId = "";
		this.valueSelect = "";
		this.dataLookups = [];
		this.sectionDataList = [];
		this.getValueSelect(AccessItems.filter(item => item.id === accessItemsIds.All)[0]);
	}

	ColumnsAccessMainPanel = [
		{
			name: "title",
			caption: "Заголовок",
			width: 30,
			type: FilterColumnType.String,
			getLink: (id: string) => "",
			sortColumn: "title"
		},
		{
			name: "name",
			caption: "Название",
			width: 30,
			type: FilterColumnType.String,
			getLink: (id: string) => "",
			sortColumn: "name"
		},
		{
			name: "administratedByRecords",
			caption: "Доступ по операциям органичен",
			width: 25,
			type: FilterColumnType.Boolean,
			sortColumn: "administratedByRecords",
			check: true,
			onChecked: (entityId: any, checked: boolean) => { this.enableRightSettingByRecords(checked, entityId); console.log(entityId); }
		},
		{
			name: "administratedByEntityOperations",
			caption: "Доступ по записям ограничен",
			type: FilterColumnType.Boolean,
			width: 25,
			sortColumn: "administratedByEntityOperations",
			check: true,
			onChecked: (entityId: any, checked: boolean) => this.enableRightSettingByEntityOperations(checked, entityId)
		}
	];


	setValue(value: any, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	async loadRightSettings(sort?: ISort) {
		try {
			// let req = await api.get("/api/Administration/settings/getAdministartiveObjectList").then(res => res.data);
			let response = await api.http.httpApi.administration.getAdministartiveObjectList().get();
			let req = response.data.data;
			if (req != null) {
				if (sort) {
					req = sortArray(req, sort.columnPath, sort.direction);
				}
				this.setValue(req, "settingsRightStore");
				this.getDataToGridSettings();
			}
		}
		catch (error: any) {
			console.error("An error occurred:", error);
		}
	}

	getDataToGridSettings() {
		let newDataToGrid: any[] = [];
		this.settingsRightStore.forEach(e => {
			newDataToGrid.push({
				id: e.id,
				entityId: e.entityId,
				name: e.entityName,
				title: e.title,
				administratedByRecords: e.administratedByRecords,
				administratedByEntityOperations: e.administratedByEntityOperations
			});
		});
		this.setValue(newDataToGrid, "settingsRightsDataToGrid");
	}

	async enableRightSettingByRecords(enable: boolean, entityId: string) {
		const data = { entityId: entityId, enable: enable };
		// await api.post("/api/Administration/settings/administrationbyrecords/enable", data);
		await api.http.httpApi.administration.administrationByRecords().post(data);
		this.loadRightSettings();
	}

	async enableRightSettingByEntityOperations(enable: boolean, entityId: string) {
		const data = { entityId: entityId, enable: enable };
		// await api.post("/api/Administration/settings/administrationbyentityoperations/enable", data);
		await api.http.httpApi.administration.administrationByEntityOperationsEnable().post(data);
		this.loadRightSettings();
	}

	getCheckRecord = async (entityId: string) => {
		// const getCheck = await api.get(`/api/Administration/settings/administrationbyentityoperations/getrecord/${entityId}`);
		const response = await api.http.httpApi.administration.getRecord(entityId).get();
		const getCheck = response.data;
		if (getCheck) {
			return getCheck.data;
		};
	};

	getCheckOperation = async (entityId: string) => {
		// const getCheck = await api.get(`/api/Administration/settings/administrationbyentityoperations/getoperation/${entityId}`);
		const response = await api.http.httpApi.administration.getOperations(entityId).get();
		const getCheck = response.data;
		if (getCheck) {
			return getCheck.data;
		}
	};

	async getDataListSection() {
		// const section = await api.get("/api/Entity/entitysectiondatalist").then(data => data.data);
		const response = await api.http.httpApi.entity.entityDataList().get();
		const section = response.data;
		if (section) {
			this.sectionDataList = section;
		}
	}

	getValueSelect(event: any) {
		if (event.id === accessItemsIds.All) {
			this.setValue(event.name, "valueSelect");
			this.setValue([], "settingsRightStore");
			this.loadRightSettings();
			this.getDataToGridSettings();
		}
		else if (event.id === accessItemsIds.Lookups) {
			this.setValue(event.name, "valueSelect");
			if (this.settingsRightStore.length) {
				this.loadRightSettings();
				lookupMainPanelStore.loadLookups();
				this.setValue(lookupMainPanelStore.lookups, "dataLookups");
				const filterData = this.settingsRightStore.filter((item) => {
					return this.dataLookups.some((lookup) => lookup["entityName"] === item["entityName"]);
				});
				this.setValue(filterData, "settingsRightStore");
				this.getDataToGridSettings();
			}
			else {
				this.loadRightSettings();
				this.setValue(lookupMainPanelStore.lookups, "dataLookups");
				const filterData = this.settingsRightStore.filter((item) => {
					return this.dataLookups.some((lookup) => lookup["entityName"] === item["entityName"]);
				});
				this.setValue(filterData, "settingsRightStore");
				this.getDataToGridSettings();
			}
		}
		else if (event.id === accessItemsIds.Sections) {
			this.setValue(event.name, "valueSelect");
			if (this.settingsRightStore.length) {
				this.setValue([], "settingsRightStore");
				this.getDataListSection();
				this.loadRightSettings();
				const filterData = this.settingsRightStore.filter((item) => {
					return this.sectionDataList.some((lookup) => lookup["entityName"] === item["entityName"]);
				});
				this.setValue(filterData, "settingsRightStore");
				this.getDataToGridSettings();
			}
		}
	}

}

const accessStore = new AccessRight();

export default accessStore;
