import styles from "./info.module.css";

import { InfoIcon } from "assets/icons";

function Info(props: { title: string, text: string }) {
    return (
        <div className={styles.body}>
            <InfoIcon stroke="var(--color-indigo-800)" />
            <div className={styles.textBody}>
                <div className={styles.title}>{props.title}</div>
                <div className={styles.text}>{props.text}</div>
            </div>
        </div>
    )
}

export default Info;