import { DataValueType } from "entities/filter/IFilter";
import IIconSetting from "../core/IIconSetting";

import { Lookup } from "assets/icons";
import { Clocks } from "assets/icons";
import { Numbers } from "assets/icons";
import { TextIcon } from "assets/icons";
import { Filter } from "assets/icons";
import { CheckMarkerSquare } from "assets/icons";

const IconSetting: IIconSetting[] = [
    {
        types: [DataValueType.Lookup, DataValueType.Entity],
        icon: <Lookup />
    },
    {
        types: [DataValueType.Integer, DataValueType.Float],
        icon: <Numbers />
    },
    {
        types: [DataValueType.DateTime],
        icon: <Clocks />
    },
    {
        types: [DataValueType.Text],
        icon: <TextIcon />
    },
    {
        types: [DataValueType.Boolean],
        icon: <CheckMarkerSquare />
    },
]

function getIcon(type: DataValueType | undefined): JSX.Element {
    const icon = IconSetting.find(i => i.types.some(t => t === type))?.icon;

    if (icon) return icon;

    return <Filter />;
}

export default getIcon;