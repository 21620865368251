import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { dispatcher } from "store";

import ToggleSelect, { IToggleSelectItem } from "features/toggle-select/toggle-select";

import { Button, ButtonStyle, InputSearch } from "components";
import { ConstructorSourcePanel } from "../constructor-source-panel";

import { IGridItem } from "components/select/types";
import { MoreTitle, NewFields } from "pages/section-wizzard/data/data";
import { DisplayedPanel, TabId, TabState } from "types/entity";

import { SectionWizzardAdditionalPanel, SectionWizzardMainPanel, SectionWizzardSwitchDisplayedPanel, } from "assets/icons";

import styles from "./constructor-right-panel.module.css";


const ConstructorRightPanel = observer((props: {
	sourcePanelElements: Array<IGridItem>,
	onMoveBoxTab: (id: string, value: boolean, currentDisplayedPanel?: DisplayedPanel) => void
}) => {
	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	const tabsConfig = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.tabsConfig.filter(elem => elem);
	}, [sectionWizzard?.reactorConfig.tabs.tabsConfig.filter(elem => elem)]);

	const displayedPanel = useMemo(() => {
		return sectionWizzard?.displayedPanel;
	}, [sectionWizzard?.displayedPanel]);

	const additionalPanelTabs = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.additionalPanel;
	}, [sectionWizzard?.reactorConfig.tabs.additionalPanel]);

	const buttonContens = useMemo(() => {
		return {
			[DisplayedPanel.Main]: { firstIcon: <SectionWizzardMainPanel />, caption: "Основная панель" },
			[DisplayedPanel.Additional]: { firstIcon: <SectionWizzardAdditionalPanel />, caption: "Вспомогательная панель" },
		};
	}, [displayedPanel]);

	const switchPanel = useCallback(() => {
		const newDisplayedPanel =
			sectionWizzard?.displayedPanel == DisplayedPanel.Main ? DisplayedPanel.Additional
				: DisplayedPanel.Main;

		if (sectionWizzard) {
			dispatcher.sectionWizzard.switchDisplayedPanel(newDisplayedPanel);
		}
	}, [sectionWizzard]);

	const searchValue = useMemo(() => {
		return sectionWizzard?.searchValue ?? "";
	}, [sectionWizzard?.searchValue]);

	const handleSearchChange = useCallback((value: string) => {
		dispatcher.sectionWizzard.setSearchValue(value);
	}, [searchValue]);

	const getСheckedToogle = useCallback((settingId: TabId) => {
		if (sectionWizzard?.displayedPanel === DisplayedPanel.Main) {
			return dispatcher.sectionWizzard.getAdditionalFromConfig()![settingId].state === TabState.EnabledOnPrimary;
		}
		else {
			return dispatcher.sectionWizzard.getAdditionalFromConfig()![settingId].state === TabState.EnabledOnAuxiliary;
		}

	}, [tabsConfig, additionalPanelTabs, displayedPanel]);

	const tabs: IToggleSelectItem[] = useMemo(() => {
		const additional = dispatcher.sectionWizzard.getAdditionalFromConfig();
		return [
			{
				id: TabId.Files,
				displayValue: additional![TabId.Files].title,
				checked: getСheckedToogle(TabId.Files)
			},
			{
				id: TabId.Comments,
				displayValue: additional![TabId.Comments].title,
				checked: getСheckedToogle(TabId.Comments)!
			},
			{
				id: TabId.Chronology,
				displayValue: additional![TabId.Chronology].title,
				checked: getСheckedToogle(TabId.Chronology)!
			},
			{
				id: TabId.LinkedRecords,
				displayValue: additional![TabId.LinkedRecords].title,
				checked: getСheckedToogle(TabId.LinkedRecords)!
			},
		];
	}, [tabsConfig, additionalPanelTabs, displayedPanel]);

	const rightSettings = useMemo(() => {
		return <div className={styles.rightSettingsWrapper}>
			<ConstructorSourcePanel
				tabs={tabs}
				newFields={NewFields}
				sourcePanelElements={props.sourcePanelElements}
				onMoveBoxTab={props.onMoveBoxTab}
			/>
		</div>;
	}, [toJS(props.sourcePanelElements), toJS(tabs), props.onMoveBoxTab]);

	const choosingPanel = useMemo(() => {
		if (displayedPanel === DisplayedPanel.Main) {
			return <>
				<div className={styles.divider}></div>
				<InputSearch
					value={searchValue}
					placeholder="Поиск"
					onChangeValue={handleSearchChange}
					className={styles.search}
					classNameInput={styles.search}
				/>
				{rightSettings}
			</>;
		}
		else {
			return <>
				<div className={styles.divider}></div>
				<ToggleSelect
					items={tabs}
					name={MoreTitle}
					buttonStyle={ButtonStyle.Settings}
					onToggleClick={props.onMoveBoxTab}
				/>
			</>;
		}

	}, [searchValue, rightSettings, displayedPanel]);

	return (
		<div className={styles.constructorRightPanel}>
			<Button
				{...buttonContens[sectionWizzard?.displayedPanel!]}
				className={styles.switchPanelButton}
				classNameCaption={styles.switchPanelButtonText}
				onClick={switchPanel}
				secondIcon={<SectionWizzardSwitchDisplayedPanel />}
			/>
			{choosingPanel}
		</div>
	);
});

export default ConstructorRightPanel;