import { FunctionComponent } from "react";

import {
    String,
    Date,
    Time,
    DateTime,
    Boolean,
    InputNumber,
    Decimal,
    Select,
    BooleanProps,
    DateProps,
    DateTimeProps,
    DecimalProps,
    NumberProps,
    SelectProps,
    StringProps,
    TimeProps,
} from "../inputs";

import { EntityColumnSpecialization, GridItem } from "types/entity";
import { ColumnType } from "entities/ColumnType";

export const inputs = {
    [ColumnType.String]: String,
    [ColumnType.Guid]: String,
    [ColumnType.Date]: Date,
    [ColumnType.Time]: Time,
    [ColumnType.DateTime]: DateTime,
    [ColumnType.Boolean]: Boolean,
    [ColumnType.Integer]: InputNumber,
    [ColumnType.FieldGroup]: String,
    [ColumnType.Lookup]: Select,
    [ColumnType.Decimal]: Decimal,
    [ColumnType.Detail]: String,
    [ColumnType.Double]: String,
};

export type InputTypes =
    FunctionComponent<BooleanProps> |
    FunctionComponent<DateProps> |
    FunctionComponent<DateTimeProps> |
    FunctionComponent<DecimalProps> |
    FunctionComponent<NumberProps> |
    FunctionComponent<SelectProps> |
    FunctionComponent<StringProps> |
    FunctionComponent<TimeProps>;


export type Props = {
    field: string
}

export type ParsedProps = GridItem & {
    fieldConfig?: {
        columnType: ColumnType,
        columnId: string;
        columnName: string;
        columnTitle: string;
        hasIndex: boolean;
        isLookup: boolean;
        isRequired: boolean;
        lookupTable: string | null;
        defaultValue?: string;
        rounding?: string;
        prompt?: string;
        specializations?: EntityColumnSpecialization
    }
}
