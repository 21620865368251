import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { Button, ButtonStyle, Input, InputStyleName, MultilevelSelect, SelectStyleName, SelectStyles } from "components";

import { Item } from "types";
import { ISelectProps } from "./types";

import { ArrowToDown } from "assets/icons";
import { Locked } from "assets/icons";
import { FavouriteStar } from "assets/icons";

import styles from "./select.module.css";

function Select(props: ISelectProps) {
	const [isOpened, setOpened] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const inputElement = useRef<HTMLDivElement>(null);
	const selectStyle = SelectStyles[props.selectStyle ?? SelectStyleName.Base];
	const inputStyle = props.inputStyle ?? InputStyleName.BaseWithoutBorder;

	const classNamesWrapper = classNames(`${selectStyle.classNames}`, {
		[`${props.className}`]: props.className,
	});
	const buttonClassNames = classNames({
		[`${props.classNameButton} `]: props.classNameButton,
		[`${props.classNameOpenList}`]: props.classNameOpenList && isOpened,
	});
	const arrowButton = classNames(`${styles.selectButton}`, {
		[`${styles.close} ${styles.listItemDisabled}`]: isOpened,
	});
	const inputClassNames = classNames(`${selectStyle.input}`, {
		[`${styles.inputDisabled}`]: props.isDisabled,
		[`${selectStyle.focusInput}`]: isOpened,
		[`${styles.inputDisabled} ${styles.invalid}`]: props.isInvalid && !isOpened,
	});
	const inputClasses = classNames(`${styles.inputSelect}`, {
		[`${props.classNameInput}`]: props.classNameInput,
	});
	const invalidMessageClasses = classNames(`${styles.errorMessage} `);

	const firstIconClassNames = classNames(`${styles.iconButton} `, {
		[`${styles.close}`]: props.isRotateFirstIcon && isOpened,
		[`${props.classNameIcon}`]: props.classNameIcon,
	});
	const secondIconClassNames = classNames(`${styles.iconButton} `, {
		[`${styles.close}`]: props.isRotateSecondIcon && isOpened,
	});

	const listClassNames = classNames(`${styles.baseList}`, {
		[` ${styles.visible} `]: isOpened,
		[`${props.classNameList} `]: props.classNameList,
	});
	const inputLinkWrapper = classNames(`${styles.linkWrapper}`, {
		[` ${styles.inputDisabled} `]: props.isDisabled,
	});
	const inputLink = classNames(`${styles.linkStyle}`, {
		[`${styles.inputDisabled}`]: props.isDisabled,
	});

	let error = null;
	if (props.isInvalid === true && !isOpened) {
		if (props.invalidMessage !== undefined && props.invalidMessage.length > 1) {
			error = <div className={invalidMessageClasses}>{props.invalidMessage}</div>;
		}
	}

	const getInputValue = useMemo(() => {
		return (props.value !== null && props.value !== undefined) ?
			((props.value.title !== undefined) ?
				props.value.title
				:
				((props.value.displayValue !== undefined) ?
					props.value!.displayValue
					:
					props.value!.name
				)
			)
			: ""
	}, [props.value]);

	const firstIcon = useMemo(() => {
		return props.iconOpened ? (isOpened ? props.iconOpened : props.firstIcon) : props.firstIcon
	}, [props.iconOpened, props.firstIcon, isOpened])

	const hideMenu = useCallback(() => {
		setOpened(false);
		if (props.onFocusOut) props.onFocusOut();
		document.removeEventListener("click", handleClick);
	}, [props.onFocusOut]);

	useEffect(() => {
		window.addEventListener("scroll", hideMenu);
		return () => {
			window.removeEventListener("scroll", hideMenu);
		};
	}, []);

	const handleClick = useCallback((event: Event) => {
		if (firstIcon?.type.render?.name !== (event.target as Element).id)
			if (wrapperRef.current != null && !wrapperRef.current.contains(event.target as Node)) {
				hideMenu();
			}
	}, [wrapperRef, firstIcon]);

	const openMenu = useCallback(() => {
		if (props.isDisabled) return;
		setOpened(true);
		if (props.onItemsLoad) {
			props.onItemsLoad(null);
		}
	}, [props.isDisabled, props.onItemsLoad]);

	const onClick = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (props.isDisabled) return
		if (props.onClick) {
			props.onClick(e);
		}
		if (!isOpened) {
			openMenu();
			document.addEventListener("click", handleClick);
		}
		else {
			hideMenu();
			document.removeEventListener("click", handleClick);
		}
	}, [props.onClick, isOpened, props.isDisabled]);

	const setPositionsContextMenu = (element: HTMLUListElement) => {
		if (element && wrapperRef.current !== null) {
			const position = element.getBoundingClientRect();
			if (!props.notTranslate) {
				let winh = window.innerHeight;
				let raz = (winh - position.bottom) - 15;

				if (position.bottom > winh) {
					element.style.top = raz + "px";
					element.style.left =
						wrapperRef.current!.children?.item(0)!.getBoundingClientRect().width + "px";
				}
			}
			if (position.right > window.innerWidth) {
				element.style.left = (-position.width + wrapperRef.current?.getBoundingClientRect().width!) + "px";
			}
			if (props.isPositionLeft) {
				element.style.left = (-position.width + wrapperRef.current?.getBoundingClientRect().width!) + "px";
			}

			if (inputElement.current) {
				const wrapperPosition = inputElement.current.getBoundingClientRect();
				element.style.minWidth = (wrapperPosition.width - 20) + "px";
			}
			if (props.isListAdjustOfButton) {
				element.style.minWidth = (wrapperRef.current?.getBoundingClientRect().width! - 10) + "px";
			}
			if (wrapperRef.current.style.visibility === "hidden") {
				hideMenu();
			}

		}
	};

	const selectItem = useCallback((item: Item | null,e?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		if (!item?.isDisabled && !item?.isLocked) {
			props.onChangeValue(item,e);
			hideMenu();
		}
	}, [props.onChangeValue, isOpened]);

	const onClickToFavorite = useCallback((item: Item) => {
		props.handleClickToFavorites!(item);
	}, [props.handleClickToFavorites]);

	const handleOnBlur = useCallback((event: React.FocusEvent<HTMLDivElement, Element>) => {
		if (event.relatedTarget) {
			if (!event.currentTarget.contains(event.relatedTarget)) {
				hideMenu();
			}
		}
	}, [hideMenu]);

	const listItem = useCallback((item: Item) => {
		return (
			<>
				{item.icon &&
					<div className={styles.icon}>
						{item.icon}
					</div>
				}
				<div className={styles.listItemCaption} >
					<span className={styles.listItemName} >{item.title ? item.title : item.displayValue ? item.displayValue : item.name}</span>
					{item.note && <span className={styles.listItemNote} >{item.note}</span>}
				</div>

				{item.isLocked && <Locked style={{ marginLeft: "auto", stroke: "var(--color-grayBlue-900)" }} />}
			</>
		);
	}, [props.items]);

	const link = useCallback((item: Item) => {
		const listItemLinkClassNames = classNames(`${styles.listItem} ${styles.link}`);
		const favStarClassNames = classNames({
			[`${styles.activeFavStar}`]: item.IsFavorite,
			[`${styles.favStar}`]: !item.IsFavorite,
		});

		return <>
			<Link
				key={item.id}
				to={item.entityName ?? (item!.path ? item!.path : "")}
				onClick={hideMenu}
				className={listItemLinkClassNames}
			>
				{listItem(item)}
			</Link>
			{(item.tag && isOpened) &&
				<FavouriteStar className={favStarClassNames} onClick={() => onClickToFavorite(item)} />
			}
		</>;
	}, [onClickToFavorite, hideMenu, listItem]);

	const mappingList = useMemo(() => {
		return (props.items !== undefined &&
			props.items.map((item, i) => {
				const listItemClassNames = classNames(styles.listItem, {
					[`${styles.listItemDisabled}`]: item.isDisabled || item.isLocked,
					[`${styles.exit}`]: item.isRed,
					[`${item.classNames}`]: item.classNames,
					[`${styles.fav}`]: item.tag,
				});

				if (item.multilevel && item.multilevelItems) {
					return (<MultilevelSelect
						key={item.id}
						selected={item}
						onClick={() => selectItem(item)}
						handleClickToFavorites={props.handleClickToFavorites!}
					/>);
				}
				else {
					return (
						<div key={item.id} id={`${item.id}`}>
							{(item.entityName || item.path) ?
								<div className={listItemClassNames}>
									{link(item)}
								</div>
								:
								<li
									key={`${item.id}`}
									id={`${item.id}`}
									className={listItemClassNames}
									onClick={(e) => selectItem(item,e)}
								>
									{listItem(item)}
								</li>

							}
							{item.isSeparator &&
								<div className={styles.separator}></div>
							}
						</div>
					);
				}
			}));
	}, [props.items, listItem, link, selectItem, props.handleClickToFavorites]);

	return (
		<div
			ref={wrapperRef}
			className={classNamesWrapper}
			style={props.style}
			onBlur={handleOnBlur}
		>
			{props.isInput &&
				<div ref={inputElement} className={inputClassNames} tabIndex={1} >
					<Input
						value={getInputValue}
						placeholder={props.placeholder ?? ""}
						onClick={onClick}
						isPlaceholderDelay={props.isListDelay}
						isInvalid={props.isInvalid && !isOpened}
						onChangeValue={() => { }}
						className={inputClasses}
						isNeedCorrect={props.isNeedCorrect}
						isLightning={props.isLightning}
						inputStyle={inputStyle}
					/>
					{!props.isListDelay &&
						<Button
							onClick={onClick}
							firstIcon={(props.isLockedIcon && props.isDisabled) ? <Locked className={styles.iconResult} /> : <ArrowToDown />}
							style={ButtonStyle.Icon}
							className={arrowButton}
						/>
					}
				</div>
			}

			{(!props.inputLink && !props.isInput) &&
				<Button
					classNameFirstIcon={firstIconClassNames}
					classNameSecondIcon={secondIconClassNames}
					className={buttonClassNames}
					firstIcon={firstIcon}
					secondIcon={props.secondIcon}
					caption={props.value === null || props.value === undefined ? props.name : props.value.name}
					onClick={onClick}
					iconOnRight={props.isRightIcon}
					style={props.styles}
					onFocus={props.onFocus}
					onBlur={props.onBlur}
					onMouseEnter={() => { }}
					onMouseLeave={() => { }}
					selected={isOpened}
				/>
			}

			{(props.inputLink && !props.isInput) &&
				<div onClick={onClick} className={inputLinkWrapper}>
					<div>
						<Link to={props.inputLink} className={inputLink} target={props.linkTarget}>
							{(props.value !== null && props.value !== undefined) ?
								((props.value.title !== undefined) ?
									props.value.title
									:
									((props.value.displayValue !== undefined) ?
										props.value!.displayValue
										:
										props.value!.name
									)
								)
								: ""
							}
						</Link>
					</div>
					{props.isDisabled
						?
						<Locked className={styles.iconResult} />
						:
						<div>
							<Button
								onClick={onClick}
								firstIcon={<ArrowToDown />}
								style={ButtonStyle.Icon}
								className={arrowButton}
							/>
						</div>
					}
				</div>
			}

			<ul className={listClassNames} ref={setPositionsContextMenu}>
				{props.children && props.children}
				{mappingList}
			</ul>
			{error}
		</div>
	);

}

export default Select;