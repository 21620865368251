import React, { CSSProperties, ForwardedRef } from "react";
import { observer } from "mobx-react-lite";

import { ContextMenuElementType } from "types/item";

import styles from "./context-menu.module.css"

/**
 * @description Компонент Контекстное меню
 * @param style в параметр стиля для позиционирования компонента, например:{top: position.y, left: position.x},
 * @param options элементы контекстного меню
 */
const ContextMenu = observer(React.forwardRef((props: { style: CSSProperties, options: ContextMenuElementType[] }, ref: ForwardedRef<HTMLDivElement>) => {

    return (
        <div ref={ref} className={styles.wrapper} style={props.style}>
            {props.options.map((option, index) =>
                <div onClick={option.action} key={index} >
                    {option.element}
                    {option.isDivider && <div className={styles.divider} />}
                </div>
            )}
        </div>
    );
}));

export default ContextMenu