import Api from "api/http/api";

class DeduplicationSettings extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/DeduplicationSettings";
	}

	createSetting = () => {
		this.setPath(`${this.basicPath}/create`);
		return this;
	};
	updateSetting = () => {
		this.setPath(`${this.basicPath}/update`);
		return this;
	};
	deleteSetting = (id:string) => {
		this.setPath(`${this.basicPath}/delete?id=${id}`);
		return this;
	};
	getSetting = () => {
		this.setPath(`${this.basicPath}/get`);
		return this;
	};
	settingsList = () => {
		this.setPath(`${this.basicPath}/list`);
		return this;
	};
}

const deduplicationSetting = new DeduplicationSettings();

export { deduplicationSetting };