import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { toJS } from "mobx";

import { v4 } from "uuid";
import { UpFirst } from "entities/lowFirst";

import detailFieldConfigurationPopupState, { FieldStoreEnums } from "../detail-field-configuration-popup-state"

import {
    Field,
    Input,
    Text,
    SelectStyleName,
    Button,
    ButtonStyle,
    Dropdown,
    ValidationPrompt,
} from "components";
import ToggleBlock from "features/toggle-block/toggle-block";

import { FieldTypeTitle } from "pages/section-wizzard/pages/constructor/field-configuration/field-configuration";
import { NewLookupTabs } from "pages/section-wizzard/pages/constructor/field-configuration/types";
import { BasicSettings } from "../basic-settings/basic-settings";

import { ILookup } from "entities/Entity";

import {
    DragIcon,
    Cross
} from "assets/icons";

import styles from "../detail-field-configuration-popup.module.css";

export const NewLookupConfiguration = observer(() => {
    const [tab, setTab] = useState(NewLookupTabs.LookupTab);
    const [selected, setSelected] = useState<number>(0);
    const [defValues, setDefValues] = useState<ILookup[]>(detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues);

    const basicSettingsMemo = useMemo(() => <BasicSettings />, []);

    const handleVirtualLookupDefaultValueChange = useCallback(async (value: any) => {
        detailFieldConfigurationPopupState.setValue(FieldStoreEnums.selectedLookupDefaultValue, value);
        if (value !== null) {
            detailFieldConfigurationPopupState.setValue(
                FieldStoreEnums.defaultValue,
                detailFieldConfigurationPopupState.selectedLookupDefaultValue!.id
            );
        } else {
            detailFieldConfigurationPopupState.setValue(FieldStoreEnums.defaultValue, value);
        }
    }, []);

    const handleDefaultValuesLoad = useCallback(async (find: string | null) => {
        if (find) {
            const filtered = detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.filter(value => value.name.includes(find));
            setDefValues(filtered);
            return filtered.length
        }
        else {
            setDefValues(detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues);
            return detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.length
        }
    }, [defValues, detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues]);

    const defaultVirtualValueField = useMemo(() => {
        return <Field
            name="defaultValue"
            caption="Значение по умолчанию"
            promptText='Значение, которым заполнится поле при создании записи'
        >
            <Dropdown
                items={defValues}
                onChangeValue={handleVirtualLookupDefaultValueChange}
                onItemsLoad={handleDefaultValuesLoad}
                isInput
                value={detailFieldConfigurationPopupState.selectedLookupDefaultValue}
                selectStyle={SelectStyleName.Base}
            />
        </Field>
    }, [toJS(defValues), detailFieldConfigurationPopupState.selectedLookupDefaultValue]);

    const removeBadVirtualLookupValues = () => {
        detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues = detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.filter(
            (item) => item.name.trim() !== ""
        );
    };

    const handleChangeTab = (tabIndex: NewLookupTabs) => {
        if (tab === NewLookupTabs.LookupTab) {
            if (detailFieldConfigurationPopupState.virtualLookup.entityTitle.trim() === ""
                || detailFieldConfigurationPopupState.virtualLookup.systemName.trim() === "") {
                return;
            }
        }
        removeBadVirtualLookupValues();
        setSelected(tabIndex);
        setTab(tabIndex);
    };
    const handleAddVirtualLookupValue = () => {
        if (detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.length > 0) {
            const lastIndex = detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.length - 1;
            if (detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues[lastIndex].name.trim() === "") {
                return;
            }
        }
        detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.push({ id: v4(), name: "" });
    };

    const onChangeVirtualLookupValue = (value: string, index: number) => {
        detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues[index].name = value;
    };

    const handleTitleChange = useCallback((value: string) => {
        detailFieldConfigurationPopupState.virtualLookup.entityTitle = value;
    }, []);

    const handleTitleFocusOut = useCallback(async () => {
        await detailFieldConfigurationPopupState.validateNewLookupTitle();
    }, []);

    const handleVirtualLookupSystemNameChange = useCallback((value: string) => {
        detailFieldConfigurationPopupState.virtualLookup.systemName = UpFirst(value);
    }, []);

    const handleVirtualLookupSystemNameFocusOut = useCallback(async () => {
        await detailFieldConfigurationPopupState.validateNewLookupName();
    }, []);

    const handleRemoveVirtualLookupValue = (index: number) => {
        const valueToRemove = detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues[index];

        if (valueToRemove.id === detailFieldConfigurationPopupState.defaultValue) {
            return;
        }
        detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.splice(index, 1);
    };


    const itemClasses = useCallback((i: number) => {
        return classNames(`${styles.tabButton} `, {
            [`${styles.selected} `]: selected === i,
        });
    }, [selected]);

    return (
        <>
            <FieldTypeTitle fieldType={detailFieldConfigurationPopupState.fieldType} />

            <div className={styles.tabContainer}>
                <Button
                    key="newLookupSettingTab"
                    caption="Справочник"
                    onClick={() => handleChangeTab(NewLookupTabs.LookupTab)}
                    className={itemClasses(NewLookupTabs.LookupTab)}
                    style={ButtonStyle.DragGrayButton}
                />
                <Button
                    key="settingFieldTab"
                    caption="Настройки поля"
                    onClick={() => handleChangeTab(NewLookupTabs.FieldSettingsTab)}
                    className={itemClasses(NewLookupTabs.FieldSettingsTab)}
                    style={ButtonStyle.DragGrayButton}
                />
            </div>
            {tab == NewLookupTabs.LookupTab ? (
                <>
                    <Text className={styles.sectionTitle}>ОСНОВНОЕ</Text>
                    <Field
                        name="title"
                        caption="Название справочника"
                        required
                        promptText='Название нового справочника'
                    >
                        <Input
                            value={detailFieldConfigurationPopupState.virtualLookup.entityTitle}
                            placeholder=""
                            onChangeValue={handleTitleChange}
                            isNeedCorrect
                            isInvalid={detailFieldConfigurationPopupState.validation.virtualLookupTitle.isInvalid}
                            isNotUnique={detailFieldConfigurationPopupState.validation.virtualLookupTitle.isNotUnique}
                            invalidMessage={detailFieldConfigurationPopupState.validation.virtualLookupTitle.error}
                            onFocusOut={handleTitleFocusOut}
                        />
                    </Field>

                    <Field
                        name="systemName"
                        caption="Системное название"
                        required
                        promptText='Название, которое будет отображаться в базе данных'
                    >
                        <Input
                            value={detailFieldConfigurationPopupState.virtualLookup.systemName}
                            placeholder=""
                            onChangeValue={handleVirtualLookupSystemNameChange}
                            isNeedCorrect
                            isInvalid={detailFieldConfigurationPopupState.validation.virtualLookupSystemName.isInvalid}
                            invalidMessage={detailFieldConfigurationPopupState.validation.virtualLookupSystemName.error}
                            onFocusOut={handleVirtualLookupSystemNameFocusOut}
                        />
                    </Field>

                    <Text className={styles.sectionTitle}>ЗНАЧЕНИЯ СПРАВОЧНИКА</Text>
                    <ValidationPrompt text="Добавятся только текстовые значения справочника" />
                    {detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.length === 0 ? (
                        <Text className={styles.defaultTipText}>Нажмите «Добавить значение», чтобы наполнить справочник</Text>
                    ) : (
                        <>
                            {detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.map((item, index) => (
                                <div className={styles.item} key={index}>
                                    <DragIcon />
                                    <Input
                                        value={item.name}
                                        placeholder=""
                                        onChangeValue={(value: string) => {
                                            onChangeVirtualLookupValue(value, index);
                                        }}
                                        className={styles.virtualLookupInput}
                                        isNeedCorrect
                                        invalidMessage="Заполните поле"
                                    />
                                    <Button
                                        onClick={(e) => {
                                            handleRemoveVirtualLookupValue(index);
                                        }}
                                        firstIcon={<Cross />}
                                        style={ButtonStyle.Icon}
                                        className={styles.clearButton}
                                    />
                                </div>

                            ))}
                        </>
                    )}
                    <Button
                        key="addLookupValue"
                        className={styles.addLookupValueButton}
                        caption="Добавить значение"
                        onClick={handleAddVirtualLookupValue}
                        style={ButtonStyle.LightBlue} />
                </>
            ) :
                (<>
                    {basicSettingsMemo}
                    {defaultVirtualValueField}
                    <ToggleBlock />
                    <Text className={styles.sectionTitle}>ВНЕШНИЙ ВИД</Text>
                    <Field
                        name="prompt"
                        caption="Подсказка"
                        promptText='То, что вы читаете прямо сейчас 🙂'
                    >
                        <Input
                            value={detailFieldConfigurationPopupState.prompt}
                            placeholder=""
                            onChangeValue={(value: string) => {
                                detailFieldConfigurationPopupState.setValue(FieldStoreEnums.prompt, value);
                            }}
                        />
                    </Field>
                </>)
            }

        </>
    );
});