import { useEffect, useRef, useState } from "react";

import { Item } from "types";

import { ArrowToRightThin } from "assets/icons";

import styles from "./item-dropdown.module.css";


interface ItemDropdownProps {
    items: Item[],
    value: JSX.Element,
    onItemClick: (item: Item) => void,
    renderFunction?: (value: Item) => JSX.Element
    isCanOpen?: boolean;
}

function defaultRender(value: Item): JSX.Element {
    return <span>{value.name}</span>
}

function ItemDropdown(props: ItemDropdownProps) {
    const [showItems, setShow] = useState<boolean>(false);
    const items = props.items;
    const ref = useRef<HTMLDivElement>(null);

    const render = props.renderFunction ?? defaultRender;

    const rect = ref.current?.getBoundingClientRect();
    const top = rect?.top;
    const left = rect ? rect.left + rect.width : undefined;

    useEffect(() => {
        if (props.isCanOpen === false) {
            setShow(false);
        }
    }, [props.isCanOpen])

    function onShow() {
        setShow(true);
    }

    function onHide() {
        setShow(false);
    }

    return (
        <div className={styles.wrapper} ref={ref} onMouseEnter={onShow} onMouseLeave={onHide}>
            <div className={styles.place}></div>
            <div className={styles.value} >
                {props.value}
                <ArrowToRightThin stroke="var(--color-gray-400)" />
            </div>
            <ul className={styles.list} style={items && items!.length > 0 && showItems && props.isCanOpen ?
                { visibility: "visible", left: left, top: top } : {}}>
                {items?.map((item: Item) => (
                    <li key={item.id} onClick={() => props.onItemClick(item)} className={styles.listItem}>
                        {render(item)}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default ItemDropdown;