import { makeAutoObservable, runInAction } from "mobx";
import IFilter, { ComparisonType, DataValueType, MacrosType } from "entities/filter/IFilter";
import ListStore from "entities/ListStore";
import DirectorSimpleFilter from "../../../../app/services/filter/directors/DirectorSimpleFilter";
import SimpleFilterStore from "./SectionsFilter";
import defaultValue, { booleanItems } from "../data/DefaultValue";
import { itemToLookup } from "components/lib/data-to-item";
import ComparisonsGroup from "../data/ComparisonsGroup";
import { Comparison, Property, SimpleFilterTemplate, Value } from "./TemplateEngine/TemplateEngine";
import parseDataValueType from "../converters/ParseDataValueType";
import IPropertyFilter from "app/services/filter/IPropertyFilter";
import authStore from "AuthStore";
import SavedFilter from "entities/filter/SavedFilter";
import { sortArray } from "app/services/SortService";
import { IFilterStore } from "entities/filter/FilterStore";
import DateMacroses from "../data/DateMacroses";
import StaticGroup from "entities/filter/StaticGroup";
import { Item } from "types";
import { api } from "api";



export class SimpleFilter implements SimpleFilterTemplate {
	property: Property;
	comparison: Comparison;
	value: Value;
	isApplay: boolean;
	filter: SavedFilter | null;
	advancedFilter: IFilter | null;
	favoriteFilters: SavedFilter[];
	staticGroup: StaticGroup | null;
	placeholder: string | null;

    constructor() {
        makeAutoObservable(this);
        this.property = {
            items: [],
            propertyValue: {
                value: null,
            },
            proprtiesInfo: []
        };
        this.comparison = {
            items: [],
            value: null,
        };
        this.value = {
            value: null,
            displayValue: null,
        };
        this.isApplay = false;
        this.filter = null;
        this.advancedFilter = null;
        this.placeholder = null;
        this.staticGroup = null;
        this.favoriteFilters = [];

	}

    setProperty(item: Item | null) {
        this.isApplay = false;
        if (item) {
            const property = this.property;
            const info = this.property.proprtiesInfo[property.items.indexOf(item)];
            const type = info.type;
            this.value.value = defaultValue[type];
            property.propertyValue.type = type;
            property.propertyValue.isNullable = info.isNullable;
            property.propertyValue.lookupTable = info.lookupTable;
            property.propertyValue.name = info.name;
            if (type > -1) {

				let collections = ComparisonsGroup[type].slice();

				if (this.property.propertyValue.isNullable) {
					collections.push({ id: ComparisonType.IsNotNull, name: "Заполнено" });
					collections.push({ id: ComparisonType.IsNull, name: "Не заполнено" });
				}
				this.comparison.value = ComparisonsGroup[type][0];
				this.comparison.items = collections;

            }
            this.property.propertyValue.value = item;
        }
        else {
            this.property = {
                items: this.property.items,
                propertyValue: {
                    value: null,
                },
                proprtiesInfo: this.property.proprtiesInfo
            };
            this.comparison = {
                items: [],
                value: null,
            };

            this.value = {
                value: null,
                displayValue: null,
            };
        }
    };

    setComparison(item: Item | null) {
        this.comparison.value = item;
        this.isApplay = false;
        if (item) {

			if (item.id === ComparisonType.Between) {
				this.value.value = { start: null, end: null };
			}
			else if (item.id === ComparisonType.IsNull || item.id === ComparisonType.IsNotNull) {
				this.value = {
					value: null,
					displayValue: null,
				};

			}
		}
		else {
			this.value = {
				value: null,
				displayValue: null,
			};
		}
	};
	async new(listStore: ListStore) {
		this.property = {
			items: [],
			propertyValue: {
				value: null,
			},
			proprtiesInfo: []
		};

		this.comparison = {
			items: [],
			value: null,
		};

        this.value = {
            value: null,
            displayValue: null,
        };
        this.filter = null;
        this.advancedFilter = null;
        this.placeholder = null;
        this.staticGroup = null;
        this.isApplay = false;
        this.loadProperty(listStore);
        listStore.load(null);
        this.setFilterInLocalStorage(listStore, null);
    };

	updateListFilter(listStore: ListStore, filter: IFilter | null) {
		if (filter === null && this.staticGroup !== null && this.staticGroup.id !== null) {
			listStore.load(null, this.staticGroup.id);
			this.placeholder = this.staticGroup.name;
			return;
		}
		listStore.load(filter);

		if (filter?.filters && filter.filters.length > 1) {
			this.advancedFilter = filter;
		}

		this.placeholder = (this.filter && this.filter.filterName) ? `${this.filter.filterName} `
			:
			(this.advancedFilter?.filters && this.advancedFilter.filters.length > 1) ?
				`Колонки : ${this.advancedFilter.filters.length}`
				:
				(this.comparison.value && this.comparison.value.id == -1) ?
					`${this.property.propertyValue.value?.displayValue!} : ${this.comparison.value?.name} ${this.value.value ? "" + this.value.value.start.split("T")[0].split("-").reverse().join(".") + " и " + this.value.value.end.split("T")[0].split("-").reverse().join(".") : ""} ` :
					`${this.property.propertyValue.value?.displayValue!} : ${this.comparison.value?.name} ${this.value.displayValue ? "«" + this.value.displayValue + "»" : ""} `;


	};

    restore(listStore: ListStore) {
        const filter = SimpleFilterStore.getFilter(listStore.schema);

		if (filter) {
			const newValue = JSON.parse(filter) as SimpleFilterTemplate;

			if (newValue.filter) {
				this.filter = new SavedFilter(listStore.schema);
				let myMap = new Map(Object.entries(newValue.filter as SavedFilter));
				myMap.forEach((value, key) => {
					this.filter?.setValue(value, key);
				});
			}
			else {
				this.filter = newValue.filter;
			} this.advancedFilter = newValue.advancedFilter;
			this.property = newValue.property;
			this.comparison = newValue.comparison;
			this.value = newValue.value;
			this.staticGroup = newValue.staticGroup;
			this.isApplay = true;

			this.updateListFilter(listStore, this.advancedFilter ? this.advancedFilter : this.staticGroup ? null : this.compile(listStore));
			this.setFilterInLocalStorage(listStore);
		}
		else {
			this.new(listStore);
		}
	};
	setValue(value: any) {
		this.value.value = value;
		this.isApplay = false;
	};
	resetValue() {
		const type = this.property.propertyValue.type!;
		this.value.value = defaultValue[type];
		this.isApplay = false;
	};
	async loadProperty(listStore: ListStore) {
		// let request = await m.post("/api/entity/entityinfo", { entityName: listStore.schema });
		const response = await api.http.httpApi.entity.entityInfo().post({ entityName: listStore.schema });
		let request = response.data;
		this.setItems(null, []);
		let proprtiesInfo = this.property.proprtiesInfo;
		if (request && request.data.success) {
			for (let item of request.data.entityInfoResultItems) {
				proprtiesInfo.push(
					{
						id: item.columnId,
						// @ts-ignore TODO Убрать ts-ignore после доработки фильтров
						type: parseDataValueType(item.columnType),
						lookupTable: item.lookupTable,
						isNullable: !item.isRequired,
						displayValue: item.columnTitle,
						name: item.columnName
					}
				);
				let newItems = proprtiesInfo.map(((e) => { return { id: e.id, displayValue: e.displayValue, name: e.name }; }));
				this.setItems(newItems);
			}
		}
	};
	async save(listStore: ListStore, updateFavorite?: boolean, filterName?: string) {
		if (this.filter === null || this.filter === undefined || filterName) {
			this.filter = new SavedFilter(listStore.schema);
			this.filter.setValue(filterName, "filterName");
			this.placeholder = `${this.filter.filterName} `;
		}

		this.filter.setValue(this.compile(listStore), "filterInfo");
		if (updateFavorite) {
			this.filter.setValue(!this.filter.isFavorite, "isFavorite");

			const result: SavedFilter[] = [];
			if (this.favoriteFilters.length > 0) {
				this.favoriteFilters.forEach(item => {
					if (item.id !== this.filter!.id)
						result.push(item);
				});
			}
			if (this.filter.isFavorite) {
				result.push(this.filter);
			}
			this.favoriteFilters = result;
		}

		if (this.filter.validate()) {
			if (this.filter.id === null) {
				// let request = await m.post("/api/SavedFilters/create", this.filter?.serialize());
				const response =  await api.http.httpApi.savedFilter.filterCreate().post(this.filter?.serialize());
				let request = response.data;
				runInAction(() => {
					if (request.data.success) {
						this.filter?.setValue(request.data, "id");
						this.setFilterInLocalStorage(listStore);
					}
				});
			}
			else {

				// let request = await m.post("/api/SavedFilters/update", this.filter?.serialize());
				const response = await api.http.httpApi.savedFilter.filterUpdate().post(this.filter?.serialize());
				let request = response.data;
				if (request.data.success) {
					this.setFilterInLocalStorage(listStore);
				}
			}
		}

	};
	async getFilter(filterId: string, listStore: ListStore) {
		// console.log(filterId);
		this.staticGroup = null;
		// let request = await m.get(`/api/SavedFilters/get?id=${filterId}`);
		let response = await api.http.httpApi.savedFilter.getFilter(filterId).get();
		let request = response.data;
		runInAction(() => {
			if (request.data.success) {

				this.filter = new SavedFilter(request.data.entityName);
				let myMap = new Map(Object.entries(request.data as SavedFilter));
				myMap.forEach((value, key) => {
					this.filter?.setValue(value, key);
				});
				if ((request.data as SavedFilter).filterInfo && (request.data as SavedFilter).filterInfo!.filters && (request.data as SavedFilter).filterInfo!.filters!.length > 1) {
					this.advancedFilter = ((request.data as SavedFilter).filterInfo as IFilter);
					this.updateListFilter(listStore, this.filter.filterInfo as IFilter);
				}
				else {
					this.parseFilter(this.filter?.filterInfo!.filters![0]);
					this.updateListFilter(listStore, this.compile(listStore));
				}
				this.setFilterInLocalStorage(listStore);
			}
		});

    };
    parseFilter(filter: IFilterStore) {
        let properties = this.property.items.find(item => item.name === filter.attribute);
        this.setProperty(properties ?? null);
        let comparison: Item = { id: ComparisonType.IsNotNull, name: "Заполнено" };
        let comparisonType = filter.comparisonType;
        if (comparisonType! > 1 || comparisonType! === -1) {
            comparison = ComparisonsGroup[filter.rightExpression!.parameter.dataValueType].find(comparison => comparison.id === filter.comparisonType)!
        }
        else {
            if (comparisonType === ComparisonType.IsNotNull) {
                comparison = { id: ComparisonType.IsNotNull, name: "Заполнено" }
            }
            else if (comparisonType === ComparisonType.IsNull) {
                comparison = { id: ComparisonType.IsNull, name: "Не заполнено" }
            }
        }
        this.setComparison(comparison);
        let booleanItem = booleanItems.find(item => item.name === filter.rightExpression!.parameter.value);
        let macros = this.getMacros(filter.rightExpression!.macros);

		let value = booleanItem ? booleanItem : macros ? macros : filter.rightExpression!.parameter.value;


		this.setValue(value);
	};

    getMacros(macros: MacrosType | undefined) {
        if (macros === undefined) {
            return undefined;
        }
        let result: Item | undefined
        DateMacroses?.forEach(i => {
            let multilevelItem = i.multilevelItems?.find((v: any) => {
                return (typeof v.id === "number" && v.id === macros)
            })
            if (multilevelItem !== undefined) {
                result = multilevelItem;
            }
        });
        return result;
    }

	async getFiltersList(entityName: string) {
		let resultItems: Item[] = [];
		// let response = await m.get(`/api/SavedFilters/list?userId=${authStore.userId}&entityName=${entityName}`);
		let response = await api.http.httpApi.savedFilter.filtersList(authStore.userId!,entityName).get();
		runInAction(() => {
			if (response.data.success) {
				let items = (response.data.data as SavedFilter[]).map((item: any) => {
					return (
						{
							...item,
							createdOn: new Date(Date.parse(item.createdOn)),
							modifiedOn: new Date(Date.parse(item.modifiedOn))
						}
					);
				});
				items = sortArray(items, "modifiedOn", 1);
				resultItems = items.map((item: any) => { return { id: item.id, name: item.filterName }; });
				this.favoriteFilters = items.filter(item => item.isFavorite);
			}
		});
		return resultItems;
	};

    setItems(items: Item[] | null, proprtiesInfo?: any[]) {
        if (items) this.property.items = items;
        if (proprtiesInfo) this.property.proprtiesInfo = proprtiesInfo;
    };


    compile(listStore: ListStore): IFilter {
        const value = this.property.propertyValue;
        const property: IPropertyFilter = { Name: value.name!, Type: value.type!, DisplayName: "", LookupTable: value.lookupTable, isNulable: value.isNullable }

        let newFilter;

        if (value.type === DataValueType.DateTime) {
            if (this.comparison.value!.id === ComparisonType.Between) {
                newFilter = DirectorSimpleFilter.CreateAttribute(this.value.value, Number(this.comparison.value!.id), property, listStore.schema);
                this.value.displayValue = this.value.value;
            }
            else if ((this.value.value as Item).id !== undefined) {
                newFilter = DirectorSimpleFilter.CreateMacros(this.value.value.id, property, listStore.schema)
                this.value.displayValue = this.value.value.name;
            }
            else {
                newFilter = DirectorSimpleFilter.CreateDateAttribute(this.value.value, property, listStore.schema);
                this.value.displayValue = new Date(this.value.value).toLocaleString().substring(0, 10);
            }
        }
        else if (value.type === DataValueType.Entity || value.type === DataValueType.Lookup) {
            newFilter = DirectorSimpleFilter.CreateAttribute(itemToLookup(this.value.value), Number(this.comparison.value!.id), property, listStore.schema);
            this.value.displayValue = this.value.value ? this.value.value.name : null;
        }
        else if (value.type === DataValueType.Boolean) {
            newFilter = DirectorSimpleFilter.CreateAttribute(this.value.value.name, Number(this.comparison.value!.id), property, listStore.schema);
            this.value.displayValue = this.value.value.displayValue;
        }
        else if (value.type === DataValueType.Integer || value.type === DataValueType.Float || value.type === DataValueType.Long) {
            newFilter = DirectorSimpleFilter.CreateAttribute(this.value.value, Number(this.comparison.value!.id), property, listStore.schema);
            this.value.displayValue = this.value.value;
        }
        else {
            newFilter = DirectorSimpleFilter.CreateAttribute(this.value.value, Number(this.comparison.value!.id), property, listStore.schema);
            this.value.displayValue = this.value.value;
        }

        this.isApplay = true;
        return newFilter;
    };
    setFilterInLocalStorage(listStore: ListStore, value?: string | null) {
        SimpleFilterStore.setFilter(listStore.schema, (
            value !== undefined)
            ? value : JSON.stringify(this));
    };
    isValid(): boolean {

		return this.property.propertyValue.type !== undefined && (this.comparison.value !== null && ((this.comparison.value!.id === ComparisonType.Between
            && this.value.value.start != null && this.value.value.end != null)
            || this.comparison.value!.id !== ComparisonType.Between && (
                ((this.value.value as Item) && this.value.value.name !== "" && this.value.value.name !== undefined && this.value.value.name !== null)
                ||
                ((this.value.value as Item)?.displayValue === undefined) && this.value.value != null && this.value.value != "")
            ||
            (this.comparison.value!.id == ComparisonType.IsNotNull || this.comparison.value!.id == ComparisonType.IsNull)));
	};

}

const template = new SimpleFilter();

{
	// export const template = observable<SimpleFilterTemplate>({
	//     property: {
	//         items: [],
	//         propertyValue: {
	//             value: null,
	//         },
	//         proprtiesInfo: []
	//     },
	//     comparison: {
	//         items: [],
	//         value: null,
	//     },
	//     value: {
	//         value: null,
	//         displayValue: null,
	//         type: ValueTypes.Between
	//     },
	//     isApplay: false,
	//     filter: null,
	//     placeholder: null,
	//     favoriteFilters: [],
	//     setProperty: (item: IItem | null) => {
	//         template.isApplay = false;
	//         if (item) {
	//             const property = template.property;
	//             const info = template.property.proprtiesInfo[property.items.indexOf(item)];
	//             const type = info.type;
	//             template.value.value = defaultValue[type];
	//             property.propertyValue.type = type;
	//             property.propertyValue.isNullable = info.isNullable;
	//             property.propertyValue.lookupTable = info.lookupTable;
	//             property.propertyValue.name = info.name;
	//             if (type > -1) {

	//                 let collections = ComparisonsGroup[type].slice();

	//                 if (template.property.propertyValue.isNullable) {
	//                     collections.push({ id: ComparisonType.IsNotNull, name: "Заполнено" });
	//                     collections.push({ id: ComparisonType.IsNull, name: "Не заполнено" });
	//                 }
	//                 template.comparison.value = ComparisonsGroup[type][0];
	//                 template.comparison.items = collections;

	//             }
	//             template.property.propertyValue.value = item;
	//         }
	//         else {
	//             template.property = {
	//                 items: template.property.items,
	//                 propertyValue: {
	//                     value: null,
	//                 },
	//                 proprtiesInfo: template.property.proprtiesInfo
	//             };
	//             template.comparison = {
	//                 items: [],
	//                 value: null,
	//             };

	//             template.value = {
	//                 value: null,
	//                 displayValue: null,
	//                 type: ValueTypes.Between
	//             };
	//         }
	//     },

	//     setComparison: (item: IItem | null) => {
	//         template.comparison.value = item;
	//         template.isApplay = false;
	//         if (item) {

	//             if (item.id === ComparisonType.Between) {
	//                 template.value.type = ValueTypes.Between;
	//                 template.value.value = { start: null, end: null };
	//             }
	//             else if (item.id === ComparisonType.IsNull || item.id === ComparisonType.IsNotNull) {
	//                 template.value = {
	//                     value: null,
	//                     displayValue: null,
	//                     type: ValueTypes.Between
	//                 };
	//             }
	//             else {
	//                 template.value.type = ValueTypes.value;
	//             }
	//         }
	//         else {
	//             template.value = {
	//                 value: null,
	//                 displayValue: null,
	//                 type: ValueTypes.Between
	//             };
	//         }
	//     },
	//     new: (listStore: ListStore) => {
	//         template.property = {
	//             items: [],
	//             propertyValue: {
	//                 value: null,
	//             },
	//             proprtiesInfo: []
	//         };

	//         template.comparison = {
	//             items: [],
	//             value: null,
	//         };

	//         template.value = {
	//             value: null,
	//             displayValue: null,
	//             type: ValueTypes.Between
	//         };
	//         template.filter = null;
	//         template.placeholder = null;
	//         template.isApplay = false;
	//         template.loadProperty(listStore)
	//         listStore.load(null);
	//         SimpleFilterStore.setFilter(listStore.schema, null);
	//     },

	//     restore: (listStore: ListStore) => {
	//         const filter = SimpleFilterStore.getFilter(listStore.schema);//filtres[listStore.schema];
	//         if (filter) {
	//             const newValue = JSON.parse(filter) as SimpleFilterTemplate;

	//             if (newValue.filter) {
	//                 template.filter = new Filter(listStore.schema); let myMap = new Map(Object.entries(newValue.filter as Filter));
	//                 myMap.forEach((value, key) => {
	//                     template.filter?.setValue(value, key);
	//                 })
	//             }
	//             else {
	//                 template.filter = newValue.filter;
	//             }
	//             template.property = newValue.property;
	//             template.comparison = newValue.comparison;
	//             template.value = newValue.value;
	//             template.isApplay = true;
	//             template.compile(listStore);

	//             template.setFilterInLocalStorage(listStore);
	//         }
	//         else {
	//             template.new(listStore);
	//         }
	//     },
	//     setValue: (value: any) => {
	//         template.value.value = value;
	//         template.isApplay = false;
	//     },
	//     resetValue: () => {
	//         const type = template.property.propertyValue.type!;
	//         template.value.value = defaultValue[type];
	//         template.isApplay = false;
	//     },
	//     loadProperty: async (listStore: ListStore) => {
	//         let request = await m.post("/api/entity/entityinfo", { entityName: listStore.schema });

	//         template.setItems(null, []);
	//         // template.property.proprtiesInfo = [];
	//         let proprtiesInfo = template.property.proprtiesInfo;
	//         for (let item of request.data.entityInfoResultItems) {
	//             proprtiesInfo.push(
	//                 {
	//                     id: item.columnId,
	//                     type: parseDataValueType(item.columnType),
	//                     lookupTable: item.lookupTable,
	//                     isNullable: !item.isRequired,
	//                     displayValue: item.columnTitle,
	//                     name: item.columnName
	//                 }
	//             );
	//             let newItems = proprtiesInfo.map(((e) => { return { id: e.id, displayValue: e.displayValue, name: e.name } }))
	//             template.setItems(newItems)
	//         }
	//     },
	//     save: async (listStore: ListStore, updateFavorite?: boolean, filterName?: string) => {
	//         if (template.filter === null || template.filter === undefined || filterName) {
	//             template.filter = new Filter(listStore.schema);
	//             template.filter.setValue(filterName, "filterName");
	//             template.placeholder = `${template.filter.filterName} `;
	//         }
	//         template.compile(listStore);
	//         template.filter.setValue(listStore.filter, "filterInfo");
	//         if (updateFavorite) {
	//             template.filter.setValue(!template.filter.isFavorite, "isFavorite");

	//             const result: Filter[] = [];
	//             if (template.favoriteFilters.length > 0) {
	//                 template.favoriteFilters.forEach(item => {
	//                     if (item.id !== template.filter!.id)
	//                         result.push(item);
	//                 })
	//             }
	//             if (template.filter.isFavorite) {
	//                 result.push(template.filter);
	//             }
	//             template.favoriteFilters = result;
	//         }

	//         if (template.filter.validate()) {
	//             if (template.filter.id === null) {
	//                 let request = await m.post("/api/SavedFilters/create", template.filter?.serialize());
	//                 runInAction(() => {
	//                     if (request.success) {
	//                         template.filter?.setValue(request.data, "id");
	//                         template.setFilterInLocalStorage(listStore);
	//                     }
	//                 })
	//             }
	//             else {

	//                 let request = await m.post("/api/SavedFilters/update", template.filter?.serialize());

	//                 if (request.success) {
	//                     template.setFilterInLocalStorage(listStore);
	//                 }
	//             }
	//         }

	//     },
	//     getFilter: async (filterId: string, listStore: ListStore) => {

	//         let request = await m.get(`/api/SavedFilters/get?id=${filterId}`);
	//         runInAction(() => {
	//             if (request.success) {
	//                 template.filter = new Filter(request.data.entityName);
	//                 let myMap = new Map(Object.entries(request.data as Filter));
	//                 myMap.forEach((value, key) => {
	//                     template.filter?.setValue(value, key);
	//                 })
	//                 template.parseFilter(template.filter?.filterInfo!);
	//                 template.compile(listStore);
	//                 template.setFilterInLocalStorage(listStore);

	//             }
	//         })

	//     },
	//     parseFilter: (filter: IFilter) => {
	//         let properties = template.property.items.find(item => item.name === filter.filters![0].attribute);
	//         template.setProperty(properties ?? null);
	//         let comparison: IItem = { id: ComparisonType.IsNotNull, name: "Заполнено" };
	//         let comparisonType = filter.filters![0].comparisonType;
	//         if (comparisonType > 1) {
	//             comparison = ComparisonsGroup[filter.filters![0].rightExpression!.parameter.dataValueType].find(comparison => comparison.id === filter.filters![0].comparisonType)!
	//         }
	//         else {
	//             if (comparisonType === ComparisonType.IsNotNull) {
	//                 comparison = { id: ComparisonType.IsNotNull, name: "Заполнено" }
	//             }
	//             else if (comparisonType === ComparisonType.IsNull) {
	//                 comparison = { id: ComparisonType.IsNull, name: "Не заполнено" }
	//             }
	//         }
	//         template.setComparison(comparison);
	//         let value = filter.filters![0].rightExpression!.parameter.value;
	//         template.setValue(value);
	//     },

	//     getFiltersList: async (entityName: string) => {
	//         let resultItems: IItem[] = [];
	//         let response = await m.get(`/api/SavedFilters/list?userId=${authStore.userId}&entityName=${entityName}`);
	//         runInAction(() => {
	//             if (response.success) {
	//                 let items = (response.data as Filter[]).map((item: any) => {
	//                     return (
	//                         {
	//                             ...item,
	//                             createdOn: new Date(Date.parse(item.createdOn)),
	//                             modifiedOn: new Date(Date.parse(item.modifiedOn))
	//                         }
	//                     )
	//                 });
	//                 items = sortArray(items, "modifiedOn", 1)
	//                 resultItems = items.map((item: any) => { return { id: item.id, name: item.filterName } });
	//                 template.favoriteFilters = items.filter(item => item.isFavorite);
	//                 // console.log(items, items.sort((a, b) => {
	//                 //     if (a.modifiedOn! < b.modifiedOn!) {
	//                 //         return 1;
	//                 //     }
	//                 //     if (a.modifiedOn! > b.modifiedOn!) {
	//                 //         return -1;
	//                 //     }
	//                 //     return 0;
	//                 // }),
	//                 // sortArray(items,"modifiedOn",1));

	//                 // sortArray


	//             }
	//         })
	//         return resultItems;
	//     },
	//     setItems: (items: IItem[] | null, proprtiesInfo?: any[]) => {
	//         if (items) template.property.items = items;
	//         if (proprtiesInfo) template.property.proprtiesInfo = proprtiesInfo;
	//     },
	//     compile: (listStore: ListStore, filter?: IFilter) => {
	//         const value = template.property.propertyValue;
	//         const property: IPropertyFilter = { Name: value.name!, Type: value.type!, DisplayName: "", LookupTable: value.lookupTable, isNulable: value.isNullable }

	//         let newFilter;
	//         if (filter !== undefined)
	//             newFilter = filter;
	//         else {
	//             if (value.type === DataValueType.DateTime) {
	//                 if (template.comparison.value!.id === ComparisonType.Between) {
	//                     newFilter = DirectorSimpleFilter.CreateBetweenDate(template.value.value.start, template.value.value.end, property, listStore.schema)
	//                     template.value.displayValue = template.value.value.start + " - " + template.value.value.end;
	//                 }
	//                 else if ((template.value.value as IItem).id !== undefined) {
	//                     newFilter = DirectorSimpleFilter.CreateMacros(template.value.value.id, property, listStore.schema)
	//                     template.value.displayValue = template.value.value.value;
	//                 }
	//                 else {
	//                     newFilter = DirectorSimpleFilter.CreateDateAttribute(template.value.value, property, listStore.schema);
	//                     template.value.displayValue = new Date(template.value.value).toLocaleString().substring(0, 10);
	//                 }
	//             }
	//             else if (value.type === DataValueType.Entity || value.type === DataValueType.Lookup) {
	//                 newFilter = DirectorSimpleFilter.CreateAttribute(itemToLookup(template.value.value), Number(template.comparison.value!.id), property, listStore.schema);
	//                 template.value.displayValue = template.value.value ? template.value.value.name : null;
	//             }
	//             else if (value.type === DataValueType.Boolean) {
	//                 newFilter = DirectorSimpleFilter.CreateAttribute(template.value.value.name, Number(template.comparison.value!.id), property, listStore.schema);
	//                 template.value.displayValue = template.value.value.name;
	//             }
	//             else if (value.type === DataValueType.Integer || value.type === DataValueType.Float || value.type === DataValueType.Long) {
	//                 if (template.comparison.value!.id === ComparisonType.Between) {
	//                     newFilter = DirectorSimpleFilter.CreateBetweenNumber(Number(template.value.value.start), Number(template.value.value.end), property, listStore.schema)
	//                     template.value.displayValue = template.value.value.start + " - " + template.value.value.end;
	//                 }
	//                 else {
	//                     newFilter = DirectorSimpleFilter.CreateAttribute(Number(template.value.value), Number(template.comparison.value!.id), property, listStore.schema);
	//                     template.value.displayValue = template.value.value;
	//                 }
	//             }
	//             else {
	//                 if (template.comparison.value!.id === ComparisonType.Between) {
	//                     newFilter = DirectorSimpleFilter.CreateBetweenNumber(template.value.value.start, template.value.value.end, property, listStore.schema)
	//                     template.value.displayValue = template.value.value.start + " - " + template.value.value.end;
	//                 }
	//                 else {
	//                     newFilter = DirectorSimpleFilter.CreateAttribute(template.value.value, Number(template.comparison.value!.id), property, listStore.schema);
	//                     template.value.displayValue = template.value.value;
	//                 }
	//             }
	//         }



	//         template.isApplay = true;
	//         listStore.load(newFilter);
	//         // console.log(JSON.stringify(template));
	//         if (filter !== undefined)
	//             template.parseFilter(newFilter);
	//         template.placeholder = template.filter ? `${template.filter.filterName} ` : `${template.property.propertyValue.value?.displayValue!} : ${template.comparison.value!.name} ${template.value.displayValue ? "«" + template.value.displayValue + "»" : ""} `;
	//     },
	//     setFilterInLocalStorage: (listStore: ListStore) => {
	//         SimpleFilterStore.setFilter(listStore.schema, JSON.stringify(template));
	//     },
	//     isValid: (): boolean => {

	//         return template.property.propertyValue.type !== undefined && (template.comparison.value !== null && ((template.comparison.value!.id === ComparisonType.Between
	//             && template.value.value.start != null && template.value.value.end != null)
	//             || template.comparison.value!.id !== ComparisonType.Between && (
	//                 ((template.value.value as IItem) && template.value.value.value !== "" && template.value.value.value !== undefined && template.value.value.value !== null)
	//                 ||
	//                 ((template.value.value as IItem)?.displayValue === undefined) && template.value.value != null && template.value.value != "")
	//             ||
	//             (template.comparison.value!.id == ComparisonType.IsNotNull || template.comparison.value!.id == ComparisonType.IsNull)));
	//     }
	// });

}


export default template;
