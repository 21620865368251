import { useEffect, useRef } from "react";

import { binding } from "module/reactor/binding/binding";
import IProps from "../../IProps";
import ImportStore from "entities/import/ImportStore";

import Files from "features/full-view/files/files/files";

import styles from "./file-upload.module.css";

interface FileUploadProps extends IProps {
    entitySchema: string
}

function FileUploadReactor(props: FileUploadProps) {
    const entity = binding(props.services, props.entitySchema);

    const importStore = useRef<any>(new ImportStore(entity));

    useEffect(() => {
        if (importStore !== null) {
            const setMaxSizeFileInImortStore = async () => { await importStore.current.getMaxFileSize() };
            setMaxSizeFileInImortStore();
        }
    }, [importStore]);

    return (
        <div className={styles.wrapper}>
            <Files importStore={importStore.current} />
        </div>
    )
}

export default FileUploadReactor;