import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import authStore from 'AuthStore';
import { synchroiser } from 'synchroiser';
import { dispatcher, selector } from 'store';
import FilterBuilder from 'app/services/filter/FilterBuilder';

import { RenameLayout } from '../filter-popups';
import { modalController } from 'features/modals';
import { StaticGroupBlockFolderTree } from './static-group-block-folder-tree/static-group-block-folder-tree';

import { Button, ButtonStyle, InputSearch } from 'components';

import { StaticGroup } from 'types/entity';

import { CloseMaxi, Plus } from 'assets/icons';

import styles from './static-group-block.module.css';

type StaticGroupBlockProps = {
    onCancel: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const StaticGroupBlock = observer(function (props: StaticGroupBlockProps) {
    const [idModal] = useState<string>(v4());
    const [selected, setSelected] = useState<StaticGroup | null>(null);
    const [isDisableButton, setDisableButton] = useState<boolean>(false);

    const isDisabledAddButton = useMemo(() => {
        return !selected || isDisableButton;
    }, [selected, isDisableButton]);

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            updateGroupFolder();
        }
    }, []);

    const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e?.stopPropagation();
        modalController.modalRemove(idModal);
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const addNewStaticGroupPopup = useCallback(() => {
        modalController.popupAdd({
            id: idModal,
            layout: <RenameLayout
                dialogTitle='Новая статическая группа'
                captionSuccessfulButton='Сохранить'
                startName={null}
                onRename={saveNewStaticGroup}
                onClose={closeConfirm}
            />,
            closeFunc: closeConfirm
        });
    }, []);

    const setStaticGroup = useCallback((value: StaticGroup | null) => {
        setSelected(value);
    }, []);

    const updateGroupFolder = useCallback(async () => {
        if (selected) {
            setDisableButton(true);
            const currentEntity = dispatcher.entity.get();
            if (currentEntity) {
                const filter = new FilterBuilder(currentEntity.entityName);

                const currentSavedFilter = selector.filter.getFilter()?.savedFilter;
                if (currentSavedFilter && currentSavedFilter.filterInfo && currentEntity.entity.includedIds.length == 0) {
                    filter.filter = currentSavedFilter.filterInfo.serialize();
                }

                filter.filter.includedIds = currentEntity.entity.includedIds.map(e => e.id);
                filter.filter.excludedIds = currentEntity.entity.excludedIds.map(e => e.id);
                selected.filter = filter.filter;

                await synchroiser.updateStaticGroup(selected);
                handleCancel();
            }
            setDisableButton(false);
        }

    }, [selected, dispatcher.entity.get()]);

    const handleCancel = useCallback(() => {
        dispatcher.entity.onChangeCheckedAll(false);
        props.onCancel();
        setSelected(null);
    }, []);

    const saveNewStaticGroup = useCallback(async (groupName: string | null) => {
        if (groupName) {
            const newGroup: StaticGroup = {
                userId: authStore.userId,
                entityName: dispatcher.entity.get()?.entityName!,
                name: groupName,
                filter: null,
                folderId: null,
                modifiedOn: '',
                createdOn: ''
            }
            await synchroiser.saveStaticGroup(newGroup).then(async () => {
                await synchroiser.getStaticGroupFolderTree();
            });
        }
    }, []);

    return (
        <div className={styles.dialog}>
            <div className={styles.header}>
                <span className={styles.title}>Добавление записей в группу</span>
                <CloseMaxi
                    className={styles.closeButton}
                    onClick={handleCancel} />

            </div>
            <div className={styles.dialogBody}>
                <span className={styles.subtitle}>
                    Отметьте необходимые статические группы
                </span>
                <InputSearch
                    classNameInput={styles.search}
                    value={null}
                    placeholder={'Поиск'}
                    onChangeValue={(value: string) => { }}
                />
                <div className={styles.folderTree}>
                    <StaticGroupBlockFolderTree
                        staticGroupFolder={selector.filter.getFilterTree()?.staticGroupFolderTree}
                        selected={selected}
                        setSelected={setStaticGroup}
                    />
                </div>
            </div>
            <div className={styles.devider} />
            <div className={styles.dialogFooterButtons}>
                <Button
                    caption={'Новая группа'}
                    onClick={addNewStaticGroupPopup}
                    style={ButtonStyle.Link}
                    firstIcon={<Plus width={18} height={18} />}
                    classNameFirstIcon={styles.addGroupIcon}
                />
                <div className={styles.dialogFooterButtonsRight}>
                    <Button
                        caption={'Отмена'}
                        onClick={handleCancel}
                        style={ButtonStyle.Subtle}
                    />
                    <Button
                        caption={'Добавить'}
                        onClick={updateGroupFolder}
                        style={ButtonStyle.Primary}
                        isDisabled={isDisabledAddButton}

                    />
                </div>
            </div>
        </div>
    );
});

export default StaticGroupBlock;
