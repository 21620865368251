import eventBus from "app/services/EventBus";
// import api from "app/services/api";
import FileStorages from "entities/FileStorages";
import { makeAutoObservable } from "mobx";
import FileStoreEvents from "../types/FileStoreEvents";
import * as signalR from "@microsoft/signalr";
import { getSignalRUrl } from "app/services/UrlService";
import { api } from "api";

export enum CloudStoreState {
    NotConnecting = "NotConnecting",
    Connecting = "Connecting",
    Connected = "Connected",
    Error = "Error",
    Switch = "Switch"
}

export enum LoadStates {
    Progress = 0,
    Success = 1,
    Waiting = 3,
    Failure = 4
}

export interface ILoadResult {
    state: LoadStates;
    percent: number;
}


class CloudStoreService {
	endpoint: string = "";
	accessKey: string = "";
	secretKey: string = "";
	bucketName: string = "";

	loadResult: ILoadResult = { state: LoadStates.Waiting, percent: 0 };
	connection: signalR.HubConnection | null = null;

	state: CloudStoreState = CloudStoreState.NotConnecting;

	constructor() {
		makeAutoObservable(this);
		this.getSettings();
		this.health();
		this.initSignalR();

		eventBus.registerHandler(FileStoreEvents.Swithing, (event) => this.swithingHandler(event));
	}

	async initSignalR() {
		let url = getSignalRUrl();
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(`${url}/transferhub`)
		//.configureLogging(signalR.LogLevel.Trace) don't remove
			.build();

		await this.connection.start();

		await this.connection.invoke("JoinRoom", "Transfer");
		this.connection.on("Receive", (data) => {
			//console.log("Received transfer progress:", data);
			cloudStoreService.loadResult = { state: data.state, percent: data.precent };
			//console.log(cloudStoreService.loadResult);    
		});

	}

	swithingHandler(event: boolean) {
		if (event) {
			this.state = CloudStoreState.Switch;
		}
		else {
			this.health();
		}
	}

	setEndpoint(value: string) {
		this.endpoint = value;
	}
	setAccessKey(value: string) {
		this.accessKey = value;
	}
	setSecretKey(value: string) {
		this.secretKey = value;
	}
	setBucketName(value: string) {
		this.bucketName = value;
	}

	async health() {
		// const response = await api.get("/api/file/health");
		const response = await api.http.httpApi.file.fileHealth().get();
		if (response && response.data.Success && response.data) {
			this.state = CloudStoreState.Connected;
		}
	}

	async uploadSettings() {
		this.state = CloudStoreState.Connecting;
		// const response = await api.post("/api/file/editS3", {
		// 	endpoint: this.endpoint,
		// 	accessKey: this.accessKey,
		// 	secretKey: this.secretKey,
		// 	bucketName: this.bucketName
		// });
		const response =await api.http.httpApi.file.editS3().post({
			endpoint: this.endpoint,
			accessKey: this.accessKey,
			secretKey: this.secretKey,
			bucketName: this.bucketName
		});
       
		if (response.data.success) {
			this.state = CloudStoreState.Connected;
		}
		else {
			this.state = CloudStoreState.Error;
		}
	}

	async swithStore(isMoveFile: boolean) {
		// const response = await api.put("/api/file/editSource", { sourceId: FileStorages.S3, needTransferFiles: isMoveFile });
		const response = await api.http.httpApi.file.editSource().put({ sourceId: FileStorages.S3, needTransferFiles: isMoveFile });
		eventBus.dispatchEvent(FileStoreEvents.Switch, FileStorages.S3);
	}

	async getSettings() {
		// const response = await api.get("/api/file/sourceInfo");
		// this.accessKey = response.s3ConnectionSettings.accessKey;
		// this.bucketName = response.s3ConnectionSettings.bucketName;
		// this.endpoint = response.s3ConnectionSettings.endpoint;
		// this.secretKey = response.s3ConnectionSettings.secretKey;
	}
}

const cloudStoreService = new CloudStoreService();

export default cloudStoreService;