import {useMemo} from "react";
import classNames from "classnames";

import styles from "./style.module.scss";

type Props = {
    name: string;
    children?: JSX.Element;
    order: number; // -1 - начальная, 0 - промежуточная, 1 - конечная
    color: string;
    passed?: boolean,
    disabled?: boolean;
    onClick?: () => void;
    overflowClipDisable?: boolean;
}

export function Stage(props: Props) {

    const cxLeft = useMemo(() => classNames(styles.left, {
        [`${styles.startLeft}`]: props.order === -1,
        [`${styles.intemidateLeft}`]: props.order === 0,
        [`${styles.endLeft}`]: props.order === 1,
        [`${styles.effects}`]: props.passed,
        [`${styles.default}`]: !props.passed,
    }), [props.order, props.passed])

    const cxRight = useMemo(() => classNames(styles.right, {
        [`${styles.startRight}`]: props.order === -1,
        [`${styles.intemidateRught}`]: props.order === 0,
        [`${styles.endRight}`]: props.order === 1,
        [`${styles.effects}`]: props.passed,
        [`${styles.default}`]: !props.passed,
    }), [props.order, props.passed])

    const cxCenter = useMemo(() => classNames(styles.center, {
        [`${styles.effects}`]: props.passed,
        [`${styles.default}`]: !props.passed,
    }), [props.passed])

    return (
        <div style={{
            cursor: props.disabled ? "not-allowed" : "pointer",
        }}>
            <div onClick={props?.onClick} style={{
                pointerEvents: props.disabled ? "none" : "auto",
            }} className={styles.container}>
                <div className={cxLeft} style={{
                    borderTopColor: props.color,
                    borderRightColor: props.color,
                    borderBottomColor: props.color,
                    borderLeftColor: props.order === -1 ? props.color : "transparent"
                }}></div>
                <div className={cxCenter} style={{backgroundColor: props.color}}></div>
                <div className={cxRight} style={{
                    borderColor: props.order === 1 ? props.color : "transparent",
                    borderLeftColor: props.color
                }}></div>
                <div style={{
                    overflow: props.overflowClipDisable ? "visible" : "clip",
                }} className={styles.content}>
                    {props.children ?? <div className={classNames(styles.name, !props.passed && styles.default)}
                                            style={{color: props.color}}>{props.name}</div>}
                </div>
            </div>
        </div>
    )
}