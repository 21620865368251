import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { isUndefined } from "lodash";
import classNames from "classnames";
import { toJS } from "mobx";
import { v4 } from "uuid";

import { dispatcher, store } from "store";
import {
	detailConfigurationStore, fieldConfigurationStore,
	fieldGroupConfigurationStore, FieldConfigurationWrapper, FieldGroupConfiguration, DetailConfiguration
} from "pages/section-wizzard/pages/constructor";

import { Zone } from "modules/DND/zone";
import { Types } from "modules/DND/type";
import { Element } from "modules/DND/element";

import { modalController } from "features/modals";
import { IDragElement } from "../../drag-zone";

import { Button, ButtonStyle } from "components";
import { multiTypeDragBlocks } from "..";

import { DEFAULT_WARNING_TEXT } from "pages/section-wizzard/data/data";
import { GridItem } from "types/entity";

import { POST_INIT_TIME } from "../../../../constants";

import TypeIcon from "features/type-icon/type-icon";
import { Close, DragIcon, Rename, FieldGroupIcon, Warning, ChangeView } from "assets/icons";

import styles from "./drag-blocks.module.css";

export interface DragFieldBlockProps {
	element: GridItem,
	icon: JSX.Element,
	width: string,
	boxColumns?: GridItem[],
	onEdit: (columnId: string) => void,
	onDelete: (gridItemId: string) => void
}
/**
 * @description Задержка перед повторной инициализацией zone, нужно для пост инициализации после проигрывания анимации
 */
export const DragFieldBlock = observer((props: DragFieldBlockProps) => {
	const editButtonClasses = classNames(`${styles.editButton} `);
	const deleteButtonClasses = classNames(`${styles.deleteButton} `);

	const isVisibleEditButton = useMemo(() => {
		if (props.element.fieldConfig) {
			if (props.boxColumns) {
				if (props.boxColumns.find(column => column.fieldConfig?.columnId === props.element.fieldConfig?.columnId))
					return false;
			} else if (props.element.fieldConfig.columnName === "Id" ||
				props.element.fieldConfig.columnName === "CreatedOn" ||
				props.element.fieldConfig.columnName === "ModifiedOn" ||
				props.element.fieldConfig.columnName === "ExternalId") {
				return false;
			}
		}
		return true;
	}, [props.element, props.boxColumns]);

	const handleMouseDown = useCallback((e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		e.stopPropagation();
	}, []);

	const fieldClassName = classNames(styles.fieldWrapper, {
		[`${styles.fillField} `]: props.element?.fieldConfig?.columnTitle?.length ?? false,
	});

	return (
		<div style={{ width: props.width }} className={fieldClassName}>
			<DragIcon style={{ stroke: "var(--color-gray-300)" }} />
			{props.icon}
			<span className={styles.fieldCaption}>
				{props.element.fieldConfig?.isRequired && <span className={styles.star}>* </span>}
				{props.element.fieldConfig?.columnTitle ?? ""}
			</span>
			<div className={styles.buttonsInField}>
				<Button
					style={ButtonStyle.Icon}
					firstIcon={<Rename />}
					className={editButtonClasses}
					onClick={() => props.onEdit(props.element.fieldConfig?.columnId!)}
					onMouseDown={handleMouseDown}
					isVisible={isVisibleEditButton}
				/>
				<Button
					style={ButtonStyle.Icon}
					firstIcon={<Close />}
					className={deleteButtonClasses}
					onClick={() => props.onDelete(props.element.gridItemId)}
					onMouseDown={handleMouseDown}
				/>
			</div>
		</div>
	);
});

//TODO прикрутить еще стрелочку дропдаун
export const DragFieldGroupBlock = (props: {
	id: string,
	elements: Array<GridItem>,
	groupName: string,
	groupField: GridItem,
	targetZone?: string
}) => {
	const [rowQuality, setRowQuality] = useState(1);
	const [targetElementViewLayout, setTargetElementViewLayout] = useState<JSX.Element>(<></>);

	const editButtonClasses = classNames(`${styles.editButton} `);
	const deleteButtonClasses = classNames(`${styles.deleteButton} `);

	const [idModal] = useState<string>(v4());
	const [idConfirm] = useState<string>(v4());
	const WARNING_TEXT = "Поля внутри группы тоже будут удалены.\nУдалить группу полей?";

	useEffect(() => {
		if (props.elements.length > 0) {
			const maxYElement = props.elements.reduce((maxYElement, currentElement) => maxYElement.y > currentElement.y ? maxYElement : currentElement);
			setRowQuality(maxYElement.y === -1 ? 1 : maxYElement.y + 1);
		}
	}, [toJS(props.elements)]);

	const container = useRef<HTMLDivElement>(null);

	const cellLayoutWidth = useMemo(() => {
		if (container.current) {
			return `${container.current?.getBoundingClientRect().width / 2}px`;
		}
		return "100%";
	}, [container.current?.getBoundingClientRect().width]);

	const innerGrid = useMemo(() =>
		props?.elements && props.elements.map((element: GridItem) => {
			if (isUndefined(element.fieldConfig?.columnType)) {
				return <></>;
			}
			const gridItem = {
				gridItemId: element.gridItemId,
				columnId: element.fieldConfig?.columnId!,
				title: element.fieldConfig?.columnTitle!,
				x: element.x,
				y: element.y,
				width: element.width,
				height: element.height,
				type: element.fieldConfig?.columnType,
				fieldConfig: element.fieldConfig,
				groupFieldsConfig: element.groupFieldsConfig,
			};

			// @ts-ignore
			const GridItem = multiTypeDragBlocks[element.fieldConfig.columnType] ?? ((props: any) => <></>);

			return (
				<Element
					key={element.gridItemId}
					id={element.gridItemId}
					x={element.x}
					y={element.y}
					width={element.width}
					height={element.height}
				>
					{/* @ts-ignore */}
					<GridItem
						element={gridItem}
						width={`calc(${cellLayoutWidth} - 15px)`}
						targetZone={props.id}
					/>
				</Element>
			);
		}), [props.elements]
	);

	const handleDrop = useCallback(({
		elementId,
		sourceZone,
		targetZone,
		MATRIX
	}: {
		elementId: string | null;
		sourceZone: string | null;
		targetZone: string | null;
		type: Types;
		sourceData?: any;
		[key: string]: any;
	}) => {
		if (MATRIX.cellY === rowQuality) {
			setRowQuality(rowQuality + 1);
		}

		if (rowQuality <= MATRIX.cellY + 1) {
			setRowQuality(MATRIX.cellY === -1 ? 1 : MATRIX.cellY + 1);
		}

		if (props.elements) {
			const inFieldGroup = props.elements.find(({ gridItemId: id, groupFieldsConfig }) => id ?? groupFieldsConfig?.groupFieldId === targetZone);

			if (inFieldGroup) {
				const dragElement = props.elements.find(({ gridItemId }) => gridItemId === elementId);

				const innerDragElement = inFieldGroup?.groupFieldsConfig?.inner?.items.find(({ gridItemId }) => gridItemId === elementId);
				if (dragElement) {
					const gridItem: GridItem = {
						...dragElement,
						x: MATRIX.cellX,
						y: MATRIX.cellY,
						gridItemId: dragElement?.gridItemId,
						width: dragElement?.width,
						height: dragElement?.height,
					};
					dispatcher.sectionWizzard.autoPut({
						tabIndex: dispatcher.sectionWizzard.getSectionWizzard()?.reactorConfig.tabs.currentTab ?? 0,
						targetId: targetZone || "",
						sourceId: sourceZone,
						item: gridItem
					});
				} else {
					if (innerDragElement) {
						const gridItem: GridItem = {
							...innerDragElement,
							x: MATRIX.cellX,
							y: MATRIX.cellY,
							gridItemId: innerDragElement?.gridItemId,
							width: innerDragElement?.width,
							height: innerDragElement?.height
						};
						dispatcher.sectionWizzard.autoPut({
							tabIndex: dispatcher.sectionWizzard.getSectionWizzard()?.reactorConfig.tabs.currentTab ?? 0,
							targetId: targetZone || "",
							sourceId: sourceZone,
							item: gridItem
						});
					}
				}

			} else {
				const dragElement = props.elements.find(({ gridItemId }) => gridItemId === elementId);
				if (dragElement) {
					const gridItem: GridItem = {
						...dragElement,
						x: MATRIX.cellX,
						y: MATRIX.cellY,
						gridItemId: dragElement?.gridItemId,
						width: dragElement?.width,
						height: dragElement?.height
					};
					dispatcher.sectionWizzard.autoPut({
						tabIndex: dispatcher.sectionWizzard.getSectionWizzard()?.reactorConfig.tabs.currentTab ?? 0,
						targetId: targetZone || "",
						sourceId: sourceZone,
						item: gridItem
					});
				}
			}
		}
	}, [props.elements, rowQuality]);

	const handleUp = useCallback(({ elementId }: { elementId: string }) => {
		if (props.elements) {
			const item = props.elements.find((element) => element.gridItemId === elementId);

			if (item) {
				const fieldClassName = classNames(styles.fieldWrapper, {
					[`${styles.fillField} `]: item?.fieldConfig?.columnTitle?.length ?? false,
				});

				setTargetElementViewLayout(
					<div style={{
						transform: "rotate(-3deg) translateX(-12px) translateY(-20px)",
						padding: "0px 8px",
						alignItems: "center",
						gap: "8px",
						flexShrink: 0,
						borderRadius: "8px",
						boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.14)",
						width: cellLayoutWidth !== "100%" ? `calc(${cellLayoutWidth} * 0.4)` : "40%",
						minHeight: "40px"
					}} key={item.gridItemId} className={fieldClassName}>
						<DragIcon />
						<TypeIcon type={item?.fieldConfig?.columnType ?? "GroupField"} />
						<span style={{
							color: "var(--color-indigo-800)",
							fontFamily: "var(--font-roboto-400)",
							fontSize: "14px",
						}} >{item.fieldConfig?.columnTitle}</span>
					</div >
				);
			}
		}

	}, [cellLayoutWidth, props.elements.map(element => element)]);


	const cellLayout = useMemo(() => {
		const fixedHeight = 48;
		const hardStyle = {
			width: cellLayoutWidth !== "100%" ? `calc(${cellLayoutWidth} - 20px)` : "100%",
			height: `${fixedHeight}px`
		};

		return (
			<div style={hardStyle} >
				<div className={styles.fieldWrapper}></div>
			</div>);
	}, [cellLayoutWidth]);

	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	const isNew = useMemo(() => {
		return dispatcher.entity.get()?.isNew;
	}, [dispatcher.entity.get()?.isNew]);

	const currentTabIndex = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
	}, [sectionWizzard?.reactorConfig.tabs.currentTab]);

	const handleDeleteGroupClick = useCallback(() => {
		if (!isNew) {
			const newItem: GridItem = {
				...props.groupField as unknown as GridItem,
				gridItemId: props.groupField.gridItemId ?? "",
				x: -1,
				y: -1
			};
			dispatcher.sectionWizzard.getAllGridItems().forEach(item => {
				if (item.gridItemId === newItem.gridItemId) {
					item.x = -1;
					item.y = -1;
				}
			});
		} else {
			dispatcher.sectionWizzard.deleteGridItemFromTab(props.groupField.gridItemId, currentTabIndex, props.targetZone);
		};
		closeAllModals();
	}, [currentTabIndex, isNew, props.groupField, props.targetZone]);

	const handleMouseDown = useCallback((e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		e.stopPropagation();
	}, []);

	const closeConfirm = useCallback(() => {
		modalController.modalRemove(idConfirm);
	}, []);

	const closeFuncWithConfirm = useCallback(() => {
		modalController.popupAdd({ id: idConfirm, layout: warningConfirm, closeFunc: closeConfirm });
	}, [idConfirm]);

	const closeAllModals = useCallback(() => {
		store.modals.map((modal) => { modalController.modalRemove(modal.id); });
		fieldConfigurationStore.resetConfiguration();
		detailConfigurationStore.resetConfiguration();
		fieldGroupConfigurationStore.resetConfiguration();
	}, [store.modals]);

	const warningDeleteConfirm = useMemo(() => {
		return <div className={styles.warningDialog}>
			<div className={styles.warningHeader}>
				<span className={styles.warningTitle}>Внимание</span>
				<Warning />
			</div>
			<div className={styles.warningDialogBody}>
				<span className={styles.title}>{WARNING_TEXT}</span>
			</div>
			<div className={styles.dialogFooter}>
				<Button caption="Вернуться к редактированию" onClick={closeConfirm} style={ButtonStyle.Subtle} />
				<Button caption="Удалить" onClick={handleDeleteGroupClick} style={ButtonStyle.Danger} />
			</div>
		</div>;
	}, [closeAllModals, closeConfirm, dispatcher.entity.get()?.entity.sectionWizzard]);

	const warningConfirm = useMemo(() => {
		return <div className={styles.warningDialog}>
			<div className={styles.warningHeader}>
				<span className={styles.warningTitle}>Внимание</span>
				<Warning />
			</div>
			<div className={styles.warningDialogBody}>
				<span className={styles.title}>{DEFAULT_WARNING_TEXT}</span>
			</div>
			<div className={styles.dialogFooter}>
				<Button caption="Вернуться к редактированию" onClick={closeConfirm} style={ButtonStyle.Subtle} />
				<Button caption="Да, отменить" onClick={closeAllModals} style={ButtonStyle.Danger} />
			</div>
		</div>;
	}, [closeAllModals, closeConfirm, dispatcher.entity.get()?.entity.sectionWizzard]);

	const handleDeleteClick = useCallback(() => {
		modalController.popupAdd({ id: idConfirm, layout: warningDeleteConfirm, closeFunc: closeConfirm });
	}, [idConfirm]);

	const closeOnFocusModal = () => {
		if (fieldGroupConfigurationStore.hasChanges) {
			closeFuncWithConfirm()
		} else {
			closeAllModals()
		}
	};

	const handleEditClick = useCallback(() => {
		modalController.popupAdd({
			id: idModal,
			layout: <FieldGroupConfiguration
				// targetZone={props.targetZone}
				position={{ cellX: -1, cellY: -1 }}
				fieldGroupId={props.groupField.gridItemId}
				close={closeFuncWithConfirm}
				closeAll={closeAllModals}
			/>,
			closeFunc: closeOnFocusModal
		});
	}, [closeAllModals, closeFuncWithConfirm, idModal, props.groupField.gridItemId, props.groupField.fieldConfig?.columnType, props.targetZone]);

	return (
		<div ref={container} className={styles.fieldGroupWrap}>
			<div className={styles.fieldGroupHead}>
				<DragIcon style={{ stroke: "var(--color-gray-300)" }} />
				<FieldGroupIcon style={{ stroke: "var(--color-indigo-800)" }} />
				<span className={styles.indigo800TitleGroup}>Группа полей: </span>
				<span className={styles.fieldCaption}>{props.groupName} </span>
				<div className={styles.buttonsInGroupField}>
					<Button
						style={ButtonStyle.Icon}
						firstIcon={<Rename />}
						className={editButtonClasses}
						onClick={handleEditClick}
						onMouseDown={handleMouseDown}
						isVisible={true}
					/>
					<Button
						style={ButtonStyle.Icon}
						firstIcon={<Close />}
						className={deleteButtonClasses}
						onClick={handleDeleteClick}
						onMouseDown={handleMouseDown}
					/>
				</div>
			</div>
			<Zone
				id={props.id}
				config={{
					targetElementViewLayout: targetElementViewLayout,
					cellLayout: cellLayout,
					placeholderLayout: cellLayout,
					predictLayout: <div style={{
						borderRadius: "4px",
						border: "1px solid var(--color-gray-300)",
						background: "var(--color-indigo-50)",
						width: "calc(100% - 40px)",
						height: "26px",
						padding: "10px",
						margin: "5px",
						marginBottom: 0,
						gap: "12px",
					}}
					/>,
					width: 2,
					height: rowQuality,
				}}
				type={Types.MATRIX}
				postInitTime={POST_INIT_TIME}
				onDrop={handleDrop}
				onUp={handleUp}
			>
				{innerGrid || []}
			</Zone>

		</div>
	);
};

export const DragDetailBlock = (props: { element: GridItem }) => {
	const WARNING_TEXT = "Вы действительно хотите удалить деталь?";
	const editButtonClasses = classNames(`${styles.editButton} `);
	const deleteButtonClasses = classNames(`${styles.deleteButton} `);
	const [idModal] = useState<string>(v4());
	const [idConfirm] = useState<string>(v4());

	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	const currentTabIndex = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
	}, [sectionWizzard?.reactorConfig.tabs.currentTab]);

	const closeConfirm = useCallback(() => {
		modalController.modalRemove(idConfirm);
	}, []);

	const closeFuncWithConfirm = useCallback(() => {
		modalController.popupAdd({ id: idConfirm, layout: warningConfirm, closeFunc: closeConfirm });
	}, [idConfirm]);

	const closeAllModals = useCallback(() => {
		store.modals.map((modal) => { modalController.modalRemove(modal.id); });
		fieldConfigurationStore.resetConfiguration();
		detailConfigurationStore.resetConfiguration();
		fieldGroupConfigurationStore.resetConfiguration();
	}, [store.modals]);

	const handleDeleteDetailClick = useCallback(() => {
		dispatcher.sectionWizzard.deleteGridItemFromTab(props.element.gridItemId, currentTabIndex);
		closeAllModals();
	}, [currentTabIndex, props.element]);

	const warningDeleteConfirm = useMemo(() => {
		return <div className={styles.warningDialog}>
			<div className={styles.warningHeader}>
				<span className={styles.warningTitle}>Внимание</span>
				<Warning />
			</div>
			<div className={styles.warningDialogBody}>
				<span className={styles.title}>{WARNING_TEXT}</span>
			</div>
			<div className={styles.dialogFooter}>
				<Button caption="Отменить" onClick={closeConfirm} style={ButtonStyle.Subtle} />
				<Button caption="Удалить" onClick={handleDeleteDetailClick} style={ButtonStyle.Danger} />
			</div>
		</div>;
	}, [closeAllModals, closeConfirm, dispatcher.entity.get()?.entity.sectionWizzard]);

	const warningConfirm = useMemo(() => {
		return <div className={styles.warningDialog}>
			<div className={styles.warningHeader}>
				<span className={styles.warningTitle}>Внимание</span>
				<Warning />
			</div>
			<div className={styles.warningDialogBody}>
				<span className={styles.title}>{DEFAULT_WARNING_TEXT}</span>
			</div>
			<div className={styles.dialogFooter}>
				<Button caption="Вернуться к редактированию" onClick={closeConfirm} style={ButtonStyle.Subtle} />
				<Button caption="Да, отменить" onClick={closeAllModals} style={ButtonStyle.Danger} />
			</div>
		</div>;
	}, [closeAllModals, closeConfirm, dispatcher.entity.get()?.entity.sectionWizzard]);

	const handleDeleteClick = useCallback(() => {
		modalController.popupAdd({ id: idConfirm, layout: warningDeleteConfirm, closeFunc: closeConfirm });
	}, [idConfirm]);

	const closeOnFocusModal = () => {
		if (fieldGroupConfigurationStore.hasChanges) {
			closeFuncWithConfirm()
		} else {
			closeAllModals()
		}
	};

	const handleEditClick = useCallback(() => {
		modalController.popupAdd({
			id: idModal,
			layout: <DetailConfiguration
				position={{ cellX: -1, cellY: -1 }}
				detailId={props.element.gridItemId}
				close={closeFuncWithConfirm}
				closeAll={closeAllModals}
			/>,
			closeFunc: closeOnFocusModal
		});
	}, [closeAllModals, closeFuncWithConfirm, idModal, props.element.gridItemId, props.element.fieldConfig?.columnType]);

	const handleMouseDown = useCallback((e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		e.stopPropagation();
	}, []);

	return (
		<div className={styles.detailWrap}>
			<div className={styles.detailHead}>
				<DragIcon style={{ stroke: "var(--color-gray-300)" }} />
				<ChangeView style={{ stroke: "var(--color-indigo-800)" }} />
				<span className={styles.indigo800Title}>Деталь: </span>
				<span className={styles.fieldCaption}>{props.element.detailConfig?.detailTitle} </span>
				<div className={styles.buttonsInGroupField}>
					<Button
						style={ButtonStyle.Icon}
						firstIcon={<Rename />}
						className={editButtonClasses}
						onClick={handleEditClick}
						onMouseDown={handleMouseDown}
						isVisible={true}
					/>
					<Button
						style={ButtonStyle.Icon}
						firstIcon={<Close />}
						className={deleteButtonClasses}
						onClick={handleDeleteClick}
						onMouseDown={handleMouseDown}
					/>
				</div>
			</div>
		</div>
	);
};
