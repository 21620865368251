import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { synchroiser } from "synchroiser";
import { store } from "store";

import { Kanban, Grid, CheckBox, Field, SearchSelect, InputStyleName, SelectStyleName, Input, InputPhone } from "components";
import SectionHead from "features/section-head/section-head";

import { default as leadEntity, Lead } from "entities/lead/lead";
import { validateEmail, validatePhoneNumber, validateRequired } from "entities/Validation";
import { leadPriorityStore, leadStageStore, leadStore } from "entities/lead/leads-section-store";
import fields, { columns, infoFields } from "./data/Fields";
import GetFormattedPhone from "entities/GetFormattedPhone";
import Entity, { IEntityStore } from "entities/Entity";
import { QuickViewFilldType } from "features/quick-view-block/constants/constants";

import styles from "./leads.module.css";
import { Item } from "types";



const Leads = /*observer(*/() => {
	const [entity, setEntity] = useState<Entity<IEntityStore>>();

	useEffect(() => {
		setEntity(new Entity<Lead>(new Lead()));
	}, []);

	useEffect(() => {
		synchroiser.switchSection("lead");
	}, [store.sections]);

	function getCardsData(data: any[]): any[] {
		return data.map((item) => ({
			schema: "Leads",
			id: item.id,
			stageId: item.stage.id,
			position: item.position,
			priorityOrder: item.priority.order,
			priority: item.priority.name,
			priorityColor: item.priority.color,
			cardTitle: item.title,
			cardItems: [
				// {
				//     icon: <Product />,
				//     caption: item.needType.name
				// },

				// {
				//     icon: <Account />,
				//     caption: item?.account?.name
				// },

				{
					// icon: <PersonIconGrey />,
					caption: item.contact?.name
				},
				{
					// icon: <PhonePlain />,
					caption: GetFormattedPhone(item.phone)
				}
			],
			createdOn: item.createdOn,
			modifiedOn: item.modifiedOn,
			owner: item.owner.name
		}));
	}
	return (
		entity ?
			<SectionHead
				pageTitle={"Лиды"}
				kanban={<Kanban
					entity={leadEntity}
					field={fields}
					infoFields={infoFields}
					stageField={{
						type: QuickViewFilldType.Select,
						name: "stage",
						lookupName: "LeadStage",
						caption: "Стадия",
						required: true,
						isSorted: true
					}}
					listStore={leadStore}
					getCardsData={getCardsData}
					listStagesStore={leadStageStore}
					listPriorityStore={leadPriorityStore}
					stages={leadStageStore.data.slice().sort((a, b) => { return a.order - b.order; })}
				/>}
				grid={<Grid columns={columns} listStagesStore={leadStageStore} listStore={leadStore} entity={leadEntity} />}
				priorityFilter={true}
				priorityStore={leadPriorityStore}
				listStore={leadStore}
				entity={entity}
				dialogTitle={"лид"}
				columns={columns}
				new={() => { entity.new(new Lead()); }}
				dialogContent={<LeadsDialogFilling entity={entity} />}
			/>
			:
			<></>

	);
};/*);*/

export const LeadsDialogFilling = observer((props: { entity: Entity<IEntityStore> }) => {
	let entity = props.entity as Entity<Lead>;

	return (
		<>
			{entity.isNew &&
                <>
                	<div className={!entity.entity.isLinkToContact ? styles.switcher : styles.switcher + " " + styles.active} onClick={() => { entity.entity.setValue(!entity.entity.isLinkToContact, "isLinkToContact"); }}>
                		<CheckBox /*className={styles.checkbox}*/ checked={entity.entity.isLinkToContact} onChangeChecked={(value) => { entity.entity.setValue(value, "isLinkToContact"); }} />
                        Привязать к существующему контакту
                	</div>

                	{/* <Field key={"number"} name={"number"} caption={"Номер"} required={true}>
                        <BaseInput
                            value={leadEntity.entity.number}
                            placeholder={""}
                            onChangeValue={(value: string) => { }}
                            isDisabled={true}
                        />
                    </Field> */}

                	<div className={styles.combination}>
                		<Field key={"priority"} name={"priority"} caption={"Приоритет"} required={true} className={styles.combinationInput}>
                			<SearchSelect
                				items={entity.lookups["LeadPriority"]}
                				isInput={true}
                				onItemsLoad={async () => {
                					return await entity.loadLookups("LeadPriority", undefined, null, true);
                				}}
                				value={entity.entity.priority}
                				isInvalid={entity.entity.validation.priority.isInvalid}
                				invalidMessage={entity.entity.validation.priority.error}
                				onChangeValue={(value) => {
                					entity.entity.setValue(value!, "priority");
                					// validateRequired(value, leadEntity.entity.validation.priority,true);
                				}}
                				onFocusOut={() => validateRequired(entity.entity.priority, entity.entity.validation.priority, true)}
                				inputStyle={InputStyleName.BaseWithoutBorder}
                				selectStyle={SelectStyleName.Base}
                			/>
                		</Field>

                		<Field key={"stage"} name={"stage"} caption={"Стадия"} required={true} className={styles.combinationInput}>
                			<SearchSelect
                				items={entity.lookups["LeadStage"]}
                				isInput={true}
                				onItemsLoad={async () => {
                					return await entity.loadLookups("LeadStage", undefined, null, true);
                				}}
                				value={entity.entity.stage}
                				isInvalid={entity.entity.validation.stage.isInvalid}
                				invalidMessage={entity.entity.validation.stage.error}
                				onChangeValue={(value) => {
                					entity.entity.setValue(value!, "stage");
                					// validateRequired(value, leadEntity.entity.validation.stage,true);
                				}}
                				onFocusOut={() => validateRequired(entity.entity.stage, entity.entity.validation.stage, true)}
                				inputStyle={InputStyleName.BaseWithoutBorder}
                				selectStyle={SelectStyleName.Base}
                			/>

                		</Field>
                	</div>

                	{!entity.entity.isLinkToContact ?
                		<>
                			<Field key={"name"} name={"name"} caption={"ФИО"} required={true}>
                				<Input
                					value={entity.entity.name}
                					placeholder={""}
                					onChangeValue={(value: string) => {
                						entity.entity.setValue(value, "name");
                						// validateRequired(value, leadEntity.entity.validation.name);
                					}}
                					isInvalid={entity.entity.validation.name.isInvalid}
                					invalidMessage={entity.entity.validation.name.error}
                					// isNeedCorrect={true}
                					onFocusOut={() => validateRequired(entity.entity.name, entity.entity.validation.name)}
                					inputStyle={InputStyleName.Base}
                				/>
                			</Field>
                			<Field key={"phone"} name={"phone"} caption={"Мобильный телефон"} required={true}>
                				<InputPhone
                					value={entity.entity.phone}
                					placeholder={"+7 (123) 456-78-90"}
                					onChangeValue={(value: string) => {
                						entity.entity.setValue(value, "phone");
                					}}
                					inputStyle={InputStyleName.Base}
                					isInvalid={entity.entity.validation.phone.isInvalid}
                					invalidMessage={entity.entity.validation.phone.error}
                					onFocusOut={() => validatePhoneNumber(entity.entity.phone, entity.entity.validation.phone)}
                				/>
                			</Field>

                			<Field key={"email"} name={"email"} caption={"Email"} required={true}>
                				<Input
                					value={entity.entity.email}
                					placeholder={"example@web.com"}
                					onChangeValue={(value: string) => {
                						entity.entity.setValue(value, "email");
                						// validateEmail(value, leadEntity.entity.validation.email);
                					}}
                					isInvalid={entity.entity.validation.email.isInvalid}
                					invalidMessage={entity.entity.validation.email.error}
                					// isNeedCorrect={true}
                					onFocusOut={() => validateEmail(entity.entity.email, entity.entity.validation.email)}
                					inputStyle={InputStyleName.Base}
                				/>
                			</Field>
                		</>
                		:
                		<Field key={"contact"} name={"contact"} caption={"Контакт"} required={true}>
                			<SearchSelect
                				items={entity.lookups["Contact"]}
                				isInput={true}
                				onItemsLoad={async (s) => {
                					return await entity.loadLookups("Contact", s, null, false);
                				}}
                				value={entity.entity.contact as Item}
                				className={styles.select}
                				onChangeValue={(value: any) => {
                					entity.entity.setValue(value, "contact");
                				}}
                				inputStyle={InputStyleName.BaseWithoutBorder}
                				selectStyle={SelectStyleName.Base}
                				isInvalid={entity.entity.validation.contact.isInvalid}
                				invalidMessage={entity.entity.validation.contact.error}
                				onFocusOut={() => validateRequired(entity.entity.contact, entity.entity.validation.contact, true, true)}
                			/>
                		</Field>
                	}
                	<Field key={"sourceReceipt"} name={"sourceReceipt"} caption={"Источник поступления"} required={true}>
                		<SearchSelect
                			items={entity.lookups["SourceReceipt"]}
                			onChangeValue={(value) => {
                				entity.entity.setValue(value!, "sourceReceipt");
                				// validateRequired(value, leadEntity.entity.validation.sourceReceipt,true);
                			}}
                			isInput={true}
                			onItemsLoad={async () => { return await entity.loadLookups("SourceReceipt", undefined); }}
                			value={entity.entity.sourceReceipt}
                			isInvalid={entity.entity.validation.sourceReceipt.isInvalid}
                			invalidMessage={entity.entity.validation.sourceReceipt.error}
                			onFocusOut={() => validateRequired(entity.entity.sourceReceipt, entity.entity.validation.sourceReceipt, true)}
                			inputStyle={InputStyleName.BaseWithoutBorder}
                			selectStyle={SelectStyleName.Base}
                		/>

                	</Field>

                	<Field key={"needType"} name={"needType"} caption={"Тип потребности"} required={true}>
                		<SearchSelect
                			items={entity.lookups["NeedType"]}
                			onChangeValue={(value) => {
                				entity.entity.setValue(value!, "needType");
                				// validateRequired(value, leadEntity.entity.validation.needType,true);
                			}}
                			isInput={true}
                			onItemsLoad={async () => { return await entity.loadLookups("NeedType", undefined); }}
                			value={entity.entity.needType}
                			isInvalid={entity.entity.validation.needType.isInvalid}
                			invalidMessage={entity.entity.validation.needType.error}
                			onFocusOut={() => validateRequired(entity.entity.needType, entity.entity.validation.needType, true)}
                			inputStyle={InputStyleName.BaseWithoutBorder}
                			selectStyle={SelectStyleName.Base}
                		/>

                	</Field>

                	<Field key={"owner"} name={"owner"} caption={"Ответственный"} required={true}>
                		<SearchSelect
                			items={entity.lookups["User"]}
                			isInput={true}
                			onItemsLoad={async (s) => {
                				return await entity.loadLookups("User", s, null, false);
                			}}
                			value={entity.entity.owner as Item}
                			className={styles.select}
                			onChangeValue={(value: any) => {
                				entity.entity.setValue(value, "owner");
                			}}
                			isInvalid={entity.entity.validation.owner.isInvalid}
                			invalidMessage={entity.entity.validation.owner.error}
                			onFocusOut={() => validateRequired(entity.entity.owner, entity.entity.validation.owner, true, true)}
                			inputStyle={InputStyleName.BaseWithoutBorder}
                			selectStyle={SelectStyleName.Base}
                		/>
                	</Field>

                </>
			}
		</>
	);
});






export default Leads;
