import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import axios from "axios";
import { observable } from "mobx";
import { useNavigate } from "react-router";
import authStore from "AuthStore";
// import api from "app/services/api";

import { synchroiser } from "synchroiser";

import { Field, Input, Button, LoadingCircle } from "components";
import ErrorMessage from "./ErrorMessage/ErrorMessage";
import SectionRoute from "entities/SectionRoute";
import { ERROR_4XX, ERROR_5XX, INVATION_EXPIRED } from "./LoginConsts";
import ChangePassword from "pages/change-password/change-password";

import { LogoSalebridge } from "assets/icons";

import styles from "./Login.module.css";
import { api } from "api";



enum LoadingState {
    None,
    Loading,
    Loaded,
}
interface ILoginState {
    username: string;
    password: string;
    passwordInvalid: boolean;
    usernameInvalid: boolean;
    errorStatus: string;
    isLoadState: LoadingState;
    needChangePassword: boolean;
    userId: string;
    logIn: () => Promise<void>;
    changePassword: (value: string) => void;
    changeUsername: (value: string) => void;
    keyDown: (event: any) => void;
    isInvalid: () => boolean;
}

const LoginState = observable<ILoginState>({
    username: "",
    password: "",
    userId: "",
    passwordInvalid: false,
    usernameInvalid: false,
    needChangePassword: false,
    errorStatus: "",
    isLoadState: LoadingState.Loaded,

    logIn: async () => {
        try {
            LoginState.isLoadState = LoadingState.Loading;
            LoginState.errorStatus = "";
            // const result = await api.post("/api/User/authenticate", {
            //     name: LoginState.username,
            //     password: LoginState.password,
            // });
            const result = await api.http.httpApi.user.userAuthenticate().post({
                name: LoginState.username,
                password: LoginState.password,
            });

            // TODO Убрать дубликацю запроса, ПОСЛЕ ПОЛНОЙ ЗАМЕНЫ НА НОВЫЙ STORE

            await synchroiser.auth(
                LoginState.username,
                LoginState.password
            );

            const data = result.data;

            if (data.success === true) {
                if (data.changePassword === true) {
                    LoginState.needChangePassword = true;
                    LoginState.userId = data.id;
                    LoginState.isLoadState = LoadingState.Loaded;
                    return;
                }

                const token = data.accessToken;
                const expires = data.expires;
                document.removeEventListener("keydown", LoginState.keyDown);
                authStore.logIn(token, expires);
            } else {
                console.log(data.message);
                LoginState.errorStatus = ERROR_4XX;
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    LoginState.errorStatus = ERROR_4XX;
                } else if (error.response?.status.toString()[0] === "5") {
                    LoginState.errorStatus = ERROR_5XX;
                }
            }
        } finally {
            LoginState.isLoadState = LoadingState.Loaded;
            LoginState.password = "";
            LoginState.username = "";
        }
    },
    changePassword: (value: string) => {
        LoginState.password = value;
    },
    changeUsername: (value: string) => {
        LoginState.username = value;
    },
    keyDown: (event: any) => {
        if (event.code === "Enter") {
            LoginState.logIn();
        }
    },
    isInvalid: (): boolean => {
        LoginState.usernameInvalid = false;
        LoginState.passwordInvalid = false;
        if (LoginState.username.length === 0) LoginState.usernameInvalid = true;
        if (LoginState.password.length === 0) LoginState.passwordInvalid = true;

        return LoginState.passwordInvalid || LoginState.usernameInvalid;
    },
});

const Login = observer(() => {
    const f = LoginState;

    useEffect(() => {
        document.removeEventListener("keydown", f.keyDown);
        document.addEventListener("keydown", f.keyDown);
    }, []);
    let navigate = useNavigate();

    const isLoading = f.isLoadState === LoadingState.Loading;

    let fieldClassNames = styles.inputField + " ";
    let forgotPasswordClassNames = styles.forgotPassword + " ";
    let inputClass = styles.input + " ";
    if (isLoading) {
        fieldClassNames += styles.textLoading;
        forgotPasswordClassNames += styles.textLoading;
        inputClass += styles.textLoading;
    }

    if (localStorage.getItem("inactiveLink") === "true") {
        f.errorStatus = INVATION_EXPIRED;
        localStorage.removeItem("inactiveLink");
    }

    return (
        <>
            {f.needChangePassword ? (
                <ChangePassword userId={f.userId} />
            ) : (
                <div className={styles.container}>
                    <div className={styles.square}>
                        <div className={styles.header}>
                            <div className={styles.logoContainer}>
                                <LogoSalebridge />
                            </div>
                            <span>Войти в систему</span>
                        </div>
                        <div className={styles.inputPanel}>
                            <Field
                                caption="Адрес электронной почты"
                                name={"username"}
                                className={fieldClassNames}
                            >
                                <Input
                                    className={inputClass}
                                    id="username"
                                    value={f.username}
                                    placeholder={""}
                                    onChangeValue={f.changeUsername}
                                    isDisabled={isLoading}
                                    isInvalid={f.usernameInvalid}
                                    onFocusOut={f.isInvalid}
                                />
                            </Field>
                            <Field caption="Пароль" name={"Password"} className={fieldClassNames}>
                                <Input
                                    className={inputClass}
                                    id="Password"
                                    value={f.password}
                                    placeholder={""}
                                    isPassword={true}
                                    onChangeValue={f.changePassword}
                                    isDisabled={isLoading}
                                    isInvalid={f.passwordInvalid}
                                    onFocusOut={f.isInvalid}
                                />
                            </Field>
                        </div>
                        <div className={styles.actionContainer}>
                            <a href="#" onClick={() => { }} className={forgotPasswordClassNames}>
                                Забыли пароль?
                            </a>
                            <Button
                                className={styles.loginBtn}
                                caption={isLoading ? <LoadingCircle /> : "Войти"}
                                onClick={async (event) => {
                                    if (f.isInvalid()) {
                                        return;
                                    }
                                    await f.logIn();
                                    if (authStore.tokenExpiration && (new Date().getTime()) < (new Date(Date.parse(authStore.tokenExpiration)).getTime())) {
                                        navigate(`/${SectionRoute.Settings}`);
                                    }

                                }}
                            />
                        </div>
                        {f.errorStatus && <ErrorMessage errorStatus={f.errorStatus} />}
                    </div>
                </div>
            )}
        </>
    );
});

export default Login;
