import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";

import { Button, ButtonStyle, Field, Input, InputStyleName } from "components";

import { CloseMaxi } from "assets/icons";

import styles from "./filter-popups.module.css";


export type LayoutRenameType = {
    dialogTitle: string,
    startName: string | null,
    captionSuccessfulButton?: string | null,
    onRename: (filterName: string | null) => Promise<void>,
    onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void,
}

const RenameLayout = observer((props: LayoutRenameType) => {
    const [filterName, setFilterName] = useState<string | null>(props.startName);
    const [isDisableButton, setDisableButton] = useState<boolean>(false);

    const onSaveKeyUp = useCallback((event: KeyboardEvent) => {
        event.stopPropagation()
        if (event.key === 'Enter') {
            handleRename();
        }
    }, [filterName]);

    useEffect(() => {
        document.addEventListener('keydown', onSaveKeyUp);
        return () => {
            document.removeEventListener('keydown', onSaveKeyUp);
        };
    }, [onSaveKeyUp])

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation()
    }, []);

    const handleChangeFilterName = useCallback((value: string) => {
        setFilterName(value);
    }, [filterName]);

    const handleCancel = useCallback(() => {
        setFilterName(null);
        props.onClose();
    }, [props.onClose]);

    const handleRename = useCallback(async () => {
        setDisableButton(true);
        await props.onRename(filterName).then(() => {
            setDisableButton(false);
        });
        handleCancel();
    }, [props.onRename, filterName]);

    return <div className={styles.dialog} onClick={handleClick}>
        <div className={styles.header}>
            <span className={styles.title}>{props.dialogTitle}</span>
            <CloseMaxi
                className={styles.closeButton}
                onClick={handleCancel} />
            
        </div>
        <div className={styles.dialogBody}>
            <Field name='filterName' caption='Название' className={styles.filterNameField}>
                <Input
                    value={filterName}
                    placeholder="Введите значение..."
                    onChangeValue={handleChangeFilterName}
                    inputStyle={InputStyleName.Base}
                    maxLength={50}
                    focus={true}
                />
            </Field>
        </div>
        <div className={styles.dialogFooter}>
            <Button
                caption="Отменить"
                onClick={handleCancel}
                style={ButtonStyle.Subtle} />
            <Button
                caption={props.captionSuccessfulButton ?? "Переименовать"}
                onClick={handleRename}
                style={ButtonStyle.Primary}
                isDisabled={isDisableButton}
            />
        </div>

    </div>
});

export default RenameLayout;