import {action, toJS} from "mobx";
import {v4} from "uuid";

import {dispatcher} from "store/store-dipatcher/dispatcher";
import {selector} from "store/store-selector";

import {HEXColors, ResultHEXColors, ResultType, StageModel, StageSetting, Stages} from "types/entity";
import {isNull} from "lodash";

export class StageModelController {
    /**
     * @description Инициализация конфига стадийной модели.
     */
    init = (stageLookupTitle: string, stageLookupName: string, detailedResultLookupName?: string): void => {
        const stageId = v4();
        const sectionWizzard = dispatcher.entity.get()?.entity.sectionWizzard;
        if (sectionWizzard) {
            const basicStageModel: StageModel = {
                stageLookupTitle: stageLookupTitle,
                stageLookupName: stageLookupName,
                detailedResultLookupName: detailedResultLookupName ?? '',
                stages: [{
                    id: stageId,
                    name: "Новая стадия"
                }],
                detailResults: [],
                stageSettings: [{
                    id: v4(),
                    stageId: stageId,
                    color: HEXColors["#C7D7FE"],
                    isHidden: false,
                    order: 0,
                    resultType: null
                }],
                movingRules: []
            }
            sectionWizzard.stageModelConfig = basicStageModel;
        }
    };

    setStageModelLookupSystemName = action((stageModelLookupSystemName: string): void => {
        const sectionWizzard = dispatcher.entity.get()?.entity.sectionWizzard;
        if (sectionWizzard?.stageModelConfig?.stageLookupName) {
            sectionWizzard.stageModelConfig!.stageLookupName = stageModelLookupSystemName;
        }
    })

    setStageModelLookupName = action((stageModelLookupName: string): void => {
        const sectionWizzard = dispatcher.entity.get()?.entity.sectionWizzard;
        if (sectionWizzard?.stageModelConfig?.stageLookupTitle) {
            sectionWizzard.stageModelConfig!.stageLookupTitle = stageModelLookupName;
        }
    })

    /**
     * @description получение названия стадии по id.
     */
    getNameStageById = (stageId: string): string => {
        return dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stages.find((stage: Stages) => stage.id === stageId)?.name!;
    };
    /**
     * @description получение параметров стадии по id.
     */
    getStageSettingsById = (stageId: string): StageSetting | undefined => {
        return dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stageSettings.find((setting: StageSetting) => setting.stageId === stageId);
    };

    /**
     * @description Изменение цвета стадии.
     */
    setColor = (stageId: string, color: HEXColors | string): void => {
        const settings = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stageSettings.find((setting: StageSetting) => setting.stageId === stageId);
        if (settings) {
            settings.color = color;
        }
    };

    /**
     * @description Изменение названия стадии.
     */
    setStageName = (stageId: string, name: string): void => {
        const settings = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stages.find((info: Stages) => info.id === stageId);
        if (settings) {
            settings.name = name;
        }
    };

    /**
     * @description Изменение типа результата стадии.
     */
    setStageType = (stageId: string, stageType: ResultType | null): void => {
        const settings = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stageSettings.find((setting: StageSetting) => setting.stageId === stageId);
        if (settings) {
            settings.resultType = stageType;
        }
    };

    /**
     * @description Изменение названий стадий по их order.
     */
    changeStageNames = (value?: string): void => {
        const allStages = selector.stageModels.getAll();
        allStages?.forEach(stage => {
            if (value) this.setStageName(stage.id, value);
        })
    };

    /**
     * @description Перемещение элементов по id на позицию position.
     */
    moveToById = (stageId: string, position: number): void => {
        const stageSettings = toJS(dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stageSettings);
        if (stageSettings) {
            const movingSetting = stageSettings.find((setting: StageSetting) => setting.stageId === stageId);

            if (movingSetting) {
                // перемещаемый элемент правее нужной позиции
                if (movingSetting.order > position) {
                    stageSettings.forEach((setting: StageSetting) => {
                        if (setting.order >= position && setting.order < movingSetting.order) {
                            setting.order++;
                        } else if (setting.order == movingSetting.order) {
                            setting.order = position;
                        }
                    });
                }
                // перемещаемый элемент левее нужной позиции
                else if (movingSetting.order > position) {
                    stageSettings.forEach((setting: StageSetting) => {
                        if (setting.order > movingSetting.order && setting.order <= position) {
                            setting.order--;
                        } else if (setting.order == movingSetting.order) {
                            setting.order = position;
                        }
                    });
                }
                // добавление по нажатию на плюсик
                else {
                    stageSettings.forEach((setting: StageSetting) => {
                        if (setting.order >= movingSetting.order) {
                            setting.order++;
                            movingSetting.order = position;
                        }
                    });

                }
                ;
                this.changeStageNames();
            }

            const sectionWizard = dispatcher.entity.get()?.entity.sectionWizzard;
            if (sectionWizard?.stageModelConfig?.stageSettings) {
                sectionWizard.stageModelConfig.stageSettings = stageSettings;
            }
        }
    };

    /**
     * @description Добавление новой пустой стадии по индексу.
     */
    addStage = action("add stage", (order: number, resultType: ResultType | null, position?: number): string => {
        const stageId = v4();
        const newStage: Stages = {
            id: stageId,
            name: `Новая стадия ${position}`
        }
        let color: HEXColors | ResultHEXColors | string = HEXColors["#C7D7FE"];
        if (!isNull(resultType)) {
            color = ResultHEXColors.NULL;
        }
        const newStageSetting: StageSetting = {
            id: v4(),
            stageId: stageId,
            color: color,
            isHidden: false,
            order: order,
            resultType: resultType,
        }

        dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stages.push(newStage);
        dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stageSettings.push(newStageSetting);
        this.moveToById(newStageSetting.stageId, order);
        return newStage.id
    });

    /**
     * @description удаление стадии по её id.
     */
    deleteStage = (stageId: string): void => {
        const stageModel = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig;
        if (stageModel) {
            const newStageSettings = stageModel.stageSettings.filter(stage => stage.stageId !== stageId);
            stageModel.stageSettings = newStageSettings;
            const newStages = stageModel.stages.filter(stage => stage.id !== stageId);
            stageModel.stages = newStages;
            const newMovingRules = stageModel.movingRules.filter(rule => rule.stageFromId !== stageId || rule.stageToId !== stageId);
            stageModel.movingRules = newMovingRules;
        }
        this.changeStageNames();
    };

    /**
     * @description скрытие стадии.
     */
    hideStage = (stageId: string): void => {
        const stageModel = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig;
        if (stageModel) {
            const newStageSetting = stageModel.stageSettings.find(stage => stage.stageId === stageId);
            if (newStageSetting) {
                newStageSetting.isHidden = true;
            }
        }
    };

    /**
     * @description появление стадии.
     */
    viewStage = (stageId: string): void => {
        const stageModel = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig;
        if (stageModel) {
            const newStageSetting = stageModel.stageSettings.find(stage => stage.stageId === stageId);
            if (newStageSetting) {
                newStageSetting.isHidden = false;
            }
        }
    };

    /**
     * @description добавление нового правила перехода.
     */
    addMoveRule(stageFrom: string, stageTo: string) {
        if (stageFrom.length > 0 && stageTo.length > 0) {
            const newMoveRule = {
                id: v4(),
                stageFromId: stageFrom,
                stageToId: stageTo
            }
            const stageModel = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig;
            if (stageModel) {
                stageModel.movingRules.push(newMoveRule);
            }
        }
    }

    /**
     * @description удаление правила перехода.
     */
    deleteRuleById(stageFrom: string, stageTo: string) {
        const stageModel = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig;
        if (stageModel) {
            const newRules = stageModel.movingRules.filter(rule => rule.stageFromId !== stageFrom && rule.stageToId !== stageTo);
            stageModel.movingRules = newRules;
        }
    }
}

export const stageModelController = new StageModelController();