import classNames from "classnames";

import { InfoIcon } from "assets/icons";

import styles from "./validation-prompt.module.scss";

/**
 * @description Подсказка по валидации
 * @param props.header Текст заголовка подсказки
 * @param props.text Текст в теле подсказки
 * @param props.example Текст примера
 * @param props.promtIcon Стиль иконки подсказки
 * @param props.centerAlign Положение иконки по центру
 */
export function ValidationPrompt(props: {[key: string]:any}) {
  const TEXT="Допустимые символы для поля [Системное название]:\n латинские буквы без пробелов, цифры, символ подчеркивания и дефис."
	const icon = classNames(
		{ 
      [`${props.promtIcon}`]: props.promtIcon,
      [`${styles.icon}`]: !props.promtIcon,
    }
	);
   	const infoContent = classNames(`${styles.infoContent}`,
		{ [`${styles.infoContent} ${styles.centerAlign}`]: props.centerAlign, }
	); 
  
  return (
      <div className={styles.promtInfo}>
        <div className={infoContent}>
            <div className={icon}><InfoIcon /></div>
            <div className={styles.textWrapper}>
                <span className={styles.header}>{props.header}</span>
                <div className={styles.text}>
                  {props.text ?? TEXT}
                  </div>
                <span className={styles.example}>{props.example}</span>
            </div>
        </div>
      </div>
  );
}