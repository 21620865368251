import ListStore from "entities/ListStore";



const leadSchema = "Lead";
const leadStagesSchema = "LeadStage";
const leadPrioritySchema = "LeadPriority";

export const leadStore = new ListStore(leadSchema);
export const leadStageStore = new ListStore(leadStagesSchema);
export const leadPriorityStore = new ListStore(leadPrioritySchema);