import FilterColumnType from "entities/ColumnType";
import IFilter, { ComparisonType, DataValueType, ExpressionType, FilterType, IExpression, LogicalOperation, MacrosType } from "entities/filter/IFilter";
import IPropertyFilter from "./IPropertyFilter";
import ValueType from "./ValueType";

class FilterBuilder {
    filter: IFilter;
    oldFilter: IFilter;
    table: string;


    constructor(table: string) {
        this.filter = {
            schema: table,
            "isEnabled": true,
            "type": 0,
            "filters": [],
            "logicalOperation": 0,
            "comparisonType": 2,
            "detail": "",
            "detailAggregationType": 0,
            "attribute": "",
            "isActive": true,
            "includedIds": [],
            "excludedIds": []
        };

        this.oldFilter = this.filter;

        this.table = table;
    }

    new(table: string) {
        this.filter = {
            schema: table,
            "isEnabled": true,
            "type": 0,
            "filters": [],
            "logicalOperation": 0,
            "comparisonType": 2,
            "detail": "",
            "detailAggregationType": 0,
            "attribute": "",
            "isActive": true,
            "includedIds": [],
            "excludedIds": []
        };

        this.oldFilter = this.filter;

        this.table = table;

        return this.filter;
    }

    buildGroup(operation: LogicalOperation, filter?: IFilter): IFilter {
        let currentFilter = filter ?? this.filter;

        let result = {
            schema: this.table!,
            "isEnabled": true,
            "type": FilterType.Group,
            "filters": [],
            "logicalOperation": operation,
            "comparisonType": 2,
            "detail": "",
            "detailAggregationType": 0,
            "attribute": "",
            "isActive": true
        };

        currentFilter?.filters?.push(result)

        this.oldFilter = result;
        return result;
    };

    buildAttribute(value: IExpression, comparison: ComparisonType, property: IPropertyFilter, filter?: IFilter) {
        let currentFilter = filter ?? this.filter;

        let result = {
            "schema": this.table,
            "isEnabled": true,
            "type": 1,
            "filters": [],
            "logicalOperation": 0,
            "comparisonType": comparison,
            "rightExpression": value,
            "detail": "",
            "detailAggregationType": 0,
            "attribute": property.Name,
            "attributeType": dataTypeToColumnType(property.Type),
            "isActive": true
        }

        currentFilter?.filters?.push(result)

        this.oldFilter = result;
        return result;
    }

    buildRightExpression(value: ValueType, property: IPropertyFilter, macros?: MacrosType) {
        const result: IExpression = {
            type: macros ? ExpressionType.Macros : ExpressionType.Const,
            parameter: {
                dataValueType: property.Type,
                value: value
            },
            macros: macros
        }

        return result;
    }

    buildDetail(value: IExpression, comparison: ComparisonType, attribute: string, property: IPropertyFilter, filter?: IFilter) {
        let currentFilter = filter ?? this.filter;

        let detailFilter = {
            "schema": property.Name,
            "isEnabled": true,
            "type": 0,
            "filters": [
                {
                    "schema": property.LookupTable!,
                    "isEnabled": true,
                    "type": 1,
                    "filters": [],
                    "logicalOperation": 0,
                    "comparisonType": comparison,
                    "rightExpression": value,
                    "detail": "",
                    "detailAggregationType": 0,
                    "attribute": attribute,
                    "attributeType": FilterColumnType.Lookup,
                    "isActive": true
                }
            ],
            "logicalOperation": 0,
            "comparisonType": comparison,
            "detail": "",
            "detailAggregationType": 0,
            "attribute": "",
            "isActive": true
        }

        let result = {
            "schema": this.table,
            "isEnabled": true,
            "type": 2,
            "filters": [],
            "logicalOperation": 0,
            "comparisonType": comparison,
            "rightExpression": {
                "type": 0,
                "parameter": {
                    "dataValueType": 3,
                    "value": null
                }
            },
            "detail": property.Name,
            "detailAggregationType": 0,
            "detailFilter": detailFilter,
            "attribute": "",
            "isActive": true,

        }

        currentFilter?.filters?.push(result);

        this.oldFilter = result;
        return detailFilter;
    }

    buildMacros(macros: MacrosType) {
        const result: IExpression = {
            type: ExpressionType.Macros,
            parameter: {
                dataValueType: 0,
                value: undefined
            },
            macros: macros
        }
        return result;
    }

    getResult() {
        return this.filter;
    }

}

export function dataTypeToColumnType(type: DataValueType) {
    switch (type) {
        case DataValueType.Boolean:
            return FilterColumnType.Boolean;
        case DataValueType.DateTime:
            return FilterColumnType.Date;
        case DataValueType.Entity:
            return FilterColumnType.Entity;
        case DataValueType.Decimal:
        case DataValueType.Float:
            return FilterColumnType.Float;
        case DataValueType.Guid:
            return FilterColumnType.Guid
        case DataValueType.Integer:
            return FilterColumnType.Integer;
        case DataValueType.Long:
            return FilterColumnType.Integer;
        case DataValueType.Lookup:
            return FilterColumnType.Lookup;
        case DataValueType.Text:
            return FilterColumnType.String;
        case DataValueType.Date:
            return FilterColumnType.Date;
        case DataValueType.Time:
            return FilterColumnType.Time;
        default:
            throw "DataValueType not processed! " + type;
    }
}

export default FilterBuilder;