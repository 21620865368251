import { useCallback, useEffect, useMemo, useState } from "react";

import { v4 } from "uuid";

import detailFieldConfigurationPopupState, { FieldStoreEnums } from "../detail-field-configuration-popup-state";

import { ChoiceOption } from "components";
import { FieldTypeTitle } from "pages/section-wizzard/pages/constructor/field-configuration/field-configuration";

import { LookupType } from "pages/section-wizzard/data/data";
import { ColumnType } from "entities/ColumnType";

import { OffRadioButton, OnRadioButton } from "assets/icons";

import styles from "./choice-lookup-type.module.css";

interface ChoiceLookupTypeProps {
    fieldType: ColumnType;
}

export const ChoiceLookupType = (props: ChoiceLookupTypeProps) => {
    const [selectedOption, setSelectedOption] = useState(LookupType.ExistLookup);

    useEffect(() => {
        detailFieldConfigurationPopupState.setValueWithoutTrackingChanges(FieldStoreEnums.lookupType, LookupType.ExistLookup);
        detailFieldConfigurationPopupState.setValueWithoutTrackingChanges(FieldStoreEnums.fieldType, props.fieldType);
        detailFieldConfigurationPopupState.setValueWithoutTrackingChanges(FieldStoreEnums.columnId, v4());
    }, []);

    const handleOptionSelect = useCallback((option: LookupType) => {
        detailFieldConfigurationPopupState.setValueWithoutTrackingChanges(FieldStoreEnums.lookupType, option);
        setSelectedOption(option);
    }, []);

    const fieldTypeTitle = useMemo(
        () => <FieldTypeTitle fieldType={props.fieldType} />,
        [props.fieldType]
    );
    const getRadioButtonIcon = useCallback(
        (option: LookupType) => {
            return selectedOption === option ? <OnRadioButton /> : <OffRadioButton />;
        },
        [selectedOption]
    );

    return (
        <>
            {fieldTypeTitle}
            <div className={styles.choiceContainer}>
                <ChoiceOption
                    name={LookupType.ExistLookup}
                    checked={selectedOption === LookupType.ExistLookup}
                    onSelect={handleOptionSelect}
                    icon={getRadioButtonIcon(LookupType.ExistLookup)}
                    title="По существующему объекту"
                    description="Создать поле, в котором значения выпадающего списка - значения из существующей таблицы системы"
                />
                <ChoiceOption
                    name={LookupType.NewLookup}
                    checked={selectedOption === LookupType.NewLookup}
                    onSelect={handleOptionSelect}
                    icon={getRadioButtonIcon(LookupType.NewLookup)}
                    title="По новому справочнику"
                    description="Создать поле и задать допустимые для него значения выпадающего списка"
                />
            </div>
        </>
    );
};


