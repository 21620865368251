import { Account } from 'entities/account/Account';
import Entity, { IEntityData, IEntityStore } from 'entities/Entity';
import { FieldValidationState, validateEmail, validatePhoneNumber, validateRequired } from 'entities/Validation';
import { action, makeAutoObservable, makeObservable, observable } from 'mobx';
import { Item } from "types";


export class Contact implements IEntityStore {
    schema: string = "Contact";
    displaySchema: string = "Контакты";
    id: string = "";


    name: string = "";
    surName: string = "";
    givenName: string = "";
    middleName: string = "";
    phone: string = "";
    email: string = "";
    role: Item | null = null;
    // city: any = {
    //     city: Item | null = null,
    //     hasChange: boolean = false
    // }
    city: Item | null = null;
    gender: Item | null = null;
    // primaryAccount: Item | null = null;
    primaryAccount: Account | null = null;
    position: string = "";
    notes: string = "";
    modifiedOn: string = "";
    createdOn: string = "";
    birthDate: string = "";

    validation: { [key: string]: FieldValidationState };

    constructor() {
        makeObservable(this, {
            name:observable,
            surName: observable,
            givenName: observable,
            middleName: observable,
            phone: observable,
            email: observable,
            role: observable,
            city: observable,
            primaryAccount: observable,
            position: observable,
            gender: observable,
            notes: observable,
            birthDate: observable,
            validation: observable,
            setValue: action,
            validate: action,
            resetValidate: action,
            deserialize: action,
        })

        this.validation = {
            surName: {
                isInvalid: false
            },
            givenName: {
                isInvalid: false
            },
            phone: {
                isInvalid: false
            },
            email: {
                isInvalid: false
            },
            role: {
                isInvalid: false
            },
            city: {
                isInvalid: false
            }
        }
    }

    setValue(value: string | Item | null, fieldName: string) {
        Reflect.set(this, fieldName, value);
    }

    // @action
    validate(): boolean {
        if (!validateRequired(this.surName, this.validation.surName)) {
            return false;
        }
        if (!validateRequired(this.givenName, this.validation.givenName)) {
            return false;
        }
        if (!validatePhoneNumber(this.phone, this.validation.phone)) {
            return false;
        }
        if (!validateEmail(this.email, this.validation.email)) {
            return false;
        }
        if (!validateRequired(this.role, this.validation.role,true)) {
            return false;
        }
        if (!validateRequired(this.city, this.validation.city,true,true)) {
            return false;
        }
        return true;
    }

    resetValidate() {
        this.validation = {
            surName: {
                isInvalid: false
            },
            givenName: {
                isInvalid: false
            },
            phone: {
                isInvalid: false
            },
            email: {
                isInvalid: false
            },
            role: {
                isInvalid: false
            },
            city: {
                isInvalid: false
            }
        }
    }

    // @action
    deserialize(p: any) {
        // p.values.map((item: IEntityData) => {
        //     let name = item.propertyName.charAt(0).toLowerCase() + item.propertyName.slice(1);
        //     Reflect.set(this, name, item.propertyValue);
        // })

        this.id = p.id;
        this.name = p.name ?? "";
        this.surName = p.surName ?? "";
        this.givenName = p.givenName ?? "";
        this.middleName = p.middleName ?? "";
        this.phone = p.phone ?? "";
        this.email = p.email ?? "";
        this.role = p.role;
        this.city = p.city;
        this.gender = p.gender;
        this.birthDate = p.birthDate ?? "";
        this.primaryAccount = p.primaryAccount;
        this.position = p.position ?? "";
        this.notes = p.notes ?? "";
        this.modifiedOn = p.modifiedOn;
        this.createdOn = p.createdOn;
        
    }

    serialize() {
        let values = Reflect.ownKeys(this).filter(x => !x.toString().toLowerCase().includes('schema') && !(this.id === "" && x === 'id') && x !== 'validation' && !x.toString().endsWith('edOn')).map((item) => {
            let name = null;
            let value = null;
            if (typeof Reflect.get(this, item) === 'object') {
                name = (item.toString().charAt(0).toUpperCase() + item.toString().slice(1)) + "Id";
                value = ((Reflect.get(this, item) as Item) !== null) ? (Reflect.get(this, item) as Item).id : null;
            }
            else {
                name = item.toString().charAt(0).toUpperCase() + item.toString().slice(1);
                value =  Reflect.get(this, item) ==="" ? null : Reflect.get(this, item);
            }
            if (name === 'Phone' && value !== null && value !== undefined) {
                value = (value as string).replace(/\D/g, '');
                if (value[0] !== '7')
                    value = value.charAt(0).replace(value[0], '7') + value.toString().slice(1);

            }
            if (name === 'Name')
                value = this.surName + " " + this.givenName + " " + this.middleName;
            return {
                propertyName: name,
                propertyValue: value
            }
        }) as IEntityData[];
        values.pop();

        return {
            entityName: this.schema!,
            values: values
        }
    }

    handleError(error: string): void {
        if (error === "name_is_empty") {
            // this.validName = false;
            // this.errorName = "";
        }
    }
}


export const contacts = new Contact();

const contactEntity = new Entity<Contact>(contacts);

export default contactEntity;