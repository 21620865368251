import { useCallback } from 'react';

import { StageActionProps } from "./stage-actions";

import { ActionFlag, ActionOpen, ActionPost, ActionProcess } from 'assets/icons';

export const stageActionPropsArray: StageActionProps[] = [
  { icon: <ActionFlag/>, actionName: "Создать активность", action: ()=> {} },
  { icon: <ActionPost/>, actionName: "Отправить письмо", action: ()=> {}},
  { icon: <ActionProcess/>, actionName: "Запустить процесс", action: ()=> {}},
  { icon: <ActionOpen/>, actionName: "Открыть запись", action: ()=> {}},
];