import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";

import { dispatcher, store } from "store";
import { synchroiser } from "synchroiser";
import { validateEmail, validatePhoneNumber, validateRequired } from "entities/Validation";

import FullView from "features/full-view/full-view";
import {
	Input, HorizontalField, InputNumber, Select,
	Field, IStageIndicationPanel, InputPhone, InputStyleName,
	SelectStyleName, SearchSelect, Dropdown
} from "components";
import SkeletonAccountFullView from "pages/accounts/accountFullView/skeleton/SkeletonAccountFullView";
import { NotFoundPage } from "pages/error-page/error-page";

import saleEntity, { Sale } from "entities/sale/Sale";
import Entity, { ILookup } from "entities/Entity";
import SectionRoute from "entities/SectionRoute";
import contactEntity, { Contact } from "entities/contact/Contact";
import { saleStageStore } from "entities/sale/SalesSectionStore";
import IStage from "entities/IStage";
import { loadLookup } from "app/services/LookupService";
import IFilter, { ComparisonType, DataValueType } from "entities/filter/IFilter";
import { entity } from "api/http/endpoints";
import FilterColumnType from "entities/ColumnType";
import { Item } from "types";

import { Contacts } from "assets/icons";

import styles from "./SaleFullView.module.css";


const SaleFullView = observer(() => {
	const sale = useParams<{ id: string }>();

	useEffect(() => {
		saleEntity.load(sale.id!);
		saleStageStore.load(null, null, 0);
	}, []);

	useEffect(() => {
		if (sale.id) {
			dispatcher.currentRow.switch(sale.id);
		}

		if (!store.currentEntityId) {
			synchroiser.switchSection("sales");
		}
	}, [store.sections, sale.id]);

	if (saleEntity.isLoading || saleStageStore.isLoading) {
		return (<SkeletonAccountFullView />);
	}

	if (!saleEntity.entity.id) return (<NotFoundPage/>);

	return (
		<FullView entity={saleEntity}
			mainInformation={
				{
					title: saleEntity.entity.name,
					dontDisplayTitle: true,
					mainInformationDataLeft: <MainInformationDataLeft entity={saleEntity} />,
					mainInformationDataRight: <MainInformationDataRight entity={saleEntity} />
				}
			}
			mainInformationDetails={MainInformationDetails(saleEntity)}
			stages={
				saleStageStore.data && saleEntity.entity.stage ?
					{
						stages: saleStageStore.data as IStage[],
						stage: saleEntity.entity.stage
					} as IStageIndicationPanel
					: null}
		/>
	);
});

const MainInformationDetails = ((entity: Entity<Sale>) => {
	return ([
		{
			caption: "Контакты",
			iconCaption: <Contacts />,
			body: entity.entity.contact ?
				<ContactsDetailBody entity={entity.entity.contact} />
				: null
		},
	]);
});

const ContactsDetailBody = observer((props: { entity: Contact }) => {

	useEffect(() => {
		contactEntity.load(props.entity.id);
	}, [props.entity.id]);

	return (
		<Field key={"contactName"} name={"contactName"}
			caption={contactEntity.entity.name}
			captionLink={`/${SectionRoute.Contact}/${contactEntity.entity.id}`}
			className={styles.contactsDetailBodyField}
			isMain={true}

		>
			<div className={styles.contactsDetailBody}>
				<div className={styles.contactsDetailBodyLeft}>
					<HorizontalField key={"email"} name={"email"} caption={"Email"}>
						<Input
							value={contactEntity.entity.email}
							placeholder={"example@web.com"}
							onChangeValue={(value) => {
								contactEntity.entity.setValue(value, "email");
							}}
							isInvalid={contactEntity.entity.validation.email.isInvalid}
							invalidMessage={contactEntity.entity.validation.email.error}
							onFocusOut={() => validateEmail(contactEntity.entity.email, contactEntity.entity.validation.email)}
							inputStyle={InputStyleName.FullViewInput}
						/>
					</HorizontalField>
					<HorizontalField key={"phone"} name={"phone"} caption={"Рабочий телефон"}>
						<InputPhone
							value={contactEntity.entity.phone}
							placeholder={"+7 (123) 456-78-90"}
							onChangeValue={(value) => {
								contactEntity.entity.setValue(value, "phone");
							}}
							inputStyle={InputStyleName.FullViewInput}
							isInvalid={contactEntity.entity.validation.phone.isInvalid}
							invalidMessage={contactEntity.entity.validation.phone.error}
							onFocusOut={() => validatePhoneNumber(contactEntity.entity.phone, contactEntity.entity.validation.phone)}
						/>
					</HorizontalField>
				</div>
				<div className={styles.contactsDetailBodyRight}>
					<HorizontalField key={"role"} name={"role"} caption={"Роль"} >
						<Select
							items={contactEntity.lookups["ContactRole"]}
							onChangeValue={(value) => {
								contactEntity.entity.setValue(value!, "role");
							}}
							isInput={true}
							onItemsLoad={async (s) => { return await contactEntity.loadLookups("ContactRole", undefined, null, true); }}
							value={contactEntity.entity.role}
							selectStyle={SelectStyleName.BaseWithoutBorder}
							className={styles.fullViewBasicInformationDataInput + " " + styles.input}
						/>
					</HorizontalField>
					<HorizontalField key={"position"} name={"position"} caption={"Должность"} >
						<Input
							value={contactEntity.entity.position}
							placeholder={""}
							onChangeValue={(value: string) => { contactEntity.entity.setValue(value, "position"); }}
							inputStyle={InputStyleName.FullViewInput}
						/>
					</HorizontalField>
				</div>
			</div>
		</Field>

	);
});

const MainInformationDataLeft = observer((props: { entity: Entity<Sale> }) => {
	const leadSchema = "Lead";
	const attributeForLeadFilter = "Title";
	const [leadItems, setLeadItems] = useState<ILookup[]>([]);
	const [countLeadItems, setCountLeadItems] = useState<number>(0);

	//TODO переделать всю логику на новый стор
	const handleItemsChange = useCallback((value: Item | null) => {

	}, [dispatcher.currentRow.get()]);

	const getItemsCount = useCallback(async (schema: string, filter: IFilter | null) => {
		const allCount = await entity.entityCount().post({
			entityName: schema,
			filter: filter
		});
		if (allCount.data.success) {
			setCountLeadItems(allCount.data.data);
			return allCount.data.data;
		}
		else return 0
	}, [leadItems, countLeadItems]);

	const handleItemsLoad = useCallback(async (schema: string, value: string | null, attributeForFilter: string | null, limit?: number) => {
		let filter: IFilter | null = null;
		if (value) {
			filter = {
				schema: "Sale",
				isEnabled: true,
				type: 1,
				comparisonType: ComparisonType.Contain,
				attribute: attributeForFilter ?? "Name",
				attributeType: FilterColumnType.String,
				rightExpression: {
					type: 0,
					parameter: {
						dataValueType: DataValueType.Text,
						value: value
					}
				},
				filters: [],
				logicalOperation: 0,
				detail: ""
			};
		}
		try {
			const items = await loadLookup(schema, value ? filter : null, limit);
			if (items) {
				setLeadItems(items);
				if (items.length > 0) {
					const newCount = await getItemsCount(schema, filter);
					setCountLeadItems(newCount);
					return newCount;
				}
				else return 0
			}
			else return -1
		}
		catch (error) {
			console.error(error)
		}

	}, [leadItems, countLeadItems]);

	return (
		<>
			<HorizontalField name={"number"} caption={"Номер"} classNameCaption={styles.fieldCaption}>
				<InputNumber
					value={props.entity.entity.number}
					placeholder={""}
					onChangeValue={(value: string) => { }}
					inputStyle={InputStyleName.FullViewInput}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
					isDisabled={true}
					isDisabledLocked={true}
				/>
			</HorizontalField>


			<HorizontalField name={"priority"} caption={"Приоритет"} required={true} classNameCaption={styles.fieldCaption}>
				<Select
					items={props.entity.lookups["SalePriority"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "priority");
						validateRequired(value, props.entity.entity.validation.priority);
					}}
					isInput={true}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					onItemsLoad={async () => {
						return await props.entity.loadLookups("SalePriority", undefined, null, false, undefined, 0);
					}}
					value={props.entity.entity.priority}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
					isInvalid={props.entity.entity.validation.priority.isInvalid}
					invalidMessage={props.entity.entity.validation.priority.error}
					onFocusOut={() => validateRequired(props.entity.entity.priority, props.entity.entity.validation.priority)}
				/>
			</HorizontalField>

			<HorizontalField name={"lead"} caption={"Лид"} classNameCaption={styles.fieldCaption}>
				{/* старый вариант SearchSelect */}
				{/* <SearchSelect
					items={props.entity.lookups["Lead"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "lead");
					}}
					isInput={true}
					onItemsLoad={async (s) => { return await props.entity.loadLookups("Lead", s); }}
					value={props.entity.entity.lead}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					className={styles.fullViewBasicInformationDataInput}
					classNameInput={styles.inputForSelect}
				/> */}
				<Dropdown
					items={leadItems ?? []}
					onChangeValue={handleItemsChange}
					count={countLeadItems}
					isInput={true}
					onItemsLoad={(value, limit) => handleItemsLoad(leadSchema, value, attributeForLeadFilter, limit)}
					value={props.entity.entity.lead}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					className={styles.fullViewBasicInformationDataInput}
					classNameInput={styles.inputForSelect}
				/>
			</HorizontalField>
			<HorizontalField key={"needType"} name={"needType"} caption={"Тип потребности"} required={true} classNameCaption={styles.fieldCaption}>
				<Select
					items={props.entity.lookups["NeedType"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "needType");
						validateRequired(value, props.entity.entity.validation.needType);
					}}
					isInput={true}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					onItemsLoad={async () => { return await props.entity.loadLookups("NeedType", undefined); }}
					value={props.entity.entity.needType}
					isInvalid={props.entity.entity.validation.needType.isInvalid}
					invalidMessage={props.entity.entity.validation.needType.error}
					onFocusOut={() => validateRequired(props.entity.entity.needType, props.entity.entity.validation.needType, true)}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
				/>
			</HorizontalField>
			<HorizontalField key={"account"} name={"account"} caption={"Контрагент"} required={true} classNameCaption={styles.fieldCaption}>
				<SearchSelect
					items={props.entity.lookups["Account"]}
					onChangeValue={(value) => {
						console.log('onChangeValue Account', value);

						props.entity.entity.setValue(value!, "account");
						validateRequired(value, props.entity.entity.validation.account);
					}}
					isInput={true}
					onItemsLoad={async (s) => { return await props.entity.loadLookups("Account", s); }}
					value={props.entity.entity.account}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					className={styles.fullViewBasicInformationDataInput}
					classNameInput={styles.inputForSelect}
				/>
			</HorizontalField>
		</>
	);
});

const MainInformationDataRight = observer((props: { entity: Entity<Sale> }) => {
	return (
		<>
			<HorizontalField key={"name"} name={"name"} caption={"Название"} classNameCaption={styles.fieldCaption}>
				<Input
					value={props.entity.entity.name}
					placeholder={""}
					onChangeValue={(value: string) => { }}
					inputStyle={InputStyleName.FullViewInput}
					isDisabled={true}
					isDisabledLocked={true}
				/>
			</HorizontalField>

			<HorizontalField key={"salesAmount"} name={"salesAmount"} caption={"Сумма продажи"} classNameCaption={styles.fieldCaption}>
				<InputNumber
					value={props.entity.entity.salesAmount}
					placeholder={""}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "salesAmount");
					}}
					inputStyle={InputStyleName.FullViewInput}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
				/>
			</HorizontalField>
			<HorizontalField key={"budgetEstimate"} name={"budgetEstimate"} caption={"Бюджетная оценка"} classNameCaption={styles.fieldCaption}>
				<InputNumber
					value={props.entity.entity.budgetEstimate}
					placeholder={""}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "budgetEstimate");
					}}
					inputStyle={InputStyleName.FullViewInput}
					className={styles.fullViewBasicInformationDataInput + " " + styles.input}
				/>
			</HorizontalField>

			<HorizontalField key={"owner"} name={"owner"} caption={"Ответственный"} required={false} classNameCaption={styles.fieldCaption}>
				<SearchSelect
					items={props.entity.lookups["User"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "owner");
					}}
					isInput={true}
					onItemsLoad={async (s) => { return await props.entity.loadLookups("User", s); }}
					value={props.entity.entity.owner}
					selectStyle={SelectStyleName.BaseWithoutBorder}
					className={styles.fullViewBasicInformationDataInput}
					classNameInput={styles.inputForSelect}
				/>
			</HorizontalField>
		</>
	);
});

export default SaleFullView;