import { useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";

import { UpFirst } from "entities/lowFirst";
import fieldGroupConfigurationStore from "./field-group-configuration-store";

import { Field, Input, InputStyleName, ButtonStyle, Button } from "components";

import { CloseMaxi } from "assets/icons";

import styles from "./field-group-configuration.module.css";


const FieldGroupConfiguration = observer((props: { fieldGroupId?: string, close: () => void, closeAll: () => void, position: { cellX: number, cellY: number } }) => {
	useEffect(() => {
		if (props.fieldGroupId) {
			fieldGroupConfigurationStore.getConfigurationById(props.fieldGroupId);
		}
	}, [props.fieldGroupId]);

	useEffect(() => {
		fieldGroupConfigurationStore.setValueWithoutTrackingChanges("x", props.position.cellX);
		fieldGroupConfigurationStore.setValueWithoutTrackingChanges("y", props.position.cellY);
	}, [props.position.cellX, props.position.cellY]);

	const handleSave = useCallback(() => {
		if (props.fieldGroupId) {
			fieldGroupConfigurationStore.saveFieldGroup(props.fieldGroupId);
		} else {
			fieldGroupConfigurationStore.saveFieldGroup();
		}
		props.closeAll();
	}, [props.closeAll]);

	const handleClose = useCallback(() => {
		if (fieldGroupConfigurationStore.hasChanges) {
			props.close();
		} else {
			props.closeAll();
		}
	}, [props.close]);

	const handleTitleChange = useCallback((value: string) => {
		fieldGroupConfigurationStore.setValue("name", value);
	}, [fieldGroupConfigurationStore]);

	const handleTitleFocusOut = useCallback(() => {
		fieldGroupConfigurationStore.validateTitle()
		fieldGroupConfigurationStore.validGroup();
	}, []);

	const handleSystemNameChange = useCallback((value: string) => {
		fieldGroupConfigurationStore.setValue("systemName", UpFirst(value));
	}, [fieldGroupConfigurationStore]);

	const handleSystemNameFocusOut = useCallback(() => {
		fieldGroupConfigurationStore.validateSystemName()
		fieldGroupConfigurationStore.validGroup();
	}, []);

	return (
		<div className={styles.dialog}>
			<div className={styles.header}>
				<span className={styles.title}>Новая группа полей</span>
				<CloseMaxi
					className={styles.closeButton}
					onClick={handleClose}
				/>
			</div>
			<div className={styles.dialogBody}>
				<Field name="title" caption="Название" required={true}>
					<Input
						value={fieldGroupConfigurationStore.name}
						placeholder="Введите значение"
						onChangeValue={handleTitleChange}
						inputStyle={InputStyleName.Base}
						isInvalid={fieldGroupConfigurationStore.validation.name.isInvalid}
						isNotUnique={fieldGroupConfigurationStore.validation.name.isNotUnique}
						invalidMessage={fieldGroupConfigurationStore.validation.name.error}
						onFocusOut={handleTitleFocusOut}
					/>
				</Field>
				<Field name="systemName" caption="Системное название" required={true}>
					<Input
						value={fieldGroupConfigurationStore.systemName}
						placeholder="Введите значение"
						onChangeValue={handleSystemNameChange}
						inputStyle={InputStyleName.Base}
						isInvalid={fieldGroupConfigurationStore.validation.systemName.isInvalid}
						invalidMessage={fieldGroupConfigurationStore.validation.systemName.error}
						onFocusOut={handleSystemNameFocusOut}
					/>
				</Field>
				<div className={styles.dialogFooter}>
					<Button
						caption="Отменить"
						onClick={handleClose}
						style={ButtonStyle.Subtle} />
					<Button
						caption="Сохранить"
						onClick={handleSave}
						isDisabled={fieldGroupConfigurationStore.isDidabledSaveButton}
						style={ButtonStyle.Primary} />
				</div>
			</div>
		</div>
	);
});

export default FieldGroupConfiguration;