import { ComparisonType, MacrosType } from "entities/filter/IFilter";
import FilterBuilder from "../FilterBuilder";
import IPropertyFilter from "../IPropertyFilter";
import ValueType from "../ValueType";


class DirectorSimpleFilter{
	static CreateLookup(value: ValueType, property: IPropertyFilter, table:string){
		let builder = new FilterBuilder(table);
		const rightExpression = builder.buildRightExpression(value, property);
		builder.buildAttribute(rightExpression, ComparisonType.Contain, property);
		return builder.getResult();
	}

	static CreateAttribute(value: ValueType, comparison : ComparisonType, property: IPropertyFilter, table:string){
		let builder = new FilterBuilder(table);
		const rightExpression = builder.buildRightExpression(value, property);
		builder.buildAttribute(rightExpression, comparison, property);
		return builder.getResult();
	}

	static CreateDateAttribute(value: string, property: IPropertyFilter, table:string){
		let builder = new FilterBuilder(table);
		const rightExpression = builder.buildRightExpression(value, property);

		builder.buildAttribute(rightExpression, ComparisonType.GreaterOrEqual, property);


		const right2 = builder.buildRightExpression(new Date((new Date(value)).setHours(23,59)).toISOString(), property);
		builder.buildAttribute(right2, ComparisonType.LessOrEqual, property);
		return builder.getResult();

	}

	static CreateMacros(macros: MacrosType, property: IPropertyFilter, table:string){

		let builder = new FilterBuilder(table);
		const rightExpression = builder.buildMacros(macros);
		builder.buildAttribute(rightExpression, ComparisonType.Equal, property);
		return builder.getResult();
	}

	static CreateBetweenDate(start: string, end:string, property: IPropertyFilter, table:string){
		let builder = new FilterBuilder(table);
		const rightExpression = builder.buildRightExpression(start, property);
		builder.buildAttribute(rightExpression, ComparisonType.GreaterOrEqual, property);


		const right2 = builder.buildRightExpression(new Date((new Date(end)).setHours(23,59)).toISOString(), property);
		builder.buildAttribute(right2, ComparisonType.LessOrEqual, property);
		return builder.getResult();
	}

	static CreateBetweenNumber(start: number, end:number, property: IPropertyFilter, table:string){
		let builder = new FilterBuilder(table);
		const rightExpression = builder.buildRightExpression(start, property);
		builder.buildAttribute(rightExpression, ComparisonType.GreaterOrEqual, property);

		const right2 = builder.buildRightExpression(end, property);
		builder.buildAttribute(right2, ComparisonType.LessOrEqual, property);
		return builder.getResult();
	}
}

export default DirectorSimpleFilter;