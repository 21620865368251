import { convertFromHTML, convertToHTML } from 'draft-convert';

import {
    TextFormattingIconLink, TextFormattingIconBold, TextFormattingIconColor,
    TextFormattingIconItalic, TextFormattingIconStrikethrough, TextFormattingIconUnderline
} from 'assets/icons';

import { CUSTOM_STYLE_MAP, EntityType, InlineStyle, mappingColors } from '../config/config';

export const stateToHTML = convertToHTML<InlineStyle>({
    styleToHTML: (style) => {
        switch (style) {
            case InlineStyle.BOLD:
                return <strong />;
            case InlineStyle.ITALIC:
                return <em />;
            case InlineStyle.UNDERLINE:
                return <u />;
            case InlineStyle.STRIKETHROUGH:
                return <s />;
            default:
                return <span className={style} style={CUSTOM_STYLE_MAP[style]} />;
        }
    },
    entityToHTML: (entity, originalText) => {
        if (entity.type === EntityType.LINK) {
            return <a href={entity.data.url} style={{ color: mappingColors.get(InlineStyle.INDIGO600), textDecoration: `underline` }} >{originalText}</a>;
        }
        return originalText;
    },

});

export const HTMLtoState = convertFromHTML<DOMStringMap>({
    htmlToStyle: (nodeName, node, currentStyle) => {
        switch (nodeName) {
            case 'strong':
                return currentStyle.add(InlineStyle.BOLD);
            case 'em':
                return currentStyle.add(InlineStyle.ITALIC);
            case 'u':
                return currentStyle.add(InlineStyle.UNDERLINE);
            case 's':
                return currentStyle.add(InlineStyle.STRIKETHROUGH);
            case 'a':
                return currentStyle.add(InlineStyle.INDIGO600);
            case 'span': {
                return currentStyle.add(node.classList[0]);
            }
            default:
                return currentStyle;
        }
    },
    htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === "a" && node.href) {
            return createEntity(EntityType.LINK, "MUTABLE", { url: node.href });
        }

        return undefined;
    },

});

export function getImage(code: string) {
    switch (code) {
        case (InlineStyle.BOLD): {
            return <TextFormattingIconBold />
        }
        case (InlineStyle.ITALIC): {
            return <TextFormattingIconItalic />
        }
        case (InlineStyle.STRIKETHROUGH): {
            return <TextFormattingIconStrikethrough />
        }
        case (InlineStyle.UNDERLINE): {
            return <TextFormattingIconUnderline />
        }
        case ('color'): {
            return <TextFormattingIconColor />
        }
        case ('link'): {
            return <TextFormattingIconLink />
        }
    }
}
