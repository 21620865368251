import { useCallback } from "react";
import { observer } from "mobx-react-lite";

import detailFieldConfigurationPopupState, { FieldStoreEnums } from "../detail-field-configuration-popup-state";

import {
	Toggle,
	Text,
} from "components";

import { ColumnType } from "entities/ColumnType";

import styles from "../detail-field-configuration-popup.module.css";

export const ToggleBlockConfiguration = observer(() => {
	const renderToggleForDateTime = useCallback(() => {
		if (
			detailFieldConfigurationPopupState.fieldType === ColumnType.DateTime ||
			detailFieldConfigurationPopupState.fieldType === ColumnType.Date ||
			detailFieldConfigurationPopupState.fieldType === ColumnType.Time
		) {
			return (
				<>
					<div className={styles.toggleField}>
						<div className={styles.toggleFieldLayout}>
							<Text className={styles.defaultText}>
								{detailFieldConfigurationPopupState.fieldType === ColumnType.Date
									? "Заполнять текущей датой по умолчанию"
									: detailFieldConfigurationPopupState.fieldType === ColumnType.Time
										? "Заполнять текущим временем по умолчанию"
										: "Заполнять текущей датой и временем по умолчанию"
								}
							</Text>
							<Toggle
								checked={detailFieldConfigurationPopupState.isSetDefaultData || detailFieldConfigurationPopupState.defaultValue.length > 0}
								onCheck={(val) => {
									if (val) {
										const currentDate = new Date();
										let currentDateString = "";
										if (detailFieldConfigurationPopupState.fieldType === ColumnType.Date) {
											currentDateString = currentDate.toISOString().split("T")[0];
										} else if (detailFieldConfigurationPopupState.fieldType === ColumnType.Time) {
											currentDateString = currentDate.toISOString().split("T")[1];
										} else {
											currentDateString = currentDate.toISOString();
										}
										detailFieldConfigurationPopupState.setValue(FieldStoreEnums.defaultValue, currentDateString);
									}
									else {
										detailFieldConfigurationPopupState.setValue(FieldStoreEnums.defaultValue, "");
									}
									detailFieldConfigurationPopupState.setValue(FieldStoreEnums.isSetDefaultData, val);
								}}
							/>
						</div>
						<Text className={styles.defaultTipText}>
							{detailFieldConfigurationPopupState.fieldType === ColumnType.Date
								? "Поле будет заполнено датой создания записи"
								: detailFieldConfigurationPopupState.fieldType === ColumnType.Time
									? "Поле будет заполнено временем на момент создания записи"
									: "Поле будет заполнено датой и временем на момент создания записи"
							}
						</Text>
						<div className={styles.divider} />
					</div>
				</>
			);
		}
		return null;
	}, [detailFieldConfigurationPopupState.fieldType]);

	const renderToggleForNonLogicalTypes = () => {
		const renderIndexToggle = () => {
			return (
				<div className={styles.toggleField}>
					<div className={styles.toggleFieldLayout}>
						<Text className={styles.defaultText}>Поле индексируемое</Text>
						<Toggle
							checked={detailFieldConfigurationPopupState.hasIndex}
							onCheck={(val) => detailFieldConfigurationPopupState.setValue(FieldStoreEnums.hasIndex, val)}
						/>
					</div>
					<Text className={styles.defaultTipText}>
						Включите, чтобы поиск по этому полю выполнялся быстрее
					</Text>
				</div>
			);
		};

		if (detailFieldConfigurationPopupState.fieldType !== ColumnType.Boolean) {
			return (
				<>
					<div className={styles.toggleField}>
						<div className={styles.toggleFieldLayout}>
							<Text className={styles.defaultText}>Поле обязательное</Text>
							<Toggle
								checked={detailFieldConfigurationPopupState.isRequired}
								onCheck={(val) => detailFieldConfigurationPopupState.setValue(FieldStoreEnums.isRequired, val)}
							/>
						</div>
						<Text className={styles.defaultTipText}>
							Перед сохранением записи необходимо заполнить это поле
						</Text>
						<div className={styles.divider} />
					</div>
					{renderIndexToggle()}
				</>
			);
		}
		return renderIndexToggle();
	};

	return (
		<>
			{renderToggleForDateTime()}
			{renderToggleForNonLogicalTypes()}
		</>
	);
});