import { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";

import { Button, ButtonStyle, Toggle } from "components";

import { ArrowToUpThin } from "assets/icons";

import styles from "./toggle-select.module.css";


interface IToggleSelectProps {
    name: string;
    items: IToggleSelectItem[];
    isRightIcon?: boolean;
    iconOpened?: JSX.Element;
    classNameIcon?: string;
    buttonStyle?: ButtonStyle;
    onToggleClick?: (id: string, value: boolean) => void;
    isDisabled?: boolean
}

export interface IToggleSelectItem {
    id: string,
    displayValue: string,
    checked: boolean,

}
function Item(props: {
    item: IToggleSelectItem,
    onToggleClick?: (id: string, value: boolean) => void,
    isDisabled?: boolean
}) {
    const [checked, setChecked] = useState(props.item.checked)

    useEffect(() => {
        setChecked(props.item.checked);
    }, [props.item.checked]);

    const onCheckToggle = useCallback((value: boolean) => {
        if (props.onToggleClick && !props.isDisabled) props.onToggleClick(props.item.id, value)
    }, [props])

    return (
        <li
            key={props.item.id}
            className={styles.item}
            onClick={() => onCheckToggle(!checked)}
        >
            <span>{props.item.displayValue}</span>
            <Toggle
                checked={checked}
                onCheck={() => { }}
                isDisabled={props.isDisabled}
            />
        </li>
    );
};


function ToggleSelect(props: IToggleSelectProps) {
    const [isOpened, setOpened] = useState(true);

    const buttonClassNames = classNames({
        [`${styles.button} `]: true,
    });
    const secondIconClassNames = classNames({
        [`${styles.secondIcon} `]: true,
        [`${styles.close} `]: isOpened,
    });
    const listClassNames = classNames({
        [`${styles.list} `]: true,
        [`${styles.visible} `]: isOpened,
    });

    const itemsMapping = useMemo(() => {
        return props.items &&
            props.items.map((item) =>
                <Item
                    key={item.id}
                    item={item}
                    onToggleClick={props.onToggleClick}
                    isDisabled={props.isDisabled}
                />
            )
    }, [props.items])

    const openMenu = useCallback(() => {
        setOpened(!isOpened);
    }, [isOpened])

    return (
        <div className={styles.wrapper}>
            <Button
                classNameSecondIcon={secondIconClassNames}
                className={buttonClassNames}
                secondIcon={<ArrowToUpThin />}
                caption={props.name}
                onClick={openMenu}
                style={props.buttonStyle}
                selected={isOpened}
            />
            <ul className={listClassNames} >
                {itemsMapping}
            </ul>

        </div>
    );
}



export default ToggleSelect;
