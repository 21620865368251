import Api from "api/http/api";
import { UserRoleType } from "features/tree-view/tree-view";

class Administration extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/Administration";
	}

	roles = (roleId?:string) => {
		if(roleId){
			this.setPath(`${this.basicPath}/roles?id=${roleId}`);
		}else{
			this.setPath(`${this.basicPath}/roles`);
		}
		return this;
	};

	getRolesByType = (type: UserRoleType)  => {
		this.setPath(`${this.basicPath}/roles?userRoleType=${type}`);
		return this;
	};
	getTreeOfOrganizationRoles = () => {
		this.setPath(`${this.basicPath}/getTreeOfOrganizationRoles`);
		return this;
	};
	getTreeOfFunctionalRoles = () => {
		this.setPath(`${this.basicPath}/getTreeOfFunctionalRoles`);
		return this;
	};
	leadRoles = () => {
		this.setPath(`${this.basicPath}/leadRoles`);
		return this;
	};
	deleteLeadRole = (id:string) => {
		this.setPath(`${this.basicPath}/deleteLeadRole?id=${id}`);
		return this;
	};
	includeUserInPole = () => {
		this.setPath(`${this.basicPath}/roles/include/user`);
		return this;
	};
	excludeUserInPole = () => {
		this.setPath(`${this.basicPath}/excludeUserInPole`);
		return this;
	};
	rolesByUser = (userId: string) => {
		this.setPath(`${this.basicPath}/roles/${userId}`);
		return this;
	};
	usersByRole = (roleId: string) => {
		this.setPath(`${this.basicPath}/users/${roleId}`);
		return this;
	};
	administrationByRecords = () => {
		this.setPath(`${this.basicPath}/settings/administrationbyrecords/enable`);
		return this;
	};
	administrationByEntityOperationsEnable = () => {
		this.setPath(`${this.basicPath}/settings/administrationbyentityoperations/enable`);
		return this;
	};
	getRecord = (entityId:string) => {
		this.setPath(`${this.basicPath}/settings/administrationbyentityoperations/getrecord/${entityId}`);
		return this;
	};
	getOperations = (entityId:string) => {
		this.setPath(`${this.basicPath}/settings/administrationbyentityoperations/getoperations/${entityId}`);
		return this;
	};
	operations = () => {
		this.setPath(`${this.basicPath}/operations`);
		return this;
	};
	checkIncludeUser = () => {
		this.setPath(`${this.basicPath}/api/Administration/operations/checkinclude/{userId}/{operationId}`);
		return this;
	};
	includeUser = () => {
		this.setPath(`${this.basicPath}/operations/include/user`);
		return this;
	};
	excludeUser = () => {
		this.setPath(`${this.basicPath}/operations/exclude/user`);
		return this;
	};
	includeRole = () => {
		this.setPath(`${this.basicPath}/operations/include/role`);
		return this;
	};
	excludeRole = () => {
		this.setPath(`${this.basicPath}/operations/exclude/role`);
		return this;
	};
	includeRolesList = () => {
		this.setPath(`${this.basicPath}/operations/includelist/role`);
		return this;
	};
	includeUsersList = () => {
		this.setPath(`${this.basicPath}/operations/includelist/user`);
		return this;
	};
	entityOperations = () => {
		this.setPath(`${this.basicPath}/entity/operations`);
		return this;
	};
	deleteEntityOperations = (operationId:string) => {
		this.setPath(`${this.basicPath}/entity/operations?id=${operationId}`);
		return this;
	};
	operationRight = (entityId:string) => {
		this.setPath(`${this.basicPath}/entity/operationsRight/${entityId}`);
		return this;
	};
	recordsRight = (entityId:string) => {
		this.setPath(`${this.basicPath}/entity/recordsRight/${entityId}`);
		return this;
	};
	entityOperationsCheck = () => {
		this.setPath(`${this.basicPath}/entity/operations/check`);
		return this;
	};
	entityRecords = () => {
		this.setPath(`${this.basicPath}/entity/records`);
		return this;
	};
	deleteEntityRecords = (entityRecordsId:string) => {
		this.setPath(`${this.basicPath}/entity/records?id=${entityRecordsId}`);
		return this;
	};
	operationCheckCanRead = () => {
		this.setPath(`${this.basicPath}/entity/operations/checkCanRead`);
		return this;
	};
	operationCheckCanEdit = () => {
		this.setPath(`${this.basicPath}/entity/operations/checkCanEdit`);
		return this;
	};
	operationCheckCanCreate = () => {
		this.setPath(`${this.basicPath}/entity/operations/checkCanCreate`);
		return this;
	};
	operationCheckCanDelete = () => {
		this.setPath(`${this.basicPath}/entity/operations/checkCanDelete`);
		return this;
	};
	recordCheckCanRead = () => {
		this.setPath(`${this.basicPath}/entity/record/checkCanRead`);
		return this;
	};
	recordCheckCanEdit = () => {
		this.setPath(`${this.basicPath}/entity/record/checkCanEdit`);
		return this;
	};
	recordCheckCanDelete = () => {
		this.setPath(`${this.basicPath}/entity/record/checkCanDelete`);
		return this;
	};
	includeUserInRecord = () => {
		this.setPath(`${this.basicPath}/records/include/user`);
		return this;
	};
	includeRoleInRecord = () => {
		this.setPath(`${this.basicPath}/records/include/role`);
		return this;
	};
	deleteUserOrRole = () => {
		this.setPath(`${this.basicPath}/records/delete`);
		return this;
	};
	linkOrgAndFuncRole = (orgRoleId:string,funcRoleId:string ) => {
		this.setPath(`${this.basicPath}/linkOrgAndFuncRole?orgRoleId=${orgRoleId}&funcRoleId=${funcRoleId}`);
		return this;
	};
	linkOrgAndFuncRoles = () => {
		this.setPath(`${this.basicPath}/linkOrgAndFuncRoles`);
		return this;
	};
	linkFuncAndOrgRoles = () => {
		this.setPath(`${this.basicPath}/linkFuncAndOrgRoles`);
		return this;
	};
	deleteLinkOrgAndFuncRoles = () => {
		this.setPath(`${this.basicPath}/deleteLinkOrgAndFuncRoles`);
		return this;
	};
	getAllFuncRoleByOrgRole = (id:string) => {
		this.setPath(`${this.basicPath}/getAllFuncRoleByOrgRole?roleId=${id}`);
		return this;
	};
	getAllOrgRoleByFuncRole = (id:string) => {
		this.setPath(`${this.basicPath}/getAllOrgRoleByFuncRole?roleId=${id}`);
		return this;
	};
	getAdministartiveObjectList = () => {
		this.setPath(`${this.basicPath}/settings/getAdministartiveObjectList`);
		return this;
	};
}
const administration = new Administration();

export { administration };