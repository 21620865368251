import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { v4 } from "uuid";
import { isNull } from "lodash";

import { dispatcher, selector } from "store";
import { modalController, Position } from "features/modals";
import { ModalType } from "features/modals/viewer/modal-viewer";

import { Dropdown, Field, Input, InputStyleName, Section, SelectStyleName } from "components";
import { ColorPanel } from "components/color-panel";
import { ModificateSection } from "../../modificate-section";
import { ToggleWithOptions } from "pages/section-wizzard/components/toggle-with-options/toggle-with-options";
import { MovingRules } from "./moving-rules";

import { Item } from "types";
import { HEXColors, ResultHEXColors, ResultType, StageFullInfo } from "types/entity";
import { FieldValidationState, validateRequired } from 'entities/Validation';

import { SettingPanelProps } from "../settings-panel";

import styles from "../settings-panel.module.css";

export const SettingsBody = observer((props: SettingPanelProps) => {
    const colors = Object.values(HEXColors);
    const [allStages, setAllStages] = useState<StageFullInfo[]>(props.allStages);
    const [validation, setValidation] = useState<FieldValidationState>({
        isInvalid: false,
        error: "Заполните поле"
    });

    useEffect(() => {
        setAllStages(props.allStages)
    }, [props.allStages]);

    const currentStage = useMemo(() => {
        return allStages.find((stage: StageFullInfo) => stage.id === props.idStage)
    }, [toJS(allStages), props.idStage]);

    useEffect(() => {        
        if (currentStage?.name !== '') {
            setValidation({
                isInvalid: false,
                error: "Заполните поле",
            })
        }
        else {
            setValidation({isInvalid: true})
        }
    }, [currentStage?.name, setValidation]);

    const isDisabledColors = useMemo(() => {                   
        return (!isNull(currentStage?.resultType));
    }, [currentStage]);

    const handleChangeColor = useCallback((value: string) => {
        if (currentStage) {
            dispatcher.stageModel.setColor(currentStage.id, value as HEXColors);
        }
    }, [currentStage?.id]);


    //TODO перепроверить этот кейс с changeStageNames, пока что он ломает апдейт имени иногда
    const handleChangeStageName = useCallback((value: string) => {
        if (currentStage) {
            dispatcher.stageModel.setStageName(currentStage.id, value);
            // dispatcher.stageModel.changeStageNames();
        }
    }, [currentStage?.id]);

    useEffect(() => {
        if (currentStage) {
            dispatcher.stageModel.setColor(props.idStage, currentStage.color);
            dispatcher.stageModel.setStageName(props.idStage, currentStage.name);
        }
    }, [props.idStage]);

    const validateSet = useCallback(() => {
        const isValidRes = validateRequired(currentStage!.name, validation)  
        if ((!isValidRes || currentStage?.name.length == 0)) {
            setValidation(prevValidation => (
            {
                ...prevValidation,
                isInvalid: (!isValidRes || currentStage?.name.length == 0),
                error: "Заполните поле",
            }));  
        } else if (props.allStages.filter(stage => stage.name === currentStage?.name).length > 1) {
                       setValidation(prevValidation => (
                {
                    ...prevValidation,
                    isInvalid: (props.allStages.filter(stage => stage.name === currentStage?.name).length > 1),
                    error: "Такая стадия уже существует",
                }));  
        }                
    }, [currentStage?.name, validation]);    

    return (
        <ModificateSection title='Настройки' closeAction={props.onCloseSettingsPanel}>
            <Field
                name='color'
                caption='Цвет'
                className={styles.field}
            >
                <ColorPanel
                    value={currentStage?.color}
                    onChange={handleChangeColor}
                    colorPanel={colors}
                    isDisabled={isDisabledColors}
                />
            </Field>
            <Field
                name='stageName'
                caption='Название стадии'
                className={styles.field}
                required
            >
                <Input
                    value={currentStage?.name ?? ''}
                    placeholder=''
                    onChangeValue={handleChangeStageName}
                    inputStyle={InputStyleName.Base}
                    maxLength={50}
                    isInvalid={validation.isInvalid}
                    invalidMessage={validation.error}
                    onFocusOut={validateSet}
                />
            </Field>
            <MovingRules allStages={allStages} idStage={props.idStage}/>
        </ModificateSection>
    );
});

export const AutoTransitionBody = observer(() => {
    const [autoTransition, setAutoTransition] = useState<Item>();
    //TODO пустая заглушка, в дальнейшем реализовать заполнение данными
    const autoTransitionItems: Item[] = [];

    const handleAutoTransitionLoad = useCallback((value: string | null, limit?: number | undefined) => {
    }, []);
    const handleAutoTransitionSelect = useCallback(() => {
    }, []);

    return (
        <Section title='Автоматический переход'>
            <Field
                name='autoTransition'
                caption='Автоматический переход на след. стадию'
                className={styles.field}
            >
                {/* TODO переделать на новый Dropdown с чекбоксами внутри */}
                <Dropdown
                    items={autoTransitionItems}
                    onChangeValue={handleAutoTransitionSelect}
                    isInput={true}
                    onItemsLoad={(value, limit) => handleAutoTransitionLoad(value, limit)}
                    value={autoTransition}
                    selectStyle={SelectStyleName.Base}
                />
            </Field>
        </Section>
    );
});

enum ResultStageOptionsNames {
    Neutral = "Нейтральный",
    Positive = "Положительный",
    Negative = "Отрицательный"
}

export const AdditionalBody = observer((props: {
    idStage: string;
    allStages: StageFullInfo[]
}) => {
    const [isResultStage, setResultStage] = useState<boolean>(false);
    const defaultResultStageOptions = [
        { id: ResultStageOptionsNames.Neutral, name: ResultStageOptionsNames.Neutral, info: 'Например, «Задача выполнена»', type: ResultType.NULL, checked: true },
        { id: ResultStageOptionsNames.Positive, name: ResultStageOptionsNames.Positive, info: 'Например, лид: «Перевод в продажи»', type: ResultType.SUCCESSFUL, checked: false },
        { id: ResultStageOptionsNames.Negative, name: ResultStageOptionsNames.Negative, info: 'Например, лид: «Дисквалифицирован»', type: ResultType.NEGATIVE, checked: false }
    ];

    const [detailedResult, setDetailedResult] = useState<boolean>(false);

    const currentStage = useMemo(() => {
        return props.allStages.find((stage: StageFullInfo) => stage.id === props.idStage)
    }, [toJS(props.allStages), toJS(props.allStages), props.idStage]);

    const handleResultStageChange = useCallback((value: boolean) => {
        setResultStage(value);
        if (currentStage) {
            if (value) {
                dispatcher.stageModel.setStageType(currentStage.id, ResultType.NULL);
                dispatcher.stageModel.setColor(currentStage.id, ResultHEXColors.NULL);
                modalController.notificationAdd({
                    id: v4(),
                    type: ModalType.NOTIFICATION,
                    position: Position.CENTER,
                    layout: <div>{`Стадия «${currentStage.name}» перенесена в результирующие стадии`}</div>,
                    allowTimer: true,
                    allowDefaultClick: true,
                    withBackdrop: false,
                })
            } else {
                dispatcher.stageModel.setStageType(currentStage.id, null);
                dispatcher.stageModel.setColor(currentStage.id, HEXColors["#C7D7FE"]);
                modalController.notificationAdd({
                    id: v4(),
                    type: ModalType.NOTIFICATION,
                    position: Position.CENTER,
                    layout: <div>{`Стадия «${currentStage.name}» перенесена в промежуточные стадии`}</div>,
                    allowTimer: true,
                    allowDefaultClick: true,
                    withBackdrop: false,
                })
            }
        }
    }, [props.idStage, currentStage]);

    const handleResultStageSelect = useCallback((value: string) => {
        if (currentStage) {
            dispatcher.stageModel.setStageType(currentStage.id, defaultResultStageOptions.find(stage => stage.name === value)?.type ?? null);
            if (value === ResultStageOptionsNames.Neutral) {
                dispatcher.stageModel.setColor(currentStage.id, ResultHEXColors.NULL as string);
            } else if (value === ResultStageOptionsNames.Negative) {
                dispatcher.stageModel.setColor(currentStage.id, ResultHEXColors.NEGATIVE as string);
            } else if (ResultStageOptionsNames.Positive) {
                dispatcher.stageModel.setColor(currentStage.id, ResultHEXColors.SUCCESSFUL as string);
            }
        }
    }, [currentStage]);

    //TODO требует бизнес доработок
    const handleDetailedResultChange = useCallback((value: boolean) => {
        setDetailedResult(value);
    }, []);

    const checkedResultStageOptions = useMemo(() => {
        return defaultResultStageOptions.map(option => ({
            ...option,
            checked: currentStage?.resultType === option.type,
        }))
    }, [currentStage]);

    useEffect(() => {
        if (currentStage) {
            setResultStage(!isNull(currentStage.resultType));
        }
    }, [props.idStage]);

    return (
        <Section title='Дополнительно'>
            <ToggleWithOptions
                name=""
                caption="Стадия является результатом"
                description="Отметьте, если требуется конечная стадия"
                onToggle={handleResultStageChange}
                toggleValue={isResultStage}
                onSelect={handleResultStageSelect}
                options={checkedResultStageOptions}
            />
            {/* {isResultStage ?
                <HorizontalField
                    name='detailedResult'
                    caption='Требовать подробный результат'
                    className={styles.field}
                >
                    <Toggle
                        checked={detailedResult}
                        onCheck={handleDetailedResultChange}
                    />
                </HorizontalField>
                : <></>
            } */}
        </Section>
    );
});