import { useRef, useState } from "react";

import { Button, ButtonStyle } from "components";
import { HierarchicalStyles } from "../core/StylesSystem";
import Styles, { IHierarchicalSelectStyles } from "../core/StylesSystem";
import HierarchicalItem from "./hierarchical-item";
import { Item } from "types";

import { ArrowToDownGrey } from "assets/icons";

// import stylesBase from "components/select/select.module.css";
import styles from "./hierarchical-select.module.css";
import stylesBase from "components/select/select.module.css"

interface HierarchicalSelectProps {
    items: Item[] | undefined;
    value: Item;
    nullableValue: JSX.Element;
    onChange: (selectedItem: Item) => void;
    onItemsLoad: () => void;
    className?: string;
    isInvalid?: boolean;
    styles?: HierarchicalStyles;
    placeholder?: string;
}

function HierarchicalSelect(props: HierarchicalSelectProps) {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [showItems, setShowItems] = useState(false);

    const hierarchicalStyles: IHierarchicalSelectStyles = Styles[props.styles ?? HierarchicalStyles.Primary];

    // const selectStyle = Styles[props.selectStyle ?? SelectStyles.Base];
    // const inputStyle = props.inputStyle ?? InputStyles.BaseWithoutBorder;


    let classNames = `${stylesBase.wrapper} ${props.className}`;
    let listClassNames = `${stylesBase.selectList} ${styles.list}`;
    let inputClassNames = hierarchicalStyles.value.classNames;
    let listItemClassNames = `${stylesBase.listItem} `;

    if (props.items && props.items!.length > 0 && showItems) {
        listClassNames += " " + stylesBase.visible;
    }

    if (showItems) {
        inputClassNames += hierarchicalStyles.value.focus;
    }

    function hideMenu() {
        setShowItems(false);
        document.removeEventListener("click", handleClick);
    }

    function handleClick(event: Event) {
        if (wrapperRef.current != null && !wrapperRef.current.contains(event.target as Node)) {
            hideMenu();
        }
    }

    const handleItemClick = (selectedItem: Item) => {
        setShowItems(false);
        props.onChange(selectedItem);
    };

    function onSelectOpen() {
        props.onItemsLoad();
        setShowItems(true);
        document.addEventListener("click", handleClick);
    }

    const onButtonClick = () => {
        if (showItems) {
            hideMenu();
        }
        else {
            onSelectOpen();
        }
    }

    function renderDisplayValue(items: Item[] | undefined, value: Item) {
        return <span className={styles.displayValue}>{renderPrefix(items, value) + value.name}</span>;
    }

    function renderPrefix(items: Item[] | undefined, value: Item) {
        const prefix = items?.find(i => IsCollect(i, value))?.name;
        return prefix ? prefix + ": " : "";
    }

    function IsCollect(item: Item, value: Item): boolean {
        return item.multilevelItems?.find((v: any) => v.id === value.id) !== undefined;
    }

    return (
        <div ref={wrapperRef} className={classNames}>
            <div className={inputClassNames} onClick={onSelectOpen} >
                <div className={styles.item}>
                    {
                        props.value.name !== "" ?
                            renderDisplayValue(props.items, props.value) :
                            <span className={styles.placeholder}>{props.placeholder ?? ""}</span>

                    }
                </div>
                {(props.styles && props.styles !== HierarchicalStyles.AdvancedFilter) &&
                    <Button
                        onClick={onButtonClick}
                        firstIcon={<ArrowToDownGrey />}
                        style={ButtonStyle.Icon}
                        className={showItems ? stylesBase.selectButton + " " + stylesBase.close : stylesBase.selectButton}
                    />
                }
            </div>
            <ul className={listClassNames}>
                {props.items?.map((item: Item) => (
                    <li key={item.id} className={listItemClassNames} onClick={(e) => { e.stopPropagation() }}>
                        <HierarchicalItem
                            value={item}
                            onClick={(item: Item) => {
                                handleItemClick(item)
                            }}
                            isCanOpen={showItems}
                        />
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default HierarchicalSelect;