import authStore from "AuthStore";

enum SectionRoute {
    Lead = "lead",
    Sale = "sale",
    Contact = "contact",
    Account = "account",
    Settings = "settings",
    Workspace = "workspace",
    User = "user",
    Import = "import",
    SinglePage = "singlePage",
    // License = "license"
}

export default SectionRoute;