import { useCallback, useState } from "react";

import { Button, ButtonStyle, ChoiceOption } from "components";

import { LookupType } from "pages/section-wizzard/data/data";

import { OffRadioButton, OnRadioButton } from "assets/icons";

import styles from "./configurator-stage-model.module.css"

export const ConfiguratorStageModel = (props: { onNextStep: (option: LookupType) => void }) => {
    const [selectedOption, setSelectedOption] = useState(LookupType.NewLookup);

    const getRadioButtonIcon = useCallback((option: LookupType) => {
        return selectedOption === option ? <OnRadioButton /> : <OffRadioButton />;
    }, [selectedOption]);

    const handleOptionSelect = useCallback((option: LookupType) => {
        setSelectedOption(option);
    }, []);

    const handleNextStep = useCallback(() => {
        props.onNextStep(selectedOption)
    }, [selectedOption]);

    return (
        <div className={styles.lookupStepWrapper}>
            <div className={styles.titleBlock}>
                <span className={styles.title}>Настройки стадийной модели</span>
            </div>
            <div className={styles.lookupRadioButtonBlock}>
                <ChoiceOption
                    name={LookupType.NewLookup}
                    checked={selectedOption === LookupType.NewLookup}
                    onSelect={handleOptionSelect}
                    icon={getRadioButtonIcon(LookupType.NewLookup)}
                    title="Создать новую"
                    description="Создать новый справочник и перейти к настройке стадийной модели"
                    className={styles.choiceOption}
                />
                <ChoiceOption
                    name={LookupType.ExistLookup}
                    checked={selectedOption === LookupType.ExistLookup}
                    disabled
                    onSelect={handleOptionSelect}
                    icon={getRadioButtonIcon(LookupType.ExistLookup)}
                    title="Выбрать существующую"
                    description="Выбрать справочник, по которому нужно построить стадийную модель"
                    className={styles.choiceOption}
                />
            </div>
            <Button
                caption="Продолжить"
                onClick={handleNextStep}
                style={ButtonStyle.Primary}
                className={styles.nextStepButton}
            />
        </div>
    );
};