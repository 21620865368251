import classNames from "classnames";

import styles from "./choice-option.module.css";
import { useCallback } from "react";

export type ChoiceOptionProps = {
    name: string,
    checked: boolean,
    disabled?: boolean,
    onSelect: (option: any) => void,
    icon: JSX.Element,
    title: string,
    description: string,
    className?: string,
}

export function ChoiceOption(props: ChoiceOptionProps) {
    const { name, checked, disabled, onSelect, icon, title, description, className } = props;

    const infoClasses = classNames(`${styles.info}`, {
        [`${styles.selected}`]: checked,
        [`${styles.disabled}`]: disabled,
        [`${className}`]: className
    });

    const handleClick = useCallback(() => {
        if (!disabled) {
            onSelect(name);
        }
    }, [name]);

    return (
        <div className={infoClasses} onClick={handleClick}>
            <div className={styles.icon}>{icon}</div>
            <div className={styles.text}>
                <div className={styles.title}>{title}</div>
                <div className={styles.description}>{description}</div>
            </div>
        </div>
    );
}

