import authStore from "AuthStore";
// import m from "app/services/api";
import { SavingState } from "entities/Entity";
import { LoadingState } from "entities/ListStore";
import { action, makeObservable, observable } from "mobx";
import IFilter from "./IFilter";
import { api } from "api";

export interface IStaticGroup {
    id: string | null;
    userId: string | null;
    entityName: string | null;
    name: string | null;
    filter: IFilter | null;
    folderId: string | null;
    modifiedOn: string;
    createdOn: string;
}

export class StaticGroup implements IStaticGroup {
	id: string | null;
	userId: string | null;
	entityName: string | null;
	name: string | null;
	filter: IFilter | null = null;
	folderId: string | null;
	modifiedOn: string = "";
	createdOn: string = "";

	loadingState: LoadingState;
	savingState: SavingState;

	constructor(entityName: string) {
		makeObservable(this, {
			id: observable,
			userId: observable,
			entityName: observable,
			name: observable,
			filter: observable,
			folderId: observable,
			loadingState: observable,
			savingState: observable,

			setValue: action,
			deserialize: action,
		});

		this.id = null;
		this.userId = authStore.userId;
		this.name = null;
		this.entityName = entityName;
		this.folderId = null;

		this.loadingState = LoadingState.NotAsked;
		this.savingState = SavingState.NotAsked;

	}

	get isLoading(): boolean {
		return this.loadingState === LoadingState.Loading;
	}

	get isLoaded(): boolean {
		return this.loadingState === LoadingState.Loaded;
	}

	get isError(): boolean {
		return this.loadingState === LoadingState.Failed;
	}

	get isSaving(): boolean {
		return this.savingState === SavingState.Saving;
	}

	get isSaved(): boolean {
		return this.savingState === SavingState.Saved;
	}

	setValue(value: any, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	async save(): Promise<boolean> {
		if (!this.validate()) {
			return false;
		}
		this.setValue(SavingState.Saving, "savingState");
		const data = this.serialize();
		let response;
		if (this.id === null) {
			// response = await m.post("/api/StaticGroup/create", data);
			response = await api.http.httpApi.staticGroup.staticGroupCreate().post(data);

			if (response && response.data.success) {
				this.setValue(response.data, "id");
			}
		}
		else {
			// response = await m.post("/api/StaticGroup/update", data);
			response =  await api.http.httpApi.staticGroup.staticGroupUpdate().post(data);
		}

		if (response && response.data.success) {

			this.setValue(SavingState.Saved, "savingState");
			return true;
		}
		else {
			this.setValue(SavingState.Failed, "savingState");
		}
		return false;
	}

	async excludeRecords(includeRecords: string[],excludeRecords: string[]): Promise<boolean> {
		if (!this.validate()) {
			return false;
		}
		this.setValue(SavingState.Saving, "savingState");
		const data = {
			staticGroupId: this.id,
			recordsToExclude: includeRecords,
			recordsToSave: excludeRecords
            
		};
		// let response = await m.post("/api/StaticGroup/exclude-records", data);
		let response =  await api.http.httpApi.staticGroup.excludeRecords().post(data);

		if (response && response.data.success) {

			this.setValue(SavingState.Saved, "savingState");
			return true;
		}
		else {
			this.setValue(SavingState.Failed, "savingState");
		}
		return false;
	}

	async delete(): Promise<boolean> {
		this.setValue(SavingState.Saving, "savingState");
		// let response = await m.delete(`/api/StaticGroup/delete?id=${this.id}`);
		let response =  await api.http.httpApi.staticGroup.staticGroupDelete(this.id!).delete();
		if (response && response.data.success === true) {
			this.setValue(SavingState.Saved, "savingState");
			return true;
		} else {
			this.setValue(SavingState.Failed, "savingState");

		}
		return false;
	}


	validate() {
		if (this.userId === null) return false;
		if (this.name === null) return false;
		if (this.entityName === null) return false;
		return true;
	}

	deserialize(p: any) {
		this.id = p.id ?? null;
		this.userId = p.userId;
		this.name = p.name;
		this.filter = p.filter;
		this.entityName = p.entityName;
		this.folderId = p.folderId;
		this.createdOn = p.createdOn;
		this.modifiedOn = p.modifiedOn;
	}

	serialize() {
		return this.id ?
			{
				id: this.id,
				name: this.name,
				folderId: this.folderId,
				entityName: this.entityName,
				userId: this.userId,
				filter: this.filter,
			}
			:
			{
				name: this.name,
				folderId: this.folderId,
				entityName: this.entityName,
				userId: this.userId,
				filter: this.filter,
			};
	}
}

export default StaticGroup;


