import styles from "./header.module.css";
import { useState } from "react";
import CheckBox from "components/check-box/check-box";


function Header(props: { onChangeAll(value: boolean): void }) {
    const [checked, setCheck] = useState<boolean>(false);
    return (
        <div className={styles.checkBoxContainer}>
            <CheckBox checked={checked} onChangeChecked={(value) => {
                props.onChangeAll(value);
                setCheck(value);
            }} />
        </div>
    )
}

export default Header;