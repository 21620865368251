import { getNoun } from "app/services/NounService";
import Entity, { IEntityStore } from "entities/Entity";

export interface IQuickViewBlock {
    schema: string
    id: string,
    entity: Entity<IEntityStore>,
    fields: IQuickViewFilld[],
    infoFields: IQuickViewFilld[],
    stageField: IQuickViewFilld,
    pageTitle: string,
    quickViewTitle: string,
    closeQuick: () => void;
    load: () => Promise<void>;
}

export interface IQuickViewFilld {
    type: QuickViewFilldType;
    name: string;
    lookupName?: string;
    isNotValidation?: boolean;
    required?: boolean;
    caption: string;
    isNew?: boolean;
    isSorted?: boolean;
    isDisabled?: boolean
}

export enum QuickViewFilldType {
    Select,
    SelectFiltering,
    InputNumber,
    Input,
    Note,
    Date
}

export interface IQuickViewData {
    schema: string,
    id: string,
    entity: Entity<IEntityStore>,
    fields: IQuickViewFilld[],
    closeQuick: () => void;
    load: () => Promise<void>;
}

export function getModifiedOn(date: string) {
    let time = Date.now() - Date.parse(date);
    let result = 0;
    let days = "";
    if (time < 60000) {
        return "только что";
    }
    else {
        switch (time) {
            case ((time >= 60000) && (time < 3600000)) ? time : true:
                {
                    //минуты
                    result = Math.trunc(time / 60000);
                    return `${result} мин. назад`;
                }
            case ((time >= 3600000) && (time < 86400000)) ? time : true:
                {
                    //часы
                    result = Math.trunc(time / 3600000);
                    days = getNoun(result, "час", "часа", "часов");
                    return `${result} ${days} назад`;
                }
            case ((time >= 86400000) && (time < 604800000)) ? time : true:
                {
                    //дни (считается до 1 недели)
                    result = Math.trunc(time / 86400000);
                    days = getNoun(result, "день", "дня", "дней");
                    return `${result} ${days} назад`;
                }
            case ((time >= 604800000) && (time < 2592000000)) ? time : true:
                {
                    //недели (максимум 4 недели)
                    result = Math.trunc(time / 604800000);
                    days = getNoun(result, "неделя", "недели", "недель");
                    return `${result} ${days} назад`;
                }
            case ((time >= 2592000000) && (time < 31536000000)) ? time : true:
                {
                    //месяцы
                    result = Math.trunc(time / 2592000000);
                    days = getNoun(result, "месяц", "месяца", "месяцев");
                    return `${result} ${days} назад`;
                }
            default:
                {
                    //годы
                    result = Math.trunc(time / 31536000000);
                    days = getNoun(result, "год", "года", "лет");
                    return `${result} ${days} назад`;
                }
        }
    }
}