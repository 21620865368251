import { observer } from "mobx-react-lite";

import { ColumnType } from "entities/ColumnType";

import {
    Clocks, Numbers, DecimalIcon, DateIcon,
    TextIcon, Guid, DateTimeIcon, LogicalIcon,
    SectionMasterLookupIcon, ChangeView, FieldGroupIcon
} from "assets/icons";

const TypeIcon = observer((props: { type: string | null | undefined }) => {
    switch (props.type!) {
        case ColumnType.String:
            return <TextIcon />

        case ColumnType.Guid:
            return <Guid />

        case ColumnType.Integer:
            return <Numbers />

        case ColumnType.Double:
        case ColumnType.Decimal:
            return <DecimalIcon />

        case "Logical":
        case ColumnType.Boolean:
            return <LogicalIcon />

        case ColumnType.DateTime:
            return <DateTimeIcon />

        case ColumnType.Date:
            return <DateIcon />

        case ColumnType.Time:
            return <Clocks />

        case ColumnType.Lookup:
            return <SectionMasterLookupIcon />

        case ColumnType.Detail:
            return <ChangeView />

        case ColumnType.FieldGroup:
            return <FieldGroupIcon />

        default:
            return <></>
    }

});

export default TypeIcon;