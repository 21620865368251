import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";

import { Button, ButtonStyle, Field, Input, InputStyleName } from "components";

import { CloseMaxi } from "assets/icons";

import styles from "./new-filter.module.css";


interface INewOperationItemProps {
    onSave: (filterName: string | null) => void,
    close: () => void
}

const NewFilter = observer((props: INewOperationItemProps) => {
    const [filterName, setFilterName] = useState<string | null>(null);

    const onSaveKeyUp = useCallback((event: KeyboardEvent) => {
        event.stopPropagation()
        if (event.key === 'Enter') {
            handleCancel();
            props.onSave(filterName);
        }
    }, [filterName]);

    useEffect(() => {
        document.addEventListener('keydown', onSaveKeyUp);
        return () => {
            document.removeEventListener('keydown', onSaveKeyUp);
        };
    }, [onSaveKeyUp])

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation()
    }, []);

    const handleChangeFilterName = useCallback((value: string) => {
        setFilterName(value);
    }, [filterName]);

    const handleCancel = useCallback(() => {
        setFilterName(null);
        props.close();
    }, [props.close]);

    const handleSave = useCallback(() => {
        handleCancel();
        props.onSave(filterName);
    }, [props.onSave, filterName]);

    return (

        <div className={styles.dialog} onClick={handleClick}>
            <div className={styles.dialogHeader}>
                <span className={styles.title}>Новый фильтр</span>
                <CloseMaxi className={styles.closeButton} onClick={handleCancel} />
            </div>
            <div className={styles.dialogBody}>
                <Field name={'filterName'} caption={'Название'} className={styles.selectPropertyField}>
                    <Input
                        placeholder="Введите значение"
                        value={filterName}
                        onChangeValue={handleChangeFilterName}
                        focus={true}
                        maxLength={50}
                        inputStyle={InputStyleName.Base}
                    />
                </Field>
            </div>
            <div className={styles.dialogFooter}>
                <Button
                    caption="Отмена"
                    onClick={handleCancel}
                    style={ButtonStyle.Subtle}
                />
                <Button
                    caption="Сохранить"
                    onClick={handleSave}
                    style={ButtonStyle.Primary}
                    isDisabled={filterName === null || filterName?.length < 1}
                />
            </div>
        </div>
    )
});

export default NewFilter;