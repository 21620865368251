import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";

import NavigationTitle from "../NavigationTitle";
import Tabs from "components/tabs/tabs";
import TreeView, { UserRoleType } from "features/tree-view/tree-view";
import WarningMessage from "components/dialog/warning-message/warning-message";
import WarningDialog from "components/dialog/warning-dialog/warning-dialog";
import { UsersTab, LeadersTab, RolesTab } from "./tabs/OrgStructureTabs";

import orgStructureMainPanelStore from "./OrgStructureMainPanelStore";
import { rootUserRoles } from "entities/userRole/RootUserRoles";
import UserRole from "entities/userRole/UserRole";
import { userStore } from "entities/user/UsersSectionStore";
import { UserEnums, UsersSettings, leadersCaption, usersCaption } from "../data/Fields";

import styles from "./OrgStructure.module.css";


const OrgStructureMainPanel = observer(function () {
    const [isOpenWarningMessage, setOpenWarningMessage] = useState<boolean>(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [tab, setTab] = useState<JSX.Element>(<UsersTab />);

    const tabs = useMemo(() => {
        return [
            {
                caption: usersCaption,
                action: () => { setTab(<UsersTab />); }
            },
            {
                caption: leadersCaption,
                action: () => { setTab(<LeadersTab />); }
            },
            {
                caption: UsersSettings.filter(e => e.id === UserEnums.FunctionalRoles)[0].name,
                action: () => { setTab(<RolesTab />); }
            },
        ]
    }, [])

    const onRoleClick = useCallback((userRole: UserRole) => {
        if (userRole === null) {
            userStore.load(null);
            orgStructureMainPanelStore.setValue(userStore.data, "filteredUserStore");
            orgStructureMainPanelStore.setValue(null, "userRole");
            orgStructureMainPanelStore.setValue([], "funcRolesStore");
            orgStructureMainPanelStore.setValue([], "funcLeaderRolesStore");
            orgStructureMainPanelStore.setValue([], "leaderStore");
        }
        else {
            orgStructureMainPanelStore.setValue(userRole, "userRole");
            orgStructureMainPanelStore.setUsersById(userRole.data!.id);
            orgStructureMainPanelStore.loadFuncRoles(userRole.data?.id!);
            if (orgStructureMainPanelStore.userRole?.data?.hasLeadRole) {
                orgStructureMainPanelStore.setLeadersById(orgStructureMainPanelStore.userRole.data.leadRole.id);
                orgStructureMainPanelStore.loadFuncLeaderRoles(userRole.data?.leadRole.id!);
            }
            else {
                orgStructureMainPanelStore.setValue([], "funcLeaderRolesStore");
                orgStructureMainPanelStore.setValue([], "leaderStore");
            }
        }
    }, [])

    const onClickDelete = useCallback(async (userRole: UserRole) => {
        let data = await orgStructureMainPanelStore.getUsersByRoleId(userRole.data!.id)
        if (data.length > 0)
            setOpenWarningMessage(true)
        else setOpenDeleteDialog(true)
    }, [])

    const deleteRole = useCallback(async () => {
        if (orgStructureMainPanelStore.userRole?.data?.id) {
            let deleted = await rootUserRoles.deleteFolder(orgStructureMainPanelStore.userRole.data?.id, UserRoleType.OrganizationRole);
            if (deleted) {
                setOpenDeleteDialog(false);
            }
        }
    }, [])
    const deleteBack = useCallback(() => {
        setOpenDeleteDialog(false)
    }, [])
    const warningBack = useCallback(() => {
        setOpenWarningMessage(false)
    }, [])

    useEffect(() => {
        userStore.load(null);
        orgStructureMainPanelStore.setValue(userStore.data, "filteredUserStore");
        orgStructureMainPanelStore.setValue(null, "userRole");
    }, [])

    return (
        <div className={styles.systemMainPanelWrapper}>
            <NavigationTitle />
            <div className={styles.mainTitle}>
                {UsersSettings[1].name}
            </div>

            <div className={styles.systemMainPanel}>
                <div className={styles.leftPanel}>
                    <TreeView
                        isOpen={true}
                        type={UserRoleType.OrganizationRole}
                        onClick={onRoleClick}
                        choosingItem={orgStructureMainPanelStore.userRole}
                        onClickDelete={onClickDelete}
                        isHeader={true}
                        isActionsVisible={true}
                    />
                    <div className={styles.mainDivider} />
                </div>
                <div className={styles.rightPanel}>
                    <Tabs items={tabs} className={styles.tab} />
                    {tab}
                </div>
            </div>

            <WarningDialog
                value={"Роль будет удалена без возможности восстановления."}
                valueReturn={"Отмена"}
                valueDelete={"Удалить"}
                isOpen={isOpenDeleteDialog}
                onBackClick={deleteBack}
                onCancelClick={deleteRole}
            />
            <WarningMessage
                value={"Невозможно удалить элемент организационной структуры, в котором содержатся пользователи.\nИсключите пользователей и попробуйте снова."}
                isOpen={isOpenWarningMessage}
                onBackClick={warningBack}
            />

        </div>
    );
});



export default OrgStructureMainPanel;