import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import classNames from 'classnames';

import { synchroiser } from 'synchroiser';
import { dispatcher, selector } from 'store';
import SavedFilter from 'entities/filter/SavedFilter';
import { FilterStore } from 'entities/filter/FilterStore';

import { LayoutDeleteConfirm, RenameLayout, RestoreLayout } from './filter-popups';
import FilterConditions from './filter-conditions/filter-conditions';

import { AdvancedFilterConst } from 'features/section-head/data/constants';
import { Position, modalController } from 'features/modals';

import { Button, ButtonStyle, Select } from 'components';

import { Item } from 'types';
import { LoadingState } from 'types/entity';

import {
    DeleteIcon,
    FavouriteStar,
    GoBack,
    MoreEllipsis,
    Rename,
    RestoreIcon,
    SaveAs,
    SaveIconAsFloppyDisk,
} from 'assets/icons';

import styles from './advanced-filter.module.css';

enum AdvancedFilterActionEnum {
    Favourite = 'Favourite',
    Save = 'Save',
    SaveAs = 'SaveAs',
    Delete = 'Delete',
}

const AdvancedFilterElement = observer(function (props: {
    isOpen: boolean;
    advancedFilter: SavedFilter;
}) {
    const { isOpen, advancedFilter } = props;

    const [idModal] = useState<string>(v4());
    const [idNotification] = useState<string>(v4());
    const [isClickCleaning, setClickCleaning] = useState<boolean>(false);

    const isNewFilter = useMemo(() => advancedFilter && advancedFilter.id === null, [advancedFilter]);

    const cleanButtonClassName = classNames(styles.advancedFilterActionsButton, {
        [`${styles.activeClearButton}`]: isClickCleaning
    });

    const filterActions: Item[] = useMemo(() => [
        {
            id: AdvancedFilterActionEnum.Favourite,
            name: advancedFilter?.isFavorite ? 'Убрать из Избранного' : 'Добавить в Избранное',
            icon: <FavouriteStar className={styles.favStarInActions} />,
            isDisabled: isNewFilter,
            isLocked: isNewFilter,
        },
        {
            id: AdvancedFilterActionEnum.Save,
            name: 'Сохранить',
            icon: <SaveIconAsFloppyDisk />,
            isDisabled: !advancedFilter?.hasChanges || (isNewFilter && !advancedFilter?.hasChanges),
            isLocked: !advancedFilter?.hasChanges || (isNewFilter && !advancedFilter?.hasChanges),
        },
        {
            id: AdvancedFilterActionEnum.SaveAs,
            name: 'Сохранить как',
            isSeparator: true,
            icon: <SaveAs />,
            isDisabled: isNewFilter,
            isLocked: isNewFilter,
        },

        {
            id: AdvancedFilterActionEnum.Delete,
            name: 'Удалить',
            isRed: true,
            icon: <DeleteIcon />,
            isDisabled: isNewFilter,
            isLocked: isNewFilter,
        },
    ], [advancedFilter?.isFavorite, advancedFilter?.hasChanges, isNewFilter]);

    useEffect(() => {
        if (isOpen && advancedFilter.filterInfo === null) {
            advancedFilter.filterInfo = new FilterStore(advancedFilter.entityName!, null, null);
        }
    }, [advancedFilter, isOpen]);

    useEffect(() => {
        if (advancedFilter &&
            (advancedFilter.filterInfo?.filters === undefined ||
                advancedFilter.filterInfo?.filters.length === 0) &&
            advancedFilter.hasChanges
        ) {
            advancedFilter.setValue(false, 'hasChanges');
        }
    }, [advancedFilter?.filterInfo?.filters.length]);

    const handleChangeValue = useCallback((value: Item | null) => {
        if (value) {
            switch (value.id) {
                case AdvancedFilterActionEnum.Favourite:
                    addToFavorite();
                    break;
                case AdvancedFilterActionEnum.Save:
                    saveOrUpdate();
                    break;
                case AdvancedFilterActionEnum.SaveAs:
                    modalController.popupAdd({
                        id: idModal,
                        layout: <RenameLayout
                            dialogTitle='Новый фильтр'
                            captionSuccessfulButton='Сохранить'
                            startName={null}
                            onRename={saveAsNew}
                            onClose={closeConfirm}
                        />,
                        closeFunc: closeConfirm
                    });
                    break;
                case AdvancedFilterActionEnum.Delete:
                    modalController.popupAdd({
                        id: idModal,
                        layout: <LayoutDeleteConfirm
                            delete={deleteFilter}
                            dialogBody={`Фильтр «${advancedFilter.filterName}» будет удален без возможности восстановления.`}
                            closeConfirm={closeConfirm}
                        />,
                        closeFunc: closeConfirm
                    });

                    break;
            }
        }
    }, [advancedFilter]);

    const handleMouseLeaveFromCleanButton = useCallback(() => {
        if (isClickCleaning) {
            setClickCleaning(!isClickCleaning);
        }
    }, [isClickCleaning]);

    const addNotification = useCallback(() => {
        if (synchroiser.loadingState === LoadingState.Error && synchroiser.errorMessage) {
            const layoutNotificationWithError =
                <div className={styles.errorNotification}>
                    {synchroiser.errorMessage}
                </div>;

            modalController.notificationAdd({ id: idNotification, position: Position.CENTER, layout: layoutNotificationWithError, allowDefaultClick: true, allowTimer: true });
        }

    }, [synchroiser.errorMessage]);

    const addCondition = useCallback(() => {
        if (advancedFilter.filterInfo) {
            advancedFilter.filterInfo.addFilter();
        }
    }, [advancedFilter]);

    const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e?.stopPropagation();
        modalController.modalRemove(idModal);
    }, []);

    const resetSavedFilter = useCallback(() => {
        if (advancedFilter && advancedFilter.id !== null) {
            advancedFilter.setValue(null, "id");
            advancedFilter.setValue(null, "filterName");
            advancedFilter.setValue(false, "isFavorite");
        }
    }, [advancedFilter]);

    const handleBack = useCallback(() => {
        AdvancedFilterConst.setOpenAdvancedFilterTree(!AdvancedFilterConst.isOpenAdvancedFilterTree, true);
    }, []);

    const addToFavorite = useCallback(async () => {
        if (advancedFilter && advancedFilter.id) {
            const updatedFilter = new SavedFilter(advancedFilter.entityName!);
            updatedFilter.deserialize(advancedFilter);
            advancedFilter.setValue(!advancedFilter.isFavorite, 'isFavorite');

            await advancedFilter.update(true).then(async () => {
                if (synchroiser.loadingState == LoadingState.Error) {
                    AdvancedFilterConst.setAdvancedFilter(updatedFilter);
                }
            });
            addNotification();
        }
    }, [advancedFilter, synchroiser.loadingState]);

    const applyFilter = useCallback(async () => {
        if (advancedFilter?.id && !advancedFilter.hasChanges) {
            await synchroiser.getFilter(advancedFilter.id);
            return;
        }
        if (advancedFilter.filterInfo) {
            resetSavedFilter();
            dispatcher.filter.setSavedFilter(advancedFilter);
            await synchroiser.getEntityWithFilter();
            addNotification();
        }
    }, [advancedFilter]);

    const clean = useCallback(() => {
        if (!isClickCleaning) {
            setClickCleaning(true);
        }
        else if (advancedFilter.filterInfo?.filters && advancedFilter.filterInfo?.filters.length > 0) {
            advancedFilter.cancel();
            advancedFilter.filterInfo = new FilterStore(advancedFilter.entityName!, null, null);
            setClickCleaning(false);
        }
    }, [isClickCleaning, advancedFilter]);

    const save = useCallback(async (filterName: string | null) => {
        if (advancedFilter?.filterInfo?.isValid && filterName) {
            resetSavedFilter();
            const savedFilter = new SavedFilter(advancedFilter.entityName!);
            savedFilter.deserialize(advancedFilter);
            advancedFilter.setValue(filterName, 'filterName');
            await advancedFilter.save().then(async () => {
                if (synchroiser.loadingState == LoadingState.Error) {
                    AdvancedFilterConst.setAdvancedFilter(savedFilter);
                    return;
                }
                if (selector.filter.getFilter()?.savedFilter?.id === advancedFilter.id) {
                    await synchroiser.getEntityWithFilter();
                }
                await synchroiser.getSavedFilterFolderTree();
            })
            addNotification();
        }
    }, [advancedFilter, selector.filter.getFilter()?.savedFilter?.id, synchroiser.loadingState]);

    const saveOrUpdate = useCallback(async () => {
        if (!isNewFilter) {
            await advancedFilter.update(false).then(async () => {
                if (synchroiser.loadingState != LoadingState.Error) {
                    if (selector.filter.getFilter()?.savedFilter?.id === advancedFilter.id) {
                        await synchroiser.getEntityWithFilter();
                    }
                    await synchroiser.getSavedFilterFolderTree();
                }

            })
            addNotification();
        } else {
            modalController.popupAdd({
                id: idModal,
                layout: <RenameLayout
                    dialogTitle='Новый фильтр'
                    captionSuccessfulButton='Сохранить'
                    startName={null}
                    onRename={save}
                    onClose={closeConfirm}
                />,
                closeFunc: closeConfirm
            });
        }
    }, [advancedFilter, idModal, selector.filter.getFilter()?.savedFilter?.id, synchroiser.loadingState]);

    const saveAsNew = useCallback(async (filterName: string | null) => {
        const newFilter = new SavedFilter(advancedFilter.entityName!);
        const { id, ...advancedFilterCopy } = advancedFilter;
        newFilter.deserialize(advancedFilterCopy);
        newFilter.setValue(filterName, 'filterName');

        newFilter?.save().then(async () => {
            if (synchroiser.loadingState != LoadingState.Error) {
                await synchroiser.getSavedFilterFolderTree();
                advancedFilter.cancel();
            }
        })

    }, [advancedFilter, synchroiser.loadingState])

    const reName = useCallback(async (filterName: string | null) => {
        if (filterName) {
            advancedFilter?.setValue(filterName, 'filterName');
        }
    }, [advancedFilter]);

    const deleteFilter = useCallback(async () => {
        if (!isNewFilter) {
            await synchroiser.deleteFilter(advancedFilter.id!).then(() => {
                if (synchroiser.loadingState != LoadingState.Error) {
                    AdvancedFilterConst.setOpenAdvancedFilterTree(!AdvancedFilterConst.isOpenAdvancedFilterTree, true);
                }
            })
        }
    }, [advancedFilter, synchroiser.loadingState]);

    const addRenamePopup = useCallback(() => {
        modalController.popupAdd({
            id: idModal,
            layout: <RenameLayout
                dialogTitle='Переименовать фильтр'
                startName={advancedFilter.filterName}
                onRename={reName}
                onClose={closeConfirm}
            />,
            closeFunc: closeConfirm
        });
    }, [idModal, advancedFilter]);

    const addRestorePopup = useCallback(() => {
        modalController.popupAdd({
            id: idModal,
            layout: <RestoreLayout
                restore={advancedFilter.cancel}
                closeConfirm={closeConfirm}
            />,
            closeFunc: closeConfirm
        });
    }, [idModal, advancedFilter]);

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.advancedFilter}>
            <div className={styles.advancedFilterHeader}>
                <Button
                    firstIcon={<GoBack />}
                    style={ButtonStyle.Link}
                    onClick={handleBack}
                    className={styles.advancedFilterRenameGoBackButton}
                />

                <div className={styles.advancedFilterHeaderDivider} />
                <span className={styles.advancedFilterName}>
                    {advancedFilter?.filterName ?? 'Новый фильтр'}
                </span>

                <div className={styles.renameAction}>
                    <span className={styles.renameTooltip}>Переименовать</span>
                    <Rename
                        className={styles.advancedFilterRenameButton}
                        onClick={addRenamePopup}
                    />
                </div>

                <div className={styles.filterChangedStateAction}>
                    <div className={styles.restoreAction}>
                        <span className={styles.restoreAndSaveTooltip}>Восстановить</span>
                        <Button
                            firstIcon={<RestoreIcon />}
                            style={ButtonStyle.IconIndigo}
                            className={styles.restoreFilterButton}
                            isVisible={advancedFilter.hasChanges && advancedFilter.id !== null}
                            onClick={addRestorePopup}
                        />
                    </div>
                    <div className={styles.saveAction}>
                        <span className={styles.restoreAndSaveTooltip} style={{ left: "-23px" }}>Сохранить</span>
                        <Button
                            firstIcon={<SaveIconAsFloppyDisk />}
                            style={ButtonStyle.IconIndigo}
                            className={styles.advancedFilterSaveButton}
                            onClick={saveOrUpdate}
                            isVisible={advancedFilter.hasChanges}
                        />
                    </div>

                </div>
            </div>
            <div className={styles.advancedFilterActions}>
                <Button
                    caption={'Применить'}
                    style={ButtonStyle.Primary}
                    onClick={applyFilter}
                    className={styles.advancedFilterActionsButton}
                    isDisabled={
                        advancedFilter.filterInfo?.filters === undefined ||
                        advancedFilter.filterInfo?.filters.length < 1
                    }
                />
                <Button
                    caption={'Добавить условие'}
                    style={ButtonStyle.IconIndigo}
                    onClick={addCondition}
                    className={styles.filterConditionsButton}
                    selected={true}
                />
                <Button
                    caption={isClickCleaning ? 'Eще раз' : 'Очистить'}
                    style={ButtonStyle.Subtle}
                    onClick={clean}
                    className={cleanButtonClassName}
                    isVisible={advancedFilter.filterInfo?.filters !== undefined && advancedFilter.filterInfo?.filters.length > 0}
                    onMouseLeave={handleMouseLeaveFromCleanButton}
                />
                <Select
                    items={filterActions}
                    onChangeValue={handleChangeValue}
                    styles={ButtonStyle.IconIndigo}
                    firstIcon={<MoreEllipsis />}
                    className={styles.selectFilter}
                    classNameButton={styles.selectFilterButton}
                    isPositionLeft={true}
                />
            </div>
            <FilterConditions filter={advancedFilter.filterInfo!} />
        </div>
    );
});

export default AdvancedFilterElement;
