import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { Button } from "components";

import { Item } from "types";
import { ISelectProps } from "components/select/types";

import styles from "./select-sections.module.css";


interface IItemSelectSections extends Item {
    target?: React.HTMLAttributeAnchorTarget;
}

interface ISelectSectionsProps extends ISelectProps {
    items: IItemSelectSections[];
}

function SelectSections(props: ISelectSectionsProps) {
    const [isOpened, setOpened] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const inputElement = useRef<HTMLDivElement>(null);

    const containerClasses = classNames(`${styles.wrapper} `, {
        [`${styles.wrapper} ${props.className}`]: props.className,
    });
    const buttonClasses = classNames(`${styles.button} `, {
        [`${props.classNameButton}`]: props.classNameButton,
        [`${props.classNameOpenList}`]: props.classNameOpenList && isOpened,
    });
    const firstIconClasses = classNames(`${styles.iconButton} `, {
        [`${props.classNameIcon}`]: props.classNameIcon,
        [`${styles.close}`]: props.isRotateFirstIcon && isOpened,
    });
    const listClasses = classNames(`${styles.list} `, {
        [`${props.classNameList}`]: props.classNameList,
        [`${styles.visible}`]: isOpened,
    });

    function hideMenu() {
        setOpened(false);
        document.removeEventListener("click", handleClick);
    }

    function openMenu() {
        if (!isOpened) {
            setOpened(true);
            document.addEventListener("click", handleClick);
        }
        else {
            setOpened(false);
        }
    }

    function handleClick(event: Event) {
        if (wrapperRef.current != null && !wrapperRef.current.contains(event.target as Node)) {
            hideMenu();
        }
    }

    const setPositionsContextMenu = (element: HTMLUListElement) => {
        if (element && wrapperRef.current !== null) {
            const position = element.getBoundingClientRect();

            let winh = window.innerHeight;
            let raz = (winh - position.bottom) - 15;
            if (position.bottom > winh) {
                element.style.transform = 'translate(' + wrapperRef.current!.children?.item(0)!.getBoundingClientRect().width + 'px, ' + raz + 'px)';
            }
            if (position.right > window.innerWidth) {
                element.style.left = (-position.width + wrapperRef.current?.getBoundingClientRect().width!) + "px";
            }
            if (inputElement.current) {
                const wrapperPosition = inputElement.current.getBoundingClientRect();
                element.style.minWidth = (wrapperPosition.width - 20) + "px";
            }
        }
    };

    function selectItem(item: Item | null) {
        if (!item?.isDisabled && !item?.isLocked) {
            props.onChangeValue(item);
            hideMenu();
        }

    }

    function listItem(item: Item) {
        return (
            <>
                {item.icon &&
                    <div className={styles.icon}>
                        {item.icon}
                    </div>
                }
                <div className={styles.listItemCaption} >
                    <div className={styles.listItemModule} >
                        <span className={styles.listItemName} >{item.title ? item.title : item.displayValue ? item.displayValue : item.name}</span>
                        {item.option && <span className={styles.sectionOption}>
                            {item.option.toUpperCase()}
                        </span>
                        }
                    </div>
                    <span className={styles.listItemNote} >{item.note}</span>
                </div>
            </>
        );
    }

    return (
        <div ref={wrapperRef} className={containerClasses}>
            <Button
                classNameFirstIcon={firstIconClasses}
                className={buttonClasses}
                firstIcon={props.firstIcon}
                caption={props.value === null || props.value === undefined ? props.name : props.value.name}
                onClick={openMenu}
                style={props.styles}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                onMouseEnter={() => { }}
                onMouseLeave={() => { }}
                selected={isOpened}
            />
            <ul className={listClasses} ref={setPositionsContextMenu}>
                {props.items !== undefined &&
                    props.items.map((item, i) => {
                        const itemClasses = classNames(`${styles.listItem} `, {
                            [`${styles.listItemDisabled}`]: item.isDisabled || item.isLocked,
                            [`${styles.exit}`]: item.isRed,
                            [`${item.classNames}`]: item.classNames,
                            [`${styles.fav}`]: item.tag,
                        });
                        if (!item.tag)
                            return (
                                <div key={item.id}>
                                    {(item.entityName || item.path) ?
                                        <Link to={item.entityName ? item!.entityName.toLowerCase() : item!.path!.toLowerCase()} target={item.target} onClick={hideMenu}
                                            className={itemClasses}>
                                            {listItem(item)}
                                        </Link>
                                        :
                                        <li key={i} className={itemClasses} onClick={() => { selectItem(item); }}>
                                            {listItem(item)}
                                        </li>

                                    }
                                    {item.isSeparator &&
                                        <div className={styles.separator}></div>
                                    }
                                </div>
                            );
                    })
                }
            </ul>
        </div>
    );
}



export default SelectSections;