import { ColumnType } from "entities/ColumnType";
import { ILookup } from "entities/Entity";
import { EntityColumnSpecialization } from "types/entity";

export enum ConfigurationStep {
  InitialLookupType = 0,
  MainSettings = 1,
}


export enum NewLookupTabs{
  LookupTab = 0,
  FieldSettingsTab =1
}
export interface VirtualLookupValue {
  name: string;
}

export interface EntityInfoItem{
 columnId:string,
 columnName:string,
 columnTitle:string,
 columnType:ColumnType,
 isLookup :boolean,
 isLink:boolean,
 lookupTable?:string| null,
 isRequired:boolean,
 hasIndex:boolean,
 specializations?: EntityColumnSpecialization | null
}
export interface VirtualLookup{
  entityTitle:string,
  systemName:string,
  isLookup:boolean,
  columnInfo:EntityInfoItem[],
  virtualLookupValues:ILookup[]
}
