import { observer } from "mobx-react-lite";
import { useState, useEffect, useCallback } from "react";

import { store } from "store";
import { synchroiser } from "synchroiser";

import SectionHead from "features/section-head/section-head";
import Field from "components/field/field";
import Grid from "components/grid/grid";
import Select from "components/select/select";
import Input from "components/input/input";
import SearchSelect from "components/select/search-select/search-select";
import { InputStyleName } from "components/input/input-styles";
import { SelectStyleName } from "components/select/select-styles";

import { Account, default as accountEntity } from "entities/account/Account";
import FilterColumnType from "entities/ColumnType";
import { validateRequired } from "entities/Validation";
import SectionRoute from "entities/SectionRoute";
import { accountStore } from "entities/account/AccountsSectionStore";
import Entity, { IEntityStore } from "entities/Entity";
import { Item } from "types";


const Accounts = () => {
	const [entity, setEntity] = useState<Entity<IEntityStore>>();

	useEffect(() => {
		setEntity(new Entity<Account>(new Account()));
	}, []);

	useEffect(() => {
		synchroiser.switchSection("account");
	}, [store.sections]);

	return (
		entity ?
			<SectionHead
				pageTitle={"Контрагенты"}
				grid={<Grid columns={columns} listStore={accountStore} entity={accountEntity} />}
				entity={entity}
				dialogTitle={"контрагент"}
				dialogContent={<AccountDialogFilling entity={entity} />}
				listStore={accountStore}
				columns={columns}
				new={() => { entity.new(new Account()); }}
			/>
			:
			<></>
	);
};

export const AccountDialogFilling = observer((props: { entity: Entity<IEntityStore> }) => {

	const entity = props.entity as Entity<Account>;
	const handleChange = useCallback((value: any) => {
		entity.entity.setValue(value, "owner");
	}, []);

	return (
		<>
			{entity.isNew &&
                <>
                	<Field name={"name"} caption={"Название"} required={true} >
                		<Input
                			value={entity.entity.name}
                			placeholder={""}
                			onChangeValue={(value: string) => {
                				entity.entity.setValue(value, "name");
                			}}
                			inputStyle={InputStyleName.Base}
                			isInvalid={entity.entity.validation.name.isInvalid}
                			invalidMessage={entity.entity.validation.name.error}
                			onFocusOut={() => validateRequired(entity.entity.name, entity.entity.validation.name)}
                		/>
                	</Field>

                	<Field name={"site"} caption={"Сайт"} required={true} >
                		<Input
                			value={entity.entity.site}
                			placeholder={""}
                			onChangeValue={(value: string) => {
                				entity.entity.setValue(value, "site");
                			}}
                			inputStyle={InputStyleName.Base}
                			isInvalid={entity.entity.validation.site.isInvalid}
                			invalidMessage={entity.entity.validation.site.error}
                			onFocusOut={() => validateRequired(entity.entity.site, entity.entity.validation.site)}
                		/>
                	</Field>

                	<Field name={"owner"} caption={"Ответственный"} required={false}>
                		<SearchSelect
                			items={entity.lookups["User"]}
                			isInput={true}
                			onItemsLoad={async (s) => {
                				return await entity.loadLookups("User", s, null, false);
                			}}
                			inputStyle={InputStyleName.BaseWithoutBorder}
                			selectStyle={SelectStyleName.Base}
                			value={entity.entity.owner as Item}
                			onChangeValue={handleChange}
                		/>
                	</Field>

                	<Field name={"category"} caption={"Категория"} required={true} key={"categoty"} >
                		<SearchSelect
                			items={entity.lookups["AccountCategory"]}
                			isInput={true}
                			onItemsLoad={async (s) => {
                				return await entity.loadLookups("AccountCategory", s, null, true);
                			}}
                			value={entity.entity.category as Item}
                			inputStyle={InputStyleName.BaseWithoutBorder}
                			selectStyle={SelectStyleName.Base}
                			isInvalid={entity.entity.validation.category.isInvalid}
                			invalidMessage={entity.entity.validation.category.error}
                			onChangeValue={(value: any) => {
                				entity.entity.setValue(value, "category");
                			}}
                			onFocusOut={() => validateRequired(entity.entity.category, entity.entity.validation.category, true)}
                		/>

                	</Field>

                </>
			}
		</>
	);
});

export const AccountDialogLeads = observer((props: { entity: Entity<Account> }) => {
	return (
		<>
			<Field name={"name"} caption={"Название"} required={true}>
				<Input
					value={props.entity.entity.name}
					placeholder={""}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "name");
					}}
					isInvalid={props.entity.entity.validation.name.isInvalid}
					isNeedCorrect={true}
					onFocusOut={() => validateRequired(props.entity.entity.name, props.entity.entity.validation.name)}
				/>
			</Field>

			<Field name={"site"} caption={"Сайт"} required={true}>
				<Input
					value={props.entity.entity.site}
					placeholder={""}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "site");

					}}
					isInvalid={props.entity.entity.validation.site.isInvalid}
					isNeedCorrect={true}
					onFocusOut={() => validateRequired(props.entity.entity.site, props.entity.entity.validation.site)}
				/>
			</Field>

			<Field name={"category"} caption={"Категория"} required={true} key={"categoty"} >
				<Select
					items={props.entity.lookups["AccountCategory"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "category");
					}}
					isInput={true}
					onItemsLoad={async () => {
						return await props.entity.loadLookups("AccountCategory", undefined, null, true);
					}}
					value={props.entity.entity.category}
					isInvalid={props.entity.entity.validation.category.isInvalid}
					isNeedCorrect={true}
					onFocusOut={() => validateRequired(props.entity.entity.category, props.entity.entity.validation.category)}

				/>
			</Field>

			<Field name={"owner"} caption={"Ответственный"} required={false}>
				<SearchSelect
					items={props.entity.lookups["User"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "owner");
					}}
					isInput={true}
					onItemsLoad={async (s) => { return await props.entity.loadLookups("User", s); }}
					value={props.entity.entity.owner}
					selectStyle={SelectStyleName.BaseWithoutBorder}
				/>
			</Field>
		</>
	);
});


const columns = [
	{
		name: "name",
		caption: "Название",
		width: 23,
		type: FilterColumnType.String,
		getLink: (id: string) => `${id}`,
		sortColumn: "name"
	},
	{
		name: "type",
		caption: "Тип",
		width: 15,
		type: FilterColumnType.Lookup,
		sortColumn: "type.name"
	},
	{
		name: "site",
		caption: "Web",
		width: 15,
		type: FilterColumnType.String,
		getLink: () => "",
		sortColumn: "site"
	},
	{
		name: "category",
		caption: "Категория",
		width: 8,
		type: FilterColumnType.Lookup,
		sortColumn: "category.order"
	},
	{
		name: "owner",
		caption: "Ответственный",
		width: 28,
		type: FilterColumnType.Owner,
		getLink: (id: string) => `/${SectionRoute.User}/${id}`,
		sortColumn: "owner.name"
	},
	{
		name: "createdOn",
		caption: "Дата создания",
		width: 11,
		type: FilterColumnType.Date,
		sortColumn: "createdOn"
	},

];

export default Accounts;
