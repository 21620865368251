import { observer } from "mobx-react";

import { CloseMaxi } from "assets/icons";

import styles from "./modificate-section.module.css";

interface SectionProps {
    title: string,
    closeAction: () => void,
    children?: JSX.Element[]
}

export const ModificateSection = observer((props: SectionProps) => {

    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.sectionHeader}>
                <span >{props.title}</span>
                <CloseMaxi className={styles.close} onClick={props.closeAction} />
            </div>
            {props.children}
        </div>
    );
});
