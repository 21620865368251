import { IFilter } from "./filter/IFilter";
// import api from "../app/services/api";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import ISort from "./ISort";
import { IEntityStore } from "./Entity";
import { api } from "api";

export enum LoadingState {
  NotAsked,
  Loading,
  Loaded,
  Failed,
  LoadingMore,
}

export interface CanbanColumn {
  columnName: string;
  columnValue: string;
}

const DEFAULT_LIMIT = 30;
const DEFAULT_SORT = {
	columnPath: "createdOn",
	direction: 1,
};

export default class ListStore {
	schema: string;
	lastId: number | null;
	data: any[];
	count: number;
	loadingState: LoadingState;
	error?: string;
	filter: IFilter | null = null;
	staticGroupId: string | null = null;

	isCheckedAll: boolean;
	includedIds: IEntityStore[];
	excludedIds: IEntityStore[];
	countOfChecked: number;

	constructor(schema: string) {
		makeObservable(this, {
			data: observable,
			count: observable,
			loadingState: observable,
			error: observable,
			filter: observable,
			staticGroupId: observable,
			isCheckedAll: observable,
			includedIds: observable,
			excludedIds: observable,
			countOfChecked: observable,
			isLoading: computed,
			isLoaded: computed,
			isError: computed,
			setValue: action,
			load: action,
			loadMore: action,
		});
		this.schema = schema;
		//  this.sort = null;
		this.data = [];
		this.count = 0;
		this.loadingState = LoadingState.NotAsked;
		this.error = undefined;
		this.lastId = null;
		this.isCheckedAll = false;
		this.includedIds = [];
		this.excludedIds = [];
		this.countOfChecked = 0;
	}

	// @computed
	get isLoading(): boolean {
		return this.loadingState === LoadingState.Loading;
	}

	//@computed
	get isLoaded(): boolean {
		return this.loadingState === LoadingState.Loaded;
	}

	//@computed
	get isError(): boolean {
		return this.loadingState === LoadingState.Failed;
	}

	setValue(value: any, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}
	/*
    async setCount() {
        try {
            const param: any = {
                entityName: this.schema,
            };
            let url = `/api/Entity/entitycount`;
            const data = await api.post(url, param).then(res => res.data);
            if (data !== null) {
                this.count = data;

            } else {
                console.log("Something went wrong :(");
                console.log(data.message);
            }

        } catch (error: any) {
            var message = error.response.message;
            console.error("An error occurred:", error);
        } finally {
        }
    }
    */

	//метод удаляет таблицу из БД
	async deleteTable() {
		let response: any;
		let info: any = {
			entityName: this.schema,
		};
		// response = await api.post("/api/Entity/deleteInfo", info);
		response =  await api.http.httpApi.entity.deleteInfo().post(info);
	}

	async load(
		filter: IFilter | null,
		staticGroupId?: string | null,
		limit?: number,
		sort?: ISort | null,
		canbanColumn?: CanbanColumn
	) {
		this.loadingState = LoadingState.Loading;
		this.setValue(false, "isCheckedAll");
		this.setValue([], "excludedIds");
		this.setValue([], "includedIds");
		this.setValue(0, "countOfChecked");

		limit = limit ?? DEFAULT_LIMIT;
		sort = sort ?? DEFAULT_SORT;

		this.filter = filter;
		this.staticGroupId = staticGroupId ?? null;
		try {
			const data: any = {
				canbanColumn: canbanColumn ?? null,
				filter: filter,
				offset: 0,
				limit: limit,
				staticGroupId: staticGroupId ?? null,
				entityName: this.schema,
				sort: sort,
			};
			// const response = await api.post("/api/Entity/list", data);
			const response = await api.http.httpApi.entity.recordsList().post(data);
			const countRequest = { entityName: this.schema, filter: filter };
			// const countResponse = await api.post("/api/entity/entitycount", countRequest);
			const countResponse = await api.http.httpApi.entity.entityCount().post(countRequest);
			runInAction(() => {
				if (response.data.success === true && countResponse.data.success === true) {
					this.loadingState = LoadingState.Loaded;
					this.data = response.data.data;
					this.count = countResponse.data.data;
					if (this.data != null && this.data.length > 0) {
						this.lastId = this.data[this.data.length - 1].id;
					}
				} else {
					this.loadingState = LoadingState.Failed;
					this.error = response.data.error;
					this.count = 0;
				}
			});
		} catch (e) {
			this.loadingState = LoadingState.Failed;
			this.error = "Что-то пошло не так";
			this.setValue(0, "count");
		}
	}

	async loadMore(
		filter: IFilter | null,
		limit: number,
		sort?: ISort | null,
		canbanColumn?: CanbanColumn,
		offset?: number
	) {
		this.loadingState = LoadingState.LoadingMore;
		limit = limit ?? DEFAULT_LIMIT;
		filter = filter ?? this.filter;
		try {
			const data: any = {
				canbanColumn: canbanColumn ?? null,
				filter: filter,
				offset: 0,
				limit: limit,
				entityName: this.schema,
				sort: sort,
				staticGroupId: this.staticGroupId,
			};
			// if (this.sort) {
			//     data.sort = {
			//         columnPath: getColumnSortPath(this.sort.column),
			//         direction: this.sort.direction
			//     };
			data.offset = offset ?? this.data?.length;
			// }
			// else{
			//     data.lastId = this.lastId;
			//     data.offset = this.data?.length;
			// }
			if (true /*filter != null*/) {
				// const response = await api.post("/api/Entity/list", data);
				const response = await api.http.httpApi.entity.recordsList().post(data);
				runInAction(() => {
					if (response.data.success === true) {
						this.loadingState = LoadingState.Loaded;
						if (response.data && response.data.length > 0) {
							let currData = this.data;
							let responseData = response.data.data;
							this.data = currData?.concat(responseData) as any;
							if (this.isCheckedAll) this.countOfChecked = this.count;
						}
						if (this.data != null && this.data.length > 0) {
							this.lastId = this.data[this.data.length - 1].id;
						}
					} else {
						this.loadingState = LoadingState.Failed;
						this.error = response.data.error;
						this.count = 0;
					}
				});
			}
		} catch (e) {
			this.loadingState = LoadingState.Failed;
			this.error = "Что-то пошло не так";
		}
	}

	onChangeChecked = (check: boolean, entity: any) => {
		const result: IEntityStore[] = [];
		const list = this.isCheckedAll ? this.excludedIds : this.includedIds;

		if (list.length > 0) {
			list.forEach((item) => {
				if (item !== entity && item.id !== entity.id) result.push(item);
			});
		}
		if (check && !this.isCheckedAll) {
			result.push(entity);
		}
		if (!check && this.isCheckedAll) {
			result.push(entity);
		}

		this.setValue(result, this.isCheckedAll ? "excludedIds" : "includedIds");
		this.setValue(
			!check && this.isCheckedAll ? this.data.length - result.length : result.length,
			"countOfChecked"
		);
	};

	onChangeCheckedAll = (check: boolean) => {
		this.setValue(check, "isCheckedAll");
		this.setValue([], "excludedIds");
		this.setValue([], "includedIds");
		if (check) this.setValue(this.count, "countOfChecked");
		else this.setValue(0, "countOfChecked");
	};
}
