import { ChangeDisplay, Export, Import, SectionWizzard } from "assets/icons";
import { observable } from "mobx";
import { IFormat } from "entities/IItem";
import SavedFilterFolder from "entities/filter/SavedFilterFolder";
import SavedFilter from "entities/filter/SavedFilter";
import StaticGroupFolder from "entities/filter/StaticGroupFolder";


export interface IItemState {
    data: any,
    checked: boolean,
    avatar?: JSX.Element
}

export interface IUniteData {
    data: IFormat[],
    columnName: string,
}


export interface GetMaxWidth {
    maxwidth: number;
    setMaxWidth: (value: number) => void;
}
export const MaxWidth = observable<GetMaxWidth>({
    maxwidth: 0,
    setMaxWidth: (value: number) => { MaxWidth.maxwidth = value }
});

export interface FilterTree {
    savedFilterTree: SavedFilterFolder | null;
    staticGroupFolderTree: StaticGroupFolder | null;

}

interface OpenAdvancedFilter {
    isOpenAdvancedFilterTree: boolean;
    isOpenAdvancedFilter: boolean;
    filterTree: FilterTree | null;
    filter: SavedFilter | null;
    setAdvancedFilter: (value: SavedFilter) => void;
    setOpenAdvancedFilterTree: (value: boolean, openFilterTree?: boolean) => void;
    setAdvancedFilterTree: (value: FilterTree) => void;
}

export const AdvancedFilterConst = observable<OpenAdvancedFilter>({
    isOpenAdvancedFilterTree: false,
    isOpenAdvancedFilter: false,
    filterTree: null,
    filter: null,
    setOpenAdvancedFilterTree: (value: boolean, openFilterTree?: boolean) => {
        if (openFilterTree) {
            AdvancedFilterConst.isOpenAdvancedFilterTree = value;
            if (AdvancedFilterConst.isOpenAdvancedFilter) {
                AdvancedFilterConst.isOpenAdvancedFilter = false;
            }
        }
        else {
            if (AdvancedFilterConst.isOpenAdvancedFilterTree) {
                AdvancedFilterConst.isOpenAdvancedFilterTree = false;
            }
            AdvancedFilterConst.isOpenAdvancedFilter = value;
        }
    },
    setAdvancedFilterTree: (value: FilterTree) => { AdvancedFilterConst.filterTree = value },
    setAdvancedFilter: (value: SavedFilter) => { AdvancedFilterConst.filter = value },
});

export enum ActionsIds {
    "Unite" = 1,
    "Export" = 2,
    "ChangeDisplay" = 3,
    "Import" = 4,
    "AddToGroup" = 5,
    "AddTag" = 6,
    "Delete" = 7,
    "ExportAll" = 8,
    "SectionWizzard"= 9,
};

export const Actions = [
    {
        id: ActionsIds.ExportAll,
        name: "Экспортировать все",
        icon: <Export />,
    },
    {
        id: ActionsIds.ChangeDisplay,
        name: "Изменить отображение",
        icon: <ChangeDisplay />
    },
    {
        id: ActionsIds.Import,
        name: "Импортировать",
        icon: <Import />,
        isSeparator:true
    },
    {
        id: ActionsIds.SectionWizzard,
        name: "Редактировать раздел",
        icon: <SectionWizzard />
    },
];
export let Formats: IFormat[] = [
    {
        name: '.xlsx',
        checked: true
    },
    {
        name: '.csv',
        checked: false
    }
];
