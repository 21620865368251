import { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { autorun } from 'mobx'

import fieldConfigurationStore from './field-configuration-store'

import { Button, ButtonStyle } from 'components'
import FieldConfiguration from './field-configuration'

import { ConfigurationStep } from './types'
import ChoiceLookupType from './choice-lookup-type/choice-lookup-type'
import { ColumnType } from 'entities/ColumnType'

import { CloseMaxi } from 'assets/icons'

import styles from './field-configuration.module.css'

interface FieldConfigurationWrapperProps {
	columnId?: string
	fieldType?: ColumnType
	close: () => void
	closeAll: () => void
	position: {
		cellX: number
		cellY: number
	}
	targetZone?: string | null
}

export const FieldConfigurationWrapper = observer(
	(props: FieldConfigurationWrapperProps) => {
		const [isConfigurationValid, setIsConfigurationValid] = useState(false)
		const [step, setStep] = useState(ConfigurationStep.InitialLookupType)

		useEffect(() => {
			if (props.fieldType !== ColumnType.Lookup) {
				setStep(ConfigurationStep.MainSettings)
			}
		}, [props.fieldType])

		useEffect(() => {
			fieldConfigurationStore.setValueWithoutTrackingChanges(
				'x',
				props.position.cellX,
			)
			fieldConfigurationStore.setValueWithoutTrackingChanges(
				'y',
				props.position.cellY,
			)
		}, [props.position.cellX, props.position.cellY])

		useEffect(() => {
			const validateConfiguration = () => {
				const isValid = fieldConfigurationStore.isConfigurationValid()
				setIsConfigurationValid(isValid)
			}
			validateConfiguration()
			const disposer = autorun(() => {
				validateConfiguration()
			})
			return () => {
				disposer()
			}
		}, [fieldConfigurationStore])

		const handleStepChange = useCallback(() => {
			setStep(ConfigurationStep.MainSettings)
		}, [])

		const handleClose = useCallback(() => {
			if (fieldConfigurationStore.hasChanges) {
				props.close()
			} else {
				props.closeAll()
			}
		}, [props.close])

		const handleSave = useCallback(() => {
			// TODO кейс на пустой props
			fieldConfigurationStore.saveConfiguration({
				targetZone: props.targetZone,
				columnId: props.columnId ?? '',
			})
			props.closeAll()
		}, [props])

		const dialogFooterButtons = useMemo(() => {
			if (props.columnId) {
				setStep(ConfigurationStep.MainSettings)
			}

			return (
				<>
					<Button
						key='cancelButton'
						caption='Отменить'
						onClick={handleClose}
						style={ButtonStyle.Subtle}
					/>
					{step === ConfigurationStep.InitialLookupType && (
						<Button
							key='continueButton'
							caption='Продолжить'
							onClick={handleStepChange}
							style={ButtonStyle.Primary}
							isDisabled={false}
						/>
					)}
					{step === ConfigurationStep.MainSettings && (
						<Button
							key='saveButton'
							caption='Сохранить'
							onClick={handleSave}
							style={ButtonStyle.Success}
							isDisabled={!isConfigurationValid}
						/>
					)}
				</>
			)
		}, [handleClose, step, handleStepChange, handleSave, isConfigurationValid])

		const dialogContent = useMemo(() => {
			if (props.columnId) {
				return <FieldConfiguration columnId={props.columnId} />
			}
			if (step === ConfigurationStep.InitialLookupType) {
				return props.fieldType === ColumnType.Lookup ? (
					<ChoiceLookupType fieldType={props.fieldType} />
				) : (
					<FieldConfiguration fieldType={props.fieldType} />
				)
			} else if (step === ConfigurationStep.MainSettings) {
				return <FieldConfiguration fieldType={props.fieldType} />
			}

			return null
		}, [step, props.fieldType, props.columnId]) 
		

		return (
			<>
				{fieldConfigurationStore.fieldIsLoading && (
					<div className={styles.modalOverlay}>
						<svg className={styles.circleLoader} viewBox='0 0 50 50'>
							<circle
								className={styles.circle}
								cx='25'
								cy='25'
								r='20'
								fill='none'
								stroke={`var(--color-gray-300)`}
								strokeWidth='3'
							/>
						</svg>
					</div>
				)}
				<div className={styles.headerModal}>
					<span className={styles.titleModal}>Настройки нового поля</span>
					<CloseMaxi className={styles.closeButton} onClick={handleClose} />
				</div>
				<div className={styles.dialogBody}>
					{dialogContent}
					<div className={styles.dialogFooter}>{dialogFooterButtons}</div>
				</div>
			</>
		)
	},
)
