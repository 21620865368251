import { observable } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
// import api from "app/services/api";

import { Field, Button, LoadingCircle, Input } from 'components'

import { InfoIcon, LogoSalebridge } from 'assets/icons'
import userEntity from 'entities/user/User'
import authStore from 'AuthStore'

import styles from './change-password.module.css'
import { synchroiser } from 'synchroiser'
import { api } from 'api'

enum LoadingState {
	None,
	Loading,
	Loaded,
}

interface INewPasswordForm {
	password: string
	passwordConfirm: string
	errorMessage: string | ''
	isLoadState: LoadingState
	passwordInvalid: boolean
	passwordConfirmInvalid: boolean
	passwordIsNeedCorrect: boolean
	passwordConfirmIsNeedCorrect: boolean
	validatePassword: () => void
	savePassword: () => void
	keyDown: (event: any) => void
}

const NewPasswordForm = observable<INewPasswordForm>({
	password: '',
	passwordConfirm: '',
	errorMessage: '',
	isLoadState: LoadingState.Loaded,
	passwordInvalid: false,
	passwordConfirmInvalid: false,
	passwordIsNeedCorrect: false,
	passwordConfirmIsNeedCorrect: false,

	keyDown: (event: any) => {
		if (event.code === 'Enter') {
			NewPasswordForm.savePassword()
		}
	},
	validatePassword() {
		let isValid = true

		isValid = isValid && validatePasswordFormat()
		isValid = isValid && validatePasswordNotEmpty()
		isValid = isValid && validatePasswordMatch()

		if (isValid) {
			resetValidationState()
		}
	},

	savePassword: async () => {
		if (NewPasswordForm.passwordInvalid) return
		try {
			NewPasswordForm.passwordConfirmIsNeedCorrect = true
			NewPasswordForm.passwordIsNeedCorrect = true
			NewPasswordForm.isLoadState = LoadingState.Loading
			NewPasswordForm.errorMessage = ''

			let requestData = {
				password: NewPasswordForm.password,
				name: userEntity.entity.email,
			}
			const result = await axios.post('/api/User/authenticate', requestData)

			await synchroiser.auth(userEntity.entity.email, NewPasswordForm.password)

			const data = result.data
			if (data.success === true) {
				const token = data.accessToken
				const expires = data.expires

				document.removeEventListener('keydown', NewPasswordForm.keyDown)
				authStore.logIn(token, expires)
				window.location.href = window.location.hash + '/settings'
			} else {
				console.log('Something went wrong :(')
				console.log(data.message)
			}
		} catch (error: any) {
			handleSavePasswordError(error)
		} finally {
			NewPasswordForm.isLoadState = LoadingState.Loaded
		}
	},
})

// Функция для валидации формата пароля
function validatePasswordFormat() {
	// - ^ - начало строки
	// - (?=.*[a-z]) - должна содержать хотя бы одну строчную букву
	// - (?=.*[A-Z]) - должна содержать хотя бы одну заглавную букву
	// - (?=.*\d) - должна содержать хотя бы одну цифру
	// - (?=.*[@$!%*?&]) - должна содержать хотя бы один из следующих символов: @ $ ! % * ? & # ^ _ / + - . , ` ~ ( ) : ; < >
	// - [A-Za-z\d@$!%*?&]{8,} - должна состоять только из латинских букв (строчных и заглавных), цифр и специальных символов @ $ ! % * ? & # ^ _ / + - . , ` ~ ( ) : ; < > и иметь длину не менее 6 символов
	// - $ - конец строки
	const validPasswordRegex =
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^_/+\-.,'`~():;<>\s])[A-Za-z\d@$!%*?&#^_/+\-.,'`~():;<>\s]{6,}$/

	let valid = validPasswordRegex.test(NewPasswordForm.password)
	if (!valid) {
		setInvalidPasswordState('Пароль не соответствует требованиям')
	}
	return valid
}

// Функция для валидации непустого пароля
function validatePasswordNotEmpty() {
	if (NewPasswordForm.password.length === 0) {
		setInvalidPasswordState('Не заполнено обязательное поле')
		return false
	}
	return true
}

// Функция для валидации совпадения паролей
function validatePasswordMatch() {
	if (NewPasswordForm.password !== NewPasswordForm.passwordConfirm) {
		NewPasswordForm.passwordConfirm = NewPasswordForm.passwordConfirm
		setInvalidPasswordState('Пароли не совпадают')
		return false
	}
	return true
}

// Функция для установки состояния невалидного пароля
function setInvalidPasswordState(errorMessage: string) {
	NewPasswordForm.errorMessage = errorMessage
	NewPasswordForm.passwordInvalid = true
	NewPasswordForm.passwordIsNeedCorrect = false
}

// Функция для сброса состояния валидации пароля
function resetValidationState() {
	NewPasswordForm.errorMessage = ''
	NewPasswordForm.passwordInvalid = false
	NewPasswordForm.passwordConfirmInvalid = false
	NewPasswordForm.passwordConfirmIsNeedCorrect = true
	NewPasswordForm.passwordIsNeedCorrect = true
}

// Функция для обработки ошибок при сохранении пароля
function handleSavePasswordError(error: any) {
	if (error.response && error.response.status === 401) {
		NewPasswordForm.errorMessage = 'Something went wrong :('
	}
}

const ChangePassword = observer((props: { userId: string }) => {
	const userId = props.userId

	const passwordForm = NewPasswordForm
	const navigate = useNavigate()

	useEffect(() => {
		getUser()
		document.removeEventListener('keydown', passwordForm.keyDown)
		document.addEventListener('keydown', passwordForm.keyDown)
	}, [])

	async function changePassword() {
		let requestData = {
			userId: userId,
			newPassword: NewPasswordForm.password,
		}

		const result = await axios.post('/api/User/change-password', requestData)
		if (result.data) {
			await passwordForm.savePassword()
		} else {
			navigate('/login')
		}
	}

	const getUser = useCallback(async () => {
		const data = { userId: userId }
		passwordForm.isLoadState = LoadingState.Loading
		try {
			const response = await api.http.httpApi.user.getUser().post(data)
			let loadResult = response.data
			if (loadResult.success) {
				userEntity.entity.deserialize(loadResult.data)
			}
		} catch (error) {
			console.error()
			navigate('/login')
		} finally {
			passwordForm.isLoadState = LoadingState.Loaded
		}
	}, [userId])

	const isLoading = useMemo(
		() => passwordForm.isLoadState === LoadingState.Loading,
		[passwordForm.isLoadState],
	)

	let fieldClassNames = styles.inputField + ' '
	let inputClass = styles.input + ' '

	if (isLoading) {
		fieldClassNames += styles.textLoading
		inputClass += styles.textLoading
	}

	function Promt(props: { texts: string }) {
		const lines = props.texts.split('\\n')
		return (
			<div>
				<div className={styles.promtInfo}>
					<div className={styles.promtInfoContent}>
						<InfoIcon className={styles.promtIcon} />
						{/* <div  className={styles.promtText}>{props.texts}</div > */}

						<div className={styles.promtText}>
							{lines.map((line, index) => (
								<div className={styles.promtText} key={index}>
									{line}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		)
	}

	const handleChangePassword = useCallback(
		(e: string) => {
			passwordForm.password = e
			passwordForm.validatePassword()
		},
		[passwordForm],
	)
	const handleChangePasswordConfirm = useCallback(
		(e: string) => {
			passwordForm.passwordConfirm = e
			passwordForm.validatePassword()
		},
		[passwordForm],
	)

	const handleChangePasswordLogin = useCallback(async () => {
		passwordForm.passwordInvalid = true
		passwordForm.passwordConfirmInvalid = true
		passwordForm.validatePassword()

		let passwordIsValid =
			!NewPasswordForm.passwordInvalid && NewPasswordForm.passwordConfirm
		if (passwordIsValid) {
			await changePassword()
		}
	}, [passwordForm])

	return (
		<>
			{!isLoading && (
				<div className={styles.container}>
					<div className={styles.square}>
						<div className={styles.header}>
							<div className={styles.logoContainer}>
								<LogoSalebridge />
							</div>
							<span className={styles.newUser}>Новый пароль</span>
							<span className={styles.emailUser}>
								{userEntity.entity.email}
							</span>
						</div>

						<Promt texts='Пароль должен содержать строчные, заглавные и латинские буквы, цифры и специальные символы.\nМинимальная длина пароля — 6 символов' />

						<div className={styles.inputPanel}>
							<Field
								caption='Придумайте пароль'
								name={'firstUserPassword'}
								className={fieldClassNames}
							>
								<Input
									className={inputClass}
									id='paassword'
									value={passwordForm.password}
									placeholder={''}
									onChangeValue={handleChangePassword}
									isDisabled={isLoading}
									isNeedCorrect={passwordForm.passwordIsNeedCorrect}
									isInvalid={passwordForm.passwordInvalid}
									isPassword={true}
								/>
							</Field>
							<Field
								caption='Подтвердите пароль'
								name={'secondUserPassword'}
								className={fieldClassNames}
							>
								<Input
									className={inputClass}
									id='passwordConfirm'
									value={passwordForm.passwordConfirm}
									placeholder={''}
									onChangeValue={handleChangePasswordConfirm}
									isDisabled={isLoading}
									isNeedCorrect={passwordForm.passwordConfirmIsNeedCorrect}
									isPassword={true}
									isInvalid={passwordForm.passwordConfirmInvalid}
								/>
							</Field>
						</div>
						<div className={styles.buttonContainer}>
							<Button
								className={styles.loginBtn}
								caption={
									isLoading ? <LoadingCircle /> : 'Сохранить и войти в систему'
								}
								onClick={handleChangePasswordLogin}
							/>
						</div>
						{passwordForm.errorMessage && (
							<ErrorMessage message={passwordForm.errorMessage} />
						)}
					</div>
				</div>
			)}
		</>
	)
})

function ErrorMessage(props: { message: string }) {
	return (
		<div className={styles.ErrorBody}>
			<ul className={styles.titleError}>
				<li className={styles.errorMessage}>{props.message}</li>
			</ul>
		</div>
	)
}

export default ChangePassword
