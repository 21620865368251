import { observer } from "mobx-react-lite";
import styles from "./Currents.module.css";

import { InProgress } from 'assets/icons';
import NavigationTitle from "../NavigationTitle";


const CurrentMainPanel = observer(function () {
    return (
        <div className={styles.systemMainPanel}>
            <NavigationTitle />
            <div className={styles.inProgress}>
                <InProgress className={styles.inProgressSvg} />
            </div >
        </div >
    );
});

export default CurrentMainPanel;