import { useCallback } from "react";
import { observer } from "mobx-react-lite";

import { booleanStringify } from "./lib";

import { InputChecker, InputWrapper } from "components/input-for-section-designer";

export type Props = {
	columnName: string | undefined;
	columnTitle?: string | undefined;
	prompt?: string | undefined;
	isRequired?: boolean | undefined;
	rounding?: string | undefined;
	value: string;
	isDisabled?: boolean;
	onChange: (value: string) => void;
}

export const Boolean = observer(function (props: Props) {
	const handleChange = useCallback((value: boolean) => {
		props.onChange(booleanStringify(value));
	}, [props]);

	return (
		<InputWrapper
			name={props.columnTitle ?? ""}
			caption={props.columnTitle ?? ""}
			required={props.isRequired}
			infoCaption={props.prompt}
		>
			<InputChecker
				startValue={props.value === "true"}
				text={(props.value === "true") ? "Да" : "Нет"}
				onChangeValue={handleChange}
				isDisabled={props.isDisabled}
			/>
		</InputWrapper>
	);
});
