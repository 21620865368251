

type EventHandler<T> = (event: T) => void;

class EventBus<T> {
    private eventHandlers: Map<string, EventHandler<T>[]> = new Map();

    public registerHandler(type: string, handler: EventHandler<T>) {
        if (this.eventHandlers.has(type)) {
            const eventHandlers = this.eventHandlers.get(type);
            eventHandlers!.push(handler);
        }
        else {
            this.eventHandlers.set(type, [handler]);
        }
    }

    public unregisterHandler(type: string, handler: EventHandler<T>) {
        if (this.eventHandlers.has(type)) {
            const eventHandlers = this.eventHandlers.get(type)!;
            const index = eventHandlers.indexOf(handler);
            if (index !== -1) {
                eventHandlers.splice(index, 1);
            }
        }

    }

    public dispatchEvent(type: string, event: T) {
        const eventHandlers = this.eventHandlers.get(type);
        if (eventHandlers && eventHandlers.length > 0) {
            eventHandlers.forEach((handler) => {
                handler(event);
            });
        }
    }
}

const eventBus = new EventBus<any>();

export default eventBus;