import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";

import NavigationTitle from "../NavigationTitle";
import { OrgStructure, UsersTab } from "./tabs/FunctionalRolesTabs";
import TreeView, { UserRoleType } from "features/tree-view/tree-view";
import Tabs from "components/tabs/tabs";
import WarningDialog from "components/dialog/warning-dialog/warning-dialog";
import WarningMessage from "components/dialog/warning-message/warning-message";

import functionalRolesMainPanelStore from "./FunctionalRolesMainPanelStore";
import { rootUserRoles } from "entities/userRole/RootUserRoles";
import UserRole from "entities/userRole/UserRole";
import { userStore } from "entities/user/UsersSectionStore";
import { UserEnums, UsersSettings, usersCaption } from "../data/Fields";

import styles from "./FunctionalRoles.module.css";

const FunctionalRolesMainPanel = observer(function () {
    const [isOpenWarningMessage, setOpenWarningMessage] = useState<boolean>(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [tab, setTab] = useState<JSX.Element>(<UsersTab />);

    const tabs = useMemo(() => {
        return [{
            caption: UsersSettings.filter(e => e.id === UserEnums.OrgStructure)[0].name,
            action: () => { setTab(<OrgStructure />); }
        },
        {
            caption: usersCaption,
            action: () => { setTab(<UsersTab />); }
        }]
    }, [])

    const onClick = useCallback((userRole: UserRole) => {
        if (userRole === null) {
            userStore.load(null);
            functionalRolesMainPanelStore.setValue(userStore.data, "filteredUserStore");
            functionalRolesMainPanelStore.setValue(null, "userRole");
            functionalRolesMainPanelStore.setValue([], "orgRolesStore");
        }
        else {
            functionalRolesMainPanelStore.setValue(userRole, "userRole");
            functionalRolesMainPanelStore.setUsersById(userRole.data!.id);
            functionalRolesMainPanelStore.loadOrgRoles(userRole.data?.id!);
        }
    }, [])
    const onClickDelete = useCallback(async (userRole: UserRole) => {
        let data = await functionalRolesMainPanelStore.getUsersByRoleId(userRole.data!.id)
        if (data.length > 0)
            setOpenWarningMessage(true)
        else setOpenDeleteDialog(true)
    }, [])

    const deleteRole = useCallback(async () => {
        if (functionalRolesMainPanelStore.userRole?.data?.id) {
            let deleted = await rootUserRoles.deleteFolder(functionalRolesMainPanelStore.userRole.data?.id, UserRoleType.FunctionalRole);
            if (deleted) {
                setOpenDeleteDialog(false);
            }
        }
    }, [])
    const deleteBack = useCallback(() => {
        setOpenDeleteDialog(false)
    }, [])
    const warningBack = useCallback(() => {
        setOpenWarningMessage(false)
    }, [])

    useEffect(() => {
        userStore.load(null);
        functionalRolesMainPanelStore.setValue(userStore.data, "filteredUserStore");
        functionalRolesMainPanelStore.setValue(null, "userRole");
    }, [])


    return (
        <div className={styles.systemMainPanelWrapper}>
            <NavigationTitle />
            <div className={styles.mainTitle}>
                {UsersSettings[2].name}
            </div>

            <div className={styles.systemMainPanel}>
                <div className={styles.leftPanel}>
                    <TreeView
                        isOpen={true}
                        type={UserRoleType.FunctionalRole}
                        onClick={onClick}
                        choosingItem={functionalRolesMainPanelStore.userRole}
                        onClickDelete={onClickDelete}
                        isHeader={true}
                        isActionsVisible={true}
                    />
                    <div className={styles.mainDivider} />
                </div>
                <div className={styles.rightPanel}>
                    <Tabs items={tabs} className={styles.tab} />
                    {tab}
                </div>
            </div>
            <WarningDialog
                value={"Роль будет удалена без возможности восстановления."}
                valueReturn={"Отмена"}
                valueDelete={"Удалить"}
                isOpen={isOpenDeleteDialog}
                onBackClick={deleteBack}
                onCancelClick={deleteRole}
            />
            <WarningMessage value={"Вы не можете удалить роль, если у неё есть связанные записи."}
                isOpen={isOpenWarningMessage}
                onBackClick={warningBack}
            />
        </div>
    );
});

export default FunctionalRolesMainPanel;