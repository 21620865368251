import classNames from "classnames";

import { Button } from "components";

import { ITabProps, ITabsProps } from "../types/types";

import styles from "./../additional-block.module.css";



export function Tab(props: ITabProps & React.HTMLAttributes<HTMLDivElement>) {
    return (
        <>{props.children} </>
    );
}

function TabsHeader(props: { tabs: ITabProps[], activeTab: string, onChangeTab: (tab: string) => void }) {

    return (
        <div className={styles.header}>
            <div className={styles.itemBar}>
                {
                    props.tabs.map(
                        (t: ITabProps) => {
                            let buttonClassNames = classNames({
                                [`${styles.headerItem} ` + `${styles.active} `]: (t.name === props.activeTab),
                                [`${styles.headerItem} `]: true
                            });
                            return (
                                <Button
                                    key={t.name}
                                    caption={t.caption}
                                    className={buttonClassNames}
                                    onClick={() => { props.onChangeTab(t.name) }}
                                />
                            );
                        })
                }
            </div>
        </div>
    );
}


function Tabs(props: ITabsProps) {
    const tabs = props.children.map(c => c.props);
    const activeTab = props.children.filter(t => t.props.name === props.activeTab)[0];
    return (
        <div className={styles.tabs}>
            <TabsHeader tabs={tabs} activeTab={props.activeTab} onChangeTab={props.setActiveTab} />
            <div className={styles.activeTab}>
                <div className={styles.line}></div>
                {activeTab}
            </div>
        </div>

    );
}
export default Tabs