import { CSSProperties, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { NavLink, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { toJS } from "mobx";

import { ColumnType } from "entities/ColumnType";
import { SortDirection } from "entities/ISort";

import { dispatcher } from "store";
import { synchroiser } from "synchroiser";

import { CheckBox, ContextMenu } from "components";
import SkeletonGrid from "../skeleton/skeleton-grid";

import { Click, GeneralizedGridProps, GridHeadProps, GridRowProps, IGeneralizedColumn, ValueProps } from "../data/data";
import { ContextMenuElementType, Coordinate } from "types/item";
import { LoadingState } from "types/entity";

import { SortOldToNew } from "assets/icons";

import styles from "./generalized-grid.module.css"

const ContextMenuElement = observer((props: { element: any, handleClick: (element: any) => void }) => {

	return (
		<div className={styles.contextMenuElement} onClick={() => props.handleClick(props.element)}>
			{props.element.caption}
		</div>
	);
});

const GridHead = observer(function (props: GridHeadProps) {

	const headerClassNames = useMemo(() => props.isDetailGrid ? styles.detailTableHeader : styles.tableHeader, [props.isDetailGrid]);

	const handleChange = useCallback((value: boolean) => {
		props.onChangeChecked(value)
	}, [props.onChangeChecked]);

	return (
		<div className={headerClassNames}>
			<CheckBox
				className={styles.checkBoxGrid}
				checked={props.checkedAll}
				onChangeChecked={handleChange}
			/>
			{
				props.columns.map((column: IGeneralizedColumn) => {
					return (
						<div key={column.columnName} id={column.columnName} className={styles.headerCell}
							style={{ width: `calc(${column.spanX! + "%"} - 20px` }}>
							<div className={styles.headerCaption}>{column.columnTitle}</div>
							{<SortOldToNew className={styles.sortIcon} onClick={() => {
								props.onSortClick(column);
							}} />}
						</div>
					);
				})
			}

		</div>
	);
});

export const EmptyGrid = () => {
	const history = useNavigate();
	return (
		<div className={styles.emptyWrapper}>
			<div className={styles.emptyTitle}>Ничего не найдено.</div>
			<div className={styles.emptySubTitle}>
				<span className={styles.emptyLink} onClick={() => { history("new"); }}>Создайте </span>
				запись или проверьте настройки фильтра
			</div>
		</div>
	);
};

const GeneralizedGrid = observer(function (props: GeneralizedGridProps) {
	const defaultLimit = 30;
	const allRecords = 0;
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const gridBodyRef = useRef<HTMLDivElement>(null);
	const [isShownContextMenu, setIsShownContextMenu] = useState(false);
	const contextMenuRef = useRef<HTMLDivElement>(null);

	const gridClassNames = useMemo(() => props.isDetailGrid ? styles.detailGrid : styles.grid, [props.isDetailGrid]);

	const checkedAll = useMemo(() => {
		if (props.entity.entity.excludedIds.length > 0) {
			return false;
		}
		else {
			return props.entity.entity.isCheckedAll;
		}
	}, [toJS(props.entity.entity.excludedIds), props.entity.entity.isCheckedAll]);


	const dataMapping = useMemo(() => (
		props.entity.entity.rows.length > 0 && props.entity.entity.visibleColumns.length > 0 &&
		props.entity.entity.rows.map((row) => {
			return (
				<Row
					key={row["id"]}
					entity={props.entity}
					row={row}
					gridBodyRef={gridBodyRef}
					contextMenuRef={contextMenuRef}
					setIsShownContextMenu={setIsShownContextMenu}
					setPosition={setPosition}
					onChangeCheckedAll={props.onChangeCheckedAll}
					onDoubleRowClick={props.onDoubleRowClick}
				/>
			);
		})
	), [toJS(props.entity.entity.rows), gridBodyRef, toJS(props.contextMenuOptions), toJS(props.entity.entity.visibleColumns)])

	//TODO ленивая подгрузка
	function trackScrolling(e: SyntheticEvent<HTMLDivElement>) {
		// const wrappedElement = e.target as HTMLDivElement;
		// if (Math.round(wrappedElement.scrollHeight - wrappedElement.scrollTop) <= wrappedElement.clientHeight + 1 && listStore!.isLoaded) {

		// 	listStore?.loadMore(listStore.filter, defaultLimit, sort.current);
		// 	console.log("trackScrolling грузим порцию");
		// }
	}

	const onSortClick = useCallback(async (column: IGeneralizedColumn) => {
		if (props.entity.entity.sort.columnPath.split('.')[0] === column.columnName) {
			if (props.entity.entity.sort.direction == SortDirection.Ascending) {
				props.entity.entity.sort.direction = SortDirection.Descending;
			}
			else {
				props.entity.entity.sort.direction = SortDirection.Ascending;
			}
		}
		else {
			props.entity.entity.sort = {
				columnPath: column.columnType === ColumnType.Lookup ? `${column.columnName}.name` : column.columnName,
				direction: SortDirection.Ascending
			}
		}
		dispatcher.entity.set(props.entity);
		await synchroiser.getEntity();
	}, [])

	const contextMenuStyle: CSSProperties = useMemo(() => {
		return {
			top: position.y,
			left: position.x,
			visibility: isShownContextMenu ? 'visible' : 'hidden'
		}
	}, [isShownContextMenu, position])

	const options: ContextMenuElementType[] = useMemo(() => {
		if (props.contextMenuOptions)
			return props.contextMenuOptions.map((element: any) => {
				return {
					element: <ContextMenuElement
						element={element}
						handleClick={() => setIsShownContextMenu(false)}
					/>,
					action: element.action,
					isDivider: element.isDivider
				}
			})
		else return []
	}, [toJS(props.contextMenuOptions)])

	//TODO Skeleton грида (synchroiser.loadingState надо отлавливать чтобы подхватывалось его состояние измененное)
	if (synchroiser.loadingState == LoadingState.Loading)
		return (<SkeletonGrid />);


	//TODO обработка ошибок
	return (
		<div className={gridClassNames} ref={gridBodyRef}>
			<GridHead
				columns={props.entity.entity.visibleColumns}
				checkedAll={checkedAll}
				onChangeChecked={props.onChangeCheckedAll}
				onSortClick={onSortClick}
				isDetailGrid={props.isDetailGrid}
			/>
			<div className={styles.table} onScroll={trackScrolling}>
				{dataMapping}
				{(props.entity.entity.rows.length === 0 || props.entity.entity.visibleColumns.length === 0) && <div><EmptyGrid /></div>}
				{/* {listStore?.isError && <div>{listStore.error}</div>} */}
				{props.contextMenuOptions &&
					<ContextMenu
						ref={contextMenuRef}
						style={contextMenuStyle}
						options={options}
					/>
				}
			</div>
		</div>
	);
});

const Row = observer(function (props: GridRowProps) {
	const navigate = useNavigate();

	const checkedRow = useMemo(() => (
		props.entity.entity.includedIds.findIndex(x => x.id === props.row.id) > -1 ||
		(
			props.entity.entity.isCheckedAll &&
			props.entity.entity.excludedIds.findIndex(x => x.id === props.row.id) == -1
		)
	), [props.entity.entity.includedIds, props.entity.entity.isCheckedAll, props.entity.entity.excludedIds])

	const handleChange = useCallback((value: boolean) => {
		dispatcher.entity.onChangeChecked(value, props.row, dispatcher.entity.get()?.id === props.entity.id ? undefined : props.entity.entity);
	}, [props.row]);

	const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		handleChange(!checkedRow);
		if (props.onClick) {
			props.onClick(props.row);
		}
	}, [checkedRow, props.onClick, props.row, props.row.id]);

	const handleDoubleClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (props.onDoubleRowClick) {
			props.onDoubleRowClick(props.row.id, event);
			return;
		}
		navigate(props.row.id);
	}, [props.row]);

	const getFixingPoint = useCallback((coord: Coordinate) => {
		let fixingPosition: Coordinate = coord;
		const parrentSize = props.gridBodyRef.current?.getBoundingClientRect();
		const contextMenuSize = props.contextMenuRef.current?.getBoundingClientRect();
		if (parrentSize && contextMenuSize) {
			if (coord.x <= (parrentSize.width - (contextMenuSize.width + 6))) {
				fixingPosition.x -= 6;
			} else {
				fixingPosition.x -= contextMenuSize.width - 6;
			}

			if (coord.y <= (parrentSize.height - (contextMenuSize.height + 6))) {
				fixingPosition.y -= 6;
			} else {
				fixingPosition.y -= contextMenuSize.height - 6;
			}

		}
		return fixingPosition;
	}, [props.gridBodyRef.current, props.contextMenuRef.current, props.entity.entity.isCheckedAll])

	const handleClickEventListener = useCallback((event: Event) => {
		if (props.contextMenuRef.current != null && !props.contextMenuRef.current.contains(event.target as Node)) {
			props.setIsShownContextMenu(false);
			document.removeEventListener("click", handleClickEventListener);
		}
	}, [props.setIsShownContextMenu, props.contextMenuRef.current])

	const checkRows = useCallback((row: any) => {
		if (!props.entity.entity.isCheckedAll) {
			if (!props.entity?.entity.includedIds.find(cell => cell.id === row.id)) {
				props.onChangeCheckedAll(false);
				// dispatcher.entity.onChangeCheckedAll(false,dispatcher.entity.get()?.id === props.entity.id ? undefined : props.entity.entity);
				dispatcher.entity.onChangeChecked(true, row, dispatcher.entity.get()?.id === props.entity.id ? undefined : props.entity.entity);
			}
		}
	}, [toJS(props.entity?.entity.includedIds), props.entity.entity.isCheckedAll])

	const handleClickContextMenu = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.preventDefault();
		props.setIsShownContextMenu(false);
		const newPosition: Coordinate = {
			x: event.pageX,
			y: event.pageY,
		};
		const res = getFixingPoint(newPosition);
		props.setPosition(res);
		checkRows(props.row);
		props.setIsShownContextMenu(true);
		document.addEventListener("click", handleClickEventListener);
	}, [props.row]);

	const rowClassNames = classNames(styles.tableRow, {
		[`${styles.activeDataTableRow}`]: checkedRow
	});

	return (
		<div
			id={props.row.id}
			className={rowClassNames}
			onContextMenu={handleClickContextMenu}
			onClick={handleClick}
			onDoubleClick={handleDoubleClick}
		>
			<CheckBox
				className={styles.checkBoxGrid}
				checked={checkedRow}
				onChangeChecked={handleChange}
			/>

			{props.entity.entity.visibleColumns.map((column: any) => {
				return (
					<div key={column.columnName} className={styles.cell}
						style={{ width: `calc(${column.spanX! + "%"} - 20px` }}>
						<Value row={props.row} column={column} width={`calc(${column.spanX! + "%"} - 20px`} entityName={props.entity.entityName} />
					</div>
				);
			})}
		</div>
	);
});


const FormatValue = (value: any, type: string): JSX.Element => {
	let text: JSX.Element | null = null;
	let element: JSX.Element | null = null;
	switch (type) {
		case ColumnType.String:
		case ColumnType.Guid:
		case ColumnType.Integer:
			text = value;
			break;
		case ColumnType.DateTime:
			const date = new Date(Date.parse(value));
			text = <>{date.toLocaleDateString("ru-Ru")}</>;
			break;
		case ColumnType.Lookup:
			text = value.displayValue;
			break;
		case ColumnType.Double:
		case ColumnType.Decimal:
			text = <>{value.toLocaleString("ru-RU")}</>;
			break;
		case ColumnType.Boolean:
			if ((value as boolean)) {
				text = <>Да</>;
			}
			else {
				text = <>Нет</>;
			}
			break;
	}
	return (
		<>
			{element !== null && element}
			{text !== null &&
				<span className={styles.value}>
					{text}
				</span>
			}
		</>);
}

const Value = (props: ValueProps) => {
	let text: JSX.Element = <>-</>;
	const value = props.row[props.column.columnName];

	if (value !== null && value !== undefined) {
		text = FormatValue(value, props.column.columnType);
	}

	if (props.column.isLink && value) {
		const id = (props.column.columnType === ColumnType.Lookup) ? value.id : props.row["id"];

		if (props.column.lookupTable && props.column.isLookup) {
			return (
				<NavLink className={styles.linkColumn} to={`/singlePage/${props.column.lookupTable.toLocaleLowerCase()}/${id}`}>{text}</NavLink>
			);
		}

		const toPath = location.pathname === `/singlePage/${props.entityName}` ? `${id}` : `/singlePage/${props.entityName?.toLocaleLowerCase()}/${id}`;
		return (
			<NavLink className={styles.linkColumn} to={toPath}>{text}</NavLink>
		);
	}




	return (
		<>
			{text}
		</>

	);
}

export default GeneralizedGrid;
