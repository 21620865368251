import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { action } from "mobx";
import classNames from "classnames";
// import api from "app/services/api"

import { dispatcher } from "store";

import { Button, ButtonStyle, Dialog, WarningDialog, StatusBar, ITab, Select, SearchSelect, IColumn } from "components";
import AdvancedFilterElement from "features/advanced-filter/advanced-filter";
import AdvancedFilterTree from "features/advanced-filter/advanced-filter-tree/advanced-filter-tree";
import StaticGroupBlock from "features/advanced-filter/static-group-block/static-group-block";
// import { staticGroupBlockDialog } from "features/advanced-filter/static-group-block/static-group-block-type";
import DisplayTabs from "features/display-tabs/display-tabs";
import exportStore from "features/export-block/core/export-store";
import ExportBlock from "features/export-block/export-block";
import { SimpleFilter } from "features/filter";

import template from "features/filter/simpleFilter/core/State";
import UniteBlock from "features/unite-block/unite-block";

import Entity, { IEntityStore } from "entities/Entity";
import lookupEntity from "entities/lookup/Lookup";
import ListStore from "entities/ListStore";
import EntityType from "entities/EntityImportType";
import SectionRoute from "entities/SectionRoute";
import templateStore from "app/services/TemplateStore";
import TemplateStoreKeys from "entities/TemplateStoreKeys";

import SavedFilterFolder from "entities/filter/SavedFilterFolder";
import SavedFilter from "entities/filter/SavedFilter";
import StaticGroupFolder from "entities/filter/StaticGroupFolder";
import { AdvancedFilterConst, MaxWidth, ActionsIds, Actions, Formats } from "./data/constants";
import { Item } from "types";

import {
    Add,
    AddTag,
    AddToGroup,
    ChangeDisplay,
    DeleteIcon,
    OpenInNewWindow,
    ExcludeFromGroup,
    Unite,
} from "assets/icons";
import { ArrowToDownMini } from "assets/icons";
import { Export } from "assets/icons";
import { GridIcon } from "assets/icons";
import { Import } from "assets/icons";
import { Kanban } from "assets/icons";
import { VerticalDividerShort } from "assets/icons";
import { AllFilters } from "assets/icons";
import { FilterExtended } from "assets/icons";

import styles from "./section-head.module.css";
import { api } from "api";
import { SectionWizzardMode } from "pages/section-wizzard/section-wizzard";


const SectionHead = observer(function (props: {
    pageTitle: string;
    kanban?: JSX.Element;
    grid?: JSX.Element;
    priorityFilter?: boolean;
    entity: Entity<IEntityStore>;
    dialogTitle: string;
    dialogContent: JSX.Element;
    priorityStore?: ListStore;
    listStore: ListStore;
    columns: IColumn[];
    new: () => void;
}) {
    const [tab, setTab] = useState<JSX.Element>();
    const tabs = useMemo(() => {
        return [
            {
                action: () => {
                    setTab(props.kanban);
                },
                icon: <Kanban />,
                tooltipCaption: "Доска Kanban",
            },
            {
                action: () => {
                    setTab(props.grid);
                },
                icon: <GridIcon />,
                tooltipCaption: "Список",
            },
        ];
    }, []);

    useEffect(() => {
        AdvancedFilterConst.setAdvancedFilterTree({
            savedFilterTree: new SavedFilterFolder(props.entity.entity.schema),
            staticGroupFolderTree: new StaticGroupFolder(props.entity.entity.schema),
        });
    }, []);

    const classes: any = {};
    classes[`${styles.hide}`] = !(
        AdvancedFilterConst.isOpenAdvancedFilterTree ||
        AdvancedFilterConst.isOpenAdvancedFilter
    );
    let cx = classNames(`${styles.sectionPage} `, classes);

    return (
        <>
            <div className={cx}>
                <AdvancedFilterTree
                    isOpen={AdvancedFilterConst.isOpenAdvancedFilterTree}
                />
                <AdvancedFilterElement
                    isOpen={
                        AdvancedFilterConst.isOpenAdvancedFilter &&
                        AdvancedFilterConst.filter !== null
                    }
                    advancedFilter={AdvancedFilterConst.filter!}
                />
                <div className={styles.sectionHeadContent}>
                    <div className={styles.sectionHead}>
                        <div className={styles.sectionHeadTop}>
                            <LeftSectionHead
                                pageTitle={props.pageTitle}
                                priorityFilter={props.priorityFilter}
                                priorityStore={props.priorityStore}
                                listStore={props.listStore}
                                entity={props.entity}
                                dialogTitle={props.dialogTitle}
                                dialogContent={props.dialogContent}
                                new={props.new}
                            />
                            <RightSectionHead
                                pageTitle={props.pageTitle}
                                tabs={props.kanban ? tabs : null}
                                entity={props.entity}
                                listStore={props.listStore}
                                columns={props.columns}
                            />
                        </div>
                        <div className={styles.sectionHeadFooter}>
                            <SectionHeadFooter
                                entity={props.entity}
                                listStore={props.listStore}
                            />
                        </div>
                    </div>
                    {props.kanban ? tab : props.grid}
                </div>
            </div>
        </>
    );
});

interface IRecordKey {
    keys: string[];
    recordsId: string[];
}

const LeftSectionHead = observer(function (props: {
    pageTitle: string;
    priorityFilter?: boolean;
    priorityStore?: ListStore;
    listStore?: ListStore;
    entity: Entity<IEntityStore>;
    dialogTitle: string;
    dialogContent: JSX.Element;
    new: () => void;
}) {
    const [isSaved, setSaved] = useState<boolean>(false);
    const [isBlockSaveOption, setIsBlockSaveOption] = useState<boolean>(false);
    let maxWidth = MaxWidth.maxwidth;
    const widthRef = useRef<HTMLDivElement>(null);
    const refSetTimeout = useRef<NodeJS.Timeout>();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [recordsKeys, setRecordsKeys] = useState<IRecordKey[]>([]);
    const [columnNames, setColumnNames] = useState<any[]>([]);

    async function checkDublicatesBeforeSave() {
        // let resentEntity = await api.post("/api/Entity/entityinfo", {
        //     entityName: props.entity.entity.schema,
        // });
        let resentEntity = await api.http.httpApi.entity.entityInfo().post({
            entityName: props.entity.entity.schema,
        });
        setColumnNames(resentEntity.data.entityInfoResultItems);

        if (isBlockSaveOption) return;
        setIsBlockSaveOption(true);
        let result = await props.entity.checkDublicates();

        if (result.hasDublicates === false) {
            save();
        } else {
            setRecordsKeys(
                result.records.map((record: any) => ({
                    keys: record.keys,
                    recordsId: record.record.id,
                }))
            );
            setOpenDialog(true);
        }
    }

    async function save() {
        let saved = await props.entity.save();

        setSaved(saved);
        if (saved) {
            await props.listStore?.load(
                props.listStore.filter,
                props.listStore.staticGroupId
            );
        }
        refSetTimeout.current = setTimeout(() => {
            setSaved(false);
            setIsBlockSaveOption(false);
        }, 4000);
    }

    const conflictElementsList = useMemo(() => {
        return recordsKeys.map((record, i) => {
            return (
                <>
                    <span key={i}>
                        По полю:{" "}
                        {record.keys.map((key, index) => {
                            let name;
                            name = columnNames.filter(
                                (columnName) =>
                                    columnName.columnName.toLowerCase() === key.slice(0, -2) ||
                                    columnName.columnName.toLowerCase() === key
                            );

                            return (
                                <span key={index}>
                                    {" "}
                                    [{name[0].columnTitle}]([{key}])
                                </span>
                            );
                        })}
                        :
                    </span>
                    <Button
                        caption={"Перейти к записи"}
                        secondIcon={<OpenInNewWindow />}
                        style={ButtonStyle.Link}
                        className={styles.buttonNewWindow}
                        onClick={() => {
                            window.open(window.location.href + "/" + record.recordsId);
                        }}
                    />
                </>
            );
        });
    }, [recordsKeys, columnNames]);

    const handleClickEdit = useCallback(() => {
        setOpenDialog(false);
        setIsBlockSaveOption(false);
    }, []);

    const handleClickCreate = useCallback(() => {
        setOpenDialog(false);
        save();
    }, []);

    const handleClickCloseDialog = useCallback(() => {
        setOpenDialog(false);
    }, []);

    const handleChange = useCallback((value: boolean) => {
        setSaved(value);
        setIsBlockSaveOption(false);
    }, []);

    return (
        <div
            className={styles.left}
            style={
                maxWidth > 0 ? { maxWidth: `calc(100% - ${maxWidth + 10}px)` } : {}
            }
        >
            <div
                className={styles.left}
                ref={widthRef}
                style={{ marginRight: "10px" }}
            >
                <Add className={styles.addButton} onClick={props.new} />
                <div
                    className={styles.title}
                    onClick={() => document.location.reload()}
                >
                    {props.pageTitle}
                </div>
                <VerticalDividerShort className={styles.divider} />
                <Button
                    className={styles.allFilters}
                    style={ButtonStyle.IconIndigo}
                    firstIcon={<AllFilters />}
                    onClick={() => {
                        AdvancedFilterConst.setOpenAdvancedFilterTree(
                            !AdvancedFilterConst.isOpenAdvancedFilterTree,
                            true
                        );
                    }}
                    selected={AdvancedFilterConst.isOpenAdvancedFilterTree}
                />
            </div>
            <div
                className={styles.filters}
                style={
                    widthRef.current?.getBoundingClientRect().width
                        ? {
                            maxWidth: `calc(100% - ${widthRef.current?.getBoundingClientRect().width + 10
                                }px)`,
                        }
                        : {}
                }
            >
                {props.listStore && <SimpleFilter />}
            </div>
            {props.entity !== undefined && (
                <>
                    <Dialog
                        title={
                            props.dialogTitle.startsWith("Нов")
                                ? props.dialogTitle
                                : "Новый " + props.dialogTitle
                        }
                        isOpen={props.entity.openedDialog}
                        dialogFooterButtons={
                            <>
                                <Button
                                    caption={"Отмена"}
                                    onClick={props.entity.cancelClick}
                                    style={ButtonStyle.Subtle}
                                />
                                <Button
                                    caption={"Сохранить"}
                                    onClick={checkDublicatesBeforeSave}
                                    style={ButtonStyle.Success}
                                    isDisabled={props.entity.isSaving}
                                />
                            </>
                        }
                        onClick={props.entity.cancelClick}
                    >
                        {props.dialogContent}
                    </Dialog>
                    <WarningDialog
                        isOpen={props.entity.openedWarningDialog}
                        onBackClick={props.entity.setWarningDialog}
                        onCancelClick={props.entity.cancelChanges}
                    />
                </>
            )}
            <StatusBar
                isOpen={isSaved}
                path={props.entity.entity.id}
                changeOpen={handleChange}
            />
            <Dialog
                title={"Найдены записи-дубликаты"}
                isOpen={openDialog}
                dialogFooterButtons={
                    <>
                        <Button
                            caption={"Вернуться к редактированию"}
                            onClick={handleClickEdit}
                            style={ButtonStyle.Subtle}
                        />
                        <Button
                            caption={"Всё равно создать"}
                            style={ButtonStyle.Primary}
                            onClick={handleClickCreate}
                        />
                    </>
                }
                onClick={handleClickCloseDialog}
            >
                <div className={styles.bodyDedubl}>
                    {conflictElementsList}
                    <div className={styles.dividerBody} />
                </div>
            </Dialog>
        </div>
    );
});

const getImportEntity = (location: any) => {
    if (location.pathname.startsWith("/" + SectionRoute.Contact)) {
        return EntityType.Contacts;
    }
    if (location.pathname.startsWith("/" + SectionRoute.Account)) {
        return EntityType.Counterparties;
    }
    if (location.pathname.startsWith("/" + SectionRoute.Lead)) {
        return EntityType.Leads;
    }
    if (location.pathname.startsWith("/" + SectionRoute.Sale)) {
        return EntityType.Sales;
    }

    return null;
};

interface IValuesToMerge {
    entityId: string;
    entitiesForDelete: [];
    entityName: string;
    values: IValues[];
}

interface IValues {
    propertyName: string;
    propertyValue: string;
}

const RightSectionHead = observer(function (props: {
    pageTitle: string;
    tabs: ITab[] | null;
    entity: Entity<IEntityStore>;
    listStore?: ListStore;
    columns: IColumn[];
}) {
    const location = useLocation();
    const history = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const updateMaxWidth = MaxWidth;
    const [isOpenExportBlock, setOpenExportBlock] = useState(false);
    const [isOpenUniteBlock, setOpenUniteBlock] = useState(false);
    const [isOpenDeleteBlock, setOpenDeleteBlock] = useState(false);
    const [isOpenDialog, setOpenDialog] = useState(false);
    let dialogContent =
        "Удаление невозможно — ID выбранных записей используется в других записях системы.";

    const advancedFilterClassName = classNames(styles.advancedFilter, { [`${styles.advancedFilterNotSelected}`]: !AdvancedFilterConst.isOpenAdvancedFilter })

    const importEntity = useMemo(
        () => getImportEntity(location),
        [location.pathname]
    );
    const [isDoneUnite, setIsDoneUnite] = useState<boolean>(false);

    const actionsIfChecked: Item[] = useMemo(() => {
        return [
            {
                id: ActionsIds.Unite,
                name: "Объединить записи",
                icon: <Unite />,
                isLocked: props.listStore?.countOfChecked! < 2,
            },
            {
                id: ActionsIds.Export,
                name: "Экспортировать (" + props.listStore?.countOfChecked + ")",
                icon: <Export />,
            },
            {
                id: ActionsIds.ChangeDisplay,
                name: "Изменить отображение",
                icon: <ChangeDisplay />,
                isLocked: true,
            },
            {
                id: ActionsIds.Import,
                name: "Импортировать",
                icon: <Import />,
                isSeparator: true,
            },
            {
                id: ActionsIds.AddToGroup,
                name:
                    template.staticGroup !== null
                        ? "Исключить из группы (" + props.listStore?.countOfChecked + ")"
                        : "Добавить в группу...",
                icon:
                    template.staticGroup !== null ? <ExcludeFromGroup /> : <AddToGroup />,
                isLocked: true
            },
            {
                id: ActionsIds.AddTag,
                name: "Добавить тег",
                icon: (
                    <AddTag
                        style={{
                            width: "17px",
                            height: "17px",
                        }}
                    />
                ),
                isLocked: true,
                isSeparator: true,
            },
            {
                id: ActionsIds.Delete,
                name: "Удалить (" + props.listStore?.countOfChecked + ")",
                icon: (
                    <DeleteIcon
                        style={{
                            width: "17px",
                            height: "17px",
                        }}
                    />
                ),
                isRed: true,
                isLocked: props.listStore?.countOfChecked! < 1,
            },
        ];
    }, [props.listStore?.countOfChecked]);

    // const staticGroupBlock = useMemo(
    //     () =>
    //         // staticGroupBlockDialog.isOpen 
    //         //TODO сделать через modalController??????????
    //         // && (
    //         //     <StaticGroupBlock
    //         //         staticGroupFolderTree={
    //         //             AdvancedFilterConst.filterTree?.staticGroupFolderTree!
    //         //         }
    //         //         listStore={props.listStore}
    //         //     />
    //         // )
    //         ,
    //     [staticGroupBlockDialog.isOpen]
    // );

    const observer = useRef(
        new ResizeObserver((entries) => {
            const { width: containerWidth } = entries[0].contentRect;
            updateMaxWidth.setMaxWidth(containerWidth);
        })
    );

    useEffect(() => {
        if (ref.current) {
            updateMaxWidth.setMaxWidth(ref.current.getBoundingClientRect().width);
        }
    }, []);

    useEffect(() => {
        if (ref.current) {
            observer.current.observe(ref.current);
        }
    }, [observer]);

    async function deleteRecords() {
        try {
            let promises: Promise<boolean>[] = [];
            if (props.listStore!.includedIds.length > 0)
                promises = props.listStore!.includedIds.map(async (element) => {
                    const deleted = await props.entity.delete(
                        props.listStore?.schema,
                        element.id
                    );
                    return deleted;
                });
            else if (props.listStore!.isCheckedAll) {
                if (props.listStore!.excludedIds.length > 0)
                    promises = props.listStore!.data.map(async (item) => {
                        let index = props.listStore!.excludedIds.findIndex(
                            (exc) => exc.id === item.id
                        );
                        let deleted = true;
                        if (index === -1)
                            deleted = await lookupEntity.delete(
                                props.listStore?.schema,
                                item.id
                            );
                        return deleted;
                    });
                else
                    promises = props.listStore!.data.map(async (item) => {
                        const deleted = await lookupEntity.delete(
                            props.listStore?.schema,
                            item.id
                        );
                        return deleted;
                    });
            }
            await Promise.all(promises);
            await props.listStore!.load(
                props.listStore!.filter,
                props.listStore?.staticGroupId
            );
        } catch (error: any) {
            if (error.response.status === 401) {
                console.error(error.response);
            }
            if (error.response.status === 409) {
                setOpenDialog(true);
            }
            if (error.response.status === 403) {
                dialogContent =
                    "Недостаточно прав для совершения операции.\nПожалуйста, обратитесь к вашему администратору.";
                setOpenDialog(true);
            }
        }
    }

    const excludeRecordsFromGroup = useCallback(async () => {
        let includedIds: string[] = props.listStore?.includedIds
            ? props.listStore?.includedIds.map(({ id }) => id)
            : [];
        let excludedIds: string[] = props.listStore?.excludedIds
            ? props.listStore?.excludedIds.map(({ id }) => id)
            : [];

        const excluded = await template.staticGroup!.excludeRecords(
            includedIds,
            excludedIds
        );

        if (excluded) {
            props.listStore?.load(props.listStore.filter, template.staticGroup?.id);
        }
    }, []);

    function onClickAction(item: Item | null) {
        if (item) {
            if (item.id === ActionsIds.Import && importEntity) {
                templateStore.set(TemplateStoreKeys.import, importEntity);
                history("/import");
            } else if (item.id === ActionsIds.AddToGroup) {
                if (template.staticGroup !== null) {
                    excludeRecordsFromGroup();
                } else {
                    // staticGroupBlockDialog.setOpen(true);
                }
            } else if (item.id === ActionsIds.Unite) {
                setOpenUniteBlock(true);
            } else if (item.id === ActionsIds.ChangeDisplay) {
            } else if (item.id === ActionsIds.Delete) {
                setOpenDeleteBlock(true);
            } else if (item.id === ActionsIds.Export) {
                setOpenExportBlock(true);
                exportStore.initSignalR();
            } else if (item.id === ActionsIds.AddTag) {
            } else if (item.id === ActionsIds.ExportAll) {
                props.listStore!.onChangeCheckedAll(true);
                setOpenExportBlock(true);
                exportStore.initSignalR();
            } else if (item.id === ActionsIds.SectionWizzard) {
                window.open(
                    `/sectionMaster/${SectionWizzardMode.ExistSection}/${dispatcher.entity.get()?.entityName.toLowerCase()}`,
                    "_blank"
                );
            }
        }
    }

    return (
        <div className={styles.sectionBlock} ref={ref} id="RightSectionHead">
            <div className={styles.rightButtons}>
                {props.tabs !== null && (
                    <>
                        <DisplayTabs
                            items={props.tabs}
                            styleButton={ButtonStyle.Icon}
                            useLocalStorage={true}
                            localStorageKey={"display"}
                        />
                        <VerticalDividerShort className={styles.divider} />
                    </>
                )}
            </div>
            <Button
                caption={"Расширенный фильтр"}
                className={advancedFilterClassName}
                style={ButtonStyle.IndigoSelect}
                firstIcon={<FilterExtended />}
                onClick={() => {
                    AdvancedFilterConst.setAdvancedFilter(
                        new SavedFilter(props.entity.entity.schema)
                    );
                    AdvancedFilterConst.setOpenAdvancedFilterTree(
                        !AdvancedFilterConst.isOpenAdvancedFilter
                    );
                }}
                selected={AdvancedFilterConst.isOpenAdvancedFilter}
            />
            <VerticalDividerShort className={styles.divider} />
            <Select
                name={"Действия"}
                items={
                    props.listStore?.countOfChecked! > 0 ? actionsIfChecked : Actions
                }
                onChangeValue={onClickAction}
                secondIcon={<ArrowToDownMini />}
                isRotateSecondIcon={true}
                styles={ButtonStyle.Link}
                classNameButton={styles.actionButton}
            />
            <ExportBlock
                schema={props.listStore!.schema}
                pageTitle={props.pageTitle}
                listStore={props.listStore}
                formats={Formats}
                title={"Экспорт данных: " + props.pageTitle}
                choosingCount={props.listStore?.countOfChecked!}
                allCount={props.listStore?.count!}
                isOpen={isOpenExportBlock}
                onClick={() => {
                    setOpenExportBlock(false);
                    props.listStore?.onChangeCheckedAll(false);
                }}
            />
            {/* {staticGroupBlock} */}
            <UniteBlock
                columns={props.columns}
                listStore={props.listStore}
                entity={props.entity}
                isOpen={isOpenUniteBlock}
                onClick={() => {
                    setOpenUniteBlock(false);
                }}
                setDone={setIsDoneUnite}
            />
            <StatusBar
                isOpen={isDoneUnite}
                path={props.entity.entity.id}
                eventName={"Записи объединены в одну"}
            />
            <WarningDialog
                value={
                    "Вы действительно хотите удалить выбранные записи?\nЭто действие невозможно отменить."
                }
                valueReturn={"Отмена"}
                valueDelete={"Удалить"}
                isOpen={isOpenDeleteBlock}
                onBackClick={() => {
                    props.listStore?.onChangeCheckedAll(false);
                    setOpenDeleteBlock(false);
                }}
                onCancelClick={() => {
                    deleteRecords();
                    setOpenDeleteBlock(false);
                    props.listStore?.onChangeCheckedAll(false);
                }}
            />
            <Dialog
                title={"Не удалось удалить записи"}
                isOpen={isOpenDialog}
                dialogFooterButtons={
                    <Button
                        caption={"Понятно"}
                        onClick={() => {
                            setOpenDialog(false);
                        }}
                        style={ButtonStyle.Primary}
                    />
                }
                onClick={() => {
                    setOpenDialog(false);
                }}
            >
                <div className={styles.dialogContent}>{dialogContent}</div>
            </Dialog>
        </div>
    );
});

const SectionHeadFooter = observer(function (props: { entity: Entity<IEntityStore>, listStore: ListStore, }) {
    const [constItems, setConstItems] = useState<Item[]>([]);
    const [items, setItems] = useState<Item[]>([]);

    useEffect(() => {
        getItems();
    }, []);

    async function getItems() {
        let items = await template.getFiltersList(props.entity.entity.schema);
        setConstItems(items);
        setItems(items);
    }

    return (
        <div className={styles.sectionBlockFooter}>
            <div>
                <SearchSelect
                    items={items}
                    name={"Сохраненные фильтры"}
                    styles={ButtonStyle.IndigoSelect}
                    onItemsLoad={async (s) => {
                        if (s === null) {
                            getItems();
                            return constItems.length;
                        }
                        let newItems = constItems.filter((x) => x.name?.includes(s));
                        setItems(newItems);
                        return newItems.length;
                    }}
                    value={null}
                    onChangeValue={(value) => {
                        if (value) template.getFilter(value.id.toString(), props.listStore);
                    }}
                    secondIcon={<ArrowToDownMini />}
                    isRotateSecondIcon={true}
                />

                {template.favoriteFilters.map((favoriteFilter, i) => {
                    return (
                        <>
                            {i === 0 && <div className={styles.ellipse} />}
                            <Button
                                caption={favoriteFilter.filterName ?? ""}

                                style={ButtonStyle.IndigoSelect}
                                selected={template.filter?.id === favoriteFilter.id!}
                                onClick={action(() => {
                                    template.getFilter(favoriteFilter.id!, props.listStore);
                                })}
                            />
                        </>
                    );
                })}
            </div>

            <RowCount count={props.listStore.count} />
        </div>
    );
});

function RowCount(props: { count: number }) {
    return (
        <div>
            <div>
                <span className={styles.rowCountLabel}>Количество:</span>
                <span className={styles.rowCountValue}>{props.count}</span>
            </div>
        </div>
    );
}

export default SectionHead;
