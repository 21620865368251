import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { InputStyleName, InputStyles } from 'components';

import { Locked } from "assets/icons";

import styles from './input.module.css';


interface IInputNumberProps extends React.HTMLAttributes<HTMLElement> {
    name?: string;
    value: number | string | null;
    placeholder: string;
    isInvalid?: boolean;
    invalidMessage?: string;
    isDisabled?: boolean;
    isDisabledLocked?: boolean;
    classNameInput?: string;
    classNameInputFocus?: string;
    isNeedCorrect?: boolean;
    size?: number | null;
    isBaseInput?: boolean;
    isLightning?: boolean;
    onChangeValue: (value: string) => void;
    onFocusOut?: () => void;
    focus?: boolean;
    inputStyle?: InputStyleName;
}

function InputNumber(props: IInputNumberProps) {
    const [focusOut, setFocusOut] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const ref = useRef<HTMLInputElement>(null);

    const inputStyle = InputStyles[props.inputStyle ? props.inputStyle : props.isBaseInput ? InputStyleName.Base : InputStyleName.BaseWithoutBorder];

    useEffect(() => {
        if (props.focus)
            ref.current!.focus();
    }, [props.focus]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        props.onChangeValue(event.target.value.replace(/[^0-9.]/, ""));

    }

    function handleClick(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        setFocusOut(false);
        setFocus(true);
        if (props.onClick !== undefined)
            props.onClick(event);
    }

    function handleFocus(event: React.FocusEvent<HTMLInputElement, Element>) {
        setFocusOut(false);
        setFocus(true);
    }

    function handleOnBlur() {
        if (props.onFocusOut !== undefined)
            props.onFocusOut();
        if (props.isInvalid === false || (props.value !== null && props.value.toString().length > 0)) {

            setFocusOut(true);
        }
        setFocus(false);

    }
    const wrapperClassNames = classNames({
        [`${inputStyle.classNames} `]: true,
        [`${props.className} `]: props.className,
        [`${inputStyle.focus} `]: focus,
        [`${props.classNameInputFocus} `]: focus && props.classNameInputFocus,
        [`${inputStyle.invalid} `]: props.isInvalid,
        [`${styles.correct} `]: focusOut && props.isLightning && !props.isInvalid,
    });
    const inputClassNames = classNames({
        [`${inputStyle.input} `]: true,
        [`${inputStyle.disabled} `]: props.isDisabled,
        [`${props.classNameInput} `]: props.classNameInput,
    });

    let icon = null;
    if (props.isDisabled && props.isDisabledLocked) {
        icon = <Locked className={styles.iconResult} />;
    }

    let result = null;
    let error = null;
    if (props.isInvalid === true) {
        if (props.invalidMessage !== undefined && props.invalidMessage.length > 1) {
            let invalidMessageClasses = `${styles.errorMessage} `;
            error = <div className={invalidMessageClasses}>{props.invalidMessage}</div>
        }
    }

    return (
        <div className={wrapperClassNames} style={{ display: "flex", alignItems: "center" }}>
            <input
                ref={ref}
                id={props.id}
                name={props.name}
                type={'text'}
                className={inputClassNames}
                value={props.value ?? ""}
                onChange={handleChange}
                placeholder={props.placeholder}
                onBlur={handleOnBlur}
                disabled={props.isDisabled}
                size={props.size!}
                style={props.style}
                onMouseOver={props.onMouseOver}
                onClick={handleClick}
                onFocus={handleFocus}
                onKeyUp={props.onKeyUp}
            />
            {icon}
            {result}
        </div>
    );
}


export default InputNumber;
