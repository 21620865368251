import { observer } from "mobx-react";
import {  useState } from "react";

import Comments from "features/comments/comments-block";
import Tabs, { Tab } from "./tabs/tabs";

import { IAdditionalBlockData, ICommentData } from "./types/types";

import styles from "./additional-block.module.css";


const AdditionalBlock = observer(function (props: IAdditionalBlockData) {
	const [comments, setComments] = useState<ICommentData[]>([]);
	const [activeTab, setActiveTab] = useState<string>("tasks");

	return (
		<Tabs activeTab={activeTab} setActiveTab={(value: string) => { setActiveTab(value); }} >
			<Tab caption="Задачи" name="tasks" counter={0}>

			</Tab>

			<Tab caption="Комментарии" name="comments" counter={comments.length}>
				{props.commentEntity &&
                    <Comments
                    	className={styles.commentsWrapper}
                    	classNameTabContent={styles.tabContent}
                    	classNameTextEditor={styles.textEditor}
                    	commentsAndInputClassName={styles.commentsAndInputBlock}
                    />
				}         

			</Tab>
			<Tab caption="Лента изменений" name="changes" counter={0}>

			</Tab>
		</Tabs>
	);
});

export default AdditionalBlock;
