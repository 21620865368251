import { useMemo, useState } from "react";

import { filterFinally, filterIntermediate, filterVisibly, sortByOrder } from "./lib";

import { StageGroup } from "./components/stage-group";

import { StageFullInfo } from "types/entity";

import styles from "./stage-model-settings.module.css"

interface StageModelSettingsProps {
    onChooseStage: (stage: any) => void
    stages: Array<StageFullInfo>,
    selectedStageId: string,
    handleMoveIntermediateStage: (oldPosition: number, newPosition: number) => void;
    handleMoveFinallyStage: (oldPosition: number, newPosition: number) => void;
}

export function StageModelSettings(props: StageModelSettingsProps) {
    const sortedStages = useMemo(() => sortByOrder(props.stages), [props.stages]);

    const intermediateStages = useMemo(() => filterIntermediate(sortedStages), [sortedStages]);

    const finallyStages = useMemo(() => filterFinally(sortedStages), [sortedStages]);
    
    const [stagesCounter, setStagesCounter] = useState<number>(1);

    return (
        <div className={styles.wrapper}>
            <StageGroup
                isSingleGroup={!filterVisibly(finallyStages).length}
                stages={intermediateStages}
                header="Промежуточные стадии"
                onOpenSettingPanel={props.onChooseStage}
                onMoveStage={props.handleMoveIntermediateStage}
                selectedStageId={props.selectedStageId}
                stagesCounter={stagesCounter}
                setStagesCounter={setStagesCounter}
            />
            <StageGroup
                isSingleGroup={!filterVisibly(intermediateStages).length}
                stages={finallyStages}
                header="Результирующие стадии"
                onOpenSettingPanel={props.onChooseStage}
                onMoveStage={props.handleMoveFinallyStage}
                selectedStageId={props.selectedStageId}
                stagesCounter={stagesCounter}
                setStagesCounter={setStagesCounter}
            />
        </div>
    );
}