import Api from "api/http/api";

class GlobalSearch extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/GlobalSearch";
	}

	search = () => {
		this.setPath(`${this.basicPath}/search`);
		return this;
	};
}

const globalSearcher = new GlobalSearch();

export { globalSearcher };