import { VirtualLookup } from "pages/section-wizzard/pages/constructor/field-configuration/types";
import { User } from "./";
import SavedFilter from "entities/filter/SavedFilter";
import IFilter from "entities/filter/IFilter";

export enum LoadingState {
    NotAsked,
    Loading,
    Successful,
    Error
}

export enum EntityNameType {
    Tables = 0,
    Sections = 1,
    Lookups = 2,
}

type City = {
    createdOn: string,
    externalId: string | null,
    id: string,
    modifiedOn: string,
    name: string
}

type Gender = {
    createdOn: string;
    externalId: string | null;
    id: string;
    modifiedOn: string;
    name: string;
};

type Role = {
    createdOn: string;
    externalId: string | null;
    id: string;
    modifiedOn: string;
    name: string;
    order: number;
};

export enum EntityType {
    ACCOUNT = "ACCOUNT",
    CONTACTS = "CONTACTS",
    LOOKUP = "LOOKUP",
    LEAD = "LEAD",
}

export type Column = {
    columnId: string;
    columnName: string;
    columnTitle: string;
    columnType: string;
    hasIndex: boolean;
    isLookup: boolean;
    isLink: boolean;
    isRequired: boolean;
    lookupTable: string | null;
    specializations?:
    {
        tag: number,
        properties: {
            additionalProp1: string,
            additionalProp2: string,
            additionalProp3: string
        }
    }
};

export type ViewColumn = {
    id: string,
    columnName: string,
    columnTitle: string
};


export type Cell = {
    birthDate: string,
    cityId: string | null;
    createdOn: string;
    email: string;
    externalId: string | null;
    genderId: string;
    givenName: string;
    id: string;
    middleName: string | null;
    modifiedOn: string;
    name: string;
    notes: string | null;
    phone: string;
    position: string | null;
    primaryPhone: string | null;
    site: string | null;
    ownerId: string | null;
    inn: string | null;
    kpp: string | null;
    ogrn: string | null;
    categoryId: string | null;
    industryId: string | null;
    typeId: string | null;
    typeOwnershipId: string | null;
    primaryAccount: string | null;
    primaryAccountId: string | null;
    roleId: string;
    surName: string;
    city: City | null;
    gender: Gender;
    role: Role;
    owner: User;
    comments: Array<any>;
}

// type Filter = {
//     aggregationColumn: string | null;
//     attribute: string;
//     attributeType: number;
//     comparisonType: number;
//     detail: string;
//     detailAggregationType: number;
//     detailFilter: string | null;
//     excludedIds: string | null;
//     filters: Array<Filter>;
//     id: string;
//     includedIds: string | null;
//     isActive: boolean;
//     isEnabled: boolean;
//     logicalOperation: number;
//     rightExpression: { type: number, parameter: { dataValueType: number, value: string }, macros: string | null };
//     schema: string;
//     type: number;
// };

type FilterInfo = {
    aggregationColumn: string;
    attribute: string;
    attributeType: number;
    comparisonType: number;
    detail: string;
    detailAggregationType: string;
    detailFilter: string | null;
    excludedIds: string | null;
    filters: Array<Filter>;
    id: string;
    includedIds: string | null;
    isActive: boolean;
    isEnabled: boolean;
    logicalOperation: number;
    rightExpression: null | string;
    schema: string;
    type: number;
}

type FilterFolder = {
    childFilterFolders: Array<FilterFolder>
    entityName: string | null;
    filters: Array<{
        createdOn: string;
        entityName: string;
        filterInfo: FilterInfo;
        filterName: string;
        id: string;
        isFavorite: boolean;
        modifiedOn: string,
        savedFilterFoldId: string;
        userId: string;
    }>
    id: string;
    isRoot: boolean;
    name: string;
    parentFolderId: string;
};

export enum OptionPage {
    GlobalSettings = "GlobalSettings",
    Constructor = "Constructor",
    AccessRights = "AccessRights",
    StageModel = "StageModel",
    CardSettings = "CardSettings",
    QuickView = "QuickView",
}

export enum DisplayedPanel {
    Main,
    Additional
}

export enum SectionWizzardFields {
    optionPage = "optionPage",
    entityTitle = "entityTitle",
    systemName = "systemName",
    hasStageModel = "hasStageModel",
    kanbanIsEnabled = "kanbanIsEnabled",
    hasCalendar = "hasCalendar",
    hasSpecificFinish = "hasSpecificFinish",
    searchValue = "searchValue",
    displayedPanel = "displayedPanel",
    viewColumnId = "viewColumnId",
    viewAdditionalPanelToggle = "viewAdditionalPanelToggle",
    isValid = "isValid",
}
export enum TabId {
    Files = "files",
    Comments = "comments",
    Chronology = "chronology",
    LinkedRecords = "linkedRecords"
}
export enum TabState {
    EnabledOnPrimary,
    EnabledOnAuxiliary,
    Disabled
}

export enum RecordOperation {
    Read = 0,
    Edit = 1,
    Delete = 2,
}

export enum RecordRightLevel {
    Deny = 0,
    Granted = 1,
}
/**
 * @param state Состояние таба
 * @param index Индекс таба в массиве вкладок
 * @param title Заголовок таба
 */
export type TabParams = {
    state: TabState,
    index: number,
    title: string
};
export type AdditionalPanel = {
    comments: TabParams,
    files: TabParams,
    chronology: TabParams,
    linkedRecords: TabParams
};
export type GroupFieldsConfig = {
    name: string,
    title: string,
    inner: Grid | null,
    groupFieldId: string,
};
export type VirtualDetail = {
    entityName: string,
    entityTitle: string,
    entityLinkColumn: string,
    entityLinkColumnName: string,
    entityLinkColumnTitle: string,
};
export type DetailConfig = {
    entityName: string,
    detailName: string,
    detailTitle: string,
    detailColumn: string,
    entityColumn: string,
    inner: Grid | null,
    virtualDetail?: VirtualDetail | null
};
export type DetailDesignerType = {
    columnsInfo: Array<Column>,
    detailConfig: DetailConfig | null,
    isSection: Boolean
};

export enum ItemType {
    Field,
    GroupFields,
    Detail
}
export type FieldConfig = {
    columnId: string;
    columnName: string;
    columnTitle: string;
    columnType: string;
    hasIndex: boolean;
    isLookup: boolean;
    isRequired: boolean;
    lookupTable: string | null;
    defaultValue?: string;
    virtualLookup?: VirtualLookup | null;
    rounding?: string;
    prompt?: string;
    specializations?: EntityColumnSpecialization
};

export type EntityColumnSpecialization = {
    tag: ColumnSpecializationType
    properties: Properties;
}

export type Properties = {
    [key: string]: string;
};


export enum ColumnSpecializationType {
    DateTime,
    Price,
    Link,
    Phone,
    Stage,
    Avatar,
    Double,
}

export type GridItem = {
    x: number,
    y: number,
    gridItemId: string,
    width: number,
    height: number,
    type?: ItemType,
    fieldConfig?: FieldConfig,
    groupFieldsConfig?: GroupFieldsConfig,
    detailConfig?: DetailConfig
};
export type Grid = {
    items: Array<GridItem>
};
export type TabsConfig = {
    tabIndex: number,
    tabName: string,
    tabTitle: string,
    grid: Grid
};

export type Tabs = {
    tabsConfig: TabsConfig[],
    additionalPanel: AdditionalPanel,
    currentTab: number
};
export type Navbar = {
    left: string,
    center: string,
    right: string
};

export type ReactorConfig = {
    navbar: Navbar,
    tabs: Tabs
};

export type AccessRightsConfig = {
    adminByOperation: {
        isEnabled: boolean;
        operationItems: OperationItem[]
    };
    adminByRecords: {
        isEnabled: boolean;
        recordItems: RecordItem[];
    };
}

export type OperationItem = {
    id: string,
    userId: string | null;
    roleId: string | null;
    unitName: string | null;
    canCreate: boolean;
    canEdit: boolean;
    canRead: boolean;
    canDelete: boolean;
    position: number;
}

export type RecordItem = {
    id: string,
    groupRecordsId: string,
    authorUserId: string | null;
    authorRoleId: string | null;
    granteeUserId: string | null;
    granteeRoleId: string | null;
    authorName: string | null;
    granteeName: string | null;
    operation: RecordOperation;
    rightLevel: RecordRightLevel;
    position: number;
}

export type SectionWizzard = {
    optionPage: OptionPage | null,
    entityTitle: string,
    systemName: string,
    hasStageModel: boolean,
    kanbanIsEnabled: boolean,
    hasCalendar: boolean,
    hasSpecificFinish: boolean,
    displayedPanel: DisplayedPanel,
    viewColumnId: string | null,
    searchValue: string | null,
    reactorPage: string | null,
    reactorConfig: ReactorConfig,
    accessRightsConfig: AccessRightsConfig,
    hasChanges: boolean,
    stageModelConfig: StageModel | null;
};

export type StaticGroup = {
    id?: string | null;
    userId: string | null;
    entityName: string | null;
    name: string | null;
    filter: IFilter | null;
    folderId: string | null;
    modifiedOn?: string;
    createdOn?: string;
};

export type ExcludeRecordsFromStaticGroup = {
    staticGroupId: string,
    recordsToExclude: string[],
    recordsToSave: string[]
};

export type Filter = {
    savedFilter: SavedFilter | null,
    staticGroup: StaticGroup | null,
    favoriteFilters: SavedFilter[];
    placeholder: string | null,
};

export type SavedFilterFolder = {
    id?: string | null;
    parentFolderId: string | null;
    name: string | null;
    filters: SavedFilter[];
    childFilterFolders: SavedFilterFolder[];
    isRoot: boolean;
    entityName: string | null;
    userId: string | null;
}

export type StaticGroupFolder = {
    id?: string | null;
    parentFolderId: string | null;
    name: string | null;
    groups: StaticGroup[];
    childGroupFolders: StaticGroupFolder[];
    isRoot: boolean;
    entityName: string | null;
}

export type FilterTree = {
    savedFilterTree: SavedFilterFolder | null;
    staticGroupFolderTree: StaticGroupFolder | null;

}

export interface BasicEntity {
    columns: Array<Column>;
    viewColumn: ViewColumn | null;
    rows: Array<any>;
    filterTree: FilterTree | null;
    quality: number;
    sort: any;
    visibleColumns: any;
    display: any;
    filter: Filter | null;
    sectionWizzard: SectionWizzard | null
    isCheckedAll: boolean;
    includedIds: Array<any>;
    excludedIds: Array<any>;
    countOfChecked: number;
}

export type StageId = string; // Общий id стадии
export type Id = string; // Индивидумальный id еденицы данных

export enum HEXColors {
    //TODO старые цвета из палитры
    // "#B3B8DB" = "#B3B8DB",
    // "#4FB8D3" = "#4FB8D3",
    // "#41E3DC" = "#41E3DC",
    // "#A6E73C" = "#A6E73C",
    // "#FFF126" = "#FFF126",
    // "#FFBE37" = "#FFBE37",
    // "#F072BB" = "#F072BB",
    // "#CC66FF" = "#CC66FF",

    "#C7D7FE" = "#C7D7FE",
    "#EAECF5" = "#EAECF5",
    "#DDF1F6" = "#DDF1F6",
    "#DAF9F8" = "#DAF9F8",
    "#EEFAD9" = "#EEFAD9",
    "#FFFBCB" = "#FFFBCB",
    "#FFF1D5" = "#FFF1D5",
    "#FBD9EC" = "#FBD9EC",
    "#F1D6FF" = "#F1D6FF",
}

export enum ResultHEXColors {
    SUCCESSFUL = "#D0F2E0",
    NEGATIVE = "#FDD6D3",
    NULL = "#C7D7FE",
}

export enum ResultType {
    SUCCESSFUL = 0,
    NULL = 1,
    NEGATIVE = 2,
}

/**
 * @description 
 * Current - текущая стадия 
 * 
 * Passed - стадия с левой стороны от current
 * 
 * Default - стадия с правой стороны от current
 */
export enum StageType {
    CURRENT = "Current",
    PASSED = "Passed",
    DEFAULT = "Default",
}

export type Stages = {
    id: StageId;
    name: string;
};

export type DetailResult = {
    id: Id;
    stageId: StageId;
    detailResultText: string;
};

export type StageSetting = {
    id: Id;
    stageId: StageId;
    color: HEXColors | string;
    isHidden: boolean;
    order: number;
    resultType: ResultType | null;
};

export type MovingRule = {
    id: Id;
    stageFromId: StageId;
    stageToId: StageId;
};

export type StageModel = {
    stageLookupTitle: string;
    stageLookupName: string;
    detailedResultLookupName: string;
    stages: Array<Stages>;
    detailResults: Array<DetailResult>;
    stageSettings: Array<StageSetting>;
    movingRules: Array<MovingRule>;
};

export type StagesFromAndTo = {
    stagesFrom: Array<string>,
    stagesTo: Array<string>
};

export type StageFullInfo = {} & Stages & DetailResult & StageSetting & StagesFromAndTo;

export const BlockedColumnNames = ['Id', 'ExternalId', 'CreatedOn', 'ModifiedOn', 'CreatedBy', 'ModifiedBy']