import { makeAutoObservable } from "mobx";
import * as signalR from "@microsoft/signalr";

import { IFormat } from "entities/IItem";
import ListStore from "entities/ListStore";
import FilterBuilder from "app/services/filter/FilterBuilder";
import { UpFirst } from "entities/lowFirst";

import { ColumnTypeName } from "pages/settings/data/Fields";
import { getSignalRUrl } from "app/services/UrlService";
import { api } from "api";
import { Entity, dispatcher } from "store";

export enum ResultStates {
    Progress = "Progress",
    Success = "Success",
    NotStarted = "NotStarted"
}

export interface IExportResult {
    state: ResultStates;
    percent: number;
}


class ExportStore {
	pageTitle: string = "";
	schema: string = "";
	listStore: ListStore | undefined;
	formats: IFormat[] = [];
	columns: any[] = [];
	checkedColumns: any[] = [];
	checkedAll: boolean = false;
	exportResult: IExportResult | null = { state: ResultStates.NotStarted, percent: 0 };
	connection: signalR.HubConnection | null = null;

	constructor() {
		makeAutoObservable(this);
		this.checkedColumns = [];
		this.initSignalR();
	}


	setValue(value: any, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	clearCheckedColumns() {
		this.checkedColumns = [];
	}
	setCheckedAll() {
		this.checkedColumns = this.columns;

	}
	getActiveFormat() {
		return this.formats.filter(e => e.checked === true)[0];
	}
	getNameColumns() {
		let newColumns: string[] = [];
		this.checkedColumns.forEach(checkedColumn => {
			if (checkedColumn.type) {
				if (checkedColumn.type === 6 || checkedColumn.type === 7 || checkedColumn.type === 9 || checkedColumn.type === 11 || checkedColumn.type === 12)
					newColumns.push(UpFirst(checkedColumn.name + "Id"));
				else newColumns.push(UpFirst(checkedColumn.name));
			}
			else if (checkedColumn.columnType) { //для справочников
				if (checkedColumn.columnType === ColumnTypeName.Lookup)
					newColumns.push(UpFirst(checkedColumn.columnName + "Id"));
				else newColumns.push(UpFirst(checkedColumn.columnName));
			}

		});

		return newColumns;
	}

	async initSignalR() {
		let url = getSignalRUrl();
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(`${url}/exporthub`)
		//.configureLogging(signalR.LogLevel.Trace) don't remove
			.build();

		await this.connection.start();
		this.connection.on("ExportProgress", (progress, state) => {
			exportStore.exportResult = { state: state, percent: progress };
		});
	}

	async stopSignalR() {
		this.connection?.stop();
	}

	/**
	 * Старт экспорта
	 * @description При старте экспорта мы достаем отмеченные записи в гриде и фильтр. Для старых разделов это берем из listStore, для новых из dispatcher.entity.get()
	 */
	async startExport(entity?:Entity) {
		try {
			let filters = new FilterBuilder(this.schema);
			if (this.listStore?.filter) {
				filters.filter = this.listStore.filter;
			}
			if(this.listStore){
				filters.filter.includedIds = this.listStore?.includedIds.map(e => e.id);
				filters.filter.excludedIds = this.listStore?.excludedIds.map(e => e.id);
			}else{
				//TODO после адаптации фильтров под кастомные разделы, нужно доставать фильтр из нового стора и передавать его. Это нужно
				// в том случае если записи не выбраны но применен фильтр, таким образом мы не экспортируем всю таблицу
				const exportEntity = entity ?? dispatcher.entity.get();
					filters.filter.includedIds = exportEntity?.entity.includedIds.map((element)=>{
						return element.id
					})
					filters.filter.excludedIds = exportEntity?.entity.excludedIds.map((element)=>{
						return element.id
					})				
			}
			
			

			let data = {
				entityName: this.schema,
				fileType: this.getActiveFormat().name,
				column: this.getNameColumns(),
				filters: filters.filter
			};
			const fileName = "ExportFile" + this.getActiveFormat().name;
			let response = await api.http.httpApi.exportEntity.exportEntity().downloadFile(data,fileName);
		}
		catch (e) {
			console.log(e);
		}
		finally {
			this.checkedColumns = [];
			this.checkedAll = false;
		}
	}

	async initColumnsByEntity() {
		try {
			let response = await api.http.httpApi.entity.entityInfo().post({ "entityName": `${this.schema}` });
			let columns = response.data.data.entityInfoResultItems;
			this.columns = columns;
		} catch (e) {
			console.log(e);
		}
	}
}



const exportStore = new ExportStore();

export default exportStore;