import { useState } from "react";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";

import classNames from "classnames";

import { ClocksIcon, Locked } from "assets/icons";
import { DateIcon } from "assets/icons";

import styles from "components/input-for-section-designer/input.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

export const InputDateTime = (props:{[className: string]: any }) => {
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());
	const [selectedTime, setSelectedTime] = useState<Date>(new Date());;

	const dateTimeInputWrapper = classNames(styles.dateInputWrapper,
		{ [`${props.className}`]: props.className },
	);

	const dateWrapper = classNames(`${styles.wrapperDateTime} ${styles.borderBottom}`,
		{ [`${props.className}`]: props.className, }
	);

	const dateInput = classNames(styles.inputDateTime,
		{ [`${styles.dateLocked}`]: props.isDisabled, }
	);

	const timeWrapper = classNames(`${styles.parentTime} ${styles.wrapperTime} ${styles.borderBottom} time-picker-container`,
		{
			[`${props.className}`]: props.className,
			[`${styles.parentLockedTime} ${styles.parentTime} ${styles.borderBottom} time-picker-container`]: props.isDisabled,
		});

	const timeInput = classNames(
		{ [`${styles.inputTimeDateLocked}`]: props.isDisabled, }
	);

	return (
		<div {...props} className={dateTimeInputWrapper}>
			<div className={dateWrapper}>
				<DatePicker
					selected={selectedDate}
					onChange={(date: Date) => setSelectedDate(date)}
					dateFormat="dd.MM.yyyy"
					className={dateInput}
					disabled={props.isDisabled}
					open={false}
				/>			
				{props.isDisabled ? <Locked className={styles.iconResult} />:<div className={styles.iconWraper}><DateIcon/></div>}
			</div>

			<div className={timeWrapper}>
				<TimePicker
					onChange={(time: any) => setSelectedTime(time)}
					value={selectedTime}
					format="HH:mm"
					clockIcon={null}
					clearIcon={null}
					className={timeInput}
					disabled={props.isDisabled}
					openClockOnFocus={false}
				/>
				{props.isDisabled ? <Locked className={styles.iconResult} />:<div className={styles.iconWraper}><ClocksIcon/></div>}
			</div>
		</div>
	);
};