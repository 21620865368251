import eventBus from "app/services/EventBus";
// import api from "app/services/api";
import FileStorages from "entities/FileStorages";
import FileStoreEvents from "../types/FileStoreEvents";
import { api } from "api";
class DataBaseStore{
	async swithStore(isMoveFile:boolean){
		// const response = await api.put(`/api/file/editSource`,{sourceId:FileStorages.Db, needTransferFiles:isMoveFile});
		const response = await api.http.httpApi.file.editSource().put({ sourceId:FileStorages.Db, needTransferFiles:isMoveFile });
		eventBus.dispatchEvent(FileStoreEvents.Switch, FileStorages.Db);
	}
}

const dataBaseStore = new DataBaseStore();

export default dataBaseStore;