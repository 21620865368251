import { useCallback } from "react";
import { observer } from "mobx-react-lite";

import { InputWrapper, InputDate } from "components/input-for-section-designer";

export type Props = {
	columnName: string | undefined;
	columnTitle?: string | undefined;
	prompt?: string | undefined;
	isRequired?: boolean | undefined;
	rounding?: string | undefined;
	value: string;
	isDisabled?: boolean;
	onChange: (value: string) => void;
}

export const Date = observer(function (props: Props) {
	const handleChange = useCallback((value: string) => {
		props.onChange(value);
	}, [props]);

	return (
		<InputWrapper
			name={props.columnTitle ?? ""}
			caption={props.columnTitle ?? ""}
			required={props.isRequired}
			infoCaption={props.prompt}
		>
			<InputDate
				value={props.value}
				onChangeValue={handleChange}
				isLocked={props.isDisabled}
			/>
		</InputWrapper>
	);
});