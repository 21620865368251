import { SavingState } from "entities/Entity";
import { Lambda, action, autorun, makeObservable, observable } from "mobx";
import IFilter from "./IFilter";
import authStore from "AuthStore";
import { FilterStore, IFilterStore } from "./FilterStore";
// import m from 'app/services/api';
import { LoadingState } from "entities/ListStore";
import { api } from "api";
import { synchroiser } from "synchroiser";

export interface ISavedFilter {
	id: string | null;
	userId: string | null;
	entityName: string | null;
	filterName: string | null;
	isFavorite: boolean;
	filterInfo: IFilterStore | null;
	savedFilterFoldId: string | null;
	modifiedOn: string;
	createdOn: string;
}

export class SavedFilter implements ISavedFilter {
	id: string | null;
	userId: string | null;
	entityName: string | null;
	filterName: string | null;
	isFavorite: boolean;
	filterInfo: IFilterStore | null;
	oldFilter: IFilter | null;
	savedFilterFoldId: string | null;
	modifiedOn: string = "";
	createdOn: string = "";

	hasChanges: boolean;
	noNeedReaction: boolean = true;
	disposer?: Lambda;
	loadingState: LoadingState;
	savingState: SavingState;

	constructor(entityName: string) {
		makeObservable(this, {
			id: observable,
			userId: observable,
			entityName: observable,
			filterName: observable,
			isFavorite: observable,
			filterInfo: observable,
			oldFilter: observable,
			savedFilterFoldId: observable,
			hasChanges: observable,
			loadingState: observable,
			savingState: observable,

			cancel: action,
			setValue: action,
			deserialize: action,
		});

		this.id = null;
		this.userId = authStore.userId;
		this.filterName = null;
		this.isFavorite = false;
		this.filterInfo = null;
		this.oldFilter = null;
		this.entityName = entityName;
		this.savedFilterFoldId = null;

		this.hasChanges = false;
		this.loadingState = LoadingState.NotAsked;
		this.savingState = SavingState.NotAsked;

		this.disposer = autorun(
			() => {
				if (this.noNeedReaction) {
					this.serialize();
					this.noNeedReaction = false;
					return;
				}
				this.setValue(true, "hasChanges");
			}, {
			delay: 10
		}
		);

	}

	disposeAutorun() {
		if (this.disposer !== undefined) {
			this.disposer();
		}
		this.noNeedReaction = true;
	}

	get isLoading(): boolean {
		return this.loadingState === LoadingState.Loading;
	}

	get isLoaded(): boolean {
		return this.loadingState === LoadingState.Loaded;
	}

	get isError(): boolean {
		return this.loadingState === LoadingState.Failed;
	}

	get isSaving(): boolean {
		return this.savingState === SavingState.Saving;
	}

	get isSaved(): boolean {
		return this.savingState === SavingState.Saved;
	}

	cancel() {
		this.disposeAutorun();
		this.noNeedReaction = true;
		this.filterInfo = new FilterStore(this.entityName!, this.oldFilter, null);
		this.hasChanges = false;
		this.disposer = autorun(
			() => {
				if (this.noNeedReaction) {
					this.oldFilter = this.filterInfo ? this.filterInfo.serialize() : null;
					this.serialize();
					this.noNeedReaction = false;
					return;
				}
				this.setValue(true, "hasChanges");
			}, {
			delay: 10
		}
		);
	}

	setValue(value: any, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	async save():Promise<boolean> {
		this.disposeAutorun();
		await synchroiser.saveFilter(this);
		this.setValue(false, "hasChanges");
		this.disposer = autorun(
			() => {
				if (this.noNeedReaction) {
					this.serialize();
					this.noNeedReaction = false;
					return;
				}
				if (this.id !== null) {
					this.setValue(true, "hasChanges");
				}
			}, {
			delay: 10
		}
		);
		return true;
	}

	async update(needWorkWithFavoriteFilters:boolean):Promise<boolean> {
		this.disposeAutorun();
		await synchroiser.updateFilter(this,needWorkWithFavoriteFilters);
		this.setValue(false, "hasChanges");
		this.disposer = autorun(
			() => {
				if (this.noNeedReaction) {
					this.serialize();
					this.noNeedReaction = false;
					return;
				}
				if (this.id !== null) {
					this.setValue(true, "hasChanges");
				}
			}, {
			delay: 10
		}
		);
		return true;
	}

	async delete(): Promise<boolean> {
		this.savingState = SavingState.Saving;
		let response = await api.http.httpApi.savedFilter.filterDelete(this.id!).delete();
		if (response && response.data.success === true) {
			this.savingState = SavingState.Saved;
			this.hasChanges = false;
			return true;
		} else {
			this.savingState = SavingState.Failed;

		}
		return false;
	}


	validate() {
		if (this.userId === null) return false;
		if (this.filterName === null) return false;
		if (this.filterInfo === null) return false;
		if (this.entityName === null) return false;
		return true;
	}

	deserialize(p: any) {
		this.id = p.id ?? null;
		this.userId = p.userId;
		this.filterName = p.filterName;
		this.isFavorite = p.isFavorite;
		this.filterInfo = new FilterStore(this.entityName!, p.filterInfo, null);
		this.oldFilter = p.filterInfo;
		this.entityName = p.entityName;
		this.savedFilterFoldId = p.savedFilterFoldId;
		this.createdOn = p.createdOn;
		this.modifiedOn = p.modifiedOn;
	}

	serialize() {
		return this.id ?
			{
				id: this.id,
				userId: this.userId,
				entityName: this.entityName,
				filterName: this.filterName,
				isFavorite: this.isFavorite,
				filterInfo: (this.filterInfo instanceof FilterStore) ? this.filterInfo?.serialize() : this.filterInfo,
				savedFilterFoldId: this.savedFilterFoldId,
			}
			:
			{
				userId: this.userId,
				entityName: this.entityName,
				filterName: this.filterName,
				isFavorite: this.isFavorite,
				filterInfo: (this.filterInfo instanceof FilterStore) ? this.filterInfo?.serialize() : this.filterInfo,
				savedFilterFoldId: this.savedFilterFoldId,
			};
	}

	handleError(error: string): void {
		if (error === "name_is_empty") {
			// this.validName = false;
			// this.errorName = "";
		}
	}
}

export default SavedFilter;


