import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { action } from "mobx";
import { observer } from "mobx-react";

import { synchroiser } from "synchroiser";
import { dispatcher } from "store";

import Field from "components/field/field";
import HorizontalField from "components/field/horizontal-field";
import InputDate from "components/input/input-date";
import InputPhone from "components/input/input-phone";
import Notes from "components/notes/notes";
import Select from "components/select/select";
import Input from "components/input/input";
import SkeletonAccountFullView from "pages/accounts/accountFullView/skeleton/SkeletonAccountFullView";
import { SelectStyleName } from "components/select/select-styles";
import SearchSelect from "components/select/search-select/search-select";
import { InputStyleName } from "components/input/input-styles";

import contactEntity, { Contact } from "entities/contact/Contact";
import Entity from "entities/Entity";
import SectionRoute from "entities/SectionRoute";
import { validateEmail, validatePhoneNumber, validateRequired } from "entities/Validation";
import { NotFoundPage } from "pages/error-page/error-page";

import { Address } from "assets/icons";
import { Career } from "assets/icons";
import { CareerBody } from "assets/icons";
import { MeansOfCommunication } from "assets/icons";
import { NotesIcon } from "assets/icons";

import styles from "./ContactFullView.module.css";
import Reactor from "module/reactor/Reactor";
import FullView from "features/full-view/full-view";

const fullViewXML = `
        <Program>
            <Module>
                <Service name="main"/>
                <Style name="testStyle"/>
            </Module>

            <Render direction="column" height="100%">
                <FullView 
                    entityTitle="value:main:entityTitle" 
                    navigation="/contacts" 
                    title="name" 
                    onSave="func:main:save" 
                    onCancel="func:main:cancel"
                >
                    <Flex gap="10" direction="column" height="100%">
                        <Grid columns="1fr 0.5fr" rows="1fr" gap="10">
                            <Content>
                                <Tabs activeTab="{{value:main:tab}}" setActiveTab="func:main:onChangeTab">
                                    <Tab caption="Основная информация" name="main">
                                        <Flex direction="column" paddingRight="25" paddingLeft="25" gap="20">
                                            <BaseInfo title="name">
                                                <Field fieldName="surName"/>
                                                <Field fieldName="city"/>                                             
                                                <Field fieldName="givenName"/>
                                                <Field fieldName="gender"/>                                 
                                                <Field fieldName="middleName"/>
                                                <Field fieldName="birthDate"/>
                                            </BaseInfo>

                                            <Detail title="Средства связи"  entity="'ContactCommunication'" properties="['Value','CommunicationType']" parentEntity="value:main:entityName"/>
                                            <Detail title="Карьера"  entity="'Career'" properties="['Position']" parentEntity="value:main:entityName"/>
                                            <Detail title="Адреса"  entity="'Address'" properties="['Street','City', 'State', 'ZipCode','CreatedOn']" parentEntity="value:main:entityName"/>
                                        </Flex>
                                    </Tab>
                                    <Tab caption="Файлы" name="test">
                                        <FileUpload entitySchema="value:main:entityName" />                                       
                                    </Tab>   
                                </Tabs> 
                            </Content>

                            <Content>
                                <Additional schema="value:main:entityName"/>
                            </Content>
                        </Grid>
                    </Flex>
                </FullView>               
            </Render>

            <Script>
                (
                    class MainService{
                        value = {value:"test", displayValue:"test"};
                        tab="main";
                        entityTitle="Контакт";
                        entityName="Contact";

                        constructor(){

                        }

                        onChange(item){
                            this.value = item;
                        }

                        onChangeTab(tabName){
                            this.tab = tabName;
                        }

                        save(){
                           
                        }
                        cancel(){
                        
                        }

                    }
                )
            </Script>
        </Program>
    `;


const ContactFullView = observer(() => {
    const contact = useParams<{ id: string }>();

    useEffect(() => {
        contactEntity.load(contact.id!);
        dispatcher.currentRow.switch(contact.id!);
    }, []);

    useEffect(() => {
        synchroiser.switchSection("contacts");
    }, []);

    if (contactEntity.isLoading) {
 
        return (<SkeletonAccountFullView />);
    }

    //--------------Старый вариант:
    // function onEditClick(value: string) {
    //     setEditableProfileItem(value)
    // }
    if (!contactEntity.entity.id) return (<NotFoundPage/>);

    return (

        <FullView entity={contactEntity}
            mainInformation={
                {
                    title: contactEntity.entity.name,
                    mainInformationDataLeft: <MainInformationDataLeft entity={contactEntity} />,
                    mainInformationDataRight: <MainInformationDataRight entity={contactEntity} />
                }
            }
            mainInformationDetails={MainInformationDetails(contactEntity)}
        />
    );

    //--------------Использование реактора:
    // return (<Reactor xml={fullViewXML} />);
});

const MainInformationDetails = action((entity: Entity<Contact>) => {
    return ([
        {
            caption: "Средства связи",
            iconCaption: <MeansOfCommunication />,
            body: <MainInformationDetailBodies entity={entity} caption={"Средства связи"} />,
        },
        {
            caption: "Карьера",
            iconCaption: <Career />,
            body: entity.entity.primaryAccount ?
                <MainInformationDetailBodies entity={entity} caption={"Карьера"} />
                : null
        },
        {
            caption: "Адреса",
            iconCaption: <Address />,
            body: null,
        },
        {
            caption: "Заметки",
            iconCaption: <NotesIcon />,
            body: entity.entity.notes !== null ?
                <MainInformationDetailBodies entity={entity} caption={"Заметки"} />
                : null,
        },
    ]);
});

const MainInformationDetailBodies = observer((props: { entity: Entity<Contact>, caption: string }) => {
    switch (props.caption) {
        case ("Средства связи"):
            return (<MeansOfCommunicationDetailBody entity={props.entity} />);
        case ("Карьера"):
            return (<CareerDetailBody entity={props.entity} />);
        case ("Адреса"):
            return (null);
        case ("Заметки"):
            return (<Notes value={props.entity.entity.notes} onChangeValue={(value) => { props.entity.entity.setValue(value, "notes"); }} />);
    }
    return <></>;
});

const MeansOfCommunicationDetailBody = observer((props: { entity: Entity<Contact> }) => {
    return (
        <>
            <Field name={"phone"} caption={"Основной телефон"} className={styles.meansOfCommunicationField} isMain={true}>
                <InputPhone
                    value={props.entity.entity.phone}
                    placeholder={"+7 (123) 456-78-90"}
                    onChangeValue={(value: string) => {
                        props.entity.entity.setValue(value, "phone");
                    }}
                    inputStyle={InputStyleName.FullViewInput}
                    isInvalid={props.entity.entity.validation.phone.isInvalid}
                    invalidMessage={props.entity.entity.validation.phone.error}
                    onFocusOut={() => {
                        console.log(props.entity.entity.phone);
                        validatePhoneNumber(props.entity.entity.phone, props.entity.entity.validation.phone);
                    }}
                />
            </Field>
            <Field name={"email"} caption={"Email"} className={styles.meansOfCommunicationField} >
                <Input
                    value={props.entity.entity.email}
                    placeholder={"example@web.com"}
                    onChangeValue={(value: string) => {
                        props.entity.entity.setValue(value, "email");
                    }}
                    inputStyle={InputStyleName.FullViewInput}
                    isInvalid={props.entity.entity.validation.email.isInvalid}
                    invalidMessage={props.entity.entity.validation.email.error}
                    onFocusOut={() => validateEmail(props.entity.entity.email, props.entity.entity.validation.email)}
                />
            </Field>
        </>
    );
});

const CareerDetailBody = observer((props: { entity: Entity<Contact> }) => {
    return (
        <div className={styles.careerBody}>
            <CareerBody />
            <div className={styles.careerBodyDetail}>
                <div className={styles.careerBodyHeader}>
                    <Link to={`/${SectionRoute.Account}/${props.entity.entity.primaryAccount?.id}`} className={styles.careerBodyHeaderName}>{props.entity.entity.primaryAccount?.name}</Link>
                    {contactEntity.entity.primaryAccount?.site !== undefined && <Link to={props.entity.entity.primaryAccount!.site} className={styles.careerBodyHeaderSite}>{props.entity.entity.primaryAccount!.site}</Link>}
                </div>
                <div className={styles.careerBodyDetailBody}>
                    <Field name={"role"} caption={"Роль"} className={styles.careerBodyDetailBodyField}>
                        <Select
                            items={props.entity.lookups["ContactRole"]}
                            onChangeValue={(value) => {
                                props.entity.entity.setValue(value!, "role");
                            }}
                            isInput={true}
                            onItemsLoad={async (s) => { return await props.entity.loadLookups("ContactRole", undefined, null, true); }}
                            value={props.entity.entity.role}
                            selectStyle={SelectStyleName.BaseWithoutBorder}
                            className={styles.input}
                        />
                    </Field>
                    <Field name={"position"} caption={"Должность"} className={styles.careerBodyDetailBodyField} >
                        <Input
                            value={props.entity.entity.position}
                            placeholder={""}
                            onChangeValue={(value: string) => { props.entity.entity.setValue(value, "position"); }}
                            inputStyle={InputStyleName.FullViewInput}
                        />
                    </Field>
                </div>
            </div>
        </div>
    );
});

const MainInformationDataLeft = observer((props: { entity: Entity<Contact> }) => {
    return (
        <>
            <HorizontalField name={"surName"} caption={"Фамилия"} required={true} classNameCaption={styles.fieldCaption}>
                <Input
                    value={props.entity.entity.surName}
                    placeholder={""}
                    onChangeValue={(value: string) => {
                        props.entity.entity.setValue(value, "surName");
                    }}
                    inputStyle={InputStyleName.FullViewInput}
                    isInvalid={props.entity.entity.validation.surName.isInvalid}
                    invalidMessage={props.entity.entity.validation.surName.error}
                    onFocusOut={() => validateRequired(props.entity.entity.surName, props.entity.entity.validation.surName)}
                />
            </HorizontalField>


            <HorizontalField name={"givenName"} caption={"Имя"} required={true} classNameCaption={styles.fieldCaption}>
                <Input
                    value={props.entity.entity.givenName}
                    placeholder={""}
                    onChangeValue={(value: string) => {
                        props.entity.entity.setValue(value, "givenName");
                    }}
                    inputStyle={InputStyleName.FullViewInput}
                    isInvalid={props.entity.entity.validation.givenName.isInvalid}
                    invalidMessage={props.entity.entity.validation.givenName.error}
                    onFocusOut={() => validateRequired(props.entity.entity.givenName, props.entity.entity.validation.givenName)}
                />
            </HorizontalField>
            <HorizontalField name={"middleName"} caption={"Отчество"} classNameCaption={styles.fieldCaption}>
                <Input
                    value={props.entity.entity.middleName}
                    placeholder={""}
                    onChangeValue={(value: string) => {
                        props.entity.entity.setValue(value, "middleName");
                    }}
                    inputStyle={InputStyleName.FullViewInput}
                />
            </HorizontalField>
        </>
    );
});

const MainInformationDataRight = observer((props: { entity: Entity<Contact> }) => {
    return (
        <>
            <HorizontalField name={"city"} caption={"Город"} required={true} classNameCaption={styles.fieldCaption}>
                <SearchSelect
                    items={props.entity.lookups["City"]}
                    onChangeValue={(value) => {
                        props.entity.entity.setValue(value!, "city");
                        validateRequired(value, props.entity.entity.validation.city);
                    }}
                    isInput={true}
                    onItemsLoad={async (s) => { return await props.entity.loadLookups("City", s); }}
                    value={props.entity.entity.city}
                    selectStyle={SelectStyleName.BaseWithoutBorder}
                    className={styles.fullViewBasicInformationDataInput}
                    classNameInput={styles.inputForSelect}
                />
            </HorizontalField>

            <HorizontalField name={"gender"} caption={"Пол"} classNameCaption={styles.fieldCaption}>
                <Select
                    items={props.entity.lookups["Gender"]}
                    onChangeValue={(value) => {
                        props.entity.entity.setValue(value!, "gender");
                    }}
                    isInput={true}
                    selectStyle={SelectStyleName.BaseWithoutBorder}
                    onItemsLoad={async (s) => { return await props.entity.loadLookups("Gender", s); }}
                    value={props.entity.entity.gender}
                    className={styles.fullViewBasicInformationDataInput + " " + styles.input}
                />
            </HorizontalField>
            <HorizontalField name={"birthDate"} caption={"Дата рождения"} classNameCaption={styles.fieldCaption}>
                <InputDate
                    value={props.entity.entity.birthDate}
                    placeholder={""}
                    onChangeValue={(value: string) => {
                        props.entity.entity.setValue(value, "birthDate");
                    }}
                    className={styles.fullViewBasicInformationDataInput + " " + styles.input}
                />
            </HorizontalField>
        </>
    );
});


export default ContactFullView;