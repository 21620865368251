import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import styles from './Input.module.css';

import { Warning } from "assets/icons";
import { Success } from "assets/icons";
import { Locked } from "assets/icons";
import { EyeNotHidden } from "assets/icons";
import { EyeHidden } from "assets/icons";
import { Lightning } from "assets/icons";
import IProps from '../../IProps';
import { binding, bindingInXml } from 'module/reactor/binding/binding';

export interface IInputProps extends IProps {
    onChangeValue?:string;
    inputType?:string;
    placeholder?:string;
    isDisabled?:boolean;
    handleFocus?:string;
    isInvalid?:string;
    invalidMessage?:string;
    text?:string;
}

function Input(props: IInputProps) {

    const handleChange = binding(props.services, props.onChangeValue);
    const placeholder = bindingInXml(props.services, props.placeholder);
    const handleFocus = binding(props.services, props.handleFocus);
    const isInvalid = binding(props.services, props.isInvalid);
    const invalidMessage = bindingInXml(props.services, props.invalidMessage);
    const text = bindingInXml(props.services, props.text);

    function onChange(value:ChangeEvent<HTMLInputElement>){
        handleChange(value.target.value);
    }

    return (
        <div className={styles.parentInput + " " + props.className}>
            <div className={`${styles.input} ${styles.baseInput}`}>
                <input
                    type={props.inputType}
                    className={styles.baseInputText}
                    value={text}
                    onChange={onChange}
                    placeholder={placeholder}
                    disabled={props.isDisabled}
                    onFocus={handleFocus}
                />
                {isInvalid && <div className={styles.invalid}>{invalidMessage}</div>}
            </div>
        </div>
    );
}

export default Input;
