import { makeAutoObservable } from "mobx";
import { v4 } from "uuid";
import { api } from "api";
import { isEmpty } from "lodash";

import { dispatcher } from "store";
import { validateRequired, validateSchema } from "entities/Validation";

import { ILookup } from "entities/Entity";
import { ILookupData } from "pages/settings/data/Fields";
import { ColumnType } from "entities/ColumnType";
import { VirtualLookup } from "./types";
import {
	ColumnSpecializationType, EntityColumnSpecialization, EntityNameType,
	FieldConfig, GridItem, ItemType, Properties
} from "types/entity";
import {
	DEFAULT_ERROR_VALUE, ERROR_LOOKUP_EXIST_NAME, ERROR_LOOKUP_EXIST_TITLE,
	ERROR_VALUE_EXIST_NAME, ERROR_VALUE_EXIST_TITLE, LookupType, ValidationState
} from "pages/section-wizzard/data/data";
import { HIDE_ELEMENT_POSITION } from "../../../constants";
import IFilter from "entities/filter/IFilter";
import { synchroiser } from "synchroiser";

export enum FieldStoreEnums {
	columnId = "columnId",
	fieldType = "fieldType",
	defaultValue = "defaultValue",
	systemName = "systemName",
	title = "title",
	hasIndex = "hasIndex",
	isRequired = "isRequired",
	isSetDefaultData = "isSetDefaultData",
	prompt = "prompt",
	rounding = "rounding",
	lookupType = "lookupType",
	selectedLookupDefaultValue = "selectedLookupDefaultValue",
	selectedLookup = "selectedLookup",
	specializations = "specializations",
	virtualLookupValues = "virtualLookupValues"
}
const initialVirtualLookup = {
	entityTitle: "",
	systemName: "",
	columnInfo: [{
		columnId: v4(),
		columnName: "Name",
		columnTitle: "Название",
		columnType: ColumnType.String,
		isLookup: false,
		isLink: false,
		lookupTable: null,
		isRequired: true,
		hasIndex: false,
		specializations: null

	}],
	isLookup: true,
	virtualLookupValues: []
};
class FieldConfigurationStore {
	fieldIsLoading: boolean = false;
	hasChanges: boolean = false;

	columnId: string = "";
	fieldType: ColumnType = ColumnType.String;
	defaultValue: any = "";

	x: number = HIDE_ELEMENT_POSITION;
	y: number = HIDE_ELEMENT_POSITION;

	systemName: string = "";
	title: string = "";
	hasIndex: boolean = false;
	isRequired: boolean = false;
	isSetDefaultData: boolean = false;
	prompt: string = "";
	specializations: EntityColumnSpecialization = { tag: ColumnSpecializationType.Double, properties: {} };

	rounding: string = "";

	validation: { [key: string]: ValidationState };

	lookupType: LookupType | string = "";

	//Настройки выпадающих списоков
	lookups: ILookup[] = [];
	selectedLookup: ILookup | null = null;

	selectedLookupData: ILookup[] = [];
	selectedLookupDefaultValue: ILookup | null = null;

	//Виртуальные справочники
	virtualLookup: VirtualLookup;

	constructor() {
		makeAutoObservable(this);
		this.virtualLookup = initialVirtualLookup;
		this.validation = {
			title: {
				isInvalid: false,
				isNotUnique: false,
				error: ""
			},
			systemName: {
				isInvalid: false,
				error: ""
			},

			lookup: {
				isInvalid: false,
				error: ""
			},
			virtualLookupSystemName: {
				isInvalid: false,
				error: ""
			},
			virtualLookupTitle: {
				isInvalid: false,
				isNotUnique: false,
				error: ""
			},

			defaultValue: {
				isInvalid: false,
				error: ""
			},
			rounding: {
				isInvalid: false,
				error: ""
			},
		};
	}

	saveConfiguration(value: { targetZone?: string | null, columnId: string }) {
		const currentTabIndex = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;

		const groupField = dispatcher.sectionWizzard.getSectionWizzard()?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items.find((item) => item.gridItemId === value.targetZone);

		const fieldConfig: FieldConfig = {
			columnId: this.columnId,
			columnName: this.systemName,
			columnType: this.fieldType,
			columnTitle: this.title,
			defaultValue: this.defaultValue,
			rounding: this.rounding,
			prompt: this.prompt,
			specializations: this.specializations,
			virtualLookup: this.lookupType === LookupType.NewLookup ? this.virtualLookup : null,
			lookupTable: this.lookupType === LookupType.NewLookup ? this.virtualLookup.systemName : this.selectedLookup?.name!,
			isLookup: this.fieldType === ColumnType.Lookup,
			hasIndex: this.hasIndex,
			isRequired: this.isRequired,
		};

		if (groupField?.groupFieldsConfig?.inner?.items) {
			const item = groupField.groupFieldsConfig.inner.items.find((innerItem) => innerItem?.fieldConfig?.columnId === value.columnId);
			if (item?.fieldConfig) {
				dispatcher.sectionWizzard.getAllGridItems().forEach(equalItems => {
					if (equalItems?.fieldConfig?.columnId === value.columnId) {
						equalItems.fieldConfig = fieldConfig;

					}
					return;
				});
			}
		}

		const itemsWidthEqualColumnId = dispatcher.sectionWizzard.getAllGridItems().filter((item) => item.fieldConfig?.columnId === value.columnId);
		itemsWidthEqualColumnId?.forEach((item) => {
			const target = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items.find((checkElem) => checkElem?.groupFieldsConfig?.inner?.items.find(innerItem => innerItem.gridItemId === item.gridItemId) || checkElem.gridItemId === item.gridItemId);
			if (target?.fieldConfig) {
				target.fieldConfig.columnTitle = this.title;
				target.fieldConfig.columnName = this.systemName;
			} else if (target?.groupFieldsConfig?.inner) {
				const innerTarget = target.groupFieldsConfig.inner.items.find((innerItem) => innerItem.gridItemId === item.gridItemId);
				if (innerTarget?.fieldConfig) {
					innerTarget.fieldConfig = fieldConfig;
				}
			}
		});

		const findItemInField = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex]
			.grid.items.find(item => item.fieldConfig?.columnId === value.columnId);
		const findGroup = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex]
			.grid.items.find(item => item.groupFieldsConfig?.groupFieldId === value.columnId);

		let flag = false;

		if (findItemInField) {
			findItemInField.fieldConfig = fieldConfig;
		} else if (findGroup) {
			const innerItems = findGroup.groupFieldsConfig?.inner?.items;
			if (innerItems) {
				for (let indexItem = 0; indexItem < innerItems?.length; indexItem++) {
					if (innerItems[indexItem].fieldConfig?.columnId === fieldConfig.columnId) {
						innerItems[indexItem].fieldConfig = fieldConfig;
						flag = true;
					}
				}
			}
		}
		if (!flag) {
			const item: GridItem = {
				x: this.x,
				y: this.y,
				gridItemId: v4(),
				width: 1,
				height: 1,
				type: ItemType.Field,
				fieldConfig: fieldConfig
			};
			const result = dispatcher.sectionWizzard.createNewGridItemInGroup(value.targetZone ?? "", currentTabIndex, item);
			if (!result) {
				dispatcher.sectionWizzard.createNewGridItemInTab(item, currentTabIndex);
			}
			this.resetConfiguration();
		}
	}

	setValue(fieldName: string, value: string | boolean | null | number | ILookup[]) {
		this.hasChanges = true;
		Reflect.set(this, fieldName, value);
	}

	setValueWithoutTrackingChanges(fieldName: string, value: string | boolean | null | number) {
		Reflect.set(this, fieldName, value);
	}

	setSpecialization(fieldName: string, tag: ColumnSpecializationType, properties: Properties) {
		this.hasChanges = true;
		Reflect.set(this, fieldName, { tag, properties });
	}

	isValidDate(date: string) {
		return !isNaN(Date.parse(date));
	}

	isValidGuidValue(value: string): boolean {
		const guidPattern =
			/^[{]?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}[}]?$/;
		return guidPattern.test(value);
	}

	isConfigurationValid(): boolean {
		const isTitleValid = !this.validation.title.isInvalid && this.title.length !== 0;
		const isSystemNameValid =
			!this.validation.systemName.isInvalid && this.systemName.length !== 0;

		if (this.fieldType === ColumnType.Decimal) {
			const roundingValid =
				!this.validation.rounding.isInvalid && this.rounding.length !== 0;
			return (
				isTitleValid && isSystemNameValid && this.validation.defaultValue && roundingValid
			);
		}

		if (this.fieldType === ColumnType.Lookup) {
			const lookupValid = !this.validation.lookup.isInvalid;

			if (this.lookupType === LookupType.ExistLookup) {
				return (
					isTitleValid &&
					isSystemNameValid &&
					this.validation.defaultValue &&
					lookupValid &&
					this.selectedLookup !== null
				);
			}
			else if (this.lookupType === LookupType.NewLookup) {
				const virtualLookupValid = !this.validation.virtualLookupSystemName.isInvalid
					&& !this.validation.virtualLookupTitle.isInvalid;
				return (
					isTitleValid &&
					isSystemNameValid &&
					this.validation.defaultValue &&
					virtualLookupValid
				);
			}

		}
		return isTitleValid && isSystemNameValid && !this.validation.defaultValue.isInvalid;
	}

	validateTitle() {
		const isNotUnique = dispatcher.sectionWizzard.checkExistFieldTitle(this.title);
		this.validation.title.isNotUnique = isNotUnique;
		if (isNotUnique) {
			this.validation.title.isInvalid = false;
			this.validation.title.error = ERROR_VALUE_EXIST_TITLE;
		}
		else {
			validateRequired(this.title, this.validation.title);
		}
	}

	validateSystemName() {
		const isNotUnique = dispatcher.sectionWizzard.checkExistFieldName(this.systemName);
		if (isNotUnique) {
			this.validation.systemName.isInvalid = isNotUnique;
			this.validation.systemName.error = ERROR_VALUE_EXIST_NAME;
		}
		else if (validateSchema(this.systemName, this.validation.systemName)) {
			this.validation.systemName.isInvalid = false;
			this.validation.systemName.error = "";
		}
	}

	async validateNewLookupTitle() {
		if (this.lookupType === LookupType.NewLookup) {
			const isNotUnique = await synchroiser.checkExistEntityTitle(this.virtualLookup.entityTitle, EntityNameType.Lookups)
			this.validation.virtualLookupTitle.isNotUnique = isNotUnique;
			if (isNotUnique) {
				this.validation.virtualLookupTitle.isInvalid = false;
				this.validation.virtualLookupTitle.error = ERROR_LOOKUP_EXIST_TITLE;
			}
			else {
				validateRequired(this.virtualLookup.entityTitle, this.validation.virtualLookupTitle);
			}
		}
		else {
			this.validation.virtualLookupTitle.isNotUnique = false;
			validateRequired(this.virtualLookup.entityTitle, this.validation.virtualLookupTitle);
		}
	}

	async validateNewLookupName() {
		if (this.lookupType === LookupType.NewLookup) {
			const isNotUnique = await synchroiser.checkExistEntityName(this.virtualLookup.systemName);
			if (isNotUnique) {
				this.validation.virtualLookupSystemName.isInvalid = isNotUnique;
				this.validation.virtualLookupSystemName.error = ERROR_LOOKUP_EXIST_NAME;
			}
			else if (validateSchema(this.virtualLookup.systemName, this.validation.virtualLookupSystemName)) {
				this.validation.systemName.isInvalid = false;
				this.validation.systemName.error = "";
			}
		}
		else {
			validateSchema(this.virtualLookup.systemName, this.validation.virtualLookupSystemName)
		}
	}

	setInvalidDefaultValue(value: boolean) {
		if (value) {
			this.validation.defaultValue.isInvalid = value;
			this.validation.defaultValue.error = DEFAULT_ERROR_VALUE;
		} else {
			this.validation.defaultValue.isInvalid = false;
			this.validation.defaultValue.error = "";
		}
	}

	validateDefaultValue() {
		switch (this.fieldType) {
			case ColumnType.String:
				if (typeof this.defaultValue !== "string") {
					this.setInvalidDefaultValue(true);
				} else {
					this.setInvalidDefaultValue(false);
				}
				break;
			case ColumnType.Integer:
				const isPureNumber = /^\d+$/.test(this.defaultValue);
				if (isPureNumber || isEmpty(this.defaultValue)) {
					this.setInvalidDefaultValue(false);
				}
				else {
					this.setInvalidDefaultValue(true);
				}
				break;
			case ColumnType.Decimal:
				const validDfaultValue =
					/^-?\d+(\.\d+)?$/.test(this.defaultValue) || this.defaultValue === "";
				if (validDfaultValue) {
					this.setInvalidDefaultValue(false);
				}
				else {
					this.setInvalidDefaultValue(true);
				}

				break;
			case ColumnType.Lookup:
				if (!this.isValidGuidValue(this.defaultValue)) {
					this.setInvalidDefaultValue(false);
				}
				else {
					this.setInvalidDefaultValue(true);
				}
				break;
			case ColumnType.DateTime:
				if (!this.isValidDate(this.defaultValue)) {
					this.setInvalidDefaultValue(false);
				}
				else {
					this.setInvalidDefaultValue(true);
				}
				break;
			case ColumnType.Boolean:
				if (
					this.defaultValue === "true" ||
					this.defaultValue === "false" ||
					this.defaultValue === ""
				) {
					this.setInvalidDefaultValue(false);
				} else {
					this.setInvalidDefaultValue(true);
				}
				break;
			default:
				this.setInvalidDefaultValue(true);
		}
	}

	convertToLookupArray(dataArray: ILookupData[]): ILookup[] {
		return dataArray.map((dataItem) => ({
			id: dataItem.id,
			name: dataItem.entityName,
			title: dataItem.entityTitle,
			isLookup: dataItem.isLookup,
			isSection: dataItem.isSection
		}));
	}
	convertToLookupDataArray(dataArray: any): ILookup[] {
		return dataArray.map((dataItem: any) => ({
			id: dataItem.id,
			name: dataItem.name,
			title: dataItem.displayValue ?? dataItem.name,
		}));
	}

	setLookups(data: ILookupData[]) {
		let convertedLookups = this.convertToLookupArray(data);
		this.lookups = convertedLookups;
	}

	/**
	 * Метод подгружает справочники и разделы для источника данных у справочного поля
	 */
	async loadLookups() {
		let req = await api.http.httpApi.entity.entitySectionAndLookupDataList().get();
		this.setLookups(req?.data.data);
		return req?.data.data
	}

	setLookupData(data: any) {
		let convertedData = this.convertToLookupDataArray(data);
		this.selectedLookupData = convertedData;
	}

	/**
	 * Метод подгружает значение справочника для того что отобразить в выпадающем списке
	 * @param lookupName Системное название справочника
	 */
	async loadLookupData(lookupName: string, filter: IFilter | null) {
		let req = await api.http.httpApi.entity.recordsListWithColumns().post({
			entityName: lookupName,
			columnNames: [],
			filter: filter
		});
		this.setLookupData(req?.data.data.records);
		return req?.data.data.records
	}

	async getConfigurationById(columnId: string) {
		this.fieldIsLoading = true;
		const currentTabIndex = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
		const column = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items
			.find(item => item.fieldConfig?.columnId === columnId);


		if (column && column.fieldConfig) {
			this.columnId = columnId;
			this.systemName = column.fieldConfig.columnName ?? "";
			this.fieldType = column.fieldConfig.columnType as ColumnType;
			this.title = column.fieldConfig.columnTitle ?? "";
			this.hasIndex = column.fieldConfig.hasIndex ?? "";
			this.isRequired = column.fieldConfig.isRequired ?? "";
			this.defaultValue = column.fieldConfig.defaultValue;
			this.rounding = column.fieldConfig.rounding ?? "";
			this.prompt = column.fieldConfig.prompt ?? "";
			this.specializations = column.fieldConfig.specializations ?? { tag: ColumnSpecializationType.Double, properties: {} };

			if (this.fieldType === ColumnType.Lookup) {
				const isVirtualLookup = column.fieldConfig.virtualLookup !== null;

				await this.loadLookups();
				if (!isVirtualLookup) {
					await this.loadLookupData(column.fieldConfig?.lookupTable!, null);
				}

				this.selectedLookup = this.lookups.find(lookup => lookup.name === column.fieldConfig?.lookupTable) ?? null;
				this.virtualLookup = column.fieldConfig.virtualLookup!;
				this.selectedLookupDefaultValue = this.getSelectedLookupData(isVirtualLookup);
				this.lookupType = isVirtualLookup ? LookupType.NewLookup : "";
			}
		} else {
			let innerColumn: GridItem = {
				x: 0,
				y: 0,
				gridItemId: "",
				width: 0,
				height: 0
			};

			dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items
				.forEach(groupField => groupField.groupFieldsConfig?.inner?.items
					.forEach((item) => {
						if (item.fieldConfig?.columnId === columnId) {
							innerColumn = item;
						}
					})
				);

			if (innerColumn.gridItemId.length > 0 && innerColumn.fieldConfig) {
				this.columnId = columnId;
				this.systemName = innerColumn.fieldConfig.columnName;
				this.fieldType = innerColumn.fieldConfig.columnType as ColumnType;
				this.title = innerColumn.fieldConfig.columnTitle;
				this.hasIndex = innerColumn.fieldConfig.hasIndex;
				this.isRequired = innerColumn.fieldConfig.isRequired;
				this.defaultValue = innerColumn.fieldConfig.defaultValue;
				this.rounding = innerColumn.fieldConfig.rounding ?? "";
				this.prompt = innerColumn.fieldConfig.prompt ?? "";
				this.specializations = innerColumn.fieldConfig.specializations ?? { tag: ColumnSpecializationType.Double, properties: {} };


				if (innerColumn?.fieldConfig && this.fieldType === ColumnType.Lookup) {
					const isVirtualLookup = innerColumn.fieldConfig.virtualLookup !== null;
					await this.loadLookups();
					if (!isVirtualLookup) {
						await this.loadLookupData(innerColumn.fieldConfig?.lookupTable!, null);
					}

					this.selectedLookup = this.lookups.find(lookup => lookup.name === innerColumn.fieldConfig?.lookupTable) ?? null;
					this.virtualLookup = innerColumn.fieldConfig.virtualLookup!;
					this.selectedLookupDefaultValue = this.getSelectedLookupData(isVirtualLookup);
					this.lookupType = isVirtualLookup ? LookupType.NewLookup : "";
				}
			}
		}

		if (column) {
			this.hasChanges = false;
		}
		this.fieldIsLoading = false;
	}
	/**
	 * Метод предназначен для того чтобы получить значение по умолчанию для справочного поля буд то по существующему или по новому справочнику.
	 */
	getSelectedLookupData(isVirtualLookup: boolean): ILookup | null {
		const selectedData = isVirtualLookup
			? this.virtualLookup.virtualLookupValues.find(item => item.id === this.defaultValue)
			: this.selectedLookupData.find(item => item.id === this.defaultValue);
		return selectedData || null;
	}

	resetConfiguration() {
		this.hasChanges = false;
		this.fieldType = ColumnType.String;
		this.defaultValue = "";
		this.systemName = "";
		this.title = "";
		this.hasIndex = false;
		this.isRequired = false;
		this.isSetDefaultData = false;
		this.prompt = "";
		this.lookupType = "";
		this.rounding = "";

		this.validation.defaultValue.isInvalid = false;
		this.validation.defaultValue.error = "";

		this.selectedLookup = null;
		this.selectedLookupData = [];
		this.selectedLookupDefaultValue = null;

		this.virtualLookup = initialVirtualLookup;

		for (const key in this.validation) {
			this.validation[key].isInvalid = false;
			this.validation[key].isNotUnique = false;
			this.validation[key].error = "";
		}
	}
}

const fieldConfigurationStore = new FieldConfigurationStore();

export default fieldConfigurationStore;
