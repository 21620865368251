import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";

import { Button, ButtonStyle, SettingsSelect } from "components";

import { KanbanSettings, Menu, MenuHeaders } from "./data/data";
import { Item } from "types";

import { DeveloperModeIcon, HideIcon, OpenIcon } from "assets/icons";

import styles from "./section-wizzard-left-panel.module.css";

interface ISectionWizzardLeftPanelProps {
	sectionSettingsItems: Item[],
	stagesConstructorItems: Item[],
	selected?: string | null,
	onChangeValue: (item: Item | null) => void,
	useLocalStorage?: boolean,
	localStorageKey?: string,
}
enum MenuState {
	open,
	hide
}

export const SectionWizzardLeftPanel = observer((props: ISectionWizzardLeftPanelProps) => {
	const [menuState, setMenuState] = useState(MenuState.hide);

	const leftPanelClasses = classNames(`${styles.sectionLeft} `, {
		[`${styles.open} `]: menuState === MenuState.open,
	});
	const hideButtonClasses = classNames(`${styles.hideButton} `, {
		[`${styles.openButton} `]: menuState === MenuState.open,
	});

	useEffect(() => {
		if (props.useLocalStorage && props.localStorageKey) {
			setMenuState(Number(localStorage.getItem(props.localStorageKey)));
		}
	}, []);

	const onClickHideOrOpenMenu = useCallback(() => {
		const newValue = menuState === MenuState.open ? MenuState.hide : MenuState.open;
		setMenuState(newValue);
		if (props.useLocalStorage && props.localStorageKey) {
			if (localStorage.getItem(props.localStorageKey))
				localStorage.removeItem(props.localStorageKey);
			localStorage.setItem(props.localStorageKey, newValue as unknown as string);
		}
	}, [menuState, props]);

	const hideButtonIcon = useMemo(() => {
		return menuState === MenuState.open ? <HideIcon /> : <OpenIcon />;
	}, [menuState]);

	const menu = useMemo(() => {
		return menuState === MenuState.open &&
			<>
				<SettingsSelect /* Настройка раздела */
					name={Menu.find(item => item.id === MenuHeaders.SectionSettings)!.name}
					items={props.sectionSettingsItems}
					onChangeValue={props.onChangeValue}
					selected={props.selected}
					styles={ButtonStyle.Settings}
					classNameButton={styles.headSelect}
					className={styles.settingsSelect}
				/>

				<SettingsSelect /* Конструктор стадий */
					name={Menu.find(item => item.id === MenuHeaders.StagesConstructor)!.name}
					items={props.stagesConstructorItems}
					onChangeValue={props.onChangeValue}
					selected={props.selected}
					styles={ButtonStyle.Settings}
					classNameButton={styles.headSelect}
					className={styles.settingsSelect}
				/>
				<SettingsSelect /* Настройки доски kanban */
					name={Menu.find(item => item.id === MenuHeaders.KanbanSettings)!.name}
					items={KanbanSettings}
					onChangeValue={props.onChangeValue}
					selected={props.selected}
					styles={ButtonStyle.Settings}
					classNameButton={styles.headSelect}
					className={styles.settingsSelect}
				/>
				<Button
					caption={"Режим разработчика"}
					firstIcon={<DeveloperModeIcon />}
					className={styles.devButton}
					classNameCaption={styles.devButtonCaption}
					classNameContent={styles.devButtonContent}
				/>
				<span className={styles.devInfo}>Перейти в режим разработчика</span>
			</>;
	}, [menuState, props, props.sectionSettingsItems, props.stagesConstructorItems]);

	return (
		<div className={leftPanelClasses}>
			<Button
				firstIcon={hideButtonIcon}
				className={hideButtonClasses}
				onClick={onClickHideOrOpenMenu}
			/>
			{menu}
		</div >
	);
});
