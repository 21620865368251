import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { InfoIcon, MainStar } from "assets/icons";

import styles from "./field.module.css";

interface IFieldProps extends React.HTMLAttributes<HTMLElement> {
    name: string;
    caption: string;
    captionLink?: string;
    required?: boolean;
    isMain?: boolean;
    classNameCaption?: string;
    promptText?: string;
}

function Field(props: IFieldProps) {
    const wrapperClasses = classNames({
        [`${styles.field} `]: true,
        [`${props.className}`]: props.className,
    });

    const captionClasses = classNames({
        [`${styles.captionText} `]: true,
        [`${props.classNameCaption}`]: props.classNameCaption,
        [`${styles.link}`]: props.captionLink,
    });

    return (
        <div className={wrapperClasses} style={props.style}>
            <label className={styles.caption} htmlFor={props.name}>
                {props.captionLink ? (
                    <Link to={props.captionLink} className={captionClasses}>
                        {props.caption} {props.required && <span className={styles.star}>*</span>}
                    </Link>
                ) : (
                    <span className={styles.captionText}>
                        {props.caption}
                        {props.required && <span className={styles.star}>*</span>}
                    </span>
                )}
                {props.isMain && <MainStar />}

                {props.promptText &&
                    <div className={styles.promptTool} >
                        <span className={styles.tooltip}>
                            <span> {props.promptText} </span>
                        </span>
                        <InfoIcon className={styles.promtIcon} />
                    </div>
                }
            </label>
            {props.children}
        </div>
    );
}

export default Field;
