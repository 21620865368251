import { ComparisonType, DataValueType } from "entities/filter/IFilter";
import { Item } from "types";

type IComparisonsGroup = {
    [key: number]: Item[];
};

const ComparisonsGroup: IComparisonsGroup = {
    [DataValueType.Text]: [
        { id: ComparisonType.Contain, name: "Содержит" },
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.StartWith, name: "Начинается с" },
    ],
    [DataValueType.Integer]: [
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.Greater, name: "Больше" },
        { id: ComparisonType.Less, name: "Меньше" },
        { id: ComparisonType.GreaterOrEqual, name: "Больше или равно" },
        { id: ComparisonType.LessOrEqual, name: "Меньше или равно" },
        { id: ComparisonType.Between, name: "Между" },
    ],
    [DataValueType.Float]: [
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.Greater, name: "Больше" },
        { id: ComparisonType.Less, name: "Меньше" },
        { id: ComparisonType.GreaterOrEqual, name: "Больше или равно" },
        { id: ComparisonType.LessOrEqual, name: "Меньше или равно" },
        { id: ComparisonType.Between, name: "Между" },
    ],
    [DataValueType.Long]: [
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.Greater, name: "Больше" },
        { id: ComparisonType.Less, name: "Меньше" },
        { id: ComparisonType.GreaterOrEqual, name: "Больше или равно" },
        { id: ComparisonType.LessOrEqual, name: "Меньше или равно" },
        { id: ComparisonType.Between, name: "Между" },
    ],
    [DataValueType.Decimal]: [
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.Greater, name: "Больше" },
        { id: ComparisonType.Less, name: "Меньше" },
        { id: ComparisonType.GreaterOrEqual, name: "Больше или равно" },
        { id: ComparisonType.LessOrEqual, name: "Меньше или равно" },
        { id: ComparisonType.Between, name: "Между" },
    ],
    [DataValueType.DateTime]: [
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.Between, name: "Между" },
    ],
    [DataValueType.Boolean]: [
        { id: ComparisonType.Equal, name: "Равно" },
    ],
    [DataValueType.Lookup]: [
        { id: ComparisonType.Equal, name: "Равно" }
    ],
    [DataValueType.Guid]: [
        { id: ComparisonType.Equal, name: "Равно" },
    ],
    [DataValueType.Time]: [
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.Between, name: "Между"},
    ],
    [DataValueType.Date]: [
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.Between, name: "Между"}
    ]
}

export default ComparisonsGroup;