
import { action, makeObservable, observable } from 'mobx';

enum UserRoleType {
    OrganizationRole = 0,
    FunctionalRole = 1
}
export interface IData {
    id: string,
    parentId: string,
    name: string,
    roleType: UserRoleType,
    hasLeadRole: boolean,
    leadRole: IData,
    leadRoleId: string,
}


export class UserRole {
    data: IData | null;
    children: UserRole[];

    constructor() {

        makeObservable(this, {
            data: observable,
            children: observable,
            setValue: action,
            deserialize: action,
        })

        this.data = null;
        this.children = [];
    }

    setValue(value: any, fieldName: string) {
        Reflect.set(this, fieldName, value);
    }

    deserialize(p: any) {
        this.data = p.data;
        this.children = p.children;
    }


}


const userRole = new UserRole();

export default UserRole;