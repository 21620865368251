import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import classNames from "classnames";

import { dispatcher } from "store";

import { modalController, Position } from "features/modals";
import { ModalType } from "features/modals/viewer/modal-viewer";

import { EyeHidden, EyeNotHidden } from "assets/icons";

import { StageFullInfo } from "types/entity";

import styles from './header-group.module.scss';
import { Tooltip } from "components";

interface StageTypeHeaderProps {
    stages: Array<StageFullInfo>;
    title: string;
    hiddenCount: number;
    isSingleGroup: boolean;
}

function Item(props: {
    hidden: boolean,
    name: string,
    id: string
    disable: boolean;
}) {


    const handleClick = useCallback(() => {
        if (!props.hidden && props.disable) {
            return;
        }

        if (!props.hidden) {
            modalController.notificationAdd({
                id: v4(),
                type: ModalType.NOTIFICATION,
                position: Position.CENTER,
                layout: <div>{`Стадия «${props.name}» скрыта`}</div>,
                allowTimer: true,
                allowDefaultClick: true,
                withBackdrop: false,
            })
        } else {
            modalController.notificationAdd({
                id: v4(),
                type: ModalType.NOTIFICATION,
                position: Position.CENTER,
                layout: <div>{`Стадия «${props.name}» отображена`}</div>,
                allowTimer: true,
                allowDefaultClick: true,
                withBackdrop: false,
            })
        }
        props.hidden ? dispatcher.stageModel.viewStage(props.id) : dispatcher.stageModel.hideStage(props.id)
    }, [props.hidden, props.disable, props.name, props.id])

    return (
        <div onClick={handleClick} className={classNames(styles.dropDownItem, { [`${styles.disable}`]: props.disable })}>
            <div className={styles.itemName}>{props.name}</div>
            {props.hidden ? <EyeHidden /> : <EyeNotHidden />}
        </div>
    )
}

export function HeaderGroup(props: StageTypeHeaderProps) {
    const [isOpen, setIsOpen] = useState(false);


    useEffect(() => {
        window.document.body.addEventListener("click", (event) => {
            setIsOpen(false)
        })
    }, []);

    const stages = useMemo(() =>
        props.stages.map((stage) => <Item
            disable={props.isSingleGroup && !stage.isHidden && (props.stages.filter((stage) => !stage.isHidden).length === 1)}
            hidden={stage.isHidden}
            name={stage.name}
            id={stage.id}
        />)
        , [props.stages, props.isSingleGroup])

    const hiddenButtonClasses = useMemo(() => classNames(styles.openButton, isOpen ? styles.enable : styles.disable), [isOpen])

    return (
        <div onClick={(event) => {
            event.stopPropagation()
        }}>
            <div className={styles.container}>
                <span className={styles.header}>{props.title}</span>
                <Tooltip tooltip="Скрыть стадии">
                    <div className={hiddenButtonClasses}
                        onClick={(event) => {
                            setIsOpen(!isOpen)
                        }}>
                        <div className={styles.iconWrapper}><EyeHidden /></div>
                        <div className={styles.counter}>({props.hiddenCount})</div>
                    </div>
                </Tooltip>
                <div className={styles.positioner}>
                    {isOpen &&
                        <div className={styles.dropDownContainer}>
                            {stages}
                        </div>
                    }
                </div>

            </div>
            <div className={styles.divider}></div>
        </div>
    )
}