import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import { Button, ButtonStyle } from "components";

import SelectMiniStyles, { ISelectStyles, SelectMiniStyleName } from "../core/styles-system";
import { IItem } from "entities/IItem";

import { ArrowToDown } from "assets/icons";

import styles from "./select-mini.module.css";
import stylesBase from "../../select.module.css";


interface SelectMiniProps {
    items: IItem[] | undefined;
    value: IItem;
    onChange: (selectedItem: IItem) => void;
    onItemsLoad?: () => void;
    className?: string;
    isInvalid?: boolean;
    styles?: SelectMiniStyleName;
    renderFunction?: (value: IItem) => JSX.Element;
    placeholder?: string;
}

function defaultRender(value: IItem): JSX.Element {
    return <span className={styles.displayValue}>{value.displayValue}</span>
}

function SelectMini(props: SelectMiniProps) {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [showItems, setShowItems] = useState(false);

    const dropdownStyles: ISelectStyles = SelectMiniStyles[props.styles ?? SelectMiniStyleName.Primary];
    const renderFunction = props.renderFunction ?? defaultRender;


    let classNames = `${stylesBase.wrapper} ${props.className} `;
    let listClassNames = `${stylesBase.list} ${styles.list} `;
    let inputClassNames = dropdownStyles.input.classNames;
    let listItemClassNames = `${stylesBase.listItem} `;

    if (props.items && props.items!.length > 0 && showItems) {
        listClassNames += " " + stylesBase.visible;
    }

    if (showItems) {
        inputClassNames += dropdownStyles.input.focus;
    }

    function hideMenu() {
        setShowItems(false);
        document.removeEventListener("click", handleClick);
    }

    function handleClick(event: Event) {
        if (wrapperRef.current != null && !wrapperRef.current.contains(event.target as Node)) {
            hideMenu();
        }
    }

    const handleItemClick = (selectedItem: IItem) => {
        setShowItems(false);
        props.onChange(selectedItem);
    };

    function onSelectOpen() {
        if (props.onItemsLoad) props.onItemsLoad();
        setShowItems(true);
        document.addEventListener("click", handleClick);
    }

    const onButtonClick = () => {
        if (showItems) {
            hideMenu();
        }
        else {
            onSelectOpen();
        }
    }

    return (
        <div ref={wrapperRef} className={classNames} onClick={onButtonClick}>
            <div className={inputClassNames}>
                <div className={styles.item}>
                    {renderFunction(props.value)}
                </div>
                <Button
                    onClick={onButtonClick}
                    firstIcon={<ArrowToDown />}
                    style={ButtonStyle.Icon}
                    className={showItems ? stylesBase.selectButton + " " + stylesBase.close : stylesBase.selectButton}
                />
            </div>
            <ul className={listClassNames}>
                {props.items?.map((item: IItem) => (
                    <li key={item.value} className={listItemClassNames} onClick={() => handleItemClick(item)}>
                        {renderFunction(item)}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default observer(SelectMini);