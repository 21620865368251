import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Link } from "react-router-dom";

import { Item } from "types";

import { ArrowToRight } from "assets/icons";
import { FavouriteStar } from "assets/icons";

import styles from "./select.module.css";


const MultilevelSelect = observer(function (props: { selected: Item, onClick: (item: Item | null) => void, isSection?: boolean, handleClickToFavorites: (item: Item) => void }) {
    const { selected, onClick } = props;
    const [selectItemOpened, setSelectItemOpened] = useState<boolean>(false);
    const [style, setStyle] = useState<string>("");

    const setPositionsContextMenu = (element: HTMLUListElement) => {
        if (element) {
            const position = element.getBoundingClientRect();

            if (position.bottom > window.innerHeight) {
                element.style.top = (element.offsetTop - (position.bottom - window.innerHeight)) + "px";
            }

            if (position.right > window.innerWidth) {
                element.style.left = (-position.width) + "px";
            }
            setStyle((position.width) + "px")
        }
    };


    function listItem(item: Item) {
        return (
            <>
                {item.icon &&
                    <div className={styles.multilevelIcon}>
                        {item.icon}
                    </div>
                }

                {item.name ?? item.displayValue}

                {/*TODO �������� �� ����� ��� ������� �� ������ ���������� � ������. ������ ����� ����� �������� ���  ��������  */}
                {/* <Star className={item.IsFavorite?styles.activeFavStar:styles.favStar} onClick={() => { props.handleClickToFavorites(item)}} /> */}


            </>
        );
    }

    return (
        <ul className={styles.multilevelSelect} onMouseEnter={() => { setSelectItemOpened(true); }}
            onMouseLeave={() => { setSelectItemOpened(false); }} onClick={() => { setSelectItemOpened(true); }} ref={setPositionsContextMenu}>
            {selected.icon &&
                <div className={styles.icon}>
                    {selected.icon}
                </div>
            }
            {selected.name}
            <div className={styles.showListButton}> <ArrowToRight /> </div>
            {selectItemOpened &&
                <div>
                    <ul className={styles.multilevelList} style={{ left: style }}>
                        {selected.multilevelItems!.map((item, i) => {
                            let listItemClassNames = props.isSection ? `${styles.listItem} ${styles.sectionListItem} ${styles.multilevel} ` : `${styles.listItem} ${styles.multilevel} `;
                            if (item.classNames) {
                                listItemClassNames += item.classNames;
                            }
                            return (
                                <>
                                    {
                                        item.entityName ? <div className={listItemClassNames}>
                                            <Link key={i} to={item.entityName} style={{ padding: '0' }} className={listItemClassNames}>
                                                {listItem(item)}
                                            </Link>
                                            <FavouriteStar className={item.IsFavorite ? styles.activeFavStar : styles.favStar} onClick={() => { props.handleClickToFavorites(item) }} />
                                        </div> :
                                            <li key={i} className={listItemClassNames} onClick={() => { onClick(item) }}>
                                                {listItem(item)}
                                            </li>

                                    }
                                </>
                            );
                        })
                        }
                    </ul>
                </div>
            }
        </ul>
    );
});

export default MultilevelSelect;