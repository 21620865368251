import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { dispatcher, store } from "store";
import { Zone } from "modules/DND/zone";
import { Element } from "modules/DND/element";

import { ButtonStyle } from "components";
import { FieldSelect } from "pages/section-wizzard/components";

import { FieldsTitle } from "pages/section-wizzard/data/data";
import { Types } from "modules/DND/type";
import { GridItem, ItemType } from "types/entity";
import { DragIcon } from "assets/icons";
import { IGridItem } from "components/select/types";

import styles from "../constructor-source-panel.module.css";

type DNDOnDropEvent = {
    elementId: string | null;
    sourceZone: string | null;
    targetZone: string | null;
    type: Types,
    sourceData?: any;
    [key: string]: any;
}

export const ExistFieldsBlock = observer((props: { items: Array<IGridItem> }) => {
    const [placeholderExistField, setPlaceholderExistField] = useState<JSX.Element>(<></>);
    const [targetElementViewLayoutExistField, setTargetElementViewLayoutExistField] = useState<JSX.Element>(<></>);

    const sectionWizzard = useMemo(() => {
        return dispatcher.entity.get()?.entity.sectionWizzard;
    }, [dispatcher.entity.get()?.entity.sectionWizzard]);

    const currentTabIndex = useMemo(() => {
        return sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
    }, [sectionWizzard?.reactorConfig.tabs.currentTab]);

    const onZoneDropExistField = useCallback((value: DNDOnDropEvent) => {
        const findedItem = dispatcher.sectionWizzard.getAllGridItems().find(item => item.fieldConfig?.columnId === value.elementId);
        if (findedItem) {
            const gridItem: GridItem = {
                ...findedItem,
                x: value.MATRIX.cellX,
                y: value.MATRIX.cellY,
                type: ItemType.Field,
                gridItemId: v4()
            };
            if (currentTabIndex === 0 && (gridItem.fieldConfig?.columnName === "Id" || gridItem.fieldConfig?.columnName === "CreatedOn" ||
                gridItem.fieldConfig?.columnName === "ModifiedOn" || gridItem.fieldConfig?.columnName === "ExternalId")) {

                dispatcher.sectionWizzard.setGridItemByTabIndex(currentTabIndex, gridItem, value.targetZone ?? "");
            } else {
                if (sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items.find(item => item.fieldConfig?.columnId === value.elementId)) {
                    dispatcher.sectionWizzard.createDuplicateGridItemInTab(value.targetZone ?? "", gridItem, currentTabIndex);
                } else {
                    dispatcher.sectionWizzard.addGridItemByTabIndex(gridItem, currentTabIndex);
                }
            }
        }
    }, [currentTabIndex, toJS(sectionWizzard?.reactorConfig.tabs.tabsConfig)]);

    const onZoneUpExistField = useCallback(({ elementId }: {
        elementId: string
    }) => {
        const item = props.items.find((innerItem) => (innerItem.columnId ?? innerItem.id) === elementId);
        if (item) {
            const fieldClassNames = classNames(`${styles.field} `, {
                [`${item.classNames} `]: item.classNames,
            });

            setPlaceholderExistField(
                <li
                    style={{ borderRadius: "8px", background: "var(--color-grayBlue-50)" }}
                    key={item.columnId}
                    className={fieldClassNames}
                >
                    <DragIcon style={{ stroke: "var(--color-gray-300)" }} />
                    {item.icon}
                    <span >{item.displayValue}</span>
                </li>
            );

            setTargetElementViewLayoutExistField(
                <div style={{
                    transform: "rotate(-3deg) translateX(-12px) translateY(-20px)",
                    padding: "0px 8px",
                    alignItems: "center",
                    gap: "8px",
                    flexShrink: 0,
                    borderRadius: "8px",
                    background: "#FFF",
                    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.14)",
                    maxWidth: "400px"
                }} key={item.id} className={fieldClassNames}>
                    <DragIcon style={{ stroke: "var(--color-gray-300)" }} />
                    {item.icon}
                    <span style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "90px"
                    }}>{item.displayValue}</span>
                </div>
            );
        }
    }, [props.items.map(item => item), placeholderExistField]);

    const zoneConfig = useMemo(() => {
        return {
            targetElementViewLayout: targetElementViewLayoutExistField,
            placeholderLayout: placeholderExistField,
            predictLayout: <div className={styles.predictLayout}></div>,
            cellLayout: <div style={{ height: "2em" }}></div>,
            width: 1,
            height: 1
        };
    }, [targetElementViewLayoutExistField, placeholderExistField]);

    const fieldsMapping = useMemo(() => {
        const unDuplicatedItems: Array<IGridItem> = [];
        props.items.forEach((item) => {
            if (item.columnId && !unDuplicatedItems.find((equalItem) => equalItem.columnId === item.columnId)) {
                unDuplicatedItems.push(item);
            }
        });
        return (unDuplicatedItems &&
            unDuplicatedItems.map((item, index) => {
                const fieldClassNames = classNames(`${styles.field} `, {
                    [`${styles.fieldDisabled} `]: item.isDisabled || item.isLocked,
                    [`${item.classNames} `]: item.classNames,
                });

                return (
                    <Zone
                        key={`${item.columnId ?? item.gridItemId ?? item.id}-source-zone`}
                        onDrop={onZoneDropExistField}
                        onUp={onZoneUpExistField}
                        id={`${item.columnId ?? item.gridItemId ?? item.id}-source-zone`}
                        config={zoneConfig}
                        type={Types.MATRIX}
                    >
                        <Element
                            key={item.id}
                            id={`${item.columnId ?? item.gridItemId ?? item.id}`}
                            x={1}
                            y={1}
                            width={1}
                            height={1}
                            sourceData={{
                                title: item.displayValue,
                                icon: item.icon,
                                id: item.id,
                                type: item.columnType ?? item.name
                            }}
                        >
                            <li key={item.columnId ?? item.gridItemId} className={fieldClassNames}>
                                <DragIcon style={{ stroke: "var(--color-gray-300)" }} />
                                {item.icon}
                                <span>{item.displayValue}</span>
                            </li>
                        </Element>
                    </Zone>
                );
            })
        );
    }, [zoneConfig, toJS(props.items)]);

    return (
        <FieldSelect
            name={FieldsTitle}
            buttonStyle={ButtonStyle.Settings}
        >
            {!store.options.isDisabledConstructorInSectionWizzard ? fieldsMapping : <></>}
        </FieldSelect>
    )
});
