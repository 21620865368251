import styles from "./avatar-generator.module.css";

export default function Avatar(props: { name: string | null, className?: string }) {
    let className = `${styles.avatar} `;
    if (props.className)
        className += props.className;
    if (props.name) {
        let avatar = props.name.split(" ");
        return (
            <div className={className}>{avatar[0][0]}{avatar.length > 1 && avatar[1][0]}</div>
        );
    }
    return (
        <div className={className} />
    );
}