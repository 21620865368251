import Api from "api/http/api";

class License extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/License";
	}

	sendRequest = () => {
		this.setPath(`${this.basicPath}/sendRequest`);
		return this;
	};

}

const license = new License();

export { license };