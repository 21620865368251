import { api } from "api";
import ISort from "entities/ISort";
import ListStore from "entities/ListStore";
import { makeAutoObservable } from "mobx";
// import Api from "app/services/api";


class CurrentLookupPanelStore {
	clickedId: string = "";
	listStore: ListStore | undefined;
	sort: ISort = {
		columnPath: "Name",
		direction: 0
	};
	openedDeleteValueWarningDialog: boolean = false;
	openedDeleteTableWarningDialog: boolean = false;
	openedEditDialod: boolean = false;
	clickedNewValue: boolean = false;
	newNameTable: string = "";

	constructor() {
		makeAutoObservable(this);

	}

	setValue(value: string | number | ListStore | boolean, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	setClickedNull() {
		this.clickedId = "";
	}

	async renameTable() {
		let response: any;
		if (this.newNameTable !== "") {
			let info: any = {
				title: this.newNameTable,
				entityName: this.listStore?.schema,
			};
			// response = await Api.post("/api/Entity/editTitle", info);
			response = await api.http.httpApi.entity.editTitle().post(info);
		}
	}

}

const currentLookupPanelStore = new CurrentLookupPanelStore();

export default currentLookupPanelStore;