import { FrameWaves } from 'assets/icons';
import styles from './error-page.module.scss';

export const NotFoundPage = (props:{[key: string]:any}) => {
    const errorText = 'Cтраница не найдена.\r\nВозможно, вы перешли по ссылке, в которой была допущена ошибка, или ресурс не существует.\nПопробуйте уточнить поиск.';
    const errorNumber = '404';
        return (
        <div className={styles.errorPageWrapper}>
            <div className={styles.errorTextWrapper}>
                <div className={styles.errorNumber}>{errorNumber ?? props.errorNumber}</div>
                <div className={styles.errorBackgroundNumber}>{errorNumber ?? props.errorNumber}</div>
                <div className={styles.errorText}>{errorText ?? props.errorText}</div>
            </div>
            <div className={styles.errorWaves}><FrameWaves/></div>            
        </div> )
};