import classNames from "classnames";

import { Locked } from "assets/icons";

import styles from "./toggle.module.css";


interface ToggleProps {
    checked: boolean;
    onCheck: (value: boolean) => void;
    isDisabled?: boolean
}

function Toggle(props: ToggleProps) {
    const classNameSwith = classNames({
        [`${styles.swith} `]: true,
        [`${styles.checkedSwith} `]: props.checked,
    });
    const classNameSlider = classNames({
        [`${styles.slider} `]: true,
        [`${styles.checked} `]: props.checked,
    });
    const classNameDisabledIcon = classNames({
        [`${styles.disabledIcon} `]: true,
        [`${styles.checkedDisabledIcon} `]: props.checked,
    });
    function onChange() {
        if (!props.isDisabled) props.onCheck(!props.checked);
    }

    return (
        <div className={classNameSwith} onClick={onChange}>
            {props.isDisabled && <Locked className={classNameDisabledIcon} />}
            <div className={classNameSlider} />
        </div>
    )
}

export default Toggle;