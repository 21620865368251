import { useCallback, useMemo, useRef, useState } from "react";
import { isNull, isUndefined } from "lodash";
import { v4 } from "uuid";

import { dispatcher, selector } from "store";

import { Element, Zone } from 'modules/DND/horizontal-column';
import { Types } from "modules/DND/horizontal-column/type";

import { modalController, Position } from "features/modals";
import { ModalType } from "features/modals/viewer/modal-viewer";

import { Stage, stageActionPropsArray } from "components";

import { ConfirmModal, HeaderGroup } from "./components";
import { filterVisibly, sortByOrder } from "../../lib";

import { ResultType, StageFullInfo } from "types/entity";

import s from '../../stage-model-settings.module.css';

type Props = {
    header: string;
    stages: Array<StageFullInfo>;
    selectedStageId: string;
    onOpenSettingPanel: (id: string) => void;
    isSingleGroup: boolean;
    onMoveStage: (oldPosition: number, newPosition: number) => void;
    stagesCounter: number;
    setStagesCounter: (stagesCounter: number) => void;
}

export function StageGroup(props: Props) {
    const [idModal] = useState(v4());
    const [idZone] = useState(v4());
    const [placeholder, setPlaceholder] = useState<null | StageFullInfo>(null);

    const container = useRef<HTMLDivElement>(null);

    const allSortedStages = useMemo(() => sortByOrder(props.stages), [props.stages]);
    const visiblyStages = useMemo(() => filterVisibly(allSortedStages), [allSortedStages]);

    const handleMoveStage = useCallback((value: {
        zoneId: string,
        currentElementIndex: number,
        targetElementIndex: number
    }) => {
        if (idZone !== value.zoneId) {
            return;
        }

        let oldPosition = null;
        if (!isUndefined(props.stages[value.currentElementIndex]?.order)) {
            oldPosition = props.stages[value.currentElementIndex].order;
        } else {
            if (value.targetElementIndex > (props.stages.length / 2)) {
                oldPosition = props.stages[props.stages.length - 1].order;
            } else {
                oldPosition = props.stages[0].order;
            }
        }

        let newPosition = null;
        if (!isUndefined(props.stages[value.targetElementIndex]?.order)) {
            newPosition = props.stages[value.targetElementIndex].order;
        } else {
            if (value.targetElementIndex > (props.stages.length / 2)) {
                newPosition = props.stages[props.stages.length - 1].order;
            } else {
                newPosition = props.stages[0].order;
            }
        }

        props.onMoveStage(oldPosition, newPosition);
    }, [idZone, props])

    const stageElements = useMemo(() => visiblyStages.map((stage, index) => <Element
        id={stage.id}
        x={index}
    >
        <Stage
            stageActionProps={stageActionPropsArray}
            name={stage.name}
            color={stage.color}
            isVisibleDelete={visiblyStages.length > 1 || !props.isSingleGroup}
            isVisiblyHide={visiblyStages.length > 1 || !props.isSingleGroup}
            hidden={stage.isHidden}
            selectedStageId={props.selectedStageId}
            onHidden={() => {
                dispatcher.stageModel.hideStage(stage.id)
                modalController.notificationAdd({
                    id: v4(),
                    type: ModalType.NOTIFICATION,
                    position: Position.CENTER,
                    layout: <div>{`Стадия «${stage.name}» скрыта`}</div>,
                    allowTimer: true,
                    allowDefaultClick: true,
                    withBackdrop: false,
                })
            }}
            onView={() => {
                dispatcher.stageModel.viewStage(stage.id)
            }}
            onDelete={(event: MouseEvent) => {
                event.stopPropagation();
                props.onOpenSettingPanel("");
                modalController.popupAdd({
                    id: idModal,
                    layout: <ConfirmModal
                        close={() => {
                            modalController.modalRemove(idModal)
                        }}
                        delete={() => {
                            modalController.notificationAdd({
                                id: v4(),
                                type: ModalType.NOTIFICATION,
                                position: Position.CENTER,
                                layout: <div>{`Стадия «${stage.name}» удалена`}</div>,
                                allowTimer: true,
                                allowDefaultClick: true,
                                withBackdrop: false,
                            })
                            modalController.modalRemove(idModal)
                            dispatcher.stageModel.deleteStage(stage.id)
                        }}
                    />,
                    closeFunc: () => {
                        modalController.modalRemove(idModal)
                    }
                });
            }}
            onAdd={() => {
                modalController.notificationAdd({
                    id: v4(),
                    type: ModalType.NOTIFICATION,
                    position: Position.CENTER,
                    layout: <div>Новая стадия добавлена</div>,
                    allowTimer: true,
                    allowDefaultClick: true,
                    withBackdrop: false,
                });
                const resultTupeForNewStage = !isNull(stage.resultType) ? ResultType.NULL : null;
                const newStageId = dispatcher.stageModel.addStage(stage.order + 1, resultTupeForNewStage, props.stagesCounter);
                props.onOpenSettingPanel(newStageId);
                props.setStagesCounter(props.stagesCounter + 1);
            }}
            onClick={() => {
                props.onOpenSettingPanel(stage.id)
            }}
            id={stage.id}
        />
    </Element>
    ), [visiblyStages, idModal, props, props.selectedStageId, props.stagesCounter])

    const handleStageUp = useCallback((id: string) => {
        const uppedStage = props.stages.find((stage) => stage.id === id) ?? null;
        setPlaceholder(uppedStage);
    }, [props.stages]);

    const uppedStageLayout = useMemo(() => <Stage
        stageActionProps={stageActionPropsArray}
        name={placeholder?.name}
        color={placeholder?.color}
        height={(container.current?.getBoundingClientRect().height ?? 100) - 48}
        id={"placeholder"}
        singleViewStage
    />, [placeholder?.name, placeholder?.color])

    const handleViewOnlyOneStageView = useCallback((event: any) => {
        event.preventDefault();
        dispatcher.stageModel.viewStage(allSortedStages[0].id)
    }, [allSortedStages[0]?.id])

    const handleViewOnlyOneStageHide = useCallback((event: any) => {
        event.preventDefault();
        dispatcher.stageModel.hideStage(allSortedStages[0].id)
    }, [allSortedStages[0]?.id])

    const handleViewOnlyOneStageDelete = useCallback(() => {
        dispatcher.stageModel.deleteStage(allSortedStages[0].id);
    }, [allSortedStages[0]?.id])

    const handleViewOnlyOneStageAdd = useCallback(() => {
        dispatcher.stageModel.addStage(allSortedStages[0].order + 1, allSortedStages[0].resultType);
    }, [allSortedStages])

    const handleViewOnlyOneStageClick = useCallback(() => {
        props.onOpenSettingPanel(allSortedStages[0].id)
    }, [allSortedStages[0]?.id])

    if (!props.stages.length) {
        return (<></>)
    }

    return (
        <div ref={container} className={s.zoneContainer}>
            <div>
                <HeaderGroup
                    stages={props.stages} // TODO ДОПИСАТЬ КОМПОНЕНТ ВНУТРИ, НУЖЕН АДАПТЕР
                    title={props.header}
                    hiddenCount={props.stages.length}
                    isSingleGroup={props.isSingleGroup}
                />
            </div>
            {!visiblyStages.length ?
                <Stage
                    stageActionProps={stageActionPropsArray}
                    name={allSortedStages[0].name}
                    color={allSortedStages[0].color}
                    isVisibleDelete={!props.isSingleGroup}
                    isVisiblyHide={!props.isSingleGroup}
                    hidden={allSortedStages[0].isHidden}
                    selectedStageId={props.selectedStageId}
                    onView={handleViewOnlyOneStageView}
                    onHidden={handleViewOnlyOneStageHide}
                    onDelete={handleViewOnlyOneStageDelete}
                    onAdd={handleViewOnlyOneStageAdd}
                    onClick={handleViewOnlyOneStageClick}
                    id={allSortedStages[0].id}
                    singleViewStage
                />
                :
                <Zone
                    onUp={handleStageUp}
                    id={idZone}
                    movementElement={uppedStageLayout}
                    placeholder={<></>}
                    predict={uppedStageLayout}
                    type={Types.HORIZONTAL_COLUMN}
                    onDrop={handleMoveStage}
                >
                    {stageElements}
                </Zone>
            }
        </div>
    )
}