import Entity, { IEntityData, IEntityStore } from 'entities/Entity';
import { FieldValidationState, validateEmail, validatePhoneNumber, validateRequired } from 'entities/Validation';
import { Account } from 'entities/account/Account';
import { Contact } from 'entities/contact/Contact';
import ImportStore from 'entities/import/ImportStore';
import { action, makeAutoObservable, makeObservable, observable } from 'mobx';
import { Item } from "types";
import IPriority from 'entities/IPriority';
import GetFormattedPhone from 'entities/GetFormattedPhone';


export class Lead implements IEntityStore {
    schema: string = "Lead";
    displaySchema: string = "Лиды";
    id: string = "";

    isLinkToContact: boolean = false;

    number: string = this.random();
    title: string = "";
    contact: Contact | null = null;
    priority: IPriority | null = null;
    stage: Item | null = null;
    name: string = "";
    phone: string = "";
    email: string = "";
    sourceReceipt: Item | null = null;
    needType: Item | null = null;
    owner: Item | null = null;
    city: Item | null = null
    notes: string = "";
    modifiedOn: string = "";
    createdOn: string = "";
    account: Account | null = null;


    validation: { [key: string]: FieldValidationState };

    constructor() {
        makeObservable(this, {
            isLinkToContact: observable,
            contact: observable,
            priority: observable,
            stage: observable,
            name: observable,
            phone: observable,
            email: observable,
            sourceReceipt: observable,
            needType: observable,
            owner: observable,
            account: observable,
            city: observable,
            notes: observable,
            // modifiedOn: observable,
            validation: observable,
            setValue: action,
            validate: action,
            resetValidate: action,
            deserialize: action,
        })

        this.validation = {
            contact: {
                isInvalid: false
            },
            priority: {
                isInvalid: false
            },
            stage: {
                isInvalid: false
            },
            name: {
                isInvalid: false
            },
            phone: {
                isInvalid: false
            },
            email: {
                isInvalid: false
            },
            sourceReceipt: {
                isInvalid: false
            },
            needType: {
                isInvalid: false
            },
            owner: {
                isInvalid: false
            },
            account: {
                isInvalid: false
            },
        }
    }

    random(): string {
        let rnd = Math.floor((Math.random() * (1000 - 1) + 1)).toString();
        if (rnd.length < 3) {
            let mask = "000";
            rnd = mask.substring(0, mask.length - rnd.length).concat(rnd);

        }
        return rnd;
    }

    setValue(value: string | boolean | Item, fieldName: string) {
        Reflect.set(this, fieldName, value);
    }

    // @action
    validate(): boolean {
        if (!validateRequired(this.priority, this.validation.priority,true)) {
            return false;
        }
        if (!validateRequired(this.stage, this.validation.stage,true)) {
            return false;
        }
        if (this.isLinkToContact && !validateRequired(this.contact, this.validation.contact,true,true)) {
            return false;
        }
        if (!this.isLinkToContact) {
            if (!validateRequired(this.name, this.validation.name)) {
                return false;
            }
            if (!validatePhoneNumber(this.phone, this.validation.phone)) {
                return false;
            }
            if (!validateEmail(this.email, this.validation.email)) {
                return false;
            }
        }
        if (!validateRequired(this.sourceReceipt, this.validation.sourceReceipt,true)) {
            return false;
        }
        if (!validateRequired(this.needType, this.validation.needType,true)) {
            return false;
        }
        if (!validateRequired(this.owner, this.validation.owner,true,true)) {
            return false;
        }
        // if (!validateRequired(this.account, this.validation.account)) {
        //     return false;
        // }
        return true;
    }

    resetValidate() {
        this.validation = {
            contact: {
                isInvalid: false
            },
            priority: {
                isInvalid: false
            },
            stage: {
                isInvalid: false
            },
            name: {
                isInvalid: false
            },
            phone: {
                isInvalid: false
            },
            email: {
                isInvalid: false
            },
            sourceReceipt: {
                isInvalid: false
            },
            needType: {
                isInvalid: false
            },
            owner: {
                isInvalid: false
            },
            account: {
                isInvalid: false
            },
        }
    }

    // @action
    deserialize(p: any) {
        // console.log(p);

        // if (p.values) {
        //     p.values.map((item: IEntityData) => {
        //         let name = item.propertyName.charAt(0).toLowerCase() + item.propertyName.slice(1);
        //         Reflect.set(this, name, item.propertyValue);
        //     })
        // } else {
        this.id = p.id;
        this.number = p.number ?? this.random();
        this.title = p.title ?? "";
        this.contact = p.contact;
        this.priority = p.priority;
        this.stage = p.stage;
        this.name = p.name ?? "";
        this.phone = p.phone ;
        this.email = p.email ?? "";
        this.sourceReceipt = p.sourceReceipt;
        this.needType = p.needType;
        this.owner = p.owner;
        this.city = p.city;
        this.modifiedOn = p.modifiedOn;
        this.createdOn = p.createdOn;
        this.notes = p.notes ?? "";
        this.account = p.account;
    }

    serialize() {
        let values = Reflect.ownKeys(this).filter(x => !x.toString().toLowerCase().includes('schema') && !(this.id === "" && x === 'id') && x !== 'validation' && !x.toString().startsWith('is') && !x.toString().endsWith('edOn')).map((item) => {
            let name = null;
            let value = null;


            if (typeof Reflect.get(this, item) === 'object') {
                name = (item.toString().charAt(0).toUpperCase() + item.toString().slice(1)) + "Id";
                value = ((Reflect.get(this, item) as Item) !== null) ? (Reflect.get(this, item) as Item).id : null;
            }
            else {
                name = item.toString().charAt(0).toUpperCase() + item.toString().slice(1);
                value = Reflect.get(this, item) === "" ? null : Reflect.get(this, item);
            }
            if (name === 'Phone' && value !== null && value !== undefined) {
                value = (value as string).replace(/\D/g, '');
                if (value[0] !== '7')
                    value = value.charAt(0).replace(value[0], '7') + value.toString().slice(1);

            }
            if (name === 'Title' && (value === undefined || value === null))
                value = this.number + " / " + this.needType?.name;
            return {
                propertyName: name,
                propertyValue: value
            }
        }) as IEntityData[];
        values.pop();


        return {
            entityName: this.schema!,
            values: values
        }
    }

    handleError(error: string): void {
        if (error === "name_is_empty") {
            // this.validName = false;
            // this.errorName = "";
        }
    }
}


const lead = new Lead();

const leadEntity = new Entity<Lead>(lead);

export const leadImportStore = new ImportStore(lead.schema);

export default leadEntity;
