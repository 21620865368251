import { useParams } from "react-router-dom";
import {  useInsertionEffect, useRef } from "react";
import { observer } from "mobx-react-lite";

import locContainer from "module/reactor/loc/Loc";

import componentBuild from "./pageBuilder/pageBuilder";
import ReactorStore from "./api/ReactorStore";
import componentLibary from "./data/componentLibary";


const Reactor = observer((props: { xml: string }) => {
    const param = useParams<any>();
    //TODO xml должна будет браться из стора
    const store = useRef<ReactorStore>(new ReactorStore(props.xml));

    useInsertionEffect(() => {
        store.current.loadServices(param.id);

        //TODO реализовать подгрузку стилей (пока что нет даже запроса на бэке)
        // store.current.loadStyles();

        return () => locContainer.remove("main");
    }, []);

    return (
        <>
            {store.current.styles}
            {store.current.services && componentBuild(store.current.render!, store.current.services, componentLibary)}
        </>
    )
});
export default Reactor