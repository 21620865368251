import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { v4 } from "uuid";

import { dispatcher, store } from "store";

import { Button, ButtonStyle, Select } from "components";
import { Position, modalController } from "features/modals/controller";
import { actionsSectionWizzard } from "./data/data";

import { ArrowToDown, Ellipse, Warning } from "assets/icons";

import styles from "./section-wizzard-header.module.css";
import sectionWizzard from "../../section-wizzard";
import {synchroiser} from "../../../../synchroiser";

export const SectionWizzardHeader = observer((props: {
	isViewing: boolean,
	isViewingToggle: () => void,
	beforeUnloadHandler: (event: any) => string,
	saveClick: () => void
}) => {
	const DELETE_PADDING_TICKS = 5;
	const [tick, setTick] = useState<number>(0);
	const [clicked, setClicked] = useState<boolean>(false);
	const refSetInterval = useRef<NodeJS.Timeout>();
	const [idModal] = useState<string>(v4());
	const [idModalStageModel] = useState<string>(v4());


	//TODO тут будет описан метод выбора действий из выпадающего списка
	const handleClickToSectionWizzardActions = useCallback(() => {

	}, []);

	const sectionWizzardHeadTitle = useMemo(() => {
		if (!props.isViewing) {
			return (
				<Select
					name="Дизайнер раздела"
					items={actionsSectionWizzard}
					onChangeValue={handleClickToSectionWizzardActions}
					secondIcon={<ArrowToDown />}
					classNameButton={styles.headTitle}
					classNameList={styles.leftList}
				/>
			);
		} else {
			return (
				<>
					<span className={styles.headCaption}>Дизайнер раздела</span>
					<Ellipse />
					<span className={styles.greyHeadCaption}>Предварительный просмотр</span>
				</>
			);
		}
	}, [props.isViewing]);

	const getViewingButton = useMemo(() => {
		if (!props.isViewing) {
			return (
				<Button
					caption="Предварительный просмотр"
					onClick={props.isViewingToggle}
					style={ButtonStyle.LightBlue}
				/>
			);
		} else {
			return (
				<Button
					caption="Вернуться в дизайнер раздела"
					onClick={props.isViewingToggle}
					style={ButtonStyle.LightBlue}
				/>
			);
		}

	}, [props.isViewing]);

	const closeFuncWithOutConfirmStageModel = useCallback(() => {
		modalController.modalRemove(idModalStageModel);
	}, [idModalStageModel]);

	const handleClickSaveButton = useCallback(async () => {
		// TODO Поправить валидацию и проработать ее архитектуру
        props.saveClick();
	}, [props]);

	const startInterval = useCallback(() => {
		refSetInterval.current = setInterval(() => {
			setTick(tick => tick - 1);
		}, 1000);

	}, [tick, refSetInterval.current]);

	const closeAll = useCallback(() => {
		modalController.modalRemove(idModal);
		setClicked(false);
		window.close();
		store.removeEntity(store.currentEntityId);
		clearInterval(refSetInterval.current);
	}, [idModal, refSetInterval.current, store.currentEntityId]);

	useEffect(() => {
		if (tick == DELETE_PADDING_TICKS) {
			startInterval();
		}
		if (tick < DELETE_PADDING_TICKS + 1 && tick > 0 && clicked) {
			modalController.modalRemove(idModal);
			modalController.notificationAdd({ id: idModal, position: Position.CENTER, layout: layoutNotification, withBackdrop: false });
		}
		if (tick == 0 && clicked) {
			closeAll();
		}
	}, [tick]);

	const cancelTimer = (() => {
		clearInterval(refSetInterval.current);
		modalController.modalRemove(idModal);
	});
	const closeFuncWithOutConfirm = useCallback(() => {
		modalController.modalRemove(idModal);
	}, []);

	const layoutNotification = useMemo(() => {
		return (
			<>
				<div className={styles.notificationClose}>{`Вкладка браузера закроется автоматически через ${tick} сек.`}</div>
				<div className={styles.verticalDivider}></div>
				<div className={styles.notificationCancel} onClick={cancelTimer}>Отменить</div>
			</>
		);
	}, [tick]);

	const onClickNoSave = useCallback(() => {
		window.removeEventListener("beforeunload", props.beforeUnloadHandler);
		setClicked(true);
		setTick(DELETE_PADDING_TICKS);
		closeFuncWithOutConfirm();
		modalController.notificationAdd({ id: idModal, position: Position.CENTER, layout: layoutNotification, withBackdrop: true });
	}, [tick, layoutNotification]);

	const layoutConfirm = useMemo(() => {
		return (
			<div className={styles.dialog}>
				<div className={styles.header}>
					<span className={styles.title}>Сохранить изменения?</span>
					<Warning />
				</div>
				<div className={styles.dialogFooter}>
					<Button
						caption="Назад"
						onClick={closeFuncWithOutConfirm}
						style={ButtonStyle.Subtle} />
					<Button
						caption="Не сохранять"
						onClick={onClickNoSave}
						style={ButtonStyle.Danger} />
					<Button
						caption="Сохранить"
						onClick={handleClickSaveButton}
						style={ButtonStyle.Success} />
				</div>
			</div>
		);
	}, []);

	const handleClickCloseButton = useCallback(() => {
		if (dispatcher.sectionWizzard.getSectionWizzard()?.hasChanges) {
			modalController.popupAdd({ id: idModal, layout: layoutConfirm, closeFunc: closeFuncWithOutConfirm });
		} else {
			closeAll();
		}
	}, [dispatcher.sectionWizzard.getSectionWizzard()?.hasChanges]);

	return (
		<div className={styles.sectionWizzardHead}>
			<div className={styles.leftHeader}>
				{sectionWizzardHeadTitle}
			</div>

			<div className={styles.rightHeader} >
				{getViewingButton}
				<div className={styles.buttonsBlock}>
					<Button
						caption="Сохранить раздел"
						onClick={handleClickSaveButton}
						style={ButtonStyle.Success}
						isVisible={!props.isViewing}
					/>
					<Button
						caption="Закрыть"
						onClick={handleClickCloseButton}
						style={ButtonStyle.Subtle}
						isVisible={!props.isViewing}
					/>
				</div>
			</div>
		</div>
	);
});
