import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";

import { HierarchicalSelect, HierarchicalStyles } from "features/filter/simpleFilter/ui/MacrosSelect";
import DateMacroses from "features/filter/simpleFilter/data/DateMacroses";
import { booleanItems } from "features/filter/simpleFilter/data/DefaultValue";
import { SearchSelect, itemToLookup, InputDate, Select, InputNumber, Input, InputStyleName, SelectStyleName } from "components";

import DirectorSimpleFilter from "../../../../app/services/filter/directors/DirectorSimpleFilter";
import IPropertyFilter from "app/services/filter/IPropertyFilter";
import { IFilterStore } from "entities/filter/FilterStore";
import { loadEntity } from "app/services/LookupService";
import { DataValueType, ComparisonType, ExpressionType, MacrosType } from "entities/filter/IFilter";
import ListStore from "entities/ListStore";

import styles from "./right-expression.module.css";
import { Item } from "types";
import classNames from "classnames";

interface ValueProps {
    filter: IFilterStore;
    isDisabled?: boolean;
    isAdvancedFilter?: boolean;
}

export const RightExpression = observer((props: ValueProps) => {
    const type = props.filter.property.propertyValue.type;
    const comparisonValue = props.filter.comparisonType;
    const isAdvancedFilter = props.isAdvancedFilter ?? false;

    if (!props.isDisabled) {
        if (type === DataValueType.Entity || type === DataValueType.Lookup) {
            return <EntityValue filter={props.filter} isAdvancedFilter={isAdvancedFilter} />
        }

        if ((type === DataValueType.DateTime || type === DataValueType.Date) && comparisonValue) {
            if (comparisonValue === ComparisonType.Between) {
                return <BetweenDateValue filter={props.filter} isAdvancedFilter={isAdvancedFilter} />
            }
            return <DateSelect filter={props.filter} isAdvancedFilter={isAdvancedFilter} />
        }

        if (type === DataValueType.Boolean) {
            return <BooleanValue filter={props.filter} isAdvancedFilter={isAdvancedFilter} />
        }

        if ((type === DataValueType.Integer || type === DataValueType.Float || type === DataValueType.Long) && comparisonValue) {

            if (comparisonValue === ComparisonType.Between) {
                return <BetweenNumberValue filter={props.filter} isAdvancedFilter={isAdvancedFilter} />
            }

            return <NumberValue filter={props.filter} isAdvancedFilter={isAdvancedFilter} />
        }

        if (type === DataValueType.Text) {
            return <TextValue filter={props.filter} isAdvancedFilter={isAdvancedFilter} />
        }

        if (type === DataValueType.Time && comparisonValue) {
            //TODO Поля для времени
        }

    }
    return <TextValue isDisabled={props.isDisabled} filter={props.filter} isAdvancedFilter={isAdvancedFilter} />
})

export default RightExpression;

const EntityValue = observer((props: ValueProps) => {
    const [items, setItems] = useState<Item[]>([]);
    const className = classNames({
        [`${styles.searchSelect}`]: props.isAdvancedFilter,
    });
    const classNameInput = classNames({
        [`${styles.seacrhSelectInput}`]: props.isAdvancedFilter,
    });
    const inputStyle = useMemo(() =>
        props.isAdvancedFilter ? InputStyleName.QuickViewInput : undefined
        , [props.isAdvancedFilter]);
    const selectStyle = useMemo(() =>
        props.isAdvancedFilter ? SelectStyleName.QuickViewSelect : undefined
        , [props.isAdvancedFilter]);

    return (
        <SearchSelect
            isInput={true}
            items={items}
            value={props.filter.rightExpression?.parameter.value}
            onChangeValue={(item) => {
                props.filter.setRightExpression(itemToLookup(item));
            }}
            onItemsLoad={async (value) => {

                setItems([]);
                let property: IPropertyFilter = {
                    DisplayName: "",
                    Name: "Name",
                    Type: DataValueType.Text
                }

                const lookupTable = props.filter.property.propertyValue.lookupTable!;

                let filter = DirectorSimpleFilter.CreateAttribute(value ?? "", ComparisonType.Contain, property, lookupTable)

                let data = await loadEntity(lookupTable, filter);
                setItems(data as Item[]);
                return data.length
            }}
            isListDelay={true}
            className={className}
            classNameInput={classNameInput}
            inputStyle={inputStyle}
            selectStyle={selectStyle}
        />
    )
})

const DateSelect = observer((props: ValueProps) => {
    const styles = useMemo(() =>
        props.isAdvancedFilter ? HierarchicalStyles.AdvancedFilter : HierarchicalStyles.Opacity
        , [props.isAdvancedFilter]);

    function getMacros(macros: MacrosType) {
        let result: Item | undefined
        DateMacroses?.forEach(i => {
            let multilevelItem = i.multilevelItems?.find((v: any) => {
                return (typeof v.id === "number" && v.id === macros)
            })
            if (multilevelItem !== undefined) {
                result = multilevelItem;
            }
        });
        return result;
    }

    const macros = props.filter.rightExpression?.type === ExpressionType.Macros ? getMacros(props.filter.rightExpression?.macros!):undefined;
    let value = (macros !== undefined) ? macros : props.filter.rightExpression?.parameter.value;

    const [item, setItem] = useState<Item>(props.filter.rightExpression?.parameter.value);

    if (value === null || typeof value === "string" || value?.id === "0") {
        if (value?.id === "0") {
            props.filter.setRightExpression(null);
        }
        return (
            <DateValue filter={props.filter} isAdvancedFilter={props.isAdvancedFilter} />
        )
    }

    if (value === undefined) {
        value = item;
    }

    console.log(props.filter.rightExpression, value);


    function onChange(item: Item) {
        setItem(item);
        props.filter.setRightExpression(item);
    }

    return (
        <HierarchicalSelect
            items={DateMacroses}
            value={value}
            nullableValue={<DateValue filter={props.filter} isAdvancedFilter={props.isAdvancedFilter} />}
            onChange={onChange}
            onItemsLoad={() => { }}
            styles={styles}
            placeholder={"Выберите период"}
        // className={styles.hierarchicalSelect}
        />
    )
});


const BetweenDateValue = observer((props: ValueProps) => {
    const valueBetween = props.filter.rightExpression?.parameter.value;
    const inputStyle = useMemo(() =>
        props.isAdvancedFilter ? InputStyleName.AdvancedFilter : undefined
        , [props.isAdvancedFilter]);

    function onChangeStart(value: string) {
        updateFilter(value, valueBetween.end);
    }

    function onChangeEnd(value: string) {
        updateFilter(valueBetween.start, value);
    }

    function updateFilter(start: string | null, end: string | null) {
        props.filter.setRightExpression({ start: start, end: end });
    }

    return (
        <div className={styles.between}>
            <InputDate
                placeholder=""
                value={valueBetween.start}
                onChangeValue={onChangeStart}
                isBaseInput={true}
                // className={styles.textValueWithoutborder}
                inputStyle={inputStyle}
            />
            <span className={styles.tir}>—</span>
            <InputDate
                placeholder=""
                value={valueBetween.end}
                onChangeValue={onChangeEnd}
                isBaseInput={true}
                // className={styles.textValueWithoutborder}
                inputStyle={inputStyle}
            />

        </div>
    )
})


const DateValue = observer((props: ValueProps) => {
    const value = props.filter.rightExpression?.parameter.value;
    const inputStyle = useMemo(() =>
        props.isAdvancedFilter ? InputStyleName.AdvancedFilter : undefined
        , [props.isAdvancedFilter]);

    function onChange(value: string) {
        props.filter.setRightExpression(value);
    }

    return (
        <InputDate
            placeholder=""
            value={value}
            onChangeValue={onChange}
            isBaseInput={true}
            inputStyle={inputStyle}
        />
    )
})

const BooleanValue = observer((props: ValueProps) => {
    const className = classNames({
        [`${styles.searchSelect}`]: props.isAdvancedFilter,
    });
    const classNameInput = classNames({
        [`${styles.seacrhSelectInput}`]: props.isAdvancedFilter,
    });
    const inputStyle = useMemo(() =>
        props.isAdvancedFilter ? InputStyleName.QuickViewInput : undefined
        , [props.isAdvancedFilter]);
    const selectStyle = useMemo(() =>
        props.isAdvancedFilter ? SelectStyleName.QuickViewSelect : undefined
        , [props.isAdvancedFilter]);

    let value: Item

    useEffect(() => {
        value = booleanItems[0];
    }, [])

    if (typeof props.filter.rightExpression?.parameter.value === "string") {
        value = booleanItems.find(item => item.name === props.filter.rightExpression?.parameter.value)!;
        onChange(value);
    }
    else {
        value = props.filter.rightExpression?.parameter.value;
    }

    function onChange(item: Item | null) {
        props.filter.setRightExpression(item ? item.name : null);
    }

    return (
        <Select
            value={value}
            items={booleanItems}
            onChangeValue={onChange}
            isInput={true}
            isListDelay={props.isAdvancedFilter}
            className={className}
            classNameInput={classNameInput}
            inputStyle={inputStyle}
            selectStyle={selectStyle}
        />
    )
})

const NumberValue = observer((props: ValueProps) => {
    const value = props.filter.rightExpression?.parameter.value;
    const inputStyle = useMemo(() =>
        props.isAdvancedFilter ? InputStyleName.AdvancedFilter : undefined
        , [props.isAdvancedFilter]);

    function onChange(value: string) {
        props.filter.setRightExpression(value);
    }

    return (
        <InputNumber
            placeholder="Введите значение"
            value={value}
            onChangeValue={onChange}
            isBaseInput={true}
            inputStyle={inputStyle}
        />
    )
})


const BetweenNumberValue = observer((props: ValueProps) => {
    const valueBetween = props.filter.rightExpression?.parameter.value;
    const inputStyle = useMemo(() =>
        props.isAdvancedFilter ? InputStyleName.AdvancedFilter : undefined
        , [props.isAdvancedFilter]);

    function onChangeStart(value: string) {
        UpdateFilter(value, valueBetween.end);
    }

    function onChangeEnd(value: string) {
        UpdateFilter(valueBetween.start, value);
    }

    function UpdateFilter(start: string | null, end: string | null) {
        props.filter.setRightExpression({ start: start, end: end });
    }

    return (
        <div className={styles.between}>
            <InputNumber
                placeholder="" value={valueBetween.start}
                onChangeValue={onChangeStart}
                isBaseInput={true}
                inputStyle={inputStyle}
            />
            <span className={styles.tir}>—</span>
            <InputNumber
                placeholder=""
                value={valueBetween.end}
                onChangeValue={onChangeEnd}
                isBaseInput={true}
                inputStyle={inputStyle}

            />

        </div>
    )
})

const TextValue = observer((props: ValueProps) => {
    const valueText = props.filter.rightExpression?.parameter.value;
    const inputStyle = useMemo(() =>
        props.isAdvancedFilter ? InputStyleName.AdvancedFilter : undefined
        , [props.isAdvancedFilter]);

    function onChange(value: string) {
        props.filter.setRightExpression(value);
    }

    return (
        <Input
            placeholder={props.isDisabled ? "Выберите колонку" : "Введите значение"}
            value={valueText}
            onChangeValue={onChange}
            isDisabled={props.isDisabled}
            inputStyle={inputStyle}
        />

    )
});
