import React from 'react';

import { InputStyleName, InputStyles } from 'components';

import styles from './input.module.css';


interface IInputDateProps extends React.HTMLAttributes<HTMLElement> {
    value: string | null;
    onChangeValue?: (value: string) => void;
    onFocusOut?: () => void;
    isInvalid?: boolean;
    isDisabled?: boolean;
    invalidMessage?: string;
    classNameInput?: string;
    withTime?: boolean;
    isBaseInput?: boolean;
    isNeedCorrect?: boolean;
    inputStyle?: InputStyleName;
}

function InputDate(props: IInputDateProps) {

    function handleOnBlur() {
        if (props.onFocusOut !== undefined)
            props.onFocusOut();
    }
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        props.onChangeValue!(ToUTCString(event.target.value));
    }
    const inputStyle = InputStyles[props.inputStyle ? props.inputStyle : props.isBaseInput ? InputStyleName.Base : InputStyleName.BaseWithoutBorder];

    let classNames=inputStyle.classNames;

    if (props.className) {
        classNames += props.className;
    }

    let inputClassNames = inputStyle.input;


    if (props.classNameInput) {
        inputClassNames += props.classNameInput + ` `;
    }

    let error = null;
    if (props.isInvalid === true && props.invalidMessage !== undefined && props.invalidMessage.length > 0) {
        inputClassNames += `${styles.invalid} `;
        if (props.invalidMessage.length > 1) {
            error = <div className={styles.errorMessage}>{props.invalidMessage}</div>
        }
    }

    const inputType = props.withTime ? "datetime-local" : "date";

    let inputValue = "";


    if (props.value) {
        inputValue = props.withTime ?
            ToLocalString(props.value).substring(0, 19) :
            ToLocalString(props.value).substring(0, 10);
    }

    return (
        <div className={classNames}>
            <input
                type={inputType}
                className={inputClassNames}
                value={inputValue}
                onChange={handleChange}
                onBlur={handleOnBlur}
                disabled={props.isDisabled}
                onKeyUp={props.onKeyUp}
            />
            {error}
        </div>
    );
}

function ToLocalString(date: string): string {
    const dateTime = date.indexOf("Z") === -1
        ? new Date(date + "Z")
        : new Date(date);
    const timeOffset = dateTime.getTimezoneOffset();
    dateTime.setMinutes(dateTime.getMinutes() - timeOffset);
    return dateTime.toISOString();
}

function ToUTCString(date: string): string {
    const dateTime = new Date(date);
    return dateTime.toISOString();
}

export default InputDate;