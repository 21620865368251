import { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { Button, ButtonStyle, SelectStyleName, SelectStyles } from "components";
import { getSectionPath } from "../../lib/get-section-path";

import { Locked } from "assets/icons";
import { FavouriteStar } from "assets/icons";

import styles from "./navigation-select.module.css";

export interface INavigationSelectItem {
    id: number | string;
    displayValue?: string;
    entityName?: string;
    IsFavorite?: boolean;
    classNames?: string;
    isDisabled?: boolean;
    isLocked?: boolean;
    tag?: boolean;
}


export interface ISelectProps extends React.HTMLAttributes<HTMLElement> {
    name?: string;
    items: INavigationSelectItem[];
    secondIcon?: JSX.Element;
    isRotateSecondIcon?: boolean;
    classNameIcon?: string;
    styles?: ButtonStyle;
    classNameButton?: string;
    classNameOpenList?: string;
    classNameList?: string;
    handleClickToFavorites?: (item: INavigationSelectItem) => void;
}

function NavigationSelect(props: ISelectProps) {
    const [isOpened, setOpened] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const selectStyle = SelectStyles[SelectStyleName.Base];

    const classNamesWrapper = classNames(`${selectStyle.classNames} `, {
        [`${selectStyle.classNames} ${props.className}`]: props.className,
    });
    const buttonClassNames = classNames({
        [`${props.classNameButton} ${props.classNameOpenList}`]: props.classNameOpenList && isOpened,
        [`${props.classNameButton}`]: props.classNameButton,
    });

    const secondIconClassNames = classNames(`${styles.iconButton} `, {
        [`${styles.iconButton} ${styles.close}`]: props.isRotateSecondIcon && isOpened,
    });
    const listClassNames = classNames(`${styles.list} `, {
        [`${styles.list} ${styles.visible} `]: isOpened,
        [`${styles.list} ${props.classNameList} `]: props.classNameList,
    });

    function hideMenu() {
        setOpened(false);
    }

    function openMenu() {
        if (!isOpened) {
            setOpened(true);
            document.addEventListener("click", handleClick);
        }
        else {
            hideMenu();
            document.removeEventListener("click", handleClick);
        }
    }

    function handleClick(event: Event) {
        if (wrapperRef.current != null && !wrapperRef.current.contains(event.target as Node)) {
            hideMenu();
        }
    }



    function onClick(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        e.stopPropagation();
        if (props.onClick) {
            props.onClick(e);
        }
    }

    const setPositionsContextMenu = (element: HTMLUListElement) => {
        if (element && wrapperRef.current !== null) {
            const position = element.getBoundingClientRect();
            if (position.right > window.innerWidth) {
                element.style.left = (-position.width + wrapperRef.current?.getBoundingClientRect().width!) + "px";
            }
            if (wrapperRef.current.style.visibility === 'hidden') {
                hideMenu();
            }
        }
    };

    const onClickToFavorite = useCallback(
        (item: INavigationSelectItem) => { props.handleClickToFavorites!(item) }, []
    );

    const listItem = useCallback((item: INavigationSelectItem) => {
        return (
            <>
                <div className={styles.listItemCaption} >
                    <span className={styles.listItemName} >
                        {item.displayValue}
                    </span>
                </div>
                {item.isLocked && <Locked style={{ marginLeft: "auto", stroke: 'var(--color-grayBlue-900)' }} />}
            </>
        );
    }, []);

    const sectionLink = useCallback((item: INavigationSelectItem) => {
        const listItemLinkClassNames = classNames(`${styles.listItem} ${styles.link}`);
        const favStarClassNames = classNames({
            [`${styles.activeFavStar}`]: item.IsFavorite,
            [`${styles.favStar}`]: !item.IsFavorite,
        });

        const path = getSectionPath(item.entityName ?? "");

        return <>
            <Link
                key={item.id}
                to={path}
                onClick={hideMenu}
                className={listItemLinkClassNames}>
                {listItem(item)}
            </Link>
            {(item.tag && isOpened) &&
                <FavouriteStar className={favStarClassNames} onClick={() => onClickToFavorite(item)} />
            }
        </>
    }, [onClickToFavorite, listItem, isOpened]);

    return (
        <div ref={wrapperRef} className={classNamesWrapper} onClick={onClick} style={props.style} >
            <Button
                classNameSecondIcon={secondIconClassNames}
                className={buttonClassNames}
                secondIcon={props.secondIcon}
                caption={props.name}
                onClick={openMenu}
                style={props.styles}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                selected={isOpened}
            />
            <ul className={listClassNames} ref={setPositionsContextMenu}>
                {props.children && props.children}
                {props.items &&
                    props.items.map((item, i) => {
                        const listItemClassNames = classNames(`${styles.listItem} `, {
                            [`${styles.listItem} ${styles.listItemDisabled}`]: item.isDisabled || item.isLocked,
                            [`${styles.listItem} ${item.classNames}`]: item.classNames,
                            [`${styles.listItem} ${styles.fav}`]: item.tag,
                        });
                        return (
                            <div key={item.id} id={`${item.id}`}>
                                {item.entityName &&
                                    <div className={listItemClassNames}>
                                        {sectionLink(item)}
                                    </div>
                                }
                            </div>
                        );
                    })
                    
                }
            </ul>
        </div>
    );

}

export default NavigationSelect;