import { action, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';
import classNames from 'classnames';

import { synchroiser } from 'synchroiser';
import { dispatcher, store, selector } from 'store';
import SavedFilter from 'entities/filter/SavedFilter';

import NewFilter from './new-filter/new-filter';
import getIcon from '../data/IconSetting';

import { Position, modalController } from 'features/modals';
import RightExpression from 'features/advanced-filter/filter-conditions/right-expression/right-expression';
import { AdvancedFilterConst } from 'features/section-head/data/constants';

import { Button, ButtonStyle, Field, InputStyleName, SearchSelect, SelectStyleName } from 'components';

import { IFilterStore } from 'entities/filter/FilterStore';
import { ComparisonType } from 'entities/filter/IFilter';
import { Item } from 'types';
import { LoadingState } from 'types/entity';

import { ApplayIcon, ArrowToDown, Clear } from 'assets/icons';

import styles from './SimpleFilter.module.css';
import stylesBase from 'components/select/select.module.css';

function SimpleFilter() {
    const [idModal] = useState<string>(v4());
    const [idNotification] = useState<string>(v4());
    const [showBody, setShowBody] = useState(false);
    const [filter, setFilter] = useState<SavedFilter | null>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const visibleSaveAsNew = useMemo(() =>
        filter !== null && filter.id !== null && filter.hasChanges
        , [filter, filter?.id, filter?.hasChanges]);

    const isFavorite = useMemo(() =>
        dispatcher.entity.get()?.entity.filter?.savedFilter?.isFavorite
        , [dispatcher.entity.get()?.entity.filter?.savedFilter?.isFavorite]);

    const placeholderClassNames = classNames(styles.placeholder, {
        [`${styles.placeholder} ${styles.apply}`]: dispatcher.entity.get()?.entity.filter?.placeholder
    });

    const selectButtonClassName = classNames(stylesBase.selectButton, {
        [`${stylesBase.selectButton} ${stylesBase.close}`]: showBody
    });

    const bodyClassNames = classNames(styles.body, {
        [`${styles.visible}`]: showBody
    });

    const selectBlockTopClassName = classNames(styles.selectBlockTop, {
        [`${styles.selectBlockTop} ${styles.withoutFooter}`]: filter &&
            filter.filterInfo!.filters.length > 0 &&
            filter.filterInfo?.filters[0].comparisonType !== null &&
            (filter.filterInfo?.filters[0].comparisonType == ComparisonType.IsNotNull ||
                filter.filterInfo?.filters[0].comparisonType == ComparisonType.IsNull)
    });

    const resetSavedFilter = useCallback(() => {
        if (filter && filter.id !== null) {
            filter.setValue(null, "id");
            filter.setValue(null, "filterName");
            filter.setValue(false, "isFavorite");
        }
    }, [filter]);

    const hendleClearPlaceholderClick = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        hideMenu();
        dispatcher.filter.setSavedFilter(null);
        dispatcher.filter.setStaticGroup(null);
        dispatcher.filter.setPlaceholder();
        dispatcher.entity.onChangeCheckedAll(false);
        await synchroiser.getEntityWithFilter();
        addNotification();
    }, []);

    const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e?.stopPropagation();
        modalController.modalRemove(idModal);
    }, []);

    const addNotification = useCallback(() => {
        if (synchroiser.loadingState === LoadingState.Error && synchroiser.errorMessage) {
            const layoutNotificationWithError =
                <div className={styles.errorNotification}>
                    {synchroiser.errorMessage}
                </div>;

            modalController.notificationAdd({ id: idNotification, position: Position.CENTER, layout: layoutNotificationWithError, allowDefaultClick: true, allowTimer: true });

            const entity = dispatcher.entity.get();
            if (entity) {
                const savedFilter = new SavedFilter(entity.entityName);
                savedFilter.deserialize(entity.entity.filter?.savedFilter);
                setFilter(savedFilter);
            }
        }

    }, [synchroiser.errorMessage]);

    const onKeyUp = useCallback((event: KeyboardEvent) => {
        if (showBody && !store.modals.find(({ id }) => id === id)) {
            if (event.key === 'Enter') {
                applyFilter();
            }
            else if (event.key === 'Escape') {
                hideMenu();
            }
        }
    }, [showBody, toJS(store.modals)]);

    useEffect(() => {
        const entity = dispatcher.entity.get();
        if (entity) {
            if (entity.entity.filter?.savedFilter) {
                const savedFilter = new SavedFilter(entity.entityName);
                savedFilter.deserialize(entity.entity.filter?.savedFilter);
                setFilter(savedFilter);
            }
            else {
                setFilter(selector.filter.getNewSavedFilter());
            }
        }

    }, [dispatcher.entity.get(), dispatcher.entity.get()?.entity.filter?.savedFilter]);


    useEffect(() => {
        if (showBody && !store.modals.find(({ id }) => id === id)) {
            document.body.addEventListener('keypress', onKeyUp);
            return () => {
                document.body.removeEventListener('keypress', onKeyUp);
            };
        }
    }, [onKeyUp]);

    useEffect(() => {
        if (!store.modals.find(({ id }) => id === idNotification)) {
            synchroiser.loadingState = LoadingState.NotAsked;
            synchroiser.errorMessage = null;
        }
    }, [store.modals.length]);


    function hideMenu() {
        setShowBody(false);
        document.removeEventListener('click', handleClick);
    }

    const handleClick = action((event: Event) => {
        if (wrapperRef.current === null) {
            return;
        }
        if (!wrapperRef.current.contains(event.target as Node) && filter) {
            hideMenu();
            if (filter.id) {
                filter.cancel();
            }
            else if (selector.filter.getFilter()?.savedFilter) {
                filter.deserialize(selector.filter.getFilter()?.savedFilter);
            }
            else {
                setFilter(selector.filter.getNewSavedFilter());
            }
        }
        else if (wrapperRef.current.contains(event.target as Node) && (event.target as Node).textContent === 'Добавить поле') {
            document.removeEventListener('click', handleClick);
        }
    });

    function onSelectOpen() {
        if (filter?.filterInfo?.filters.length === 0) {
            filter.filterInfo.addFilter();
        }
        setShowBody(true);
        document.addEventListener('click', handleClick);
    }

    const addCondition = useCallback(() => {
        hideMenu();
        const advancedFilter = new SavedFilter(filter?.entityName!);
        advancedFilter.deserialize(filter);
        advancedFilter.setValue(filter!.filterInfo, 'filterInfo')
        filter!.cancel();

        advancedFilter.setValue(false, 'noNeedReaction');
        advancedFilter.setValue(true, 'hasChanges');
        advancedFilter.filterInfo?.addFilter();
        AdvancedFilterConst.setAdvancedFilter(advancedFilter);
        AdvancedFilterConst.setOpenAdvancedFilterTree(true);
    },[filter]);


    const applyFilter = useCallback(async () => {
        if (filter?.filterInfo?.filters[0].isValid) {
            hideMenu();
            if (filter?.id && !filter.hasChanges) {
                await synchroiser.getFilter(filter?.id);
                addNotification();
                return;
            }
            resetSavedFilter();
            dispatcher.filter.setSavedFilter(filter);
            await synchroiser.getEntityWithFilter();
            addNotification();
        }
    }, [filter]);

    const save = useCallback(async (filterName: string | null) => {
        if (filter?.filterInfo?.filters[0].isValid && filterName) {
            if (filter.id == null || filterName) {
                filter.setValue(filterName, "filterName");
            }
            hideMenu();
            await filter.save().then(async () => {
                dispatcher.filter.setSavedFilter(filter);
                await synchroiser.getEntityWithFilter();
                await synchroiser.getSavedFilterFolderTree();
            });

            addNotification();
        }
    }, [filter]);

    const saveOrUpdate = useCallback(async () => {
        if (filter && filter.id !== null) {
            hideMenu();
            await filter.update(false);
            await synchroiser.getEntityWithFilter();
            addNotification();
        } else {
            addPopup();
        }
    }, [filter]);

    const addToFavorite = useCallback(async () => {
        if (filter) {
            filter.disposeAutorun();
            filter.setValue(!filter.isFavorite, 'isFavorite');
            if (filter.id !== null) {
                await filter.update(true);
                addNotification();
            } else {
                addPopup();
            }
        }
    }, [filter]);

    const onButtonClick = useCallback(() => {
        if (!filter || !filter.filterInfo) {
            return;
        }
        if (filter.filterInfo.filters.length < 2) {
            if (showBody) {
                hideMenu();
            } else {
                onSelectOpen();
            }
        } else {
            const advancedFilter = new SavedFilter(filter.entityName!);
            advancedFilter.deserialize(filter);
            AdvancedFilterConst.setAdvancedFilter(advancedFilter);
            AdvancedFilterConst.setOpenAdvancedFilterTree(true);
        }
    }, [filter, showBody]);

    const addPopup = useCallback(() => {
        resetSavedFilter();
        modalController.popupAdd({
            id: idModal,
            layout: <NewFilter
                onSave={save}
                close={closeConfirm}
            />,
            closeFunc: closeConfirm
        });
    }, [idModal, save]);

    //TODO разблокировать, когда статические группы переедут на новый store
    if (selector.filter.getFilter()?.staticGroup) {
        return (<div tabIndex={0} className={`${styles.placeholder} ${styles.apply}` /**placeholderClassNames */}>
            <ApplyPlaceholder onClearClick={hendleClearPlaceholderClick} />
        </div>);
    }


    return (
        <div ref={wrapperRef} className={styles.wrapper}>

            <div tabIndex={0} className={placeholderClassNames} onClick={onButtonClick}>
                {selector.filter.getFilter()?.placeholder ? (
                    <ApplyPlaceholder onClearClick={hendleClearPlaceholderClick} />
                ) : (
                    <>
                        <span className={styles.placeholderLabel}>Фильтр</span>
                        <Button
                            onClick={onButtonClick}
                            firstIcon={<ArrowToDown />}
                            style={ButtonStyle.Icon}
                            className={selectButtonClassName}
                        />
                    </>
                )}
            </div>
            {filter?.filterInfo?.filters[0] &&
                <div className={bodyClassNames}>
                    <div className={styles.selectBlock}>
                        <div className={selectBlockTopClassName} >
                            <SelectProperty filter={filter!.filterInfo!.filters[0]} />
                            <Comparisons templateConditions={filter!.filterInfo!.filters[0]} />
                        </div>
                        {
                            !(filter.filterInfo?.filters[0].comparisonType == ComparisonType.IsNotNull
                                || filter.filterInfo?.filters[0].comparisonType == ComparisonType.IsNull
                            ) && (
                                <RightExpression
                                    isDisabled={!filter.filterInfo?.filters[0].property.propertyValue.value || !filter.filterInfo?.filters[0].comparisonType}
                                    filter={filter.filterInfo!.filters[0]}
                                />
                            )}
                        <Button
                            caption='Добавить поле'
                            className={styles.addFieldButton}
                            onClick={addCondition}
                            style={ButtonStyle.Link}
                        />
                    </div>

                    <div className={styles.simpleFilterFooter}>
                        <Button
                            secondIcon={<ApplayIcon />}
                            caption='Применить'
                            className={styles.applyButton}
                            onClick={applyFilter}
                            isDisabled={!filter.filterInfo!.filters[0].isValid}
                        />

                        <div className={styles.footerRight}>
                            <Button
                                caption={filter.filterInfo && filter.id !== null ? 'Обновить' : 'Сохранить'}
                                className={`${styles.applyButton} ${styles.applyButtonsRight}`}
                                onClick={saveOrUpdate}
                                isDisabled={!filter.filterInfo!.filters[0].isValid || !filter.hasChanges}
                            />

                            <div className={styles.stroke} />

                            <Button
                                caption={
                                    //filter.filterInfo && filter.isFavorite
                                    isFavorite
                                        ? 'Убрать из Избранного'
                                        : 'Добавить в Избранное'
                                }
                                className={`${styles.applyButton} ${styles.applyButtonsRight}`}
                                onClick={addToFavorite}
                                isVisible={!visibleSaveAsNew}
                                isDisabled={!filter.filterInfo!.filters[0].isValid}
                            />

                            <Button
                                caption='Сохранить как новый'
                                className={`${styles.applyButton} ${styles.applyButtonsRight}`}
                                onClick={addPopup}
                                isVisible={visibleSaveAsNew}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default observer(SimpleFilter);

const Comparisons = observer((props: { templateConditions: IFilterStore }) => {
    let comparisonItems = props.templateConditions.comparators;
    let value = props.templateConditions.comparisonType;
    const [items, setItems] = useState<Item[]>(comparisonItems);

    function getComparatorDisplayValue() {
        const comparator = comparisonItems.find((item) => item.id === value);
        return comparator;
    }

    return (
        <Field name={'operation'} caption={'Операция'} className={styles.selectPropertyField}>
            <SearchSelect
                isInput={true}
                items={items}
                onChangeValue={(value: Item | null) => {
                    props.templateConditions.selectComparator(value);
                }}
                onItemsLoad={async (s) => {
                    if (s === null) {
                        setItems(comparisonItems);
                        return comparisonItems.length;
                    }
                    let newItems = comparisonItems.filter((x) => x.name?.includes(s));
                    setItems(newItems);
                    return newItems.length;
                }}
                value={getComparatorDisplayValue()}
                placeholder={props.templateConditions.property.propertyValue.value ? '' : 'Выберите колонку'}
                isDisabled={!props.templateConditions.property.propertyValue.value}
                inputStyle={InputStyleName.BaseWithoutBorder}
                selectStyle={SelectStyleName.SimpleFilterSelect}
            />
        </Field>
    );
});

const SelectProperty = observer((props: { filter: IFilterStore }) => {
    const property = props.filter.property;
    const propertyItems = property.items;

    let value = props.filter.property.propertyValue.value;
    const itemsWithIcon = property.items.map((item) => {
        return {
            ...item,
            icon: getIcon(property.proprtiesInfo[property.items.indexOf(item)]?.type),
        } as Item;
    });

    const [items, setItems] = useState<Item[]>(itemsWithIcon);

    return (
        <Field
            name={'columnName'}
            caption={'Название колонки'}
            className={styles.selectPropertyField}
        >
            <SearchSelect
                isInput={true}
                items={items}
                onChangeValue={(value: Item | null) => {
                    props.filter.selectAttributeOrDetail(
                        value ? propertyItems.find((item) => item.name === value!.name)! : null
                    );
                }}
                value={value}
                placeholder={'Выберите значение'}
                onItemsLoad={async (s) => {
                    if (s === null) {
                        setItems(itemsWithIcon);
                        return itemsWithIcon.length;
                    }
                    let newItems = itemsWithIcon.filter((x) => x.displayValue?.includes(s));
                    setItems(newItems);
                    return newItems.length;
                }}
                inputStyle={InputStyleName.BaseWithoutBorder}
                selectStyle={SelectStyleName.Base}
            />
        </Field>
    );
});

const ApplyPlaceholder = observer((props: { onClearClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void }) => {

    // const placeholder = useMemo(() => {
    //         dispatcher.filter.setPlaceholder();
    //         dispatcher.entity.get()?.entity.filter?.placeholder
    //     }, [ dispatcher.entity.get()?.entity.filter?.savedFilter?.filterName,dispatcher.entity.get()?.entity.filter?.staticGroup?.name]);
    return (
        <>
            <span className={styles.tooltip}>
                {dispatcher.entity.get()?.entity.filter?.placeholder}
            </span>
            <span className={styles.placeholderLabel}>
                {dispatcher.entity.get()?.entity.filter?.placeholder}
            </span>

            <Button
                onClick={props.onClearClick}
                firstIcon={<Clear />}
                style={ButtonStyle.Icon}
                className={styles.clearButton}
            />
        </>
    );
});
