import { SyntheticEvent, useEffect, useState } from "react";
import InputSearch from "components/input/input-search/input-search";
import SearchCard from "./ui/SearchCard";

import styles from "./Index.module.css";
import filterTable, { filterTableType } from "./data/FilterTable";
import searchStore from "./core/SearchStore";
import { observer } from "mobx-react-lite";
import { getNoun } from "app/services/NounService";
import { Search } from "assets/icons";
import classNames from "classnames";

function GlobalSearch() {

    const [searchValue, setSearchValue] = useState<string>("");
    const [isInput, setInput] = useState<boolean>(false);
    const [isFilterAll, setFilterAll] = useState<boolean>(true);

    useEffect(() => {
        function click(event: any) {
            if (!event.target.closest('#GlobalSearch')){
                setInput(false);
            }                         
        }

        document.addEventListener("click", click);

        return () => document.removeEventListener("click", click);
    }, [])

    function search(s: string) {
        setSearchValue(s);
        setInput(true);

        searchStore.load(s);
    }

    function onSearchClick() {
            setInput(true);
    }


    function onChangeFilter(table: string, value: boolean) {
        const result: any[] = [];
        for (let filter of searchStore.filtres) {
            if (filter !== table)
                result.push(filter);
        }
        if (value) result.push(table);

        setFilterAll(false);
        searchStore.filtres = result;
        searchStore.load(searchValue, result);
    }

    function onFilterAll(value: boolean) {
        if (value) {
            let currentFiltres = filterTable.map(f => f.table);
            searchStore.filtres = currentFiltres;
            searchStore.load(searchValue, currentFiltres);
        }
        else {
            searchStore.filtres = [];
            searchStore.load(searchValue, []);
        }
        setFilterAll(value);
    }

    function getCount(count: number) {
        if (count === 0) {
            return "Нет совпадений"
        }
        else {
            return `${getNoun(count, "Найдена", "Найдено", "Найдено")}: ${count} ${getNoun(count, "запись", "записи", "записей")}`
        }
    }

    function trackScrolling(e: SyntheticEvent<HTMLDivElement>) {
        const wrappedElement = e.target as HTMLDivElement;
        if (Math.round(wrappedElement.scrollHeight - wrappedElement.scrollTop) <= wrappedElement.clientHeight + 1 && searchStore.isLoadedBatch) {
            searchStore.loadMore(searchValue)
        }
    }

    let className= classNames({
        [`${styles.search}`]: true,
        [`${styles.search} ${styles.listItem} ${styles.searchActive}`]: isInput,
    });
    
    return (
        <div style={{ position: "relative", height: "32px" }} id="GlobalSearch" >
            <InputSearch placeholder="Поиск" value={searchValue} onChangeValue={search} onClick={onSearchClick} className={className} />
            {isInput && <SearchCard entities={searchStore.entities} filtres={filterTable} onFilterChange={onChangeFilter} onFilterAll={onFilterAll}
                isCheckedFilterAll={isFilterAll} filtresActive={searchStore.filtres}
                isLoaded={searchStore.isLoaded} count={getCount(searchStore.count)}
                onScroll={trackScrolling} />}
        </div>

    )
}

export default observer(GlobalSearch);
