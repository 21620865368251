import { httpApi } from "api/http";
import { RightsItems, rightsItemsIds } from "entities/accessRights/AccessRightLinkStore";
import UserRole from "entities/userRole/UserRole";
import { makeAutoObservable, runInAction } from "mobx";
import { v4 } from "uuid";

import { UserRoleType } from "pages/sales/sales/data/Fields";
import { dispatcher } from "store";
import { Item } from "types";
import { OperationItem, RecordItem, RecordOperation, RecordRightLevel } from "types/entity";


class AccessRightsStore {
    name: string = '';
    systemName: string = '';
    userList: Item[] = [];
    currentSelectRole: Item;
    user: Item | null = null;
    userRoleType: UserRoleType;
    userRole: UserRole | null = null;
    authorUser: Item | null = null;
    authorRole: UserRole | null = null;
    granteeUser: Item | null = null;
    granteeRole: UserRole | null = null;
    isAuthorAdd: boolean = false;
    isGranteeAdd: boolean = false;

    constructor() {
        makeAutoObservable(this);

        this.currentSelectRole = RightsItems.filter(item => item.id === rightsItemsIds.Users)[0];
        this.userRoleType = UserRoleType.FunctionalRole;
    }

    setValue(fieldName: string, value: any) {
        Reflect.set(this, fieldName, value);
    }

    async selectRole(role: any) {
        this.setValue('currentSelectRole',role);
        if (role.id === rightsItemsIds.FuncRoles) {
            this.setValue('userRoleType',UserRoleType.FunctionalRole);
            //TODO for edit record
            // if (functionalRolesMainPanelStore.userRole) {
            //     this.setValue(functionalRolesMainPanelStore.userRole, 'userRole')
            // }
        } else if (role.id === rightsItemsIds.OrgRoles) {
            this.setValue('userRoleType',UserRoleType.OrganizationRole);
            //TODO for edit record
            // if (orgStructureMainPanelStore.userRole) {
            //     this.setValue(orgStructureMainPanelStore.userRole, 'userRole')
            // }
        } else if (role.id === rightsItemsIds.Users) {
            await this.getUsers();
        }
    }

    async getUsers(): Promise<void> {
        try {
            const request = await httpApi.user.userList().get();
            console.log(request);

            runInAction(() => {
                if (request /*&& request.status == 200*/) {
                    const userList = request.data.map((user: any) => ({
                        id: user.id,
                        name: user.name,
                    }));
                    this.setValue('userList',userList)
                }
            });
        } catch (error) {
            console.info(error);
        }
    }

    setUser(userId: string) {
        this.setValue("user",userId);
        this.setValue("userRole",null);
    }

    setUserRole(userRole: UserRole) {
        this.setValue("user",null);
        this.setValue("userRole",userRole);
    }

    save() {
        const sectionWizzard = dispatcher.entity.get()?.entity.sectionWizzard;
        if (sectionWizzard) {
            if (!this.isAuthorAdd && !this.isGranteeAdd) {
                const data: OperationItem = {
                    id: v4(),
                    userId: this.user ? this.user.id.toString() : null,
                    roleId: (this.userRole && this.userRole.data) ? this.userRole.data.id : null,
                    unitName: this.user ? this.user.name : (this.userRole && this.userRole.data) ? this.userRole.data.name : null,
                    canCreate: false,
                    canEdit: false,
                    canRead: false,
                    canDelete: false,
                    position: sectionWizzard.accessRightsConfig.adminByOperation.operationItems.length
                }
                dispatcher.sectionWizzard.setAdminByOperationItems(data);
            }
            if (this.isAuthorAdd) {
                this.setValue("authorUser",this.user);
                this.setValue("authorRole",this.userRole);
            }
            if (this.isGranteeAdd) {
                this.setValue("granteeUser",this.user);
                this.setValue("granteeRole",this.userRole);
            }
            this.resetRoleOrUserConfiguration();


        }
    }

    saveAccessRightByRecords() {
        const sectionWizzard = dispatcher.entity.get()?.entity.sectionWizzard;
        if (sectionWizzard) {
            if (this.isAuthorAdd || this.isGranteeAdd) {

                const position = sectionWizzard.accessRightsConfig.adminByRecords.recordItems.reduce<string[]>((resultArray, item) => {
                    if (!resultArray.find(v => v === item.groupRecordsId)) {
                        resultArray.push(item.groupRecordsId);
                    }
                    return resultArray;
                }, []).length;

                const groupRecordsId = v4();
                const data: RecordItem[] = [
                    {
                        id: v4(),
                        groupRecordsId: groupRecordsId,
                        authorUserId: this.authorUser ? this.authorUser.id.toString() : null,
                        authorRoleId: (this.authorRole && this.authorRole.data) ? this.authorRole.data.id : null,
                        granteeUserId: this.granteeUser ? this.granteeUser.id.toString() : null,
                        granteeRoleId: (this.granteeRole && this.granteeRole.data) ? this.granteeRole.data.id : null,
                        authorName: this.authorUser ? this.authorUser.name : (this.authorRole && this.authorRole.data) ? this.authorRole.data.name : null,
                        granteeName: this.granteeUser ? this.granteeUser.name : (this.granteeRole && this.granteeRole.data) ? this.granteeRole.data.name : null,
                        operation: RecordOperation.Read,
                        rightLevel: RecordRightLevel.Deny,
                        position: position
                    },
                    {
                        id: v4(),
                        groupRecordsId: groupRecordsId,
                        authorUserId: this.authorUser ? this.authorUser.id.toString() : null,
                        authorRoleId: (this.authorRole && this.authorRole.data) ? this.authorRole.data.id : null,
                        granteeUserId: this.granteeUser ? this.granteeUser.id.toString() : null,
                        granteeRoleId: (this.granteeRole && this.granteeRole.data) ? this.granteeRole.data.id : null,
                        authorName: this.authorUser ? this.authorUser.name : (this.authorRole && this.authorRole.data) ? this.authorRole.data.name : null,
                        granteeName: this.granteeUser ? this.granteeUser.name : (this.granteeRole && this.granteeRole.data) ? this.granteeRole.data.name : null,
                        operation: RecordOperation.Edit,
                        rightLevel: RecordRightLevel.Deny,
                        position: position
                    },
                    {
                        id: v4(),
                        groupRecordsId: groupRecordsId,
                        authorUserId: this.authorUser ? this.authorUser.id.toString() : null,
                        authorRoleId: (this.authorRole && this.authorRole.data) ? this.authorRole.data.id : null,
                        granteeUserId: this.granteeUser ? this.granteeUser.id.toString() : null,
                        granteeRoleId: (this.granteeRole && this.granteeRole.data) ? this.granteeRole.data.id : null,
                        authorName: this.authorUser ? this.authorUser.name : (this.authorRole && this.authorRole.data) ? this.authorRole.data.name : null,
                        granteeName: this.granteeUser ? this.granteeUser.name : (this.granteeRole && this.granteeRole.data) ? this.granteeRole.data.name : null,
                        operation: RecordOperation.Delete,
                        rightLevel: RecordRightLevel.Deny,
                        position: position
                    },
                ];

                dispatcher.sectionWizzard.setAdminByRecordItems(data);
            }
            this.resetRecordsConfiguration();
            console.log(dispatcher.entity.get()?.entity.sectionWizzard?.accessRightsConfig.adminByRecords);
        }
    }


    resetRoleOrUserConfiguration() {
        this.setValue("currentSelectRole",RightsItems.filter(item => item.id === rightsItemsIds.Users)[0]);
        this.setValue("user",null);
        this.setValue("userRoleType",UserRoleType.FunctionalRole);
        this.setValue("userRole",null);
    }

    resetRecordsConfiguration() {
        this.setValue("currentSelectRole",RightsItems.filter(item => item.id === rightsItemsIds.Users)[0], );
        this.setValue("user",null);
        this.setValue("userRoleType",UserRoleType.FunctionalRole);
        this.setValue("userRole",null);

        this.setValue("authorUser",null);
        this.setValue("authorRole",null);
        this.setValue( "granteeUser",null);
        this.setValue("granteeRole",null);
        this.setValue("isAuthorAdd",false);
        this.setValue("isGranteeAdd",false);
    }
}

const accessRightsStore = new AccessRightsStore();

export default accessRightsStore;

