import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import { v4 } from 'uuid';

import { dispatcher, selector } from 'store';
import { synchroiser } from 'synchroiser';

import MovingFolder from 'features/advanced-filter/advanced-filter-tree/moving-folder/moving-folder';
import { LayoutDeleteConfirm, RenameLayout } from 'features/advanced-filter/filter-popups';
import { modalController } from 'features/modals';

import { ButtonStyle, Select } from 'components';

import { Item } from 'types';
import { LoadingState, StaticGroup } from 'types/entity';
import { ItemActionEnum, StaticGroupActions } from '../../utils/data';

import {MoreEllipsisVertical} from 'assets/icons';

import filterFolderstyles from '../../filter-folder/filter-folder-tree-item/filter-folder-tree-item.module.css';
import styles from './static-group-folder-tree-item.module.css';


const StaticGroupFolderTreeItem = observer(function (props: {
    item: StaticGroup;
}) {
    const { item } = props;
    const [idModal] = useState<string>(v4());

    const itemClassNames = classNames(`${filterFolderstyles.listItem} ${styles.listItem}`, {
        [`${filterFolderstyles.listItemSelected}`]: selector.filter.getFilter()?.staticGroup?.id === item.id,
    })

    const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e?.stopPropagation();
        modalController.modalRemove(idModal);
    }, []);

    const handleChangeValue = useCallback((value: Item | null) => {
        if (value) {
            switch (value.id) {
                case ItemActionEnum.MoveItem:
                    modalController.popupAdd({
                        id: idModal,
                        layout: <MovingFolder
                            savedFilterFolder={null}
                            staticGroupFolder={null}
                            savedFilter={null}
                            staticGroup={item}
                            onClose={closeConfirm}
                        />,
                        closeFunc: closeConfirm
                    });
                    break;
                case ItemActionEnum.DuplicateItem:
                    duplicate();
                    break;
                case ItemActionEnum.RenameItem:
                    if (item && item.name) {
                        modalController.popupAdd({
                            id: idModal,
                            layout: <RenameLayout
                                dialogTitle='Переименовать группу'
                                startName={item.name}
                                onRename={reName}
                                onClose={closeConfirm}
                            />,
                            closeFunc: closeConfirm
                        });
                    }
                    break;
                case ItemActionEnum.DeleteItem:
                    if (item && item.id) {
                        modalController.popupAdd({
                            id: idModal,
                            layout: <LayoutDeleteConfirm
                                delete={deleteGroup}
                                dialogBody={<>
                                    <span>Вы действительно хотите удалить группу?</span>
                                    <span>Записи удалены <b>не будут.</b></span>
                                </>}
                                closeConfirm={closeConfirm}
                            />,
                            closeFunc: closeConfirm
                        });
                    }
                    break;
            }
        }
    }, [item]);

    const selectGroup = useCallback(async () => {
        if (item.id && selector.filter.getFilter()?.staticGroup?.id !== item.id) {
            dispatcher.entity.onChangeCheckedAll(false);
            dispatcher.filter.setSavedFilter(null);
            dispatcher.filter.setStaticGroup(item)
            dispatcher.filter.setPlaceholder();
            await synchroiser.getEntityWithFilter();
        }
    }, [item]);

    const duplicate = useCallback(async () => {
        if (item && item.id) {
            const staticGroup: StaticGroup = {
                userId: item.userId,
                entityName: item.entityName,
                name: item.name + ' (копия)',
                filter: item.filter,
                folderId: item.folderId,
            }

            await synchroiser.saveStaticGroup(staticGroup).then(async () => {
                if (synchroiser.loadingState !== LoadingState.Error) {
                    await synchroiser.getStaticGroupFolderTree();
                }
            })
        }
    }, [item, synchroiser.loadingState]);

    const reName = useCallback(async (groupName: string | null) => {
        if (groupName && item && item.id) {
            const updatedStaticGroup = { ...item, name: groupName } as StaticGroup;
            await synchroiser.updateStaticGroup(updatedStaticGroup).then(async () => {
                if (synchroiser.loadingState !== LoadingState.Error) {
                    await synchroiser.getStaticGroupFolderTree();
                    await synchroiser.getFiltersList();
                    item.name = groupName;
                }
            })
        }
    }, [item]);

    const deleteGroup = useCallback(async () => {
        if (item && item.id) {
            await synchroiser.deleteStaticGroup(item.id);
        }
    }, [item]);

    return (
        <>
            <li
                key={item.id}
                className={itemClassNames}
                onClick={selectGroup}
            >
                <span className={filterFolderstyles.filterName}>{item.name}</span>
                <Select
                    items={StaticGroupActions}
                    onChangeValue={handleChangeValue}
                    styles={ButtonStyle.IconIndigo}
                    firstIcon={<MoreEllipsisVertical />}
                    className={filterFolderstyles.selectFolder}
                    classNameButton={filterFolderstyles.selectFolderButton}
                    isPositionLeft={true}
                    onClick={(e) => { e.stopPropagation() }}
                />
            </li>
        </>
    );
});

export default StaticGroupFolderTreeItem;