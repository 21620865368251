import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useToggle } from "usehooks-ts";

import {Entity, dispatcher, } from "store";
import { synchroiser } from "synchroiser";

import { validateRequired, validateSchema } from "../../validation/validation";

import { Button, ButtonStyle, HorizontalField, Input, InputStyleName, Toggle, ValidationPrompt } from "components";

import { EntityNameType } from "types/entity";
import { ERROR_VALUE_EXIST_SECTION_NAME, ERROR_VALUE_EXIST_SECTION_TITLE, ValidationState } from "pages/section-wizzard/data/data";
import { UpFirst } from "entities/lowFirst";

import styles from "./global-settings.module.css";

const maxLengthSymbols = 50;

const HeadFieldsBlock = observer((props: { caption: string }) => {
	return (
		<span className={styles.headerBlockFields}>{props.caption}</span>
	);
});

const MainInfo = observer((props: { checkWarningForSetting: () => void }) => {
	const sectionWizzard = useMemo(
		() => {
			return dispatcher.entity.get()?.entity.sectionWizzard;
		}, [dispatcher.entity.get()?.entity.sectionWizzard]
	);

	const [entityTitle, setEntityTitle] = useState(sectionWizzard?.entityTitle ?? "");
	const [systemName, setSystemName] = useState(sectionWizzard?.systemName ?? "");
	const [isDisabledSystemSectionName, disabledSystemSectionNameToggle, setDisabledSystemSectionName] = useToggle();

	const [entityTitleState, setEntityTitleState] = useState<ValidationState>({
		isInvalid: false,
		isNotUnique: false,
		error: ""
	});
	const [systemNameState, setSystemNameState] = useState<ValidationState>({
		isInvalid: false,
		isNotUnique: false,
		error: ""
	});

	useEffect(() => {
		const dispEntity = dispatcher.entity.get();
		if (!dispEntity?.isNew) {
			setDisabledSystemSectionName(true);
		}
		else setDisabledSystemSectionName(false);
	}, [dispatcher.entity.get()]);

	const checkExistTitle = useCallback(async () => {
		if (await synchroiser.checkExistEntityTitle(entityTitle, EntityNameType.Sections)) {
			const notUniqueState: ValidationState = {
				isInvalid: false,
				isNotUnique: true,
				error: ERROR_VALUE_EXIST_SECTION_TITLE
			}
			setEntityTitleState(notUniqueState);
		}
		else
			setEntityTitleState(validateRequired(entityTitle));
	}, [entityTitle, entityTitleState]);

	const checkExistName = useCallback(async () => {
		if (await synchroiser.checkExistEntityName(systemName)) {
			const notUniqueState: ValidationState = {
				isInvalid: true,
				isNotUnique: false,
				error: ERROR_VALUE_EXIST_SECTION_NAME
			}
			setSystemNameState(notUniqueState);
		}
		else
			setSystemNameState(validateSchema(systemName));
	}, [systemName, systemNameState]);

	const onFocusOutFromEntityTitleInput = useCallback(async () => {
		await checkExistTitle();
		dispatcher.sectionWizzard.setEntityTitle(entityTitle);
		props.checkWarningForSetting();
	}, [entityTitle, entityTitleState, props.checkWarningForSetting]);

	const onFocusOutFromSystemNameInput = useCallback(async () => {
		await checkExistName();
		dispatcher.sectionWizzard.setSystemName(systemName);
		props.checkWarningForSetting();
	}, [systemName, systemNameState, props.checkWarningForSetting]);

	const onChangeEntityTitle = useCallback((value: string) => {
		setEntityTitle(value);
	}, []);


	const onChangeSystemName = useCallback((value: string) => {
		dispatcher.stageModel.setStageModelLookupSystemName(`${value}Stage`);
		dispatcher.stageModel.setStageModelLookupName(`${value} стадийная модель`);
        setSystemName(UpFirst(value));
	}, []);

	// useEffect(() => {
	// 	if (!entityTitleState.isInvalid && (!dispatcher.entity.get()?.isNew || !systemNameState.isInvalid) && dispatcher.entity.get()?.entity.sectionWizzard?.viewColumnId !== null) {
	// 		dispatcher.sectionWizzard.setHasChanges(true);
	// 	}
	// 	else {
	// 		dispatcher.sectionWizzard.setHasChanges(false);
	// 	}
	// }, [entityTitleState, systemNameState, dispatcher.entity.get()?.entity.sectionWizzard?.viewColumnId]);

	const nameField = useMemo(() => {
		return (
			<HorizontalField
				name="sectionName"
				caption="Название раздела"
				required={true}
				className={styles.sectionNameFieldHorizontal}
				classNameCaption={styles.mainFieldCaption}
			>
				<Input
					value={entityTitle}
					placeholder=""
					maxLength={maxLengthSymbols}
					onChangeValue={onChangeEntityTitle}
					inputStyle={InputStyleName.FullViewInput}
					isInvalid={entityTitleState.isInvalid}
					invalidMessage={entityTitleState.error}
					isNotUnique={entityTitleState.isNotUnique}
					onFocusOut={onFocusOutFromEntityTitleInput}
				/>
			</HorizontalField>
		);
	}, [entityTitle, entityTitleState]);

	const systemNameField = useMemo(() => {
		return (
			<HorizontalField
				name="systemSectionName"
				caption="Системное название раздела"
				required={true}
				className={styles.sectionNameFieldHorizontal}
				classNameCaption={styles.mainFieldCaption}
			>
				<Input
					value={systemName}
					placeholder=""
					maxLength={maxLengthSymbols}
					isDisabled={isDisabledSystemSectionName}
					isDisabledLocked={isDisabledSystemSectionName}
					onChangeValue={onChangeSystemName}
					inputStyle={InputStyleName.FullViewInput}
					isInvalid={systemNameState.isInvalid}
					invalidMessage={systemNameState.error}
					isNotUnique={systemNameState.isNotUnique}
					onFocusOut={onFocusOutFromSystemNameInput}
				/>
			</HorizontalField>
		);
	}, [systemName, systemNameState, isDisabledSystemSectionName]);

	return (
		<div className={styles.blockSettings}>
			<HeadFieldsBlock caption="Ключевая информация" />
			<div className={styles.fiedsBlock}>
				{nameField}
				{systemNameField}
				<ValidationPrompt
					header="Обратите внимание"
					text={`Допустимые символы для поля [Системное название раздела]:\nлатинские буквы без пробелов, цифры, символ подчеркивания и дефис.`}
					example="Пример: «Contact-Address_02»"
				/>
			</div>
		</div >
	);
});

const SectionDisplayModes = observer(() => {
	const sectionWizzard = useMemo(
		() => {
			return dispatcher.entity.get()?.entity.sectionWizzard;
		}, [dispatcher.entity.get()?.entity.sectionWizzard]
	);
	const kanbanIsEnabled = useMemo(
		() => {
			return sectionWizzard?.kanbanIsEnabled;
		}, [sectionWizzard?.kanbanIsEnabled]
	);
	const hasCalendar = useMemo(
		() => {
			return sectionWizzard?.hasCalendar;
		}, [sectionWizzard?.hasCalendar]
	);
	const hasSpecificFinish = useMemo(
		() => {
			return sectionWizzard?.hasSpecificFinish;
		}, [sectionWizzard?.hasSpecificFinish]
	);
	const [isKanban, kanbanToggle, setKanban] = useToggle(kanbanIsEnabled ?? false);
	const [isCalendar, calendarToggle, setCalendar] = useToggle(hasCalendar ?? false);
	const [isSpecificFinish, analyticsToggle, setAnalytics] = useToggle(hasSpecificFinish ?? false);

	const [isKanbanDisabled, kanbanDisabledToggle, setKanbanDisabled] = useToggle(true);
	const [isCalendarDisabled, calendarDisabledToggle, setCalendarDisabled] = useToggle(true);
	const [isAnalyticsDisabled, analyticsDisabledToggle, setAnalyticsDisabled] = useToggle(true);

	useEffect(() => {
		setKanbanDisabled(false);
	}, []);

	//TODO пока не используется
	// useEffect(() => {
	// 	dispatcher.sectionWizzard.setKanbanIsEnabled(isKanban);
	// }, [isKanban]);
	// useEffect(() => {
	// 	dispatcher.sectionWizzard.setHasCalendar(isCalendar);
	// }, [isCalendar]);
	// useEffect(() => {
	// 	dispatcher.sectionWizzard.setHasSpecificFinish(isSpecificFinish);
	// }, [hasSpecificFinish]);

	return (
		<div className={styles.blockSettings}>
			<HeadFieldsBlock caption="Режимы отображения для раздела" />
			<div className={styles.fiedsBlock}>
				<HorizontalField
					name="isKanban"
					caption="Доска Kanban"
					infoCaption="Классическая Kanban доска"
					className={styles.fieldHorizontal}
					classNameCaption={styles.fieldCaption}
				>
					<Toggle
						checked={isKanban}
						onCheck={kanbanToggle}
						isDisabled={isKanbanDisabled}
					/>
				</HorizontalField>
				<HorizontalField
					name="isCalendar"
					caption="Календарь"
					infoCaption="Как в Outlook’е"
					className={styles.fieldHorizontal}
					classNameCaption={styles.fieldCaption}
				>
					<Toggle
						checked={isCalendar}
						onCheck={calendarToggle}
						isDisabled={isCalendarDisabled}
					/>
				</HorizontalField>
				<HorizontalField
					name="isAnalytics"
					caption="Итоги"
					infoCaption="Настраиваемые дашборды для аналитки"
					className={styles.fieldHorizontal}
					classNameCaption={styles.fieldCaption}
				>
					<Toggle
						checked={isSpecificFinish}
						onCheck={analyticsToggle}
						isDisabled={isAnalyticsDisabled}
					/>
				</HorizontalField>
			</div>
		</div >
	);
});

const ExistingSectionSettings = observer(() => {

	//TODO дописать метод для кнопки "выбрать раздел"
	const chooseSection = useCallback(() => {

	}, []);

	return (
		<div className={styles.blockSettings}>
			<HeadFieldsBlock caption="Настройка по существующему разделу" />
			<div className={styles.fiedsBlock}>
				<span className={styles.fieldCaption}>Создайте новый раздел по образу уже существующего в системе.</span>
				<Button
					caption="Выбрать раздел..."
					className={styles.chooseSectionButton}
					style={ButtonStyle.LightBlue}
					onClick={chooseSection}
				/>
			</div>
		</div >
	);
});

const GlobalSettingsPanel = observer((props: { checkWarningForSetting: () => void }) => {
	const [entity, setEntity] = useState<Entity>();

	useEffect(() => {
		const dispEntity = dispatcher.entity.get();
		setEntity(dispEntity);
	}, [dispatcher.entity.get()]);

	if (entity?.entity?.sectionWizzard)
		return (
			<div className={styles.wrapper}>
				<MainInfo checkWarningForSetting={props.checkWarningForSetting} />
				<SectionDisplayModes />
				<ExistingSectionSettings />
			</div >
		);
	else return <></>;
});
export default GlobalSettingsPanel;