import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { observer } from "mobx-react";
import classNames from "classnames";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { modalController } from "features/modals";
import exportStore from "features/export-block/core/export-store";
import { Entity, store } from "store/store";
import { dispatcher } from "store/store-dipatcher/dispatcher";
import { synchroiser } from "synchroiser";
import { selector } from "store";

import { Button, ButtonStyle } from "components";
import GeneralizedGrid from "components/grid/generalized-grid/generalized-grid";
import LeftSectionHead from "./components/left-section-head/left-section-head";
import RightSectionHead from "./components/right-section-head/right-section-head";
import SectionHeadFooter from "./components/section-head-footer/section-head-footer";
import AdvancedFilterTree from "features/advanced-filter/advanced-filter-tree/advanced-filter-tree";
import AdvancedFilterElement from "features/advanced-filter/advanced-filter";
import { NotFoundPage } from "pages/error-page/error-page";
import ExportBlock from "features/export-block/export-block";
import StaticGroupBlock from "features/advanced-filter/static-group-block/static-group-block";
import SavedFilterFolder from "entities/filter/SavedFilterFolder";
import StaticGroupFolder from "entities/filter/StaticGroupFolder";

import { ExcludeRecordsFromStaticGroup, LoadingState } from "types/entity";
import { IContextMenuOption } from "components/grid/data/data";
import { ExportFormats } from "pages/settings/data/Fields";
import { AdvancedFilterConst } from "features/section-head/data/constants";
import template from "features/filter/simpleFilter/core/State";

import { GridIcon, Kanban, Warning } from "assets/icons";

import styles from "features/section-head/section-head.module.css";

const WARNING_TEXT = "Вы действительно хотите удалить выбранные записи?\nЭто действие невозможно отменить";

const SinglePage = observer(() => {
    const [idModal] = useState<string>(v4());
    const { entityName } = useParams();
    const navigate = useNavigate();
    const [entity, setEntity] = useState<Entity | undefined>(undefined);
    const [isOpenExportBlock, setOpenExportBlock] = useState(false);

    const [tab, setTab] = useState<JSX.Element>();

    const init = useCallback(async () => {
        await synchroiser.switchSection(entityName!).then(() => {
            const dispatcherEntity = dispatcher.entity.get();
            setEntity(dispatcherEntity);
        });
    }, [entityName]);

    useEffect(() => {
        if (entityName) {
            init();
        }
    }, [entityName]);

    useEffect(() => {
        const dispatcherEntity = dispatcher.entity.get();
        setEntity(dispatcherEntity);
    }, [dispatcher.entity.get()]);


    const excludeRecordsFromGroup = useCallback(async () => {
        const includedIds: string[] = entity?.entity.includedIds ?
            entity!.entity.includedIds.map(({ id }) => id) : [];

        const excludedIds: string[] = entity?.entity.excludedIds ?
            entity!.entity.excludedIds.map(({ id }) => id) : [];


        const data: ExcludeRecordsFromStaticGroup = {
            staticGroupId: selector.filter.getFilter()?.staticGroup!.id!,
            recordsToExclude: includedIds,
            recordsToSave: excludedIds

        };

        await synchroiser.excludeRecordsFromStaticGroup(data);
    }, [entity]);

    const openRecord = useCallback(() => {
        if (entity?.entity.includedIds && entity?.entity.includedIds.length > 0)
            navigate(entity?.entity.includedIds[0].id);
    }, [entity])

    const openRecordInNewPage = useCallback(() => {
        if (entity?.entity.includedIds && entity?.entity.includedIds.length > 0)
            window.open(window.location.href + "/" + entity?.entity.includedIds[0].id)
    }, [entity])

    //TODO реализовать создание дубля записи
    const createDublicate = useCallback(() => {

    }, [])

    const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e?.stopPropagation();
        modalController.modalRemove(idModal);
    }, []);

    const deleteRecords = useCallback(async () => {
        if (dispatcher.entity.get()?.entity) {
            await synchroiser.deleteRecords();
            await init();
        }
        closeConfirm();
    }, [dispatcher.entity.get()?.entity])

    const warningConfirm = useMemo(() => {
        return <div className={styles.warningDialog}>
            <div className={styles.warningHeader}>
                <span className={styles.warningTitle}>Внимание</span>
                <Warning />
            </div>
            <div className={styles.warningDialogBody}>
                <span className={styles.warningText}>{WARNING_TEXT}</span>
            </div>
            <div className={styles.dialogFooter}>
                <Button caption="Отмена" onClick={closeConfirm} style={ButtonStyle.Subtle}
                    isDisabled={false} />
                <Button caption="Удалить" onClick={deleteRecords} style={ButtonStyle.Danger} isDisabled={false} />
            </div>
        </div>;
    }, [closeConfirm]);

    const handleDelete = useCallback(() => {
        modalController.popupAdd({ id: idModal, layout: warningConfirm, closeFunc: closeConfirm });
    }, [warningConfirm]);

    const exportRecords = useCallback(() => {
        setOpenExportBlock(true);
        exportStore.initSignalR();
    }, [])

    const addToGroup = useCallback(() => {
        if (selector.filter.getFilter()?.staticGroup) {
            excludeRecordsFromGroup();
        } else {
            modalController.popupAdd({
                id: idModal,
                layout: <StaticGroupBlock onCancel={closeConfirm} />,
                closeFunc: closeConfirm
            });
        }
    }, [entity])

    const handleCheckAll = useCallback((value: boolean) => {
        dispatcher.entity.onChangeCheckedAll(value);
    }, [])

    const contextMenuWhenOneCheckedElement: IContextMenuOption[] = useMemo(() => {
        return [
            {
                caption: 'Открыть запись',
                action: openRecord,
                isDivider: false
            },
            {
                caption: 'Открыть в новой вкладке',
                action: openRecordInNewPage,
                isDivider: false
            },
            {
                caption: 'Дублировать',
                action: createDublicate,
                isDivider: true
            },
            {
                caption: 'Удалить',
                action: handleDelete,
                isDivider: false
            }
        ]
    }, [entity])

    const contextMenuWhenMoreCheckedElements: IContextMenuOption[] = useMemo(() => {
        return [
            {
                caption: 'Экспортировать выбранное...',
                action: exportRecords,
                isDivider: false
            },
            {
                caption: 'Добавить в группу...',
                action: addToGroup,
                isDivider: true
            },
            {
                caption: 'Удалить',
                action: handleDelete,
                isDivider: false
            }
        ]
    }, [entity])

    const contextMenuOptions: IContextMenuOption[] = useMemo(() => {
        if (entity?.entity.isCheckedAll ||
            (entity?.entity.includedIds && entity?.entity.includedIds.length! > 1)) {
            return contextMenuWhenMoreCheckedElements;
        }
        else {
            return contextMenuWhenOneCheckedElement;
        }
    }, [entity?.entity.isCheckedAll, entity?.entity.includedIds.map(item => item)])

    const tabs = useMemo(() => {
        return [
            {
                action: () => {
                    setTab(<>Представьте доску Kanban</>);
                },
                icon: <Kanban />,
                tooltipCaption: "Доска Kanban"

            },
            {
                action: () => {
                    setTab(entity ?
                        <GeneralizedGrid
                            entity={entity}
                            contextMenuOptions={contextMenuOptions}
                            onChangeCheckedAll={handleCheckAll}
                        />
                        : <>Loading...</>
                    );
                },
                icon: <GridIcon />,
                tooltipCaption: "Список"
            },
        ];
    }, [entity, toJS(contextMenuOptions)]);

    const pageBody = useMemo(() => {
        return entity && (entity.isKanban ?
            tab :
            <GeneralizedGrid
                entity={entity}
                contextMenuOptions={contextMenuOptions}
                onChangeCheckedAll={handleCheckAll}
            />
        );
    }, [entity, tab, contextMenuOptions]);

    const classes: any = {};
    classes[`${styles.hide}`] = !(AdvancedFilterConst.isOpenAdvancedFilterTree || AdvancedFilterConst.isOpenAdvancedFilter);
    let cx = classNames(`${styles.sectionPage} `, classes);

    return (
        (synchroiser.loadingState === LoadingState.Error && !synchroiser.errorMessage) ?
            <NotFoundPage />
            :
            <div className={cx}>
                <AdvancedFilterTree isOpen={AdvancedFilterConst.isOpenAdvancedFilterTree} />
                <AdvancedFilterElement
                    isOpen={AdvancedFilterConst.isOpenAdvancedFilter && AdvancedFilterConst.filter !== null}
                    advancedFilter={AdvancedFilterConst.filter!}
                />
                <div className={styles.sectionHeadContent}>
                    <div className={styles.sectionHead}>
                        <div className={styles.sectionHeadTop}>
                            <LeftSectionHead entity={entity} />
                            <RightSectionHead entity={entity} tabs={entity?.isKanban ? tabs : null} openExportBlock={setOpenExportBlock} openAddToGroupModal={addToGroup} />
                        </div>
                        <div className={styles.sectionHeadFooter}>
                            <SectionHeadFooter entity={entity} />
                        </div>
                    </div>
                    {pageBody}
                    {entity?.entityName && <ExportBlock
                        schema={entity?.entityName!}
                        pageTitle={entity?.entityTitle!}
                        listStore={undefined}
                        formats={ExportFormats}
                        title={"Экспорт данных: " + entity?.entityTitle!}
                        choosingCount={entity?.entity.countOfChecked!}
                        allCount={entity?.entity.quality!}
                        isOpen={isOpenExportBlock}
                        onClick={() => {
                            setOpenExportBlock(false);
                            dispatcher.entity.onChangeCheckedAll(false)
                        }}
                    />}

                </div>
            </div>

    );
});

export default SinglePage;