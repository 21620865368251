import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import {
  Button,
  ButtonStyle,
  Dialog,
  WarningDialog,
  InputSearch,
  Select,
  DataGrid,
  Field,
  Input,
  SearchSelect,
  ValidationPrompt,
} from "components";
import NavigationTitle from "../NavigationTitle";

import { actionEnums } from "../data/Fields";
import { UsersActions } from "../data/Fields";
import { UserColumns } from "../data/Fields";
import { validateRequired, validateEmail, validatePassword } from "entities/Validation";
import userMainPanelStore from "./SystemUsersMainPanelStore";
import Entity, { IEntityStore } from "entities/Entity";
import { User } from "entities/user/User";
import { Item } from "types";

import { AddUserIcon, InfoIcon } from "assets/icons";
import { ArrowToDownMini } from "assets/icons";

import styles from "./SystemUsers.module.css";

const SystemUsersMainPanel = observer(function () {
  let [searchValue, setSearchValue] = useState("");
  const [entity, setEntity] = useState<Entity<User>>(
    new Entity<User>(new User())
  );

  useEffect(() => {
    userMainPanelStore.setEntityCount();
  }, []);

  function search(s: string) {
    setSearchValue(s);
  }

  async function onChangeActions(value: Item | null) {
    if (value?.id === actionEnums.ChooseAll) {
      //выбрать все
      userMainPanelStore.userStore?.onChangeCheckedAll(true);
    }
    if (value?.id === actionEnums.GroupTo) {
      //в группу
    }
    if (value?.id === actionEnums.Cancel) {
      //отменить множ выбор
      userMainPanelStore.userStore?.onChangeCheckedAll(false);
    }
    if (value?.id === actionEnums.Deactive) {
      //деактивировать

      let promises: Promise<boolean>[] = [];
      if (userMainPanelStore.userStore) {
        if (userMainPanelStore.userStore.includedIds.length > 0)
          promises = userMainPanelStore.userStore?.includedIds.map(
            async (element) => {
              const deleted = await deactivateUser(element);
              return deleted;
            }
          );
        else if (userMainPanelStore.userStore.isCheckedAll) {
          if (userMainPanelStore.userStore.excludedIds.length > 0)
            promises = userMainPanelStore.userStore?.data.map(async (item) => {
              let index = userMainPanelStore.userStore?.excludedIds.findIndex(
                (exc) => exc.id === item.id
              );
              let deleted = true;
              if (index === -1) deleted = await deactivateUser(item);
              return deleted;
            });
          else
            promises = userMainPanelStore.userStore?.data.map(async (item) => {
              const deleted = await deactivateUser(item);
              return deleted;
            });
        }
        const uploadFiles = await Promise.all(promises);
        await userMainPanelStore.userStore?.load(null);
      }
    }
  }

  async function save() {
    if (entity && entity.entity.isValid) {
      let saved = await entity.save();
      if (saved) userMainPanelStore.userStore!.load(null);
    }
  }
  async function deactivateUser(user: any): Promise<boolean> {
    if (entity) {
      entity.load(user.id);
      entity.entity.setValue(false, "isActive");
      let saved = await entity.save();
      return saved;
    }
    return false;
  }

  return (
    <>
      <div className={styles.userMainPanel}>
        <NavigationTitle />
        <div className={styles.secondTitle}>
          <div className={styles.countUsers}>
            <div className={styles.usersNumber}>
              {userMainPanelStore.entityCount}
            </div>
            <div className={styles.numberTitle}>
              Созданных пользователей в экосистеме
            </div>
          </div>
          {/* <Button
                        caption={'Пригласить...'}
                        // onClick={() => { userEntity.new(new User()); }}
                        style={ButtonStyle.Link}
                        className={styles.inviteButton}
                    /> */}
          <Button
            firstIcon={<AddUserIcon />}
            caption={"Новый пользователь"}
            onClick={() => {
              entity.new(new User());
            }}
            style={ButtonStyle.Primary}
            className={styles.newUserButton}
          />
          <Dialog
            title="Новый пользователь"
            isOpen={entity.openedDialog}
            dialogFooterButtons={
              <>
                <Button
                  caption={"Отмена"}
                  onClick={entity.cancelClick}
                  style={ButtonStyle.Subtle}
                />
                <Button
                  caption={"Сохранить"}
                  onClick={save}
                  style={ButtonStyle.Success}
                  isDisabled={!entity.entity.isValid}
                />
              </>
            }
            onClick={entity.cancelClick}
          >
            {<UsersDialogFilling entity={entity} />}
          </Dialog>
          <WarningDialog
            isOpen={entity.openedWarningDialog}
            onBackClick={entity.setWarningDialog}
            onCancelClick={entity.cancelChanges}
          />
        </div>
        <div className={styles.thirdTitle}>
          <div className={styles.searchingTitle}>
            <InputSearch
              placeholder="Фильтр + поиск"
              value={searchValue}
              onChangeValue={search}
              classNameInput={styles.searchUserGrid}
            />
            {userMainPanelStore.userStore &&
              userMainPanelStore.userStore.countOfChecked > 0 && (
                <CounterChoosingUsers
                  counter={userMainPanelStore.userStore.countOfChecked}
                />
              )}
          </div>

          <div className={styles.actionsButton}>
            <Select
              name={"Действия"}
              items={UsersActions}
              onChangeValue={onChangeActions}
              secondIcon={<ArrowToDownMini />}
              isRotateSecondIcon={true}
              styles={ButtonStyle.Link}
              classNameButton={styles.actionButton}
            />
          </div>
        </div>

        <div className={styles.grid}>
          <DataGrid
            maxHeight={"calc(100vh - 260px)"}
            columns={UserColumns}
            listStore={userMainPanelStore.userStore!}
            isCheckBox={true}
          />
        </div>
      </div>
      :<></>
    </>
  );
});
export default SystemUsersMainPanel;

const CounterChoosingUsers = observer(function (props: { counter: number }) {
  return (
    <div className={styles.counter}>
      <div className={styles.choosingUsers}>выбрано пользователей</div>
      <div className={styles.counterIcon}>{props.counter}</div>
    </div>
  );
});


export const UsersDialogFilling = observer(
  (props: { entity: Entity<IEntityStore> }) => {
    let entity = props.entity as Entity<User>;
    return (
      <>
        {entity.isNew && (
          <>
            <Field name={"surName"} caption={"Фамилия"} required={true}>
              <Input
                value={entity.entity.surName}
                placeholder={""}
                onChangeValue={(value: string) => {
                  entity.entity.setValue(value, "surName");
                  validateRequired(value, entity.entity.validation.surName);
                }}
                className={styles.input}
                isNeedCorrect={true}
                isInvalid={entity.entity.validation.surName.isInvalid}
                invalidMessage={entity.entity.validation.surName.error}
                onFocusOut={() =>
                  validateRequired(
                    entity.entity.surName,
                    entity.entity.validation.surName
                  )
                }
              />
            </Field>
            <Field name={"giveName"} caption={"Имя"} required={true}>
              <Input
                value={entity.entity.giveName}
                placeholder={""}
                onChangeValue={(value: string) => {
                  entity.entity.setValue(value, "giveName");
                  validateRequired(value, entity.entity.validation.giveName);
                }}
                className={styles.input}
                isNeedCorrect={true}
                isInvalid={entity.entity.validation.giveName.isInvalid}
                invalidMessage={entity.entity.validation.giveName.error}
                onFocusOut={() =>
                  validateRequired(
                    entity.entity.giveName,
                    entity.entity.validation.giveName
                  )
                }
              />
            </Field>
            <Field name={"middleName"} caption={"Отчество"}>
              <Input
                value={entity.entity.middleName}
                placeholder={""}
                onChangeValue={(value: string) => {
                  entity.entity.setValue(value, "middleName");
                  validateRequired(value, entity.entity.validation.middleName);
                }}
                className={styles.input}
              />
            </Field>

            <Field name={"email"} caption={"Email"} required={true}>
              <Input
                value={entity.entity.email}
                placeholder={"example@web.com"}
                onChangeValue={(value: string) => {
                  entity.entity.setValue(value, "email");
                  validateEmail(value, entity.entity.validation.email);
                  entity.entity.setValue(entity.entity.email, "login");
                }}
                className={styles.input}
                isNeedCorrect={true}
                isInvalid={entity.entity.validation.email.isInvalid}
                invalidMessage={entity.entity.validation.email.error}
                onFocusOut={() =>
                  validateEmail(
                    entity.entity.email,
                    entity.entity.validation.email
                  )
                }
              />
            </Field>

            <Field name={"password"} caption={"Пароль"} required={true}>
              <Input
                value={entity.entity.password}
                placeholder={""}
                onChangeValue={(value: string) => {
                  entity.entity.setValue(value, "password");
                  validatePassword(value, entity.entity.validation.password);
                }}
                className={styles.input}
                isNeedCorrect={true}
                isInvalid={entity.entity.validation.password.isInvalid}
                invalidMessage={entity.entity.validation.password.error}
                onFocusOut={() =>
                  validatePassword(
                    entity.entity.password,
                    entity.entity.validation.password
                  )
                }
              />
            </Field>
            <ValidationPrompt 
                text="При первом входе система предложит пользователю сменить пароль."
                promtIcon={styles.promtIcon}
                centerAlign
            />

            <Field name={"position"} caption={"Должность"}>
              <Input
                value={entity.entity.position}
                placeholder={""}
                onChangeValue={(value: string) => {
                  entity.entity.setValue(value, "position");
                }}
                className={styles.input}
              />
            </Field>
            <Field name={"department"} caption={"Отдел"}>
              <SearchSelect
                items={entity.lookups["Department"]}
                isInput={true}
                onItemsLoad={async (s) => {
                  return await entity.loadLookups("Department", s, null, false);
                }}
                value={entity.entity.department as Item}
                className={styles.select}
                onChangeValue={(value: any) => {
                  entity.entity.setValue(value, "department");
                }}
              />
            </Field>
          </>
        )}
      </>
    );
  }
);
