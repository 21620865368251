import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, } from "react-router-dom";
import { observer } from "mobx-react";
import classNames from "classnames";

import { IStageIndicationPanel,  Button, ButtonStyle, Select, StageIndicationPanel, Tabs } from "components";
import AdditionalBlock from "features/additional-block/additional-block";
import DetailDropDown, { IDetailDropDownProps } from "features/detail-drop-down/detail-drop-down";
import { Actions } from "features/section-head/data/constants";
import Files from "./files/files/files";

import Entity, { IEntityStore } from "entities/Entity";
import SectionRoute from "entities/SectionRoute";
import IStage from "entities/IStage";
import ImportStore from "entities/import/ImportStore";
import { CommentEntity } from "entities/CommentEntity";
import { Item } from "types";

import { ArrowToRightStretch } from "assets/icons";
import { ArrowToDownMini } from "assets/icons";
import { GoBack } from "assets/icons";
import { TimeZoneGrey } from "assets/icons";

import styles from "./full-view.module.css";

export interface IProfileData {
    title: string;
    icon: JSX.Element;
    body: JSX.Element;
    editable?: boolean;
    onCopyClick?: () => void
}

export interface IMainInformation {
    title: string;
    dontDisplayTitle?: boolean
    mainInformationDataLeft: JSX.Element;
    mainInformationDataRight: JSX.Element;
}

export interface IFullView {
    entity: Entity<IEntityStore>;
    mainInformation: IMainInformation;
    mainInformationDetails?: IDetailDropDownProps[];
    stages?: IStageIndicationPanel | null;
}


const FullView = observer((props: IFullView) => {
    const history = useNavigate();
    const [notModifiedStage, setNotModifiedStage] = useState<IStage | null>(null);
    const [importStore, setImportStore] = useState<ImportStore | null>(null);
    const [comment, setComment] = useState<CommentEntity | null>(null);

    const fullViewContentClasses = classNames({
        [`${styles.fullViewContent} `]: true,
        [`${styles.withoutStages}`]: !(props.stages && notModifiedStage),
    });

    useEffect(() => {
        load();
        if (props.stages && props.stages.stage)
            setNotModifiedStage(props.stages.stage);
        setImportStore(new ImportStore(props.entity.entity.schema));

    }, []);

    useEffect(() => {
        if (importStore !== null) {
            const setMaxSizeFileInImortStore = async () => { await importStore.getMaxFileSize(); };
            setMaxSizeFileInImortStore();
        }
    }, [importStore]);

    const onBackClick = useCallback(() => {
        history(-1);
        props.entity.entity.resetValidate();
        props.entity.setValue(false, "hasChanges");
    }, [props.entity]);

    const save = useCallback(async () => {
        if (props.stages && notModifiedStage && notModifiedStage?.id !== props.stages.stage.id)
            props.entity.entity.setValue(props.stages.stage, "stage");
        await props.entity.save();
    }, [props]);

    const onFullViewTitleClick = useCallback(() => {
        let section = (Object.keys(SectionRoute) as Array<keyof typeof SectionRoute>).find((key) => key.includes(props.entity.entity.schema));
        if (section) history(`/section/${section?.toLowerCase()}`);
    }, [props.entity.entity.schema]);

    const load = useCallback(async () => {
        const quickComment = new CommentEntity(props.entity.entity.schema, props.entity.entity.id);
        setComment(quickComment);
        await quickComment.loadComments(true);
    }, [props.entity.entity.schema, props.entity.entity.id]);

    const onChangeFinishedStage = useCallback((value: Item | null) => {
        props.entity.entity.setValue(value!, "stage");
    }, [props.entity.entity]);

    const onChangeStage = useCallback((value: Item) => {
        props.entity.entity.setValue(value, "stage");
    }, [props.entity.entity]);


    return (
        <div className={styles.fullView}>
            <div className={styles.fullViewHeader}>
                <div className={styles.buttons}>
                    <Button
                        key="closeButton"
                        firstIcon={<GoBack />}
                        caption={"Назад"}
                        onClick={onBackClick}
                        isVisible={!props.entity.hasChanges}
                        style={ButtonStyle.Link}
                        className={styles.button}
                    />
                    <Button
                        key="cancel"
                        caption={"Отменить"}
                        onClick={props.entity.cancelChanges}
                        isVisible={props.entity.hasChanges}
                        style={ButtonStyle.Subtle}
                        className={styles.button}
                    />
                    <Button
                        key="save"
                        caption={"Сохранить изменения"}
                        onClick={save}
                        isVisible={props.entity.hasChanges}
                        style={ButtonStyle.Success}
                        className={styles.button}
                    />
                </div>
                <div className={styles.fullViewTitle}>
                    <span
                        className={styles.fullViewTitleSection}
                        onClick={onFullViewTitleClick}>
                        {props.entity.entity.displaySchema}
                    </span>
                    <ArrowToRightStretch />
                    <span className={styles.fullViewTitleDetail}>{props.mainInformation.title}</span>
                </div>
                <Select
                    name={"Действия"}
                    items={Actions}
                    onChangeValue={() => { }}
                    secondIcon={<ArrowToDownMini />}
                    isRotateSecondIcon={true}
                    styles={ButtonStyle.Link}
                    classNameButton={styles.actionButton}
                />
            </div>
            <div className={fullViewContentClasses}>
                {props.stages && notModifiedStage &&
                    <div className={styles.fullViewContentHeader}>
                        <div className={styles.fullViewStageIndication}>
                            <StageIndicationPanel stages={props.stages.stages}
                                notModifiedStage={notModifiedStage}
                                stage={props.stages.stage}
                                onChangeFinishedStage={onChangeFinishedStage}
                                classname={props.stages.classname}
                                onChangeStage={onChangeStage}
                            />
                        </div>
                    </div>
                }
                <div className={styles.fullViewContentBody}>
                    <MainPanel
                        mainInformationDetails={props.mainInformationDetails}
                        mainInformation={props.mainInformation}
                        importStore={importStore}
                    />
                    <div className={styles.additionalBlock}>
                        {comment &&
                            <AdditionalBlock
                                id={props.entity.entity.id}
                                commentEntity={comment}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
});


const MainPanel = observer((props: { mainInformationDetails?: IDetailDropDownProps[], mainInformation: IMainInformation, importStore: ImportStore | null }) => {
    const [tab, setTab] = useState<JSX.Element>(<></>);
    const tabs = useMemo(() => {
        return [
            {
                caption: "Основная информация",
                action: () => {
                    setTab(
                        <MainInformation mainInformation={props.mainInformation} details={props.mainInformationDetails} />
                    );
                }

            },
            {
                caption: "Файлы",
                action: async () => {
                    if (props.importStore)
                        setTab(<Files importStore={props.importStore} />);
                    else
                        setTab(<></>);

                }
            },
        ];
    }, [props]);

    return (
        <div className={styles.fullViewMainPanel}>
            <Tabs items={tabs} className={styles.fullViewMainPanelTabs} />
            <div className={styles.fullViewMainPanelBody}>
                {tab}
            </div>
        </div>
    );
});

//TODO доделать детали
const MainInformation = observer((props: { mainInformation: IMainInformation, details?: IDetailDropDownProps[]; }) => {
    return (
        <div className={styles.fullViewBasicInformation}>
            {!props.mainInformation.dontDisplayTitle &&
                <div className={styles.fullViewBasicInformationHeader}>
                    <span className={styles.fullViewBasicInformationName}>
                        {props.mainInformation.title}
                    </span>
                    <span className={styles.fullViewBasicInformationTimeZone}>
                        <TimeZoneGrey />
                        22:16, Москва
                    </span>
                </div>
            }
            <div className={styles.fullViewBasicInformationData}>
                <div className={styles.fullViewBasicInformationDataLeft}>
                    {props.mainInformation.mainInformationDataLeft}
                </div>
                <div className={styles.fullViewBasicInformationDataRight}>
                    {props.mainInformation.mainInformationDataRight}
                </div>
            </div>
            {
                props.details &&
                props.details.map((detail) => {
                    return (
                        <DetailDropDown caption={detail.caption} iconCaption={detail.iconCaption}
                            body={detail.body} items={detail.items} onChangeValue={detail.onChangeValue} />
                    );
                })
            }
            {/* <DetailDropDown caption={"Средства связи"} iconCaption={<MeansOfCommunication />}
                body={meansOfCommunicationBody} items={meansOfCommunicationItems} onChangeValue={change} />
            <DetailDropDown caption={"Карьера"} iconCaption={<Career />} body={props.entity.entity.primaryAccount ? careerBody : null} />
            <DetailDropDown caption={"Адреса"} iconCaption={<Address />} body={null} />
            <DetailDropDown caption={"Заметки"} iconCaption={<NotesIcon />} body={<Notes value={props.entity.entity.notes} onChangeValue={(value) => { props.entity.entity.setValue(value, "notes") }} />} /> */}
        </div>
    );
});


export default FullView;