import axios from "axios";
import { runInAction } from "mobx";
import { sessionStore, store } from "store";
import setAutoLogoutTimeout from "app/services/apiUtils";
import authStore from "AuthStore";


/**
 * @class TokenManager - Менеджер токенов
 * @param {string} refreshTokenUrl - URL для обновления токена
 */
class TokenManager {
	private refreshTokenUrl: string;

	constructor(refreshTokenUrl: string) {
		this.refreshTokenUrl = refreshTokenUrl;
	}

	/**
	 * Обновляет токен
	 */
	async refreshToken() {
		try {
			const response = await axios.post(this.refreshTokenUrl, null, {
				withCredentials: true,
			});
			runInAction(() => {
				authStore.token = response.data.accessToken;
				authStore.tokenExpiration = response.data.expires;
                
				store.session.accessToken = response.data.accessToken;
				store.session.tokenExpiration = response.data.expires;

				setAutoLogoutTimeout(response.data.expires);
			});
		} catch (error) {
			authStore.logOut();
			sessionStore.logout();
			console.error("Error refreshing token:", error);
		}
	}
}

export default TokenManager;
