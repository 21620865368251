import { useCallback } from "react";
import { observer } from "mobx-react-lite";

import { dispatcher } from "store";
import { validateRequired } from "entities/Validation";
import { UpFirst } from "entities/lowFirst";
import newTabConfigurationStore, { FieldNameFromStore } from "./new-tab-configuration-store";

import { Field, Input, InputStyleName, ButtonStyle, Button } from "components";

import { CloseMaxi } from "assets/icons";

import styles from "../tabs-panel.module.css";

interface INewTabConfigurationProps {
    close: () => void,
}

const NewTabConfiguration = observer((props: INewTabConfigurationProps) => {

    const handleSave = useCallback(() => {
        const index = dispatcher.sectionWizzard.getLastIndexForAddTab() + 1;
        newTabConfigurationStore.createNewTabInMainPanel(index);
        props.close();
        newTabConfigurationStore.resetConfiguration();
    }, [newTabConfigurationStore.name, newTabConfigurationStore.systemName, props.close]);

    const handleCancel = useCallback(() => {
        props.close();
        newTabConfigurationStore.resetConfiguration();
    }, [props.close]);

    const handleTitleChange = useCallback((value: string) => {
        newTabConfigurationStore.setValue(value, FieldNameFromStore.name);
    }, []);

    const handleTitleFocusOut = useCallback(() => {
        newTabConfigurationStore.validateTitle();
        newTabConfigurationStore.validTabConfig();
    }, []);

    const handleSystemNameChange = useCallback((value: string) => {
        newTabConfigurationStore.setValue(UpFirst(value), FieldNameFromStore.systemName);
    }, []);

    const handleSystemNameFocusOut = useCallback(() => {
        newTabConfigurationStore.validateSystemName();
        newTabConfigurationStore.validTabConfig();
    }, []);

    return (
        <div className={styles.dialog}>
            <div className={styles.header}>
                <span className={styles.title}>Новая вкладка</span>
                {<CloseMaxi
                    className={styles.closeButton}
                    onClick={handleCancel} />
                }
            </div>
            <div className={styles.dialogBody}>
                <Field name="name" caption="Название вкладки" required={true}>
                    <Input
                        value={newTabConfigurationStore.name}
                        placeholder="Введите значение"
                        onChangeValue={handleTitleChange}
                        inputStyle={InputStyleName.Base}
                        isInvalid={newTabConfigurationStore.validation.name.isInvalid}
                        isNotUnique={newTabConfigurationStore.validation.name.isNotUnique}
                        invalidMessage={newTabConfigurationStore.validation.name.error}
                        onFocusOut={handleTitleFocusOut}
                    />
                </Field>
                <Field name="systemName" caption="Системное название вкладки" required={true}>
                    <Input
                        value={newTabConfigurationStore.systemName}
                        placeholder="Введите значение"
                        onChangeValue={handleSystemNameChange}
                        inputStyle={InputStyleName.Base}
                        isInvalid={newTabConfigurationStore.validation.systemName.isInvalid}
                        invalidMessage={newTabConfigurationStore.validation.systemName.error}
                        onFocusOut={handleSystemNameFocusOut}
                    />
                </Field>
                <div className={styles.dialogFooter}>
                    <Button
                        caption="Отменить"
                        onClick={handleCancel}
                        style={ButtonStyle.Subtle}
                    />
                    <Button
                        caption="Сохранить"
                        onClick={handleSave}
                        style={ButtonStyle.Primary}
                        isDisabled={newTabConfigurationStore.isDidabledSaveButton}
                    />
                </div>
            </div>
        </div >
    );
})

export default NewTabConfiguration;