import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import fileStoreService from "../fileStore/core/FileStoresService";
import { DataSettings, DeduplicationNames, Menu, MenuItems, SettingsPath, systemDesigner } from "../data/Fields";
import cloudStoreService, { LoadStates } from "../fileStore/core/CloudStoreService";
import deduplicationRulesFuncs, { IDeduplicationRule } from "./DeduplicationsRulesUtils";
import { MainPanel, FlexColumn, CheckBox, SelectWithSearchMini, IColumn, Button, ButtonStyle, WarningDialog, SelectMiniWithSearchStyleName } from "components";
import { ISelectItem } from "pages/import/data/import-constants";
import FileStorages from "entities/FileStorages";

import { ArrowToUpThin, DeleteIcon } from "assets/icons";

import styles from "./DeduplicationRules.module.css";


const DeduplicationRules = observer(function () {
    const store = fileStoreService;
    let loadState = cloudStoreService.loadResult.state;
    const [rulesTab, setRulesTab] = useState<JSX.Element>(<></>);
    let [isNewRow, setIsNewRow] = useState<boolean>(false);
    let [isDisabledSelector, setDisabledSelector] = useState<boolean>(true);

    const load = useCallback(async () => {
        await deduplicationRulesFuncs.loadDeduplicationRulesList();

        if (deduplicationRulesFuncs.rulesList.length > 0) {
            setRulesTab(<>{deduplicationRulesFuncs.rulesList.map((rule) => {
                let info = rule.value.entityInfo.filter((info: any) => info.columnName === rule.keyColumn)[0];
                let start = [{ value: rule.value, displayValue: rule.displayValue }, { value: info.columnId, displayValue: info.columnTitle }];

                return <Row
                    key={rule.id}
                    row={rule}
                    items={deduplicationRulesFuncs.rowsList}
                    placeholder={['Выбрать таблицу', 'Выбрать колонку']}
                    startItems={start}
                    isDisabledSelector={false}
                    load={load}
                    setIsNewRow={setIsNewRow}
                    isNew={isNewRow}
                />
            })}</>)
        }
        else {
            setRulesTab(<></>);
        }
    }, [deduplicationRulesFuncs.checkedList, deduplicationRulesFuncs.rowsList, deduplicationRulesFuncs.rulesList]);

    useEffect(() => {
        load();
    }, []);

    return (
        <MainPanel>
            <FlexColumn gap={15}>
                <NavPath />
                <Title
                    activePage={store.activePage}
                    setActivePage={store.setActivePage.bind(store)}
                    isActiveStore={store.currentStore}
                    isSwithing={store.isSwithing}
                    loadState={loadState}
                    setIsNewRow={setIsNewRow}
                    isDisabledSelector={isDisabledSelector}
                    load={load}
                />
                <div>
                    <GridHead columns={DeduplicationNames} />
                    <div className={styles.rowsWrapper}>
                        {rulesTab}
                        {isNewRow &&
                            <Row
                                items={deduplicationRulesFuncs.rowsList}
                                placeholder={['Выбрать таблицу', 'Выбрать колонку']}
                                isDisabledSelector={isDisabledSelector}
                                load={load}
                                setIsNewRow={setIsNewRow}
                                isNew={isNewRow}
                            />}
                    </div>
                </div>
            </FlexColumn>
        </MainPanel>
    )
});

const Row = observer((props: {
    items: ISelectItem[],
    placeholder: string[],
    row?: IDeduplicationRule,
    startItems?: ISelectItem[],
    isDisabledSelector?: boolean,
    load: () => void,
    setIsNewRow: (value: boolean) => void,
    isNew?: boolean
}) => {
    let [checked, setChecked] = useState<boolean>(false);
    let [isDisabled, setDisabled] = useState<boolean>(props.isDisabledSelector ?? true);
    let [valueTable, setValueTable] = useState<ISelectItem>({
        value: props.startItems ? props.startItems[0].value : '',
        displayValue: props.startItems ? props.startItems[0].displayValue : props.placeholder[0]
    });
    let [valueColumn, setValueColumn] = useState<ISelectItem>({
        value: props.startItems ? props.startItems[1].value : '',
        displayValue: props.startItems ? props.startItems[1].displayValue : props.placeholder[1]
    });
    let [columns, setColumns] = useState<ISelectItem[]>([]);

    useEffect(() => {
        if (props.startItems) {
            let secondColumn = valueTable.value.entityInfo.filter((el: any) => el.columnId !== valueColumn.value);
            let secondColumnMapped = secondColumn.map((el: any) => { return { value: el, displayValue: el.columnTitle, id: el.columnId } });

            setColumns(secondColumnMapped);
        }
    }, [])

    useEffect(() => {
        if (deduplicationRulesFuncs.checkedAll === true)
            setChecked(true);
        else
            setChecked(false);
        onChangeCheckedAny();
    }, [deduplicationRulesFuncs.checkedAll])

    const onChangeTable = useCallback((table: ISelectItem) => {
        setValueTable(table);
        let secondColumn = table.value.entityInfo.map((el: any) => { return { value: el, displayValue: el.columnTitle, id: el.columnId } })
        setColumns(secondColumn);
        if (props.isDisabledSelector) setDisabled(false);
    }, []);

    const onChangeColumn = useCallback(async (valueColumn: ISelectItem) => {
        let secondColumn = valueTable.value.entityInfo.filter((el: any) => el.columnId !== valueColumn.value.columnId)
        let secondColumnMapped = secondColumn.map((el: any) => { return { value: el, displayValue: el.columnTitle, id: el.columnId } })

        setColumns(secondColumnMapped);
        setValueColumn(valueColumn);

        if (props.isNew) {
            await deduplicationRulesFuncs.createDeduplRule({ entity: valueTable.value.id, keyColumn: valueColumn.value.columnName });
            props.setIsNewRow(false);
            props.load();
        } else {
            let rule = {
                id: props.row?.id,
                entity: valueTable.value.id,
                keyColumn: valueColumn.value.columnName,
            }
            deduplicationRulesFuncs.updateRules(rule);
            props.setIsNewRow(false);
        }
    }, [deduplicationRulesFuncs, valueTable]);

    const defaultRender = useCallback((value: ISelectItem): JSX.Element => {
        return <span className={styles.displayValue}>{value.displayValue}</span>
    }, [])

    const checkRow = useCallback((value: boolean) => {
        setChecked(value);
        if (props.row?.id) {
            if (value) {
                deduplicationRulesFuncs.checkedList.push(props.row.id);
            }
            else {
                const index = deduplicationRulesFuncs.checkedList.indexOf(props.row?.id, 0);
                if (index > -1) {
                    deduplicationRulesFuncs.checkedList.splice(index, 1);
                }
            }
        }
        onChangeCheckedAny();
    }, [deduplicationRulesFuncs])

    const onChangeCheckedAny = useCallback(() => {
        if (deduplicationRulesFuncs.checkedList.length > 0) deduplicationRulesFuncs.setValue(false, 'isDisableDeleteButton');
        else deduplicationRulesFuncs.setValue(true, 'isDisableDeleteButton');
    }, [deduplicationRulesFuncs])

    const classes: any = {};
    classes[`${styles.activeSettingsTableRow}`] = checked;
    let cx = classNames(`${styles.row} `, classes);

    const inputClasses: any = {};
    classes[`${styles.disabled}`] = isDisabled;
    let cxInput = classNames(`${styles.select} `, inputClasses);

    const handleChange = useCallback((selectedItem: ISelectItem) => {
        onChangeTable(selectedItem);
        setValueColumn({ value: '', displayValue: props.placeholder[1] });
    }, []);

    return (
        <div className={cx}>
            <CheckBox
                className={styles.checkBoxGrid}
                checked={checked}
                onChangeChecked={checkRow}
            />
            <SelectWithSearchMini
                className={styles.select}
                styles={SelectMiniWithSearchStyleName.Opacity}
                value={valueTable}
                items={props.items}
                renderFunction={defaultRender}
                onChange={handleChange}
            />
            <SelectWithSearchMini
                className={cxInput}
                styles={SelectMiniWithSearchStyleName.Opacity}
                value={valueColumn}
                items={columns}
                renderFunction={defaultRender}
                onChange={onChangeColumn}
            />
        </div>
    );
});


const GridHead = observer(function (props: { columns: IColumn[], }) {
    let cx = classNames(`${styles.settingsTableHeader} `);

    const onChangeChecked = useCallback((checked: boolean) => {
        deduplicationRulesFuncs.setValue([], 'checkedList');
        deduplicationRulesFuncs.setValue(checked, 'checkedAll');

        if (checked) {
            deduplicationRulesFuncs.rulesList.map((rule) => { if (rule.id) deduplicationRulesFuncs.checkedList.push(rule.id); });
        }
        else {
            deduplicationRulesFuncs.setValue([], 'checkedList');
        }
    }, [deduplicationRulesFuncs]);

    const columnsHeaders = useMemo(() => {
        return props.columns.map((column) => {
            return (
                <>
                    <div key={column.name} id={column.caption} className={styles.headerCell}  >
                        <div className={styles.headerCaption}>{column.caption}</div>
                    </div>
                </>
            );
        })
    }, [props.columns])

    return (
        <div className={cx} >
            <CheckBox
                className={styles.checkBoxGrid}
                checked={deduplicationRulesFuncs.checkedAll}
                onChangeChecked={onChangeChecked}
            />
            {columnsHeaders}
        </div>
    );
});


const Title = observer(function (props: {
    activePage: FileStorages, setActivePage: (value: FileStorages) => void, isActiveStore: FileStorages,
    isSwithing: boolean, loadState?: LoadStates, setIsNewRow: (value: boolean) => void, isDisabledSelector?: boolean, load: () => void,
}) {
    const [open, setOpen] = useState<boolean>(false);
    let freshRules: IDeduplicationRule[] = [];

    const handleClickSetOpen = useCallback(() => {
        setOpen(true);
    }, []);
    const handleClickCancel = useCallback(() => {
        setOpen(false);
    }, []);
    const handleClickAddRule = useCallback(() => {
        props.setIsNewRow(true);
    }, []);

    const handleClickDelete = useCallback(() => {
        deduplicationRulesFuncs.deleteRules(deduplicationRulesFuncs.checkedList);
        for (let i = 0; i < deduplicationRulesFuncs.checkedList.length; i++) {
            freshRules = freshRules.filter((rule) => rule.id !== deduplicationRulesFuncs.checkedList[i])
        }
        deduplicationRulesFuncs.setValue(freshRules, 'rulesList');
        deduplicationRulesFuncs.setValue([], 'checkedList');
        setOpen(false);
    }, [deduplicationRulesFuncs]);

    useEffect(() => {
        props.load();
    }, [deduplicationRulesFuncs.checkedList, props.setIsNewRow]);

    const classes: any = {};
    classes[`${styles.disabled}`] = deduplicationRulesFuncs.isDisableDeleteButton;
    let cxInput = classNames(`${styles.deleteButton} `, classes);

    return (
        <FlexColumn gap={15}>
            <span className={styles.title}>Правила дедубликации</span>
            <div className={styles.buttons}>
                <Button
                    caption={'Добавить правило'}
                    onClick={handleClickAddRule}
                    style={ButtonStyle.Primary}
                    className={styles.addRuleButton}
                />
                <div className={cxInput}>
                    <DeleteIcon /><span onClick={handleClickSetOpen} className={styles.deleteButtonText}>Удалить</span>
                </div>
            </div>
            {<WarningDialog
                valueDelete="Удалить правило"
                valueReturn="Отмена"
                value={'Вы действительно хотите удалить это правило дедубликации?\nСуществующие записи удалены не будут.'}
                onCancelClick={handleClickDelete}
                onBackClick={handleClickCancel}
                isOpen={open}
            />}
        </FlexColumn>
    )
})

const MENU_ITEMS_DATA_SETTING = 4;
const DATA_SETTINGS_DEDUBLICATION = 2;

function NavPath() {
    const navigate = useNavigate();
    const handleClickNavigate = useCallback(() => {
        navigate(SettingsPath)
    }, []);
    const handleClickData = useCallback(() => {
        navigate(SettingsPath + '/' + MenuItems.DataSettings);
    }, [SettingsPath, MenuItems.DataSettings]);
    return (
        <div className={styles.firstTitle}>
            <div className={styles.adminTitle} onClick={handleClickNavigate}>
                {systemDesigner}
            </div>
            <div className={styles.arrowHead}>
                <ArrowToUpThin />
            </div>
            <div className={styles.adminTitle} onClick={handleClickData}>
                {Menu[MENU_ITEMS_DATA_SETTING].name}
            </div>
            <div className={styles.arrowHead}>
                <ArrowToUpThin />
            </div>
            <div className={styles.userTitle}>
                {DataSettings[DATA_SETTINGS_DEDUBLICATION].name}
            </div>
        </div>
    )
}


export default (DeduplicationRules);